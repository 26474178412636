import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 909 771">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M778.000000,1.000000 
	C821.989990,1.000000 865.980042,1.000000 909.977539,0.999999 
	C909.984985,257.931671 909.984985,514.863342 909.984985,771.897522 
	C607.000000,771.897522 304.000031,771.897522 1.000006,771.904419 
	C1.000000,747.583496 1.000000,723.255615 1.468657,698.463867 
	C10.431610,697.999573 18.926125,697.964050 27.420086,698.017029 
	C30.562920,698.036621 33.316856,697.223877 33.790638,693.803284 
	C34.261578,690.403137 36.159668,689.901978 38.980114,689.971863 
	C43.307865,690.079163 47.666759,690.276306 51.958462,689.868042 
	C53.830566,689.689941 56.992973,688.278198 57.130474,687.133667 
	C57.865227,681.017639 62.116497,682.001038 66.028236,682.000122 
	C69.192787,681.999329 72.358322,681.949280 75.521622,682.011902 
	C78.946800,682.079712 81.051338,680.788879 82.137337,677.270996 
	C82.558670,675.906128 84.646294,674.276978 86.094635,674.151672 
	C90.723724,673.750916 95.470123,674.437561 100.045151,673.799927 
	C102.150269,673.506531 104.983994,671.476379 105.666336,669.578918 
	C106.677933,666.765869 107.951241,665.915344 110.633026,665.975220 
	C114.961273,666.071960 119.314857,666.251038 123.613914,665.883240 
	C125.532288,665.719055 128.781555,664.550842 128.968399,663.428894 
	C130.077179,656.770996 134.854340,657.929688 139.178848,658.019958 
	C142.770035,658.094971 145.110428,656.958496 146.169159,653.113831 
	C146.530182,651.802917 148.760391,650.275208 150.250763,650.143921 
	C154.713333,649.751099 159.253632,650.252991 163.719330,649.878296 
	C165.620972,649.718750 168.854095,648.459106 169.016922,647.340515 
	C169.967239,640.812317 174.629669,641.955139 178.784714,642.028992 
	C182.509109,642.095154 185.191223,641.211853 186.165085,636.998230 
	C186.445892,635.783386 188.561050,634.163879 189.833038,634.165771 
	C193.309067,634.171021 195.504913,632.710876 197.412537,629.923767 
	C199.037735,631.012817 200.491806,631.880493 201.584137,633.078186 
	C207.296036,639.341003 214.898148,641.896851 222.745148,643.827637 
	C229.767197,645.555420 236.861008,647.018127 243.966766,648.368286 
	C247.842743,649.104736 251.801285,649.532593 255.740707,649.720459 
	C256.720917,649.767212 257.771271,648.343628 258.789368,647.595947 
	C258.095367,646.674377 257.401398,645.752930 256.660248,644.524902 
	C255.649780,643.656555 254.686493,643.094788 253.606384,642.230591 
	C252.870972,641.051880 252.252365,640.175415 251.763748,638.929810 
	C251.239685,636.925598 250.833786,635.128357 249.889404,633.682983 
	C245.349609,626.734619 240.664398,619.881226 236.349487,612.848267 
	C240.336990,612.015808 244.005829,611.329285 247.259186,610.720520 
	C247.383423,608.440735 247.470657,606.840088 247.852539,605.440063 
	C248.714050,606.444458 249.338638,607.214050 249.839050,608.057251 
	C256.348755,619.026611 262.601074,630.158813 269.438232,640.919556 
	C272.873749,646.326538 275.883698,652.415466 284.111053,652.742004 
	C302.356537,653.465942 320.576935,654.816711 338.809204,655.883301 
	C344.844696,656.236450 346.621307,653.649780 344.332977,647.830566 
	C340.839478,638.946655 336.993073,630.188538 333.847168,621.184631 
	C330.760162,612.349182 327.880920,603.384644 325.771210,594.277405 
	C322.533600,580.301453 322.846313,580.230103 308.502716,580.262756 
	C308.005005,580.263855 307.507141,580.197693 306.998352,579.749207 
	C306.672760,577.466003 306.358215,575.596497 305.921021,572.998169 
	C309.199646,572.998169 311.804962,573.078857 314.401123,572.959595 
	C315.478943,572.910095 317.146210,572.764771 317.508575,572.098877 
	C320.626526,566.368591 325.263855,567.514343 330.355011,568.479858 
	C334.177368,569.204773 338.245483,568.906006 342.171417,568.617676 
	C343.128418,568.547485 343.956970,566.728455 344.844604,565.713562 
	C343.818939,565.144470 342.831818,564.191589 341.759735,564.083862 
	C339.300598,563.836670 336.770081,564.246399 334.322876,563.946228 
	C329.847778,563.397400 325.412048,562.527710 321.249084,561.529175 
	C336.939819,560.486816 352.337189,559.606689 367.744354,558.960205 
	C379.523865,558.465881 391.313873,558.143494 403.102600,557.963745 
	C424.158722,557.642700 445.219696,557.189392 466.274384,557.348877 
	C485.867188,557.497253 505.454315,558.380127 525.044495,558.918701 
	C528.372437,559.010315 531.711487,558.857117 535.031433,559.053711 
	C556.203247,560.307678 577.370850,561.634155 598.540710,562.921387 
	C603.337341,563.213074 608.172852,563.163696 612.929565,563.758423 
	C625.325806,565.308411 637.666565,567.311646 650.071594,568.777588 
	C658.611694,569.786865 667.238831,570.038757 675.797119,570.919861 
	C687.632080,572.138367 699.471436,573.399353 711.249207,575.064575 
	C725.453796,577.073120 739.576233,579.661377 753.779358,581.681335 
	C757.095154,582.152954 760.592896,581.345886 764.402954,581.134033 
	C765.563477,581.015198 766.327942,580.889404 767.389893,580.867065 
	C768.091553,581.079224 768.495605,581.188049 768.923218,581.570374 
	C769.671692,581.691772 770.396484,581.539551 771.464600,581.304932 
	C772.513672,581.213928 773.219482,581.205261 773.802856,581.398193 
	C773.680359,581.599854 773.756226,582.065613 773.531494,582.288330 
	C775.046570,586.595764 778.122742,584.915710 781.365234,584.277649 
	C782.556274,584.102844 783.338013,583.934387 784.319824,583.891357 
	C784.519897,584.016785 784.888611,584.312012 784.980103,584.651123 
	C792.017456,588.358887 799.328979,589.668884 807.325317,588.941833 
	C808.056824,589.061218 808.474915,589.126587 808.992310,589.532715 
	C811.731018,590.209717 814.370483,590.545837 817.370239,590.938782 
	C818.457764,591.052124 819.184814,591.108643 820.010620,591.505005 
	C824.528259,592.865601 828.919861,594.055664 833.387268,594.775330 
	C834.375000,594.934448 835.630737,593.429321 836.763062,592.690552 
	C835.940796,591.798584 835.284973,590.480835 834.269226,590.083679 
	C827.598328,587.474854 820.871277,585.003906 814.121582,582.603333 
	C802.071472,578.317627 790.015015,574.047363 777.918213,569.895996 
	C767.935791,566.470215 757.914368,563.152954 747.858154,559.950989 
	C745.309509,559.139465 742.562317,558.951172 739.537720,558.423828 
	C738.432739,558.264099 737.696411,558.157043 736.892700,557.677124 
	C734.109436,556.664001 731.393738,556.023621 728.385498,555.352051 
	C727.711121,555.235046 727.329163,555.149353 726.924438,554.759399 
	C726.268494,554.463379 725.635315,554.471680 724.665527,554.446899 
	C719.895813,553.266724 715.462708,552.119507 710.970703,550.611023 
	C709.291626,549.265625 707.787292,547.858948 706.029358,547.376648 
	C700.809692,545.944580 695.488281,544.884399 690.209839,543.664917 
	C672.754150,539.632080 655.309692,535.549500 637.834106,531.604736 
	C635.272705,531.026611 632.542114,531.243591 629.930603,530.833313 
	C622.969421,529.739807 616.030579,528.504761 608.724182,527.183594 
	C605.905579,527.309570 603.629272,522.898376 600.702881,526.751831 
	C599.570557,526.521973 598.763916,526.293640 597.905640,525.671692 
	C592.638184,524.206787 587.430603,523.091064 582.196167,522.119690 
	C581.534241,521.996887 580.731750,522.631775 579.576660,522.920593 
	C577.498474,522.818604 575.838074,522.711609 574.036865,522.308105 
	C572.214905,522.132751 570.533569,522.253845 568.507202,522.454346 
	C567.455505,522.514587 566.748901,522.495483 566.069092,522.111572 
	C564.987061,520.887695 563.939209,519.388855 562.759155,519.276306 
	C556.519714,518.681519 550.248657,518.397034 543.983337,518.125854 
	C543.326111,518.097412 542.634521,518.861328 541.563110,519.258789 
	C540.119629,519.146362 539.071960,519.032715 537.931946,518.534302 
	C532.557068,517.442505 527.292053,516.509094 521.986572,516.096802 
	C517.342651,515.735901 512.653259,515.960632 507.575500,515.940857 
	C506.385651,516.146790 505.604218,516.338501 504.379150,516.421875 
	C495.314972,516.143616 486.694427,515.973633 477.952698,515.430969 
	C476.778931,514.734558 475.727417,514.132324 474.673767,514.128601 
	C457.622467,514.068665 440.570679,514.049683 423.519867,514.158569 
	C422.321198,514.166199 421.129364,515.245300 419.474976,515.893799 
	C413.342010,515.868835 407.668396,515.776245 401.993774,515.254944 
	C402.115417,510.730988 400.603394,505.781647 407.296936,505.209961 
	C408.030518,505.147339 409.205322,503.945160 409.257568,503.206543 
	C409.682495,497.204315 413.964783,498.175720 417.931030,497.887756 
	C420.363281,497.711151 424.257599,497.002899 424.799744,495.536682 
	C426.148407,491.889099 429.258423,490.943115 431.396179,488.737823 
	C434.490143,485.546082 437.755737,482.534149 440.752441,479.356323 
	C442.939026,477.037598 445.150024,474.942047 447.376343,472.744934 
	C450.512756,469.649597 455.386200,466.551056 456.513580,463.493774 
	C458.893951,457.038696 462.946838,458.012543 467.375366,458.025482 
	C471.284729,458.036926 473.630219,456.620575 474.081268,452.397736 
	C474.261322,450.712158 475.409393,449.129944 476.225708,447.249817 
	C474.905151,447.172882 473.452576,447.088287 471.870667,446.767212 
	C471.453247,446.317108 471.165222,446.103455 471.175110,445.628937 
	C473.407166,445.368774 475.987000,444.569885 477.157990,445.515533 
	C481.153595,448.742188 484.082489,445.465393 486.242218,443.699432 
	C488.009521,442.254333 488.217834,438.637604 488.634674,435.909851 
	C488.867950,434.383392 488.118927,432.706848 488.169678,431.197449 
	C489.658936,430.775635 490.791016,430.254333 491.923096,429.733002 
	C489.663208,424.833435 492.951752,423.886414 496.186401,423.533142 
	C497.565155,419.770081 499.398224,416.528992 499.842743,413.107513 
	C500.397919,408.834564 502.191162,408.570404 505.496643,410.431763 
	C505.492279,412.507904 505.414520,414.173187 504.986511,416.116425 
	C504.869293,422.649231 505.102356,428.904144 505.446228,435.554443 
	C505.735992,442.070709 506.689148,443.077148 512.705872,443.306458 
	C514.700256,443.382477 516.700439,443.301788 518.697937,443.301971 
	C526.349426,443.302673 526.699524,442.955261 526.700623,435.509216 
	C526.703491,415.866211 526.861023,396.221405 526.616272,376.581543 
	C526.556335,371.773956 528.325684,370.490570 532.726074,370.735962 
	C537.370300,370.994965 542.054565,370.523560 546.700623,370.766632 
	C551.389160,371.011993 552.978577,368.822357 552.771423,364.494446 
	C552.588806,360.677704 552.587524,356.852264 552.893188,353.052399 
	C554.628357,354.861328 555.976501,356.648560 557.514954,358.688019 
	C559.682312,352.122803 565.203552,353.298737 570.086060,353.382690 
	C565.822815,364.233032 561.474670,374.672913 557.357544,385.203156 
	C550.775085,402.039368 544.304321,418.919769 537.889893,435.820831 
	C536.716858,438.911530 536.193848,442.423798 540.407776,443.597717 
	C543.551636,444.473572 546.967468,444.769501 550.244385,444.679199 
	C555.173950,444.543396 559.803345,443.444031 561.755920,437.910126 
	C562.960571,434.496155 564.877991,431.253204 565.553772,427.755463 
	C566.248657,424.158661 568.162354,423.657898 571.147705,423.734253 
	C575.807434,423.853455 580.472412,423.766785 585.135132,423.776154 
	C593.284119,423.792511 593.290588,423.793457 595.569031,431.657593 
	C595.845886,432.612885 595.813538,433.702118 596.256592,434.557220 
	C598.168091,438.246277 598.496277,443.443390 603.881653,444.106110 
	C607.959045,444.607880 612.214355,444.998169 616.225586,444.375641 
	C621.597778,443.541962 622.810303,441.226288 621.103821,435.745026 
	C619.301392,429.955719 616.887451,424.357361 614.754883,418.670227 
	C611.122314,408.983185 607.508057,399.289276 603.872681,389.603302 
	C600.817078,381.461761 598.159180,373.135620 594.533997,365.255188 
	C591.988098,359.720856 591.472107,352.732483 585.014709,349.029053 
	C586.467224,341.252808 578.955627,345.762695 577.007385,342.671295 
	C579.568237,336.652222 585.673706,340.439178 588.724854,338.768890 
	C587.551575,335.891907 586.540466,333.412598 585.213257,330.157990 
	C591.975952,330.415894 594.388062,327.644287 594.324524,323.957123 
	C597.332092,321.656219 601.127747,320.204987 601.406616,318.251587 
	C602.091797,313.452820 601.264221,308.412140 600.768982,303.496582 
	C600.687561,302.688904 599.301147,301.572021 598.352112,301.367554 
	C593.890503,300.406372 589.371033,299.713654 584.910339,298.544373 
	C584.992188,297.653564 585.035950,297.143494 585.475037,296.613800 
	C587.428162,296.565155 588.986084,296.536163 590.987061,296.656677 
	C596.292419,295.557617 601.154663,294.309113 606.374451,292.953796 
	C607.434082,292.636627 608.136108,292.426239 609.128235,292.429016 
	C609.890869,292.684601 610.363403,292.727020 611.183594,292.888275 
	C612.019226,293.028870 612.507141,293.050598 613.246216,293.321472 
	C615.999756,292.761475 618.502258,291.952362 621.407410,291.110504 
	C624.804260,290.940826 627.798462,290.803894 631.224121,290.764954 
	C634.733704,289.864899 637.811768,288.866943 640.932495,287.612946 
	C640.971191,287.183899 640.967285,287.010925 641.314575,286.819397 
	C642.363708,286.732697 643.061584,286.664490 644.145630,286.742249 
	C645.351685,286.897461 646.171570,286.906677 647.209351,287.191956 
	C650.672119,287.481781 653.945129,287.550415 655.400452,283.367004 
	C656.107422,283.078888 656.522217,282.940887 657.295044,282.952850 
	C658.426697,283.127014 659.200256,283.151245 660.239929,283.403259 
	C720.543823,271.959412 778.217590,252.761871 834.854248,230.262711 
	C834.752319,229.432068 834.650330,228.601440 834.548340,227.770813 
	C831.592651,227.647110 828.540405,226.982071 825.697083,227.489014 
	C809.287231,230.414780 792.948303,233.737915 776.541321,236.680893 
	C766.794373,238.429245 756.966492,239.726562 746.809814,241.152618 
	C745.028564,241.684860 743.612610,242.291153 741.797241,242.912048 
	C740.606201,242.973648 739.814514,243.020660 738.872803,242.844116 
	C738.137207,242.953262 737.551697,243.285965 736.530273,243.691208 
	C733.728638,243.845337 731.362854,243.926926 728.753906,243.737061 
	C726.002258,244.313416 723.493835,245.161240 720.961548,246.288910 
	C720.962952,246.754349 720.988098,246.939896 720.657776,247.191833 
	C719.600464,247.397949 718.898560,247.537674 717.815186,247.463287 
	C716.004700,247.247925 714.575623,247.246674 712.718506,247.136932 
	C710.526855,247.039017 708.763245,247.049591 706.777649,246.803131 
	C704.367493,247.366455 702.179443,248.186813 699.573853,249.033600 
	C693.104675,249.700592 687.052917,250.341171 680.738281,250.713043 
	C676.934692,251.437439 672.359192,248.818359 669.658508,253.637024 
	C669.303467,253.891632 669.160278,253.991837 668.627563,253.950119 
	C667.136597,253.760788 666.035156,253.713409 664.715698,253.446167 
	C663.669922,253.138016 662.842102,253.049728 661.778809,252.712509 
	C660.366455,252.991226 659.189514,253.518860 657.811401,254.120392 
	C657.610168,254.194275 657.218384,254.368210 656.864624,254.244354 
	C656.015381,254.059189 655.519958,253.997894 654.775269,253.683289 
	C652.686951,252.987976 650.847839,252.545959 649.006287,251.663025 
	C649.418030,247.979904 649.832336,244.737701 650.257141,241.412918 
	C655.059448,240.274551 660.880432,242.892868 663.270020,236.759933 
	C662.172668,236.019897 661.007935,235.234406 660.031860,234.205505 
	C661.435242,232.302536 664.011963,230.188858 663.635559,229.059982 
	C661.733704,223.356705 659.043335,217.918304 656.667847,212.370560 
	C653.972290,206.075378 651.317261,199.762863 649.000732,193.374023 
	C650.859802,194.692642 652.971802,195.819107 653.755249,197.547729 
	C656.439087,203.469330 658.620850,209.616959 661.095398,215.636780 
	C661.424133,216.436630 662.533936,217.680573 662.949219,217.561188 
	C667.028381,216.388382 672.065735,218.348007 675.456421,214.137161 
	C677.191345,217.439011 678.815491,220.490585 680.046875,223.693192 
	C682.814331,230.890396 684.437317,232.276062 692.127258,231.891357 
	C696.057373,231.694733 699.959534,230.744034 703.848450,229.999069 
	C705.963440,229.593918 708.024170,228.905869 710.464111,228.285645 
	C711.536377,228.236725 712.253967,228.247589 713.291626,228.495392 
	C719.151123,228.138977 724.678711,227.258682 730.233215,227.028900 
	C736.418701,226.772980 739.862427,224.683182 735.410034,217.086243 
	C730.200562,208.197479 723.822266,199.991638 717.915710,191.514114 
	C711.918823,182.906952 705.003540,175.566605 693.829407,174.024994 
	C693.131836,173.928757 692.580566,172.771912 692.148193,171.852722 
	C694.411499,171.445938 696.487793,171.294952 698.564087,171.143967 
	C698.975220,170.423691 699.386414,169.703415 699.797607,168.983139 
	C700.570312,170.852554 701.343079,172.721985 702.115845,174.591400 
	C702.754150,174.371704 703.392456,174.151993 704.030762,173.932281 
	C704.677795,171.703964 706.304443,169.162308 705.717896,167.328583 
	C705.022705,165.155502 702.420471,163.592422 701.102051,162.235565 
	C702.698242,160.716217 704.283081,159.168991 705.911804,157.669434 
	C707.284668,156.405396 708.815247,155.296097 710.081482,153.937042 
	C711.500488,152.413956 713.124878,150.856201 713.866394,148.997040 
	C714.496521,147.417267 714.062988,145.384872 713.985596,143.557602 
	C713.855103,140.476517 714.698181,139.155685 718.085449,137.605301 
	C722.772156,135.460114 724.703613,129.182632 729.830811,126.698479 
	C730.190674,126.524132 729.984924,125.124664 729.993530,124.294380 
	C730.012451,122.463966 730.076843,120.629311 729.983154,118.803535 
	C729.834534,115.906654 730.650452,114.396179 733.952454,113.721786 
	C735.578064,113.389771 737.568176,110.543900 737.816101,108.631157 
	C738.388977,104.212013 738.077271,99.666321 737.973083,95.175461 
	C737.903503,92.174622 738.363770,90.234047 742.098938,89.726837 
	C743.583435,89.525246 745.231384,86.846367 745.852234,84.978645 
	C746.499939,83.029861 746.066345,80.697174 745.985229,78.536858 
	C745.883667,75.834938 746.721558,74.494568 749.713928,73.754677 
	C751.437378,73.328537 753.069092,70.780403 753.852478,68.830093 
	C754.543274,67.110039 754.067200,64.891220 753.984375,62.897697 
	C753.867065,60.075802 754.450928,58.338211 757.871277,57.754467 
	C759.455017,57.484177 761.236694,55.022709 761.832886,53.196842 
	C762.520813,51.090359 761.556274,48.467896 762.149231,46.299053 
	C762.592651,44.677059 764.148010,42.538071 765.593811,42.178123 
	C768.828247,41.372879 770.047424,39.722157 770.012024,36.610416 
	C769.959045,31.951321 770.051758,27.290380 769.981689,22.631739 
	C769.942078,19.994089 770.427734,18.072651 773.675354,17.952246 
	C777.079529,17.826038 778.065796,15.551279 778.014099,12.482602 
	C777.949707,8.656074 777.999451,4.827622 778.000000,1.000000 
M659.386475,354.077179 
	C659.043701,354.503967 658.700867,354.930756 658.254761,355.359619 
	C658.254761,355.359619 658.324890,355.435577 657.615967,355.208008 
	C657.055969,356.110962 656.495911,357.013916 655.699402,358.378510 
	C655.152954,359.006927 654.606567,359.635345 653.739197,360.066589 
	C653.492004,359.979797 653.237671,359.943695 652.300537,360.085022 
	C641.792908,369.421051 643.516174,386.070374 651.896240,395.139130 
	C653.159302,396.505951 655.466553,396.907867 657.878357,397.957947 
	C658.924805,398.625275 659.971191,399.292603 661.074036,400.675537 
	C662.049683,401.462585 662.906128,402.573242 664.020508,402.983673 
	C670.802979,405.481873 677.574036,408.054718 684.491516,410.126587 
	C688.757935,411.404388 692.145874,413.638031 691.673157,418.083984 
	C691.170959,422.806946 686.876038,423.828339 682.645081,424.133270 
	C680.082764,424.317963 677.537903,424.743744 674.334595,425.072388 
	C673.903259,425.034882 673.471924,424.997375 672.939636,424.265961 
	C670.932617,421.210938 668.092651,418.390045 667.149780,415.035889 
	C666.182190,411.593567 664.851013,408.467072 661.578796,408.381409 
	C656.131470,408.238861 650.650696,409.364990 645.186279,409.976837 
	C645.089233,409.987732 644.947083,410.249878 644.948608,410.393524 
	C645.007812,416.066742 643.196045,422.702606 645.508789,427.231079 
	C652.260132,440.450867 665.034912,445.256744 679.113708,445.740479 
	C685.341125,445.954407 692.020752,444.763550 697.797546,442.460693 
	C702.101685,440.744873 705.284912,436.217285 709.501709,432.623383 
	C710.007263,431.754974 710.512817,430.886566 711.729858,429.936310 
	C713.231445,427.772278 714.733032,425.608276 716.579529,422.947235 
	C717.884949,425.665771 718.788940,428.313324 720.295898,430.555664 
	C727.290222,440.963501 737.535461,445.468842 749.712952,445.919250 
	C755.783142,446.143738 761.584534,444.916901 767.617981,443.483246 
	C778.553528,440.884796 790.413818,425.990814 789.948730,416.237457 
	C789.631714,409.588074 789.903076,402.910736 789.905029,396.245911 
	C789.908997,383.082764 789.960144,369.919342 789.885620,356.756653 
	C789.853943,351.174713 788.653931,350.104767 783.076904,350.056671 
	C779.578125,350.026459 776.077209,349.969360 772.580322,350.052155 
	C768.142761,350.157227 765.481750,353.473358 765.472046,357.803436 
	C765.428772,377.069824 765.218323,396.335968 764.989685,415.601288 
	C764.976990,416.674805 764.408386,417.826691 763.844910,418.794434 
	C760.968079,423.735107 755.476624,425.087708 746.890137,423.066589 
	C742.748108,422.091614 739.959290,417.937683 739.937927,412.202454 
	C739.872070,394.540802 739.931519,376.878723 739.891724,359.216919 
	C739.877014,352.683685 737.154358,350.042175 730.656189,350.030853 
	C728.323486,350.026764 725.990601,350.006683 723.658142,350.029388 
	C717.717896,350.087250 715.123962,352.630341 715.091187,358.439301 
	C715.069580,362.266754 715.087097,366.094421 715.087097,370.071716 
	C713.706299,369.639557 712.500061,369.262054 711.032410,368.247925 
	C710.644165,367.210663 710.255920,366.173401 709.947815,364.444458 
	C709.223328,363.690186 708.498840,362.935944 707.526917,361.560608 
	C699.868591,353.019104 690.193542,349.855347 678.945129,350.713013 
	C675.970764,350.939758 672.970093,350.820923 669.570984,350.395508 
	C667.746765,351.319702 665.922607,352.243896 663.743774,353.366028 
	C663.743774,353.366028 663.352722,353.475922 662.685181,353.196716 
	C661.798767,353.488800 660.912354,353.780853 659.386475,354.077179 
M521.669128,728.896240 
	C523.616821,727.298584 523.227722,726.406433 519.996094,726.111206 
	C516.983826,726.379517 513.971497,726.647827 510.375244,726.368286 
	C505.248566,727.108032 500.121918,727.847839 494.142853,728.760193 
	C489.978424,729.866516 485.813965,730.972778 481.737701,732.055664 
	C480.593597,731.209839 479.248383,729.372498 477.816345,729.302124 
	C471.530640,728.993103 465.192596,728.848877 458.928223,729.341064 
	C455.151398,729.637817 452.966949,733.709473 454.486542,736.163574 
	C456.585266,739.552917 463.732971,740.020325 467.666504,736.920898 
	C468.110138,736.899963 468.553772,736.879089 469.432556,737.419800 
	C475.036316,735.673645 480.500031,737.124817 486.116119,737.542419 
	C491.765076,737.962341 497.610992,735.793396 503.356720,734.686707 
	C503.667450,734.626831 503.849823,733.900574 504.770386,733.317932 
	C505.227325,733.238953 505.684265,733.159973 506.916077,733.177368 
	C507.955109,733.153015 508.994141,733.128662 510.485260,733.560974 
	C512.326111,732.731323 514.166992,731.901672 516.504089,731.015747 
	C516.504089,731.015747 517.002197,730.979431 517.753601,731.004395 
	C518.824768,730.330627 519.895996,729.656799 520.981506,728.993591 
	C520.981506,728.993591 520.991272,728.982239 521.669128,728.896240 
M290.944214,680.219910 
	C289.637543,679.469849 288.330841,678.719788 286.455505,677.656860 
	C285.246246,676.167664 284.036957,674.678406 282.881470,672.467896 
	C280.582489,670.999268 278.283478,669.530579 275.234467,667.973999 
	C274.493622,667.831604 273.752777,667.689209 272.741852,666.883667 
	C268.878113,665.999207 265.035950,664.993713 261.138458,664.298706 
	C259.840302,664.067200 258.400238,664.631226 256.303802,664.827271 
	C255.587189,664.759033 254.870560,664.690857 253.929901,664.076965 
	C252.954941,664.018494 251.979965,663.960022 250.368256,663.884888 
	C249.598236,663.550110 248.828217,663.215332 247.864090,662.180115 
	C245.090149,661.338867 242.348801,660.356995 239.531281,659.703796 
	C237.511826,659.235657 235.390442,659.207275 233.314560,658.982666 
	C234.301117,661.233521 234.953201,663.736877 236.393784,665.644958 
	C237.328369,666.882874 239.413635,667.252136 241.498703,668.225281 
	C241.986450,668.497070 242.474182,668.768860 243.061630,669.732300 
	C244.328369,670.519897 245.595108,671.307434 247.022797,672.445679 
	C247.022797,672.445679 247.172058,672.801392 247.162262,673.524109 
	C248.525528,674.274109 249.888794,675.024109 251.636337,675.841858 
	C251.636337,675.841858 252.007904,675.960999 252.070770,676.673096 
	C253.876190,678.284424 255.487396,680.220520 257.526978,681.440369 
	C261.961487,684.092529 266.614594,686.379211 271.870972,688.937744 
	C276.418365,691.553955 280.896179,694.301758 285.531250,696.752197 
	C290.966858,699.625793 296.539215,702.240662 302.154541,705.677368 
	C303.338013,708.202393 304.953033,708.725891 307.814331,706.921448 
	C308.519318,707.117065 309.224304,707.312683 309.933441,708.190796 
	C311.104980,711.156128 313.079834,712.168823 316.774719,710.839661 
	C317.476654,710.980225 318.178558,711.120789 319.102905,711.925415 
	C322.678467,716.753967 328.475800,714.296753 333.052704,716.706848 
	C333.703094,716.773926 334.353455,716.840942 335.635132,716.906067 
	C336.055969,716.903687 336.476837,716.901306 337.619049,716.905212 
	C339.139160,716.217163 340.659271,715.529114 342.867432,714.977478 
	C345.596069,716.287903 348.324738,717.598267 351.111755,719.599426 
	C351.820129,720.006836 352.528503,720.414307 354.087372,720.815552 
	C356.081116,720.839417 358.074890,720.863281 359.960327,721.606812 
	C359.369659,722.889648 358.778961,724.172485 357.786316,726.328247 
	C360.893250,727.054321 363.534607,727.671570 366.002075,728.248230 
	C366.002075,725.261597 366.002075,723.138672 366.829376,720.998230 
	C368.207092,721.040344 369.584778,721.082458 371.130798,721.805542 
	C372.166565,722.142639 373.202332,722.479797 375.122192,722.822510 
	C378.399475,722.817322 381.676727,722.812134 385.393005,723.418762 
	C390.292847,724.264587 391.146820,723.736755 390.958649,719.279114 
	C390.078308,718.395569 389.197968,717.512085 388.087830,715.984070 
	C387.751221,715.410156 387.501892,714.757446 387.065155,714.273987 
	C377.971954,704.208191 368.957245,694.133423 362.532745,681.978271 
	C360.883972,678.858765 357.890228,675.673340 354.741638,674.331909 
	C351.277374,672.855957 346.925842,673.393677 342.953796,673.196411 
	C338.473328,672.973877 333.972076,673.080627 329.502686,672.742859 
	C319.111176,671.957397 308.737946,670.926880 298.344055,670.180664 
	C297.078613,670.089661 294.822266,670.909851 294.628845,671.700317 
	C294.312866,672.991516 294.905243,674.973450 295.838226,676.016724 
	C299.051849,679.610291 302.369019,683.169678 306.030487,686.292053 
	C313.538757,692.694763 321.295715,698.805908 328.530243,705.550476 
	C327.659332,705.721863 326.788391,705.893250 325.281006,705.766602 
	C323.122437,704.933350 320.963837,704.100159 318.270020,702.840454 
	C317.191010,702.505249 316.112000,702.170044 314.647461,701.335388 
	C313.367340,700.008362 312.087219,698.681396 310.883301,696.597107 
	C309.610229,695.715088 308.337128,694.833130 307.011871,693.996094 
	C307.011871,693.996094 307.045959,693.936584 306.962860,693.279602 
	C305.635376,692.547546 304.307861,691.815552 302.345856,690.920959 
	C301.243164,689.927124 300.140472,688.933289 298.935028,687.292908 
	C298.245209,686.898804 297.555420,686.504700 296.268433,685.960999 
	C295.510468,685.316467 294.752533,684.671936 293.946533,683.349854 
	C292.994507,682.536255 292.042450,681.722656 290.944214,680.219910 
M544.489990,674.100586 
	C544.287537,674.360229 544.085083,674.619873 543.135132,674.974731 
	C541.355042,676.177979 539.067566,677.012207 537.879639,678.645996 
	C531.514038,687.400513 525.669861,696.541870 519.137512,705.164734 
	C515.594055,709.842102 511.040039,713.753906 506.354004,718.077637 
	C506.059601,718.495667 505.765198,718.913757 505.470825,719.331787 
	C505.991638,719.539368 506.512482,719.746948 507.473999,720.181946 
	C507.730255,720.404907 507.911530,720.677612 508.123535,721.705444 
	C509.732330,721.850159 511.341156,721.994934 513.655945,722.150757 
	C514.126648,722.146606 514.597412,722.142395 515.577515,722.637268 
	C517.399963,722.200623 519.222473,721.763916 521.914124,721.173096 
	C524.274719,721.125061 526.635254,721.076965 529.115356,721.714233 
	C530.294312,722.451355 531.473206,723.188477 533.410583,724.399902 
	C530.056702,726.415466 528.079407,727.603699 526.102112,728.791931 
	C526.190125,729.109924 526.278076,729.427856 526.366028,729.745789 
	C531.229980,728.624268 536.093872,727.502686 542.170837,726.101440 
	C539.100464,723.504578 537.503723,722.154114 536.291199,720.728333 
	C536.291199,720.728333 536.679077,720.781799 537.546082,720.926514 
	C539.372803,720.316040 541.199524,719.705627 543.676819,718.989746 
	C544.114746,718.954834 544.552734,718.919861 545.063049,719.531067 
	C545.478027,719.843201 545.893005,720.155396 546.307983,720.467529 
	C546.532043,719.638550 546.756104,718.809631 547.611511,717.653870 
	C550.000000,716.682922 552.388550,715.711975 555.482788,714.950378 
	C557.327332,715.595886 559.171936,716.241333 561.684753,716.915344 
	C562.130737,716.914795 562.576660,716.914185 563.319214,717.390625 
	C563.877625,716.956116 564.436035,716.521606 565.723999,715.718628 
	C567.484436,715.440796 569.334106,715.414612 570.985291,714.828918 
	C573.777710,713.838440 576.444580,712.494080 579.838135,711.177246 
	C580.893250,710.778015 581.948364,710.378723 583.424744,710.499512 
	C584.060303,710.516479 584.811218,710.765869 585.314392,710.516113 
	C595.562744,705.430786 605.944946,700.581238 615.945129,695.043030 
	C621.588928,691.917480 626.594604,687.639587 632.312805,683.754578 
	C632.312805,683.754578 632.745117,683.655884 633.376770,683.795349 
	C634.025330,683.011902 634.673889,682.228394 635.837769,681.238464 
	C636.182068,681.102844 636.526306,680.967163 637.621948,681.000732 
	C638.118652,680.880493 638.615417,680.760254 639.365479,681.161316 
	C647.261292,675.629333 655.259094,670.232788 662.957764,664.438782 
	C664.322205,663.411987 664.441101,660.730286 665.135132,658.812744 
	C664.669739,658.333679 664.204346,657.854614 663.738953,657.375549 
	C658.281372,658.966370 652.821838,660.550903 647.376709,662.183411 
	C647.236450,662.225342 647.253418,662.791992 646.307251,663.105469 
	C638.845825,664.288513 631.322327,665.187012 623.953064,666.792969 
	C620.954163,667.446533 617.963745,669.236816 615.542664,671.212341 
	C610.691284,675.170898 606.199341,679.575623 601.627808,683.869385 
	C598.749329,686.572998 595.995667,689.409363 592.752625,692.700745 
	C590.859680,694.165955 588.966675,695.631165 586.350647,697.074585 
	C584.686218,698.062683 583.065674,699.136047 581.347961,700.020691 
	C577.948364,701.771606 574.494995,703.418152 571.011169,704.354675 
	C570.961121,703.855408 570.911072,703.356201 571.329712,702.377502 
	C580.991211,693.615662 590.680359,684.883850 600.269531,676.043518 
	C601.179565,675.204529 601.463440,673.686401 602.037964,672.483521 
	C600.753296,671.997437 599.470581,671.099304 598.183716,671.093323 
	C590.880249,671.059326 583.575745,671.238586 575.377808,671.120056 
	C571.917847,671.971313 568.457825,672.822510 564.083069,673.833740 
	C560.083191,673.984497 556.083374,674.135254 551.610168,673.637756 
	C549.405151,673.782837 547.200195,673.927856 544.489990,674.100586 
M843.119629,442.168945 
	C846.546082,443.756073 848.704529,442.386993 850.591980,438.989716 
	C850.945862,438.892365 851.299683,438.795044 852.398193,438.846313 
	C853.920959,436.888489 855.443726,434.930664 857.000000,432.997314 
	C857.000000,432.997314 856.965942,432.967926 857.717468,432.981934 
	C860.951050,430.797546 864.008118,423.240204 862.968750,419.110138 
	C862.978943,416.741913 862.989136,414.373718 863.557129,411.521912 
	C862.133911,407.321808 861.376282,402.704407 859.084106,399.049194 
	C857.130859,395.934479 855.641174,393.311584 856.792542,389.314575 
	C856.792542,389.314575 856.905273,388.943085 857.659302,388.892670 
	C858.624207,387.489807 860.187195,386.192413 860.456177,384.666809 
	C862.095764,375.369171 860.985413,366.520569 854.463623,359.185822 
	C851.787720,356.176422 848.624756,353.415741 843.241272,353.918671 
	C842.497498,353.763489 841.753784,353.608307 840.962646,352.736481 
	C838.148865,351.834808 835.357849,350.249359 832.517273,350.154999 
	C822.549194,349.823975 812.564087,349.992950 802.585449,349.998444 
	C796.535889,350.001801 794.090393,352.184723 794.069946,358.244202 
	C793.982910,384.022125 793.974426,409.800629 794.060974,435.578522 
	C794.083618,442.312103 797.534607,445.771912 804.188110,445.894196 
	C812.667114,446.049988 821.179565,446.339447 829.620422,445.750854 
	C833.546814,445.477081 837.338440,443.269531 841.633789,441.549316 
	C841.811951,441.466400 841.990051,441.383453 842.521301,441.553619 
	C842.521301,441.553619 842.954895,441.515564 843.119629,442.168945 
M391.204712,734.145508 
	C394.817749,738.932129 399.866730,736.515991 405.240173,736.760315 
	C408.144165,736.793091 411.048187,736.825867 414.408295,737.456055 
	C417.007568,737.249634 419.606873,737.043274 422.920013,736.883728 
	C424.617249,736.906982 426.314453,736.930237 428.747406,737.020508 
	C429.485382,737.063721 430.223328,737.106995 431.209686,737.865906 
	C434.622009,739.119751 438.046265,740.528137 441.815186,736.872131 
	C444.103851,731.331726 442.811707,729.095764 437.244415,729.050659 
	C431.493652,729.004089 425.742493,729.007812 419.884521,728.316345 
	C418.908783,728.278381 417.346069,727.838013 417.051819,728.267334 
	C415.019073,731.232544 413.014801,731.459229 410.162476,729.236694 
	C409.274994,728.545166 407.391357,729.132080 405.305573,729.113831 
	C404.873322,729.039673 404.441040,728.965515 403.861725,728.198120 
	C401.242523,727.797424 398.623291,727.396667 395.077881,727.012939 
	C391.066864,726.942078 387.055817,726.871216 382.875153,726.158752 
	C381.573730,726.150452 380.272278,726.142151 378.380737,726.148987 
	C377.987213,726.147705 377.593719,726.146423 376.366302,726.048462 
	C374.705353,726.307373 373.044403,726.566345 371.076019,726.873169 
	C373.172943,730.144653 385.382050,734.875549 388.666016,734.136719 
	C388.829376,734.099915 388.876984,733.548645 389.659027,733.173645 
	C390.099091,733.284485 390.539124,733.395325 391.204712,734.145508 
M432.605591,598.377136 
	C432.557861,597.687866 432.510132,596.998657 432.814606,595.393066 
	C432.858124,590.571045 433.060852,585.743774 432.902557,580.928406 
	C432.726868,575.584473 430.086700,573.724670 424.743500,574.689026 
	C423.284302,574.952393 421.779114,575.015320 420.291718,575.060181 
	C398.389374,575.721191 376.486084,576.350037 354.584076,577.021790 
	C350.161469,577.157471 348.530701,579.448364 349.422394,583.901123 
	C350.650757,590.034851 351.627869,596.225342 353.056366,602.310791 
	C355.236023,611.596313 357.578339,620.848816 360.104370,630.045959 
	C361.807404,636.246582 363.722870,642.411255 365.981903,648.427368 
	C367.142456,651.518127 369.263489,654.248230 371.083374,657.762695 
	C371.758759,658.146301 372.434113,658.529907 374.024689,658.903381 
	C378.344788,658.909607 382.664856,658.915833 387.261902,659.397400 
	C387.842102,659.260376 388.422272,659.123352 389.939453,658.977966 
	C403.027863,659.003174 416.116974,659.098389 429.203705,658.963257 
	C430.807648,658.946777 432.398804,657.696167 434.244476,656.583130 
	C434.317596,656.433289 434.390747,656.283508 435.166138,655.970398 
	C435.725159,654.512207 436.788330,653.045166 436.763062,651.597168 
	C436.569336,640.486755 436.470978,629.358826 435.707214,618.280273 
	C435.262878,611.835327 433.646057,605.471191 432.605591,598.377136 
M633.465759,632.721008 
	C634.715088,631.250000 636.194336,629.916260 637.170410,628.282043 
	C640.033020,623.489380 642.811096,618.637939 645.408264,613.697083 
	C649.029114,606.808777 652.550110,599.864014 655.932678,592.855957 
	C658.143188,588.276062 656.914368,585.774902 651.823853,585.244995 
	C633.760620,583.364746 615.672852,581.716675 597.588928,580.039795 
	C592.471558,579.565186 587.263794,579.701233 582.227539,578.816528 
	C576.720276,577.848999 574.138306,579.403137 573.069763,584.770386 
	C571.780151,591.247375 570.257507,597.704895 568.353760,604.025635 
	C566.226624,611.087891 563.675293,618.030701 561.096619,624.946411 
	C557.999634,633.252197 554.865540,641.554504 551.379822,649.700989 
	C549.524475,654.037048 550.445740,656.636169 555.254089,656.746765 
	C563.348022,656.932800 571.470947,656.907104 579.549805,656.430847 
	C589.283203,655.857056 598.985901,654.746399 608.697083,653.817810 
	C615.568909,653.160645 622.713074,653.241455 626.144592,645.243652 
	C627.987305,640.948853 630.493958,636.938843 632.808960,632.779297 
	C632.808960,632.779297 632.778748,632.675171 633.465759,632.721008 
M457.623505,711.474609 
	C457.532318,713.304932 457.435760,715.135071 457.350891,716.965698 
	C457.082245,722.758667 457.577759,723.328064 463.318787,723.285828 
	C470.317261,723.234375 477.359131,723.525818 484.294891,722.815735 
	C487.824921,722.454407 492.172821,721.142456 494.414337,718.679565 
	C504.196045,707.931702 510.873810,695.099426 517.055664,682.031738 
	C518.815857,678.310852 517.359985,676.576599 513.556030,676.644775 
	C497.236786,676.937134 480.919922,677.367310 464.600281,677.630066 
	C461.057739,677.687195 460.027893,679.625488 459.802917,682.664246 
	C459.116028,691.940979 458.381104,701.214050 457.623505,711.474609 
M477.508759,659.752380 
	C492.138824,659.444763 506.769989,659.178162 521.397278,658.769714 
	C522.947998,658.726440 524.572205,658.045471 525.997314,657.331055 
	C532.544861,654.048401 530.916199,646.672607 533.626709,641.428711 
	C535.822693,637.180176 536.503418,632.138367 537.783020,627.428589 
	C539.500793,621.106201 541.296021,614.799255 542.788879,608.422729 
	C544.770752,599.957397 546.368835,591.401733 548.383728,582.944885 
	C549.276367,579.198425 548.660461,577.199646 544.382202,577.060974 
	C527.448242,576.512085 510.517761,575.857971 493.586670,575.223450 
	C486.127533,574.943909 478.672272,574.533752 471.211121,574.347229 
	C467.315094,574.249756 464.262421,575.627808 464.110077,580.175049 
	C463.737366,591.298889 463.490051,602.427124 463.089874,613.549866 
	C462.821442,621.010559 462.504028,628.473938 461.973145,635.919373 
	C461.538391,642.016663 460.723755,648.086121 460.139343,654.173828 
	C459.765503,658.068176 462.009216,659.625122 465.522980,659.722046 
	C469.184082,659.823181 472.850037,659.749268 477.508759,659.752380 
M708.024353,618.535828 
	C712.843628,612.453430 717.755981,606.440735 722.406189,600.231750 
	C723.373474,598.940247 724.008362,596.734924 723.589661,595.303711 
	C723.309570,594.346069 720.942871,593.671143 719.429565,593.468140 
	C715.982727,593.005676 712.476318,593.009521 709.012268,592.649292 
	C698.949219,591.602783 688.905762,590.341248 678.826172,589.508545 
	C677.284668,589.381165 674.937500,590.661438 674.080444,592.004028 
	C669.833313,598.657349 666.190308,605.698364 661.903625,612.323975 
	C655.256042,622.598816 648.229675,632.628052 641.444824,642.815369 
	C640.452637,644.305054 639.907471,646.092407 638.737061,648.659851 
	C641.454224,648.659851 642.951294,648.888489 644.355286,648.623535 
	C655.389221,646.542053 666.498413,644.748779 677.398132,642.100220 
	C686.420105,639.907959 694.084839,634.996521 700.033936,627.639160 
	C702.420715,624.687378 705.044983,621.927612 708.024353,618.535828 
M379.833160,680.996826 
	C386.760864,692.976929 393.482971,705.083313 400.723572,716.871216 
	C403.394684,721.219910 407.665924,723.615417 413.366028,723.234253 
	C419.157410,722.847046 425.004608,723.332031 430.827820,723.375916 
	C440.219421,723.446716 440.211731,723.426941 439.441010,714.383423 
	C439.328094,713.058838 439.366302,711.721863 439.274292,710.394836 
	C438.631042,701.118835 437.874298,691.849304 437.369629,682.565979 
	C437.187744,679.220215 435.598938,677.617310 432.479095,677.554138 
	C416.024170,677.221069 399.567841,676.896240 383.111145,676.846130 
	C381.963684,676.842590 380.809387,679.085571 379.833160,680.996826 
M592.864868,344.538239 
	C591.088684,344.027649 589.312500,343.517059 587.536316,343.006500 
	C587.418579,343.572784 587.300842,344.139038 587.183044,344.705322 
	C588.816162,346.355194 590.290039,348.857239 592.864868,344.538239 
z"
          />
          <path
            fill="#F8F9F9"
            opacity="1.000000"
            stroke="none"
            d="
M1.000000,537.000000 
	C1.000000,443.979095 1.000000,350.958221 1.466448,257.468658 
	C5.598387,256.998505 9.280573,257.196686 12.920739,256.892303 
	C14.281153,256.778564 16.641979,255.538712 16.675386,254.734573 
	C16.922791,248.779343 20.857807,248.828415 25.095118,248.982025 
	C27.757395,249.078537 30.459156,249.262283 33.083038,248.930740 
	C36.071671,248.553116 39.964424,250.918365 41.355373,245.175003 
	C41.968258,242.644333 47.955379,241.347687 48.458557,238.872070 
	C49.500202,233.747314 52.013599,232.697006 56.380405,232.858261 
	C57.157974,232.886993 57.969845,231.986725 58.913700,231.427887 
	C58.508804,230.585022 58.217854,230.014160 57.956501,229.430054 
	C54.339092,221.345322 57.047958,217.001404 65.705841,217.000244 
	C74.369606,216.999069 83.033623,216.962112 91.696991,217.020813 
	C94.596451,217.040466 96.800385,216.878845 97.234108,213.011200 
	C97.398964,211.541199 99.897827,210.321457 101.354309,209.011444 
	C102.545166,207.940338 104.656509,207.021957 104.813660,205.831726 
	C105.333641,201.893555 107.516022,200.879471 110.994896,200.972992 
	C113.651016,201.044403 117.972115,201.834152 118.675491,200.627396 
	C121.200233,196.295792 123.961548,192.720840 128.800980,191.042709 
	C128.719467,183.936050 133.853622,184.896332 138.286499,185.035370 
	C142.055191,185.153564 144.696091,185.027695 145.569901,179.980347 
	C145.961807,177.716705 150.374756,176.159576 152.929276,174.237015 
	C153.102478,174.106674 152.860062,173.490829 153.031555,173.281601 
	C156.613876,168.910751 161.349884,165.640045 164.718094,161.057526 
	C164.781540,160.971222 165.038162,161.011902 165.203735,161.017929 
	C167.992035,161.119263 168.505615,159.454239 169.240158,157.067184 
	C169.753540,155.398819 172.053146,153.794617 173.899063,153.155136 
	C175.833664,152.484955 178.180954,152.945175 180.344681,153.013046 
	C182.969772,153.095352 184.557892,152.508179 185.210510,149.382401 
	C185.562515,147.696411 188.015060,146.460434 189.495010,144.996246 
	C193.335480,141.196640 197.691681,137.771896 200.850800,133.469681 
	C204.254944,128.833740 209.791107,126.352852 212.431808,121.117455 
	C212.511917,120.958664 213.184845,121.165062 213.373688,120.984268 
	C214.617493,119.793396 215.912888,118.630600 216.989716,117.295898 
	C220.544144,112.890320 225.363327,109.699699 228.672714,105.065338 
	C228.734695,104.978531 229.023071,105.085449 229.153076,105.010498 
	C233.829514,102.313705 235.441422,96.219322 240.911057,94.357208 
	C241.093643,94.295044 240.802582,93.240555 241.065460,92.947159 
	C242.286545,91.584381 243.485504,89.450920 244.951309,89.217209 
	C247.853577,88.754471 247.938629,86.695412 249.286362,84.979515 
	C252.644867,80.703537 256.766998,76.863846 260.889008,73.284821 
	C262.432495,71.944641 265.038818,72.129036 265.039124,69.009575 
	C265.039459,65.905067 263.418091,65.315689 260.745758,64.761658 
	C259.312897,64.464600 258.282349,62.227081 257.070221,60.865219 
	C258.542084,59.581577 259.825653,57.783802 261.537964,57.156708 
	C263.302368,56.510536 265.488098,56.925171 267.482819,57.018204 
	C270.612732,57.164173 271.557007,55.617332 273.444763,52.986298 
	C276.594299,48.596603 280.574768,44.016769 285.412872,41.263748 
	C287.819702,39.894188 289.102295,39.088943 289.015320,36.415630 
	C288.939484,34.085800 288.652527,31.672924 289.139465,29.445528 
	C289.478088,27.896782 290.901459,25.457987 291.957703,25.395254 
	C295.812927,25.166288 295.804382,21.920525 297.571259,20.121702 
	C301.033020,16.597414 304.413330,11.306149 307.977020,9.910733 
	C313.493561,7.750675 312.949097,4.845689 313.000000,1.000000 
	C411.687561,1.000000 510.375122,1.000000 609.531372,1.463893 
	C609.995544,5.091297 610.356567,8.314095 609.856445,11.397238 
	C609.529297,13.414011 608.180542,16.683226 606.899536,16.882242 
	C601.276489,17.755846 602.155029,21.522840 601.876038,25.335897 
	C601.687073,27.920294 600.645752,32.425045 599.430969,32.620296 
	C592.992065,33.655258 594.266846,38.075577 593.864807,42.255718 
	C593.639282,44.600952 592.417358,48.487289 591.017883,48.790848 
	C585.794128,49.923916 585.659119,53.132408 585.968079,57.196724 
	C586.311890,61.720440 585.392395,65.529396 579.820557,66.092888 
	C579.271484,66.148422 578.339050,66.752266 578.326721,67.126038 
	C578.132263,73.039719 573.652832,73.959259 569.000000,74.608658 
	C569.000000,77.024063 569.000000,79.012184 568.626160,80.994606 
	C567.096619,80.427086 565.914612,79.913231 564.789917,79.295036 
	C560.177429,76.759842 555.464783,74.711685 549.639160,75.920532 
	C547.852600,75.583443 546.429749,75.278412 545.002625,74.559952 
	C544.718933,73.246971 544.482483,71.581078 544.153564,71.562614 
	C539.092041,71.278542 534.018494,71.208824 528.578735,71.084763 
	C527.472412,71.053246 526.734985,71.026276 526.056396,70.657349 
	C527.113403,69.670883 528.111511,69.026390 529.109619,68.381905 
	C528.811035,67.844009 528.512451,67.306122 528.213867,66.768234 
	C525.778503,67.204269 523.343201,67.640297 520.532776,68.067528 
	C515.105652,67.035019 510.053558,66.011307 504.948181,64.608795 
	C501.343018,63.170139 497.791199,62.110291 494.239319,61.050438 
	C494.159912,61.369915 494.080505,61.689400 493.703857,62.030365 
	C492.937775,62.369892 492.468872,62.687943 491.598145,62.994232 
	C487.806000,62.999084 484.415741,63.015690 480.612305,63.021454 
	C478.447510,62.668369 476.708618,62.201439 474.940430,62.020412 
	C473.308533,61.853333 471.645233,61.992989 469.598145,61.990349 
	C468.142151,61.936260 467.083771,61.889370 465.956787,61.474316 
	C462.205811,57.677589 454.845062,59.131927 453.480988,63.626163 
	C453.392517,63.917652 454.434784,64.552345 454.963623,65.354904 
	C454.973145,66.119499 454.970123,66.556587 454.692505,67.269638 
	C453.639771,71.060524 453.391907,74.557381 456.917725,76.764015 
	C460.339874,78.905777 468.574280,78.362823 471.529663,76.109451 
	C475.054840,76.003578 478.128113,75.914658 481.544678,75.823074 
	C482.572449,75.801086 483.256927,75.781761 484.147156,76.071564 
	C485.567413,76.542191 486.781830,76.703697 487.961060,77.238205 
	C486.528931,78.737701 485.142792,79.877975 483.731110,80.985657 
	C482.841370,81.683769 481.913330,82.333092 480.533630,83.008926 
	C474.184387,83.011772 468.289185,82.775002 462.432220,83.144547 
	C460.558655,83.262756 458.786377,84.986511 456.595062,86.072678 
	C454.563995,88.966064 453.551086,91.703514 456.950653,94.505219 
	C456.973785,101.133553 456.857300,107.299553 457.034882,113.457069 
	C457.229645,120.210136 457.217285,127.033493 458.299774,133.662155 
	C458.684845,136.020233 462.015625,137.897263 464.057007,140.386261 
	C465.921661,141.519073 467.683990,142.733902 469.548462,142.918900 
	C478.205658,143.777893 486.891815,144.343262 495.565155,145.044205 
	C496.201263,145.095596 496.819672,145.382599 497.443268,145.570190 
	C500.128632,146.378067 502.877930,148.026184 505.483765,147.823532 
	C510.697510,147.418060 515.832520,145.999985 521.437378,145.000000 
	C524.097656,145.000000 526.320618,145.000000 529.743042,145.000000 
	C528.412598,142.597641 527.685791,141.285187 526.965576,139.530579 
	C526.971191,136.727997 526.970154,134.367569 527.275513,131.853287 
	C529.353088,130.289566 530.931580,128.358002 532.948425,127.612328 
	C535.089600,126.820694 537.646912,127.154366 540.142273,127.251801 
	C540.543701,127.996170 540.825317,128.485367 540.725220,129.044250 
	C539.562561,130.973907 538.098572,132.844116 538.126282,134.691925 
	C538.216492,140.704300 538.237000,146.325302 530.510620,147.434555 
	C529.574219,150.019073 527.690613,153.776352 526.120483,153.649658 
	C519.979919,153.154190 514.069763,153.378815 508.114227,154.905319 
	C507.030853,155.183014 505.664337,154.091354 504.396881,153.974335 
	C502.209991,153.772430 499.985748,153.672852 497.800415,153.838013 
	C495.391418,154.020035 493.013824,154.831696 490.613525,154.875595 
	C481.890900,155.035080 473.163727,154.932526 464.440094,155.056656 
	C462.163177,155.089035 461.287476,156.142365 463.141541,158.385681 
	C462.746613,159.124969 462.416992,159.580307 461.723846,160.058228 
	C455.786865,163.214630 455.866028,163.163818 459.245026,168.429031 
	C459.825684,169.333878 459.572998,170.899048 459.421082,172.123932 
	C459.037109,175.220566 458.270050,178.282990 458.071747,181.385666 
	C457.868835,184.559784 459.441254,186.407318 462.936127,186.400848 
	C462.676575,188.868958 462.389557,190.939575 461.708740,193.012085 
	C456.730011,193.812408 456.503357,196.597961 457.639404,200.398468 
	C458.262665,202.483597 459.038727,205.246185 458.192688,206.916611 
	C455.237640,212.750870 458.407654,217.911362 458.390503,223.356354 
	C460.134521,220.977554 461.595673,218.984604 463.071136,217.458054 
	C463.115784,222.602890 463.146149,227.281326 462.848236,232.103745 
	C458.686890,234.719879 458.176483,237.601715 460.811371,241.470184 
	C461.853851,243.000732 461.769379,245.298843 462.268433,247.567505 
	C460.009308,247.194534 457.781097,246.380554 457.469757,246.846741 
	C455.236084,250.191193 446.976196,249.447006 450.500549,256.903290 
	C450.800507,257.537903 448.712219,259.368195 447.642456,260.561523 
	C446.838440,261.458435 445.888611,262.224609 444.553284,263.053345 
	C441.402008,263.054291 438.701141,263.050842 435.935303,262.685760 
	C434.500916,261.612549 433.074768,260.991241 431.772888,260.172333 
	C428.088318,257.854614 426.034943,259.200409 424.532959,263.049316 
	C417.377197,263.019714 410.690002,262.983429 404.020874,262.539429 
	C405.440674,261.421265 406.790375,260.267578 408.255371,260.095490 
	C411.045746,259.767670 413.901947,260.000427 416.730835,260.000427 
	C416.869507,259.255615 417.008179,258.510803 417.146881,257.765991 
	C415.353943,257.178833 413.552765,256.074310 411.769684,256.102875 
	C406.675659,256.184387 401.558502,256.500153 396.512054,257.180603 
	C393.909698,257.531525 390.573761,257.896912 390.535675,262.061951 
	C379.445923,262.051086 368.824829,261.991730 358.204529,262.057800 
	C356.468353,262.068604 354.735596,262.632477 352.559509,262.956421 
	C349.771454,262.939026 347.425201,262.905945 344.959991,262.502136 
	C344.305847,261.791931 343.788788,261.190277 343.232788,261.151794 
	C332.335297,260.398102 321.425323,259.813873 310.534302,258.980621 
	C298.330719,258.046936 286.135742,256.981476 273.951294,255.822815 
	C261.785492,254.665894 249.638519,253.312119 237.481232,252.064743 
	C228.936111,251.187988 220.375854,250.445969 211.843719,249.459076 
	C202.880707,248.422318 193.943130,247.165436 184.908844,245.621140 
	C179.602463,244.158981 174.395889,242.999741 169.148010,242.074860 
	C168.181442,241.904526 167.047577,242.683517 165.549332,243.025757 
	C155.923904,241.727921 146.726440,240.516525 137.561859,239.093277 
	C132.803314,238.354294 128.118896,237.141800 123.363655,236.374695 
	C122.333481,236.208511 121.143250,237.034592 119.687241,237.374527 
	C118.894127,237.354416 118.440514,237.363419 117.823227,237.024780 
	C114.081764,232.768906 109.323196,234.411560 104.931717,233.624969 
	C102.201294,232.542618 99.543907,231.820511 96.576294,231.099274 
	C95.852463,231.104721 95.438866,231.109314 94.884315,230.871002 
	C94.157784,230.772827 93.572197,230.917542 92.654694,231.055145 
	C91.880318,231.035202 91.437859,231.022369 90.958237,230.712524 
	C90.518188,230.094879 90.115311,229.774216 89.712418,229.453552 
	C89.472572,229.963058 89.232719,230.472565 88.590942,230.977142 
	C84.792168,230.314621 81.395325,229.657013 77.954742,228.677917 
	C77.261955,227.911606 76.612900,227.466751 75.559433,227.076141 
	C71.764030,227.401077 68.373039,227.671753 64.768547,227.710159 
	C61.648766,229.045486 61.469181,230.893631 63.995350,232.788574 
	C65.363457,233.814819 66.668190,234.925537 67.633583,236.046326 
	C66.626312,237.529358 65.986954,238.964233 65.347595,240.399124 
	C66.629410,240.930130 68.314171,242.253372 69.117950,241.844559 
	C71.591003,240.586700 73.727036,238.666245 76.374420,237.038940 
	C80.492752,238.057037 84.230698,239.039474 88.142761,240.318024 
	C92.329422,244.050339 96.975235,245.283005 102.154205,246.582733 
	C111.054619,248.816422 119.373634,253.366730 128.204727,257.157562 
	C128.648697,258.247009 128.824661,259.123505 129.015625,260.400024 
	C131.162308,264.688538 134.107056,263.175964 137.432281,262.000000 
	C140.258331,261.635864 142.653244,261.271698 145.407761,260.918793 
	C147.512192,261.618195 149.257019,262.306366 151.069641,263.352051 
	C151.607956,264.110535 152.014908,264.670624 152.558853,264.888062 
	C158.367203,267.210052 164.143921,269.634644 170.060150,271.646301 
	C171.476166,272.127808 173.376480,271.184967 175.479431,270.910339 
	C181.603485,272.268707 187.302124,273.613983 193.051056,275.303711 
	C194.952591,279.263275 198.050537,278.532562 201.497070,277.942352 
	C202.925690,277.946533 203.964676,277.953278 205.011719,278.335999 
	C205.875763,282.633270 208.142761,280.628113 210.499237,279.933624 
	C218.265579,281.629547 225.633224,283.313538 233.041840,285.350220 
	C236.493927,289.893188 237.679749,289.931519 241.391373,286.003235 
	C244.512070,286.699768 247.256638,287.352478 250.058517,288.351410 
	C253.190475,290.745392 256.025574,293.766327 259.399872,294.596954 
	C263.705872,295.656952 268.447418,294.947815 273.001129,295.428955 
	C273.157135,301.467712 273.595276,301.788361 278.830261,300.189362 
	C280.212219,299.767273 281.609650,299.395874 283.014282,299.396912 
	C284.494812,303.593689 287.043396,302.061218 289.418457,301.037659 
	C291.875061,299.979034 294.580780,298.414124 296.039337,302.016968 
	C297.643158,305.978485 300.525391,305.137512 303.458099,304.617950 
	C306.311462,304.112457 309.153168,303.541199 312.357239,303.155731 
	C318.233948,303.872162 323.748505,304.685883 329.276733,304.792694 
	C330.492493,304.816193 331.756805,302.328735 333.397461,300.990265 
	C334.859375,301.002808 335.922516,301.022675 337.023865,301.331299 
	C337.713287,301.724243 338.364532,301.828461 339.387512,301.942993 
	C340.502716,301.977692 341.246124,302.002075 342.046997,302.358765 
	C343.748871,303.080505 345.393250,303.469971 347.450867,303.876892 
	C349.243134,303.909851 350.622162,303.925354 352.043121,304.326385 
	C354.564850,312.999481 354.575867,313.274567 363.289307,312.925873 
	C372.795563,312.545410 382.291351,311.713470 391.760834,310.769196 
	C393.581238,310.587677 395.258942,308.975037 397.257202,308.013977 
	C397.681793,307.999512 397.851654,307.993652 398.249176,308.174286 
	C400.651123,307.913635 402.825409,307.466522 404.953064,307.398804 
	C404.358673,309.453613 403.810883,311.128998 403.219299,312.938232 
	C402.295990,312.757874 401.668793,312.608795 401.033386,312.515442 
	C395.728821,311.735809 394.082336,313.298279 393.739716,318.427551 
	C393.657593,319.657013 391.783783,321.444153 390.454224,321.747192 
	C388.657562,322.156677 386.596619,321.318817 384.638275,321.124878 
	C380.748810,320.739746 377.243561,320.734528 376.781921,326.150879 
	C376.691986,327.206024 374.293335,327.997681 373.070312,329.040710 
	C371.701691,330.207916 369.305084,331.668060 369.439758,332.738525 
	C369.628296,334.236969 371.553345,335.802551 373.096802,336.752106 
	C374.276459,337.477875 376.016449,337.292938 377.507202,337.513092 
	C377.430847,338.146362 377.354492,338.779663 377.278137,339.412933 
	C374.454010,339.119202 371.624451,338.869415 368.807159,338.520386 
	C365.492126,338.109680 361.906006,336.315674 360.643372,341.813751 
	C360.330994,343.174072 357.555908,344.669647 355.782806,344.854309 
	C351.508911,345.299408 347.108246,344.624451 342.860657,345.180267 
	C340.900879,345.436707 339.080566,347.408325 337.459839,348.889771 
	C337.222992,349.106232 338.452881,350.927399 338.794861,352.290283 
	C338.058319,357.380890 337.530975,362.187317 336.922729,367.365662 
	C335.696198,368.163696 334.447754,368.424774 333.431366,369.058228 
	C332.192566,369.830231 330.326233,370.793945 330.165009,371.886353 
	C329.509949,376.324921 327.073090,377.657562 322.599548,376.999115 
	C318.096497,377.334412 313.993835,377.670593 309.867371,377.802124 
	C309.843536,377.597443 309.872101,377.186310 309.916809,376.743103 
	C309.633087,373.300720 311.291199,369.596039 306.835297,367.751831 
	C306.091034,367.351166 305.525146,367.198090 304.979614,367.023743 
	C305.000000,367.002441 304.954773,367.038483 304.993988,366.639343 
	C304.848694,364.936920 305.043030,363.406769 304.419891,362.366119 
	C299.312683,353.837006 287.808624,348.139160 277.903503,349.508972 
	C276.350922,348.219849 274.968597,347.014130 273.360901,346.376617 
	C268.631073,344.500977 265.334351,346.192047 264.847534,350.582214 
	C263.409180,351.029236 262.259216,351.272949 260.974609,351.201996 
	C259.959869,351.669037 259.079773,352.450775 257.988312,353.316528 
	C257.776947,353.400574 257.328369,353.476044 256.973267,353.322235 
	C255.717590,353.743103 254.817017,354.317780 253.755157,355.149292 
	C252.798035,356.010284 252.002182,356.614380 250.847778,357.153931 
	C249.342529,358.408905 248.195831,359.728424 247.024567,361.023987 
	C247.000000,361.000000 247.048462,361.048706 246.695984,361.048584 
	C245.646561,362.097076 244.949615,363.145721 244.152924,364.192322 
	C244.053177,364.190277 243.996857,363.998901 243.664230,363.993103 
	C242.888016,363.990631 242.444412,363.993958 242.000900,363.625732 
	C242.001862,362.511108 242.002747,361.768066 242.258423,360.804749 
	C242.338730,359.385895 242.164261,358.187317 241.988495,356.598450 
	C240.951019,355.179688 239.914825,354.151215 238.939316,353.061951 
	C239.000000,353.001221 238.878372,353.121307 238.901337,352.787170 
	C237.950394,351.978790 236.976486,351.504517 235.539154,351.032349 
	C231.048080,351.019714 227.020477,351.004913 222.769592,350.718597 
	C219.146072,349.477325 217.438339,350.838409 216.980225,354.455872 
	C215.219543,356.456329 213.729172,358.293518 211.852478,360.096436 
	C208.202301,363.441467 204.738007,366.653381 201.733765,370.249573 
	C199.005081,373.515930 196.776886,377.200378 193.985016,381.190826 
	C192.910706,370.057983 191.944824,360.048553 191.151825,349.702789 
	C191.754150,347.544861 192.183594,345.723236 192.613037,343.901611 
	C190.209854,343.601746 187.806686,343.301910 185.403503,343.002075 
	C185.215134,343.467041 185.026779,343.932037 184.838409,344.397034 
	C186.226074,346.259888 187.613754,348.122742 188.763718,350.118500 
	C188.337280,350.555817 188.148544,350.860199 187.631439,351.149536 
	C186.875824,351.039825 186.448593,350.945160 185.934357,350.499237 
	C184.230774,349.765015 182.614166,349.382050 180.926727,348.638916 
	C174.374985,344.572906 167.295883,346.822601 165.694977,353.159851 
	C164.927261,354.546478 164.463943,355.773254 163.860504,357.319946 
	C163.480255,358.759949 163.240128,359.879974 163.000092,361.415833 
	C162.667130,364.220703 162.334091,366.609741 161.684662,369.170074 
	C160.911896,370.894684 160.455505,372.447998 159.748489,374.203064 
	C159.998779,375.269897 160.499695,376.134949 161.333832,377.000000 
	C162.111374,377.000000 162.555695,377.000000 163.000061,377.426575 
	C163.842392,381.637299 161.993729,384.099976 158.610260,385.952148 
	C156.142654,385.603546 154.064148,385.302795 151.839783,384.702271 
	C151.346863,383.112213 151.290665,381.631531 150.595337,380.569153 
	C148.878113,377.945435 146.867935,375.513428 144.908005,372.697144 
	C144.790268,371.763794 144.740051,371.137634 144.781631,370.089355 
	C143.947601,368.435242 143.021774,367.203247 142.002014,365.661194 
	C141.877640,364.921295 141.847183,364.491486 142.115143,363.855225 
	C141.344345,359.619354 140.359222,355.563782 139.125320,351.585358 
	C138.913269,350.901642 137.680450,350.188232 136.853607,350.099518 
	C132.563736,349.639221 128.196213,348.797760 123.964874,349.209778 
	C119.781502,349.617065 114.216965,349.672150 115.428627,356.761078 
	C115.519798,357.294403 114.928223,357.910034 114.836983,358.514984 
	C114.463005,360.994812 114.159950,363.485352 113.845703,366.303040 
	C113.759201,367.062286 113.657791,367.490479 113.316444,367.969788 
	C112.713982,368.341248 112.351456,368.661560 111.728821,369.199646 
	C111.429398,370.071747 111.390083,370.725983 111.297043,371.700623 
	C111.075874,372.648193 110.908440,373.275360 110.418640,374.001801 
	C107.845505,378.893036 105.537689,383.660065 103.414932,388.508087 
	C103.144272,389.126221 103.839615,390.167328 104.078026,391.334747 
	C104.001022,392.080994 103.933434,392.504974 103.535767,392.977692 
	C102.528816,394.695099 101.851959,396.363739 101.132553,398.398041 
	C100.982399,399.491730 100.874794,400.219757 100.498230,401.125641 
	C99.224785,403.101196 95.590065,403.882385 98.011169,407.327423 
	C97.922112,408.063049 97.846001,408.480347 97.449547,408.993195 
	C95.541756,413.194092 93.954315,417.299377 92.309723,421.669556 
	C92.132690,422.275024 92.012810,422.615631 91.607544,422.993347 
	C91.261215,423.694092 91.200272,424.357666 91.107025,425.341187 
	C91.004578,426.083771 90.934441,426.506439 90.523346,426.993164 
	C87.727760,428.435638 85.763329,429.983582 88.075325,433.341827 
	C87.968445,434.078766 87.904427,434.499298 87.480621,434.991577 
	C83.234016,437.512329 83.044815,440.093719 85.859962,443.295532 
	C90.699730,448.799927 96.811050,446.343994 102.265831,445.816589 
	C109.416901,445.125214 111.835396,439.319550 113.413612,433.000000 
	C115.533432,432.740814 117.235733,432.481598 119.758972,432.097412 
	C117.530457,430.147369 116.247070,429.024353 115.122612,427.567505 
	C115.408607,426.019562 115.535660,424.805481 115.956024,423.418640 
	C117.146439,423.198761 118.043549,423.151611 118.962959,423.347992 
	C119.153915,423.845581 119.322571,424.099609 119.491211,424.353668 
	C119.683609,423.946594 119.876007,423.539490 120.529060,423.086060 
	C125.991310,423.129578 130.992905,423.219421 136.001404,423.607727 
	C136.675354,424.266418 137.342407,424.626678 138.256836,425.003906 
	C138.504211,425.020905 138.996017,425.084564 139.003860,425.457825 
	C139.729126,429.567444 140.053406,433.446564 141.335327,436.978058 
	C141.945816,438.659851 144.378738,439.680084 146.151138,441.296661 
	C147.305893,441.914154 148.295624,442.234985 149.299850,442.616577 
	C149.314346,442.677399 149.216339,442.755066 149.143768,443.198975 
	C149.668915,445.384674 150.266632,447.126404 150.864349,448.868164 
	C151.586319,447.872864 152.308289,446.877563 153.456177,445.899292 
	C155.589294,445.926025 157.296448,445.935791 159.256805,446.237671 
	C165.348740,446.322479 171.187500,446.115173 177.465561,445.921326 
	C180.254562,445.922028 182.604279,445.909241 185.161469,446.172485 
	C188.437393,446.957947 190.794510,446.814575 188.883270,442.419312 
	C189.348648,441.779938 189.746704,441.413666 190.500122,441.034485 
	C191.570114,441.013947 192.284760,441.006378 193.465881,440.999237 
	C198.955307,440.665649 203.978271,440.331604 209.445068,439.998108 
	C212.257004,439.999115 214.625092,439.999542 217.039551,440.331543 
	C218.391327,442.104645 219.696732,443.546265 220.999054,445.399780 
	C218.618347,453.678406 213.634430,446.222229 210.047775,447.695892 
	C209.826218,448.014496 209.410172,448.377991 209.292267,448.820892 
	C208.601898,451.414124 208.785751,455.130127 207.124863,456.340240 
	C205.228882,457.721619 201.711502,456.883636 198.898163,456.996124 
	C198.066742,457.029327 197.050354,456.714050 196.439362,457.085632 
	C195.193634,457.843231 193.175507,458.999847 193.213272,459.920807 
	C193.418243,464.917297 190.480789,465.259644 186.749863,465.026093 
	C184.594391,464.891174 182.366653,464.732056 180.279816,465.139160 
	C179.103683,465.368622 177.195526,466.785736 177.244690,467.565186 
	C177.600769,473.211487 174.078583,473.257996 170.107758,473.019562 
	C168.283096,472.910034 166.312668,472.601166 164.655319,473.140991 
	C163.261642,473.594879 161.211166,475.145905 161.209885,476.213348 
	C161.204758,480.464386 159.001175,481.285980 155.462555,481.026306 
	C153.475220,480.880493 151.465714,481.066528 149.470810,480.987091 
	C146.723572,480.877716 144.835785,481.688324 145.012177,484.854492 
	C145.201874,488.259308 143.311935,489.161224 140.319489,489.020691 
	C137.993576,488.911438 135.655426,489.075439 133.327896,488.984894 
	C130.478348,488.874054 128.830688,489.925201 129.001144,492.997925 
	C129.195969,496.510162 127.051674,497.075958 124.176750,497.024567 
	C119.183105,496.935303 114.169098,496.771210 109.202293,497.148010 
	C107.746368,497.258484 105.235718,499.096680 105.239738,500.135101 
	C105.257927,504.817535 102.605988,505.245667 99.040894,505.022675 
	C97.050697,504.898163 94.871544,504.516052 93.099854,505.150299 
	C91.506454,505.720734 89.310921,507.438751 89.204918,508.793274 
	C88.910583,512.554504 86.952843,513.097595 83.897285,513.028809 
	C79.070999,512.920105 74.215897,512.729126 69.425713,513.159668 
	C67.871994,513.299377 65.302498,515.213562 65.236809,516.426514 
	C65.023979,520.355957 63.130756,521.253967 59.760422,521.024536 
	C57.771557,520.889099 55.762302,521.076721 53.768635,520.982788 
	C50.849117,520.845215 48.799961,521.585449 48.994572,525.057190 
	C49.168819,528.165649 47.425507,529.066711 44.617252,529.021301 
	C39.456871,528.937866 34.271103,528.734131 29.146833,529.173889 
	C27.677248,529.299988 25.316143,531.380310 25.211637,532.709778 
	C24.921421,536.401611 23.108288,537.090759 19.981405,537.033875 
	C13.656159,536.918701 7.327343,536.999817 1.000000,537.000000 
M85.365257,394.464630 
	C85.901772,393.306732 86.438286,392.148804 86.948875,390.213013 
	C86.809746,389.716705 86.670616,389.220367 86.728020,388.687378 
	C86.728020,388.687378 86.803772,388.872406 87.593575,388.957306 
	C91.725945,388.781342 91.200439,385.375580 91.992088,382.086060 
	C92.928406,380.162964 93.942513,378.273163 94.783798,376.309357 
	C96.246056,372.895966 97.601578,369.436829 99.067177,365.550568 
	C99.067177,365.550568 99.196999,365.118561 99.868134,365.019653 
	C101.577675,362.679657 103.287224,360.339661 105.693542,357.887115 
	C106.707069,355.830536 109.093193,352.706085 108.478630,351.916107 
	C106.580002,349.475616 103.727470,347.445190 100.832291,346.298004 
	C99.861977,345.913544 97.650139,348.662323 95.106308,350.030548 
	C91.535530,349.749512 87.964752,349.468475 83.305275,349.101746 
	C80.415459,353.585297 76.641678,359.261841 73.067299,365.061279 
	C71.280876,367.959778 69.858643,371.082733 67.437805,374.026886 
	C65.240295,374.460388 62.258827,370.704742 60.154411,375.064240 
	C56.419682,368.230011 52.566441,361.456879 48.998730,354.536560 
	C47.131004,350.913666 44.495296,349.028748 40.431225,349.058075 
	C36.295097,349.087952 32.158508,349.053833 27.636648,348.536743 
	C25.287445,350.431305 22.938242,352.325867 20.303293,354.450897 
	C21.777224,355.860870 22.893618,356.928802 24.074627,358.422546 
	C24.074627,358.422546 24.091816,358.852844 24.102129,359.522064 
	C24.726498,360.016693 25.350866,360.511322 26.095457,361.716003 
	C27.117785,364.399109 28.140112,367.082184 29.112474,370.513000 
	C30.404699,372.009705 31.696926,373.506378 33.057087,375.444611 
	C33.057087,375.444611 33.077782,375.890900 33.030773,376.569153 
	C33.697231,377.020569 34.363689,377.471985 35.538921,378.502258 
	C37.042755,382.630951 38.546585,386.759644 40.017029,391.735504 
	C40.731697,393.472473 41.446365,395.209442 42.738602,397.268280 
	C45.161289,400.841583 47.583977,404.414917 50.009163,408.691162 
	C50.653320,409.465424 51.297474,410.239655 51.994755,411.948151 
	C51.973637,419.626740 51.952522,427.305298 51.256371,435.124542 
	C49.370426,436.802551 49.272923,438.157440 52.078403,439.644501 
	C52.700451,440.108643 53.322495,440.572784 53.998749,441.001221 
	C53.998749,441.001221 53.961460,441.054260 53.950489,441.661407 
	C54.290005,442.144196 54.629520,442.626984 55.007736,443.875061 
	C56.677685,444.907715 58.259697,446.569000 60.039268,446.817932 
	C62.398735,447.147980 64.913498,446.116486 67.359840,446.125519 
	C73.791206,446.149292 76.135994,444.222015 76.882774,437.059692 
	C76.888115,435.756653 76.893456,434.453644 76.973305,432.187622 
	C76.641869,426.456390 76.310425,420.725128 75.961136,414.198181 
	C76.339706,412.142120 76.718285,410.086060 77.803055,407.999146 
	C78.492149,407.265869 79.181236,406.532562 79.885567,404.991852 
	C79.964470,403.392639 80.043381,401.793396 80.134422,400.120819 
	C80.134422,400.120819 80.196655,400.080078 80.885414,399.978790 
	C81.437469,398.857452 81.989532,397.736115 82.686165,395.990723 
	C82.855812,395.373169 83.025459,394.755646 83.797218,394.118622 
	C84.197685,394.082520 84.598152,394.046387 85.365257,394.464630 
M371.991760,102.960220 
	C371.991760,102.960220 371.943298,102.933525 372.727722,102.977287 
	C373.981567,102.869576 375.235413,102.761864 377.266113,102.587418 
	C376.232086,101.003433 375.567352,99.985123 374.970093,98.373398 
	C375.283173,97.891220 375.596283,97.409042 376.624237,96.909348 
	C378.380981,95.450485 380.416504,94.219505 381.834991,92.484138 
	C384.573181,89.134209 383.000275,84.454819 379.003448,84.123100 
	C374.888916,83.781624 370.647034,83.604599 366.600464,84.241852 
	C363.274323,84.765640 360.152618,86.587410 356.166809,87.915428 
	C355.128448,87.904381 354.090088,87.893333 352.908783,87.251556 
	C351.924866,87.470291 350.940918,87.689026 349.217621,87.950089 
	C348.477264,87.957642 347.736877,87.965202 346.449890,87.419197 
	C343.298523,87.614235 340.147156,87.809265 337.078705,87.280739 
	C339.411560,85.549706 341.744446,83.818672 344.717102,82.069099 
	C345.143585,82.054253 345.570038,82.039413 346.434631,82.528366 
	C347.966248,81.696037 349.497864,80.863701 351.664673,79.973846 
	C352.445160,79.648209 353.225616,79.322578 354.858002,78.998688 
	C358.107239,79.568817 361.343933,80.467010 364.610229,80.592125 
	C366.738495,80.673660 368.909790,79.631264 371.706390,79.048393 
	C372.137482,79.039162 372.568573,79.029930 373.567078,79.580429 
	C377.833038,80.150108 382.112396,80.770515 385.946320,76.268845 
	C388.309540,76.233841 390.672791,76.198845 393.549652,76.663254 
	C394.999908,76.742821 396.450165,76.822388 398.664093,76.879501 
	C402.133057,76.566841 405.602051,76.254181 409.536499,75.969780 
	C409.536499,75.969780 410.002136,75.995880 410.061981,76.578094 
	C410.708771,76.715622 411.355591,76.853149 412.836243,76.998741 
	C414.226105,77.005005 415.615936,77.011261 417.415039,77.509140 
	C418.301666,76.845467 419.188293,76.181793 420.853760,75.295998 
	C423.568207,75.530960 426.282623,75.765915 429.129333,76.716438 
	C433.625397,80.538086 438.633148,78.382500 442.164978,76.103867 
	C444.291992,74.731590 444.230316,69.729309 444.800385,66.299782 
	C445.024567,64.951035 444.289978,63.442932 444.543457,61.494289 
	C444.888489,60.100128 445.233521,58.705967 445.670685,56.939373 
	C447.331757,57.263588 448.739594,57.765450 450.142761,57.752583 
	C451.757324,57.737770 453.419586,57.430664 454.961975,56.939217 
	C456.019196,56.602341 456.907013,55.733932 457.870544,55.103107 
	C457.082703,54.176739 456.448944,52.702446 455.477417,52.429264 
	C452.821198,51.682346 450.028290,51.112370 447.279419,51.065441 
	C440.565552,50.950817 439.331879,52.653290 440.114960,58.996422 
	C436.092102,59.992542 432.069244,60.988659 427.294434,62.004303 
	C426.195526,62.376240 425.096588,62.748177 423.422516,63.328915 
	C422.279663,64.157562 421.136780,64.986214 420.026672,65.445389 
	C420.026672,65.445389 420.027222,65.074463 419.816772,64.619446 
	C419.625549,64.329880 419.364960,64.131615 418.404083,63.924820 
	C417.259186,63.656094 416.114258,63.387367 414.157440,63.034809 
	C409.795715,63.059570 405.433990,63.084328 400.542816,62.513832 
	C397.652344,62.175537 394.268555,60.316578 392.170135,65.002312 
	C387.115448,66.329033 382.060791,67.655762 376.493652,68.440918 
	C374.996185,68.296875 373.498688,68.152832 371.976440,67.233505 
	C369.881409,63.688747 366.745178,63.589344 363.400726,64.862396 
	C359.196716,66.462639 359.853241,69.682381 360.217010,72.980461 
	C357.817413,72.990326 355.417786,73.000183 352.371735,72.680557 
	C349.197021,70.253746 346.859711,69.762993 346.997894,75.498726 
	C346.997894,75.498726 346.988586,75.993958 346.175049,75.999756 
	C343.116180,77.331299 340.057312,78.662834 337.000000,79.999695 
	C337.000000,79.999695 336.993896,79.998466 336.267731,80.086090 
	C331.628113,82.657364 331.730865,83.758865 336.783142,88.524345 
	C336.534180,89.039307 336.285217,89.554268 335.595947,90.127823 
	C335.595947,90.127823 335.152161,90.145569 334.417389,90.062477 
	C333.621399,90.721146 332.825439,91.379814 331.799744,92.463799 
	C331.598785,92.728699 331.341736,92.917023 330.261505,93.006485 
	C328.498230,94.327766 326.734955,95.649048 324.262817,96.842430 
	C322.366821,94.901260 320.454773,91.309555 318.577820,91.327805 
	C312.903229,91.382965 306.907776,91.959908 301.655701,93.958435 
	C294.102692,96.832504 286.933563,100.858086 279.892059,104.899948 
	C269.643921,110.782448 259.534027,116.928970 249.604446,123.333900 
	C244.226334,126.802979 239.159576,130.826447 234.301376,135.005554 
	C230.870941,137.956467 228.026688,141.588821 224.172592,144.972122 
	C221.796356,146.764374 219.094971,148.266754 217.152420,150.445633 
	C215.738464,152.031616 215.326447,154.510849 214.467728,156.591843 
	C216.870377,156.682556 219.360779,157.225815 221.659393,156.780045 
	C228.855515,155.384491 235.952148,153.481262 243.139267,152.032379 
	C249.431183,150.763977 255.828018,150.002411 262.093750,148.629929 
	C263.286774,148.368591 264.083893,146.299667 265.753723,144.952423 
	C270.608643,140.322983 275.360168,135.578903 280.341034,131.089203 
	C289.303040,123.010971 298.368774,115.046532 307.467010,107.121635 
	C309.896942,105.005066 310.986206,106.206978 310.744568,109.530312 
	C310.158447,110.016693 309.572327,110.503075 308.220276,110.988640 
	C307.175995,111.337357 305.823547,111.382935 305.135651,112.082230 
	C299.059967,118.258461 292.917267,124.387421 287.187500,130.877426 
	C284.304077,134.143448 282.178589,138.092850 279.846497,141.818436 
	C279.425323,142.491287 279.666016,143.578430 279.601166,144.474304 
	C280.424774,144.256439 281.248413,144.038589 282.925079,143.945709 
	C287.614685,143.952820 292.304291,143.959915 297.580933,144.566132 
	C303.103424,144.703629 308.629761,144.986969 314.144897,144.843033 
	C315.127502,144.817383 316.063843,143.019180 317.814087,142.007782 
	C318.872742,142.011078 319.931396,142.014374 321.013763,142.762634 
	C321.720215,143.385818 322.490143,144.599609 323.122314,144.531662 
	C326.124207,144.209030 329.116333,143.651840 332.054108,142.939499 
	C333.449921,142.601028 334.716492,141.729645 336.866364,141.039810 
	C338.245148,141.034485 339.623962,141.029175 341.485291,141.558960 
	C348.697327,143.332504 351.039032,142.033890 351.877716,135.395966 
	C351.877716,135.395966 351.935516,134.969757 352.568451,134.705215 
	C353.665344,132.749924 354.762207,130.794617 355.912109,128.126373 
	C357.249420,125.737785 358.586731,123.349190 360.721405,120.964905 
	C361.474579,119.296631 362.227722,117.628349 363.476959,115.468620 
	C364.981445,112.975227 366.485962,110.481842 368.763733,107.987457 
	C372.242981,107.841324 372.054199,105.379044 371.991760,102.960220 
M161.286194,224.567734 
	C162.183029,225.018845 163.079865,225.469955 164.427917,226.150543 
	C164.633301,226.413132 164.838684,226.675705 165.046204,227.772171 
	C168.650757,232.074768 172.893661,229.880402 177.890030,228.976669 
	C186.596725,229.911255 195.399826,230.327545 203.983459,231.923859 
	C212.857086,233.574097 214.196716,230.897568 217.249420,225.123489 
	C220.391907,219.179581 222.859863,212.877594 225.588608,206.717056 
	C226.725098,204.151260 227.772690,201.546097 228.981705,198.238235 
	C228.996277,198.074188 228.957230,197.878250 229.033112,197.750656 
	C233.029587,191.028946 237.016541,184.301270 241.085587,177.623688 
	C241.348251,177.192657 242.252151,177.152359 243.503357,176.713440 
	C243.965607,175.754837 244.427856,174.796234 244.915802,173.076691 
	C244.912537,172.062012 244.909256,171.047348 245.428574,169.642120 
	C245.098450,168.846527 244.859924,167.424988 244.422729,167.361008 
	C242.246704,167.042572 239.997589,166.845688 237.812439,167.038788 
	C231.384155,167.606827 224.954559,168.247086 218.572769,169.181931 
	C217.270355,169.372711 216.174164,170.971161 214.192932,171.979446 
	C213.137405,172.017441 212.081879,172.055435 210.727509,171.603500 
	C210.137115,171.790421 209.546722,171.977341 208.366959,172.165619 
	C207.974228,172.154434 207.581482,172.143234 206.382339,172.043182 
	C204.591400,173.037979 202.800446,174.032761 200.290924,175.055695 
	C199.571869,175.064606 198.852798,175.073502 197.457733,175.099670 
	C196.978897,175.745651 196.500061,176.391632 195.573837,177.563843 
	C191.064316,181.049286 186.554794,184.534714 181.341232,188.076874 
	C179.938644,190.115997 178.536057,192.155106 177.102097,194.878021 
	C176.074203,196.255814 175.046310,197.633621 173.289749,199.011642 
	C171.904068,200.739700 170.518402,202.467743 168.832138,204.743500 
	C168.226501,205.830734 167.620865,206.917984 166.459717,208.392654 
	C164.299484,212.926788 162.139252,217.460907 159.575516,222.190018 
	C159.311035,222.366409 159.156616,222.612000 159.359207,223.465439 
	C159.909546,223.640091 160.459869,223.814758 161.286194,224.567734 
M413.171021,85.084618 
	C411.781433,85.056465 410.391846,85.028320 408.831238,84.226540 
	C402.984558,81.450188 395.345825,83.707443 392.524902,89.014565 
	C389.319580,95.044731 386.154999,101.098495 382.825287,107.059662 
	C381.467590,109.490334 379.378601,111.546753 378.228760,114.051819 
	C376.161072,118.556488 374.915710,123.457909 372.664734,127.854614 
	C369.249878,134.524734 370.802429,141.603714 376.657318,141.782562 
	C387.254517,142.106262 397.882568,141.633743 408.491302,141.292801 
	C416.094086,141.048477 423.727814,139.826523 431.266968,140.307190 
	C435.457275,140.574341 437.332642,139.026443 438.345459,135.979675 
	C439.340637,132.985886 441.816132,129.941757 439.172791,126.389297 
	C438.538391,125.536697 439.026764,123.799973 439.101929,122.475708 
	C439.720306,111.579407 440.413513,100.687088 440.974121,89.787903 
	C441.204987,85.298943 438.909943,82.998367 434.378662,83.000542 
	C428.890198,83.003174 423.396942,82.878853 417.916779,83.092560 
	C416.587891,83.144386 415.302246,84.305962 413.171021,85.084618 
M384.392181,244.788727 
	C398.024597,245.090286 411.656006,245.464188 425.289917,245.661774 
	C431.741089,245.755264 432.563751,244.806808 432.584534,238.606415 
	C432.609070,231.279617 432.442047,223.946396 432.691650,216.627335 
	C433.274597,199.535492 434.033691,182.449432 434.776794,165.363449 
	C434.944366,161.510788 433.451477,159.284744 429.413971,159.291962 
	C410.097931,159.326553 390.779999,159.254532 371.467499,159.541687 
	C367.352142,159.602859 363.503937,160.739761 362.239990,166.108429 
	C358.814209,180.659531 354.601166,195.027390 351.276306,209.599335 
	C349.216858,218.625443 348.241211,227.896820 346.714783,237.047363 
	C345.912842,241.854858 347.007538,243.398117 352.023193,243.615479 
	C362.484863,244.068863 372.951996,244.396393 384.392181,244.788727 
M258.999146,171.638306 
	C256.685028,175.595581 254.070908,179.410416 252.112152,183.536377 
	C245.800140,196.832169 239.740082,210.247452 233.561264,223.606674 
	C230.776337,229.627960 232.578384,234.955536 237.986298,235.723648 
	C244.694397,236.676453 251.452255,237.311722 258.203461,237.919556 
	C266.279480,238.646698 274.369751,239.218781 282.456421,239.823593 
	C292.357727,240.564133 302.263336,241.247498 312.163727,241.999649 
	C316.232452,242.308746 318.568481,239.922623 319.495667,236.545776 
	C320.971344,231.171295 321.738800,225.606812 322.991913,220.165573 
	C324.488953,213.665039 326.025574,207.165405 327.840363,200.748886 
	C329.257538,195.738174 331.151428,190.863510 332.786560,185.912888 
	C334.737183,180.007050 336.494720,174.033066 338.605347,168.185837 
	C340.295319,163.503937 338.773682,160.881638 333.705475,161.046051 
	C315.298737,161.643143 296.873932,162.034683 278.504150,163.239349 
	C271.621368,163.690720 263.511169,162.942017 258.999146,171.638306 
M329.234039,73.778732 
	C327.209991,78.740555 327.403412,79.406914 331.368530,80.655693 
	C332.399048,72.867538 341.730103,77.417320 344.641113,71.327469 
	C339.358368,70.585487 334.490448,70.917870 329.234039,73.778732 
M235.420532,342.001221 
	C233.085007,342.431641 230.749481,342.862061 228.413971,343.292480 
	C231.699799,345.268005 234.681320,346.242401 237.735046,346.859406 
	C238.625504,347.039307 239.721924,346.199799 240.723938,345.827545 
	C240.200165,344.766205 239.889084,343.480560 239.083359,342.716583 
	C238.471115,342.136047 237.229813,342.218933 235.420532,342.001221 
M46.985336,342.173218 
	C44.157398,342.540161 41.329460,342.907104 38.501526,343.274048 
	C38.555805,343.743134 38.610085,344.212219 38.664364,344.681305 
	C41.249802,345.114838 43.827629,345.835175 46.422741,345.904022 
	C49.203213,345.977783 49.518303,344.507660 46.985336,342.173218 
M458.445770,155.709885 
	C458.711792,155.988922 458.977814,156.267944 459.243835,156.546967 
	C459.359375,156.436737 459.474884,156.326492 459.590393,156.216248 
	C459.324066,155.937195 459.057709,155.658127 458.445770,155.709885 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M323.000000,377.000000 
	C327.073090,377.657562 329.509949,376.324921 330.165009,371.886353 
	C330.326233,370.793945 332.192566,369.830231 333.431366,369.058228 
	C334.447754,368.424774 335.696198,368.163696 337.170807,367.367462 
	C337.499817,366.997345 337.996063,366.998993 338.212891,366.890381 
	C338.700653,366.584564 338.888580,366.326813 339.223694,365.723755 
	C339.880096,365.205139 340.306274,364.971283 340.892090,365.131805 
	C341.085999,368.352539 341.140564,371.178833 341.151276,374.005310 
	C341.233154,395.625824 341.306458,417.246338 341.130859,439.117462 
	C340.751617,439.567200 340.623932,439.766357 340.298035,440.005920 
	C340.293518,440.403076 340.487183,440.759857 340.750610,441.061523 
	C340.820343,441.006409 340.998108,441.009155 340.991333,441.314667 
	C341.358734,442.335724 341.732971,443.051239 342.161469,444.066528 
	C342.478821,444.909637 342.741852,445.452972 342.676453,446.049255 
	C342.579285,447.324707 342.866486,449.617371 343.033386,449.608643 
	C345.373260,449.486359 348.135193,449.629303 348.428467,446.012939 
	C350.571442,446.014374 352.285950,446.019226 354.018433,446.435364 
	C355.264008,447.563995 356.602020,449.026184 357.699066,448.863434 
	C361.468597,448.304321 365.846619,451.075409 369.428619,446.993530 
	C371.569397,446.990845 373.282135,446.988464 375.041412,447.359253 
	C378.118744,451.925629 383.623383,452.144135 387.930115,448.021179 
	C387.333008,447.677734 386.837402,447.185181 386.284424,447.109009 
	C383.196869,446.683685 380.095459,446.359039 376.997864,445.655151 
	C376.978516,444.850128 376.960083,444.390045 376.965820,443.934113 
	C376.989929,443.938293 376.947052,443.960266 377.162354,443.890015 
	C377.649170,443.671814 377.861237,443.463318 378.050720,443.040222 
	C378.178650,442.536896 378.086792,442.268127 378.152649,441.929871 
	C380.018768,441.730469 381.544281,441.681061 383.114044,441.945190 
	C384.659424,442.432526 386.160583,442.606323 388.051819,442.852295 
	C389.769958,443.410736 391.097992,443.896973 392.762299,444.506317 
	C392.836578,442.856964 392.902252,441.398834 393.084412,439.730530 
	C393.407074,439.259735 393.670044,439.084229 394.311096,438.954529 
	C395.129791,438.288147 395.627319,437.661102 396.494080,437.024780 
	C398.179688,437.462921 399.496033,437.910339 401.301758,438.524078 
	C399.649139,440.089844 398.413940,440.823517 397.866180,441.905273 
	C397.347107,442.930359 397.252838,444.398560 397.549194,445.519226 
	C397.715118,446.146790 399.053497,446.464264 399.863586,446.921478 
	C400.360657,446.036011 400.693695,444.975037 401.400787,444.314392 
	C402.109436,443.652283 403.191406,443.389771 404.317139,443.052429 
	C404.783356,443.340790 404.957611,443.588348 404.997864,444.263123 
	C405.150146,446.245728 404.804474,448.651489 405.696594,449.268921 
	C406.735138,449.987732 408.920441,449.356506 410.485718,448.895477 
	C411.468262,448.606079 412.225647,447.552185 413.469208,446.855164 
	C416.522064,446.890503 419.189789,446.912018 422.212067,446.926514 
	C423.377716,445.949707 424.188812,444.979919 425.236023,443.990234 
	C425.472137,443.970367 425.946045,443.966888 426.234070,444.093140 
	C427.348053,444.813354 428.174042,445.407288 429.005798,446.353821 
	C429.673981,447.470551 430.336365,448.234650 431.393616,448.990662 
	C436.130646,448.289001 435.514954,445.172028 434.312622,442.648315 
	C433.673981,441.307770 431.410034,440.741516 430.060974,439.476135 
	C431.211090,434.415436 432.179962,429.704407 433.600250,424.995850 
	C438.700073,424.668762 443.348511,424.339172 448.397949,423.991211 
	C449.869110,423.988678 450.939209,424.004486 452.285156,424.299011 
	C454.723389,424.723419 456.885773,424.869110 459.213165,425.349731 
	C461.943756,432.068451 464.509338,438.452179 466.995331,445.211090 
	C466.537872,447.367279 461.127991,448.351868 465.586243,450.644073 
	C467.975159,451.872284 472.068970,452.086517 472.000000,447.003662 
	C473.452576,447.088287 474.905151,447.172882 476.225708,447.249817 
	C475.409393,449.129944 474.261322,450.712158 474.081268,452.397736 
	C473.630219,456.620575 471.284729,458.036926 467.375366,458.025482 
	C462.946838,458.012543 458.893951,457.038696 456.513580,463.493774 
	C455.386200,466.551056 450.512756,469.649597 447.376343,472.744934 
	C445.150024,474.942047 442.939026,477.037598 440.752441,479.356323 
	C437.755737,482.534149 434.490143,485.546082 431.396179,488.737823 
	C429.258423,490.943115 426.148407,491.889099 424.799744,495.536682 
	C424.257599,497.002899 420.363281,497.711151 417.931030,497.887756 
	C413.964783,498.175720 409.682495,497.204315 409.257568,503.206543 
	C409.205322,503.945160 408.030518,505.147339 407.296936,505.209961 
	C400.603394,505.781647 402.115417,510.730988 401.671021,515.403564 
	C400.896973,515.889160 400.444672,515.797546 399.935516,515.354736 
	C397.972321,508.602905 392.663391,511.410309 388.706696,511.220764 
	C387.533630,511.164581 386.238983,513.647400 384.600464,514.995667 
	C383.132507,515.037903 382.064117,515.051208 380.938782,514.714111 
	C376.920410,513.909119 372.958984,513.454590 368.658325,512.851318 
	C365.256958,511.484375 362.733246,511.981323 360.753082,515.272827 
	C358.003693,515.363708 355.502472,515.181885 352.993591,514.616333 
	C351.839478,513.180298 349.877686,511.081604 349.674591,511.239868 
	C347.935791,512.594788 346.515533,514.358521 344.999939,516.360779 
	C344.996918,517.439514 344.994019,518.157410 344.576538,518.914856 
	C342.774109,518.964294 341.386200,518.974304 339.919800,518.616699 
	C336.119751,516.948853 332.379761,515.382080 328.602112,519.000000 
	C326.801880,519.686646 325.398346,520.373352 323.668030,521.081421 
	C322.904694,521.107849 322.468170,521.112793 321.987854,520.752930 
	C317.497650,513.403076 309.354309,514.152100 302.879272,511.442047 
	C302.259216,511.182526 300.882294,512.731201 300.005432,513.337646 
	C304.080444,516.179321 307.540222,518.592041 310.667511,521.150146 
	C308.205231,521.700195 306.020935,521.935120 303.972321,522.592712 
	C303.147614,522.857422 302.620239,524.048462 301.547302,524.870117 
	C299.757965,524.941162 298.378876,524.959961 297.002197,524.731018 
	C297.004608,524.483276 297.001221,523.987793 297.000671,523.574219 
	C297.844360,518.656982 294.179474,518.398804 291.624756,518.456848 
	C290.708374,518.477722 289.872589,522.049072 288.655884,524.060364 
	C287.870941,525.581482 287.430115,527.042175 286.598877,528.602966 
	C285.136261,528.751526 284.064026,528.800049 282.816467,528.623047 
	C282.092346,528.255554 281.543549,528.113586 280.824219,527.732361 
	C280.102417,527.326355 279.551208,527.159485 278.938568,526.639282 
	C274.699982,522.840515 272.885498,523.336914 268.667908,528.992432 
	C267.890106,528.998901 267.445679,529.000671 266.791901,528.742920 
	C265.720093,528.323120 264.857605,528.162781 263.705994,528.147583 
	C262.950012,529.211975 262.483185,530.131226 261.654083,531.102661 
	C259.865479,531.453064 258.439117,531.751404 256.872864,531.754028 
	C255.821732,530.970581 254.910492,530.482849 253.780334,529.749268 
	C252.150848,529.446655 250.727097,529.489075 249.333771,529.303223 
	C248.138962,529.143921 246.978973,528.723511 245.803574,528.418579 
	C245.869263,529.946533 245.934937,531.474487 245.999191,533.299438 
	C246.000580,533.990234 246.003387,534.383911 245.594208,534.838989 
	C243.797089,534.964905 242.411987,535.029480 241.020233,534.819336 
	C241.009460,534.361389 241.005341,534.178284 241.000793,533.582520 
	C240.124725,531.226868 239.519913,527.831421 238.323532,527.608337 
	C234.470276,526.889771 230.398926,527.340698 226.411667,527.340698 
	C226.358078,528.061768 226.304474,528.782837 226.250885,529.503967 
	C227.813110,530.010559 229.445175,530.368713 230.919510,531.062195 
	C232.683395,531.891846 234.312515,533.007996 235.742722,534.183777 
	C234.995102,535.500916 234.504745,536.634277 233.600403,537.824646 
	C231.794128,537.929199 230.401825,537.976807 228.777573,537.753296 
	C226.031067,536.387634 224.942535,537.353027 224.587524,539.978271 
	C223.136185,540.010254 222.079926,540.012756 220.713226,539.850952 
	C218.757645,535.492981 215.329437,533.544189 211.217392,532.635803 
	C208.162827,531.960938 206.970032,533.683350 207.370880,536.460266 
	C207.549316,537.696289 208.438751,538.829773 209.004761,540.383423 
	C209.003098,541.504761 209.002151,542.252380 208.582153,543.014282 
	C204.799301,543.391418 201.435486,543.754395 197.824677,543.823120 
	C194.592499,543.709106 191.564606,543.657532 188.645401,544.195618 
	C187.625732,544.383484 186.873947,546.024841 185.633575,547.044067 
	C182.511536,548.055481 179.755768,549.025330 177.036285,549.643127 
	C177.651581,545.418823 176.131790,543.786682 172.384491,544.344666 
	C164.253052,545.555481 156.133865,546.850952 147.995102,548.009277 
	C144.931503,548.445312 143.102020,550.180420 144.165436,553.008545 
	C145.480820,556.506714 144.110199,558.730103 141.683121,561.187988 
	C136.289368,563.249390 131.154984,565.002869 126.165520,567.101013 
	C124.230919,567.914368 122.624138,569.507446 120.563980,570.811279 
	C119.845512,570.891418 119.431091,570.905579 118.760574,570.663330 
	C113.002579,571.943115 107.500679,573.479309 101.997971,574.612915 
	C102.089432,570.637634 100.149925,570.587219 97.543793,571.525696 
	C97.702545,573.429443 97.850510,575.203857 97.641373,577.004028 
	C96.568558,577.049194 95.852852,577.068726 94.743286,577.058838 
	C92.898933,578.018799 91.448418,579.008179 89.637970,580.018433 
	C88.557037,580.048218 87.836044,580.057190 86.777313,580.082825 
	C85.972229,580.756470 85.504883,581.413391 84.790329,582.326904 
	C83.368759,582.745483 82.194412,582.907471 80.809334,582.796631 
	C77.578468,581.849731 75.668655,582.943604 74.611885,586.045471 
	C72.879059,586.086060 71.546204,586.113708 69.869339,586.128662 
	C69.019722,586.747375 68.514099,587.378784 67.855576,588.252686 
	C67.177513,588.690247 66.652359,588.885254 65.714928,589.052246 
	C62.812561,589.945679 60.322464,590.867126 57.216164,592.016602 
	C60.621967,595.346191 74.479393,596.044373 76.621025,593.969788 
	C77.005226,593.597656 76.897705,592.717834 77.412003,592.043152 
	C78.861824,591.979736 79.916870,591.945557 81.132492,592.148926 
	C81.868294,591.958923 82.443527,591.531494 83.387695,591.068726 
	C84.496971,590.998779 85.237305,590.964233 86.192627,591.204163 
	C88.279091,590.689026 90.150566,589.899414 92.457077,589.072998 
	C98.542236,588.325195 104.192360,587.614197 110.308235,586.937134 
	C113.807259,586.118591 117.645782,588.977417 120.351639,584.800537 
	C123.097733,584.381287 125.486847,584.131958 128.194824,584.036987 
	C130.349625,583.500488 132.185562,582.809692 134.459106,582.083069 
	C138.262756,581.695984 141.628845,581.344727 145.248199,581.271057 
	C146.299271,581.688904 147.109833,581.999084 147.892731,581.940369 
	C151.660416,581.658142 155.514694,581.674377 159.156067,580.813782 
	C162.541718,580.013672 165.697586,578.241272 169.235107,576.623535 
	C173.016327,575.915283 176.517075,575.480347 180.304321,575.325317 
	C184.992584,576.160034 189.407593,576.784485 193.479370,573.187378 
	C200.615173,572.452026 207.300690,571.767883 214.029953,571.367981 
	C214.393829,571.954773 214.713959,572.257385 215.034119,572.559937 
	C215.365494,572.075134 215.696838,571.590332 216.453888,571.081726 
	C218.586060,571.040833 220.292557,571.023682 222.266602,571.286865 
	C226.815414,571.314514 231.395706,572.504578 235.461166,568.956665 
	C240.182709,568.896301 244.442398,568.862488 249.126923,568.884521 
	C251.709244,568.021179 253.866745,567.101990 256.481079,566.114136 
	C260.942932,565.991089 264.947937,565.936646 269.215576,566.187012 
	C272.013153,566.249268 274.548126,566.006836 277.353241,565.758179 
	C277.982605,565.776794 278.341858,565.801636 279.187988,565.884766 
	C282.486786,565.959290 285.338470,566.251282 288.090912,565.854675 
	C289.176422,565.698242 290.040649,564.006104 291.469482,562.986450 
	C296.294464,562.941833 300.655609,562.924072 304.945862,563.277161 
	C303.438141,564.410950 302.001282,565.173889 300.781311,565.821655 
	C304.233063,567.354431 307.706055,568.896667 311.225922,570.459717 
	C314.208435,566.989990 309.985870,565.369751 309.397705,562.843201 
	C313.532227,562.473755 317.246063,562.132812 320.959900,561.791870 
	C325.412048,562.527710 329.847778,563.397400 334.322876,563.946228 
	C336.770081,564.246399 339.300598,563.836670 341.759735,564.083862 
	C342.831818,564.191589 343.818939,565.144470 344.844604,565.713562 
	C343.956970,566.728455 343.128418,568.547485 342.171417,568.617676 
	C338.245483,568.906006 334.177368,569.204773 330.355011,568.479858 
	C325.263855,567.514343 320.626526,566.368591 317.508575,572.098877 
	C317.146210,572.764771 315.478943,572.910095 314.401123,572.959595 
	C311.804962,573.078857 309.199646,572.998169 305.921021,572.998169 
	C306.358215,575.596497 306.672760,577.466003 306.546295,579.812012 
	C303.066528,580.283630 300.027740,580.278809 296.941895,579.879578 
	C295.827057,579.192444 294.761658,578.656372 293.691101,578.645874 
	C286.189301,578.572083 278.686432,578.606812 270.548798,578.606812 
	C270.372681,580.130615 270.194702,581.670349 269.569366,583.274292 
	C266.147461,583.369629 263.172943,583.400757 259.947205,583.074280 
	C253.967392,582.856445 248.179031,582.579468 242.546158,583.387024 
	C240.832260,583.632751 239.114822,586.700500 238.290146,588.822205 
	C237.984314,589.609009 240.233948,591.389160 241.290298,592.825928 
	C241.262436,592.935486 241.300110,592.720093 241.205994,593.107971 
	C241.829926,594.326660 242.548004,595.157532 243.446472,596.236572 
	C243.908752,597.246460 244.190643,598.008240 244.148529,598.970093 
	C243.631546,601.924622 242.774429,605.082092 247.409988,605.008667 
	C247.486435,605.064331 247.557877,605.239441 247.557892,605.239441 
	C247.470657,606.840088 247.383423,608.440735 247.259186,610.720520 
	C244.005829,611.329285 240.336990,612.015808 236.109299,612.678833 
	C235.324783,612.117981 235.099106,611.580627 234.905701,610.711121 
	C234.911575,609.936340 234.885178,609.493774 235.282425,609.036926 
	C237.403854,608.877502 239.101639,608.732361 241.303772,608.544067 
	C238.562210,602.037170 234.349304,599.119873 228.086517,598.606934 
	C226.722733,595.620728 225.893448,592.625977 224.054596,590.507507 
	C220.869263,586.837769 214.121719,587.113708 211.556564,590.571533 
	C210.521759,590.626465 209.830109,590.621094 208.990753,590.281189 
	C203.249954,590.151733 197.631241,590.107117 192.075500,590.676758 
	C189.629745,590.927551 187.312027,592.427307 184.662888,593.343567 
	C184.027908,593.364868 183.666245,593.402710 182.857895,593.304138 
	C179.284393,593.449219 176.157547,593.730774 172.666092,594.008057 
	C171.394928,594.153503 170.488358,594.303162 168.163162,594.687012 
	C170.905716,597.626465 172.947052,599.814331 175.006454,602.237183 
	C175.024521,602.472168 175.034454,602.943420 175.021515,603.349487 
	C175.111801,605.387878 175.215027,607.020203 175.385651,609.718140 
	C176.859161,608.140503 177.470840,607.485535 178.408569,606.922241 
	C180.155899,608.676819 181.577179,610.339661 183.002167,612.368652 
	C183.838318,614.619568 182.964569,618.130615 187.316589,616.976807 
	C188.005920,617.083496 188.397247,617.158020 188.883499,617.565674 
	C191.677124,621.884033 194.375824,625.869385 197.074524,629.854675 
	C195.504913,632.710876 193.309067,634.171021 189.833038,634.165771 
	C188.561050,634.163879 186.445892,635.783386 186.165085,636.998230 
	C185.191223,641.211853 182.509109,642.095154 178.784714,642.028992 
	C174.629669,641.955139 169.967239,640.812317 169.016922,647.340515 
	C168.854095,648.459106 165.620972,649.718750 163.719330,649.878296 
	C159.253632,650.252991 154.713333,649.751099 150.250763,650.143921 
	C148.760391,650.275208 146.530182,651.802917 146.169159,653.113831 
	C145.110428,656.958496 142.770035,658.094971 139.178848,658.019958 
	C134.854340,657.929688 130.077179,656.770996 128.968399,663.428894 
	C128.781555,664.550842 125.532288,665.719055 123.613914,665.883240 
	C119.314857,666.251038 114.961273,666.071960 110.633026,665.975220 
	C107.951241,665.915344 106.677933,666.765869 105.666336,669.578918 
	C104.983994,671.476379 102.150269,673.506531 100.045151,673.799927 
	C95.470123,674.437561 90.723724,673.750916 86.094635,674.151672 
	C84.646294,674.276978 82.558670,675.906128 82.137337,677.270996 
	C81.051338,680.788879 78.946800,682.079712 75.521622,682.011902 
	C72.358322,681.949280 69.192787,681.999329 66.028236,682.000122 
	C62.116497,682.001038 57.865227,681.017639 57.130474,687.133667 
	C56.992973,688.278198 53.830566,689.689941 51.958462,689.868042 
	C47.666759,690.276306 43.307865,690.079163 38.980114,689.971863 
	C36.159668,689.901978 34.261578,690.403137 33.790638,693.803284 
	C33.316856,697.223877 30.562920,698.036621 27.420086,698.017029 
	C18.926125,697.964050 10.431610,697.999573 1.468657,698.000000 
	C1.000000,645.312439 1.000000,592.624878 1.468640,539.468628 
	C8.433038,538.999512 14.929189,538.956909 21.424381,539.017273 
	C24.477531,539.045593 26.809628,538.142151 26.943884,534.726318 
	C27.070559,531.503357 28.915302,530.937622 31.586222,530.980530 
	C36.248623,531.055481 40.958542,531.374939 45.555157,530.819336 
	C47.481895,530.586487 50.464275,528.587402 50.710114,527.034180 
	C51.305080,523.275024 53.284515,522.861206 56.237648,522.983887 
	C58.232765,523.066833 60.439644,523.522949 62.176746,522.847473 
	C64.008888,522.135132 66.482597,520.462646 66.753029,518.880859 
	C67.337341,515.463013 69.066132,514.923462 71.894676,514.976440 
	C76.556648,515.063782 81.272202,515.393005 85.860306,514.803772 
	C87.711060,514.566101 90.329147,512.434570 90.754616,510.710114 
	C91.495003,507.709381 92.847137,506.879364 95.546585,506.986267 
	C97.542099,507.065277 99.544510,506.955597 101.541779,507.008362 
	C104.633575,507.090027 106.845741,506.009735 106.962570,502.610138 
	C107.075745,499.317352 109.111420,498.951111 111.703117,498.984131 
	C116.365967,499.043549 121.077576,499.381927 125.670975,498.813324 
	C127.568718,498.578400 130.397400,496.542847 130.726547,494.908203 
	C131.400314,491.561951 132.994415,490.853516 135.855103,490.983093 
	C137.849670,491.073395 140.066681,491.539490 141.793488,490.853668 
	C143.751114,490.076172 146.321625,488.462463 146.750793,486.744080 
	C147.492767,483.772980 148.785950,482.884338 151.511429,482.983459 
	C153.839905,483.068146 156.321671,483.478088 158.459503,482.831360 
	C160.175354,482.312256 162.586594,480.550232 162.747757,479.120911 
	C163.171219,475.365021 165.154419,474.834717 168.167435,474.981659 
	C170.161270,475.078918 172.361176,475.515533 174.108566,474.851776 
	C175.952774,474.151215 178.473099,472.515808 178.744797,470.949890 
	C179.327850,467.589539 180.938141,466.845062 183.823807,466.982269 
	C185.817947,467.077057 188.031525,467.535553 189.763123,466.854858 
	C191.726242,466.083160 194.411743,464.449646 194.741653,462.785950 
	C195.423950,459.345428 197.217880,459.018311 199.978500,458.969299 
	C209.456970,458.801056 209.456085,458.750916 211.654037,450.998169 
	C215.780869,450.998169 219.728821,450.959839 223.675644,451.009796 
	C227.112640,451.053253 229.049850,449.602356 229.416626,445.985413 
	C231.222168,445.982574 232.611084,445.987396 234.006256,446.368652 
	C234.395737,449.130188 235.054062,450.926208 237.992706,449.061249 
	C238.867218,448.506256 239.990234,448.342834 241.192047,448.240173 
	C242.589584,448.320221 243.794495,448.160126 245.167160,448.231689 
	C246.222672,448.308502 247.110428,448.153625 248.306915,448.153259 
	C254.427719,452.375732 255.859741,451.810669 257.369537,445.157959 
	C260.156525,445.551392 262.578888,445.784302 265.007172,446.393158 
	C266.692017,448.114288 268.370911,449.459503 270.049805,450.804718 
	C271.033203,449.227966 272.016602,447.651215 273.452820,446.047516 
	C276.929321,445.985992 279.953003,445.951385 283.086456,446.149841 
	C283.464142,446.587402 283.732086,446.791901 284.009827,447.378662 
	C286.330872,451.607452 289.672150,452.432831 292.057953,449.168762 
	C293.424835,447.298676 293.321686,444.354126 293.894653,441.901428 
	C293.908997,441.909607 293.919373,441.890991 294.280396,441.922180 
	C295.416840,441.289734 296.192291,440.626068 296.981354,439.978638 
	C296.994965,439.994904 296.972656,439.966827 297.341187,439.975586 
	C298.791077,438.962708 299.872437,437.941040 301.069031,436.707703 
	C301.392090,436.237640 301.653961,436.057770 302.196869,436.223480 
	C306.193115,437.427765 304.543182,434.446899 304.985352,432.984039 
	C305.000000,433.000000 304.967957,432.970551 305.336884,432.980469 
	C306.804749,432.659454 307.903717,432.328491 309.371368,431.993103 
	C311.159637,431.658722 312.579224,431.328766 314.137482,431.309448 
	C314.939453,432.266052 315.602722,432.912018 316.265991,433.557983 
	C316.836792,432.659180 317.810364,431.801453 317.906891,430.854309 
	C318.273804,427.254028 316.983917,422.525452 318.823212,420.227844 
	C323.081207,414.908905 320.837952,410.083130 319.835205,404.934845 
	C319.066376,400.987183 317.216095,398.945953 312.583344,401.000000 
	C310.777802,401.000427 309.388885,401.000824 307.843750,400.698608 
	C305.819672,398.624115 303.951813,396.852203 302.083984,395.080292 
	C302.638428,395.015381 303.192902,394.950470 303.747375,394.885590 
	C304.164917,392.590393 304.582458,390.295197 304.997925,387.654144 
	C304.975891,386.846954 304.955963,386.385651 304.961670,385.930298 
	C304.987335,385.936249 304.945557,385.968231 305.174133,385.872253 
	C305.688690,385.585785 305.887787,385.328156 306.200684,384.958496 
	C306.401367,384.913605 306.811157,384.878723 307.200012,384.916290 
	C311.912964,385.471039 310.291901,382.806763 309.821106,380.569733 
	C309.810913,380.375977 309.876038,379.993408 310.243591,380.031433 
	C311.594788,381.078857 312.408630,382.423706 313.598358,383.025879 
	C315.887390,384.184448 318.347565,385.228851 320.839172,385.568024 
	C321.318451,385.633209 322.411285,382.287781 322.944489,380.436066 
	C323.250427,379.373505 322.999969,378.150665 323.000000,377.000000 
M337.674988,432.181610 
	C337.614044,432.286377 337.476746,432.448303 337.505096,432.486145 
	C337.643372,432.670715 337.825928,432.822144 338.043579,433.489166 
	C338.329895,433.602142 338.616241,433.715149 338.902557,433.828125 
	C338.600891,433.220734 338.299225,432.613342 337.674988,432.181610 
M111.245041,565.887817 
	C115.294014,564.947144 119.342987,564.006470 123.392250,563.066895 
	C117.150719,556.363098 101.729584,558.270691 95.451607,567.878479 
	C101.155205,567.254395 105.813675,566.744629 111.245041,565.887817 
M164.841034,610.457214 
	C167.628769,607.010925 167.594666,603.220581 164.373505,601.122314 
	C163.362762,600.463867 160.380890,600.738892 160.264252,601.147278 
	C159.610779,603.435181 158.982193,606.039185 159.533951,608.236816 
	C159.826538,609.402161 162.524796,609.963623 164.841034,610.457214 
M65.293083,601.298584 
	C68.047737,601.727173 70.816475,602.460938 73.545540,602.335022 
	C74.112656,602.308777 75.490616,598.241943 74.840416,597.512939 
	C72.914192,595.353394 66.537636,597.569946 65.293083,601.298584 
M301.822815,450.786102 
	C305.156769,451.213989 307.323303,449.654388 306.667847,446.431549 
	C306.386017,445.045868 303.772491,443.928833 302.011780,443.166901 
	C301.437225,442.918274 299.362640,444.303955 299.425079,444.729156 
	C299.710571,446.673492 300.511383,448.542175 301.822815,450.786102 
M338.288208,444.450623 
	C338.011261,444.714478 337.734314,444.978333 337.457336,445.242188 
	C337.566711,445.356812 337.765198,445.577118 337.772675,445.570831 
	C338.064850,445.324585 338.339569,445.057678 338.288208,444.450623 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M312.531342,1.000000 
	C312.949097,4.845689 313.493561,7.750675 307.977020,9.910733 
	C304.413330,11.306149 301.033020,16.597414 297.571259,20.121702 
	C295.804382,21.920525 295.812927,25.166288 291.957703,25.395254 
	C290.901459,25.457987 289.478088,27.896782 289.139465,29.445528 
	C288.652527,31.672924 288.939484,34.085800 289.015320,36.415630 
	C289.102295,39.088943 287.819702,39.894188 285.412872,41.263748 
	C280.574768,44.016769 276.594299,48.596603 273.444763,52.986298 
	C271.557007,55.617332 270.612732,57.164173 267.482819,57.018204 
	C265.488098,56.925171 263.302368,56.510536 261.537964,57.156708 
	C259.825653,57.783802 258.542084,59.581577 257.070221,60.865219 
	C258.282349,62.227081 259.312897,64.464600 260.745758,64.761658 
	C263.418091,65.315689 265.039459,65.905067 265.039124,69.009575 
	C265.038818,72.129036 262.432495,71.944641 260.889008,73.284821 
	C256.766998,76.863846 252.644867,80.703537 249.286362,84.979515 
	C247.938629,86.695412 247.853577,88.754471 244.951309,89.217209 
	C243.485504,89.450920 242.286545,91.584381 241.065460,92.947159 
	C240.802582,93.240555 241.093643,94.295044 240.911057,94.357208 
	C235.441422,96.219322 233.829514,102.313705 229.153076,105.010498 
	C229.023071,105.085449 228.734695,104.978531 228.672714,105.065338 
	C225.363327,109.699699 220.544144,112.890320 216.989716,117.295898 
	C215.912888,118.630600 214.617493,119.793396 213.373688,120.984268 
	C213.184845,121.165062 212.511917,120.958664 212.431808,121.117455 
	C209.791107,126.352852 204.254944,128.833740 200.850800,133.469681 
	C197.691681,137.771896 193.335480,141.196640 189.495010,144.996246 
	C188.015060,146.460434 185.562515,147.696411 185.210510,149.382401 
	C184.557892,152.508179 182.969772,153.095352 180.344681,153.013046 
	C178.180954,152.945175 175.833664,152.484955 173.899063,153.155136 
	C172.053146,153.794617 169.753540,155.398819 169.240158,157.067184 
	C168.505615,159.454239 167.992035,161.119263 165.203735,161.017929 
	C165.038162,161.011902 164.781540,160.971222 164.718094,161.057526 
	C161.349884,165.640045 156.613876,168.910751 153.031555,173.281601 
	C152.860062,173.490829 153.102478,174.106674 152.929276,174.237015 
	C150.374756,176.159576 145.961807,177.716705 145.569901,179.980347 
	C144.696091,185.027695 142.055191,185.153564 138.286499,185.035370 
	C133.853622,184.896332 128.719467,183.936050 128.800980,191.042709 
	C123.961548,192.720840 121.200233,196.295792 118.675491,200.627396 
	C117.972115,201.834152 113.651016,201.044403 110.994896,200.972992 
	C107.516022,200.879471 105.333641,201.893555 104.813660,205.831726 
	C104.656509,207.021957 102.545166,207.940338 101.354309,209.011444 
	C99.897827,210.321457 97.398964,211.541199 97.234108,213.011200 
	C96.800385,216.878845 94.596451,217.040466 91.696991,217.020813 
	C83.033623,216.962112 74.369606,216.999069 65.705841,217.000244 
	C57.047958,217.001404 54.339092,221.345322 57.956501,229.430054 
	C58.217854,230.014160 58.508804,230.585022 58.913700,231.427887 
	C57.969845,231.986725 57.157974,232.886993 56.380405,232.858261 
	C52.013599,232.697006 49.500202,233.747314 48.458557,238.872070 
	C47.955379,241.347687 41.968258,242.644333 41.355373,245.175003 
	C39.964424,250.918365 36.071671,248.553116 33.083038,248.930740 
	C30.459156,249.262283 27.757395,249.078537 25.095118,248.982025 
	C20.857807,248.828415 16.922791,248.779343 16.675386,254.734573 
	C16.641979,255.538712 14.281153,256.778564 12.920739,256.892303 
	C9.280573,257.196686 5.598387,256.998505 1.466448,257.000000 
	C1.000000,171.732437 1.000000,86.464867 1.000000,1.000000 
	C104.687477,1.000000 208.375076,1.000000 312.531342,1.000000 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M445.003662,263.048981 
	C445.888611,262.224609 446.838440,261.458435 447.642456,260.561523 
	C448.712219,259.368195 450.800507,257.537903 450.500549,256.903290 
	C446.976196,249.447006 455.236084,250.191193 457.469757,246.846741 
	C457.781097,246.380554 460.009308,247.194534 462.268433,247.567505 
	C461.769379,245.298843 461.853851,243.000732 460.811371,241.470184 
	C458.176483,237.601715 458.686890,234.719879 463.104126,232.396515 
	C464.024933,234.496506 464.320953,236.455780 464.705383,238.397552 
	C466.390930,246.912048 466.401764,247.019440 475.018585,246.897659 
	C493.810852,246.632050 512.602539,246.294128 531.391968,245.873428 
	C536.635681,245.756027 541.941833,245.670197 547.090881,244.805054 
	C553.037781,243.805847 553.522278,242.381805 552.281921,236.539551 
	C550.427917,227.806641 548.967590,218.986542 546.968323,210.288895 
	C543.512878,195.255936 539.864807,180.265076 536.076355,165.312607 
	C535.281433,162.175247 533.989807,159.060318 529.581055,158.996811 
	C519.782959,158.855698 509.990997,158.266632 500.192963,158.140213 
	C487.866119,157.981186 475.535706,158.101807 463.206818,158.101761 
	C461.287476,156.142365 462.163177,155.089035 464.440094,155.056656 
	C473.163727,154.932526 481.890900,155.035080 490.613525,154.875595 
	C493.013824,154.831696 495.391418,154.020035 497.800415,153.838013 
	C499.985748,153.672852 502.209991,153.772430 504.396881,153.974335 
	C505.664337,154.091354 507.030853,155.183014 508.114227,154.905319 
	C514.069763,153.378815 519.979919,153.154190 526.120483,153.649658 
	C527.690613,153.776352 529.574219,150.019073 530.510620,147.434555 
	C538.237000,146.325302 538.216492,140.704300 538.126282,134.691925 
	C538.098572,132.844116 539.562561,130.973907 540.988525,129.256683 
	C542.749268,131.201721 544.292542,132.872467 544.895386,134.832520 
	C546.339355,139.527328 549.109558,141.995041 554.130371,141.953308 
	C559.936401,141.905045 565.758118,141.797577 571.548096,142.138748 
	C585.367188,142.953003 599.171631,144.015823 613.372925,144.981384 
	C614.830994,143.675842 616.945923,142.193878 616.763672,141.091873 
	C616.269470,138.104141 615.087646,135.000031 610.762146,134.927109 
	C610.062134,134.888947 609.662659,134.879150 609.024841,134.662811 
	C608.178101,133.651505 607.569702,132.846741 607.003296,131.672562 
	C606.999695,130.562408 606.954163,129.821671 606.896606,128.751282 
	C606.247437,127.957008 605.610291,127.492371 604.679810,126.868752 
	C601.233887,123.160889 598.081116,119.612007 594.952759,115.693649 
	C595.799011,114.718842 596.997131,113.417976 597.379639,113.624908 
	C599.360596,114.696426 601.368896,115.921135 602.937927,117.515404 
	C610.287109,124.982773 617.418762,132.664734 624.792175,140.107452 
	C627.671448,143.013809 630.580688,146.753830 634.180786,147.898163 
	C641.103882,150.098831 648.504089,150.895248 655.755005,151.934677 
	C660.749878,152.650650 665.838806,154.440811 670.095825,156.072388 
	C674.071960,157.596313 677.581604,157.558578 681.332214,157.981583 
	C685.466858,158.447876 686.088135,156.486572 685.028748,152.857254 
	C683.880615,148.923752 681.112000,150.151688 678.415527,150.274597 
	C678.254700,150.244171 677.965027,150.091476 677.976746,149.712006 
	C676.243469,147.973343 674.498535,146.614151 672.719727,145.268967 
	C672.685974,145.282990 672.617371,145.308228 672.721619,144.890686 
	C672.541504,143.099487 672.708801,140.925568 671.905090,140.471909 
	C659.447021,133.439941 649.938843,122.319672 637.013855,115.850098 
	C626.846436,110.760826 617.382568,104.279655 607.297302,99.007721 
	C601.211426,95.826416 594.694153,93.165413 588.066711,91.434792 
	C583.821289,90.326187 579.010132,91.166054 574.475891,91.475174 
	C572.254150,91.626648 570.085754,92.558960 567.593079,92.954773 
	C563.185486,91.185432 559.198059,88.946877 554.942749,88.163467 
	C548.306946,86.941811 541.479980,86.789444 534.752075,86.025383 
	C530.323914,85.522507 525.935486,84.335045 521.513489,84.247444 
	C519.138611,84.200394 516.730591,85.828064 514.337524,86.700043 
	C515.309631,89.022545 515.794006,91.751518 517.334656,93.600243 
	C526.001038,103.999428 533.931091,114.863472 540.022461,126.996620 
	C537.646912,127.154366 535.089600,126.820694 532.948425,127.612328 
	C530.931580,128.358002 529.353088,130.289566 526.959351,131.687866 
	C525.371277,129.503571 524.537476,127.260239 523.418396,125.169662 
	C516.898682,112.990379 510.327087,100.838814 503.740662,88.695412 
	C501.080841,83.791527 492.313385,81.647598 488.661987,85.070343 
	C487.887299,85.074776 487.444305,85.042854 486.926331,84.636093 
	C484.901703,83.842262 482.952057,83.423264 481.002441,83.004272 
	C481.913330,82.333092 482.841370,81.683769 483.731110,80.985657 
	C485.142792,79.877975 486.528931,78.737701 488.232544,76.992386 
	C489.967224,72.770912 493.826080,71.750984 496.973694,74.093864 
	C498.276581,75.063614 499.584900,76.493340 501.041351,76.712273 
	C509.345367,77.960602 517.671143,79.206589 526.035767,79.867798 
	C533.771057,80.479248 542.020203,76.708534 549.063965,82.826607 
	C549.232056,82.972618 549.701660,82.771515 550.394653,82.794273 
	C552.840515,82.887444 554.922363,82.918655 557.065918,83.306931 
	C562.105957,86.597046 565.527771,85.829338 569.000000,81.000305 
	C569.000000,79.012184 569.000000,77.024063 569.000000,74.608658 
	C573.652832,73.959259 578.132263,73.039719 578.326721,67.126038 
	C578.339050,66.752266 579.271484,66.148422 579.820557,66.092888 
	C585.392395,65.529396 586.311890,61.720440 585.968079,57.196724 
	C585.659119,53.132408 585.794128,49.923916 591.017883,48.790848 
	C592.417358,48.487289 593.639282,44.600952 593.864807,42.255718 
	C594.266846,38.075577 592.992065,33.655258 599.430969,32.620296 
	C600.645752,32.425045 601.687073,27.920294 601.876038,25.335897 
	C602.155029,21.522840 601.276489,17.755846 606.899536,16.882242 
	C608.180542,16.683226 609.529297,13.414011 609.856445,11.397238 
	C610.356567,8.314095 609.995544,5.091297 610.000000,1.463893 
	C665.687561,1.000000 721.375122,1.000000 777.531372,1.000000 
	C777.999451,4.827622 777.949707,8.656074 778.014099,12.482602 
	C778.065796,15.551279 777.079529,17.826038 773.675354,17.952246 
	C770.427734,18.072651 769.942078,19.994089 769.981689,22.631739 
	C770.051758,27.290380 769.959045,31.951321 770.012024,36.610416 
	C770.047424,39.722157 768.828247,41.372879 765.593811,42.178123 
	C764.148010,42.538071 762.592651,44.677059 762.149231,46.299053 
	C761.556274,48.467896 762.520813,51.090359 761.832886,53.196842 
	C761.236694,55.022709 759.455017,57.484177 757.871277,57.754467 
	C754.450928,58.338211 753.867065,60.075802 753.984375,62.897697 
	C754.067200,64.891220 754.543274,67.110039 753.852478,68.830093 
	C753.069092,70.780403 751.437378,73.328537 749.713928,73.754677 
	C746.721558,74.494568 745.883667,75.834938 745.985229,78.536858 
	C746.066345,80.697174 746.499939,83.029861 745.852234,84.978645 
	C745.231384,86.846367 743.583435,89.525246 742.098938,89.726837 
	C738.363770,90.234047 737.903503,92.174622 737.973083,95.175461 
	C738.077271,99.666321 738.388977,104.212013 737.816101,108.631157 
	C737.568176,110.543900 735.578064,113.389771 733.952454,113.721786 
	C730.650452,114.396179 729.834534,115.906654 729.983154,118.803535 
	C730.076843,120.629311 730.012451,122.463966 729.993530,124.294380 
	C729.984924,125.124664 730.190674,126.524132 729.830811,126.698479 
	C724.703613,129.182632 722.772156,135.460114 718.085449,137.605301 
	C714.698181,139.155685 713.855103,140.476517 713.985596,143.557602 
	C714.062988,145.384872 714.496521,147.417267 713.866394,148.997040 
	C713.124878,150.856201 711.500488,152.413956 710.081482,153.937042 
	C708.815247,155.296097 707.284668,156.405396 705.911804,157.669434 
	C704.283081,159.168991 702.698242,160.716217 701.102051,162.235565 
	C702.420471,163.592422 705.022705,165.155502 705.717896,167.328583 
	C706.304443,169.162308 704.677795,171.703964 704.030762,173.932281 
	C703.392456,174.151993 702.754150,174.371704 702.115845,174.591400 
	C701.343079,172.721985 700.570312,170.852554 699.797607,168.983139 
	C699.386414,169.703415 698.975220,170.423691 698.564087,171.143967 
	C696.487793,171.294952 694.411499,171.445938 691.688110,171.837799 
	C688.253967,172.205322 685.466919,172.331970 682.412842,172.415649 
	C681.784180,172.393494 681.422424,172.414276 680.916504,172.066711 
	C678.150879,170.848663 675.529419,169.998932 672.866150,168.754303 
	C670.202942,168.279541 667.581665,168.199677 664.569275,168.104416 
	C663.134766,168.087753 662.091309,168.086487 660.804749,167.796753 
	C657.662720,166.668045 654.763855,165.827820 651.402222,164.853485 
	C651.270630,166.651871 651.179871,167.892593 651.081970,169.514587 
	C651.817810,171.830658 652.323486,173.902084 653.344849,175.676666 
	C657.832764,183.474380 662.469666,191.186356 666.882629,199.301224 
	C665.880188,202.833908 664.890991,206.020218 667.892517,208.998093 
	C668.824402,207.305801 669.494019,206.089752 670.450317,205.068726 
	C672.213745,208.160995 673.690552,211.058228 675.167358,213.955444 
	C672.065735,218.348007 667.028381,216.388382 662.949219,217.561188 
	C662.533936,217.680573 661.424133,216.436630 661.095398,215.636780 
	C658.620850,209.616959 656.439087,203.469330 653.755249,197.547729 
	C652.971802,195.819107 650.859802,194.692642 649.024719,193.329666 
	C648.692627,193.369171 648.754211,193.295563 648.829956,192.887909 
	C648.486694,188.548264 648.067627,184.616257 647.617188,180.390091 
	C646.997681,180.586288 645.756104,180.979477 643.364624,181.736847 
	C642.449280,176.155914 641.670410,170.918091 640.684448,165.719528 
	C640.418701,164.318375 639.573425,163.009766 638.873901,161.724106 
	C638.747070,161.490906 638.035767,161.575714 637.318298,161.474915 
	C637.318298,163.020828 637.318298,164.369675 637.318298,165.231186 
	C635.502502,165.742004 634.083130,166.141296 632.370911,166.545441 
	C631.719177,166.406982 631.360352,166.263641 630.935303,165.738342 
	C629.339844,164.602707 627.641846,164.069809 626.312805,163.054153 
	C622.249817,159.949173 611.753113,160.097794 607.380981,163.547684 
	C604.973206,163.494766 602.986267,163.376251 600.929810,162.837891 
	C596.505005,162.435104 592.149719,162.452148 587.441467,162.540222 
	C586.378479,162.531143 585.668335,162.451035 584.968018,161.931427 
	C585.001587,158.101120 582.669067,157.756302 580.186951,158.030228 
	C571.165649,159.025879 562.157593,160.141724 553.526794,161.166824 
	C555.746460,165.934036 557.981628,169.827042 559.397095,173.998230 
	C561.280945,179.549225 562.493896,185.325974 564.079590,190.982056 
	C564.332581,191.884293 565.205811,192.606506 565.489563,193.507294 
	C567.008728,198.331375 570.494324,202.647324 567.630676,208.463470 
	C566.614868,210.526566 568.382080,213.959915 568.878784,216.767715 
	C569.403381,216.669724 569.927979,216.571732 570.452576,216.473724 
	C570.452576,214.831940 570.452576,213.190155 570.452576,211.246948 
	C571.933838,212.363876 573.017639,213.181091 574.238892,214.328094 
	C574.406860,215.367599 574.437317,216.077316 574.123291,217.002563 
	C574.284058,222.152893 574.400574,227.175552 575.486023,231.979263 
	C575.920105,233.900650 578.489563,235.339554 580.244263,237.287537 
	C580.457764,238.009155 580.513977,238.441315 580.147827,238.907822 
	C579.033997,239.808319 578.342468,240.674500 577.650940,241.540680 
	C579.012817,242.024597 580.354309,242.853073 581.739990,242.935104 
	C589.292175,243.382156 596.879333,244.141373 604.404907,243.774963 
	C608.531982,243.574051 612.568604,241.388565 616.617615,240.014252 
	C616.866943,239.929642 616.866760,239.110138 617.355347,238.534943 
	C618.749756,238.467789 619.771545,238.498230 621.040894,238.892029 
	C624.342896,239.599960 627.784485,242.130508 630.329224,237.592133 
	C631.368469,237.472214 632.074097,237.473618 632.975830,237.824585 
	C635.389771,239.037170 637.673096,240.753479 639.812317,240.589432 
	C644.585388,240.223404 649.335632,239.106277 653.988831,237.888672 
	C655.493835,237.494858 656.667297,235.833817 658.288818,234.641388 
	C659.004517,234.503204 659.423889,234.476059 659.843201,234.448929 
	C661.007935,235.234406 662.172668,236.019897 663.270020,236.759933 
	C660.880432,242.892868 655.059448,240.274551 650.257141,241.412918 
	C649.832336,244.737701 649.418030,247.979904 648.646729,251.653473 
	C647.571045,252.080505 646.852295,252.076141 645.654541,252.046295 
	C641.528564,252.014465 637.847656,251.718597 634.250732,252.139709 
	C632.742981,252.316208 631.413147,254.012161 629.675232,255.073700 
	C628.917175,255.575516 628.486328,256.020966 627.713867,256.404938 
	C626.693726,256.386261 626.015259,256.429047 624.865051,256.341278 
	C619.274414,256.164612 614.155518,256.118530 608.762939,255.775238 
	C605.763794,255.633133 601.919800,252.455841 600.643555,258.007568 
	C599.187988,258.589569 598.088928,259.155548 596.533813,259.788330 
	C592.387146,259.890472 588.696533,259.925812 584.701660,259.677002 
	C574.388245,259.992371 564.377197,260.566772 554.375061,261.267120 
	C553.885315,261.301422 553.472595,262.436951 552.558289,263.115448 
	C547.117310,263.283173 542.142090,263.395081 536.782288,263.317993 
	C535.264038,263.061829 534.130310,262.994720 532.690796,262.646973 
	C525.001282,262.322784 517.616028,262.161621 510.234222,262.261566 
	C497.155121,262.438599 484.078094,262.774200 471.055603,262.687378 
	C473.494415,260.773560 475.877869,259.215851 478.566132,257.458923 
	C473.655121,257.458923 469.067810,257.458923 464.234283,257.458923 
	C465.310944,259.634705 466.154785,261.339996 466.530212,263.054108 
	C459.042419,263.058258 452.023041,263.053619 445.003662,263.048981 
M563.524048,211.716858 
	C562.955688,211.606125 562.387329,211.495392 561.818970,211.384674 
	C561.813599,211.789276 561.808228,212.193878 561.802917,212.598480 
	C562.374023,212.502106 562.945190,212.405716 563.524048,211.716858 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M229.000000,445.993073 
	C229.049850,449.602356 227.112640,451.053253 223.675644,451.009796 
	C219.728821,450.959839 215.780869,450.998169 211.654037,450.998169 
	C209.456085,458.750916 209.456970,458.801056 199.978500,458.969299 
	C197.217880,459.018311 195.423950,459.345428 194.741653,462.785950 
	C194.411743,464.449646 191.726242,466.083160 189.763123,466.854858 
	C188.031525,467.535553 185.817947,467.077057 183.823807,466.982269 
	C180.938141,466.845062 179.327850,467.589539 178.744797,470.949890 
	C178.473099,472.515808 175.952774,474.151215 174.108566,474.851776 
	C172.361176,475.515533 170.161270,475.078918 168.167435,474.981659 
	C165.154419,474.834717 163.171219,475.365021 162.747757,479.120911 
	C162.586594,480.550232 160.175354,482.312256 158.459503,482.831360 
	C156.321671,483.478088 153.839905,483.068146 151.511429,482.983459 
	C148.785950,482.884338 147.492767,483.772980 146.750793,486.744080 
	C146.321625,488.462463 143.751114,490.076172 141.793488,490.853668 
	C140.066681,491.539490 137.849670,491.073395 135.855103,490.983093 
	C132.994415,490.853516 131.400314,491.561951 130.726547,494.908203 
	C130.397400,496.542847 127.568718,498.578400 125.670975,498.813324 
	C121.077576,499.381927 116.365967,499.043549 111.703117,498.984131 
	C109.111420,498.951111 107.075745,499.317352 106.962570,502.610138 
	C106.845741,506.009735 104.633575,507.090027 101.541779,507.008362 
	C99.544510,506.955597 97.542099,507.065277 95.546585,506.986267 
	C92.847137,506.879364 91.495003,507.709381 90.754616,510.710114 
	C90.329147,512.434570 87.711060,514.566101 85.860306,514.803772 
	C81.272202,515.393005 76.556648,515.063782 71.894676,514.976440 
	C69.066132,514.923462 67.337341,515.463013 66.753029,518.880859 
	C66.482597,520.462646 64.008888,522.135132 62.176746,522.847473 
	C60.439644,523.522949 58.232765,523.066833 56.237648,522.983887 
	C53.284515,522.861206 51.305080,523.275024 50.710114,527.034180 
	C50.464275,528.587402 47.481895,530.586487 45.555157,530.819336 
	C40.958542,531.374939 36.248623,531.055481 31.586222,530.980530 
	C28.915302,530.937622 27.070559,531.503357 26.943884,534.726318 
	C26.809628,538.142151 24.477531,539.045593 21.424381,539.017273 
	C14.929189,538.956909 8.433038,538.999512 1.468640,539.000000 
	C1.000000,538.555542 1.000000,538.111084 1.000000,537.333313 
	C7.327343,536.999817 13.656159,536.918701 19.981405,537.033875 
	C23.108288,537.090759 24.921421,536.401611 25.211637,532.709778 
	C25.316143,531.380310 27.677248,529.299988 29.146833,529.173889 
	C34.271103,528.734131 39.456871,528.937866 44.617252,529.021301 
	C47.425507,529.066711 49.168819,528.165649 48.994572,525.057190 
	C48.799961,521.585449 50.849117,520.845215 53.768635,520.982788 
	C55.762302,521.076721 57.771557,520.889099 59.760422,521.024536 
	C63.130756,521.253967 65.023979,520.355957 65.236809,516.426514 
	C65.302498,515.213562 67.871994,513.299377 69.425713,513.159668 
	C74.215897,512.729126 79.070999,512.920105 83.897285,513.028809 
	C86.952843,513.097595 88.910583,512.554504 89.204918,508.793274 
	C89.310921,507.438751 91.506454,505.720734 93.099854,505.150299 
	C94.871544,504.516052 97.050697,504.898163 99.040894,505.022675 
	C102.605988,505.245667 105.257927,504.817535 105.239738,500.135101 
	C105.235718,499.096680 107.746368,497.258484 109.202293,497.148010 
	C114.169098,496.771210 119.183105,496.935303 124.176750,497.024567 
	C127.051674,497.075958 129.195969,496.510162 129.001144,492.997925 
	C128.830688,489.925201 130.478348,488.874054 133.327896,488.984894 
	C135.655426,489.075439 137.993576,488.911438 140.319489,489.020691 
	C143.311935,489.161224 145.201874,488.259308 145.012177,484.854492 
	C144.835785,481.688324 146.723572,480.877716 149.470810,480.987091 
	C151.465714,481.066528 153.475220,480.880493 155.462555,481.026306 
	C159.001175,481.285980 161.204758,480.464386 161.209885,476.213348 
	C161.211166,475.145905 163.261642,473.594879 164.655319,473.140991 
	C166.312668,472.601166 168.283096,472.910034 170.107758,473.019562 
	C174.078583,473.257996 177.600769,473.211487 177.244690,467.565186 
	C177.195526,466.785736 179.103683,465.368622 180.279816,465.139160 
	C182.366653,464.732056 184.594391,464.891174 186.749863,465.026093 
	C190.480789,465.259644 193.418243,464.917297 193.213272,459.920807 
	C193.175507,458.999847 195.193634,457.843231 196.439362,457.085632 
	C197.050354,456.714050 198.066742,457.029327 198.898163,456.996124 
	C201.711502,456.883636 205.228882,457.721619 207.124863,456.340240 
	C208.785751,455.130127 208.601898,451.414124 209.292267,448.820892 
	C209.410172,448.377991 209.826218,448.014496 210.047775,447.695892 
	C213.634430,446.222229 218.618347,453.678406 221.406479,445.397217 
	C224.211319,445.319550 226.605667,445.656311 229.000000,445.993073 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M339.004059,352.006073 
	C338.452881,350.927399 337.222992,349.106232 337.459839,348.889771 
	C339.080566,347.408325 340.900879,345.436707 342.860657,345.180267 
	C347.108246,344.624451 351.508911,345.299408 355.782806,344.854309 
	C357.555908,344.669647 360.330994,343.174072 360.643372,341.813751 
	C361.906006,336.315674 365.492126,338.109680 368.807159,338.520386 
	C371.624451,338.869415 374.454010,339.119202 377.278137,339.412933 
	C377.354492,338.779663 377.430847,338.146362 377.507202,337.513092 
	C376.016449,337.292938 374.276459,337.477875 373.096802,336.752106 
	C371.553345,335.802551 369.628296,334.236969 369.439758,332.738525 
	C369.305084,331.668060 371.701691,330.207916 373.070312,329.040710 
	C374.293335,327.997681 376.691986,327.206024 376.781921,326.150879 
	C377.243561,320.734528 380.748810,320.739746 384.638275,321.124878 
	C386.596619,321.318817 388.657562,322.156677 390.454224,321.747192 
	C391.783783,321.444153 393.657593,319.657013 393.739716,318.427551 
	C394.082336,313.298279 395.728821,311.735809 401.033386,312.515442 
	C401.668793,312.608795 402.295990,312.757874 403.219299,312.938232 
	C403.810883,311.128998 404.358673,309.453613 405.408081,307.384705 
	C409.620514,306.498566 413.331299,306.005951 417.506165,305.457642 
	C432.251282,305.416046 446.532715,305.378326 460.813080,305.484772 
	C463.926544,305.507965 467.040161,306.331635 470.146759,306.288391 
	C476.418427,306.201141 482.685394,305.771881 488.922668,305.746399 
	C488.919830,306.360474 488.948608,306.711853 489.052429,307.400391 
	C490.830200,309.634399 492.185028,309.723572 493.465668,306.984772 
	C500.248749,306.962250 506.563385,306.947784 513.357910,306.956970 
	C520.064514,307.289978 526.289124,307.656677 532.518799,307.880920 
	C535.074890,307.972900 537.134827,307.175812 537.425049,303.985291 
	C544.250427,303.455811 550.665649,303.100616 557.042297,302.396698 
	C566.333435,301.371124 575.596985,300.094604 584.872253,298.925110 
	C589.371033,299.713654 593.890503,300.406372 598.352112,301.367554 
	C599.301147,301.572021 600.687561,302.688904 600.768982,303.496582 
	C601.264221,308.412140 602.091797,313.452820 601.406616,318.251587 
	C601.127747,320.204987 597.332092,321.656219 594.324524,323.957123 
	C594.388062,327.644287 591.975952,330.415894 585.213257,330.157990 
	C586.540466,333.412598 587.551575,335.891907 588.724854,338.768890 
	C585.673706,340.439178 579.568237,336.652222 577.007385,342.671295 
	C578.955627,345.762695 586.467224,341.252808 584.553528,348.988647 
	C580.712891,349.392395 577.328186,349.379547 573.997681,348.989197 
	C574.874817,347.640961 575.697754,346.670288 576.520691,345.699585 
	C576.271973,345.294952 576.023315,344.890289 575.774658,344.485657 
	C573.687439,345.430084 571.600281,346.374481 569.513123,347.318909 
	C570.151123,348.305695 570.789062,349.292480 571.349487,350.576019 
	C570.882996,351.586121 570.493958,352.299469 570.104919,353.012817 
	C565.203552,353.298737 559.682312,352.122803 557.514954,358.688019 
	C555.976501,356.648560 554.628357,354.861328 552.815491,352.829010 
	C551.891602,352.155243 551.432434,351.726562 550.987915,350.899719 
	C551.150879,349.118469 551.299255,347.735413 551.331909,347.430389 
	C547.391907,347.970398 543.639526,348.896149 539.879211,348.929871 
	C522.266479,349.087585 504.651398,348.960968 487.037720,349.047150 
	C484.762695,349.058289 482.423065,349.489838 480.237366,350.142059 
	C478.223022,350.743164 477.317078,352.009216 479.853882,353.849426 
	C479.743378,357.201630 479.620087,360.127380 479.086273,363.075134 
	C478.196655,363.970551 477.717590,364.843933 477.238525,365.717316 
	C478.059723,366.066406 478.880951,366.415497 479.884430,366.987823 
	C480.148926,367.776001 480.231171,368.340881 479.970093,368.960815 
	C479.370117,369.724609 479.113434,370.433380 478.856781,371.142181 
	C479.931061,370.996124 481.005371,370.850037 482.409668,370.647217 
	C483.161072,370.721924 483.582489,370.853424 484.093689,371.380157 
	C485.015625,372.179260 485.808502,372.772247 486.686646,372.954498 
	C490.618103,373.770416 495.757355,370.688843 498.200531,376.627350 
	C498.329803,376.941589 500.919189,376.477661 502.227692,376.002563 
	C503.382080,375.583405 504.346741,374.641724 505.504639,374.394592 
	C505.550507,386.579285 505.486877,398.300110 505.423248,410.020966 
	C502.191162,408.570404 500.397919,408.834564 499.842743,413.107513 
	C499.398224,416.528992 497.565155,419.770081 496.186401,423.533142 
	C492.951752,423.886414 489.663208,424.833435 491.923096,429.733002 
	C490.791016,430.254333 489.658936,430.775635 487.818115,431.103882 
	C481.079620,415.606110 475.049896,400.301331 469.376465,384.925476 
	C473.189453,378.713806 472.310974,376.467712 463.779572,369.710358 
	C462.403748,366.658539 460.783966,364.010315 460.015411,361.134583 
	C457.041595,350.006927 457.117584,350.458435 445.820709,349.866089 
	C437.468811,349.428192 433.848297,353.324432 432.680786,361.005676 
	C425.747345,363.327942 425.287048,364.649628 427.930664,373.451752 
	C427.257050,375.209686 426.729034,376.660645 425.790771,378.081024 
	C420.406830,379.095276 421.463593,382.007599 423.177399,385.506287 
	C421.139221,390.937103 419.112732,395.988708 416.671570,401.026642 
	C415.153168,401.016479 414.049347,401.019958 412.939941,400.554810 
	C412.935181,398.100494 412.913055,396.114502 412.940704,394.129181 
	C413.089294,383.458740 410.796051,373.362671 403.784149,365.179962 
	C400.619843,361.487335 397.947205,356.166962 391.547150,356.085083 
	C389.956207,355.502899 388.478027,355.234589 386.705566,354.755096 
	C386.230072,354.384247 386.048920,354.224579 385.812988,354.010925 
	C385.758240,353.956909 385.311951,353.948914 384.950317,354.041382 
	C384.123718,353.994232 383.658722,353.854675 383.170227,353.439545 
	C381.766235,352.452667 380.385712,351.741302 378.572906,351.011108 
	C376.699219,351.081543 375.257874,351.170776 373.361267,351.222992 
	C365.613007,351.039856 358.319977,350.893768 351.009430,350.392273 
	C350.284454,349.385529 349.576996,348.734222 348.869537,348.082886 
	C348.239136,348.979950 347.608734,349.877045 346.593994,350.879120 
	C345.158173,351.132294 344.106659,351.280457 342.927673,351.126404 
	C342.291504,351.334656 341.782837,351.845154 341.118347,352.422729 
	C340.962494,352.489807 340.626648,352.440887 340.224548,352.318970 
	C339.549683,352.133423 339.276886,352.069763 339.004059,352.006073 
z"
          />
          <path
            fill="#0B4FAD"
            opacity="1.000000"
            stroke="none"
            d="
M842.168213,441.300537 
	C841.990051,441.383453 841.811951,441.466400 841.017029,441.515961 
	C838.044189,441.499390 835.688171,441.516174 832.866943,441.343384 
	C821.500916,441.246918 810.600037,441.340027 799.482849,441.368103 
	C799.266602,441.303070 798.850586,441.127350 798.889160,440.649780 
	C798.918579,415.433655 798.864807,390.694977 798.917847,365.956543 
	C798.947083,352.307251 797.043518,354.116852 810.743774,354.084259 
	C819.937805,354.062378 829.132446,354.342316 838.628723,354.524231 
	C839.537903,354.569397 840.145203,354.577484 841.042358,354.835388 
	C843.925171,356.346619 846.518127,357.608063 849.060791,358.919037 
	C849.010498,358.968536 849.128479,358.891296 849.111938,359.237671 
	C849.796997,359.981354 850.498596,360.378632 851.369263,360.863922 
	C851.538391,360.951935 851.857849,361.159973 851.864929,361.529541 
	C852.096252,362.514343 852.259521,363.160553 852.554138,363.739960 
	C855.697327,369.921112 858.497009,376.072937 855.544312,383.275024 
	C854.270630,386.381775 853.667664,389.763489 852.767578,393.347412 
	C852.780457,394.110870 852.785645,394.547180 852.573547,395.198212 
	C852.760986,396.260040 853.035095,397.205872 853.589844,397.939514 
	C862.614990,409.874237 863.030334,418.877777 854.924866,431.453491 
	C853.272217,434.017639 850.937927,436.142426 848.595337,438.535095 
	C847.837646,438.648743 847.402588,438.696991 846.746094,438.519714 
	C845.072571,439.296265 843.620422,440.298401 842.168213,441.300537 
M832.096802,425.714386 
	C838.280457,425.205475 842.246826,422.418518 842.919250,418.110168 
	C844.024292,411.030060 841.968567,406.356476 835.404968,404.387238 
	C831.088989,403.092346 826.221497,403.674744 821.608398,403.320038 
	C816.985596,402.964630 815.361023,405.562531 815.279785,409.632233 
	C815.203308,413.459442 815.367615,417.292358 815.255066,421.117737 
	C815.161621,424.293732 816.577393,425.692505 819.700867,425.662476 
	C823.529114,425.625641 827.358337,425.693146 832.096802,425.714386 
M815.270508,379.428162 
	C815.293396,386.865356 815.392151,386.973145 822.698364,386.956268 
	C826.185852,386.948212 829.687195,387.090363 833.156982,386.828857 
	C837.179810,386.525635 839.863098,382.933411 839.816162,378.402618 
	C839.768250,373.785553 837.115356,370.618988 832.878723,370.327362 
	C829.898438,370.122253 826.904602,370.077423 823.915833,370.038574 
	C815.361267,369.927307 815.327698,369.975616 815.270508,379.428162 
z"
          />
          <path
            fill="#507ABE"
            opacity="1.000000"
            stroke="none"
            d="
M631.001465,166.120300 
	C631.360352,166.263641 631.719177,166.406982 632.406555,166.875031 
	C637.319336,175.368118 641.877319,183.551544 646.533081,191.678955 
	C646.948425,192.403992 647.998779,192.765320 648.754211,193.295563 
	C648.754211,193.295563 648.692627,193.369171 648.668640,193.413513 
	C651.317261,199.762863 653.972290,206.075378 656.667847,212.370560 
	C659.043335,217.918304 661.733704,223.356705 663.635559,229.059982 
	C664.011963,230.188858 661.435242,232.302536 660.031860,234.205505 
	C659.423889,234.476059 659.004517,234.503204 657.833191,234.567535 
	C648.980713,235.561493 640.880249,236.518265 632.779785,237.475006 
	C632.074097,237.473618 631.368469,237.472214 629.893677,237.532379 
	C626.347473,237.905533 623.570435,238.217117 620.793335,238.528687 
	C619.771545,238.498230 618.749756,238.467789 616.897644,238.450867 
	C606.167725,239.225342 596.274536,240.105316 586.362793,240.642960 
	C584.469543,240.745651 582.502930,239.498093 580.570190,238.873505 
	C580.513977,238.441315 580.457764,238.009155 580.303345,236.888474 
	C578.292786,229.728973 576.380249,223.257996 574.467773,216.787018 
	C574.437317,216.077316 574.406860,215.367599 574.278564,213.934082 
	C569.582214,197.000946 564.983765,180.791595 560.146545,163.740845 
	C561.769653,163.359512 563.874573,162.497833 566.003418,162.433884 
	C572.316589,162.244247 578.639282,162.369049 584.958252,162.370926 
	C585.668335,162.451035 586.378479,162.531143 587.796875,162.766510 
	C592.669922,163.033768 596.834656,163.145752 600.999329,163.257736 
	C602.986267,163.376251 604.973206,163.494766 607.705872,163.806351 
	C615.968201,164.706390 623.484863,165.413345 631.001465,166.120300 
M583.020813,225.713074 
	C583.329468,226.797607 583.638123,227.882126 583.462952,229.431839 
	C584.541138,235.330765 588.416321,236.769241 596.941895,234.086349 
	C599.943665,234.086594 602.945374,234.086823 606.562317,234.640564 
	C607.374146,234.771332 608.217529,235.114426 608.992920,234.999374 
	C615.279114,234.066650 621.549377,233.027542 627.830688,232.060547 
	C636.149109,230.779968 644.464905,229.477661 652.798645,228.303848 
	C656.065979,227.843643 657.828308,226.762817 656.277771,223.107468 
	C654.390198,218.657532 652.917908,214.014572 650.788879,209.689972 
	C649.448730,206.967758 647.214050,204.690353 645.418762,202.186172 
	C644.401184,200.766846 642.832397,199.387360 642.596680,197.826141 
	C641.708740,191.945862 639.838623,186.655334 635.666931,182.256226 
	C634.819702,181.362900 634.701843,179.814178 633.952820,178.781464 
	C633.167969,177.699326 632.028137,176.874649 631.036377,175.209564 
	C629.684753,173.802963 628.333191,172.396378 626.159241,170.861420 
	C625.368347,170.604446 624.591248,170.181931 623.784607,170.114899 
	C606.967590,168.717697 590.154114,167.266449 573.324097,166.044189 
	C566.958679,165.581909 565.823303,167.431961 567.789429,173.825958 
	C570.878479,183.872086 573.940186,193.926636 576.989685,204.813751 
	C577.614441,207.496078 578.147522,210.203964 578.886841,212.854340 
	C579.836243,216.257462 580.951416,219.614365 582.020203,223.591125 
	C582.330872,224.071945 582.641541,224.552765 583.020813,225.713074 
z"
          />
          <path
            fill="#A9B5D9"
            opacity="1.000000"
            stroke="none"
            d="
M278.701080,565.826416 
	C278.341858,565.801636 277.982605,565.776794 277.047546,565.521790 
	C273.965485,565.488525 271.459229,565.685364 268.952972,565.882263 
	C264.947937,565.936646 260.942932,565.991089 256.094635,566.055420 
	C253.068268,566.986450 250.885193,567.907532 248.702103,568.828735 
	C244.442398,568.862488 240.182709,568.896301 234.997299,568.929443 
	C230.633682,568.832520 227.195770,568.736206 223.942017,568.312012 
	C224.547943,567.735535 224.952805,567.305908 225.393936,567.264648 
	C237.433228,566.139526 249.484116,565.132202 261.514008,563.916443 
	C265.366821,563.527100 269.165588,562.603149 273.311035,561.923096 
	C274.059723,561.880920 274.486511,561.839478 275.327484,561.893555 
	C277.833221,561.684082 279.924713,561.379150 282.477753,561.082397 
	C286.936066,561.009399 290.932831,560.928284 295.383698,560.937866 
	C303.220703,560.007629 310.603638,558.986755 318.455475,557.981812 
	C324.918121,557.829773 330.911865,557.661865 337.342194,557.621521 
	C340.142883,557.675537 342.506958,557.601990 345.204529,557.649841 
	C346.353912,557.495300 347.169891,557.219360 348.396240,556.963135 
	C350.168793,556.895996 351.530945,556.809143 353.332886,556.827148 
	C356.635925,556.436890 359.499207,555.941711 362.773438,555.433716 
	C365.116394,555.313721 367.048340,555.206604 369.313843,555.165039 
	C370.100220,555.239685 370.553070,555.248718 371.327454,555.502014 
	C375.763885,555.479797 379.878723,555.213318 384.462341,554.964355 
	C390.052490,554.977478 395.175385,555.049866 400.295013,554.952148 
	C409.847809,554.769836 419.398712,554.488586 429.387299,554.428345 
	C438.841156,554.571167 447.858093,554.534180 457.185455,554.604980 
	C457.706390,554.652222 457.916901,554.591675 458.554810,554.628662 
	C461.297302,554.598389 463.612427,554.470520 466.379944,554.510742 
	C482.224121,554.778687 497.615875,554.878601 513.375732,555.172913 
	C522.298706,555.909058 530.849548,556.528015 539.409546,556.968994 
	C546.644043,557.341614 553.889099,557.507996 561.575806,557.820374 
	C565.343811,557.893799 568.665466,557.912598 572.291077,558.209473 
	C582.088013,559.292053 591.581055,560.096558 601.526001,560.935791 
	C605.312927,560.998230 608.647949,561.025818 612.231445,561.290222 
	C613.995300,561.427063 615.510803,561.327087 617.390747,561.225952 
	C618.725159,561.268982 619.695190,561.313110 620.842651,561.682495 
	C629.398987,562.850342 637.777771,563.692871 646.524170,564.627563 
	C647.621521,564.585876 648.351379,564.452087 649.412231,564.501709 
	C650.496643,564.738831 651.250061,564.792542 652.258179,565.169556 
	C659.403442,566.173889 666.294006,566.854919 673.604431,567.638062 
	C676.346863,567.784302 678.669434,567.828430 681.265198,568.163086 
	C686.115906,569.176331 690.693359,569.898926 695.710083,570.680420 
	C699.767151,570.818054 703.385071,570.896790 707.274963,571.244629 
	C716.752930,572.993652 725.946533,574.556702 735.169861,575.918396 
	C739.830811,576.606567 744.545471,576.930359 749.647644,577.515259 
	C752.046326,577.662537 754.033142,577.713501 756.229370,578.083435 
	C758.943359,578.974426 761.447998,579.546509 763.987549,580.369568 
	C764.017212,580.789429 764.012024,580.958252 764.006897,581.127075 
	C760.592896,581.345886 757.095154,582.152954 753.779358,581.681335 
	C739.576233,579.661377 725.453796,577.073120 711.249207,575.064575 
	C699.471436,573.399353 687.632080,572.138367 675.797119,570.919861 
	C667.238831,570.038757 658.611694,569.786865 650.071594,568.777588 
	C637.666565,567.311646 625.325806,565.308411 612.929565,563.758423 
	C608.172852,563.163696 603.337341,563.213074 598.540710,562.921387 
	C577.370850,561.634155 556.203247,560.307678 535.031433,559.053711 
	C531.711487,558.857117 528.372437,559.010315 525.044495,558.918701 
	C505.454315,558.380127 485.867188,557.497253 466.274384,557.348877 
	C445.219696,557.189392 424.158722,557.642700 403.102600,557.963745 
	C391.313873,558.143494 379.523865,558.465881 367.744354,558.960205 
	C352.337189,559.606689 336.939819,560.486816 321.249084,561.529175 
	C317.246063,562.132812 313.532227,562.473755 309.002289,562.832031 
	C307.129700,562.868286 306.073212,562.887268 305.016724,562.906250 
	C300.655609,562.924072 296.294464,562.941833 291.018097,562.956177 
	C286.256531,563.729187 281.547974,561.084534 278.701080,565.826416 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M711.293823,368.884521 
	C712.500061,369.262054 713.706299,369.639557 715.087097,370.071716 
	C715.087097,366.094421 715.069580,362.266754 715.091187,358.439301 
	C715.123962,352.630341 717.717896,350.087250 723.658142,350.029388 
	C725.990601,350.006683 728.323486,350.026764 730.656189,350.030853 
	C737.154358,350.042175 739.877014,352.683685 739.891724,359.216919 
	C739.931519,376.878723 739.872070,394.540802 739.937927,412.202454 
	C739.959290,417.937683 742.748108,422.091614 746.890137,423.066589 
	C755.476624,425.087708 760.968079,423.735107 763.844910,418.794434 
	C764.408386,417.826691 764.976990,416.674805 764.989685,415.601288 
	C765.218323,396.335968 765.428772,377.069824 765.472046,357.803436 
	C765.481750,353.473358 768.142761,350.157227 772.580322,350.052155 
	C776.077209,349.969360 779.578125,350.026459 783.076904,350.056671 
	C788.653931,350.104767 789.853943,351.174713 789.885620,356.756653 
	C789.960144,369.919342 789.908997,383.082764 789.905029,396.245911 
	C789.903076,402.910736 789.631714,409.588074 789.948730,416.237457 
	C790.413818,425.990814 778.553528,440.884796 767.617981,443.483246 
	C761.584534,444.916901 755.783142,446.143738 749.712952,445.919250 
	C737.535461,445.468842 727.290222,440.963501 720.295898,430.555664 
	C718.788940,428.313324 717.884949,425.665771 716.579529,422.947235 
	C714.733032,425.608276 713.231445,427.772278 711.308960,429.574951 
	C711.007141,428.396729 710.957764,427.508148 711.270569,426.773743 
	C715.754395,416.244537 713.885620,406.740631 705.353760,399.094147 
	C702.183228,396.252655 698.428040,394.063477 695.062622,391.256653 
	C703.088806,392.204468 707.809998,397.627594 711.925964,403.809021 
	C712.546936,404.741516 712.946350,405.821625 713.448242,406.833435 
	C713.834167,406.729340 714.220032,406.625244 714.605957,406.521149 
	C714.605957,399.363739 714.605957,392.206329 714.605957,385.089417 
	C713.508118,384.707184 712.226990,384.261139 711.150391,383.501282 
	C711.929810,380.917511 712.983337,378.640869 712.951538,376.379456 
	C712.916382,373.873871 711.889099,371.382233 711.293823,368.884521 
M768.999878,356.994751 
	C769.015381,375.665344 769.030884,394.335938 768.500671,413.488373 
	C766.722351,422.731720 760.783630,426.430206 750.236877,426.095856 
	C740.941833,425.801178 736.763123,418.146210 736.830811,410.886932 
	C736.978149,395.082428 736.857849,379.275574 736.868469,363.469666 
	C736.876099,352.122467 736.736877,351.966980 725.533142,352.081390 
	C725.200378,352.084808 724.867615,352.080841 724.534851,352.083221 
	C720.065308,352.114960 717.833984,354.284271 717.843567,358.835663 
	C717.880798,376.471222 717.746155,394.108490 717.985352,411.740906 
	C718.025085,414.666260 719.609680,417.570679 720.454773,420.983002 
	C720.458923,421.316559 720.492615,421.647095 720.177917,422.546753 
	C720.734863,430.713989 731.187317,440.424835 740.937317,441.934143 
	C747.796936,442.996063 754.421326,443.533966 761.557495,442.357605 
	C777.591919,439.714294 786.901917,428.408051 787.724915,413.939270 
	C788.782532,395.346558 788.082336,376.648132 787.866516,357.998444 
	C787.847168,356.322266 786.106934,353.576935 784.688538,353.218811 
	C781.046448,352.299194 777.103516,352.051178 773.347961,352.357330 
	C771.856934,352.478882 770.543030,354.772827 768.999878,356.994751 
z"
          />
          <path
            fill="#B9CFEA"
            opacity="1.000000"
            stroke="none"
            d="
M469.020172,384.996582 
	C475.049896,400.301331 481.079620,415.606110 487.460938,431.004395 
	C488.118927,432.706848 488.867950,434.383392 488.634674,435.909851 
	C488.217834,438.637604 488.009521,442.254333 486.242218,443.699432 
	C484.082489,445.465393 481.153595,448.742188 477.157990,445.515533 
	C475.987000,444.569885 473.407166,445.368774 470.880066,445.482727 
	C469.216370,445.343567 468.145660,445.089722 467.074951,444.835876 
	C464.509338,438.452179 461.943756,432.068451 458.956879,425.039001 
	C456.360168,424.268982 454.184753,424.144653 452.009338,424.020325 
	C450.939209,424.004486 449.869110,423.988678 447.933472,423.970825 
	C442.428253,424.310333 437.788574,424.651855 433.148865,424.993347 
	C432.179962,429.704407 431.211090,434.415436 429.738525,439.562439 
	C428.138580,441.321198 427.042297,442.644043 425.946045,443.966858 
	C425.946045,443.966888 425.472137,443.970367 424.892761,444.008698 
	C423.494751,445.009186 422.676117,445.971344 421.857483,446.933533 
	C419.189789,446.912018 416.522064,446.890503 413.205383,446.602600 
	C410.053345,445.522614 407.550293,444.709045 405.047241,443.895477 
	C404.957611,443.588348 404.783356,443.340790 404.156708,442.732971 
	C404.022491,439.956421 403.955750,437.518250 404.566620,435.263702 
	C405.312042,432.512512 404.509918,431.200104 401.827209,430.456360 
	C401.839508,429.350433 401.832855,428.612549 402.051880,427.667297 
	C402.599579,426.708557 402.921570,425.957245 403.564697,425.142639 
	C404.227997,424.362518 404.570129,423.645721 405.011902,422.661133 
	C405.249390,422.038025 405.387238,421.682770 405.801788,421.179749 
	C406.246063,420.272614 406.413605,419.513275 406.679871,418.488037 
	C406.913635,417.868958 407.048676,417.515778 407.514709,417.097839 
	C408.096832,416.275604 408.347961,415.518097 408.688904,414.372559 
	C408.844666,412.657104 408.910645,411.329742 409.263702,409.702545 
	C409.368439,400.934235 409.186035,392.465698 409.003021,383.628174 
	C408.658020,381.848541 408.313629,380.437897 408.017639,378.686432 
	C407.371063,377.229523 406.676117,376.113434 405.942810,374.697815 
	C405.823364,374.003998 405.742310,373.609772 405.763824,372.881744 
	C404.894012,371.381836 403.921631,370.215698 402.858002,368.856995 
	C402.766724,368.664398 402.591064,368.275970 402.634827,367.981049 
	C402.217529,367.382202 401.756470,367.078278 401.272217,366.751404 
	C401.249054,366.728455 401.215088,366.672729 401.172150,366.374847 
	C400.517181,365.635651 399.905121,365.194336 399.190002,364.594910 
	C399.086945,364.436829 398.863159,364.132904 398.819580,363.790863 
	C397.602173,362.565491 396.428345,361.682129 395.222412,360.805664 
	C395.190308,360.812500 395.186981,360.746887 395.130005,360.441956 
	C394.413574,359.729736 393.754120,359.322418 393.054901,358.949707 
	C393.015106,358.984344 393.074249,358.897003 392.991821,358.613678 
	C392.492920,357.686554 392.076416,357.042755 391.659943,356.398956 
	C397.947205,356.166962 400.619843,361.487335 403.784149,365.179962 
	C410.796051,373.362671 413.089294,383.458740 412.940704,394.129181 
	C412.913055,396.114502 412.935181,398.100494 412.929810,400.878113 
	C412.928345,402.101898 412.931396,402.533722 412.926758,403.340302 
	C412.888672,404.460571 412.858337,405.206146 412.572449,406.231506 
	C411.799927,409.624878 411.282990,412.738403 410.766022,415.851929 
	C411.352051,415.964935 411.938110,416.077972 412.524170,416.190979 
	C413.375153,412.805786 414.226166,409.420624 415.093170,405.818909 
	C415.109161,405.602356 415.145935,405.169617 415.497650,405.071045 
	C416.261658,403.661743 416.673950,402.351013 417.086243,401.040283 
	C419.112732,395.988708 421.139221,390.937103 423.511902,385.396545 
	C424.639069,382.642242 425.420044,380.376892 426.201050,378.111572 
	C426.729034,376.660645 427.257050,375.209686 428.238464,373.254333 
	C430.147736,368.833557 431.603668,364.917175 433.059570,361.000824 
	C433.848297,353.324432 437.468811,349.428192 445.820709,349.866089 
	C457.117584,350.458435 457.041595,350.006927 460.015411,361.134583 
	C460.783966,364.010315 462.403748,366.658539 463.819580,370.085876 
	C465.677795,375.505829 467.348969,380.251190 469.020172,384.996582 
M425.903198,387.577240 
	C425.483765,389.183289 425.233887,390.857635 424.618591,392.384766 
	C418.665588,407.159149 412.627258,421.899139 406.676117,436.674286 
	C405.586426,439.379730 406.446472,441.500031 409.547852,441.609650 
	C413.853516,441.761871 418.178864,441.531158 422.486755,441.298126 
	C423.321320,441.252960 424.550232,440.637756 424.852264,439.955658 
	C426.234283,436.834229 427.572266,433.659271 428.517883,430.385681 
	C430.567108,423.291473 432.234924,421.889038 439.550079,421.885162 
	C444.546753,421.882507 449.603241,422.366333 454.523834,421.754547 
	C459.829559,421.094879 462.081848,424.006653 463.896637,427.964844 
	C464.437103,429.143677 464.240814,430.725250 464.928070,431.759552 
	C466.994232,434.869049 466.071564,440.154816 470.693390,441.202057 
	C474.179657,441.992035 477.975494,441.701294 481.609344,441.485199 
	C486.655701,441.185150 487.492615,439.344513 485.354645,434.824310 
	C483.536835,430.980957 481.860199,427.054688 480.391724,423.065460 
	C476.777924,413.248260 473.390442,403.347748 469.780609,393.528992 
	C465.637909,382.260651 461.599976,370.941040 456.958527,359.877777 
	C455.792236,357.097839 452.778809,353.805237 450.104431,353.263062 
	C446.553162,352.543091 441.533356,350.883514 438.920654,355.824463 
	C437.456970,358.592468 436.424194,361.590942 435.235687,364.501282 
	C432.197327,371.941528 429.180481,379.390564 425.903198,387.577240 
z"
          />
          <path
            fill="#4377BC"
            opacity="1.000000"
            stroke="none"
            d="
M480.313416,368.905792 
	C480.231171,368.340881 480.148926,367.776001 479.910980,366.616821 
	C479.669128,365.032745 479.582977,364.042938 479.496826,363.053131 
	C479.620087,360.127380 479.743378,357.201630 480.166595,353.735535 
	C482.582214,352.547485 484.694702,351.349396 486.814056,351.337067 
	C508.199829,351.212769 529.586731,351.282593 550.973267,351.297913 
	C551.432434,351.726562 551.891602,352.155243 552.428467,352.807281 
	C552.587524,356.852264 552.588806,360.677704 552.771423,364.494446 
	C552.978577,368.822357 551.389160,371.011993 546.700623,370.766632 
	C542.054565,370.523560 537.370300,370.994965 532.726074,370.735962 
	C528.325684,370.490570 526.556335,371.773956 526.616272,376.581543 
	C526.861023,396.221405 526.703491,415.866211 526.700623,435.509216 
	C526.699524,442.955261 526.349426,443.302673 518.697937,443.301971 
	C516.700439,443.301788 514.700256,443.382477 512.705872,443.306458 
	C506.689148,443.077148 505.735992,442.070709 505.546661,435.076904 
	C505.469788,428.082153 505.403290,421.960327 505.336792,415.838501 
	C505.414520,414.173187 505.492279,412.507904 505.496643,410.431763 
	C505.486877,398.300110 505.550507,386.579285 505.558777,373.946655 
	C504.274353,372.288147 503.062439,370.933472 501.813202,370.898102 
	C495.882355,370.730011 489.941315,370.921509 484.003906,370.984924 
	C483.582489,370.853424 483.161072,370.721924 482.232117,370.463013 
	C481.254211,369.859009 480.783813,369.382416 480.313416,368.905792 
M507.959015,401.500000 
	C507.963409,412.663940 508.091339,423.829895 507.906158,434.990692 
	C507.840118,438.971436 509.346619,440.392456 513.199219,440.135834 
	C516.488892,439.916687 519.804749,440.090485 523.706665,440.090485 
	C523.827515,438.336304 524.071838,436.415710 524.074829,434.494720 
	C524.104614,415.332855 524.098511,396.170868 524.093872,377.008942 
	C524.091797,368.421906 525.438660,367.121490 534.300354,367.061493 
	C538.292603,367.034485 542.304932,367.136658 546.270874,366.773071 
	C551.339233,366.308441 549.905640,362.248383 550.086609,359.131805 
	C550.304260,355.384918 547.627380,355.885773 545.336365,355.883575 
	C528.507202,355.867371 511.677979,355.879883 494.848846,355.849915 
	C493.028381,355.846649 491.163788,355.841370 489.397278,355.468964 
	C485.707062,354.690887 482.598053,354.829956 481.989929,359.452728 
	C481.266815,364.949158 482.930359,366.914124 488.353485,367.050903 
	C492.516541,367.155884 496.684235,367.068420 500.849823,367.085114 
	C506.720123,367.108673 507.892609,368.190979 507.935028,374.006683 
	C507.999481,382.837372 507.956329,391.668854 507.959015,401.500000 
z"
          />
          <path
            fill="#90AFDB"
            opacity="1.000000"
            stroke="none"
            d="
M573.943420,349.366699 
	C577.328186,349.379547 580.712891,349.392395 584.558838,349.445679 
	C591.472107,352.732483 591.988098,359.720856 594.533997,365.255188 
	C598.159180,373.135620 600.817078,381.461761 603.872681,389.603302 
	C607.508057,399.289276 611.122314,408.983185 614.754883,418.670227 
	C616.887451,424.357361 619.301392,429.955719 621.103821,435.745026 
	C622.810303,441.226288 621.597778,443.541962 616.225586,444.375641 
	C612.214355,444.998169 607.959045,444.607880 603.881653,444.106110 
	C598.496277,443.443390 598.168091,438.246277 596.256592,434.557220 
	C595.813538,433.702118 595.845886,432.612885 595.569031,431.657593 
	C593.290588,423.793457 593.284119,423.792511 585.135132,423.776154 
	C580.472412,423.766785 575.807434,423.853455 571.147705,423.734253 
	C568.162354,423.657898 566.248657,424.158661 565.553772,427.755463 
	C564.877991,431.253204 562.960571,434.496155 561.755920,437.910126 
	C559.803345,443.444031 555.173950,444.543396 550.244385,444.679199 
	C546.967468,444.769501 543.551636,444.473572 540.407776,443.597717 
	C536.193848,442.423798 536.716858,438.911530 537.889893,435.820831 
	C544.304321,418.919769 550.775085,402.039368 557.357544,385.203156 
	C561.474670,374.672913 565.822815,364.233032 570.086060,353.382690 
	C570.493958,352.299469 570.882996,351.586121 571.613892,350.541443 
	C572.618347,349.928955 573.280884,349.647858 573.943420,349.366699 
M548.486023,441.158875 
	C551.362549,440.782440 555.081055,441.370575 556.900024,439.782654 
	C559.116516,437.847809 560.271851,434.197998 560.939575,431.086823 
	C562.601135,423.345215 564.850830,421.205933 572.707336,421.193848 
	C577.533386,421.186432 582.359436,421.166901 587.185486,421.156036 
	C594.825745,421.138794 596.254517,422.309326 597.878113,429.907135 
	C600.216431,440.849457 600.655579,441.209137 611.622314,441.169250 
	C612.620789,441.165619 613.619812,441.184906 614.617737,441.160797 
	C619.176697,441.050781 619.989319,439.542053 618.261475,435.267517 
	C613.187866,422.715942 608.096436,410.167847 603.295654,397.510803 
	C599.574097,387.699066 596.393982,377.682861 592.738098,367.844879 
	C591.269104,363.891846 589.273621,360.118408 587.294800,356.381287 
	C586.743652,355.340454 585.515198,354.287750 584.394104,353.978973 
	C576.663940,351.849792 570.902405,355.720306 568.737366,363.282074 
	C566.537781,370.964661 563.620361,378.454834 560.763000,385.932037 
	C558.253296,392.499115 555.309204,398.898773 552.706787,405.432037 
	C549.603333,413.222809 546.593994,421.052795 543.674438,428.914307 
	C542.262268,432.716888 541.148376,436.630249 539.682800,441.163422 
	C542.933167,441.163422 545.234009,441.163422 548.486023,441.158875 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M244.472534,598.769958 
	C244.190643,598.008240 243.908752,597.246460 243.457794,595.863586 
	C242.625854,594.401672 241.962982,593.560852 241.300110,592.720093 
	C241.300110,592.720093 241.262436,592.935486 241.337845,592.440063 
	C240.347443,588.098938 241.976776,586.685669 245.787735,586.227173 
	C250.633255,585.644226 255.398514,584.394226 260.198395,583.431824 
	C263.172943,583.400757 266.147461,583.369629 270.024933,583.327759 
	C279.614899,582.302612 288.301941,581.288269 296.988983,580.273926 
	C300.027740,580.278809 303.066528,580.283630 306.557312,580.225708 
	C307.507141,580.197693 308.005005,580.263855 308.502716,580.262756 
	C322.846313,580.230103 322.533600,580.301453 325.771210,594.277405 
	C327.880920,603.384644 330.760162,612.349182 333.847168,621.184631 
	C336.993073,630.188538 340.839478,638.946655 344.332977,647.830566 
	C346.621307,653.649780 344.844696,656.236450 338.809204,655.883301 
	C320.576935,654.816711 302.356537,653.465942 284.111053,652.742004 
	C275.883698,652.415466 272.873749,646.326538 269.438232,640.919556 
	C262.601074,630.158813 256.348755,619.026611 249.839050,608.057251 
	C249.338638,607.214050 248.714050,606.444458 247.852539,605.440063 
	C247.557877,605.239441 247.486435,605.064331 247.387680,604.649902 
	C246.350113,602.413635 245.411316,600.591797 244.472534,598.769958 
M321.115326,591.330933 
	C321.069397,590.888855 321.023468,590.446716 320.999481,589.199524 
	C319.907745,586.959351 318.853119,582.836670 317.717651,582.814270 
	C313.456024,582.730225 309.168030,583.984619 304.001282,584.792725 
	C301.018005,584.824890 298.034729,584.857056 294.180328,584.850769 
	C292.447357,584.881042 290.714386,584.911316 288.120300,584.911316 
	C286.398834,584.984680 284.677399,585.058105 282.501648,585.090393 
	C282.501648,585.090393 282.049866,585.153381 281.207397,585.079651 
	C279.802338,585.134766 278.397308,585.189819 276.255188,585.130310 
	C275.084656,585.713135 273.914124,586.296021 271.875427,586.857117 
	C264.766266,587.268188 257.644592,587.533325 250.552277,588.140869 
	C245.678680,588.558350 245.180359,589.705627 246.984146,594.221313 
	C253.382721,610.239929 262.309662,624.851074 272.044556,639.673462 
	C272.611115,640.154663 273.177673,640.635864 273.934509,641.782776 
	C276.596039,644.129272 278.932587,647.802429 281.985718,648.550354 
	C288.661804,650.185730 295.691711,650.450378 302.596283,651.056091 
	C308.037964,651.533447 313.509003,651.672302 318.965851,651.981628 
	C326.165253,652.389648 333.363525,652.817322 342.011414,653.320923 
	C337.101349,640.304810 332.675903,628.573242 328.162964,616.026978 
	C326.491669,609.289124 324.865265,602.539429 323.089264,595.829285 
	C322.879761,595.037720 321.849548,594.463318 321.185516,593.374878 
	C321.185516,593.374878 321.096344,592.971069 321.111145,592.484619 
	C321.111145,592.484619 321.092834,591.997620 321.115326,591.330933 
z"
          />
          <path
            fill="#38459A"
            opacity="1.000000"
            stroke="none"
            d="
M457.644196,710.981567 
	C458.381104,701.214050 459.116028,691.940979 459.802917,682.664246 
	C460.027893,679.625488 461.057739,677.687195 464.600281,677.630066 
	C480.919922,677.367310 497.236786,676.937134 513.556030,676.644775 
	C517.359985,676.576599 518.815857,678.310852 517.055664,682.031738 
	C510.873810,695.099426 504.196045,707.931702 494.414337,718.679565 
	C492.172821,721.142456 487.824921,722.454407 484.294891,722.815735 
	C477.359131,723.525818 470.317261,723.234375 463.318787,723.285828 
	C457.577759,723.328064 457.082245,722.758667 457.350891,716.965698 
	C457.435760,715.135071 457.532318,713.304932 457.644196,710.981567 
M502.578400,698.723206 
	C502.740845,698.198547 502.903259,697.673889 503.761230,696.973633 
	C506.044891,692.055603 508.328552,687.137573 511.175232,681.006958 
	C499.412109,681.006958 489.191895,681.006958 478.180664,680.983093 
	C476.131653,681.304932 474.082672,681.626770 471.560547,681.427612 
	C468.331726,681.489380 464.898621,681.258118 464.935425,686.759827 
	C464.928375,687.487122 464.921295,688.214355 464.338623,689.556458 
	C464.202087,698.067505 463.967957,706.578735 463.980530,715.089600 
	C463.985077,718.180664 466.044647,719.503296 469.021240,719.005127 
	C472.185150,718.475525 475.298676,717.650452 478.456818,717.079346 
	C484.915466,715.911438 492.684235,717.243469 494.731873,707.997131 
	C494.973969,706.903870 496.591400,706.164978 497.425049,705.142578 
	C499.044952,703.155945 500.560974,701.084595 502.578400,698.723206 
z"
          />
          <path
            fill="#9CA5CB"
            opacity="1.000000"
            stroke="none"
            d="
M477.011292,659.752075 
	C472.850037,659.749268 469.184082,659.823181 465.522980,659.722046 
	C462.009216,659.625122 459.765503,658.068176 460.139343,654.173828 
	C460.723755,648.086121 461.538391,642.016663 461.973145,635.919373 
	C462.504028,628.473938 462.821442,621.010559 463.089874,613.549866 
	C463.490051,602.427124 463.737366,591.298889 464.110077,580.175049 
	C464.262421,575.627808 467.315094,574.249756 471.211121,574.347229 
	C478.672272,574.533752 486.127533,574.943909 493.586670,575.223450 
	C510.517761,575.857971 527.448242,576.512085 544.382202,577.060974 
	C548.660461,577.199646 549.276367,579.198425 548.383728,582.944885 
	C546.368835,591.401733 544.770752,599.957397 542.788879,608.422729 
	C541.296021,614.799255 539.500793,621.106201 537.783020,627.428589 
	C536.503418,632.138367 535.822693,637.180176 533.626709,641.428711 
	C530.916199,646.672607 532.544861,654.048401 525.997314,657.331055 
	C524.572205,658.045471 522.947998,658.726440 521.397278,658.769714 
	C506.769989,659.178162 492.138824,659.444763 477.011292,659.752075 
M525.259949,653.232544 
	C525.259949,653.232544 525.339661,653.216675 526.019287,653.039001 
	C527.977234,648.425842 530.409851,643.950562 531.798889,639.172058 
	C535.163269,627.597839 538.196350,615.920044 541.056152,604.209106 
	C542.695679,597.495117 543.950378,590.663208 544.858521,583.815430 
	C545.033203,582.498108 542.905762,579.690552 541.641541,579.564453 
	C534.761047,578.877930 527.818237,578.815857 519.999756,578.302429 
	C503.364899,578.307617 486.730011,578.312805 469.310608,578.144897 
	C468.568268,579.593323 467.326324,580.990906 467.172760,582.499207 
	C466.604950,588.076721 466.361389,593.688843 466.062714,599.291565 
	C465.747040,605.213745 465.507538,611.139893 465.091736,617.982788 
	C465.031525,624.639587 464.971344,631.296448 464.488525,638.361328 
	C464.604065,639.259888 464.719604,640.158386 464.758209,641.865112 
	C464.556824,644.255005 464.355469,646.644958 463.537842,649.425049 
	C463.949799,651.012390 464.361725,652.599792 464.920319,654.102478 
	C464.920319,654.102478 464.805511,654.226929 464.994537,655.033142 
	C466.019775,655.405823 467.044952,656.102112 468.070251,656.102356 
	C482.310852,656.105591 496.551514,656.025696 510.791748,655.910278 
	C511.546753,655.904175 512.297668,655.399841 513.942749,655.164856 
	C517.355103,654.883179 520.767456,654.601501 524.681030,654.124023 
	C524.847168,653.823181 525.013306,653.522278 525.259949,653.232544 
z"
          />
          <path
            fill="#676CA5"
            opacity="1.000000"
            stroke="none"
            d="
M707.792786,618.808167 
	C705.044983,621.927612 702.420715,624.687378 700.033936,627.639160 
	C694.084839,634.996521 686.420105,639.907959 677.398132,642.100220 
	C666.498413,644.748779 655.389221,646.542053 644.355286,648.623535 
	C642.951294,648.888489 641.454224,648.659851 638.737061,648.659851 
	C639.907471,646.092407 640.452637,644.305054 641.444824,642.815369 
	C648.229675,632.628052 655.256042,622.598816 661.903625,612.323975 
	C666.190308,605.698364 669.833313,598.657349 674.080444,592.004028 
	C674.937500,590.661438 677.284668,589.381165 678.826172,589.508545 
	C688.905762,590.341248 698.949219,591.602783 709.012268,592.649292 
	C712.476318,593.009521 715.982727,593.005676 719.429565,593.468140 
	C720.942871,593.671143 723.309570,594.346069 723.589661,595.303711 
	C724.008362,596.734924 723.373474,598.940247 722.406189,600.231750 
	C717.755981,606.440735 712.843628,612.453430 707.792786,618.808167 
M700.589294,621.724365 
	C701.148865,620.868042 701.708374,620.011719 702.814941,618.779907 
	C705.266846,615.627380 707.718811,612.474854 710.810608,609.101318 
	C711.597168,608.101440 712.383728,607.101562 713.756653,605.770386 
	C715.714600,603.336304 717.672485,600.902161 720.522705,597.358704 
	C712.161133,596.368347 705.623352,595.678711 699.111816,594.795593 
	C694.057312,594.110046 689.026794,593.232910 684.002747,592.344177 
	C681.003967,591.813782 678.152954,591.063965 676.103821,594.453735 
	C668.048279,607.779297 659.935791,621.071106 651.742493,634.312256 
	C649.643250,637.704834 647.170410,640.866211 644.119324,645.198486 
	C647.764893,644.909851 649.931030,644.981873 651.983032,644.532471 
	C659.691345,642.843994 667.630737,641.704590 674.964233,638.967896 
	C681.057739,636.693909 686.422302,632.466248 692.000427,629.000000 
	C692.000427,629.000000 692.103699,629.109741 692.739990,628.876099 
	C694.190735,627.320740 695.641479,625.765381 697.715088,624.065125 
	C698.466370,623.379272 699.217590,622.693420 700.009705,622.009460 
	C700.009705,622.009460 700.007568,621.968628 700.589294,621.724365 
z"
          />
          <path
            fill="#4453A0"
            opacity="1.000000"
            stroke="none"
            d="
M379.745697,680.640381 
	C380.809387,679.085571 381.963684,676.842590 383.111145,676.846130 
	C399.567841,676.896240 416.024170,677.221069 432.479095,677.554138 
	C435.598938,677.617310 437.187744,679.220215 437.369629,682.565979 
	C437.874298,691.849304 438.631042,701.118835 439.274292,710.394836 
	C439.366302,711.721863 439.328094,713.058838 439.441010,714.383423 
	C440.211731,723.426941 440.219421,723.446716 430.827820,723.375916 
	C425.004608,723.332031 419.157410,722.847046 413.366028,723.234253 
	C407.665924,723.615417 403.394684,721.219910 400.723572,716.871216 
	C393.482971,705.083313 386.760864,692.976929 379.745697,680.640381 
M396.997803,699.998535 
	C396.997803,699.998535 396.941315,700.053162 396.905457,700.831726 
	C397.551971,702.830322 397.770325,705.135254 398.934967,706.763184 
	C401.287872,710.052185 404.154572,712.973511 406.991302,716.833496 
	C408.308044,717.570618 409.610962,718.915710 410.943787,718.945984 
	C419.475220,719.139832 428.013245,719.044861 436.866577,719.044861 
	C436.866577,714.287476 437.147736,710.155029 436.739441,706.091797 
	C436.555145,704.257446 434.998199,702.561035 434.082977,699.968933 
	C433.750793,695.882874 433.550323,691.780090 433.037109,687.716919 
	C432.727936,685.269165 433.047211,682.156860 429.181091,681.933350 
	C424.103119,681.639709 419.028229,681.292908 413.317474,680.958435 
	C412.894440,680.962097 412.471405,680.965759 411.101501,680.933838 
	C405.472565,680.936646 399.827209,680.699829 394.224274,681.083069 
	C392.462616,681.203491 389.961761,682.598022 389.278717,684.087769 
	C387.408539,688.166870 392.462524,698.024475 396.997803,699.998535 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M632.701050,632.800354 
	C630.493958,636.938843 627.987305,640.948853 626.144592,645.243652 
	C622.713074,653.241455 615.568909,653.160645 608.697083,653.817810 
	C598.985901,654.746399 589.283203,655.857056 579.549805,656.430847 
	C571.470947,656.907104 563.348022,656.932800 555.254089,656.746765 
	C550.445740,656.636169 549.524475,654.037048 551.379822,649.700989 
	C554.865540,641.554504 557.999634,633.252197 561.096619,624.946411 
	C563.675293,618.030701 566.226624,611.087891 568.353760,604.025635 
	C570.257507,597.704895 571.780151,591.247375 573.069763,584.770386 
	C574.138306,579.403137 576.720276,577.848999 582.227539,578.816528 
	C587.263794,579.701233 592.471558,579.565186 597.588928,580.039795 
	C615.672852,581.716675 633.760620,583.364746 651.823853,585.244995 
	C656.914368,585.774902 658.143188,588.276062 655.932678,592.855957 
	C652.550110,599.864014 649.029114,606.808777 645.408264,613.697083 
	C642.811096,618.637939 640.033020,623.489380 637.170410,628.282043 
	C636.194336,629.916260 634.715088,631.250000 633.106567,632.337769 
	C633.351868,630.638184 633.912842,629.299622 634.568481,628.009094 
	C637.765015,621.716736 640.986694,615.437195 644.316101,608.988525 
	C644.432617,608.823914 644.559448,608.441040 644.835999,608.257324 
	C648.476013,601.682190 651.839417,595.290771 656.041077,587.306458 
	C650.860718,587.306458 647.357361,587.306458 643.503540,587.229980 
	C642.437683,587.105286 641.722168,587.057129 640.798340,586.680054 
	C632.049500,585.796997 623.509033,585.242859 614.650940,584.667725 
	C613.699463,584.589294 613.065674,584.531799 612.207397,584.170776 
	C601.367432,582.925720 590.760193,581.846313 580.126404,581.212341 
	C578.753052,581.130493 576.521790,583.079285 575.952942,584.570129 
	C574.935425,587.237183 574.881165,590.271729 574.336121,593.463806 
	C574.248535,594.205200 574.249023,594.635437 573.918396,595.219971 
	C572.880432,597.975403 572.173584,600.576538 571.377930,603.525635 
	C571.195251,604.585571 571.101318,605.297363 570.717773,606.186768 
	C569.832397,608.583618 569.236755,610.802917 568.601440,613.276733 
	C568.520996,613.872131 568.480408,614.213074 568.160645,614.751099 
	C565.736694,621.026978 563.591919,627.105652 561.347107,633.528198 
	C561.160828,634.583252 561.074524,635.294495 560.658264,636.137207 
	C557.839478,641.940796 555.350769,647.612793 552.416504,654.300293 
	C564.165588,655.718994 574.620483,654.567139 585.470337,651.988220 
	C591.558716,651.861450 597.179565,651.724915 603.230774,651.729797 
	C607.414856,651.419983 611.201477,651.148438 614.913086,650.467590 
	C618.718811,649.769348 621.639282,648.110901 623.725830,644.235352 
	C625.989075,640.031494 629.649780,636.579956 632.701050,632.800354 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M747.174988,241.226685 
	C756.966492,239.726562 766.794373,238.429245 776.541321,236.680893 
	C792.948303,233.737915 809.287231,230.414780 825.697083,227.489014 
	C828.540405,226.982071 831.592651,227.647110 834.548340,227.770813 
	C834.650330,228.601440 834.752319,229.432068 834.854248,230.262711 
	C778.217590,252.761871 720.543823,271.959412 660.421387,283.109497 
	C661.889648,281.710693 663.442566,280.833405 665.394165,279.940186 
	C667.863037,279.637299 669.933228,279.350342 672.235474,279.248962 
	C674.745483,278.420959 677.023438,277.407379 679.669495,276.361084 
	C681.023621,276.302734 682.009705,276.277069 683.261597,276.443909 
	C687.382202,275.123779 691.236938,273.611176 695.510681,272.092957 
	C698.155640,271.665771 700.411133,271.359955 702.602478,270.802185 
	C711.404968,268.561798 720.187622,266.243469 729.375488,263.992737 
	C733.516235,262.701538 737.259216,261.371521 741.325928,260.026123 
	C742.072998,259.925598 742.496155,259.840485 743.290222,259.842133 
	C745.773987,258.960907 747.886902,257.992889 750.365601,257.016357 
	C751.454834,256.908112 752.178284,256.808380 753.285400,256.784851 
	C758.788147,254.947983 763.907104,253.034927 769.387939,251.075745 
	C771.149353,250.640381 772.548828,250.251160 774.333435,249.916626 
	C790.051453,244.464203 805.384338,238.957092 820.717285,233.449997 
	C820.626709,232.907333 820.536133,232.364685 820.445557,231.822021 
	C818.939331,231.822021 817.391602,231.594788 815.934021,231.861160 
	C809.425598,233.050568 802.928589,234.313538 796.457520,235.691269 
	C793.604858,236.298615 790.823792,237.242477 787.586182,238.027267 
	C785.474487,238.127487 783.786865,238.233994 781.688721,238.234406 
	C778.508667,239.075409 775.739136,240.022491 772.730835,241.215256 
	C770.044922,241.433472 767.597900,241.405991 764.725708,241.265839 
	C761.555481,241.588898 758.815674,242.062149 756.063843,242.450409 
	C753.012512,242.880936 749.950317,243.233871 746.917908,243.317871 
	C747.020325,242.418976 747.097656,241.822830 747.174988,241.226685 
z"
          />
          <path
            fill="#141F8A"
            opacity="1.000000"
            stroke="none"
            d="
M756.020020,577.764526 
	C754.033142,577.713501 752.046326,577.662537 749.478394,577.228333 
	C747.240173,576.174927 745.636230,575.156372 743.916687,574.892944 
	C734.977661,573.523682 726.022217,572.237427 717.044006,571.160217 
	C713.737183,570.763489 710.352234,571.018005 707.002930,570.975525 
	C703.385071,570.896790 699.767151,570.818054 695.742126,570.510376 
	C695.334961,570.281372 695.328796,569.933350 695.480469,569.812561 
	C695.809753,569.486755 695.934021,569.253296 696.361328,568.831665 
	C697.511169,568.453186 698.324463,567.989502 699.094604,568.052734 
	C709.247192,568.885986 719.388000,569.868164 729.544556,570.645874 
	C733.678101,570.962341 737.865479,571.151917 741.980286,570.813904 
	C743.341431,570.702087 744.552002,568.757141 745.831482,567.650391 
	C745.000122,566.791260 744.300598,565.402710 743.316406,565.157715 
	C736.144287,563.372437 728.395020,563.633301 722.060486,558.918762 
	C721.429016,558.448853 720.500000,558.386719 719.718201,558.109558 
	C714.790283,556.362915 709.864990,554.608948 705.382874,552.835449 
	C712.342224,554.444458 718.886658,555.968689 725.363953,557.738770 
	C731.052551,559.293335 736.659241,561.145630 742.314026,562.826355 
	C749.791626,565.048889 757.308960,567.142212 764.756653,569.459900 
	C771.145447,571.448059 777.470032,573.645569 783.803650,575.807251 
	C786.585083,576.756531 789.307739,577.876709 792.076294,578.865540 
	C795.606140,580.126282 799.155396,581.332703 802.696289,582.562561 
	C802.598450,583.045898 802.500549,583.529236 802.402649,584.012573 
	C800.662781,584.012573 798.921997,584.047607 797.183167,584.004089 
	C794.778625,583.943848 792.375427,583.824524 789.541870,583.745483 
	C787.704224,583.944336 786.296387,584.128174 784.888611,584.312012 
	C784.888611,584.312012 784.519897,584.016785 784.246338,583.553345 
	C780.932495,580.819946 777.883118,578.597900 773.925354,581.196594 
	C773.219482,581.205261 772.513672,581.213928 771.121094,581.088074 
	C769.922791,581.067993 769.411194,581.182373 768.899658,581.296753 
	C768.495605,581.188049 768.091553,581.079224 767.336426,580.538818 
	C765.996582,579.722168 765.007874,579.337097 763.915649,578.593384 
	C761.214783,578.078003 758.617371,577.921265 756.020020,577.764526 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M433.995850,657.017456 
	C432.398804,657.696167 430.807648,658.946777 429.203705,658.963257 
	C416.116974,659.098389 403.027863,659.003174 389.326782,658.762207 
	C388.137726,658.671692 387.561340,658.796936 386.984955,658.922119 
	C382.664856,658.915833 378.344788,658.909607 373.497284,658.597412 
	C372.296753,657.908081 371.623627,657.524719 370.950500,657.141357 
	C369.263489,654.248230 367.142456,651.518127 365.981903,648.427368 
	C363.722870,642.411255 361.807404,636.246582 360.104370,630.045959 
	C357.578339,620.848816 355.236023,611.596313 353.056366,602.310791 
	C351.627869,596.225342 350.650757,590.034851 349.422394,583.901123 
	C348.530701,579.448364 350.161469,577.157471 354.584076,577.021790 
	C376.486084,576.350037 398.389374,575.721191 420.291718,575.060181 
	C421.779114,575.015320 423.284302,574.952393 424.743500,574.689026 
	C430.086700,573.724670 432.726868,575.584473 432.902557,580.928406 
	C433.060852,585.743774 432.858124,590.571045 432.331116,595.630615 
	C431.560394,591.298096 431.269775,586.728149 430.987091,582.157837 
	C430.744537,578.236389 429.174103,576.285339 424.561340,578.092407 
	C408.423523,578.244873 392.756683,578.424072 376.782043,578.430786 
	C376.008759,578.264709 375.543243,578.271240 374.616882,578.180359 
	C368.063751,578.403442 361.968414,578.675232 355.880310,579.061340 
	C351.660980,579.328918 350.478424,581.297607 351.699463,585.364746 
	C352.592804,588.340332 353.221771,591.394287 354.015289,594.401001 
	C356.354553,603.264160 358.717041,612.121155 361.072876,621.368164 
	C361.187195,622.776428 361.299103,623.797241 361.333862,625.191650 
	C363.168518,630.713074 365.080322,635.860718 367.026886,641.320007 
	C367.136353,642.041504 367.211029,642.451477 367.197754,643.245850 
	C367.962097,646.281189 368.570984,649.047485 369.753510,651.541809 
	C370.523773,653.166565 372.071045,655.539062 373.368896,655.603943 
	C378.896851,655.880310 384.461548,655.421448 390.466553,655.231079 
	C394.199188,655.272583 397.478119,655.313904 400.927216,655.714233 
	C402.668915,656.347717 404.237762,656.841736 405.812378,656.860657 
	C415.206116,656.973694 424.601227,656.977173 433.995850,657.017456 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M399.992340,515.705933 
	C400.444672,515.797546 400.896973,515.889160 401.672058,515.832153 
	C407.668396,515.776245 413.342010,515.868835 419.927551,516.051636 
	C430.802826,516.140198 440.766388,516.181091 450.729370,516.124268 
	C459.844543,516.072266 468.959076,515.914673 478.073914,515.803589 
	C486.694427,515.973633 495.314972,516.143616 504.513733,516.742432 
	C507.818207,517.646118 510.544434,518.120911 513.661377,518.648682 
	C515.368713,518.755066 516.685303,518.808411 518.160217,519.124146 
	C519.210510,519.284546 520.102417,519.182678 521.462158,519.093872 
	C527.294739,519.044312 532.659485,518.981750 538.024231,518.919189 
	C539.071960,519.032715 540.119629,519.146362 541.756165,519.507996 
	C543.166748,519.936218 543.988464,520.116394 544.930908,520.619873 
	C546.702332,521.643188 548.353210,522.343201 549.762817,523.305542 
	C548.339661,523.708618 547.157593,523.849426 545.543335,523.982544 
	C540.658630,523.635193 536.197693,523.380615 531.755493,522.937622 
	C523.704651,522.134766 515.680115,521.015503 507.615723,520.417725 
	C501.430389,519.959290 495.200684,520.099670 488.540863,519.970215 
	C485.389954,519.972839 482.688873,519.979126 479.520569,519.979797 
	C452.037415,519.959229 425.021515,519.944153 398.004883,519.615662 
	C398.010498,518.472839 398.016815,517.643433 398.325012,516.584595 
	C399.082062,516.138794 399.537201,515.922363 399.992340,515.705933 
z"
          />
          <path
            fill="#BED4EB"
            opacity="1.000000"
            stroke="none"
            d="
M798.850586,441.127350 
	C798.850586,441.127350 799.266602,441.303070 799.679443,441.702087 
	C810.818726,442.252472 821.545105,442.413574 832.272034,442.511505 
	C832.622375,442.514740 832.978638,441.873688 833.332153,441.532959 
	C835.688171,441.516174 838.044189,441.499390 840.795715,441.710571 
	C837.338440,443.269531 833.546814,445.477081 829.620422,445.750854 
	C821.179565,446.339447 812.667114,446.049988 804.188110,445.894196 
	C797.534607,445.771912 794.083618,442.312103 794.060974,435.578522 
	C793.974426,409.800629 793.982910,384.022125 794.069946,358.244202 
	C794.090393,352.184723 796.535889,350.001801 802.585449,349.998444 
	C812.564087,349.992950 822.549194,349.823975 832.517273,350.154999 
	C835.357849,350.249359 838.148865,351.834808 840.914307,353.376465 
	C840.828186,354.206177 840.790344,354.395874 840.752502,354.585571 
	C840.145203,354.577484 839.537903,354.569397 838.432373,354.219299 
	C837.503845,353.638824 837.080872,353.206757 836.642090,353.190033 
	C824.895630,352.743652 813.149658,352.232880 801.398315,351.991638 
	C797.680908,351.915283 796.955933,354.771149 796.956726,357.905701 
	C796.963501,384.324188 796.922058,410.742767 797.023193,437.160858 
	C797.028259,438.485352 798.214355,439.805298 798.850586,441.127350 
z"
          />
          <path
            fill="#AFB7D5"
            opacity="1.000000"
            stroke="none"
            d="
M328.950287,705.037109 
	C321.295715,698.805908 313.538757,692.694763 306.030487,686.292053 
	C302.369019,683.169678 299.051849,679.610291 295.838226,676.016724 
	C294.905243,674.973450 294.312866,672.991516 294.628845,671.700317 
	C294.822266,670.909851 297.078613,670.089661 298.344055,670.180664 
	C308.737946,670.926880 319.111176,671.957397 329.502686,672.742859 
	C333.972076,673.080627 338.473328,672.973877 342.953796,673.196411 
	C346.925842,673.393677 351.277374,672.855957 354.741638,674.331909 
	C357.890228,675.673340 360.883972,678.858765 362.532745,681.978271 
	C368.957245,694.133423 377.971954,704.208191 387.065155,714.273987 
	C387.501892,714.757446 387.751221,715.410156 388.061035,716.462402 
	C388.034271,716.940796 387.739471,717.242371 387.424377,717.138489 
	C384.570679,714.666809 381.853699,712.460693 379.531464,709.896912 
	C375.003815,704.898315 370.697205,699.699585 366.274811,694.420288 
	C366.250549,694.256897 366.070007,693.980347 366.058136,693.622620 
	C363.314178,688.947632 360.388275,684.737915 357.913330,680.277954 
	C356.043823,676.908936 353.494476,675.984192 349.803040,675.867065 
	C338.211304,675.499084 326.631042,674.758972 315.048096,674.130249 
	C309.853485,673.848267 304.663055,673.488831 299.470734,673.164551 
	C299.184845,673.563843 298.898956,673.963135 298.613068,674.362488 
	C301.391083,677.457031 303.916595,680.833618 306.996307,683.591248 
	C314.986481,690.745911 323.220520,697.628296 331.233154,704.751709 
	C331.109070,704.883972 330.860291,705.147949 330.545166,705.102783 
	C329.803467,705.050842 329.376862,705.043945 328.950287,705.037109 
z"
          />
          <path
            fill="#5F629C"
            opacity="1.000000"
            stroke="none"
            d="
M789.971680,583.730530 
	C792.375427,583.824524 794.778625,583.943848 797.183167,584.004089 
	C798.921997,584.047607 800.662781,584.012573 802.402649,584.012573 
	C802.500549,583.529236 802.598450,583.045898 802.696289,582.562561 
	C799.155396,581.332703 795.606140,580.126282 792.076294,578.865540 
	C789.307739,577.876709 786.585083,576.756531 783.803650,575.807251 
	C777.470032,573.645569 771.145447,571.448059 764.756653,569.459900 
	C757.308960,567.142212 749.791626,565.048889 742.314026,562.826355 
	C736.659241,561.145630 731.052551,559.293335 725.363953,557.738770 
	C718.886658,555.968689 712.342224,554.444458 705.208313,552.821411 
	C704.589661,552.829468 704.238953,552.865784 704.132385,552.567627 
	C703.288147,551.294922 702.550476,550.320251 702.195190,549.234009 
	C705.394836,549.739014 708.212219,550.355652 711.029541,550.972290 
	C715.462708,552.119507 719.895813,553.266724 724.848633,554.643738 
	C725.894592,554.936951 726.420898,555.000305 726.947266,555.063660 
	C727.329163,555.149353 727.711121,555.235046 728.535950,555.677856 
	C731.639282,556.706604 734.299744,557.378296 736.960144,558.049927 
	C737.696411,558.157043 738.432739,558.264099 739.643066,558.745850 
	C744.891479,560.842712 749.637268,562.649536 754.445251,564.271851 
	C771.775635,570.119324 789.154785,575.823364 806.455566,581.756470 
	C812.668274,583.887085 818.711182,586.512878 824.831543,588.912720 
	C824.830566,589.269165 824.829651,589.625610 824.828735,589.982056 
	C823.189758,590.376404 821.550842,590.770752 819.911926,591.165100 
	C819.184814,591.108643 818.457764,591.052124 817.144897,590.631531 
	C814.003845,589.908875 811.448425,589.550415 808.893066,589.191895 
	C808.474915,589.126587 808.056824,589.061218 807.158325,588.595642 
	C804.805542,587.767883 802.915344,587.405457 801.064575,586.899109 
	C797.355591,585.884521 793.668030,584.791321 789.971680,583.730530 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M708.967346,432.941895 
	C705.284912,436.217285 702.101685,440.744873 697.797546,442.460693 
	C692.020752,444.763550 685.341125,445.954407 679.113708,445.740479 
	C665.034912,445.256744 652.260132,440.450867 645.508789,427.231079 
	C643.196045,422.702606 645.007812,416.066742 644.948608,410.393524 
	C644.947083,410.249878 645.089233,409.987732 645.186279,409.976837 
	C650.650696,409.364990 656.131470,408.238861 661.578796,408.381409 
	C664.851013,408.467072 666.182190,411.593567 667.149780,415.035889 
	C668.092651,418.390045 670.932617,421.210938 672.619019,424.679047 
	C667.826965,423.615997 664.981750,420.867859 664.953064,415.843109 
	C664.931641,412.096985 662.370483,410.618958 659.247742,410.076721 
	C651.964844,408.812134 647.363647,410.117767 645.992371,413.633301 
	C645.835083,414.036469 646.084961,414.598480 646.104858,415.498047 
	C646.129211,417.565002 646.194763,419.220764 646.147217,421.258606 
	C647.441406,431.239685 661.085022,441.851593 670.534302,441.588867 
	C671.609619,441.696381 672.334534,441.706879 673.248657,442.069275 
	C678.172974,443.350159 682.986938,445.384949 687.642883,441.439514 
	C688.271423,441.441132 688.630554,441.430695 689.292847,441.608643 
	C692.962280,441.798645 696.457397,442.133514 698.699341,438.349548 
	C699.646423,438.157745 700.275085,438.018188 701.253784,437.920593 
	C704.058350,436.289032 706.512878,434.615448 708.967346,432.941895 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M820.010620,591.505005 
	C821.550842,590.770752 823.189758,590.376404 824.828735,589.982056 
	C824.829651,589.625610 824.830566,589.269165 824.831543,588.912720 
	C818.711182,586.512878 812.668274,583.887085 806.455566,581.756470 
	C789.154785,575.823364 771.775635,570.119324 754.445251,564.271851 
	C749.637268,562.649536 744.891479,560.842712 740.011780,558.798523 
	C742.562317,558.951172 745.309509,559.139465 747.858154,559.950989 
	C757.914368,563.152954 767.935791,566.470215 777.918213,569.895996 
	C790.015015,574.047363 802.071472,578.317627 814.121582,582.603333 
	C820.871277,585.003906 827.598328,587.474854 834.269226,590.083679 
	C835.284973,590.480835 835.940796,591.798584 836.763062,592.690552 
	C835.630737,593.429321 834.375000,594.934448 833.387268,594.775330 
	C828.919861,594.055664 824.528259,592.865601 820.010620,591.505005 
z"
          />
          <path
            fill="#535B9F"
            opacity="1.000000"
            stroke="none"
            d="
M636.870605,680.831482 
	C636.526306,680.967163 636.182068,681.102844 635.240845,681.241821 
	C634.010925,682.048706 633.377991,682.852295 632.745117,683.655884 
	C632.745117,683.655884 632.312805,683.754578 631.731262,683.800659 
	C627.353394,685.871338 623.605286,687.993591 619.748901,689.896362 
	C614.632446,692.420898 609.431580,694.774109 604.331543,696.862366 
	C605.171936,695.933960 605.901123,695.266724 606.730713,694.765991 
	C618.050354,687.932129 629.420654,681.180969 640.681030,674.251038 
	C642.767090,672.967163 644.423523,670.985229 646.278259,669.325439 
	C645.991333,668.788147 645.704407,668.250793 645.417419,667.713440 
	C641.815918,668.422363 638.083435,670.110413 634.638367,669.649658 
	C625.663391,668.449158 620.148987,673.503784 614.326111,678.865845 
	C610.240051,682.628479 605.547791,685.732849 601.001221,688.989746 
	C600.876221,688.848389 600.633057,688.559692 600.782043,688.265747 
	C606.925171,682.514648 612.770813,676.879028 618.995361,671.698669 
	C621.121216,669.929504 624.098816,668.858459 626.859985,668.242126 
	C633.887512,666.673401 641.012512,665.541260 648.497437,664.177124 
	C651.438965,663.740784 653.981567,663.361633 656.524170,662.982483 
	C656.684265,663.454590 656.844421,663.926758 657.004578,664.398865 
	C655.113953,666.126526 653.294067,667.940491 651.316956,669.562866 
	C647.896362,672.369507 644.378296,675.057312 640.553223,677.847961 
	C639.093811,678.877747 637.982239,679.854614 636.870605,680.831482 
z"
          />
          <path
            fill="#AFB7D5"
            opacity="1.000000"
            stroke="none"
            d="
M710.970703,550.611084 
	C708.212219,550.355652 705.394836,549.739014 701.902588,549.151672 
	C700.822998,549.151306 700.418457,549.121704 699.774658,548.802002 
	C694.639343,547.447327 689.743103,546.382568 684.464478,545.249939 
	C683.047546,545.144531 682.013062,545.106934 680.817749,544.771240 
	C679.407043,544.082214 678.157104,543.691345 676.519287,543.166809 
	C673.743469,542.731079 671.355591,542.428955 668.797119,541.811096 
	C666.046997,540.816650 663.467590,540.137878 660.479736,539.354614 
	C658.382446,539.155701 656.693604,539.061340 654.789185,538.661865 
	C650.695374,537.630920 646.817200,536.905212 642.621216,536.092529 
	C641.865417,536.016174 641.427246,536.026733 640.733459,535.730469 
	C637.961243,534.893250 635.461853,534.252380 632.924072,533.858948 
	C629.266968,533.292114 625.582031,532.905151 621.522461,532.312622 
	C619.784729,532.045166 618.433594,531.906555 617.003540,531.417480 
	C614.278442,530.358765 611.632202,529.650574 608.994141,528.670532 
	C609.029175,528.041077 609.056030,527.683533 609.082825,527.325928 
	C616.030579,528.504761 622.969421,529.739807 629.930603,530.833313 
	C632.542114,531.243591 635.272705,531.026611 637.834106,531.604736 
	C655.309692,535.549500 672.754150,539.632080 690.209839,543.664917 
	C695.488281,544.884399 700.809692,545.944580 706.029358,547.376648 
	C707.787292,547.858948 709.291626,549.265625 710.970703,550.611084 
z"
          />
          <path
            fill="#BCD0E6"
            opacity="1.000000"
            stroke="none"
            d="
M682.679810,172.458618 
	C685.466919,172.331970 688.253967,172.205322 691.501160,172.093597 
	C692.580566,172.771912 693.131836,173.928757 693.829407,174.024994 
	C705.003540,175.566605 711.918823,182.906952 717.915710,191.514114 
	C723.822266,199.991638 730.200562,208.197479 735.410034,217.086243 
	C739.862427,224.683182 736.418701,226.772980 730.233215,227.028900 
	C724.678711,227.258682 719.151123,228.138977 713.509399,228.181427 
	C715.938354,226.759552 718.469666,225.888550 721.401672,225.000015 
	C723.533081,224.654663 725.263794,224.326843 727.409668,224.062897 
	C729.206787,223.996674 730.588623,223.866531 732.325562,223.845352 
	C737.592651,221.821075 733.709900,220.224518 732.349731,218.212814 
	C732.213501,217.634735 732.073853,217.318054 731.971741,216.659729 
	C731.082336,215.404236 730.155396,214.490417 729.249634,213.413147 
	C729.270813,213.249710 729.100891,212.967270 729.126221,212.608276 
	C728.171021,211.051056 727.190552,209.852859 726.205200,208.471161 
	C726.200378,208.287659 725.991089,207.986069 726.003967,207.634216 
	C722.376465,202.497177 718.736145,197.711975 715.040283,192.619934 
	C714.397400,191.794464 713.809998,191.275848 713.161865,190.578217 
	C713.101135,190.399200 712.942200,190.056183 712.937622,189.716400 
	C712.013000,188.520615 711.093018,187.664612 710.054199,186.518311 
	C708.937561,185.176849 707.939697,184.125702 706.845886,182.742554 
	C704.772583,181.398544 702.795288,180.386551 700.518555,179.288818 
	C699.810974,179.128204 699.402832,179.053329 698.849426,178.662384 
	C697.445435,177.935410 696.186584,177.524490 694.523254,177.046005 
	C692.494080,176.842133 690.869507,176.705841 689.120972,176.249374 
	C686.891296,174.772339 684.785583,173.615479 682.679810,172.458618 
z"
          />
          <path
            fill="#305CB3"
            opacity="1.000000"
            stroke="none"
            d="
M718.196655,247.677383 
	C718.898560,247.537674 719.600464,247.397949 720.810181,247.431244 
	C721.872437,247.693481 722.426880,247.782700 723.088867,248.115601 
	C723.467041,248.584915 723.737610,248.810532 723.811523,249.276215 
	C722.742188,250.010422 721.869629,250.504593 720.588196,251.001434 
	C719.047974,251.196182 717.919922,251.410767 716.784729,251.576828 
	C706.641724,253.060364 696.390686,254.034836 686.387512,256.171112 
	C678.377319,257.881775 669.335510,254.418808 662.242981,260.966644 
	C661.305664,261.832001 659.434021,261.685303 657.613525,261.836609 
	C648.812683,261.434265 640.393433,261.202667 631.917725,260.621399 
	C630.644958,259.852142 629.428467,259.432587 628.379578,258.800171 
	C629.041931,258.358582 629.536682,258.129791 630.398926,257.815491 
	C632.523743,257.416931 634.281128,257.103882 636.375122,256.864441 
	C637.159241,256.856354 637.606750,256.774750 637.951172,256.878967 
	C637.848145,257.064789 637.854492,257.489685 638.028931,257.821350 
	C642.899353,258.877319 647.673279,260.680450 652.428467,257.106415 
	C653.531189,257.042694 654.268066,257.025726 655.242310,257.280823 
	C657.649170,256.775024 659.818726,255.997177 662.358459,255.140503 
	C663.482666,255.013885 664.236572,254.966080 665.203003,255.184845 
	C666.615967,254.998291 667.816528,254.545181 669.017029,254.092072 
	C669.160278,253.991837 669.303467,253.891632 670.083862,253.830414 
	C673.455872,253.714722 676.190552,253.560089 679.247559,253.565399 
	C680.043518,253.797760 680.517273,253.870148 681.232361,254.222092 
	C684.980164,253.703476 688.486694,252.905319 692.445923,252.064087 
	C699.550171,251.292801 706.201599,250.564590 713.249451,249.899643 
	C715.162781,249.201065 716.679688,248.439224 718.196655,247.677383 
z"
          />
          <path
            fill="#38459A"
            opacity="1.000000"
            stroke="none"
            d="
M310.807129,697.354370 
	C312.087219,698.681396 313.367340,700.008362 314.918274,701.940063 
	C316.728821,704.006653 318.268585,705.468689 319.927765,707.044067 
	C318.372986,707.875671 317.163391,708.522644 315.615417,709.096375 
	C313.860565,708.310974 312.444092,707.598877 310.981567,706.566528 
	C309.898285,705.934021 308.861023,705.621765 307.481995,705.230286 
	C306.238098,704.942078 305.335999,704.733154 304.280701,704.217346 
	C299.703430,701.405457 295.206207,699.019104 290.881317,696.353271 
	C288.057983,694.613037 285.484650,692.467163 282.957886,690.181396 
	C284.704254,690.044495 286.292084,690.229187 288.453094,690.480591 
	C288.703003,688.212158 288.944366,686.021423 289.519287,683.843323 
	C290.293091,683.797607 290.733337,683.739197 291.339539,683.806396 
	C291.505554,683.931885 291.868805,684.135010 291.915771,684.474854 
	C297.049622,688.698547 302.136475,692.582275 307.547363,696.657837 
	C308.849945,697.017944 309.828552,697.186157 310.807129,697.354370 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M711.163147,368.566223 
	C711.889099,371.382233 712.916382,373.873871 712.951538,376.379456 
	C712.983337,378.640869 711.929810,380.917511 710.744629,383.582733 
	C708.485901,384.095795 706.837524,384.213623 704.797241,384.186951 
	C702.601929,384.222809 700.798523,384.403198 698.665161,384.599854 
	C697.676331,384.591949 697.017395,384.567841 696.283630,384.288452 
	C696.013489,383.540253 695.818237,383.047272 696.091309,382.412537 
	C697.716614,382.198914 698.872864,382.076874 700.030518,382.062897 
	C710.763855,381.933167 712.476013,379.360016 708.598816,369.093475 
	C708.309570,368.327606 707.870117,367.601593 707.406555,366.921051 
	C703.298035,360.889679 698.172485,356.700562 690.809631,354.520264 
	C678.246460,350.800171 667.270813,352.885834 657.349670,361.077484 
	C654.430176,363.488098 651.959961,366.442871 649.200928,368.799194 
	C650.418457,365.979919 651.718994,363.509705 653.328369,360.910950 
	C653.778259,360.609497 653.919250,360.436646 654.060181,360.263733 
	C654.606567,359.635345 655.152954,359.006927 656.140686,358.112610 
	C657.162964,357.042969 657.743896,356.239288 658.324890,355.435577 
	C658.324890,355.435577 658.254761,355.359619 658.588135,355.386658 
	C659.297424,355.329102 659.673340,355.244476 660.380859,355.135193 
	C661.592590,354.565674 662.472656,354.020813 663.352722,353.475922 
	C663.352722,353.475922 663.743774,353.366028 664.294434,353.288696 
	C666.557312,352.427856 668.269409,351.644348 669.981506,350.860809 
	C672.970093,350.820923 675.970764,350.939758 678.945129,350.713013 
	C690.193542,349.855347 699.868591,353.019104 707.663696,362.207520 
	C708.489563,363.614960 709.178650,364.375519 709.867676,365.136108 
	C710.255920,366.173401 710.644165,367.210663 711.163147,368.566223 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M512.877991,306.933319 
	C506.563385,306.947784 500.248749,306.962250 493.063904,306.978088 
	C491.121552,307.007385 490.049438,307.035309 488.977356,307.063263 
	C488.948608,306.711853 488.919830,306.360474 489.292206,305.531677 
	C493.952789,304.412628 498.191681,303.491669 502.475159,303.176788 
	C523.150696,301.657013 543.848816,300.433014 564.514221,298.792908 
	C569.694885,298.381744 574.784241,296.819824 580.276855,295.844055 
	C582.055054,295.711975 583.471924,295.522675 584.904541,295.554138 
	C585.053284,296.046295 585.106384,296.332489 585.079712,296.633453 
	C585.035950,297.143494 584.992188,297.653564 584.910339,298.544373 
	C575.596985,300.094604 566.333435,301.371124 557.042297,302.396698 
	C550.665649,303.100616 544.250427,303.455811 536.975647,303.979492 
	C529.345276,304.324188 522.586365,304.580994 515.844055,305.068512 
	C514.815796,305.142853 513.864746,306.285034 512.877991,306.933319 
z"
          />
          <path
            fill="#BCD0E6"
            opacity="1.000000"
            stroke="none"
            d="
M667.052612,198.929413 
	C662.469666,191.186356 657.832764,183.474380 653.344849,175.676666 
	C652.323486,173.902084 651.817810,171.830658 651.494324,169.482193 
	C653.017822,169.130615 654.121765,169.192703 655.161255,169.625702 
	C656.324158,172.394073 657.551575,174.791519 658.826782,177.394287 
	C658.874634,177.599625 659.001709,178.001678 659.005981,178.357712 
	C663.136292,186.072037 667.212585,193.459106 671.433167,200.762756 
	C672.214172,202.114136 673.577942,203.128708 674.736755,204.618439 
	C674.960632,205.570038 675.120422,206.201950 675.220825,207.163727 
	C675.743835,208.035919 676.326294,208.578262 676.993774,209.303711 
	C677.078735,209.486832 677.226929,209.862381 677.164856,210.235535 
	C678.361450,212.779160 679.620178,214.949615 680.935120,217.411194 
	C681.073853,218.088928 681.156250,218.475494 681.180054,219.221497 
	C682.289978,221.498245 683.458557,223.415573 684.684570,225.603638 
	C684.837830,226.230637 684.933716,226.586914 685.036499,227.318329 
	C686.814026,230.807999 688.922119,230.820648 691.702942,228.347992 
	C693.737732,228.276031 695.367493,228.230118 697.206116,228.389404 
	C698.274719,228.400101 699.134399,228.205612 700.439819,228.002380 
	C703.525574,227.838486 706.165588,227.683334 709.135071,227.726807 
	C709.679504,228.065414 709.894470,228.205429 710.109375,228.345428 
	C708.024170,228.905869 705.963440,229.593918 703.848450,229.999069 
	C699.959534,230.744034 696.057373,231.694733 692.127258,231.891357 
	C684.437317,232.276062 682.814331,230.890396 680.046875,223.693192 
	C678.815491,220.490585 677.191345,217.439011 675.456421,214.137177 
	C673.690552,211.058228 672.213745,208.160995 670.415405,204.709869 
	C669.080139,202.413773 668.066345,200.671600 667.052612,198.929413 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M467.003113,736.981995 
	C463.732971,740.020325 456.585266,739.552917 454.486542,736.163574 
	C452.966949,733.709473 455.151398,729.637817 458.928223,729.341064 
	C465.192596,728.848877 471.530640,728.993103 477.816345,729.302124 
	C479.248383,729.372498 480.593597,731.209839 481.737701,732.055664 
	C485.813965,730.972778 489.978424,729.866516 494.613831,728.883423 
	C495.084839,729.006714 495.159180,729.423340 494.824463,729.614258 
	C493.415253,730.474304 492.340790,731.143494 491.266327,731.812744 
	C491.399109,732.152771 491.531860,732.492798 491.664642,732.832886 
	C495.807068,733.050049 499.949493,733.267273 504.091919,733.484497 
	C503.849823,733.900574 503.667450,734.626831 503.356720,734.686707 
	C497.610992,735.793396 491.765076,737.962341 486.116119,737.542419 
	C480.500031,737.124817 475.036316,735.673645 469.315735,736.919739 
	C469.429047,736.187622 469.694458,736.007385 470.349762,735.878174 
	C472.363098,734.546631 474.021820,733.216003 476.641968,731.114075 
	C472.532288,731.114075 469.759338,731.114075 466.593292,731.094421 
	C465.152161,731.112793 464.104126,731.150818 462.659210,731.111023 
	C460.790314,731.907471 459.318298,732.781738 457.771545,734.073547 
	C459.068420,735.280945 460.356842,736.394287 461.833832,736.773926 
	C463.466888,737.193665 465.272583,736.941528 467.003113,736.981995 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M576.271606,671.345337 
	C583.575745,671.238586 590.880249,671.059326 598.183716,671.093323 
	C599.470581,671.099304 600.753296,671.997437 602.037964,672.483521 
	C601.463440,673.686401 601.179565,675.204529 600.269531,676.043518 
	C590.680359,684.883850 580.991211,693.615662 570.793701,702.675354 
	C569.740662,703.562683 569.223755,704.152222 568.334839,704.782349 
	C567.221741,704.801025 566.480591,704.779114 565.815796,704.428162 
	C572.657898,698.365723 579.531250,692.752991 586.151367,686.855957 
	C590.473816,683.005615 594.428711,678.742798 598.548279,674.664734 
	C598.155090,674.135925 597.761902,673.607178 597.368713,673.078369 
	C594.249634,673.734619 591.130554,674.390930 587.554688,674.997131 
	C583.742981,675.028564 580.387939,675.110107 576.594910,675.086365 
	C574.068359,674.870178 571.979736,674.759277 570.025208,674.320435 
	C572.196716,673.110168 574.234131,672.227783 576.271606,671.345337 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M197.412537,629.923767 
	C194.375824,625.869385 191.677124,621.884033 188.992462,617.449036 
	C189.006500,616.999329 189.226044,616.773682 189.528687,616.867249 
	C190.785324,617.407288 191.739319,617.853760 192.752594,618.481445 
	C192.811859,618.662720 192.987946,619.000977 193.006195,619.348877 
	C196.282974,623.567322 199.137589,627.904907 202.916824,631.173523 
	C206.496841,634.269897 211.046387,636.245300 215.468719,638.788086 
	C216.174164,638.924683 216.580429,638.986877 217.197327,639.321533 
	C219.633545,640.332886 221.859131,641.071716 224.445374,641.854614 
	C225.531815,641.930481 226.257629,641.962341 227.236786,642.261841 
	C233.394943,643.551758 239.299744,644.573975 245.609314,645.687134 
	C247.676117,645.874084 249.338165,645.970215 251.196625,646.339844 
	C252.270691,646.785461 253.148331,646.957458 254.257339,647.368896 
	C255.219025,646.689941 255.949341,645.771484 256.685730,644.836792 
	C256.691803,644.820557 256.707428,644.831421 256.707428,644.831421 
	C257.401398,645.752930 258.095367,646.674377 258.789368,647.595947 
	C257.771271,648.343628 256.720917,649.767212 255.740707,649.720459 
	C251.801285,649.532593 247.842743,649.104736 243.966766,648.368286 
	C236.861008,647.018127 229.767197,645.555420 222.745148,643.827637 
	C214.898148,641.896851 207.296036,639.341003 201.584137,633.078186 
	C200.491806,631.880493 199.037735,631.012817 197.412537,629.923767 
z"
          />
          <path
            fill="#AFB7D5"
            opacity="1.000000"
            stroke="none"
            d="
M388.175964,716.784668 
	C389.197968,717.512085 390.078308,718.395569 390.645203,719.783936 
	C388.539185,721.128235 386.746582,721.967590 384.954010,722.807007 
	C381.676727,722.812134 378.399475,722.817322 374.565308,722.494385 
	C372.993103,721.819092 371.977814,721.471863 370.962494,721.124634 
	C369.584778,721.082458 368.207092,721.040344 365.991364,720.970398 
	C363.458435,720.924133 361.763550,720.905640 360.068634,720.887146 
	C358.074890,720.863281 356.081116,720.839417 353.559998,720.518005 
	C352.372864,719.783203 351.713135,719.345947 351.053406,718.908691 
	C348.324738,717.598267 345.596069,716.287903 342.434875,714.569519 
	C339.918274,713.097168 337.834259,712.032776 335.557037,710.857666 
	C334.991394,710.504211 334.618988,710.261536 334.151611,709.681396 
	C332.991180,707.945312 331.925751,706.546631 330.860291,705.147949 
	C330.860291,705.147949 331.109070,704.883972 331.567688,704.835083 
	C337.892578,708.333496 343.676178,712.030701 349.674835,715.337891 
	C351.506897,716.347900 353.930389,716.285217 356.486267,716.801636 
	C358.264771,716.896118 359.640045,716.902832 361.265076,717.212585 
	C367.716187,718.212036 373.917542,718.908508 380.456085,719.695007 
	C381.485229,719.756226 382.177185,719.727295 383.235840,719.831055 
	C384.981537,719.056519 386.360504,718.149475 387.739471,717.242371 
	C387.739471,717.242371 388.034271,716.940796 388.175964,716.784668 
z"
          />
          <path
            fill="#4377BC"
            opacity="1.000000"
            stroke="none"
            d="
M646.146118,415.086853 
	C646.084961,414.598480 645.835083,414.036469 645.992371,413.633301 
	C647.363647,410.117767 651.964844,408.812134 659.247742,410.076721 
	C662.370483,410.618958 664.931641,412.096985 664.953064,415.843109 
	C664.981750,420.867859 667.826965,423.615997 672.669434,425.026001 
	C673.471924,424.997375 673.903259,425.034882 674.973450,425.328064 
	C679.541870,425.466614 683.516174,425.680511 687.390686,425.156372 
	C693.539185,424.324615 696.777832,415.917847 691.670715,411.985901 
	C690.837463,411.344421 690.490540,410.071320 690.279297,409.065643 
	C695.554382,411.310059 696.956604,415.677429 695.606079,420.198242 
	C694.831055,422.792847 692.052185,425.750214 689.502197,426.662537 
	C683.281738,428.888153 676.452454,429.515747 670.297791,426.714050 
	C667.399902,425.394897 663.704102,421.991516 663.521301,419.317108 
	C663.097412,413.116608 659.628784,412.651703 655.259216,413.038940 
	C652.185120,413.311371 649.181335,414.375946 646.146118,415.086853 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M515.068115,722.138184 
	C514.597412,722.142395 514.126648,722.146606 513.097168,721.828125 
	C511.031525,721.337097 509.524689,721.168640 508.017883,721.000183 
	C507.911530,720.677612 507.730255,720.404907 507.271423,719.743591 
	C507.026520,718.873840 506.984222,718.442444 506.941895,718.011047 
	C511.040039,713.753906 515.594055,709.842102 519.137512,705.164734 
	C525.669861,696.541870 531.514038,687.400513 537.879639,678.645996 
	C539.067566,677.012207 541.355042,676.177979 543.688416,675.102661 
	C544.241699,675.230652 544.600586,675.577820 544.380615,675.827759 
	C543.009155,677.002991 541.555237,677.720398 540.753784,678.886353 
	C533.965637,688.761475 527.296387,698.718384 520.275574,708.758667 
	C516.875061,711.929749 513.790527,714.992859 510.086945,718.670654 
	C512.456055,719.534424 513.778259,720.016479 515.134155,720.771118 
	C515.134583,721.408569 515.101318,721.773438 515.068115,722.138184 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M432.556824,599.070740 
	C433.646057,605.471191 435.262878,611.835327 435.707214,618.280273 
	C436.470978,629.358826 436.569336,640.486755 436.763062,651.597168 
	C436.788330,653.045166 435.725159,654.512207 434.683136,655.674683 
	C434.396698,654.072998 434.824493,652.754211 434.753265,651.463013 
	C434.177887,641.032959 433.532227,630.606506 432.832367,620.184021 
	C432.760742,619.117493 432.192566,618.084290 431.862427,616.633057 
	C431.608551,613.814026 431.347107,611.397400 431.167419,608.597717 
	C431.268127,607.171814 431.287079,606.128967 431.503296,604.789551 
	C431.985992,602.685547 432.271423,600.878174 432.556824,599.070740 
z"
          />
          <path
            fill="#AFB7D5"
            opacity="1.000000"
            stroke="none"
            d="
M648.098511,664.234375 
	C641.012512,665.541260 633.887512,666.673401 626.859985,668.242126 
	C624.098816,668.858459 621.121216,669.929504 618.995361,671.698669 
	C612.770813,676.879028 606.925171,682.514648 600.448486,688.358948 
	C597.705933,689.892761 595.445984,691.039490 593.185974,692.186279 
	C595.995667,689.409363 598.749329,686.572998 601.627808,683.869385 
	C606.199341,679.575623 610.691284,675.170898 615.542664,671.212341 
	C617.963745,669.236816 620.954163,667.446533 623.953064,666.792969 
	C631.322327,665.187012 638.845825,664.288513 646.957031,663.242310 
	C647.843323,663.622375 648.007202,663.907471 648.098511,664.234375 
z"
          />
          <path
            fill="#5267AC"
            opacity="1.000000"
            stroke="none"
            d="
M431.854797,617.035583 
	C432.192566,618.084290 432.760742,619.117493 432.832367,620.184021 
	C433.532227,630.606506 434.177887,641.032959 434.753265,651.463013 
	C434.824493,652.754211 434.396698,654.072998 434.332031,655.756348 
	C434.390747,656.283508 434.317596,656.433289 434.120178,656.800293 
	C424.601227,656.977173 415.206116,656.973694 405.812378,656.860657 
	C404.237762,656.841736 402.668915,656.347717 401.399475,655.567993 
	C411.686646,654.911194 421.671722,654.759705 431.017822,654.617859 
	C431.017822,643.687134 430.959320,634.232849 431.056183,624.780090 
	C431.082672,622.196167 431.575775,619.616943 431.854797,617.035583 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M648.497437,664.177124 
	C648.007202,663.907471 647.843323,663.622375 647.401917,663.246582 
	C647.253418,662.791992 647.236450,662.225342 647.376709,662.183411 
	C652.821838,660.550903 658.281372,658.966370 663.738953,657.375549 
	C664.204346,657.854614 664.669739,658.333679 665.135132,658.812744 
	C664.441101,660.730286 664.322205,663.411987 662.957764,664.438782 
	C655.259094,670.232788 647.261292,675.629333 639.358276,680.657776 
	C639.867798,679.367798 640.384399,678.581421 640.901062,677.795044 
	C644.378296,675.057312 647.896362,672.369507 651.316956,669.562866 
	C653.294067,667.940491 655.113953,666.126526 657.004578,664.398865 
	C656.844421,663.926758 656.684265,663.454590 656.524170,662.982483 
	C653.981567,663.361633 651.438965,663.740784 648.497437,664.177124 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M709.234497,432.782654 
	C706.512878,434.615448 704.058350,436.289032 701.286743,437.580444 
	C703.995972,432.597900 708.922363,429.642853 710.515869,423.618835 
	C712.130493,417.514984 711.318604,407.081482 706.386108,402.981201 
	C702.420532,399.684723 697.629517,397.398529 693.343994,394.460938 
	C691.669556,393.313171 690.375610,391.610504 689.237915,390.009399 
	C690.049011,389.868958 690.530518,389.879639 691.133545,390.213379 
	C692.127258,390.741821 692.999329,390.947235 694.115601,391.316345 
	C694.552917,391.512268 694.746155,391.544556 694.939453,391.576843 
	C698.428040,394.063477 702.183228,396.252655 705.353760,399.094147 
	C713.885620,406.740631 715.754395,416.244537 711.270569,426.773743 
	C710.957764,427.508148 711.007141,428.396729 710.953247,429.615906 
	C710.512817,430.886566 710.007263,431.754974 709.234497,432.782654 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M689.918823,409.090149 
	C690.490540,410.071320 690.837463,411.344421 691.670715,411.985901 
	C696.777832,415.917847 693.539185,424.324615 687.390686,425.156372 
	C683.516174,425.680511 679.541870,425.466614 675.298706,425.322449 
	C677.537903,424.743744 680.082764,424.317963 682.645081,424.133270 
	C686.876038,423.828339 691.170959,422.806946 691.673157,418.083984 
	C692.145874,413.638031 688.757935,411.404388 684.491516,410.126587 
	C677.574036,408.054718 670.802979,405.481873 664.020508,402.983673 
	C662.906128,402.573242 662.049683,401.462585 661.434326,400.270447 
	C664.911804,400.821808 668.028931,401.778229 671.450867,402.809082 
	C672.172241,402.921906 672.588806,402.960327 673.146118,403.329285 
	C675.583496,404.328033 677.880310,404.996246 680.526550,405.732269 
	C681.584839,405.861816 682.293640,405.923584 683.182251,406.276367 
	C685.238525,407.405212 687.114990,408.243073 689.227112,409.122437 
	C689.462769,409.163940 689.935913,409.092163 689.946533,409.083527 
	C689.957214,409.074890 689.918762,409.090149 689.918823,409.090149 
z"
          />
          <path
            fill="#9CA5CB"
            opacity="1.000000"
            stroke="none"
            d="
M604.266968,697.200928 
	C609.431580,694.774109 614.632446,692.420898 619.748901,689.896362 
	C623.605286,687.993591 627.353394,685.871338 631.518738,683.863831 
	C626.594604,687.639587 621.588928,691.917480 615.945129,695.043030 
	C605.944946,700.581238 595.562744,705.430786 585.314392,710.516113 
	C584.811218,710.765869 584.060303,710.516479 583.288574,709.950806 
	C583.789185,708.646484 584.425964,707.890808 585.354980,707.072021 
	C586.034302,706.910950 586.421448,706.812927 587.174194,706.772339 
	C590.217163,705.610779 593.009827,704.590698 595.541809,703.121338 
	C598.545410,701.378174 601.324463,699.248108 604.223755,697.269531 
	C604.246582,697.253235 604.266968,697.200928 604.266968,697.200928 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M282.799347,690.502991 
	C285.484650,692.467163 288.057983,694.613037 290.881317,696.353271 
	C295.206207,699.019104 299.703430,701.405457 303.892700,704.347046 
	C303.122650,704.844971 302.587463,704.906372 302.052246,704.967773 
	C296.539215,702.240662 290.966858,699.625793 285.531250,696.752197 
	C280.896179,694.301758 276.418365,691.553955 271.421692,688.563721 
	C268.983582,686.457520 266.994720,684.725403 265.374695,682.978760 
	C268.155823,684.008240 270.568115,685.052368 273.218323,686.349243 
	C274.581085,686.885864 275.705872,687.169617 276.902954,687.761230 
	C277.894257,688.451904 278.813293,688.834839 279.772827,689.234985 
	C279.813324,689.252197 279.897247,689.225586 280.043030,689.506226 
	C280.787781,689.937683 281.386749,690.088562 282.184570,690.319458 
	C282.383392,690.399414 282.799347,690.502991 282.799347,690.502991 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M252.007904,675.960999 
	C252.007904,675.960999 251.636337,675.841858 251.331177,675.487671 
	C249.741364,674.356201 248.456696,673.578796 247.172058,672.801392 
	C247.172058,672.801392 247.022797,672.445679 246.851471,671.951904 
	C245.440750,670.652283 244.201324,669.846497 242.961914,669.040649 
	C242.474182,668.768860 241.986450,668.497070 241.202621,667.769043 
	C239.682358,665.828308 238.458191,664.343811 237.234024,662.859375 
	C237.403961,662.487000 237.573898,662.114624 237.743835,661.742249 
	C241.181946,662.121643 244.620071,662.501099 248.058197,662.880493 
	C248.828217,663.215332 249.598236,663.550110 250.901367,664.170044 
	C252.340973,664.510986 253.247467,664.566833 254.153946,664.622681 
	C254.870560,664.690857 255.587189,664.759033 256.885895,665.130127 
	C260.333405,666.449463 263.198792,667.466003 265.655762,668.595276 
	C258.322083,667.534485 251.396851,666.360840 244.471619,665.187256 
	C244.298874,665.633240 244.126144,666.079163 243.953415,666.525146 
	C245.990417,668.072144 248.066711,669.570496 250.056061,671.176575 
	C254.069870,674.416809 258.036407,677.715576 261.664795,680.992615 
	C258.207397,679.317017 255.107651,677.639038 252.007904,675.960999 
z"
          />
          <path
            fill="#244DAE"
            opacity="1.000000"
            stroke="none"
            d="
M672.003418,279.063416 
	C669.933228,279.350342 667.863037,279.637299 665.172852,279.679260 
	C662.014160,280.557159 659.475586,281.680054 656.936951,282.802917 
	C656.522217,282.940887 656.107422,283.078888 655.089844,283.183655 
	C654.017517,283.213593 653.547913,283.276764 652.750122,283.203491 
	C651.949524,283.029419 651.477234,282.991821 650.758850,282.692993 
	C646.004211,283.608734 641.495667,284.785706 636.643555,285.997284 
	C635.842285,286.002563 635.384583,285.973236 634.740234,285.676331 
	C631.835938,282.996582 634.020203,282.473724 635.928894,282.072174 
	C637.690979,281.701447 639.530029,281.706451 641.301575,281.369324 
	C650.541077,279.610962 659.769897,277.796295 669.373657,276.002808 
	C671.498352,275.997345 673.251709,275.990265 674.836548,276.247742 
	C673.779846,277.362671 672.891602,278.213043 672.003418,279.063416 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M477.952698,515.430908 
	C468.959076,515.914673 459.844543,516.072266 450.729370,516.124268 
	C440.766388,516.181091 430.802826,516.140198 420.386963,515.984009 
	C421.129364,515.245300 422.321198,514.166199 423.519867,514.158569 
	C440.570679,514.049683 457.622467,514.068665 474.673767,514.128601 
	C475.727417,514.132324 476.778931,514.734558 477.952698,515.430908 
z"
          />
          <path
            fill="#9CA5CB"
            opacity="1.000000"
            stroke="none"
            d="
M579.164246,711.298584 
	C576.444580,712.494080 573.777710,713.838440 570.985291,714.828918 
	C569.334106,715.414612 567.484436,715.440796 565.070374,715.880005 
	C563.952026,716.332153 563.487366,716.622925 563.022644,716.913635 
	C562.576660,716.914185 562.130737,716.914795 561.354980,716.528564 
	C562.347961,714.278381 563.497437,712.253479 565.042786,710.597961 
	C566.538513,708.995483 568.441528,707.773193 570.391235,706.207153 
	C570.761597,705.698669 570.906372,705.366882 571.039490,705.055298 
	C571.027771,705.075500 571.063843,705.107788 571.063843,705.107788 
	C574.494995,703.418152 577.948364,701.771606 581.347961,700.020691 
	C583.065674,699.136047 584.686218,698.062683 586.696228,697.415161 
	C585.625977,699.376404 584.210144,700.997192 582.752869,702.641968 
	C582.711426,702.665955 582.662109,702.748108 582.347534,702.761108 
	C581.247864,703.377258 580.462769,703.980408 579.477661,704.652466 
	C579.277588,704.721375 578.884888,704.878967 578.519409,704.890259 
	C574.366821,707.313354 570.579651,709.725281 565.801086,712.768494 
	C568.755737,712.768494 570.500610,712.950867 572.190552,712.724365 
	C574.536682,712.409912 576.841675,711.788513 579.164246,711.298584 
z"
          />
          <path
            fill="#B9CFEA"
            opacity="1.000000"
            stroke="none"
            d="
M653.019470,361.039490 
	C651.718994,363.509705 650.418457,365.979919 649.134583,369.153809 
	C649.154785,370.579437 649.158386,371.301422 648.856079,372.287292 
	C648.734863,375.695862 648.919617,378.840546 649.086304,382.348145 
	C649.123718,383.433716 649.179260,384.156342 649.134888,385.247192 
	C651.786926,389.662323 654.538818,393.709229 657.290771,397.756104 
	C655.466553,396.907867 653.159302,396.505951 651.896240,395.139130 
	C643.516174,386.070374 641.792908,369.421051 652.651489,360.292084 
	C653.008057,360.679260 653.013794,360.859375 653.019470,361.039490 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M234.873444,611.043335 
	C235.099106,611.580627 235.324783,612.117981 235.790634,612.824707 
	C240.664398,619.881226 245.349609,626.734619 249.889404,633.682983 
	C250.833786,635.128357 251.239685,636.925598 251.556854,638.882935 
	C250.580505,639.057251 249.941025,638.909363 249.242218,638.419983 
	C246.725388,634.603760 244.078064,631.245911 241.852798,627.628357 
	C238.085434,621.503906 234.584778,615.215454 231.270172,609.121643 
	C232.670044,609.846008 233.771744,610.444702 234.873444,611.043335 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M848.917847,438.469727 
	C850.937927,436.142426 853.272217,434.017639 854.924866,431.453491 
	C863.030334,418.877777 862.614990,409.874237 853.589844,397.939514 
	C853.035095,397.205872 852.760986,396.260040 852.909424,395.312439 
	C856.641541,400.809784 859.820496,406.407623 862.999390,412.005493 
	C862.989136,414.373718 862.978943,416.741913 862.688599,419.765259 
	C860.594299,424.602905 858.780090,428.785400 856.965942,432.967896 
	C856.965942,432.967926 857.000000,432.997314 856.687927,433.132202 
	C854.801758,435.077301 853.227661,436.887512 851.653564,438.697723 
	C851.299683,438.795044 850.945862,438.892365 850.035156,438.923218 
	C849.291443,438.727722 849.104675,438.598755 848.917847,438.469727 
z"
          />
          <path
            fill="#BED4EB"
            opacity="1.000000"
            stroke="none"
            d="
M851.857849,361.159973 
	C851.857849,361.159973 851.538391,360.951935 851.312439,360.557007 
	C850.433838,359.738525 849.781128,359.314911 849.128479,358.891296 
	C849.128479,358.891296 849.010498,358.968536 849.010620,358.591370 
	C847.340149,356.804047 845.669495,355.393921 843.998901,353.983826 
	C848.624756,353.415741 851.787720,356.176422 854.463623,359.185822 
	C860.985413,366.520569 862.095764,375.369171 860.456177,384.666809 
	C860.187195,386.192413 858.624207,387.489807 857.259216,388.514771 
	C857.259766,386.901428 857.551514,385.617340 858.078857,384.438629 
	C861.085754,377.718079 857.794434,365.307526 851.857849,361.159973 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M554.777039,714.741028 
	C552.388550,715.711975 550.000000,716.682922 547.000488,717.810913 
	C545.923157,718.273621 545.456909,718.579224 544.990662,718.884888 
	C544.552734,718.919861 544.114746,718.954834 542.942505,718.986694 
	C540.365112,719.583008 538.522095,720.182434 536.679077,720.781799 
	C536.679077,720.781799 536.291199,720.728333 535.672119,720.744629 
	C533.033997,720.850281 531.014954,720.939636 528.995850,721.028931 
	C526.635254,721.076965 524.274719,721.125061 521.282898,721.122620 
	C520.342651,720.961487 520.113770,720.759888 520.198853,720.120361 
	C532.350403,717.086975 545.123840,717.868713 555.995850,711.328735 
	C555.581970,712.690063 555.179504,713.715515 554.777039,714.741028 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M334.246582,710.018799 
	C334.618988,710.261536 334.991394,710.504211 335.673340,711.228760 
	C336.287811,713.440002 336.592743,715.169495 336.897705,716.898926 
	C336.476837,716.901306 336.055969,716.903687 335.138641,716.677490 
	C334.080933,716.349487 333.519684,716.250061 332.958466,716.150635 
	C328.475800,714.296753 322.678467,716.753967 319.381439,711.508301 
	C323.089813,711.847229 326.519653,712.603271 329.949493,713.359314 
	C330.024445,712.963867 330.099396,712.568420 330.174377,712.172974 
	C326.384674,709.204285 322.594971,706.235596 318.805267,703.266907 
	C320.963837,704.100159 323.122437,704.933350 325.817291,706.179626 
	C328.984589,707.734680 331.615601,708.876709 334.246582,710.018799 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M419.991486,728.989563 
	C425.742493,729.007812 431.493652,729.004089 437.244415,729.050659 
	C442.811707,729.095764 444.103851,731.331726 441.240234,736.757996 
	C440.521881,736.491760 440.378448,736.339600 440.387604,735.927551 
	C440.349213,734.735107 440.158234,733.802429 439.889343,732.477661 
	C434.083069,731.578430 428.354736,731.071472 422.626404,730.564514 
	C422.443146,731.173645 422.259888,731.782837 422.076630,732.391968 
	C424.054962,733.912537 426.033325,735.433044 428.011658,736.953552 
	C426.314453,736.930237 424.617249,736.906982 422.459625,736.538818 
	C421.068359,733.865356 414.927734,732.988708 419.991486,728.989563 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M851.864929,361.529541 
	C857.794434,365.307526 861.085754,377.718079 858.078857,384.438629 
	C857.551514,385.617340 857.259766,386.901428 856.882202,388.539978 
	C856.905273,388.943085 856.792542,389.314575 856.455933,389.612549 
	C854.999512,390.947083 853.879700,391.983673 852.759949,393.020264 
	C853.667664,389.763489 854.270630,386.381775 855.544312,383.275024 
	C858.497009,376.072937 855.697327,369.921112 852.554138,363.739960 
	C852.259521,363.160553 852.096252,362.514343 851.864929,361.529541 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M680.991028,253.942535 
	C680.517273,253.870148 680.043518,253.797760 679.328613,253.313873 
	C679.725281,252.262177 680.363220,251.621964 681.001160,250.981750 
	C687.052917,250.341171 693.104675,249.700592 699.968018,249.046616 
	C702.852966,248.375534 704.926331,247.717834 706.999695,247.060150 
	C708.763245,247.049591 710.526855,247.039017 712.674316,247.458557 
	C712.989807,248.537918 712.921448,249.187164 712.853027,249.836395 
	C706.201599,250.564590 699.550171,251.292801 692.017029,252.007690 
	C687.753906,252.643768 684.372437,253.293152 680.991028,253.942535 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M518.001831,518.861816 
	C516.685303,518.808411 515.368713,518.755066 513.520142,518.335083 
	C511.319977,517.287842 509.651947,516.607239 507.983917,515.926636 
	C512.653259,515.960632 517.342651,515.735901 521.986572,516.096802 
	C527.292053,516.509094 532.557068,517.442505 537.931946,518.534302 
	C532.659485,518.981750 527.294739,519.044312 521.298218,518.836914 
	C519.778259,518.665161 518.890076,518.763489 518.001831,518.861816 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M510.959229,726.916138 
	C513.971497,726.647827 516.983826,726.379517 520.443054,726.471191 
	C520.923767,727.548157 520.957520,728.265198 520.991272,728.982239 
	C520.991272,728.982239 520.981506,728.993591 520.604431,728.989197 
	C519.152283,729.649658 518.077271,730.314575 517.002197,730.979431 
	C517.002197,730.979431 516.504089,731.015747 515.865601,731.013611 
	C513.495789,731.709045 511.764496,732.406677 510.033173,733.104248 
	C508.994141,733.128662 507.955109,733.153015 506.494843,732.736572 
	C504.374084,731.212585 502.674561,730.129395 501.255249,728.751831 
	C504.676727,727.943665 507.817963,727.429871 510.959229,726.916138 
z"
          />
          <path
            fill="#5267AC"
            opacity="1.000000"
            stroke="none"
            d="
M432.581207,598.723938 
	C432.271423,600.878174 431.985992,602.685547 431.162659,604.528076 
	C429.748566,596.716858 428.872437,588.870667 427.972260,580.696594 
	C426.976227,579.618958 426.004272,578.869080 425.032318,578.119263 
	C429.174103,576.285339 430.744537,578.236389 430.987091,582.157837 
	C431.269775,586.728149 431.560394,591.298096 432.154999,596.088745 
	C432.510132,596.998657 432.557861,597.687866 432.581207,598.723938 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M657.584595,397.857056 
	C654.538818,393.709229 651.786926,389.662323 649.463257,385.317657 
	C650.722778,385.998627 651.867615,386.848724 652.330811,387.978363 
	C654.369507,392.949921 658.683716,395.253540 663.012024,397.661804 
	C665.800842,399.213501 668.439941,401.034424 671.146118,402.734650 
	C668.028931,401.778229 664.911804,400.821808 661.406128,399.912659 
	C659.971191,399.292603 658.924805,398.625275 657.584595,397.857056 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M404.008789,728.891418 
	C404.441040,728.965515 404.873322,729.039673 405.868652,729.366882 
	C408.566254,730.741211 410.885803,731.626343 412.752533,733.089294 
	C413.610291,733.761597 413.579865,735.567017 413.952179,736.858643 
	C411.048187,736.825867 408.144165,736.793091 404.683777,736.466370 
	C403.016113,735.258789 401.904816,734.345154 401.143738,733.290039 
	C402.522003,732.923218 403.550049,732.697937 404.954254,732.390259 
	C404.069061,731.228516 403.509735,730.494507 403.079102,729.566040 
	C403.465942,729.196777 403.732941,729.036682 404.008789,728.891418 
z"
          />
          <path
            fill="#5F629C"
            opacity="1.000000"
            stroke="none"
            d="
M318.537659,703.053711 
	C322.594971,706.235596 326.384674,709.204285 330.174377,712.172974 
	C330.099396,712.568420 330.024445,712.963867 329.949493,713.359314 
	C326.519653,712.603271 323.089813,711.847229 319.270233,711.176270 
	C318.178558,711.120789 317.476654,710.980225 316.431274,710.518188 
	C316.043121,709.854370 315.998444,709.512024 315.953796,709.169617 
	C317.163391,708.522644 318.372986,707.875671 319.927765,707.044067 
	C318.268585,705.468689 316.728821,704.006653 315.111023,702.189758 
	C316.112000,702.170044 317.191010,702.505249 318.537659,703.053711 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M555.129883,714.845703 
	C555.179504,713.715515 555.581970,712.690063 556.149048,711.228210 
	C556.313599,710.791870 556.659973,710.671875 557.038940,710.773804 
	C559.966248,708.945129 562.514465,707.014587 565.225098,704.988159 
	C565.387512,704.892395 565.739502,704.757202 565.739502,704.757202 
	C566.480591,704.779114 567.221741,704.801025 568.411377,704.847656 
	C568.859924,704.872437 569.009705,705.006897 569.149475,705.274414 
	C569.581360,705.822632 569.873535,706.103271 570.165649,706.383911 
	C568.441528,707.773193 566.538513,708.995483 565.042786,710.597961 
	C563.497437,712.253479 562.347961,714.278381 561.020874,716.514282 
	C559.171936,716.241333 557.327332,715.595886 555.129883,714.845703 
z"
          />
          <path
            fill="#535B9F"
            opacity="1.000000"
            stroke="none"
            d="
M579.501221,711.237915 
	C576.841675,711.788513 574.536682,712.409912 572.190552,712.724365 
	C570.500610,712.950867 568.755737,712.768494 565.801086,712.768494 
	C570.579651,709.725281 574.366821,707.313354 578.551392,705.258057 
	C579.856506,706.162109 580.705566,706.955017 581.688110,707.190308 
	C582.747864,707.443970 583.932556,707.175720 585.062744,707.135132 
	C584.425964,707.890808 583.789185,708.646484 583.077942,709.690796 
	C581.948364,710.378723 580.893250,710.778015 579.501221,711.237915 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M529.055603,721.371582 
	C531.014954,720.939636 533.033997,720.850281 535.480042,720.782227 
	C537.503723,722.154114 539.100464,723.504578 542.170837,726.101440 
	C536.093872,727.502686 531.229980,728.624268 526.366028,729.745789 
	C526.278076,729.427856 526.190125,729.109924 526.102112,728.791931 
	C528.079407,727.603699 530.056702,726.415466 533.410583,724.399902 
	C531.473206,723.188477 530.294312,722.451355 529.055603,721.371582 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M414.180237,737.157349 
	C413.579865,735.567017 413.610291,733.761597 412.752533,733.089294 
	C410.885803,731.626343 408.566254,730.741211 406.197754,729.376831 
	C407.391357,729.132080 409.274994,728.545166 410.162476,729.236694 
	C413.014801,731.459229 415.019073,731.232544 417.051819,728.267334 
	C417.346069,727.838013 418.908783,728.278381 419.937988,728.652954 
	C414.927734,732.988708 421.068359,733.865356 422.102692,736.515381 
	C419.606873,737.043274 417.007568,737.249634 414.180237,737.157349 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M544.810242,520.296631 
	C543.988464,520.116394 543.166748,519.936218 542.151917,519.506836 
	C542.634521,518.861328 543.326111,518.097412 543.983337,518.125854 
	C550.248657,518.397034 556.519714,518.681519 562.759155,519.276306 
	C563.939209,519.388855 564.987061,520.887695 565.725586,522.238770 
	C564.888184,522.868958 564.421082,523.007263 563.539551,523.045166 
	C561.156250,522.801208 559.187378,522.657593 557.044189,522.188354 
	C552.849976,521.340637 548.830139,520.818665 544.810242,520.296631 
z"
          />
          <path
            fill="#535B9F"
            opacity="1.000000"
            stroke="none"
            d="
M582.794312,702.617920 
	C584.210144,700.997192 585.625977,699.376404 587.057739,697.426025 
	C588.966675,695.631165 590.859680,694.165955 592.969299,692.443481 
	C595.445984,691.039490 597.705933,689.892761 600.299438,688.652832 
	C600.633057,688.559692 600.876221,688.848389 600.897278,689.305603 
	C598.331360,692.062866 595.925720,694.621704 593.109802,696.594971 
	C589.860229,698.872253 586.248657,700.632935 582.794312,702.617920 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M377.200195,726.145142 
	C377.593719,726.146423 377.987213,726.147705 378.936340,726.381592 
	C380.676239,726.676208 381.860504,726.738281 383.044800,726.800293 
	C387.055817,726.871216 391.066864,726.942078 395.538452,727.344116 
	C395.994904,728.127930 395.990784,728.580566 395.630219,729.127869 
	C394.307281,729.306274 393.340820,729.390076 392.007080,729.364258 
	C390.435608,730.160767 389.231384,731.066956 387.626221,731.980835 
	C385.346985,731.628174 383.193665,731.720093 381.662506,730.788879 
	C379.889465,729.710571 378.663605,727.732544 377.200195,726.145142 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M247.961151,662.530273 
	C244.620071,662.501099 241.181946,662.121643 237.743835,661.742249 
	C237.573898,662.114624 237.403961,662.487000 237.234024,662.859375 
	C238.458191,664.343811 239.682358,665.828308 240.944946,667.661743 
	C239.413635,667.252136 237.328369,666.882874 236.393784,665.644958 
	C234.953201,663.736877 234.301117,661.233521 233.314545,658.982666 
	C235.390442,659.207275 237.511826,659.235657 239.531281,659.703796 
	C242.348801,660.356995 245.090149,661.338867 247.961151,662.530273 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M376.783264,726.096802 
	C378.663605,727.732544 379.889465,729.710571 381.662506,730.788879 
	C383.193665,731.720093 385.346985,731.628174 387.846130,732.140015 
	C388.637482,732.606628 388.807983,732.921814 388.978455,733.236938 
	C388.876984,733.548645 388.829376,734.099915 388.666016,734.136719 
	C385.382050,734.875549 373.172943,730.144653 371.076019,726.873169 
	C373.044403,726.566345 374.705353,726.307373 376.783264,726.096802 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M428.379517,736.987061 
	C426.033325,735.433044 424.054962,733.912537 422.076630,732.391968 
	C422.259888,731.782837 422.443146,731.173645 422.626404,730.564514 
	C428.354736,731.071472 434.083069,731.578430 439.438324,732.542603 
	C435.527496,732.999756 431.989746,732.999756 428.452026,732.999756 
	C428.457611,733.419312 428.463196,733.838867 428.468811,734.258423 
	C429.353424,734.897095 430.238037,735.535706 431.084747,736.418701 
	C431.046844,736.663086 430.961304,737.150208 430.961304,737.150208 
	C430.223328,737.106995 429.485382,737.063721 428.379517,736.987061 
z"
          />
          <path
            fill="#BED4EB"
            opacity="1.000000"
            stroke="none"
            d="
M852.767578,393.347412 
	C853.879700,391.983673 854.999512,390.947083 856.430542,389.804962 
	C855.641174,393.311584 857.130859,395.934479 859.084106,399.049194 
	C861.376282,402.704407 862.133911,407.321808 863.278259,411.763672 
	C859.820496,406.407623 856.641541,400.809784 853.126709,395.097717 
	C852.785645,394.547180 852.780457,394.110870 852.767578,393.347412 
z"
          />
          <path
            fill="#5F629C"
            opacity="1.000000"
            stroke="none"
            d="
M467.334808,736.951416 
	C465.272583,736.941528 463.466888,737.193665 461.833832,736.773926 
	C460.356842,736.394287 459.068420,735.280945 458.125000,734.025024 
	C460.054108,732.768860 461.555115,731.978821 463.056091,731.188843 
	C464.104126,731.150818 465.152161,731.112793 466.671478,731.422913 
	C468.093536,733.140381 469.044342,734.509766 469.995117,735.879089 
	C469.694458,736.007385 469.429047,736.187622 469.098145,736.638916 
	C468.553772,736.879089 468.110138,736.899963 467.334808,736.951416 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M789.541870,583.745483 
	C793.668030,584.791321 797.355591,585.884521 801.064575,586.899109 
	C802.915344,587.405457 804.805542,587.767883 806.844971,588.541565 
	C799.328979,589.668884 792.017456,588.358887 784.980103,584.651123 
	C786.296387,584.128174 787.704224,583.944336 789.541870,583.745483 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M252.039337,676.317017 
	C255.107651,677.639038 258.207397,679.317017 261.867004,681.066650 
	C262.426880,681.138245 262.812927,681.329773 262.890198,681.627991 
	C263.647186,682.282227 264.326874,682.638245 265.003418,682.997131 
	C265.000275,683.000000 265.005859,682.993347 265.005859,682.993347 
	C266.994720,684.725403 268.983582,686.457520 271.075378,688.502441 
	C266.614594,686.379211 261.961487,684.092529 257.526978,681.440369 
	C255.487396,680.220520 253.876190,678.284424 252.039337,676.317017 
z"
          />
          <path
            fill="#5F629C"
            opacity="1.000000"
            stroke="none"
            d="
M500.975037,729.046143 
	C502.674561,730.129395 504.374084,731.212585 506.107422,732.688416 
	C505.684265,733.159973 505.227325,733.238953 504.431152,733.401245 
	C499.949493,733.267273 495.807068,733.050049 491.664642,732.832886 
	C491.531860,732.492798 491.399109,732.152771 491.266327,731.812744 
	C492.340790,731.143494 493.415253,730.474304 495.177887,729.683350 
	C497.569061,729.389771 499.272034,729.217957 500.975037,729.046143 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M360.014465,721.246948 
	C361.763550,720.905640 363.458435,720.924133 365.577698,720.979126 
	C366.002075,723.138672 366.002075,725.261597 366.002075,728.248230 
	C363.534607,727.671570 360.893250,727.054321 357.786316,726.328247 
	C358.778961,724.172485 359.369659,722.889648 360.014465,721.246948 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M563.953979,523.145508 
	C564.421082,523.007263 564.888184,522.868958 565.698730,522.603516 
	C566.748901,522.495483 567.455505,522.514587 568.842163,522.715942 
	C570.630737,523.060120 571.739319,523.221985 572.989380,523.721069 
	C575.419189,524.717285 577.707520,525.376282 579.702515,526.313904 
	C576.267395,526.723999 573.125732,526.855530 569.589905,526.981812 
	C568.146240,526.973633 567.096619,526.970703 565.993774,526.646851 
	C565.278320,525.265747 564.616150,524.205627 563.953979,523.145508 
z"
          />
          <path
            fill="#38459A"
            opacity="1.000000"
            stroke="none"
            d="
M249.301544,638.761536 
	C249.941025,638.909363 250.580505,639.057251 251.426880,639.252075 
	C252.252365,640.175415 252.870972,641.051880 253.547272,642.502686 
	C252.736725,644.073486 251.868454,645.069885 251.000183,646.066284 
	C249.338165,645.970215 247.676117,645.874084 245.574066,645.440613 
	C245.058517,644.776489 244.983017,644.449707 245.068695,643.810242 
	C246.587082,641.918823 247.944305,640.340210 249.301544,638.761536 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M634.926880,285.943939 
	C635.384583,285.973236 635.842285,286.002563 636.995911,286.054993 
	C638.782349,286.331390 639.872803,286.584656 640.963318,286.837952 
	C640.967285,287.010925 640.971191,287.183899 640.527710,287.631226 
	C636.984375,288.826019 633.888489,289.746490 630.792603,290.666992 
	C627.798462,290.803894 624.804260,290.940826 621.387085,290.837921 
	C620.961426,290.237061 620.958862,289.876007 621.332520,289.359283 
	C622.785156,289.066498 623.861572,288.929413 625.299683,288.843689 
	C627.100952,288.216736 628.540466,287.538361 630.363037,286.872711 
	C632.139709,286.571564 633.533325,286.257751 634.926880,285.943939 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M646.133606,252.071777 
	C646.852295,252.076141 647.571045,252.080505 648.649292,252.094406 
	C650.847839,252.545959 652.686951,252.987976 654.491211,253.973618 
	C651.270386,255.241669 648.084351,255.966095 644.462769,256.756531 
	C641.969604,257.044922 639.912048,257.267303 637.854492,257.489685 
	C637.854492,257.489685 637.848145,257.064789 637.920166,256.501129 
	C639.259521,255.298782 640.515198,254.635406 641.797424,254.028091 
	C643.230225,253.349457 644.687195,252.721680 646.133606,252.071777 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M307.223328,696.466064 
	C302.136475,692.582275 297.049622,688.698547 292.267456,684.416382 
	C293.046295,684.021118 293.520416,684.024292 293.994568,684.027466 
	C294.752533,684.671936 295.510468,685.316467 296.646484,686.342407 
	C297.695618,687.129028 298.366699,687.534241 299.037781,687.939453 
	C300.140472,688.933289 301.243164,689.927124 302.799561,691.298218 
	C304.517517,692.429199 305.781738,693.182922 307.045959,693.936584 
	C307.045959,693.936584 307.011871,693.996094 307.038116,694.291931 
	C307.117340,695.213867 307.170319,695.839966 307.223328,696.466064 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M400.793518,733.431580 
	C401.904816,734.345154 403.016113,735.258789 404.255127,736.472534 
	C399.866730,736.515991 394.817749,738.932129 391.361328,733.680725 
	C391.695221,733.115356 391.872467,733.014771 392.484924,732.988037 
	C395.544586,733.185059 398.169067,733.308350 400.793518,733.431580 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M273.011932,667.546814 
	C273.752777,667.689209 274.493622,667.831604 275.765869,668.310059 
	C278.474091,670.160461 280.650879,671.674805 282.827698,673.189209 
	C284.036957,674.678406 285.246246,676.167664 286.835449,678.101379 
	C287.497650,679.288757 287.779938,680.031677 287.721832,680.820923 
	C282.580109,676.766479 277.778778,672.665527 272.967834,668.309570 
	C272.976166,667.885254 272.994049,667.716064 273.011932,667.546814 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M466.995361,445.211060 
	C468.145660,445.089722 469.216370,445.343567 470.582153,445.743591 
	C471.165222,446.103455 471.453247,446.317108 471.870667,446.767212 
	C472.068970,452.086517 467.975159,451.872284 465.586243,450.644073 
	C461.127991,448.351868 466.537872,447.367279 466.995361,445.211060 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M579.994446,522.915649 
	C580.731750,522.631775 581.534241,521.996887 582.196167,522.119690 
	C587.430603,523.091064 592.638184,524.206787 597.514771,525.786743 
	C596.405640,526.500366 595.635803,526.705261 594.545288,526.739380 
	C592.528320,526.533325 590.831909,526.498047 588.790344,526.279724 
	C587.938354,526.159668 587.431641,526.222778 586.626831,526.188293 
	C585.921204,526.000183 585.513672,525.909668 585.016357,525.476685 
	C583.282532,524.394714 581.638489,523.655151 579.994446,522.915649 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M272.876892,667.215210 
	C272.994049,667.716064 272.976166,667.885254 272.539246,668.363037 
	C270.101532,668.608582 268.082855,668.545532 266.064209,668.482483 
	C263.198792,667.466003 260.333405,666.449463 257.246521,665.132324 
	C258.400238,664.631226 259.840302,664.067200 261.138458,664.298706 
	C265.035950,664.993713 268.878113,665.999207 272.876892,667.215210 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M606.016846,293.060608 
	C601.154663,294.309113 596.292419,295.557617 591.184937,296.370178 
	C593.286072,294.891724 595.632507,293.849213 598.424194,292.852173 
	C601.251831,292.951935 603.634338,293.006256 606.016846,293.060608 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M597.979004,292.806732 
	C595.632507,293.849213 593.286072,294.891724 590.741760,296.220703 
	C588.986084,296.536163 587.428162,296.565155 585.474976,296.613800 
	C585.106384,296.332489 585.053284,296.046295 585.115479,295.225769 
	C587.881714,293.775482 590.452759,292.874390 593.439209,291.974213 
	C595.238586,291.919800 596.622620,291.864441 598.021484,292.059113 
	C598.017151,292.474976 597.998108,292.640869 597.979004,292.806732 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M501.255249,728.751831 
	C499.272034,729.217957 497.569061,729.389771 495.512634,729.492432 
	C495.159180,729.423340 495.084839,729.006714 495.040039,728.797119 
	C500.121918,727.847839 505.248566,727.108032 510.667236,726.642212 
	C507.817963,727.429871 504.676727,727.943665 501.255249,728.751831 
z"
          />
          <path
            fill="#305CB3"
            opacity="1.000000"
            stroke="none"
            d="
M598.424194,292.852173 
	C597.998108,292.640869 598.017151,292.474976 598.112183,291.710083 
	C601.828552,290.428497 605.468872,289.746002 609.084778,289.383301 
	C608.986267,290.540680 608.912170,291.378265 608.838135,292.215851 
	C608.136108,292.426239 607.434082,292.636627 606.374451,292.953796 
	C603.634338,293.006256 601.251831,292.951935 598.424194,292.852173 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M680.738281,250.713028 
	C680.363220,251.621964 679.725281,252.262177 679.006348,253.153931 
	C676.190552,253.560089 673.455872,253.714722 670.295776,253.675995 
	C672.359192,248.818359 676.934692,251.437439 680.738281,250.713028 
z"
          />
          <path
            fill="#BED4EB"
            opacity="1.000000"
            stroke="none"
            d="
M857.341675,432.974915 
	C858.780090,428.785400 860.594299,424.602905 862.689087,420.209076 
	C864.008118,423.240204 860.951050,430.797546 857.341675,432.974915 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M575.824707,671.232666 
	C574.234131,672.227783 572.196716,673.110168 569.624023,674.399170 
	C567.730286,674.828186 566.371765,674.850647 565.028259,674.572998 
	C565.028137,674.073181 565.013000,673.873474 564.997864,673.673706 
	C568.457825,672.822510 571.917847,671.971313 575.824707,671.232666 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M641.314575,286.819397 
	C639.872803,286.584656 638.782349,286.331390 637.339478,286.020386 
	C641.495667,284.785706 646.004211,283.608734 650.522888,282.963684 
	C648.275146,284.529205 646.017273,285.562775 643.759399,286.596313 
	C643.061584,286.664490 642.363708,286.732697 641.314575,286.819397 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M564.540466,673.753784 
	C565.013000,673.873474 565.028137,674.073181 564.737488,674.865845 
	C560.289124,675.441833 556.146606,675.424927 552.015503,675.126892 
	C552.045837,674.659241 552.064636,674.472656 552.083496,674.286011 
	C556.083374,674.135254 560.083191,673.984497 564.540466,673.753784 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M608.724182,527.183594 
	C609.056030,527.683533 609.029175,528.041077 608.566650,528.717896 
	C606.413452,529.046814 604.696106,529.056519 602.671814,528.974670 
	C601.940063,528.846313 601.515198,528.809570 601.097656,528.434937 
	C601.079529,527.649109 601.054016,527.201233 601.028503,526.753296 
	C603.629272,522.898376 605.905579,527.309570 608.724182,527.183594 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M620.956299,289.514984 
	C620.958862,289.876007 620.961426,290.237061 620.984375,290.870667 
	C618.502258,291.952362 615.999756,292.761475 613.259644,293.016052 
	C613.054565,292.058899 613.087097,291.656250 613.383911,291.054077 
	C613.802063,290.696320 613.955994,290.538055 614.446533,290.489105 
	C616.840881,290.237274 618.898621,289.876129 620.956299,289.514984 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M644.898376,256.690521 
	C648.084351,255.966095 651.270386,255.241669 654.740417,254.226929 
	C655.519958,253.997894 656.015381,254.059189 656.787109,254.525635 
	C656.377258,255.623444 655.691101,256.316071 655.004944,257.008728 
	C654.268066,257.025726 653.531189,257.042694 651.984680,257.029358 
	C649.082825,256.896210 646.990601,256.793365 644.898376,256.690521 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M579.576660,522.920593 
	C581.638489,523.655151 583.282532,524.394714 584.599854,525.585327 
	C582.847412,526.036072 581.421631,526.035706 579.995911,526.035339 
	C577.707520,525.376282 575.419189,524.717285 573.321655,523.524902 
	C573.734192,522.862671 573.955872,522.733643 574.177612,522.604614 
	C575.838074,522.711609 577.498474,522.818604 579.576660,522.920593 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M728.997070,244.008514 
	C731.362854,243.926926 733.728638,243.845337 736.560181,243.957504 
	C737.025391,244.327957 737.024780,244.504669 736.878662,244.972504 
	C736.144653,245.480743 735.556030,245.697876 734.539551,245.900528 
	C731.037048,246.158829 727.962402,246.431641 724.992798,246.390259 
	C726.397583,245.386887 727.697327,244.697693 728.997070,244.008514 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M337.258362,716.902100 
	C336.592743,715.169495 336.287811,713.440002 335.866577,711.339478 
	C337.834259,712.032776 339.918274,713.097168 342.090820,714.501282 
	C340.659271,715.529114 339.139160,716.217163 337.258362,716.902100 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M395.986694,729.033264 
	C395.990784,728.580566 395.994904,728.127930 396.001556,727.335571 
	C398.623291,727.396667 401.242523,727.797424 403.935242,728.544800 
	C403.732941,729.036682 403.465942,729.196777 402.698364,729.542236 
	C400.121521,729.486328 398.054077,729.259827 395.986694,729.033264 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M592.879272,344.926086 
	C590.290039,348.857239 588.816162,346.355194 587.183044,344.705322 
	C587.300842,344.139038 587.418579,343.572784 587.536316,343.006500 
	C589.312500,343.517059 591.088684,344.027649 592.879272,344.926086 
z"
          />
          <path
            fill="#5F629C"
            opacity="1.000000"
            stroke="none"
            d="
M773.802856,581.398193 
	C777.883118,578.597900 780.932495,580.819946 784.046265,583.427979 
	C783.338013,583.934387 782.556274,584.102844 781.186340,583.941528 
	C778.317444,583.096375 776.036865,582.580994 773.756226,582.065613 
	C773.756226,582.065613 773.680359,581.599854 773.802856,581.398193 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M655.242310,257.280823 
	C655.691101,256.316071 656.377258,255.623444 657.140869,254.649506 
	C657.218384,254.368210 657.610168,254.194275 658.201294,254.208374 
	C659.856018,254.187561 660.919739,254.152649 661.982300,254.393158 
	C661.983521,254.852158 661.985840,255.035751 661.988220,255.219330 
	C659.818726,255.997177 657.649170,256.775024 655.242310,257.280823 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M710.464111,228.285645 
	C709.894470,228.205429 709.679504,228.065414 709.275879,227.444763 
	C710.393005,226.306854 711.698975,225.649612 713.327820,224.974640 
	C714.080322,224.920227 714.509949,224.883530 715.371704,224.897659 
	C717.536316,224.971512 719.268616,224.994522 721.000977,225.017548 
	C718.469666,225.888550 715.938354,226.759552 713.189331,227.944489 
	C712.253967,228.247589 711.536377,228.236725 710.464111,228.285645 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M773.531494,582.288330 
	C776.036865,582.580994 778.317444,583.096375 780.776978,583.947876 
	C778.122742,584.915710 775.046570,586.595764 773.531494,582.288330 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M600.702881,526.751831 
	C601.054016,527.201233 601.079529,527.649109 600.883789,528.744934 
	C597.955200,530.375000 595.591125,530.555725 594.865906,526.910156 
	C595.635803,526.705261 596.405640,526.500366 597.566406,526.180298 
	C598.763916,526.293640 599.570557,526.521973 600.702881,526.751831 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M334.151611,709.681396 
	C331.615601,708.876709 328.984589,707.734680 326.135529,706.328613 
	C326.788391,705.893250 327.659332,705.721863 328.740265,705.293823 
	C329.376862,705.043945 329.803467,705.050842 330.545166,705.102783 
	C331.925751,706.546631 332.991180,707.945312 334.151611,709.681396 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M440.880615,736.871948 
	C438.046265,740.528137 434.622009,739.119751 431.085510,737.508057 
	C430.961304,737.150208 431.046844,736.663086 431.514191,736.514526 
	C434.732697,736.306396 437.483856,736.246887 440.235016,736.187378 
	C440.378448,736.339600 440.521881,736.491760 440.880615,736.871948 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M315.615387,709.096375 
	C315.998444,709.512024 316.043121,709.854370 316.078552,710.455933 
	C313.079834,712.168823 311.104980,711.156128 310.193817,707.672607 
	C310.645355,707.065186 310.836517,706.975952 311.027649,706.886719 
	C312.444092,707.598877 313.860565,708.310974 315.615387,709.096375 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M504.986511,416.116425 
	C505.403290,421.960327 505.469788,428.082153 505.435852,434.681519 
	C505.102356,428.904144 504.869293,422.649231 504.986511,416.116425 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M631.224121,290.764954 
	C633.888489,289.746490 636.984375,288.826019 640.485107,287.887268 
	C637.811768,288.866943 634.733704,289.864899 631.224121,290.764954 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M728.753906,243.737061 
	C727.697327,244.697693 726.397583,245.386887 724.681152,246.453918 
	C723.841370,246.833023 723.418274,246.834274 722.808655,246.621155 
	C722.076599,246.274200 721.530945,246.141632 720.985352,246.009064 
	C723.493835,245.161240 726.002258,244.313416 728.753906,243.737061 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M644.145630,286.742279 
	C646.017273,285.562775 648.275146,284.529205 650.768921,283.224915 
	C651.477234,282.991821 651.949524,283.029419 652.661133,283.519043 
	C650.930664,284.952667 648.961060,285.934296 646.991394,286.915955 
	C646.171570,286.906677 645.351685,286.897461 644.145630,286.742279 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M843.620117,353.951233 
	C845.669495,355.393921 847.340149,356.804047 849.060913,358.541870 
	C846.518127,357.608063 843.925171,356.346619 841.042358,354.835388 
	C840.790344,354.395874 840.828186,354.206177 840.937988,353.734802 
	C841.753784,353.608307 842.497498,353.763489 843.620117,353.951233 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M519.964905,720.467407 
	C520.113770,720.759888 520.342651,720.961487 520.848267,721.199707 
	C519.222473,721.763916 517.399963,722.200623 515.322815,722.387756 
	C515.101318,721.773438 515.134583,721.408569 515.541016,720.753052 
	C517.264465,720.464050 518.614685,720.465759 519.964905,720.467407 
z"
          />
          <path
            fill="#BED4EB"
            opacity="1.000000"
            stroke="none"
            d="
M848.595337,438.535095 
	C849.104675,438.598755 849.291443,438.727722 849.777466,439.020782 
	C848.704529,442.386993 846.546082,443.756073 843.387085,441.723450 
	C844.758850,440.433685 845.863159,439.589478 846.967468,438.745270 
	C847.402588,438.696991 847.837646,438.648743 848.595337,438.535095 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M647.209351,287.191956 
	C648.961060,285.934296 650.930664,284.952667 652.989380,283.655487 
	C653.547913,283.276764 654.017517,283.213593 654.797607,283.333801 
	C653.945129,287.550415 650.672119,287.481781 647.209351,287.191956 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M282.854584,672.828552 
	C280.650879,671.674805 278.474091,670.160461 276.140900,668.354004 
	C278.283478,669.530579 280.582489,670.999268 282.854584,672.828552 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M657.295044,282.952881 
	C659.475586,281.680054 662.014160,280.557159 664.774170,279.695190 
	C663.442566,280.833405 661.889648,281.710693 660.155334,282.881714 
	C659.200256,283.151245 658.426697,283.127014 657.295044,282.952881 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M251.196625,646.339844 
	C251.868454,645.069885 252.736725,644.073486 253.664093,642.805054 
	C254.686493,643.094788 255.649780,643.656555 256.660248,644.524902 
	C256.707428,644.831421 256.691803,644.820557 256.386780,644.876221 
	C255.396500,645.664490 254.711243,646.396973 254.025970,647.129395 
	C253.148331,646.957458 252.270691,646.785461 251.196625,646.339844 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M385.173523,723.112915 
	C386.746582,721.967590 388.539185,721.128235 390.637054,720.138794 
	C391.146820,723.736755 390.292847,724.264587 385.173523,723.112915 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M808.992310,589.532715 
	C811.448425,589.550415 814.003845,589.908875 816.784546,590.574646 
	C814.370483,590.545837 811.731018,590.209717 808.992310,589.532715 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M507.575500,515.940857 
	C509.651947,516.607239 511.319977,517.287842 513.129333,518.282104 
	C510.544434,518.120911 507.818207,517.646118 504.957397,516.850708 
	C505.604218,516.338501 506.385651,516.146790 507.575500,515.940857 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M302.103394,705.322571 
	C302.587463,704.906372 303.122650,704.844971 304.045898,704.653931 
	C305.335999,704.733154 306.238098,704.942078 307.378448,705.400208 
	C307.447113,706.014771 307.277527,706.380066 307.107971,706.745422 
	C304.953033,708.725891 303.338013,708.202393 302.103394,705.322571 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M736.892700,557.677124 
	C734.299744,557.378296 731.639282,556.706604 728.828430,555.709106 
	C731.393738,556.023621 734.109436,556.664001 736.892700,557.677124 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M713.249451,249.899658 
	C712.921448,249.187164 712.989807,248.537918 713.102356,247.567047 
	C714.575623,247.246674 716.004700,247.247925 717.815186,247.463272 
	C716.679688,248.439224 715.162781,249.201065 713.249451,249.899658 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M614.109863,290.379791 
	C613.955994,290.538055 613.802063,290.696320 613.065552,291.001740 
	C611.933899,291.689056 611.384949,292.229248 610.835938,292.769440 
	C610.363403,292.727020 609.890869,292.684601 609.128174,292.429016 
	C608.912170,291.378265 608.986267,290.540680 609.496338,289.374207 
	C611.318359,288.997681 612.704346,288.950043 614.087891,289.151855 
	C614.105469,289.727203 614.113647,290.053345 614.109863,290.379791 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M392.049713,732.914246 
	C391.872467,733.014771 391.695221,733.115356 391.248566,733.361084 
	C390.539124,733.395325 390.099091,733.284485 389.318756,733.205322 
	C388.807983,732.921814 388.637482,732.606628 388.247070,732.132324 
	C389.231384,731.066956 390.435608,730.160767 391.904785,729.689819 
	C392.129700,731.054810 392.089691,731.984497 392.049713,732.914246 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M706.777649,246.803131 
	C704.926331,247.717834 702.852966,248.375534 700.385498,249.020203 
	C702.179443,248.186813 704.367493,247.366455 706.777649,246.803131 
z"
          />
          <path
            fill="#BED4EB"
            opacity="1.000000"
            stroke="none"
            d="
M852.025879,438.772034 
	C853.227661,436.887512 854.801758,435.077301 856.671143,433.119934 
	C855.443726,434.930664 853.920959,436.888489 852.025879,438.772034 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M551.846802,673.961914 
	C552.064636,674.472656 552.045837,674.659241 551.567261,675.138428 
	C548.989075,675.413696 546.870605,675.396301 544.840210,675.057556 
	C544.950562,674.515015 544.972900,674.293945 544.995178,674.072876 
	C547.200195,673.927856 549.405151,673.782837 551.846802,673.961914 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M746.809814,241.152618 
	C747.097656,241.822830 747.020325,242.418976 746.580444,243.404205 
	C745.785950,243.950882 745.354126,244.108475 744.564575,244.197510 
	C743.479736,244.105072 742.752441,244.081207 742.065430,243.766968 
	C742.135986,243.283554 742.166260,243.090500 742.196594,242.897446 
	C743.612610,242.291153 745.028564,241.684860 746.809814,241.152618 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M510.259216,733.332642 
	C511.764496,732.406677 513.495789,731.709045 515.617493,731.041748 
	C514.166992,731.901672 512.326111,732.731323 510.259216,733.332642 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M537.112549,720.854126 
	C538.522095,720.182434 540.365112,719.583008 542.617188,719.039429 
	C541.199524,719.705627 539.372803,720.316040 537.112549,720.854126 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M307.547363,696.657837 
	C307.170319,695.839966 307.117340,695.213867 307.064209,694.269409 
	C308.337128,694.833130 309.610229,695.715088 310.845215,696.975708 
	C309.828552,697.186157 308.849945,697.017944 307.547363,696.657837 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M846.746094,438.519714 
	C845.863159,439.589478 844.758850,440.433685 843.304688,441.396729 
	C842.954895,441.515564 842.521301,441.553619 842.344727,441.427063 
	C843.620422,440.298401 845.072571,439.296265 846.746094,438.519714 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M508.070709,721.352783 
	C509.524689,721.168640 511.031525,721.337097 512.744141,721.822632 
	C511.341156,721.994934 509.732330,721.850159 508.070709,721.352783 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M669.776245,350.628174 
	C668.269409,351.644348 666.557312,352.427856 664.471802,353.189758 
	C665.922607,352.243896 667.746765,351.319702 669.776245,350.628174 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M574.036865,522.308105 
	C573.955872,522.733643 573.734192,522.862671 573.180176,523.187744 
	C571.739319,523.221985 570.630737,523.060120 569.187195,522.636597 
	C570.533569,522.253845 572.214905,522.132751 574.036865,522.308105 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M307.004395,693.608093 
	C305.781738,693.182922 304.517517,692.429199 303.116821,691.379517 
	C304.307861,691.815552 305.635376,692.547546 307.004395,693.608093 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M247.167145,673.162720 
	C248.456696,673.578796 249.741364,674.356201 251.139038,675.453857 
	C249.888794,675.024109 248.525528,674.274109 247.167145,673.162720 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M288.062195,680.774536 
	C287.779938,680.031677 287.497650,679.288757 287.119751,678.257812 
	C288.330841,678.719788 289.637543,679.469849 290.948914,680.633423 
	C290.953583,681.046936 290.818481,681.183411 290.499023,681.134399 
	C289.749420,681.101868 289.319305,681.118286 288.681061,681.047852 
	C288.472931,680.960938 288.062195,680.774536 288.062195,680.774536 
z"
          />
          <path
            fill="#5F629C"
            opacity="1.000000"
            stroke="none"
            d="
M307.461121,706.833435 
	C307.277527,706.380066 307.447113,706.014771 307.720215,705.479492 
	C308.861023,705.621765 309.898285,705.934021 310.981567,706.566528 
	C310.836517,706.975952 310.645355,707.065186 310.191772,707.331360 
	C309.224304,707.312683 308.519318,707.117065 307.461121,706.833435 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M668.627563,253.950134 
	C667.816528,254.545181 666.615967,254.998291 665.197144,254.871613 
	C664.963684,254.083206 664.948669,253.874603 664.933655,253.666016 
	C666.035156,253.713409 667.136597,253.760788 668.627563,253.950134 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M517.377930,730.991943 
	C518.077271,730.314575 519.152283,729.649658 520.597290,728.983887 
	C519.895996,729.656799 518.824768,730.330627 517.377930,730.991943 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M661.983398,254.117752 
	C660.919739,254.152649 659.856018,254.187561 658.402466,254.134491 
	C659.189514,253.518860 660.366455,252.991226 661.769775,253.001709 
	C661.991882,253.732498 661.987610,253.925125 661.983398,254.117752 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M382.959961,726.479492 
	C381.860504,726.738281 380.676239,726.676208 379.231384,726.374023 
	C380.272278,726.142151 381.573730,726.150452 382.959961,726.479492 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M293.970551,683.688660 
	C293.520416,684.024292 293.046295,684.021118 292.220459,684.076477 
	C291.868805,684.135010 291.505554,683.931885 291.325989,683.492004 
	C291.037140,682.429199 290.927795,681.806335 290.818481,681.183411 
	C290.818481,681.183411 290.953583,681.046936 291.021973,680.977966 
	C292.042450,681.722656 292.994507,682.536255 293.970551,683.688660 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M243.011765,669.386475 
	C244.201324,669.846497 245.440750,670.652283 246.770996,671.776489 
	C245.595108,671.307434 244.328369,670.519897 243.011765,669.386475 
z"
          />
          <path
            fill="#9CA5CB"
            opacity="1.000000"
            stroke="none"
            d="
M640.553223,677.847900 
	C640.384399,678.581421 639.867798,679.367798 639.231628,680.397095 
	C638.615417,680.760254 638.118652,680.880493 637.246277,680.916138 
	C637.982239,679.854614 639.093811,678.877747 640.553223,677.847900 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M741.797241,242.912048 
	C742.166260,243.090500 742.135986,243.283554 741.915405,244.019318 
	C741.185303,244.785446 740.645447,245.008881 739.802368,245.072327 
	C739.303772,244.795029 739.108459,244.677734 738.922302,244.310623 
	C738.985535,243.731934 739.015991,243.400894 739.022888,243.067673 
	C739.814514,243.020660 740.606201,242.973648 741.797241,242.912048 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M521.330200,728.939209 
	C520.957520,728.265198 520.923767,727.548157 520.860535,726.475708 
	C523.227722,726.406433 523.616821,727.298584 521.330200,728.939209 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M371.046631,721.465088 
	C371.977814,721.471863 372.993103,721.819092 374.123291,722.491577 
	C373.202332,722.479797 372.166565,722.142639 371.046631,721.465088 
z"
          />
          <path
            fill="#5F629C"
            opacity="1.000000"
            stroke="none"
            d="
M764.019104,578.951965 
	C765.007874,579.337097 765.996582,579.722168 767.038818,580.435425 
	C766.327942,580.889404 765.563477,581.015198 764.402954,581.134033 
	C764.012024,580.958252 764.017212,580.789429 764.006958,580.077759 
	C764.000732,579.340637 764.009949,579.146301 764.019104,578.951965 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M738.872803,242.844116 
	C739.015991,243.400894 738.985535,243.731934 738.612671,244.394150 
	C737.870667,244.712112 737.447388,244.696747 737.024170,244.681396 
	C737.024780,244.504669 737.025391,244.327957 736.996094,243.884964 
	C737.551697,243.285965 738.137207,242.953262 738.872803,242.844116 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M254.041931,664.349854 
	C253.247467,664.566833 252.340973,664.510986 251.219757,664.178345 
	C251.979965,663.960022 252.954941,664.018494 254.041931,664.349854 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M545.026855,719.208008 
	C545.456909,718.579224 545.923157,718.273621 546.684753,717.974304 
	C546.756104,718.809631 546.532043,719.638550 546.307983,720.467529 
	C545.893005,720.155396 545.478027,719.843201 545.026855,719.208008 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M661.982300,254.393158 
	C661.987610,253.925125 661.991882,253.732498 662.005188,253.250641 
	C662.842102,253.049728 663.669922,253.138016 664.715698,253.446167 
	C664.948669,253.874603 664.963684,254.083206 664.984619,254.605057 
	C664.236572,254.966080 663.482666,255.013885 662.358459,255.140503 
	C661.985840,255.035751 661.983521,254.852158 661.982300,254.393158 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M709.907715,364.790283 
	C709.178650,364.375519 708.489563,363.614960 707.787415,362.518036 
	C708.498840,362.935944 709.223328,363.690186 709.907715,364.790283 
z"
          />
          <path
            fill="#B9CFEA"
            opacity="1.000000"
            stroke="none"
            d="
M657.970459,355.321777 
	C657.743896,356.239288 657.162964,357.042969 656.258911,357.881775 
	C656.495911,357.013916 657.055969,356.110962 657.970459,355.321777 
z"
          />
          <path
            fill="#9CA5CB"
            opacity="1.000000"
            stroke="none"
            d="
M633.060913,683.725586 
	C633.377991,682.852295 634.010925,682.048706 634.983154,681.345093 
	C634.673889,682.228394 634.025330,683.011902 633.060913,683.725586 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M663.018921,353.336304 
	C662.472656,354.020813 661.592590,354.565674 660.365601,354.863007 
	C660.021118,354.434631 660.023560,354.253784 660.025940,354.072937 
	C660.912354,353.780853 661.798767,353.488800 663.018921,353.336304 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M351.082581,719.254028 
	C351.713135,719.345947 352.372864,719.783203 353.134766,720.521118 
	C352.528503,720.414307 351.820129,720.006836 351.082581,719.254028 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M298.986389,687.616211 
	C298.366699,687.534241 297.695618,687.129028 296.945068,686.417236 
	C297.555420,686.504700 298.245209,686.898804 298.986389,687.616211 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M506.647949,718.044312 
	C506.984222,718.442444 507.026520,718.873840 507.051056,719.629883 
	C506.512482,719.746948 505.991638,719.539368 505.470825,719.331787 
	C505.765198,718.913757 506.059601,718.495667 506.647949,718.044312 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M563.170898,717.152100 
	C563.487366,716.622925 563.952026,716.332153 564.705566,716.064209 
	C564.436035,716.521606 563.877625,716.956116 563.170898,717.152100 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M568.783325,704.807068 
	C569.223755,704.152222 569.740662,703.562683 570.559326,702.915039 
	C570.911072,703.356201 570.961121,703.855408 571.037476,704.731201 
	C571.063843,705.107788 571.027771,705.075500 570.699341,705.042236 
	C569.917114,705.008301 569.463440,705.007629 569.009705,705.006897 
	C569.009705,705.006897 568.859924,704.872437 568.783325,704.807068 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M333.005585,716.428711 
	C333.519684,716.250061 334.080933,716.349487 334.822998,716.678467 
	C334.353455,716.840942 333.703094,716.773926 333.005585,716.428711 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M768.923218,581.570312 
	C769.411194,581.182373 769.922791,581.067993 770.777832,581.170471 
	C770.396484,581.539551 769.671692,581.691772 768.923218,581.570312 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M611.183594,292.888275 
	C611.384949,292.229248 611.933899,291.689056 612.801270,291.201233 
	C613.087097,291.656250 613.054565,292.058899 613.008545,292.766968 
	C612.507141,293.050598 612.019226,293.028870 611.183594,292.888275 
z"
          />
          <path
            fill="#B8B9D1"
            opacity="1.000000"
            stroke="none"
            d="
M726.924438,554.759399 
	C726.420898,555.000305 725.894592,554.936951 725.185181,554.676758 
	C725.635315,554.471680 726.268494,554.463379 726.924438,554.759399 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M720.961548,246.288925 
	C721.530945,246.141632 722.076599,246.274200 722.797485,246.880569 
	C722.975586,247.526886 722.978394,247.699402 722.981262,247.871918 
	C722.426880,247.782700 721.872437,247.693481 721.165649,247.364853 
	C720.988098,246.939896 720.962952,246.754349 720.961548,246.288925 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M371.016937,657.452026 
	C371.623627,657.524719 372.296753,657.908081 373.039673,658.602417 
	C372.434113,658.529907 371.758759,658.146301 371.016937,657.452026 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M387.123413,659.159790 
	C387.561340,658.796936 388.137726,658.671692 388.858276,658.766357 
	C388.422272,659.123352 387.842102,659.260376 387.123413,659.159790 
z"
          />
          <path
            fill="#B9CFEA"
            opacity="1.000000"
            stroke="none"
            d="
M659.706177,354.075073 
	C660.023560,354.253784 660.021118,354.434631 660.033997,354.887665 
	C659.673340,355.244476 659.297424,355.329102 658.639771,355.385620 
	C658.700867,354.930756 659.043701,354.503967 659.706177,354.075073 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M544.742554,674.086731 
	C544.972900,674.293945 544.950562,674.515015 544.795593,675.100220 
	C544.662903,675.464355 544.600586,675.577820 544.600586,675.577820 
	C544.600586,675.577820 544.241699,675.230652 544.062134,675.055054 
	C544.085083,674.619873 544.287537,674.360229 544.742554,674.086731 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M653.328369,360.910950 
	C653.013794,360.859375 653.008057,360.679260 652.989258,360.228760 
	C653.237671,359.943695 653.492004,359.979797 653.899658,360.165161 
	C653.919250,360.436646 653.778259,360.609497 653.328369,360.910950 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M113.556381,367.918640 
	C113.657791,367.490479 113.759201,367.062286 114.066772,366.008759 
	C115.230934,362.941376 115.869354,360.300751 117.231216,358.109772 
	C118.787750,355.605591 120.689384,351.995361 122.971558,351.543182 
	C127.446060,350.656616 132.620270,349.602692 136.708084,353.440002 
	C137.169876,353.873505 137.460556,354.522858 137.735443,355.116180 
	C139.113281,358.089935 140.459427,361.078369 141.816711,364.061646 
	C141.847183,364.491486 141.877640,364.921295 141.965286,366.010773 
	C142.911591,367.950775 143.800720,369.231110 144.689835,370.511475 
	C144.740051,371.137634 144.790268,371.763794 144.859970,373.083557 
	C146.907013,378.522919 148.934586,383.268616 150.954803,388.386963 
	C151.092575,389.486572 151.237686,390.213593 151.305603,391.255035 
	C151.777649,392.092285 152.326904,392.615143 152.899780,393.063995 
	C152.923401,392.989990 152.857742,393.133942 152.874084,393.418365 
	C153.202988,394.154480 153.515564,394.606171 153.835266,395.383667 
	C153.960297,396.125916 154.078156,396.542297 154.155487,397.327484 
	C156.346893,402.819519 158.578842,407.942749 160.821808,413.389404 
	C160.899460,414.138916 160.966110,414.565002 160.969376,415.358246 
	C161.928543,417.820892 162.951080,419.916412 163.959549,422.382263 
	C164.070251,423.478149 164.195038,424.203674 164.251022,425.288727 
	C165.450546,428.531403 166.718857,431.414520 168.518280,435.505005 
	C168.518280,425.678467 168.518280,417.298340 168.516449,408.456512 
	C168.472641,393.688782 168.430664,379.382782 168.574463,364.731873 
	C168.738647,361.922882 168.717072,359.458771 168.794632,356.644043 
	C168.956528,355.570740 169.019287,354.847992 169.403412,353.950745 
	C172.052460,352.875031 174.313934,351.569672 176.724533,351.174805 
	C179.759750,350.677643 182.916840,350.924591 186.021347,350.850525 
	C186.448593,350.945160 186.875824,351.039825 187.777313,351.492340 
	C188.824066,354.056244 189.829681,356.250488 189.890076,358.470459 
	C190.093689,365.956512 189.892532,373.452759 190.014999,380.942352 
	C190.039902,382.465820 190.799759,383.977203 191.220810,385.494171 
	C192.442764,384.633118 193.813538,383.920807 194.854767,382.879120 
	C196.833572,380.899353 198.795959,378.858704 200.458206,376.615814 
	C204.479034,371.190430 208.323334,365.634186 212.238800,360.130707 
	C213.729172,358.293518 215.219543,356.456329 217.311523,354.406464 
	C219.606369,353.125885 221.299606,352.058014 222.992859,350.990112 
	C227.020477,351.004913 231.048080,351.019714 235.659805,351.323669 
	C237.122070,352.115662 238.000229,352.618469 238.878372,353.121307 
	C238.878372,353.121307 239.000000,353.001221 238.977448,353.408661 
	C239.412521,354.907684 239.870132,355.999298 240.222412,357.455750 
	C239.914169,358.811066 239.711258,359.801544 239.206696,360.918793 
	C236.886551,363.639160 234.868088,366.232788 232.828934,368.800293 
	C232.808258,368.774231 232.750397,368.807129 232.449341,368.879028 
	C231.742691,369.621735 231.337097,370.292542 230.681549,371.098511 
	C229.937515,371.797668 229.443436,372.361603 228.641266,373.064728 
	C226.512772,375.775238 224.692368,378.346588 222.675781,381.046326 
	C222.239334,381.387390 222.068619,381.645935 221.665314,382.065674 
	C220.848633,383.081848 220.334076,383.982635 219.609283,385.053467 
	C219.188065,385.715057 218.977097,386.206604 218.438019,386.794525 
	C216.089600,389.607574 214.069305,392.324249 212.005127,395.356995 
	C212.005814,396.094727 212.050385,396.516357 212.068146,397.293762 
	C214.267975,400.216370 216.494598,402.783203 218.754028,405.526123 
	C218.786850,405.702179 218.944885,406.023621 218.965698,406.376190 
	C220.944000,408.863586 222.901489,410.998413 224.930939,413.071777 
	C225.002884,413.010376 224.862061,413.139435 224.894897,413.464233 
	C225.924240,414.882782 226.920731,415.976562 227.948029,417.047668 
	C227.978836,417.024994 227.911438,417.077667 227.924255,417.400879 
	C228.590424,418.527924 229.243759,419.331696 230.006119,420.338287 
	C230.302963,420.792755 230.538971,420.985352 230.885620,421.465912 
	C232.234131,422.956848 233.520172,424.100800 235.002197,425.463806 
	C235.746155,426.502350 236.294159,427.321869 236.906555,428.482788 
	C238.276123,430.732178 239.634293,432.606293 240.875305,434.555084 
	C243.416473,438.545502 241.683640,442.606476 237.023392,442.754730 
	C230.221619,442.971191 223.016113,444.425873 217.367737,438.376404 
	C217.222717,437.735657 217.121445,437.373199 216.955780,436.748840 
	C216.598267,436.301178 216.305145,436.115448 215.788330,435.767700 
	C215.086594,435.054901 214.608521,434.504150 214.058899,433.698212 
	C213.701096,433.253784 213.414841,433.064575 213.124390,432.858185 
	C213.120178,432.841003 213.148682,432.820129 213.092392,432.483398 
	C210.124802,428.657776 207.213501,425.168854 204.216339,421.413849 
	C203.721130,420.813751 203.311798,420.479797 202.801453,419.819092 
	C200.200470,416.630615 197.700500,413.768890 195.178986,410.609436 
	C194.878281,409.796356 194.599121,409.281036 194.194748,408.420807 
	C191.544434,406.356018 189.863647,406.285004 190.114578,410.538757 
	C190.093704,421.016022 190.119232,431.031708 190.144745,441.047424 
	C189.746704,441.413666 189.348648,441.779938 188.506958,442.458008 
	C187.053619,442.871185 186.043915,442.972595 184.591125,443.001526 
	C181.752090,442.985809 179.356140,443.042511 176.636505,443.059570 
	C175.878403,443.026825 175.443985,443.033722 174.759842,442.976501 
	C174.339218,442.930725 174.168320,442.949127 173.647003,442.902283 
	C170.537521,442.912537 167.778473,442.988098 164.559937,442.997467 
	C160.385727,442.990967 156.670975,443.050629 152.582001,443.051025 
	C151.210632,442.912872 150.213486,442.833954 149.216339,442.755066 
	C149.216339,442.755066 149.314346,442.677399 149.239395,442.297729 
	C148.134857,439.936157 147.105286,437.954254 146.061554,435.648041 
	C145.751785,434.517395 145.456192,433.711121 145.148376,432.564453 
	C144.536575,431.359039 143.937012,430.494019 143.295624,429.303986 
	C143.131363,428.335327 143.008926,427.691650 142.947861,426.655884 
	C142.887970,422.704041 140.500137,422.766907 137.615112,423.058289 
	C136.854401,423.116974 136.424454,423.213104 135.994507,423.309235 
	C130.992905,423.219421 125.991310,423.129578 120.248352,423.017578 
	C119.318207,423.031799 119.129425,423.068146 118.940651,423.104462 
	C118.043549,423.151611 117.146439,423.198761 115.630936,423.476929 
	C114.208099,425.132812 113.403664,426.557709 112.589310,428.318604 
	C112.567268,429.322449 112.555122,429.990295 112.213470,430.778931 
	C110.865562,433.068909 109.513390,435.149384 108.907761,437.428314 
	C108.019958,440.768951 106.142784,442.617737 102.757584,442.816162 
	C100.287689,442.960938 97.804604,442.900330 95.327438,442.897400 
	C87.583046,442.888306 87.221252,442.496429 87.840408,434.919830 
	C87.904427,434.499298 87.968445,434.078766 88.338028,433.130524 
	C89.383827,430.711578 90.124054,428.820343 90.864296,426.929108 
	C90.934441,426.506439 91.004578,426.083771 91.344711,425.163025 
	C91.707443,424.095398 91.800194,423.525818 91.892937,422.956238 
	C92.012810,422.615631 92.132690,422.275024 92.641205,421.529175 
	C94.609863,417.048462 96.189888,412.973053 97.769897,408.897614 
	C97.846001,408.480347 97.922112,408.063049 98.293266,407.133911 
	C99.314606,404.730621 100.040894,402.839233 100.767181,400.947815 
	C100.874794,400.219757 100.982399,399.491730 101.425278,398.244751 
	C102.462318,396.126892 103.164085,394.527924 103.865845,392.928955 
	C103.933434,392.504974 104.001022,392.080994 104.340141,391.109772 
	C106.654785,385.009216 108.697899,379.455872 110.741013,373.902557 
	C110.908440,373.275360 111.075874,372.648193 111.586021,371.585114 
	C112.347610,370.465851 112.766495,369.782471 113.359055,368.945679 
	C113.746460,368.506653 113.754349,368.215454 113.556381,367.918640 
M228.702972,440.988831 
	C231.202316,440.990356 233.725937,441.198059 236.190964,440.907959 
	C237.457901,440.758881 239.306335,439.966980 239.644470,439.020050 
	C240.010544,437.994873 239.237000,436.203918 238.422623,435.171082 
	C229.409851,423.741241 220.521606,412.198944 211.093735,401.117493 
	C207.170578,396.506348 208.477097,393.691010 211.840546,389.738922 
	C219.218124,381.070282 226.164124,372.029602 233.139175,363.026794 
	C234.661057,361.062500 235.566727,358.620728 236.756165,356.398834 
	C234.122620,355.865967 231.483582,354.885864 228.856964,354.918030 
	C225.844559,354.954956 221.701630,354.831390 220.040710,356.623108 
	C213.872711,363.276733 208.619461,370.768494 202.830963,377.787384 
	C199.252136,382.126953 195.326645,386.180634 191.556793,390.362671 
	C190.923798,390.138611 190.290802,389.914581 189.657806,389.690521 
	C189.132385,387.755219 188.218842,385.832733 188.154404,383.882202 
	C187.961945,378.057129 188.183289,372.219788 188.096054,366.389465 
	C187.904495,353.586823 190.198990,354.860840 176.345810,354.729736 
	C172.272491,354.691223 170.737808,356.039764 170.810486,360.221802 
	C171.016022,372.048431 170.950180,383.881622 170.854950,395.711151 
	C170.741104,409.853912 170.427185,423.995575 170.426224,438.137695 
	C170.426163,439.067932 172.261826,440.649078 173.395340,440.801880 
	C176.343292,441.199280 179.397720,440.748291 182.369400,441.042511 
	C186.677750,441.468994 188.351532,439.969543 188.179092,435.471741 
	C187.872910,427.485321 187.951065,419.475616 188.166321,411.483643 
	C188.222366,409.403046 189.379562,407.352081 190.031036,405.287537 
	C192.471054,406.234039 195.754028,406.483429 197.209793,408.243866 
	C204.796021,417.417694 211.885605,427.000793 219.220413,436.384918 
	C221.360382,439.122711 223.618912,441.897858 228.702972,440.988831 
M150.987411,396.585999 
	C148.373032,390.004913 145.668900,383.457733 143.169373,376.833313 
	C140.636551,370.120575 138.393158,363.297455 135.782867,356.616516 
	C135.433945,355.723480 133.715424,354.974976 132.563812,354.865051 
	C129.924286,354.613098 127.104523,354.289368 124.623306,354.966736 
	C122.678841,355.497559 120.377304,357.132324 119.549904,358.894379 
	C116.987793,364.350616 114.975983,370.073242 112.892143,375.744507 
	C108.125725,388.716461 103.501892,401.741028 98.692604,414.696869 
	C97.171333,418.795044 95.093880,422.685669 93.546600,426.775543 
	C91.915993,431.085724 90.614944,435.520538 88.855652,440.853485 
	C94.273460,440.853485 98.242706,441.142426 102.131058,440.707733 
	C103.634857,440.539581 105.557907,439.022430 106.239037,437.612213 
	C107.866852,434.242035 109.463402,430.654694 109.983826,426.999969 
	C110.727585,421.776764 113.959061,420.448792 118.275795,420.298431 
	C123.267494,420.124481 128.269150,420.230835 133.266479,420.224701 
	C143.496979,420.212158 145.540588,421.840179 147.867676,431.837341 
	C149.839340,440.307587 150.675110,440.990112 159.078903,440.992920 
	C166.621140,440.995422 167.382751,439.533051 164.494644,432.535767 
	C161.330887,424.870697 158.633972,417.012665 155.748367,409.233215 
	C154.271194,405.250885 152.826248,401.256622 150.987411,396.585999 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M212.094971,396.938019 
	C212.050385,396.516357 212.005814,396.094727 212.355881,395.314636 
	C214.755707,392.203522 216.760925,389.450836 218.766144,386.698181 
	C218.977097,386.206604 219.188065,385.715057 219.972504,385.046021 
	C221.019806,383.895813 221.493622,382.923096 221.967438,381.950348 
	C222.068619,381.645935 222.239334,381.387390 223.029724,381.052979 
	C225.380661,379.283112 227.181442,377.635040 229.340363,375.953552 
	C234.815750,373.307556 235.695129,371.327881 232.849609,368.826385 
	C234.868088,366.232788 236.886551,363.639160 239.623032,360.983734 
	C240.895233,360.956268 241.449432,360.990631 242.003632,361.024994 
	C242.002747,361.768066 242.001862,362.511108 242.000290,363.970673 
	C242.006393,365.146851 242.013184,365.606537 241.823318,366.099701 
	C241.626648,366.133179 241.227661,366.128510 240.874222,366.110413 
	C239.814621,366.641968 239.108459,367.191589 238.402313,367.741241 
	C238.969971,368.165405 239.537628,368.589600 240.095642,369.344482 
	C240.362778,370.450592 240.639587,371.226044 240.874969,372.455566 
	C240.809402,375.950531 240.785233,378.991394 240.535889,382.249634 
	C240.241653,383.298737 240.172592,384.130402 239.828171,385.169647 
	C240.360489,386.253754 241.168137,387.130249 242.005585,388.374878 
	C242.779922,394.794250 248.221619,396.618378 252.069778,400.191772 
	C252.732758,400.630432 253.387436,400.761322 254.419205,400.893402 
	C255.276672,400.765930 255.757065,400.637299 256.265503,400.611115 
	C256.293579,400.713562 256.104156,400.809845 256.163208,401.067078 
	C256.481262,401.549561 256.740265,401.774780 257.087891,402.319794 
	C258.449829,402.774567 259.723053,402.909515 261.294922,403.082428 
	C261.985321,403.206329 262.377106,403.292267 262.807495,403.636780 
	C262.913483,404.237823 262.980927,404.580261 263.077576,405.268433 
	C263.822388,406.026489 264.537994,406.438782 265.612244,406.844116 
	C267.302124,406.641754 268.633423,406.446289 270.314484,406.263123 
	C271.354248,406.379333 272.044250,406.483307 272.868042,406.879150 
	C275.372864,408.479156 277.641907,410.042145 280.136841,411.040771 
	C284.872742,412.936462 286.251068,417.118286 283.273102,421.272003 
	C282.431885,422.445282 281.706604,423.701660 280.611176,424.961945 
	C279.867889,425.009155 279.441650,425.014343 278.806030,424.764832 
	C277.741272,424.375397 276.885925,424.240692 275.756714,423.799957 
	C272.624298,423.355408 269.765747,423.216919 266.560913,423.028900 
	C264.887634,422.590790 263.560638,422.202271 262.193787,421.831421 
	C262.153931,421.849121 262.177399,421.765106 262.153961,421.357086 
	C262.034607,415.791260 260.919189,410.140778 255.922073,408.619476 
	C251.410034,407.245880 246.016556,408.767609 240.659760,409.070129 
	C239.237671,410.293915 238.078766,411.391479 237.114746,412.639557 
	C234.542999,415.969177 236.622925,423.948914 240.454498,425.431427 
	C240.609055,426.125458 240.629120,426.537964 240.313599,426.951172 
	C238.932739,427.348358 237.887451,427.744873 236.842163,428.141357 
	C236.294159,427.321869 235.746155,426.502350 235.034851,425.094971 
	C233.522110,423.377686 232.172638,422.248322 230.823166,421.118958 
	C230.538971,420.985352 230.302963,420.792755 230.044067,419.970093 
	C229.285812,418.625305 228.598618,417.851471 227.911438,417.077667 
	C227.911438,417.077667 227.978836,417.024994 227.983978,416.675842 
	C226.946762,415.264282 225.904419,414.201843 224.862061,413.139435 
	C224.862061,413.139435 225.002884,413.010376 224.951233,412.710724 
	C222.914703,410.281921 220.929794,408.152771 218.944885,406.023621 
	C218.944885,406.023621 218.786850,405.702179 218.822754,405.146790 
	C216.604095,402.040253 214.349533,399.489136 212.094971,396.938019 
M228.438431,382.851624 
	C228.418427,383.514221 228.398438,384.176819 228.378448,384.839447 
	C229.167587,384.508423 229.956741,384.177399 230.745880,383.846344 
	C230.171783,383.413025 229.597687,382.979706 228.438431,382.851624 
z"
          />
          <path
            fill="#72A1D2"
            opacity="1.000000"
            stroke="none"
            d="
M383.904541,244.782196 
	C372.951996,244.396393 362.484863,244.068863 352.023193,243.615479 
	C347.007538,243.398117 345.912842,241.854858 346.714783,237.047363 
	C348.241211,227.896820 349.216858,218.625443 351.276306,209.599335 
	C354.601166,195.027390 358.814209,180.659531 362.239990,166.108429 
	C363.503937,160.739761 367.352142,159.602859 371.467499,159.541687 
	C390.779999,159.254532 410.097931,159.326553 429.413971,159.291962 
	C433.451477,159.284744 434.944366,161.510788 434.776794,165.363449 
	C434.033691,182.449432 433.274597,199.535492 432.691650,216.627335 
	C432.442047,223.946396 432.609070,231.279617 432.584534,238.606415 
	C432.563751,244.806808 431.741089,245.755264 425.289917,245.661774 
	C411.656006,245.464188 398.024597,245.090286 383.904541,244.782196 
M358.187653,200.076355 
	C356.459351,206.722046 354.731049,213.367737 352.970337,220.665634 
	C352.959686,221.100845 352.949005,221.536072 352.408691,222.497879 
	C352.264099,224.001389 352.119537,225.504913 351.949066,227.914963 
	C351.943268,229.411713 351.922974,230.908585 351.934174,232.405212 
	C351.978912,238.380585 353.249390,239.818039 359.169800,240.009247 
	C371.770996,240.416168 384.377441,240.660248 396.978333,241.074188 
	C401.602631,241.226105 406.218933,241.934906 410.836029,241.907684 
	C415.787384,241.878510 420.740540,241.335281 425.677277,240.855469 
	C426.202423,240.804428 426.622803,239.675888 427.589447,238.595016 
	C427.706726,237.717773 427.824005,236.840515 427.983124,235.048248 
	C428.016724,231.374847 428.050293,227.701447 428.633301,223.406433 
	C428.745239,218.596344 428.857178,213.786255 429.013245,208.233826 
	C429.014191,207.490295 429.015137,206.746780 429.054962,205.124725 
	C429.395782,193.352829 429.822540,181.582474 430.036682,169.808273 
	C430.126984,164.841751 428.240906,163.606827 423.516052,163.796204 
	C417.550903,164.035294 411.563629,163.690384 405.585571,163.658646 
	C403.712067,163.648697 401.837463,163.836685 399.041870,163.941071 
	C395.030518,163.950470 391.019165,163.959854 386.070221,163.951492 
	C381.625031,163.962387 377.179047,164.030090 372.734924,163.966309 
	C369.130493,163.914597 366.769653,165.369278 365.958344,169.722733 
	C365.930084,170.430740 365.901825,171.138733 365.151276,172.085388 
	C364.437225,175.057922 363.723175,178.030457 362.919373,181.688385 
	C362.618652,182.789886 362.317932,183.891388 361.950348,185.778503 
	C361.596558,187.837952 361.242767,189.897400 360.291809,192.290787 
	C359.864136,193.525955 359.436462,194.761124 358.960175,196.764694 
	C358.940033,197.790619 358.919922,198.816544 358.187653,200.076355 
z"
          />
          <path
            fill="#BCD0E6"
            opacity="1.000000"
            stroke="none"
            d="
M463.141541,158.385681 
	C475.535706,158.101807 487.866119,157.981186 500.192963,158.140213 
	C509.990997,158.266632 519.782959,158.855698 529.581055,158.996811 
	C533.989807,159.060318 535.281433,162.175247 536.076355,165.312607 
	C539.864807,180.265076 543.512878,195.255936 546.968323,210.288895 
	C548.967590,218.986542 550.427917,227.806641 552.281921,236.539551 
	C553.522278,242.381805 553.037781,243.805847 547.090881,244.805054 
	C541.941833,245.670197 536.635681,245.756027 531.391968,245.873428 
	C512.602539,246.294128 493.810852,246.632050 475.018585,246.897659 
	C466.401764,247.019440 466.390930,246.912048 464.705383,238.397552 
	C464.320953,236.455780 464.024933,234.496506 463.432373,232.252518 
	C463.146149,227.281326 463.115784,222.602890 463.078613,217.006134 
	C462.748688,208.395279 462.425629,200.702744 462.102539,193.010208 
	C462.389557,190.939575 462.676575,188.868958 462.969421,185.951660 
	C462.679321,176.748566 462.383362,168.392105 462.087402,160.035645 
	C462.416992,159.580307 462.746613,159.124969 463.141541,158.385681 
M464.943329,172.520309 
	C465.899536,194.192932 467.029175,215.860352 467.682312,237.542099 
	C467.833801,242.570312 469.251343,244.255081 474.294098,244.012009 
	C497.182098,242.908829 520.082214,242.052734 542.980103,241.163254 
	C548.899902,240.933304 549.677063,240.216965 548.566772,234.352737 
	C546.630737,224.127441 544.579712,213.922882 542.463257,203.733231 
	C541.432617,198.771454 540.216797,193.844666 538.958008,188.934555 
	C536.985352,181.240295 535.072693,173.521774 532.727234,165.939560 
	C532.266968,164.451782 530.063965,162.719284 528.479736,162.511765 
	C523.943237,161.917496 519.312561,162.015991 514.717407,161.901001 
	C501.593384,161.572586 488.469971,161.186310 475.343781,160.990219 
	C464.839081,160.833298 464.837463,160.942093 464.943329,172.520309 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M259.247864,171.368362 
	C263.511169,162.942017 271.621368,163.690720 278.504150,163.239349 
	C296.873932,162.034683 315.298737,161.643143 333.705475,161.046051 
	C338.773682,160.881638 340.295319,163.503937 338.605347,168.185837 
	C336.494720,174.033066 334.737183,180.007050 332.786560,185.912888 
	C331.151428,190.863510 329.257538,195.738174 327.840363,200.748886 
	C326.025574,207.165405 324.488953,213.665039 322.991913,220.165573 
	C321.738800,225.606812 320.971344,231.171295 319.495667,236.545776 
	C318.568481,239.922623 316.232452,242.308746 312.163727,241.999649 
	C302.263336,241.247498 292.357727,240.564133 282.456421,239.823593 
	C274.369751,239.218781 266.279480,238.646698 258.203461,237.919556 
	C251.452255,237.311722 244.694397,236.676453 237.986298,235.723648 
	C232.578384,234.955536 230.776337,229.627960 233.561264,223.606674 
	C239.740082,210.247452 245.800140,196.832169 252.112152,183.536377 
	C254.070908,179.410416 256.685028,175.595581 259.247864,171.368362 
M329.040070,185.142212 
	C329.746124,182.445877 330.452209,179.749557 331.593445,176.504807 
	C332.004547,175.989380 332.607513,175.536301 332.798279,174.949249 
	C336.272461,164.257507 336.838898,162.569855 325.214539,163.183929 
	C306.716339,164.161148 288.237396,165.598618 269.785492,167.237518 
	C267.469330,167.443237 264.535614,169.556992 263.309448,171.628189 
	C258.212524,180.237717 253.660217,189.169647 248.297104,198.315262 
	C245.854599,204.205505 243.412094,210.095764 240.916794,216.418381 
	C240.916794,216.418381 240.878769,216.852295 240.190598,217.062759 
	C238.962631,219.697922 237.731873,222.331772 236.507309,224.968521 
	C233.970139,230.431595 234.604752,232.089157 240.690140,232.919647 
	C252.461807,234.526154 264.278656,235.840744 276.103058,237.010696 
	C287.447968,238.133209 298.835114,238.825089 310.184296,239.909363 
	C313.696075,240.244873 315.697876,238.656326 316.529816,235.693817 
	C317.323181,232.868607 317.467773,229.861191 317.983887,226.303391 
	C318.003052,225.878922 318.022186,225.454437 318.602570,224.520615 
	C321.580078,214.020187 324.604523,203.532623 327.492828,193.007706 
	C328.027191,191.060577 328.011810,188.962585 328.218506,186.389267 
	C328.239624,186.025558 328.260742,185.661865 329.040070,185.142212 
z"
          />
          <path
            fill="#70A6D8"
            opacity="1.000000"
            stroke="none"
            d="
M409.002258,85.000183 
	C410.391846,85.028320 411.781433,85.056465 414.047729,85.098503 
	C420.582520,85.122162 426.240692,85.115540 431.898621,85.146027 
	C438.398590,85.181061 439.214752,85.716278 438.835388,92.383896 
	C438.063202,105.953026 436.921600,119.501633 435.877686,133.054504 
	C435.554413,137.251282 432.630768,137.883530 429.124939,137.881927 
	C412.649475,137.874374 396.173981,137.978180 379.698517,137.975433 
	C373.795502,137.974442 372.569641,136.130539 375.099304,130.583023 
	C379.550323,120.822067 383.718414,110.873528 388.999512,101.567963 
	C393.331635,93.934532 396.484619,83.899040 408.646576,85.332649 
	C408.750641,85.344917 408.883087,85.116318 409.002258,85.000183 
M382.012665,132.690216 
	C383.016571,133.155457 384.020447,133.620682 385.958466,134.079956 
	C398.668579,134.064133 411.378723,134.065048 424.088745,134.025436 
	C430.503143,134.005432 432.799042,132.429977 433.012238,127.480392 
	C433.468170,116.895920 433.812897,106.304588 434.025421,95.712502 
	C434.145691,89.718681 433.291321,89.056854 426.054596,88.979782 
	C418.966858,88.992142 411.877808,88.940369 404.792603,89.080307 
	C403.618561,89.103508 401.996613,89.629890 401.374420,90.494324 
	C398.302551,94.762138 395.184814,99.051331 392.672333,103.654495 
	C389.036377,110.315918 385.538483,117.109680 382.781647,124.159805 
	C381.936798,126.320427 378.474152,129.104706 381.997559,131.999390 
	C381.997559,131.999390 381.954590,132.046646 382.012665,132.690216 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M83.195107,394.138092 
	C83.025459,394.755646 82.855812,395.373169 82.314529,396.428925 
	C81.360817,397.938110 80.778732,399.009094 80.196655,400.080078 
	C80.196655,400.080078 80.134422,400.120819 79.817764,400.288666 
	C75.424690,406.719757 72.185982,413.050537 72.913803,421.069458 
	C73.537476,427.940826 72.889595,434.927612 72.525742,441.949219 
	C71.878410,442.156006 71.509415,442.276917 70.677826,442.272705 
	C64.797310,441.783142 59.379387,441.418701 53.961460,441.054260 
	C53.961460,441.054260 53.998749,441.001221 54.027138,440.549744 
	C54.035290,431.322083 54.108711,422.544006 53.934006,413.770905 
	C53.885685,411.344269 53.634987,408.657288 52.558739,406.561340 
	C47.909115,397.506348 42.984337,388.589539 38.005451,379.708893 
	C36.785786,377.533447 34.983608,375.684570 33.389503,373.354126 
	C33.184109,372.362152 33.038460,371.701569 32.939003,370.678284 
	C31.015581,366.508789 28.808933,362.802429 27.141565,358.867523 
	C25.398224,354.753387 26.970875,352.338409 31.290152,352.102936 
	C34.775906,351.912903 38.309715,351.808960 41.764000,352.191895 
	C43.354271,352.368164 45.403744,353.430206 46.209095,354.731628 
	C48.795925,358.911774 50.849045,363.418365 53.203014,367.747406 
	C56.016853,372.922089 58.928673,378.043518 61.879932,383.492981 
	C62.782375,384.650177 63.602936,385.502411 64.423492,386.354645 
	C64.779381,385.700195 65.135269,385.045715 65.783691,384.223755 
	C69.340073,377.977264 72.574379,371.882172 75.873726,365.822479 
	C83.552238,351.719910 81.114029,351.660004 96.250137,352.091125 
	C98.009003,352.141235 100.778709,353.028625 101.230385,354.225677 
	C101.927139,356.072235 101.165001,358.548523 100.754074,360.706757 
	C100.465721,362.221283 99.731895,363.650970 99.196999,365.118561 
	C99.196999,365.118561 99.067177,365.550568 98.723007,365.915680 
	C95.280510,371.871155 92.182182,377.461487 88.990265,383.314789 
	C88.834160,383.944153 88.771652,384.310547 88.396591,384.809357 
	C87.648643,385.923401 87.213249,386.905060 86.724335,388.134705 
	C86.715126,388.545959 86.759453,388.709167 86.803772,388.872406 
	C86.803772,388.872406 86.728020,388.687378 86.338501,388.817505 
	C85.031029,390.677795 84.113068,392.407959 83.195107,394.138092 
M71.090981,417.593628 
	C71.241249,414.951569 70.634163,411.950653 71.684128,409.734924 
	C74.805809,403.147339 78.601036,396.880432 82.092911,390.466339 
	C87.436752,380.650513 92.773033,370.830383 98.061668,360.984741 
	C101.135284,355.262695 100.909187,354.952393 94.237389,354.949799 
	C92.406120,354.949097 90.536003,355.191895 88.751915,354.902527 
	C85.353661,354.351288 83.226555,356.143341 81.915131,358.726196 
	C78.060608,366.317749 74.640099,374.132935 70.676399,381.664093 
	C69.276192,384.324524 66.798187,386.417725 64.812263,388.769928 
	C62.867546,387.118042 60.280888,385.828308 59.104412,383.742981 
	C56.156261,378.517303 53.803036,372.951447 51.289921,367.485809 
	C45.623512,355.162140 44.632225,354.440948 32.832172,354.996643 
	C31.619968,355.053680 29.493128,355.528320 29.415462,356.050751 
	C29.198074,357.513000 29.518358,359.260559 30.198093,360.606934 
	C31.501909,363.189423 33.351681,365.492432 34.708420,368.052673 
	C39.772770,377.609344 44.649414,387.265900 49.751167,396.802216 
	C53.867908,404.497314 58.381229,411.971893 57.009827,421.332977 
	C56.580048,424.266602 56.943039,427.316406 56.942867,430.313019 
	C56.942253,440.968475 56.936447,440.880981 67.668877,440.174591 
	C70.276886,440.002930 71.164543,439.050812 71.126007,436.550751 
	C71.033653,430.558929 71.093964,424.564758 71.090981,417.593628 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M151.001862,262.994537 
	C149.257019,262.306366 147.512192,261.618195 145.154480,260.659058 
	C142.509583,259.782959 140.509674,259.013214 138.438400,258.609497 
	C134.961731,257.931854 131.439667,257.487274 127.936699,256.944611 
	C119.373634,253.366730 111.054619,248.816422 102.154205,246.582733 
	C96.975235,245.283005 92.329422,244.050339 88.378067,240.189880 
	C88.641441,239.475662 88.843651,239.185699 89.373795,238.912125 
	C90.135361,238.986664 90.568993,239.044800 91.061554,239.428650 
	C91.841217,240.129074 92.561958,240.503815 93.693268,240.877563 
	C99.057777,242.410416 104.016380,243.929413 108.964737,245.481079 
	C121.016624,249.260223 133.063751,253.054550 145.422699,256.898621 
	C146.153076,256.978088 146.573578,257.001678 147.209747,257.303284 
	C153.509720,259.738281 159.549698,262.033813 165.694901,264.000549 
	C169.415909,265.191406 173.299942,265.872955 177.428406,266.821259 
	C178.172333,266.855865 178.598618,266.852539 179.230774,267.153290 
	C185.979446,269.273926 192.522217,271.090485 199.460205,272.937134 
	C201.173264,273.050354 202.491058,273.133484 203.934601,273.550903 
	C215.765060,276.806702 227.469788,279.728180 239.574570,282.707153 
	C241.321472,282.824036 242.668320,282.883453 244.261230,283.232422 
	C249.119141,284.551819 253.700439,285.756989 258.350555,286.567291 
	C264.207611,287.587860 270.120911,288.285828 276.402496,289.140991 
	C278.100037,289.232483 279.404999,289.306000 280.863281,289.696228 
	C283.686188,291.078125 286.355865,292.143250 289.038086,293.447815 
	C289.050659,293.687256 289.020905,294.165833 288.571289,294.114441 
	C285.417267,294.027344 282.712860,293.991638 279.794861,293.683258 
	C277.044495,292.929535 274.507751,292.448486 271.595337,291.804657 
	C264.146881,290.429626 257.074036,289.217438 250.001205,288.005219 
	C247.256638,287.352478 244.512070,286.699768 241.113541,285.714478 
	C237.973343,285.253784 235.487106,285.125671 233.000870,284.997559 
	C225.633224,283.313538 218.265579,281.629547 210.264969,279.679718 
	C208.089233,278.929260 206.546448,278.444641 205.003677,277.960022 
	C203.964676,277.953278 202.925690,277.946533 201.405212,277.582825 
	C198.282761,276.470306 195.641769,275.714752 193.000778,274.959229 
	C187.302124,273.613983 181.603485,272.268707 175.234818,270.649780 
	C166.710495,267.915558 158.856171,265.455048 151.001862,262.994537 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M408.916748,84.613358 
	C408.883087,85.116318 408.750641,85.344917 408.646576,85.332649 
	C396.484619,83.899040 393.331635,93.934532 388.999512,101.567963 
	C383.718414,110.873528 379.550323,120.822067 375.099304,130.583023 
	C372.569641,136.130539 373.795502,137.974442 379.698517,137.975433 
	C396.173981,137.978180 412.649475,137.874374 429.124939,137.881927 
	C432.630768,137.883530 435.554413,137.251282 435.877686,133.054504 
	C436.921600,119.501633 438.063202,105.953026 438.835388,92.383896 
	C439.214752,85.716278 438.398590,85.181061 431.898621,85.146027 
	C426.240692,85.115540 420.582520,85.122162 414.460602,85.034821 
	C415.302246,84.305962 416.587891,83.144386 417.916779,83.092560 
	C423.396942,82.878853 428.890198,83.003174 434.378662,83.000542 
	C438.909943,82.998367 441.204987,85.298943 440.974121,89.787903 
	C440.413513,100.687088 439.720306,111.579407 439.101929,122.475708 
	C439.026764,123.799973 438.538391,125.536697 439.172791,126.389297 
	C441.816132,129.941757 439.340637,132.985886 438.345459,135.979675 
	C437.332642,139.026443 435.457275,140.574341 431.266968,140.307190 
	C423.727814,139.826523 416.094086,141.048477 408.491302,141.292801 
	C397.882568,141.633743 387.254517,142.106262 376.657318,141.782562 
	C370.802429,141.603714 369.249878,134.524734 372.664734,127.854614 
	C374.915710,123.457909 376.161072,118.556488 378.228760,114.051819 
	C379.378601,111.546753 381.467590,109.490334 382.825287,107.059662 
	C386.154999,101.098495 389.319580,95.044731 392.524902,89.014565 
	C395.345825,83.707443 402.984558,81.450188 408.916748,84.613358 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M224.920746,144.916962 
	C228.026688,141.588821 230.870941,137.956467 234.301376,135.005554 
	C239.159576,130.826447 244.226334,126.802979 249.604446,123.333900 
	C259.534027,116.928970 269.643921,110.782448 279.892059,104.899948 
	C286.933563,100.858086 294.102692,96.832504 301.655701,93.958435 
	C306.907776,91.959908 312.903229,91.382965 318.577820,91.327805 
	C320.454773,91.309555 322.366821,94.901260 324.652161,96.943626 
	C325.041534,97.044823 325.110718,97.118675 325.018250,97.437996 
	C321.607178,101.155594 318.288574,104.553871 314.700317,108.035110 
	C314.007385,108.457848 313.584015,108.797623 312.802704,109.103897 
	C311.965790,109.051338 311.486816,109.032288 311.007874,109.013229 
	C310.986206,106.206978 309.896942,105.005066 307.467010,107.121635 
	C298.368774,115.046532 289.303040,123.010971 280.341034,131.089203 
	C275.360168,135.578903 270.608643,140.322983 265.310730,144.908005 
	C264.867767,144.863586 264.671906,144.662384 264.782959,144.350052 
	C279.343384,125.431992 297.508423,110.742020 316.125641,96.267654 
	C314.216553,95.303772 312.711395,94.805092 311.420593,95.137863 
	C305.348907,96.703148 299.036041,97.828758 293.375488,100.371704 
	C285.685944,103.826134 278.256866,108.034378 271.174255,112.626038 
	C259.944031,119.906601 249.049072,127.709717 238.103363,135.421066 
	C233.695953,138.526123 229.529129,141.972641 225.169708,145.177124 
	C225.086609,145.090515 224.920746,144.916962 224.920746,144.916962 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M184.994446,246.004761 
	C193.943130,247.165436 202.880707,248.422318 211.843719,249.459076 
	C220.375854,250.445969 228.936111,251.187988 237.481232,252.064743 
	C249.638519,253.312119 261.785492,254.665894 273.951294,255.822815 
	C286.135742,256.981476 298.330719,258.046936 310.534302,258.980621 
	C321.425323,259.813873 332.335297,260.398102 343.232788,261.151794 
	C343.788788,261.190277 344.305847,261.791931 344.489075,262.572662 
	C341.637939,263.007538 339.133484,263.104401 336.640533,262.976837 
	C325.719147,262.418030 314.801086,261.794220 303.456909,261.154053 
	C301.023956,261.089630 299.015961,261.065186 296.731293,260.745178 
	C293.865295,260.109955 291.285278,259.643494 288.685028,259.452698 
	C278.077576,258.674408 267.463959,257.980408 256.420380,257.208618 
	C253.656631,257.132904 251.325073,257.103912 248.738892,256.770905 
	C242.266159,255.764709 236.048096,255.062531 229.407120,254.301697 
	C226.982880,254.192535 224.981522,254.142029 222.785797,253.825821 
	C221.396133,253.658447 220.200851,253.756790 218.694992,253.851410 
	C217.970520,253.833725 217.556641,253.819733 217.005066,253.448135 
	C211.861038,252.487869 206.854675,251.885193 201.463196,251.232086 
	C200.041443,251.157227 199.004791,251.132828 197.728485,250.807526 
	C184.965271,248.750336 172.441711,246.994034 159.553925,245.175415 
	C158.450867,245.091705 157.712067,245.070282 156.747696,244.762009 
	C155.317047,244.410431 154.111954,244.345718 152.572021,244.240662 
	C151.807968,244.313217 151.378769,244.426117 150.632996,244.410126 
	C149.864288,244.234146 149.412140,244.187057 148.713593,243.832428 
	C141.949203,242.753113 135.431229,241.981323 128.548157,241.158829 
	C127.445885,241.075531 126.708679,241.042923 125.786270,240.733154 
	C123.742950,239.438538 121.884842,238.421082 120.026733,237.403625 
	C121.143250,237.034592 122.333481,236.208511 123.363655,236.374695 
	C128.118896,237.141800 132.803314,238.354294 137.561859,239.093277 
	C146.726440,240.516525 155.923904,241.727921 165.831360,243.301636 
	C172.701462,244.385666 178.847961,245.195221 184.994446,246.004761 
z"
          />
          <path
            fill="#BED4EB"
            opacity="1.000000"
            stroke="none"
            d="
M612.981995,144.978256 
	C599.171631,144.015823 585.367188,142.953003 571.548096,142.138748 
	C565.758118,141.797577 559.936401,141.905045 554.130371,141.953308 
	C549.109558,141.995041 546.339355,139.527328 544.895386,134.832520 
	C544.292542,132.872467 542.749268,131.201721 541.370239,129.186996 
	C540.825317,128.485367 540.543701,127.996170 540.142273,127.251801 
	C533.931091,114.863472 526.001038,103.999428 517.334656,93.600243 
	C515.794006,91.751518 515.309631,89.022545 514.337524,86.700043 
	C516.730591,85.828064 519.138611,84.200394 521.513489,84.247444 
	C525.935486,84.335045 530.323914,85.522507 534.752075,86.025383 
	C541.479980,86.789444 548.306946,86.941811 554.942749,88.163467 
	C559.198059,88.946877 563.185486,91.185432 567.658630,93.269928 
	C568.595276,94.920433 569.166321,96.072685 569.381714,97.194946 
	C553.649231,86.644974 536.331177,89.564995 519.891724,88.613632 
	C529.582275,104.587440 538.654541,119.869743 548.180115,134.864014 
	C549.495728,136.934860 552.939331,138.304367 555.601746,138.733643 
	C561.140015,139.626572 566.817261,139.642212 572.433289,140.071396 
	C584.657654,141.005600 596.881653,141.945602 609.100281,142.950882 
	C611.428101,143.142410 613.085815,142.770477 613.415405,140.034851 
	C613.488770,141.711700 613.235352,143.344986 612.981995,144.978256 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M471.000214,263.043457 
	C484.078094,262.774200 497.155121,262.438599 510.234222,262.261566 
	C517.616028,262.161621 525.001282,262.322784 532.693115,262.939056 
	C533.010254,263.706055 533.019348,263.900330 532.639526,264.092926 
	C531.216125,264.149170 530.181763,264.207123 528.680420,264.160065 
	C521.153198,264.392303 514.090088,264.684174 507.036682,265.129944 
	C506.243408,265.180084 505.505127,266.100769 504.292633,266.654785 
	C498.229004,266.745514 492.614075,266.799255 486.737061,266.608826 
	C484.651367,266.525635 482.827728,266.686615 480.559570,266.859406 
	C477.740356,266.887787 475.365692,266.904327 472.675720,266.657837 
	C471.710968,266.291626 471.061829,266.099640 470.412201,266.098022 
	C449.031281,266.044830 427.650299,265.998230 406.269348,266.006226 
	C405.178406,266.006622 404.087616,266.499207 402.549561,266.783325 
	C399.143768,266.770264 396.185242,266.736511 393.054016,266.350098 
	C384.858337,265.436218 376.835358,264.874969 368.361359,264.258911 
	C362.929504,264.174744 357.948700,264.145386 352.981201,263.822357 
	C352.996796,263.332672 352.999054,263.136688 353.001282,262.940704 
	C354.735596,262.632477 356.468353,262.068604 358.204529,262.057800 
	C368.824829,261.991730 379.445923,262.051086 390.944336,262.117981 
	C395.882202,262.427246 399.942505,262.687195 404.002808,262.947144 
	C410.690002,262.983429 417.377197,263.019714 424.990906,263.056793 
	C429.278351,263.054169 432.639282,263.050812 436.000244,263.047424 
	C438.701141,263.050842 441.402008,263.054291 444.553284,263.053345 
	C452.023041,263.053619 459.042419,263.058258 466.930359,263.059326 
	C468.866028,263.051636 469.933136,263.047546 471.000214,263.043457 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M488.993713,85.033989 
	C492.313385,81.647598 501.080841,83.791527 503.740662,88.695412 
	C510.327087,100.838814 516.898682,112.990379 523.418396,125.169662 
	C524.537476,127.260239 525.371277,129.503571 526.653015,131.841736 
	C526.970154,134.367569 526.971191,136.727997 526.606323,139.577652 
	C524.491760,140.704407 522.742981,141.341934 520.527527,141.981934 
	C512.955383,141.322983 505.860413,140.283249 498.742126,140.085999 
	C487.170013,139.765320 475.582672,139.995956 464.001740,139.993896 
	C462.015625,137.897263 458.684845,136.020233 458.299774,133.662155 
	C457.217285,127.033493 457.229645,120.210136 457.034882,113.457069 
	C456.857300,107.299553 456.973785,101.133553 457.036743,94.130478 
	C457.177399,93.041939 457.241974,92.794197 457.644226,92.772141 
	C458.315369,93.725960 458.898254,94.438499 458.944885,95.184547 
	C459.679871,106.945648 460.415802,118.707581 460.998871,130.476898 
	C461.299774,136.550171 462.238647,137.766296 468.404144,137.816727 
	C484.887726,137.951569 501.372406,137.993011 517.856628,137.999146 
	C523.786194,138.001343 524.928467,136.476624 522.478760,131.170486 
	C517.544006,120.481766 512.734558,109.697182 507.054840,99.403816 
	C503.274170,92.552109 499.987854,84.039314 489.360260,85.355621 
	C489.252991,85.368904 489.116547,85.146416 488.993713,85.033989 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M550.030640,82.732315 
	C549.701660,82.771515 549.232056,82.972618 549.063965,82.826607 
	C542.020203,76.708534 533.771057,80.479248 526.035767,79.867798 
	C517.671143,79.206589 509.345367,77.960602 501.041351,76.712273 
	C499.584900,76.493340 498.276581,75.063614 496.973694,74.093864 
	C493.826080,71.750984 489.967224,72.770912 488.267731,76.619370 
	C486.781830,76.703697 485.567413,76.542191 484.126770,75.719833 
	C482.317047,73.002106 480.854706,70.834480 479.109711,68.925201 
	C477.829071,67.523979 476.177887,66.461403 475.099518,65.168106 
	C477.127228,63.814137 480.889343,68.289223 481.025452,63.032295 
	C484.415741,63.015690 487.806000,62.999084 491.718323,63.274330 
	C492.497925,64.115486 492.755524,64.664795 492.814301,65.441330 
	C491.630554,66.206947 490.645660,66.745323 489.850403,67.180031 
	C500.869568,76.085648 513.292358,77.607666 526.611511,74.890251 
	C525.839233,73.843178 524.959351,73.133095 523.955811,72.821945 
	C516.313721,70.452667 508.647552,68.161308 501.242188,65.601700 
	C502.664185,65.234337 503.832855,65.110970 505.001495,64.987602 
	C510.053558,66.011307 515.105652,67.035019 520.617188,68.401794 
	C522.716980,69.496346 524.357239,70.247826 525.997559,70.999313 
	C526.734985,71.026276 527.472412,71.053246 528.688843,71.466057 
	C531.169189,72.246361 533.186584,72.574364 535.168030,73.051682 
	C537.799255,73.685516 540.402344,74.435814 543.024170,75.509598 
	C544.041809,76.920876 545.032043,77.979958 546.069153,78.990997 
	C547.369629,80.258698 548.708374,81.487000 550.030640,82.732315 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M168.388687,365.076752 
	C168.430664,379.382782 168.472641,393.688782 168.163361,408.605499 
	C167.541183,410.144531 167.270294,411.072876 166.583435,412.000610 
	C164.781876,411.999603 163.396271,411.999176 162.004166,411.589233 
	C160.659912,406.796326 159.322174,402.412964 157.990082,397.614471 
	C158.330078,394.799591 158.664429,392.399780 158.999054,389.600006 
	C158.999344,388.133331 158.999359,387.066681 158.999390,386.000000 
	C161.993729,384.099976 163.842392,381.637299 163.000061,376.997131 
	C162.667023,373.760284 162.334045,371.379547 162.001053,368.998779 
	C162.334091,366.609741 162.667130,364.220703 163.381165,361.427307 
	C165.304352,362.374237 166.846527,363.725494 168.388687,365.076752 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M195.200531,410.907166 
	C197.700500,413.768890 200.200470,416.630615 202.589844,420.085022 
	C200.112946,424.646973 200.742844,425.594849 206.154694,426.297913 
	C208.197311,428.744843 210.097778,430.888672 211.777390,433.310852 
	C210.704773,435.725311 209.852997,437.861420 209.001221,439.997559 
	C203.978271,440.331604 198.955307,440.665649 193.466187,440.538818 
	C193.155411,436.616699 193.321518,433.155914 193.462387,429.694092 
	C193.553070,427.465637 193.700073,425.233826 193.656677,423.006775 
	C193.576447,418.889374 190.726074,414.440735 195.200531,410.907166 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M417.042114,305.513306 
	C413.331299,306.005951 409.620514,306.498566 405.454681,307.005310 
	C402.825409,307.466522 400.651123,307.913635 398.217865,307.883850 
	C397.302551,307.253479 396.646240,307.100006 395.534668,306.942902 
	C391.754700,306.931213 388.429993,306.923157 384.983276,306.532471 
	C379.232117,305.754761 373.602997,305.359650 367.553619,304.959351 
	C362.089294,304.616364 357.045227,304.278564 352.001160,303.940796 
	C350.622162,303.925354 349.243134,303.909851 347.263916,303.579407 
	C345.105652,302.851776 343.547577,302.439117 341.989532,302.026459 
	C341.246124,302.002075 340.502716,301.977692 339.209259,301.709930 
	C338.101349,301.325195 337.543518,301.183868 336.985687,301.042542 
	C335.922516,301.022675 334.859375,301.002808 332.931366,300.973389 
	C325.252838,300.852234 318.269440,301.909515 311.556641,298.043518 
	C305.435272,297.339142 299.757294,296.641205 294.055603,295.602478 
	C294.000458,294.579865 293.969055,293.898102 294.346710,293.098694 
	C296.172638,292.963867 297.589478,292.946655 299.251099,293.214691 
	C303.338867,294.087341 307.181915,294.674744 311.415894,295.255829 
	C313.105408,295.295776 314.404022,295.342010 315.889709,295.707214 
	C318.093811,296.264191 320.110840,296.502228 322.443237,296.773743 
	C323.181061,296.808075 323.603485,296.808960 324.244568,297.132446 
	C327.371368,297.841125 330.279541,298.227203 333.613831,298.683228 
	C336.355164,298.764160 338.670288,298.775177 341.396118,298.898224 
	C345.538330,299.335266 349.269806,299.660278 353.267914,300.284912 
	C356.019928,300.724823 358.505341,300.865173 361.294373,301.256317 
	C371.103882,301.844635 380.609711,302.182129 390.464172,302.624512 
	C391.537994,302.799530 392.263153,302.869629 393.174377,303.216553 
	C394.559906,303.403503 395.759369,303.313629 397.344788,303.198181 
	C398.758179,303.223145 399.785675,303.273682 400.963837,303.673645 
	C403.775970,304.304901 406.437408,304.586761 409.541443,304.894287 
	C412.336761,305.117737 414.689423,305.315521 417.042114,305.513306 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M464.057007,140.386261 
	C475.582672,139.995956 487.170013,139.765320 498.742126,140.085999 
	C505.860413,140.283249 512.955383,141.322983 520.529419,142.359940 
	C520.998657,143.490295 520.999329,144.245148 521.000000,145.000000 
	C515.832520,145.999985 510.697510,147.418060 505.483765,147.823532 
	C502.877930,148.026184 500.128632,146.378067 497.443268,145.570190 
	C496.819672,145.382599 496.201263,145.095596 495.565155,145.044205 
	C486.891815,144.343262 478.205658,143.777893 469.548462,142.918900 
	C467.683990,142.733902 465.921661,141.519073 464.057007,140.386261 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M119.687241,237.374527 
	C121.884842,238.421082 123.742950,239.438538 125.556351,240.989273 
	C118.742386,241.353256 112.148399,237.676514 105.081337,240.316193 
	C106.248245,241.843353 107.512833,242.863846 108.956985,243.405975 
	C114.625549,245.533936 120.297585,247.680649 126.071770,249.490341 
	C130.632889,250.919876 135.345444,251.866211 140.350449,253.075974 
	C142.109268,253.435562 143.508392,253.746307 144.926788,254.406708 
	C145.001633,255.451843 145.057205,256.147308 145.112793,256.842773 
	C133.063751,253.054550 121.016624,249.260223 108.964737,245.481079 
	C104.016380,243.929413 99.057777,242.410416 93.562958,240.585785 
	C92.348930,239.897644 91.675781,239.500305 91.002632,239.102951 
	C90.568993,239.044800 90.135361,238.986664 89.163940,238.646851 
	C85.295151,236.534729 81.964142,234.704285 78.132195,232.598557 
	C79.398285,232.353378 80.312790,231.919907 81.131027,232.057770 
	C88.745918,233.340851 96.323395,234.858780 103.955170,236.024139 
	C108.592903,236.732300 113.307068,236.939941 117.986900,237.372406 
	C118.440514,237.363419 118.894127,237.354416 119.687241,237.374527 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M240.761063,382.032227 
	C240.785233,378.991394 240.809402,375.950531 241.183655,372.288269 
	C242.261948,369.727173 242.990143,367.787506 243.830734,365.552002 
	C243.961044,364.837067 243.978943,364.417999 243.996857,363.998901 
	C243.996857,363.998901 244.053177,364.190277 244.476166,364.136292 
	C245.615585,363.071136 246.332031,362.059937 247.048462,361.048706 
	C247.048462,361.048706 247.000000,361.000000 247.361115,361.001953 
	C248.883575,359.742096 250.044952,358.480286 251.206314,357.218475 
	C252.002182,356.614380 252.798035,356.010284 254.091187,355.191833 
	C255.501770,354.477020 256.415070,353.976532 257.328369,353.476044 
	C257.328369,353.476044 257.776947,353.400574 258.310913,353.344635 
	C259.599670,352.697998 260.354462,352.107300 261.109253,351.516632 
	C262.259216,351.272949 263.409180,351.029236 265.202972,350.628357 
	C266.564453,350.497009 267.282104,350.522858 268.349792,350.719177 
	C271.188477,350.790100 273.677063,350.690552 276.441803,350.637512 
	C277.088043,350.657562 277.458130,350.631134 278.233215,350.747498 
	C283.917053,352.922485 289.570648,354.325867 294.359802,357.179840 
	C298.411072,359.594086 301.461426,363.687958 304.954773,367.038483 
	C304.954773,367.038483 305.000000,367.002441 304.956024,367.322571 
	C305.244995,368.097046 305.577972,368.551361 305.948853,369.369080 
	C306.079376,370.455811 306.171997,371.179169 306.154907,372.318420 
	C305.832458,375.184204 305.619690,377.634033 305.065643,379.837677 
	C304.472076,373.110962 302.420776,367.557800 297.713196,362.751068 
	C293.533661,358.483521 288.794800,356.140717 283.176086,354.176483 
	C273.571625,350.818909 264.544250,353.051666 256.380768,356.746948 
	C245.615234,361.620087 241.407394,374.896637 245.443436,385.894226 
	C246.343857,388.347717 247.857407,390.576172 248.953156,393.041504 
	C248.817642,393.174774 248.545853,393.440491 248.251556,393.281982 
	C245.558517,389.426361 243.159790,385.729309 240.761063,382.032227 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M352.043121,304.326355 
	C357.045227,304.278564 362.089294,304.616364 367.865601,305.200195 
	C370.036316,305.963196 371.457855,306.859253 372.916534,306.924561 
	C376.971191,307.106171 381.041290,306.942444 385.105286,306.915131 
	C388.429993,306.923157 391.754700,306.931213 395.642181,307.174591 
	C396.470764,307.614166 396.736603,307.818390 397.002441,308.022583 
	C395.258942,308.975037 393.581238,310.587677 391.760834,310.769196 
	C382.291351,311.713470 372.795563,312.545410 363.289307,312.925873 
	C354.575867,313.274567 354.564850,312.999481 352.043121,304.326355 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M165.044067,226.938278 
	C164.838684,226.675705 164.633301,226.413132 164.233078,225.695343 
	C164.060303,225.012833 164.082367,224.785507 164.558487,224.631729 
	C172.032578,225.484360 179.048004,226.308304 186.073990,227.029343 
	C192.477173,227.686478 198.912277,228.071457 205.294144,228.883987 
	C209.465469,229.415054 212.335083,228.347473 213.992065,224.264420 
	C215.538467,220.453796 217.383575,216.764359 219.415512,212.966080 
	C220.138000,212.227600 220.542191,211.544632 221.170410,210.604980 
	C222.615829,207.236481 223.837204,204.124634 225.376617,200.960388 
	C226.103943,200.242584 226.513275,199.577225 226.926285,198.907166 
	C226.929962,198.902451 226.892303,198.884262 227.220245,198.914307 
	C227.985703,198.949173 228.423187,198.954010 228.860687,198.958832 
	C227.772690,201.546097 226.725098,204.151260 225.588608,206.717056 
	C222.859863,212.877594 220.391907,219.179581 217.249420,225.123489 
	C214.196716,230.897568 212.857086,233.574097 203.983459,231.923859 
	C195.399826,230.327545 186.596725,229.911255 177.183029,228.685211 
	C172.665375,227.908585 168.854721,227.423431 165.044067,226.938278 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M182.045288,188.020142 
	C186.554794,184.534714 191.064316,181.049286 196.115967,177.256149 
	C197.149994,176.326447 197.641861,175.704422 198.133743,175.082397 
	C198.852798,175.073502 199.571869,175.064606 201.029053,175.048538 
	C203.574387,174.071609 205.381561,173.101837 207.188751,172.132050 
	C207.581482,172.143234 207.974228,172.154434 208.830017,172.362305 
	C209.870834,172.403809 210.448593,172.248611 211.026352,172.093414 
	C212.081879,172.055435 213.137405,172.017441 215.007339,171.983505 
	C216.806030,171.868362 217.790573,171.751373 218.774536,171.629562 
	C224.932388,170.867279 231.090118,170.103912 237.088654,169.664978 
	C228.750931,171.670197 220.596848,173.483459 212.388199,175.001678 
	C199.924026,177.307007 189.068863,181.708130 181.948273,193.313614 
	C177.789612,200.091568 172.106934,205.934448 167.101181,211.862671 
	C167.066711,210.353302 167.040970,209.179260 167.015228,208.005219 
	C167.620865,206.917984 168.226501,205.830734 169.311646,204.386368 
	C171.200241,202.356628 172.609314,200.684021 174.018402,199.011414 
	C175.046310,197.633621 176.074203,196.255814 177.451584,194.453949 
	C179.215805,192.026642 180.630554,190.023392 182.045288,188.020142 
z"
          />
          <path
            fill="#5DA4DD"
            opacity="1.000000"
            stroke="none"
            d="
M474.691833,65.247894 
	C476.177887,66.461403 477.829071,67.523979 479.109711,68.925201 
	C480.854706,70.834480 482.317047,73.002106 483.920959,75.410706 
	C483.256927,75.781761 482.572449,75.801086 481.400208,75.448036 
	C475.411530,74.731621 469.875610,74.651337 464.425171,73.927261 
	C461.871948,73.588066 459.483765,72.006470 457.038208,70.657135 
	C457.035278,69.885292 457.014923,69.444252 457.249298,68.784317 
	C457.350677,67.059143 457.197296,65.552872 457.023315,64.033684 
	C457.002686,64.020775 457.049438,64.026031 457.398743,63.990868 
	C458.853088,63.537640 459.958160,63.119576 461.466309,62.779732 
	C463.237152,62.874481 464.604889,62.891006 466.293579,63.091721 
	C468.746735,63.842117 470.878937,64.408325 473.283569,65.064560 
	C473.934601,65.185684 474.313202,65.216789 474.691833,65.247894 
z"
          />
          <path
            fill="#72A1D2"
            opacity="1.000000"
            stroke="none"
            d="
M393.036011,76.163849 
	C390.672791,76.198845 388.309540,76.233841 385.134186,76.256630 
	C383.227509,76.298782 382.132996,76.353149 380.591919,76.300476 
	C376.691956,76.193420 373.238556,76.193420 369.785156,76.193420 
	C369.747284,75.797813 369.709381,75.402214 369.671509,75.006607 
	C371.228424,73.746910 372.785339,72.487213 374.677582,71.235596 
	C375.677124,71.164330 376.341309,71.085007 377.417145,71.019180 
	C384.200623,69.307358 390.572479,67.582039 397.285645,65.869247 
	C398.734406,65.702774 399.841827,65.523781 401.254089,65.547913 
	C403.027130,66.072502 404.495392,66.393944 406.682526,66.872787 
	C403.945190,69.271339 401.909729,71.054871 399.912994,72.845963 
	C399.951721,72.853516 399.879761,72.860199 399.516296,72.853310 
	C397.113861,73.952232 395.074951,75.058037 393.036011,76.163849 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M264.965729,144.964157 
	C264.083893,146.299667 263.286774,148.368591 262.093750,148.629929 
	C255.828018,150.002411 249.431183,150.763977 243.139267,152.032379 
	C235.952148,153.481262 228.855515,155.384491 221.659393,156.780045 
	C219.360779,157.225815 216.870377,156.682556 214.467728,156.591843 
	C215.326447,154.510849 215.738464,152.031616 217.152420,150.445633 
	C219.094971,148.266754 221.796356,146.764374 224.546661,144.944550 
	C224.920746,144.916962 225.086609,145.090515 225.053787,145.486847 
	C223.344452,147.847122 221.667938,149.811066 219.712143,152.102173 
	C221.111145,152.816452 222.056381,153.749802 222.776733,153.600204 
	C236.757507,150.696609 250.712006,147.666519 264.671906,144.662384 
	C264.671906,144.662384 264.867767,144.863586 264.965729,144.964157 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M33.449245,373.685547 
	C34.983608,375.684570 36.785786,377.533447 38.005451,379.708893 
	C42.984337,388.589539 47.909115,397.506348 52.558739,406.561340 
	C53.634987,408.657288 53.885685,411.344269 53.934006,413.770905 
	C54.108711,422.544006 54.035290,431.322083 54.000038,440.567566 
	C53.322495,440.572784 52.700451,440.108643 52.044731,438.924866 
	C51.984509,437.131439 51.957958,436.057648 51.931404,434.983887 
	C51.952522,427.305298 51.973637,419.626740 51.991104,411.124695 
	C51.327183,409.530243 50.666916,408.759247 50.006657,407.988251 
	C47.583977,404.414917 45.161289,400.841583 42.528557,396.812256 
	C41.562481,394.533569 40.806450,392.710968 40.050419,390.888336 
	C38.546585,386.759644 37.042755,382.630951 35.170654,377.947693 
	C34.227520,376.892395 33.652653,376.391663 33.077782,375.890900 
	C33.077782,375.890900 33.057087,375.444611 33.108303,374.884003 
	C33.256096,374.110748 33.352669,373.898132 33.449245,373.685547 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M61.798050,383.188019 
	C58.928673,378.043518 56.016853,372.922089 53.203014,367.747406 
	C50.849045,363.418365 48.795925,358.911774 46.209095,354.731628 
	C45.403744,353.430206 43.354271,352.368164 41.764000,352.191895 
	C38.309715,351.808960 34.775906,351.912903 31.290152,352.102936 
	C26.970875,352.338409 25.398224,354.753387 27.141565,358.867523 
	C28.808933,362.802429 31.015581,366.508789 32.609619,370.644287 
	C31.210169,370.570404 30.186304,370.167847 29.162439,369.765289 
	C28.140112,367.082184 27.117785,364.399109 26.010231,361.040558 
	C25.313942,359.861023 24.702879,359.356934 24.091816,358.852844 
	C24.091816,358.852844 24.074627,358.422546 24.050743,357.810303 
	C25.358616,354.481262 26.690372,351.764465 28.022125,349.047699 
	C32.158508,349.053833 36.295097,349.087952 40.431225,349.058075 
	C44.495296,349.028748 47.131004,350.913666 48.998730,354.536560 
	C52.566441,361.456879 56.419682,368.230011 60.508915,375.502625 
	C60.905376,376.629547 60.947330,377.318085 61.016464,378.361267 
	C61.449795,380.059326 61.855949,381.402679 62.146507,382.856995 
	C62.030907,382.967896 61.798054,383.188019 61.798050,383.188019 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M99.532562,365.069092 
	C99.731895,363.650970 100.465721,362.221283 100.754074,360.706757 
	C101.165001,358.548523 101.927139,356.072235 101.230385,354.225677 
	C100.778709,353.028625 98.009003,352.141235 96.250137,352.091125 
	C81.114029,351.660004 83.552238,351.719910 75.873726,365.822479 
	C72.574379,371.882172 69.340073,377.977264 65.653412,384.120575 
	C65.230598,384.184875 64.977493,383.969391 65.025154,383.622131 
	C66.048126,381.183228 67.023445,379.091614 68.014557,376.635773 
	C68.110794,375.549072 68.191231,374.826630 68.271667,374.104156 
	C69.858643,371.082733 71.280876,367.959778 73.067299,365.061279 
	C76.641678,359.261841 80.415459,353.585297 83.305275,349.101746 
	C87.964752,349.468475 91.535530,349.749512 95.976044,350.028687 
	C103.822342,350.174377 104.765732,351.097168 104.996765,357.999664 
	C103.287224,360.339661 101.577675,362.679657 99.532562,365.069092 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M117.823227,237.024780 
	C113.307068,236.939941 108.592903,236.732300 103.955170,236.024139 
	C96.323395,234.858780 88.745918,233.340851 81.131027,232.057770 
	C80.312790,231.919907 79.398285,232.353378 78.132195,232.598557 
	C81.964142,234.704285 85.295151,236.534729 88.836006,238.630463 
	C88.843651,239.185699 88.641441,239.475662 88.203934,239.893768 
	C84.230698,239.039474 80.492752,238.057037 76.310883,236.697891 
	C74.903343,235.897095 73.939728,235.473007 72.974579,234.716537 
	C72.955399,233.943115 72.937737,233.502075 72.977127,232.737244 
	C74.023064,231.292786 75.011948,230.172104 76.333748,229.041336 
	C77.110603,229.020630 77.554543,229.010010 77.998474,228.999390 
	C81.395325,229.657013 84.792168,230.314621 88.924530,230.995026 
	C90.105164,231.015060 90.550278,231.012299 90.995399,231.009521 
	C91.437859,231.022369 91.880318,231.035202 92.806305,231.268768 
	C93.868309,231.364304 94.446785,231.239105 95.025261,231.113907 
	C95.438866,231.109314 95.852463,231.104721 96.679573,231.456879 
	C99.730301,232.537476 102.367523,233.261353 105.004745,233.985229 
	C109.323196,234.411560 114.081764,232.768906 117.823227,237.024780 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M211.852478,360.096436 
	C208.323334,365.634186 204.479034,371.190430 200.458206,376.615814 
	C198.795959,378.858704 196.833572,380.899353 194.854767,382.879120 
	C193.813538,383.920807 192.442764,384.633118 191.220810,385.494171 
	C190.799759,383.977203 190.039902,382.465820 190.014999,380.942352 
	C189.892532,373.452759 190.093689,365.956512 189.890076,358.470459 
	C189.829681,356.250488 188.824066,354.056244 188.105682,351.507385 
	C188.148544,350.860199 188.337280,350.555817 189.093430,350.128418 
	C190.100204,350.016663 190.539581,350.027893 190.978943,350.039093 
	C191.944824,360.048553 192.910706,370.057983 193.985016,381.190826 
	C196.776886,377.200378 199.005081,373.515930 201.733765,370.249573 
	C204.738007,366.653381 208.202301,363.441467 211.852478,360.096436 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M294.079315,295.943298 
	C299.757294,296.641205 305.435272,297.339142 311.556641,298.454285 
	C312.000000,300.247284 312.000000,301.623016 312.000000,302.998779 
	C309.153168,303.541199 306.311462,304.112457 303.458099,304.617950 
	C300.525391,305.137512 297.643158,305.978485 296.039337,302.016968 
	C294.580780,298.414124 291.875061,299.979034 289.418457,301.037659 
	C287.043396,302.061218 284.494812,303.593689 283.401245,299.395966 
	C287.102234,299.102600 288.978241,297.629303 289.020905,294.165833 
	C289.020905,294.165833 289.050659,293.687256 289.365845,293.451935 
	C290.103088,293.323822 290.525116,293.431061 290.978516,293.862488 
	C292.033020,294.772247 293.056183,295.357788 294.079315,295.943298 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M305.406921,380.083862 
	C305.619690,377.634033 305.832458,375.184204 306.478882,372.433746 
	C307.899017,373.817505 308.885559,375.501923 309.872101,377.186310 
	C309.872101,377.186310 309.843536,377.597443 309.857605,378.133118 
	C309.873108,379.110321 309.874573,379.551849 309.876038,379.993408 
	C309.876038,379.993408 309.810913,380.375977 309.504517,380.676941 
	C308.402435,382.278198 307.606812,383.578461 306.811157,384.878723 
	C306.811157,384.878723 306.401367,384.913605 305.965210,385.046509 
	C305.334534,385.442352 305.140045,385.705292 304.945557,385.968231 
	C304.945557,385.968231 304.987335,385.936249 304.501770,385.931305 
	C300.377716,385.939514 296.733490,386.088409 293.102020,385.935089 
	C287.145782,385.683563 285.706818,384.218445 285.006927,378.422150 
	C284.604095,375.085907 282.939453,373.287476 279.631134,371.630951 
	C274.329620,368.976410 269.664032,371.810333 264.854919,371.325195 
	C273.071075,365.529388 283.990082,367.338135 286.012390,375.021332 
	C288.870300,385.879364 291.462738,383.881500 299.116241,383.752991 
	C301.168427,383.718567 303.193359,382.062073 305.293640,380.886749 
	C305.373322,380.443481 305.390137,380.263672 305.406921,380.083862 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M236.906555,428.482788 
	C237.887451,427.744873 238.932739,427.348358 240.543213,427.217224 
	C242.726898,429.373383 244.345383,431.264130 245.973679,433.495117 
	C246.004456,434.515778 246.025391,435.196198 245.775085,436.104004 
	C244.985199,437.880493 244.466553,439.429565 243.706970,441.145630 
	C243.282562,441.857635 243.099121,442.402649 242.640366,443.078857 
	C241.555939,443.790070 240.746826,444.370117 239.562256,444.999084 
	C237.457870,445.362762 235.728928,445.677490 234.000000,445.992218 
	C232.611084,445.987396 231.222168,445.982574 229.416626,445.985413 
	C226.605667,445.656311 224.211319,445.319550 221.409576,444.985321 
	C219.696732,443.546265 218.391327,442.104645 217.113525,439.985687 
	C217.231247,439.090485 217.321365,438.872620 217.411484,438.654724 
	C223.016113,444.425873 230.221619,442.971191 237.023392,442.754730 
	C241.683640,442.606476 243.416473,438.545502 240.875305,434.555084 
	C239.634293,432.606293 238.276123,430.732178 236.906555,428.482788 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M461.708740,193.012085 
	C462.425629,200.702744 462.748688,208.395279 463.064270,216.539734 
	C461.595673,218.984604 460.134521,220.977554 458.390503,223.356354 
	C458.407654,217.911362 455.237640,212.750870 458.192688,206.916611 
	C459.038727,205.246185 458.262665,202.483597 457.639404,200.398468 
	C456.503357,196.597961 456.730011,193.812408 461.708740,193.012085 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M443.991974,62.007393 
	C444.289978,63.442932 445.024567,64.951035 444.800385,66.299782 
	C444.230316,69.729309 444.291992,74.731590 442.164978,76.103867 
	C438.633148,78.382500 433.625397,80.538086 429.420715,76.180603 
	C432.147919,75.462952 434.583771,75.281143 437.215759,75.323029 
	C438.284973,74.711334 439.157990,73.875946 440.340393,72.841934 
	C443.031464,67.345436 441.489624,63.364071 436.529572,63.054977 
	C434.445953,63.095383 432.780243,63.164101 430.735046,63.131035 
	C429.570709,63.030479 428.785828,63.031712 428.011597,62.770870 
	C428.030304,62.334122 428.038361,62.159447 428.046387,61.984772 
	C432.069244,60.988659 436.092102,59.992542 440.690063,59.275410 
	C442.174133,60.372059 443.083069,61.189720 443.991974,62.007393 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M296.993896,143.967010 
	C292.304291,143.959915 287.614685,143.952820 282.497070,143.528229 
	C282.789368,141.279572 283.097534,139.100067 284.300385,137.676620 
	C289.950714,130.989990 295.806976,124.474640 301.710663,118.008530 
	C302.377808,117.277817 303.806885,117.242775 304.967682,116.969124 
	C305.052704,117.053085 305.222351,117.221420 305.121460,117.538300 
	C298.230011,125.418053 291.439514,132.980927 284.649017,140.543808 
	C284.992310,141.104614 285.335602,141.665421 285.678925,142.226227 
	C287.785248,141.796692 289.891571,141.367142 292.367493,140.959671 
	C293.473541,140.915909 294.209961,140.850082 295.392487,140.866882 
	C298.252747,141.028961 300.666901,141.108414 302.978516,141.534760 
	C300.915253,142.576782 298.954590,143.271896 296.993896,143.967010 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M87.480621,434.991577 
	C87.221252,442.496429 87.583046,442.888306 95.327438,442.897400 
	C97.804604,442.900330 100.287689,442.960938 102.757584,442.816162 
	C106.142784,442.617737 108.019958,440.768951 108.907761,437.428314 
	C109.513390,435.149384 110.865562,433.068909 112.354439,431.156311 
	C112.881973,431.941956 112.939041,432.470978 112.996101,433.000000 
	C111.835396,439.319550 109.416901,445.125214 102.265831,445.816589 
	C96.811050,446.343994 90.699730,448.799927 85.859962,443.295532 
	C83.044815,440.093719 83.234016,437.512329 87.480621,434.991577 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M377.005554,71.005676 
	C376.341309,71.085007 375.677124,71.164330 374.267944,71.165985 
	C369.382935,72.848618 365.242920,74.608925 361.102905,76.369232 
	C361.249481,77.054344 361.396057,77.739456 361.542633,78.424568 
	C364.715698,78.647171 367.888763,78.869766 371.061829,79.092361 
	C368.909790,79.631264 366.738495,80.673660 364.610229,80.592125 
	C361.343933,80.467010 358.107239,79.568817 354.547607,78.715118 
	C353.823273,77.282478 353.409363,76.133408 352.998535,74.655289 
	C353.007111,73.887505 353.012665,73.448776 353.018188,73.010048 
	C355.417786,73.000183 357.817413,72.990326 360.969727,72.942665 
	C365.148712,71.272842 368.574951,69.640816 372.001221,68.008789 
	C373.498688,68.152832 374.996185,68.296875 376.745972,69.049133 
	C377.000671,70.106796 377.003113,70.556236 377.005554,71.005676 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M444.267700,61.750839 
	C443.083069,61.189720 442.174133,60.372059 441.133575,59.274033 
	C439.331879,52.653290 440.565552,50.950817 447.279419,51.065441 
	C450.028290,51.112370 452.821198,51.682346 455.477417,52.429264 
	C456.448944,52.702446 457.082703,54.176739 457.870544,55.103119 
	C456.907013,55.733932 456.019196,56.602341 454.961975,56.939217 
	C453.419586,57.430664 451.757324,57.737770 450.142761,57.752583 
	C448.739594,57.765450 447.331757,57.263588 445.670685,56.939373 
	C445.233521,58.705967 444.888489,60.100128 444.267700,61.750839 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M404.020874,262.539429 
	C399.942505,262.687195 395.882202,262.427246 391.413239,262.111298 
	C390.573761,257.896912 393.909698,257.531525 396.512054,257.180603 
	C401.558502,256.500153 406.675659,256.184387 411.769684,256.102875 
	C413.552765,256.074310 415.353943,257.178833 417.146881,257.765991 
	C417.008179,258.510803 416.869507,259.255615 416.730835,260.000427 
	C413.901947,260.000427 411.045746,259.767670 408.255371,260.095490 
	C406.790375,260.267578 405.440674,261.421265 404.020874,262.539429 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M375.909393,96.926865 
	C375.596283,97.409042 375.283173,97.891220 374.649780,98.818802 
	C373.534088,100.487312 372.738708,101.710419 371.943298,102.933525 
	C371.943298,102.933525 371.991760,102.960220 371.672852,103.105927 
	C370.232819,104.830582 369.111633,106.409515 367.990479,107.988449 
	C366.485962,110.481842 364.981445,112.975227 362.924683,115.862228 
	C361.556274,117.824097 360.740173,119.392357 359.924011,120.960609 
	C358.586731,123.349190 357.249420,125.737785 355.552338,128.526215 
	C354.524994,129.013519 353.857452,129.101013 353.119019,129.118835 
	C353.048126,129.049194 352.905609,128.910675 352.938660,128.536865 
	C355.086426,124.167114 357.044434,120.077820 359.354645,116.198265 
	C362.425598,111.041176 365.771057,106.047577 369.051392,100.795044 
	C369.104156,100.606331 369.138245,100.215935 369.458557,100.105576 
	C372.190643,97.672073 374.602417,95.348938 376.995483,93.367622 
	C376.620972,94.781914 376.265198,95.854393 375.909393,96.926865 
z"
          />
          <path
            fill="#90AFDB"
            opacity="1.000000"
            stroke="none"
            d="
M437.019623,75.099327 
	C434.583771,75.281143 432.147919,75.462952 429.354553,75.822815 
	C426.282623,75.765915 423.568207,75.530960 420.110229,75.256622 
	C418.615112,75.240135 417.863464,75.263023 416.713898,75.212326 
	C415.130157,74.057961 413.944336,72.977173 412.954590,71.615051 
	C415.431732,69.494102 417.712830,67.654480 419.993927,65.814857 
	C421.136780,64.986214 422.279663,64.157562 423.930969,63.469337 
	C425.626587,63.417488 426.813782,63.225216 428.000946,63.032944 
	C428.785828,63.031712 429.570709,63.030479 430.647522,63.460964 
	C426.610901,66.152550 422.282318,68.412415 417.953735,70.672287 
	C418.093048,71.230957 418.232330,71.789619 418.371613,72.348282 
	C424.601257,72.594414 430.830872,72.840546 437.055084,73.420898 
	C437.039642,74.203186 437.029633,74.651260 437.019623,75.099327 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M142.115128,363.855225 
	C140.459427,361.078369 139.113281,358.089935 137.735443,355.116180 
	C137.460556,354.522858 137.169876,353.873505 136.708084,353.440002 
	C132.620270,349.602692 127.446060,350.656616 122.971558,351.543182 
	C120.689384,351.995361 118.787750,355.605591 117.231216,358.109772 
	C115.869354,360.300751 115.230934,362.941376 114.051849,365.677673 
	C114.159950,363.485352 114.463005,360.994812 114.836983,358.514984 
	C114.928223,357.910034 115.519798,357.294403 115.428627,356.761078 
	C114.216965,349.672150 119.781502,349.617065 123.964874,349.209778 
	C128.196213,348.797760 132.563736,349.639221 136.853607,350.099518 
	C137.680450,350.188232 138.913269,350.901642 139.125320,351.585358 
	C140.359222,355.563782 141.344345,359.619354 142.115128,363.855225 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M195.178986,410.609436 
	C190.726074,414.440735 193.576447,418.889374 193.656677,423.006775 
	C193.700073,425.233826 193.553070,427.465637 193.462387,429.694092 
	C193.321518,433.155914 193.155411,436.616699 192.999695,440.538361 
	C192.284760,441.006378 191.570114,441.013947 190.500122,441.034485 
	C190.119232,431.031708 190.093704,421.016022 190.465576,410.475220 
	C192.015289,409.555328 193.167618,409.160522 194.319946,408.765717 
	C194.599121,409.281036 194.878281,409.796356 195.178986,410.609436 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M304.882660,116.885162 
	C303.806885,117.242775 302.377808,117.277817 301.710663,118.008530 
	C295.806976,124.474640 289.950714,130.989990 284.300385,137.676620 
	C283.097534,139.100067 282.789368,141.279572 282.070526,143.465729 
	C281.248413,144.038589 280.424774,144.256439 279.601166,144.474304 
	C279.666016,143.578430 279.425323,142.491287 279.846497,141.818436 
	C282.178589,138.092850 284.304077,134.143448 287.187500,130.877426 
	C292.917267,124.387421 299.059967,118.258461 305.135651,112.082230 
	C305.823547,111.382935 307.175995,111.337357 308.472687,111.293793 
	C307.444305,113.361031 306.163483,115.123100 304.882660,116.885162 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M461.723877,160.058228 
	C462.383362,168.392105 462.679321,176.748566 462.941986,185.554199 
	C459.441254,186.407318 457.868835,184.559784 458.071747,181.385666 
	C458.270050,178.282990 459.037109,175.220566 459.421082,172.123932 
	C459.572998,170.899048 459.825684,169.333878 459.245026,168.429031 
	C455.866028,163.163818 455.786865,163.214630 461.723877,160.058228 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M72.804062,441.863373 
	C72.889595,434.927612 73.537476,427.940826 72.913803,421.069458 
	C72.185982,413.050537 75.424690,406.719757 79.811691,400.325348 
	C80.043381,401.793396 79.964470,403.392639 79.570068,405.465820 
	C78.535332,406.636505 77.816093,407.333252 77.096855,408.029999 
	C76.718285,410.086060 76.339706,412.142120 75.688934,414.901611 
	C75.292267,420.628052 75.080948,425.651886 75.129303,430.673248 
	C75.137314,431.504639 76.280319,432.325073 76.898804,433.150604 
	C76.893456,434.453644 76.888115,435.756653 76.565056,437.544983 
	C75.099579,439.307983 73.951820,440.585663 72.804062,441.863373 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M352.559509,262.956421 
	C352.999054,263.136688 352.996796,263.332672 352.673584,264.091248 
	C344.914368,264.787445 337.476074,264.921082 329.661316,264.893677 
	C326.524231,264.524506 323.763641,264.316376 320.719849,263.800446 
	C316.295654,263.323486 312.142212,263.289490 308.020447,262.910889 
	C306.600342,262.780457 305.259094,261.791016 303.881836,261.194000 
	C314.801086,261.794220 325.719147,262.418030 336.640533,262.976837 
	C339.133484,263.104401 341.637939,263.007538 344.608032,262.943420 
	C347.425201,262.905945 349.771454,262.939026 352.559509,262.956421 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M228.921204,198.598541 
	C228.423187,198.954010 227.985703,198.949173 227.242554,198.541168 
	C230.988403,190.901260 234.778214,183.497971 239.224564,176.512558 
	C240.923203,173.843964 241.153900,171.858841 240.117523,169.243256 
	C241.963638,169.435455 243.434799,169.734055 244.905975,170.032669 
	C244.909256,171.047348 244.912537,172.062012 244.571014,173.549805 
	C243.770081,174.992050 243.313904,175.961166 242.857742,176.930298 
	C242.252151,177.152359 241.348251,177.192657 241.085587,177.623688 
	C237.016541,184.301270 233.029587,191.028946 229.033112,197.750656 
	C228.957230,197.878250 228.996277,198.074188 228.921204,198.598541 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M310.243591,380.031433 
	C309.874573,379.551849 309.873108,379.110321 309.881409,378.337769 
	C313.993835,377.670593 318.096497,377.334412 322.599548,376.999115 
	C322.999969,378.150665 323.250427,379.373505 322.944489,380.436066 
	C322.411285,382.287781 321.318451,385.633209 320.839172,385.568024 
	C318.347565,385.228851 315.887390,384.184448 313.598358,383.025879 
	C312.408630,382.423706 311.594788,381.078857 310.243591,380.031433 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M304.993988,366.639343 
	C301.461426,363.687958 298.411072,359.594086 294.359802,357.179840 
	C289.570648,354.325867 283.917053,352.922485 278.259888,350.558105 
	C277.881622,350.225922 277.970795,349.852020 277.970795,349.852020 
	C287.808624,348.139160 299.312683,353.837006 304.419891,362.366119 
	C305.043030,363.406769 304.848694,364.936920 304.993988,366.639343 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M376.266815,96.918106 
	C376.265198,95.854393 376.620972,94.781914 376.993469,93.355446 
	C377.010132,93.001450 377.025604,93.013474 377.384155,92.980400 
	C380.219513,91.191269 382.169464,87.141045 379.242188,86.752373 
	C373.641602,86.008751 367.689026,87.916306 361.498932,88.786469 
	C360.081573,88.866951 359.047791,88.884613 357.888367,88.678131 
	C357.488586,88.247276 357.214447,88.040581 356.940308,87.833885 
	C360.152618,86.587410 363.274323,84.765640 366.600464,84.241852 
	C370.647034,83.604599 374.888916,83.781624 379.003448,84.123100 
	C383.000275,84.454819 384.573181,89.134209 381.834991,92.484138 
	C380.416504,94.219505 378.380981,95.450485 376.266815,96.918106 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M420.010315,65.630127 
	C417.712830,67.654480 415.431732,69.494102 412.605377,71.641426 
	C411.063751,73.279930 410.067383,74.610725 409.071014,75.941521 
	C405.602051,76.254181 402.133057,76.566841 398.342346,76.573807 
	C398.640289,75.132149 399.260040,73.996178 399.879761,72.860199 
	C399.879761,72.860199 399.951721,72.853516 400.298676,72.901154 
	C403.798584,71.721565 407.225739,70.920563 410.014252,69.126816 
	C412.093781,67.789169 413.347748,65.168137 414.969360,63.118637 
	C416.114258,63.387367 417.259186,63.656094 418.825378,64.198349 
	C419.451172,64.744865 419.711334,64.945724 420.027222,65.074463 
	C420.027222,65.074463 420.026672,65.445389 420.010315,65.630127 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M312.357239,303.155731 
	C312.000000,301.623016 312.000000,300.247284 312.000000,298.460754 
	C318.269440,301.909515 325.252838,300.852234 332.532654,300.980682 
	C331.756805,302.328735 330.492493,304.816193 329.276733,304.792694 
	C323.748505,304.685883 318.233948,303.872162 312.357239,303.155731 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M414.563416,63.076721 
	C413.347748,65.168137 412.093781,67.789169 410.014252,69.126816 
	C407.225739,70.920563 403.798584,71.721565 400.259949,72.893600 
	C401.909729,71.054871 403.945190,69.271339 406.682526,66.872787 
	C404.495392,66.393944 403.027130,66.072502 401.280457,65.172913 
	C401.025452,64.099541 401.048859,63.604313 401.072266,63.109085 
	C405.433990,63.084328 409.795715,63.059570 414.563416,63.076721 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M288.571289,294.114441 
	C288.978241,297.629303 287.102234,299.102600 283.386963,299.000244 
	C281.609650,299.395874 280.212219,299.767273 278.830261,300.189362 
	C273.595276,301.788361 273.157135,301.467712 273.218140,295.317993 
	C273.706055,294.579376 273.894318,294.319611 274.364746,294.041168 
	C276.489136,294.040192 278.248810,293.998047 280.008453,293.955933 
	C282.712860,293.991638 285.417267,294.027344 288.571289,294.114441 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M274.000000,294.000000 
	C273.894318,294.319611 273.706055,294.579376 273.218231,294.890289 
	C268.447418,294.947815 263.705872,295.656952 259.399872,294.596954 
	C256.025574,293.766327 253.190475,290.745392 250.058517,288.351410 
	C257.074036,289.217438 264.146881,290.429626 271.862976,291.933319 
	C273.004181,292.816528 273.502106,293.408264 274.000000,294.000000 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M329.518311,73.497864 
	C334.490448,70.917870 339.358368,70.585487 344.641113,71.327469 
	C341.730103,77.417320 332.399048,72.867538 331.368530,80.655693 
	C327.403412,79.406914 327.209991,78.740555 329.518311,73.497864 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M568.626160,80.994606 
	C565.527771,85.829338 562.105957,86.597046 557.304199,83.261200 
	C557.480896,82.858414 557.957764,82.766052 558.334045,82.820618 
	C559.437317,82.206543 560.164307,81.537903 560.891296,80.869263 
	C559.918335,80.289574 558.945312,79.709892 557.578125,79.094589 
	C554.790161,78.023514 552.396484,76.988052 550.002808,75.952591 
	C555.464783,74.711685 560.177429,76.759842 564.789917,79.295036 
	C565.914612,79.913231 567.096619,80.427086 568.626160,80.994606 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M371.384094,79.070374 
	C367.888763,78.869766 364.715698,78.647171 361.542633,78.424568 
	C361.396057,77.739456 361.249481,77.054344 361.102905,76.369232 
	C365.242920,74.608925 369.382935,72.848618 373.932617,71.157921 
	C372.785339,72.487213 371.228424,73.746910 369.671509,75.006607 
	C369.709381,75.402214 369.747284,75.797813 369.785156,76.193420 
	C373.238556,76.193420 376.691956,76.193420 380.461914,76.626755 
	C378.185516,77.713623 375.592590,78.367165 372.999664,79.020706 
	C372.568573,79.029930 372.137482,79.039162 371.384094,79.070374 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M245.167267,169.837402 
	C243.434799,169.734055 241.963638,169.435455 239.807922,169.203247 
	C238.498199,169.293381 237.873047,169.317139 237.247879,169.340912 
	C231.090118,170.103912 224.932388,170.867279 218.774536,171.629562 
	C217.790573,171.751373 216.806030,171.868362 215.402344,171.954147 
	C216.174164,170.971161 217.270355,169.372711 218.572769,169.181931 
	C224.954559,168.247086 231.384155,167.606827 237.812439,167.038788 
	C239.997589,166.845688 242.246704,167.042572 244.422729,167.361008 
	C244.859924,167.424988 245.098450,168.846527 245.167267,169.837402 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M356.553558,87.874649 
	C357.214447,88.040581 357.488586,88.247276 357.600281,88.975449 
	C351.576813,90.323120 345.621216,90.757652 339.894196,92.139435 
	C336.996307,92.838623 334.511536,95.249847 331.628754,96.904854 
	C331.417542,96.922684 331.001373,97.003265 330.670288,96.983551 
	C329.897583,96.982010 329.455933,97.000183 328.958527,96.954880 
	C328.902740,96.891396 328.795135,96.761055 328.874481,96.455215 
	C329.645416,95.109177 330.336975,94.068985 331.028564,93.028786 
	C331.341736,92.917023 331.598785,92.728699 332.259521,92.240952 
	C333.530243,91.393929 334.341217,90.769745 335.152161,90.145569 
	C335.152161,90.145569 335.595947,90.127823 336.235413,90.090515 
	C340.248779,89.359726 343.622650,88.666245 346.996521,87.972763 
	C347.736877,87.965202 348.477264,87.957642 349.973755,87.966629 
	C351.503845,87.949547 352.277802,87.915916 353.051727,87.882278 
	C354.090088,87.893333 355.128448,87.904381 356.553558,87.874649 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M351.935516,134.969757 
	C351.935516,134.969757 351.877716,135.395966 351.520844,135.703125 
	C347.776886,137.681458 344.389832,139.352661 341.002747,141.023849 
	C339.623962,141.029175 338.245148,141.034485 335.997864,141.030243 
	C333.004700,140.916946 330.880066,140.813202 328.929199,140.370499 
	C329.655548,139.724640 330.192322,139.184448 330.763092,139.145798 
	C339.296997,138.567932 348.314514,139.089233 352.445862,129.049988 
	C352.484406,128.956345 352.747406,128.955063 352.905609,128.910675 
	C352.905609,128.910675 353.048126,129.049194 353.056396,129.479996 
	C352.688293,131.597122 352.311920,133.283432 351.935516,134.969757 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M371.988831,67.621147 
	C368.574951,69.640816 365.148712,71.272842 361.363007,72.934227 
	C359.853241,69.682381 359.196716,66.462639 363.400726,64.862396 
	C366.745178,63.589344 369.881409,63.688747 371.988831,67.621147 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M72.525734,441.949219 
	C73.951820,440.585663 75.099579,439.307983 76.570358,437.935913 
	C76.135994,444.222015 73.791206,446.149292 67.359840,446.125519 
	C64.913498,446.116486 62.398735,447.147980 60.039268,446.817932 
	C58.259697,446.569000 56.677685,444.907715 55.406918,443.511383 
	C60.917542,442.897736 66.028984,442.647766 71.140427,442.397827 
	C71.509415,442.276917 71.878410,442.156006 72.525734,441.949219 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M352.995453,74.984344 
	C353.409363,76.133408 353.823273,77.282478 354.121643,78.714249 
	C353.225616,79.322578 352.445160,79.648209 350.986969,79.996590 
	C348.871674,80.687744 347.434113,81.356155 345.996521,82.024567 
	C345.570038,82.039413 345.143585,82.054253 343.965637,82.049820 
	C341.140747,81.353180 339.067322,80.675819 336.993896,79.998466 
	C336.993896,79.998466 337.000000,79.999695 337.395630,79.989960 
	C340.857056,78.651466 343.922821,77.322708 346.988586,75.993958 
	C346.988586,75.993958 346.997894,75.498726 347.427673,75.249512 
	C349.570099,74.994980 351.282776,74.989662 352.995453,74.984344 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M480.533630,83.008911 
	C482.952057,83.423264 484.901703,83.842262 486.461243,84.703049 
	C480.947906,85.150444 475.802124,84.861160 470.708160,85.250092 
	C466.212402,85.593338 461.774414,86.693283 457.217285,87.090057 
	C457.071716,86.472542 457.019714,86.224091 456.967743,85.975647 
	C458.786377,84.986511 460.558655,83.262756 462.432220,83.144547 
	C468.289185,82.775002 474.184387,83.011772 480.533630,83.008911 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M105.345154,357.943390 
	C104.765732,351.097168 103.822342,350.174377 96.425781,350.002869 
	C97.650139,348.662323 99.861977,345.913544 100.832291,346.298004 
	C103.727470,347.445190 106.580002,349.475616 108.478630,351.916107 
	C109.093193,352.706085 106.707069,355.830536 105.345154,357.943390 
z"
          />
          <path
            fill="#BCD0E6"
            opacity="1.000000"
            stroke="none"
            d="
M557.957764,82.766052 
	C557.957764,82.766052 557.480896,82.858414 557.242554,82.904144 
	C554.922363,82.918655 552.840515,82.887444 550.394653,82.794266 
	C548.708374,81.487000 547.369629,80.258698 546.069153,78.990997 
	C545.032043,77.979958 544.041809,76.920876 543.354553,75.469269 
	C544.121155,75.028084 544.564026,75.000732 545.006897,74.973381 
	C546.429749,75.278412 547.852600,75.583443 549.639160,75.920532 
	C552.396484,76.988052 554.790161,78.023514 557.583862,79.458313 
	C557.975159,80.827126 557.966492,81.796593 557.957764,82.766052 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M151.069641,263.352051 
	C158.856171,265.455048 166.710495,267.915558 174.809418,270.636658 
	C173.376480,271.184967 171.476166,272.127808 170.060150,271.646301 
	C164.143921,269.634644 158.367203,267.210052 152.558853,264.888062 
	C152.014908,264.670624 151.607956,264.110535 151.069641,263.352051 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M297.287415,144.266571 
	C298.954590,143.271896 300.915253,142.576782 303.397705,141.487732 
	C305.614899,141.101929 307.310272,141.110062 309.300171,141.383362 
	C312.070312,141.778839 314.545929,141.909149 317.021545,142.039459 
	C316.063843,143.019180 315.127502,144.817383 314.144897,144.843033 
	C308.629761,144.986969 303.103424,144.703629 297.287415,144.266571 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M72.976105,235.048920 
	C73.939728,235.473007 74.903343,235.897095 75.930496,236.662231 
	C73.727036,238.666245 71.591003,240.586700 69.117950,241.844559 
	C68.314171,242.253372 66.629410,240.930130 65.347595,240.399124 
	C65.986954,238.964233 66.626312,237.529358 68.018021,236.046997 
	C70.172295,235.682648 71.574203,235.365784 72.976105,235.048920 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M377.417175,71.019180 
	C377.003113,70.556236 377.000671,70.106796 377.002197,69.319916 
	C382.060791,67.655762 387.115448,66.329033 392.940552,64.993027 
	C394.788757,65.274742 395.866547,65.565735 396.944336,65.856728 
	C390.572479,67.582039 384.200623,69.307358 377.417175,71.019180 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M457.020782,70.987946 
	C459.483765,72.006470 461.871948,73.588066 464.425171,73.927261 
	C469.875610,74.651337 475.411530,74.731621 481.056885,75.450699 
	C478.128113,75.914658 475.054840,76.003578 471.062561,76.067963 
	C467.199921,76.026665 464.246490,76.155075 461.315216,75.956398 
	C457.648834,75.707909 456.730499,74.511711 457.020782,70.987946 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M165.019409,443.063660 
	C167.778473,442.988098 170.537521,442.912537 173.725266,443.077454 
	C174.439148,443.225494 174.724350,443.133087 175.009567,443.040649 
	C175.443985,443.033722 175.878403,443.026825 176.643829,443.410522 
	C176.991989,444.503387 177.009125,445.205627 177.026260,445.907867 
	C171.187500,446.115173 165.348740,446.322479 159.514160,445.972443 
	C161.352036,444.631256 163.185715,443.847473 165.019409,443.063660 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M457.038208,70.657135 
	C456.730499,74.511711 457.648834,75.707909 461.315216,75.956398 
	C464.246490,76.155075 467.199921,76.026665 470.610657,76.084915 
	C468.574280,78.362823 460.339874,78.905777 456.917725,76.764015 
	C453.391907,74.557381 453.639771,71.060524 454.939758,67.424393 
	C455.972565,67.869865 456.483551,68.436539 456.994537,69.003212 
	C457.014923,69.444252 457.035278,69.885292 457.038208,70.657135 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M142.886490,427.048004 
	C143.008926,427.691650 143.131363,428.335327 143.194336,429.693481 
	C143.810104,431.240265 144.485352,432.072540 145.160599,432.904816 
	C145.456192,433.711121 145.751785,434.517395 145.786514,435.850464 
	C145.679138,437.918121 145.832626,439.459076 145.986115,441.000000 
	C144.378738,439.680084 141.945816,438.659851 141.335327,436.978058 
	C140.053406,433.446564 139.729126,429.567444 139.330536,425.475586 
	C140.728424,425.762756 141.807465,426.405365 142.886490,427.048004 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M158.610260,385.952148 
	C158.999359,387.066681 158.999344,388.133331 158.662231,389.654053 
	C157.214737,391.072845 156.104324,392.037659 154.637161,393.002075 
	C153.806183,393.045776 153.331955,393.089874 152.857742,393.133942 
	C152.857742,393.133942 152.923401,392.989990 152.916397,392.755859 
	C152.400528,391.994690 151.891663,391.467651 151.382812,390.940613 
	C151.237686,390.213593 151.092575,389.486572 151.224884,388.185486 
	C151.663422,386.741608 151.824539,385.871826 151.985657,385.002045 
	C154.064148,385.302795 156.142654,385.603546 158.610260,385.952148 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M128.204727,257.157562 
	C131.439667,257.487274 134.961731,257.931854 138.438400,258.609497 
	C140.509674,259.013214 142.509583,259.782959 144.794861,260.647827 
	C142.653244,261.271698 140.258331,261.635864 137.344788,261.617615 
	C134.217651,260.823456 131.609131,260.411743 129.000610,260.000000 
	C128.824661,259.123505 128.648697,258.247009 128.204727,257.157562 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M235.844467,342.003937 
	C237.229813,342.218933 238.471115,342.136047 239.083359,342.716583 
	C239.889084,343.480560 240.200165,344.766205 240.723938,345.827576 
	C239.721924,346.199799 238.625504,347.039307 237.735046,346.859406 
	C234.681320,346.242401 231.699799,345.268005 228.413971,343.292480 
	C230.749481,342.862061 233.085007,342.431641 235.844467,342.003937 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M545.002625,74.559952 
	C544.564026,75.000732 544.121155,75.028084 543.347900,75.095757 
	C540.402344,74.435814 537.799255,73.685516 535.168030,73.051682 
	C533.186584,72.574364 531.169189,72.246361 529.057739,71.470612 
	C534.018494,71.208824 539.092041,71.278542 544.153564,71.562614 
	C544.482483,71.581078 544.718933,73.246971 545.002625,74.559952 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M338.794861,352.290283 
	C339.276886,352.069763 339.549683,352.133423 340.215149,352.589630 
	C341.040436,353.657318 341.472961,354.332458 341.785095,355.383484 
	C341.353912,358.752075 341.043182,361.744751 340.732452,364.737457 
	C340.306274,364.971283 339.880096,365.205139 339.005981,365.830627 
	C338.288086,366.421387 338.100769,366.680298 337.996063,366.998993 
	C337.996063,366.998993 337.499817,366.997345 337.251740,366.995544 
	C337.530975,362.187317 338.058319,357.380890 338.794861,352.290283 
M338.581146,361.516907 
	C338.581146,361.516907 338.476562,361.578522 338.581146,361.516907 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M76.000824,229.051422 
	C75.011948,230.172104 74.023064,231.292786 72.575211,232.719727 
	C69.190025,232.100708 64.557480,233.570114 64.982056,227.942429 
	C68.373039,227.671753 71.764030,227.401077 75.569611,227.413727 
	C75.989746,228.148499 75.995285,228.599960 76.000824,229.051422 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M336.630798,80.042282 
	C339.067322,80.675819 341.140747,81.353180 343.645752,82.059090 
	C341.744446,83.818672 339.411560,85.549706 337.039368,87.640350 
	C337.000000,87.999969 337.005493,87.995926 337.005493,87.995926 
	C331.730865,83.758865 331.628113,82.657364 336.630798,80.042282 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M191.151825,349.702789 
	C190.539581,350.027893 190.100204,350.016663 189.331131,349.995514 
	C187.613754,348.122742 186.226074,346.259888 184.838409,344.397034 
	C185.026779,343.932037 185.215134,343.467041 185.403503,343.002075 
	C187.806686,343.301910 190.209854,343.601746 192.613037,343.901611 
	C192.183594,345.723236 191.754150,347.544861 191.151825,349.702789 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M217.367737,438.376404 
	C217.321365,438.872620 217.231247,439.090485 217.067154,439.654175 
	C214.625092,439.999542 212.257004,439.999115 209.445068,439.998108 
	C209.852997,437.861420 210.704773,435.725311 212.068237,433.275146 
	C212.769531,432.914093 212.959106,432.867096 213.148682,432.820129 
	C213.148682,432.820129 213.120178,432.841003 213.193497,433.114746 
	C213.554703,433.576782 213.842575,433.765106 214.130463,433.953400 
	C214.608521,434.504150 215.086594,435.054901 215.876007,436.013336 
	C216.464935,436.617645 216.742538,436.814209 217.020157,437.010773 
	C217.121445,437.373199 217.222717,437.735657 217.367737,438.376404 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M151.839783,384.702271 
	C151.824539,385.871826 151.663422,386.741608 151.232239,387.812866 
	C148.934586,383.268616 146.907013,378.522919 144.927475,373.390778 
	C146.867935,375.513428 148.878113,377.945435 150.595337,380.569153 
	C151.290665,381.631531 151.346863,383.112213 151.839783,384.702271 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M165.999390,353.000000 
	C167.295883,346.822601 174.374985,344.572906 180.481750,348.642578 
	C176.078949,349.584534 170.969223,347.131561 168.485229,353.018097 
	C167.422287,353.006622 166.710846,353.003296 165.999390,353.000000 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M465.972626,62.907532 
	C464.604889,62.891006 463.237152,62.874481 461.388580,62.491962 
	C459.621643,62.759323 458.335541,63.392681 457.049438,64.026031 
	C457.049438,64.026031 457.002686,64.020775 456.714539,64.035507 
	C455.934601,64.375961 455.442841,64.701675 454.951080,65.027390 
	C454.434784,64.552345 453.392517,63.917652 453.480988,63.626163 
	C454.845062,59.131927 462.205811,57.677589 465.936096,61.741005 
	C465.980194,62.553089 465.976410,62.730312 465.972626,62.907532 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M184.908844,245.621155 
	C178.847961,245.195221 172.701462,244.385666 166.272949,243.300232 
	C167.047577,242.683517 168.181442,241.904526 169.148010,242.074860 
	C174.395889,242.999741 179.602463,244.158981 184.908844,245.621155 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M166.737488,208.198944 
	C167.040970,209.179260 167.066711,210.353302 166.970856,212.133713 
	C166.566864,213.493210 166.284470,214.246338 165.664459,215.047760 
	C164.582993,217.074402 163.839172,219.052750 163.097305,221.363434 
	C163.090195,222.138931 163.081131,222.582092 162.768967,223.036621 
	C161.980637,223.361801 161.495422,223.675613 161.010208,223.989426 
	C160.459869,223.814758 159.909546,223.640091 159.430023,223.095276 
	C159.737320,222.532318 159.896713,222.288956 159.979019,221.995041 
	C162.139252,217.460907 164.299484,212.926788 166.737488,208.198944 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M89.083862,383.051849 
	C92.182182,377.461487 95.280510,371.871155 98.690338,366.138855 
	C97.601578,369.436829 96.246056,372.895966 94.783798,376.309357 
	C93.942513,378.273163 92.928406,380.162964 91.585297,382.496918 
	C90.480286,382.955811 89.782066,383.003815 89.083862,383.051849 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M277.903503,349.508972 
	C277.970795,349.852020 277.881622,350.225922 277.854919,350.415283 
	C277.458130,350.631134 277.088043,350.657562 276.267944,350.317627 
	C273.211914,350.150360 270.605835,350.349518 267.999756,350.548706 
	C267.282104,350.522858 266.564453,350.497009 265.491394,350.425018 
	C265.334351,346.192047 268.631073,344.500977 273.360901,346.376617 
	C274.968597,347.014130 276.350922,348.219849 277.903503,349.508972 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M64.768547,227.710159 
	C64.557480,233.570114 69.190025,232.100708 72.518143,233.043503 
	C72.937737,233.502075 72.955399,233.943115 72.974586,234.716522 
	C71.574203,235.365784 70.172295,235.682648 68.385941,235.998840 
	C66.668190,234.925537 65.363457,233.814819 63.995350,232.788574 
	C61.469181,230.893631 61.648766,229.045486 64.768547,227.710159 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M168.836731,353.026276 
	C170.969223,347.131561 176.078949,349.584534 180.552597,349.002747 
	C182.614166,349.382050 184.230774,349.765015 185.934357,350.499268 
	C182.916840,350.924591 179.759750,350.677643 176.724533,351.174805 
	C174.313934,351.569672 172.052460,352.875031 169.336685,353.676727 
	C168.911316,353.393616 168.874023,353.209961 168.836731,353.026276 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M76.936050,432.669128 
	C76.280319,432.325073 75.137314,431.504639 75.129303,430.673248 
	C75.080948,425.651886 75.292267,420.628052 75.697861,415.299469 
	C76.310425,420.725128 76.641869,426.456390 76.936050,432.669128 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M168.574463,364.731873 
	C166.846527,363.725494 165.304352,362.374237 163.381088,361.011475 
	C163.240128,359.879974 163.480255,358.759949 164.254028,357.319946 
	C166.090271,356.998230 167.392883,356.996429 168.695496,356.994659 
	C168.717072,359.458771 168.738647,361.922882 168.574463,364.731873 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M504.948181,64.608795 
	C503.832855,65.110970 502.664185,65.234337 500.889771,65.621460 
	C499.177032,65.628479 498.070068,65.371742 496.934937,64.780411 
	C495.938232,63.633511 494.969666,62.821201 494.001099,62.008888 
	C494.080505,61.689400 494.159912,61.369915 494.239319,61.050438 
	C497.791199,62.110291 501.343018,63.170139 504.948181,64.608795 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M110.418640,374.001801 
	C108.697899,379.455872 106.654785,385.009216 104.349556,390.787506 
	C103.839615,390.167328 103.144272,389.126221 103.414932,388.508087 
	C105.537689,383.660065 107.845505,378.893036 110.418640,374.001801 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M47.352993,342.344360 
	C49.518303,344.507660 49.203213,345.977783 46.422741,345.904022 
	C43.827629,345.835175 41.249802,345.114838 38.664364,344.681305 
	C38.610085,344.212219 38.555805,343.743134 38.501526,343.274048 
	C41.329460,342.907104 44.157398,342.540161 47.352993,342.344360 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M521.437378,145.000000 
	C520.999329,144.245148 520.998657,143.490295 520.996094,142.357452 
	C522.742981,141.341934 524.491760,140.704407 526.599731,140.019806 
	C527.685791,141.285187 528.412598,142.597641 529.743042,145.000000 
	C526.320618,145.000000 524.097656,145.000000 521.437378,145.000000 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M70.677834,442.272675 
	C66.028984,442.647766 60.917542,442.897736 55.387566,443.128723 
	C54.629520,442.626984 54.290005,442.144196 53.955975,441.357849 
	C59.379387,441.418701 64.797310,441.783142 70.677834,442.272675 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M328.755432,140.709442 
	C330.880066,140.813202 333.004700,140.916946 335.585480,141.060181 
	C334.716492,141.729645 333.449921,142.601028 332.054108,142.939499 
	C329.116333,143.651840 326.124207,144.209030 323.122314,144.531662 
	C322.490143,144.599609 321.720215,143.385818 321.352051,142.361832 
	C322.449829,141.558319 323.209381,141.155609 324.372192,140.773804 
	C326.102142,140.766281 327.428772,140.737869 328.755432,140.709442 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M341.244019,141.291412 
	C344.389832,139.352661 347.776886,137.681458 351.496155,135.916748 
	C351.039032,142.033890 348.697327,143.332504 341.244019,141.291412 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M435.935303,262.685760 
	C432.639282,263.050812 429.278351,263.054169 425.459473,263.050079 
	C426.034943,259.200409 428.088318,257.854614 431.772888,260.172333 
	C433.074768,260.991241 434.500916,261.612549 435.935303,262.685760 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M165.045135,227.355225 
	C168.854721,227.423431 172.665375,227.908585 176.744293,228.670807 
	C172.893661,229.880402 168.650757,232.074768 165.045135,227.355225 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M67.854736,374.065521 
	C68.191231,374.826630 68.110794,375.549072 67.655586,376.793518 
	C65.183640,377.545868 63.086464,377.776245 60.989288,378.006622 
	C60.947330,377.318085 60.905376,376.629547 60.848312,375.601929 
	C62.258827,370.704742 65.240295,374.460388 67.854736,374.065521 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M323.968903,140.752914 
	C323.209381,141.155609 322.449829,141.558319 321.340179,141.989349 
	C319.931396,142.014374 318.872742,142.011078 317.417816,142.023621 
	C314.545929,141.909149 312.070312,141.778839 309.301910,141.106461 
	C309.010803,140.380219 309.012451,140.196075 309.409912,140.028442 
	C310.860931,140.005386 311.916138,139.965820 313.423035,139.971680 
	C317.239441,140.262375 320.604187,140.507645 323.968903,140.752914 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M164.559937,442.997498 
	C163.185715,443.847473 161.352036,444.631256 159.260986,445.680298 
	C157.296448,445.935791 155.589294,445.926025 153.453430,445.554138 
	C153.001892,444.498108 152.979065,443.804199 152.956238,443.110291 
	C156.670975,443.050629 160.385727,442.990967 164.559937,442.997498 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M475.099518,65.168106 
	C474.313202,65.216789 473.934601,65.185684 473.243469,64.738037 
	C471.952545,63.546844 470.974152,62.772190 469.995758,61.997543 
	C471.645233,61.992989 473.308533,61.853333 474.940430,62.020412 
	C476.708618,62.201439 478.447510,62.668369 480.612305,63.021454 
	C480.889343,68.289223 477.127228,63.814137 475.099518,65.168106 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M373.283386,79.300568 
	C375.592590,78.367165 378.185516,77.713623 380.908447,76.733810 
	C382.132996,76.353149 383.227509,76.298782 384.714478,76.387253 
	C382.112396,80.770515 377.833038,80.150108 373.283386,79.300568 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M97.449554,408.993195 
	C96.189888,412.973053 94.609863,417.048462 92.698357,421.264282 
	C93.954315,417.299377 95.541756,413.194092 97.449554,408.993195 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M27.829388,348.792236 
	C26.690372,351.764465 25.358616,354.481262 24.018436,357.597412 
	C22.893618,356.928802 21.777224,355.860870 20.303293,354.450897 
	C22.938242,352.325867 25.287445,350.431305 27.829388,348.792236 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M397.285645,65.869247 
	C395.866547,65.565735 394.788757,65.274742 393.352966,64.973083 
	C394.268555,60.316578 397.652344,62.175537 400.807556,62.811459 
	C401.048859,63.604313 401.025452,64.099541 400.975647,64.969772 
	C399.841827,65.523781 398.734406,65.702774 397.285645,65.869247 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M233.041840,285.350220 
	C235.487106,285.125671 237.973343,285.253784 240.737411,285.670654 
	C237.679749,289.931519 236.493927,289.893188 233.041840,285.350220 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M129.015625,260.400024 
	C131.609131,260.411743 134.217651,260.823456 136.913651,261.617615 
	C134.107056,263.175964 131.162308,264.688538 129.015625,260.400024 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M152.582001,443.051025 
	C152.979065,443.804199 153.001892,444.498108 153.027481,445.537140 
	C152.308289,446.877563 151.586319,447.872864 150.864349,448.868164 
	C150.266632,447.126404 149.668915,445.384674 149.143768,443.198975 
	C150.213486,442.833954 151.210632,442.912872 152.582001,443.051025 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M177.465561,445.921326 
	C177.009125,445.205627 176.991989,444.503387 176.967514,443.450195 
	C179.356140,443.042511 181.752090,442.985809 184.583130,443.354614 
	C184.996796,444.485596 184.975388,445.191040 184.953979,445.896454 
	C182.604279,445.909241 180.254562,445.922028 177.465561,445.921326 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M409.303772,75.955650 
	C410.067383,74.610725 411.063751,73.279930 412.409302,71.922760 
	C413.944336,72.977173 415.130157,74.057961 416.689392,75.497284 
	C417.043823,76.243065 417.024811,76.630295 417.005798,77.017525 
	C415.615936,77.011261 414.226105,77.005005 412.249115,76.752731 
	C411.108704,76.336433 410.555420,76.166161 410.002136,75.995880 
	C410.002136,75.995880 409.536499,75.969780 409.303772,75.955650 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M309.916809,376.743103 
	C308.885559,375.501923 307.899017,373.817505 306.588562,372.017822 
	C306.171997,371.179169 306.079376,370.455811 306.193359,369.279968 
	C306.604523,368.551422 306.809082,368.275421 307.013641,367.999420 
	C311.291199,369.596039 309.633087,373.300720 309.916809,376.743103 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M113.413612,433.000000 
	C112.939041,432.470978 112.881973,431.941956 112.683945,431.035553 
	C112.555122,429.990295 112.567268,429.322449 112.986053,428.315552 
	C113.916359,427.951477 114.440018,427.926392 114.963669,427.901306 
	C116.247070,429.024353 117.530457,430.147369 119.758972,432.097412 
	C117.235733,432.481598 115.533432,432.740814 113.413612,433.000000 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M526.056396,70.657341 
	C524.357239,70.247826 522.716980,69.496346 520.992249,68.410599 
	C523.343201,67.640297 525.778503,67.204269 528.213867,66.768234 
	C528.512451,67.306122 528.811035,67.844009 529.109619,68.381905 
	C528.111511,69.026390 527.113403,69.670883 526.056396,70.657341 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M304.967682,116.969131 
	C306.163483,115.123100 307.444305,113.361031 308.855652,111.294205 
	C309.572327,110.503075 310.158447,110.016693 310.876221,109.271774 
	C311.486816,109.032288 311.965790,109.051338 312.747925,109.438675 
	C310.441498,112.278442 307.831940,114.749931 305.222351,117.221420 
	C305.222351,117.221420 305.052704,117.053085 304.967682,116.969131 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M346.723206,87.695984 
	C343.622650,88.666245 340.248779,89.359726 336.455566,90.061218 
	C336.285217,89.554268 336.534180,89.039307 336.894318,88.260132 
	C337.005493,87.995926 337.000000,87.999969 336.997894,88.002136 
	C340.147156,87.809265 343.298523,87.614235 346.723206,87.695984 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M352.998535,74.655289 
	C351.282776,74.989662 349.570099,74.994980 347.429321,75.001862 
	C346.859711,69.762993 349.197021,70.253746 352.694946,72.845306 
	C353.012665,73.448776 353.007111,73.887505 352.998535,74.655289 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M193.051056,275.303711 
	C195.641769,275.714752 198.282761,276.470306 201.015594,277.585388 
	C198.050537,278.532562 194.952591,279.263275 193.051056,275.303711 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M161.684662,369.170074 
	C162.334045,371.379547 162.667023,373.760284 163.000000,376.570526 
	C162.555695,377.000000 162.111374,377.000000 161.307526,376.681213 
	C160.631714,375.575409 160.315430,374.788361 159.999130,374.001312 
	C160.455505,372.447998 160.911896,370.894684 161.684662,369.170074 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M346.581818,75.996857 
	C343.922821,77.322708 340.857056,78.651466 337.394867,79.987305 
	C340.057312,78.662834 343.116180,77.331299 346.581818,75.996857 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M168.794632,356.644043 
	C167.392883,356.996429 166.090271,356.998230 164.394135,357.000000 
	C164.463943,355.773254 164.927261,354.546478 165.694977,353.159851 
	C166.710846,353.003296 167.422287,353.006622 168.485229,353.018097 
	C168.874023,353.209961 168.911316,353.393616 169.015320,353.851257 
	C169.019287,354.847992 168.956528,355.570740 168.794632,356.644043 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M185.161469,446.172485 
	C184.975388,445.191040 184.996796,444.485596 185.026199,443.427094 
	C186.043915,442.972595 187.053619,442.871185 188.439636,442.731110 
	C190.794510,446.814575 188.437393,446.957947 185.161469,446.172485 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M330.645020,93.017639 
	C330.336975,94.068985 329.645416,95.109177 328.511108,96.520172 
	C327.082489,96.966873 326.096588,97.042778 325.110718,97.118683 
	C325.110718,97.118675 325.041534,97.044823 325.006592,97.007576 
	C326.734955,95.649048 328.498230,94.327766 330.645020,93.017639 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M88.990265,383.314789 
	C89.782066,383.003815 90.480286,382.955811 91.522285,382.851746 
	C91.200439,385.375580 91.725945,388.781342 87.198669,388.914856 
	C86.759453,388.709167 86.715126,388.545959 87.076233,388.050354 
	C87.890823,386.704346 88.299980,385.690643 88.709137,384.676971 
	C88.771652,384.310547 88.834160,383.944153 88.990265,383.314789 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M456.595062,86.072678 
	C457.019714,86.224091 457.071716,86.472542 457.221100,87.448242 
	C457.314514,89.632469 457.310516,91.089470 457.306519,92.546463 
	C457.241974,92.794197 457.177399,93.041939 457.026733,93.664406 
	C453.551086,91.703514 454.563995,88.966064 456.595062,86.072678 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M104.931717,233.624969 
	C102.367523,233.261353 99.730301,232.537476 96.989792,231.456009 
	C99.543907,231.820511 102.201294,232.542618 104.931717,233.624969 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M399.516296,72.853310 
	C399.260040,73.996178 398.640289,75.132149 397.960510,76.585037 
	C396.450165,76.822388 394.999908,76.742821 393.292847,76.413544 
	C395.074951,75.058037 397.113861,73.952232 399.516296,72.853310 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M146.151138,441.296661 
	C145.832626,439.459076 145.679138,437.918121 145.800690,436.174805 
	C147.105286,437.954254 148.134857,439.936157 149.224899,442.236938 
	C148.295624,442.234985 147.305893,441.914154 146.151138,441.296661 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M222.769592,350.718567 
	C221.299606,352.058014 219.606369,353.125885 217.581818,354.243195 
	C217.438339,350.838409 219.146072,349.477325 222.769592,350.718567 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M181.693268,188.048523 
	C180.630554,190.023392 179.215805,192.026642 177.467270,194.112045 
	C178.536057,192.155106 179.938644,190.115997 181.693268,188.048523 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M372.335510,102.955406 
	C372.738708,101.710419 373.534088,100.487312 374.616028,99.115509 
	C375.567352,99.985123 376.232086,101.003433 377.266113,102.587418 
	C375.235413,102.761864 373.981567,102.869576 372.335510,102.955406 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M173.654083,199.011520 
	C172.609314,200.684021 171.200241,202.356628 169.461945,204.112518 
	C170.518402,202.467743 171.904068,200.739700 173.654083,199.011520 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M90.523346,426.993164 
	C90.124054,428.820343 89.383827,430.711578 88.380890,432.814117 
	C85.763329,429.983582 87.727760,428.435638 90.523346,426.993164 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M29.137457,370.139160 
	C30.186304,370.167847 31.210169,370.570404 32.563423,371.006958 
	C33.038460,371.701569 33.184109,372.362152 33.389500,373.354126 
	C33.352669,373.898132 33.256096,374.110748 33.074333,374.663208 
	C31.696926,373.506378 30.404699,372.009705 29.137457,370.139160 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M352.251984,134.837494 
	C352.311920,133.283432 352.688293,131.597122 353.127289,129.549652 
	C353.857452,129.101013 354.524994,129.013519 355.525818,128.882675 
	C354.762207,130.794617 353.665344,132.749924 352.251984,134.837494 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M469.598145,61.990341 
	C470.974152,62.772190 471.952545,63.546844 472.971039,64.648018 
	C470.878937,64.408325 468.746735,63.842117 466.293579,63.091721 
	C465.976410,62.730312 465.980194,62.553089 466.004669,62.109177 
	C467.083771,61.889370 468.142151,61.936260 469.598145,61.990341 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M206.785553,172.087616 
	C205.381561,173.101837 203.574387,174.071609 201.388351,175.034470 
	C202.800446,174.032761 204.591400,173.037979 206.785553,172.087616 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M100.498222,401.125641 
	C100.040894,402.839233 99.314606,404.730621 98.306213,406.815552 
	C95.590065,403.882385 99.224785,403.101196 100.498222,401.125641 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M115.122604,427.567505 
	C114.440018,427.926392 113.916359,427.951477 112.995964,427.979614 
	C113.403664,426.557709 114.208099,425.132812 115.337631,423.649658 
	C115.535660,424.805481 115.408607,426.019562 115.122604,427.567505 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M142.947861,426.655884 
	C141.807465,426.405365 140.728424,425.762756 139.322693,425.102356 
	C138.996017,425.084564 138.504211,425.020905 138.242737,424.688599 
	C137.969467,423.936127 137.957672,423.515930 137.945892,423.095764 
	C140.500137,422.766907 142.887970,422.704041 142.947861,426.655884 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M368.377106,107.987961 
	C369.111633,106.409515 370.232819,104.830582 371.645508,103.106812 
	C372.054199,105.379044 372.242981,107.841324 368.377106,107.987961 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M242.258423,360.804749 
	C241.449432,360.990631 240.895233,360.956268 239.924683,360.856964 
	C239.711258,359.801544 239.914169,358.811066 240.496094,357.441101 
	C241.246643,357.037292 241.618210,357.013031 241.989777,356.988739 
	C242.164261,358.187317 242.338730,359.385895 242.258423,360.804749 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M454.963623,65.354904 
	C455.442841,64.701675 455.934601,64.375961 456.735138,64.048416 
	C457.197296,65.552872 457.350677,67.059143 457.249298,68.784317 
	C456.483551,68.436539 455.972565,67.869865 455.214325,67.148430 
	C454.970123,66.556587 454.973145,66.119499 454.963623,65.354904 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M40.033722,391.311920 
	C40.806450,392.710968 41.562481,394.533569 42.239777,396.651306 
	C41.446365,395.209442 40.731697,393.472473 40.033722,391.311920 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M103.535759,392.977692 
	C103.164085,394.527924 102.462318,396.126892 101.467827,397.879150 
	C101.851959,396.363739 102.528816,394.695099 103.535759,392.977692 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M205.011719,278.335999 
	C206.546448,278.444641 208.089233,278.929260 209.866272,279.667786 
	C208.142761,280.628113 205.875763,282.633270 205.011719,278.335999 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M346.215576,82.276466 
	C347.434113,81.356155 348.871674,80.687744 350.669373,80.025352 
	C349.497864,80.863701 347.966248,81.696037 346.215576,82.276466 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M342.046997,302.358765 
	C343.547577,302.439117 345.105652,302.851776 346.850647,303.561920 
	C345.393250,303.469971 343.748871,303.080505 342.046997,302.358765 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M360.322723,120.962761 
	C360.740173,119.392357 361.556274,117.824097 362.676636,116.107956 
	C362.227722,117.628349 361.474579,119.296631 360.322723,120.962761 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M493.703888,62.030369 
	C494.969666,62.821201 495.938232,63.633511 496.541931,64.820984 
	C495.122406,65.202126 494.067780,65.208107 493.013153,65.214096 
	C492.755524,64.664795 492.497925,64.115486 492.120178,63.286087 
	C492.468872,62.687943 492.937775,62.369892 493.703888,62.030369 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M144.781631,370.089355 
	C143.800720,369.231110 142.911591,367.950775 142.059204,366.320831 
	C143.021774,367.203247 143.947601,368.435242 144.781631,370.089355 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M241.988495,356.598450 
	C241.618210,357.013031 241.246643,357.037292 240.601410,357.076233 
	C239.870132,355.999298 239.412521,354.907684 238.916763,353.469421 
	C239.914825,354.151215 240.951019,355.179688 241.988495,356.598450 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M250.847778,357.153931 
	C250.044952,358.480286 248.883575,359.742096 247.385681,361.025940 
	C248.195831,359.728424 249.342529,358.408905 250.847778,357.153931 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M83.496170,394.128357 
	C84.113068,392.407959 85.031029,390.677795 86.240234,388.835846 
	C86.670616,389.220367 86.809746,389.716705 86.716507,390.780029 
	C85.988976,392.234802 85.493805,393.122559 84.998627,394.010254 
	C84.598152,394.046387 84.197685,394.082520 83.496170,394.128357 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M417.210419,77.263336 
	C417.024811,76.630295 417.043823,76.243065 417.087341,75.570877 
	C417.863464,75.263023 418.615112,75.240135 419.720825,75.367676 
	C419.188293,76.181793 418.301666,76.845467 417.210419,77.263336 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M85.181946,394.237457 
	C85.493805,393.122559 85.988976,392.234802 86.729477,391.169006 
	C86.438286,392.148804 85.901772,393.306732 85.181946,394.237457 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M428.011597,62.770870 
	C426.813782,63.225216 425.626587,63.417488 424.218567,63.364937 
	C425.096588,62.748177 426.195526,62.376240 427.670410,61.994537 
	C428.038361,62.159447 428.030304,62.334122 428.011597,62.770870 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M260.974609,351.201965 
	C260.354462,352.107300 259.599670,352.697998 258.522278,353.260620 
	C259.079773,352.450775 259.959869,351.669037 260.974609,351.201965 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M51.593887,435.054199 
	C51.957958,436.057648 51.984509,437.131439 51.984676,438.607361 
	C49.272923,438.157440 49.370426,436.802551 51.593887,435.054199 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M246.695984,361.048584 
	C246.332031,362.059937 245.615585,363.071136 244.575897,364.138367 
	C244.949615,363.145721 245.646561,362.097076 246.695984,361.048584 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M161.148193,224.278580 
	C161.495422,223.675613 161.980637,223.361801 162.892288,223.234131 
	C163.580612,223.799591 163.842514,224.178894 164.104416,224.558197 
	C164.082367,224.785507 164.060303,225.012833 164.007477,225.580612 
	C163.079865,225.469955 162.183029,225.018845 161.148193,224.278580 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M80.541031,400.029419 
	C80.778732,399.009094 81.360817,397.938110 82.242249,396.740967 
	C81.989532,397.736115 81.437469,398.857452 80.541031,400.029419 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M238.901337,352.787170 
	C238.000229,352.618469 237.122070,352.115662 236.123260,351.321533 
	C236.976486,351.504517 237.950394,351.978790 238.901337,352.787170 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M137.615112,423.058289 
	C137.957672,423.515930 137.969467,423.936127 137.995361,424.671600 
	C137.342407,424.626678 136.675354,424.266418 136.001404,423.607727 
	C136.424454,423.213104 136.854401,423.116974 137.615112,423.058289 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M334.784790,90.104019 
	C334.341217,90.769745 333.530243,91.393929 332.374390,92.028305 
	C332.825439,91.379814 333.621399,90.721146 334.784790,90.104019 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M352.980255,87.566917 
	C352.277802,87.915916 351.503845,87.949547 350.343445,87.945465 
	C350.940918,87.689026 351.924866,87.470291 352.980255,87.566917 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M256.973267,353.322235 
	C256.415070,353.976532 255.501770,354.477020 254.252457,354.934967 
	C254.817017,354.317780 255.717590,353.743103 256.973267,353.322235 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M50.007904,408.339722 
	C50.666916,408.759247 51.327183,409.530243 51.964542,410.657562 
	C51.297474,410.239655 50.653320,409.465424 50.007904,408.339722 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M243.180542,176.821869 
	C243.313904,175.961166 243.770081,174.992050 244.558167,173.930298 
	C244.427856,174.796234 243.965607,175.754837 243.180542,176.821869 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M77.449951,408.014587 
	C77.816093,407.333252 78.535332,406.636505 79.562454,405.869507 
	C79.181236,406.532562 78.492149,407.265869 77.449951,408.014587 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M33.054279,376.230042 
	C33.652653,376.391663 34.227520,376.892395 34.916267,377.658264 
	C34.363689,377.471985 33.697231,377.020569 33.054279,376.230042 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M159.748489,374.203064 
	C160.315430,374.788361 160.631714,375.575409 160.974304,376.681213 
	C160.499695,376.134949 159.998779,375.269897 159.748489,374.203064 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M24.096973,359.187439 
	C24.702879,359.356934 25.313942,359.861023 25.950121,360.685547 
	C25.350866,360.511322 24.726498,360.016693 24.096973,359.187439 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M306.835297,367.751831 
	C306.809082,368.275421 306.604523,368.551422 306.155457,368.916534 
	C305.577972,368.551361 305.244995,368.097046 304.935638,367.343872 
	C305.525146,367.198090 306.091034,367.351166 306.835297,367.751831 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M113.185379,369.099091 
	C112.766495,369.782471 112.347610,370.465851 111.639755,371.264740 
	C111.390083,370.725983 111.429398,370.071747 112.028603,369.222687 
	C112.787460,369.051636 112.986420,369.075378 113.185379,369.099091 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M410.032043,76.286987 
	C410.555420,76.166161 411.108704,76.336433 411.832184,76.748688 
	C411.355591,76.853149 410.708771,76.715622 410.032043,76.286987 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M243.664215,363.993103 
	C243.978943,364.417999 243.961044,364.837067 243.548004,365.583740 
	C242.775253,365.962952 242.397629,366.014587 242.019989,366.066223 
	C242.013184,365.606537 242.006393,365.146851 242.000214,364.342224 
	C242.444412,363.993958 242.888016,363.990631 243.664215,363.993103 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M91.607552,422.993347 
	C91.800194,423.525818 91.707443,424.095398 91.377014,424.843140 
	C91.200272,424.357666 91.261215,423.694092 91.607552,422.993347 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M76.333748,229.041336 
	C75.995285,228.599960 75.989746,228.148499 75.974030,227.359467 
	C76.612900,227.466751 77.261955,227.911606 77.954742,228.677917 
	C77.554543,229.010010 77.110603,229.020630 76.333748,229.041336 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M90.958237,230.712524 
	C90.550278,231.012299 90.105164,231.015060 89.326454,230.999954 
	C89.232719,230.472565 89.472572,229.963058 89.712418,229.453552 
	C90.115311,229.774216 90.518188,230.094879 90.958237,230.712524 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M94.884315,230.871002 
	C94.446785,231.239105 93.868309,231.364304 93.138214,231.275879 
	C93.572197,230.917542 94.157784,230.772827 94.884315,230.871002 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M337.023865,301.331299 
	C337.543518,301.183868 338.101349,301.325195 338.837463,301.699585 
	C338.364532,301.828461 337.713287,301.724243 337.023865,301.331299 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M397.257202,308.013977 
	C396.736603,307.818390 396.470764,307.614166 396.097443,307.178253 
	C396.646240,307.100006 397.302551,307.253479 397.990173,307.697357 
	C397.851654,307.993652 397.681793,307.999512 397.257202,308.013977 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M197.795746,175.091034 
	C197.641861,175.704422 197.149994,176.326447 196.339661,176.993042 
	C196.500061,176.391632 196.978897,175.745651 197.795746,175.091034 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M458.618591,155.544479 
	C459.057709,155.658127 459.324066,155.937195 459.590393,156.216248 
	C459.474884,156.326492 459.359375,156.436737 459.243835,156.546967 
	C458.977814,156.267944 458.711792,155.988922 458.618591,155.544479 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M210.876923,171.848450 
	C210.448593,172.248611 209.870834,172.403809 209.124695,172.361633 
	C209.546722,171.977341 210.137115,171.790421 210.876923,171.848450 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M113.359055,368.945679 
	C112.986420,369.075378 112.787460,369.051636 112.288712,369.004883 
	C112.351456,368.661560 112.713982,368.341248 113.316452,367.969788 
	C113.754349,368.215454 113.746460,368.506653 113.359055,368.945679 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M118.962959,423.347992 
	C119.129425,423.068146 119.318207,423.031799 119.787704,423.063904 
	C119.876007,423.539490 119.683609,423.946594 119.491211,424.353668 
	C119.322571,424.099609 119.153915,423.845581 118.962959,423.347992 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M159.777267,222.092529 
	C159.896713,222.288956 159.737320,222.532318 159.306549,222.825958 
	C159.156616,222.612000 159.311035,222.366409 159.777267,222.092529 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M419.921997,64.846954 
	C419.711334,64.945724 419.451172,64.744865 419.140808,64.248260 
	C419.364960,64.131615 419.625549,64.329880 419.921997,64.846954 
z"
          />
          <path
            fill="#0B4FAD"
            opacity="1.000000"
            stroke="none"
            d="
M340.892090,365.131805 
	C341.043182,361.744751 341.353912,358.752075 342.215698,355.406555 
	C345.705261,354.720703 348.643188,354.112244 351.582458,354.105652 
	C360.807343,354.084869 370.032715,354.290680 379.588470,354.449463 
	C380.308044,354.306183 380.697083,354.122162 381.442383,354.127167 
	C382.263702,354.115814 382.728729,353.915436 383.193756,353.715088 
	C383.658722,353.854675 384.123718,353.994232 385.052246,354.251709 
	C385.515808,354.369629 385.867737,354.064911 385.867737,354.064911 
	C386.048920,354.224579 386.230072,354.384247 386.691223,355.119629 
	C389.005524,356.762604 391.039886,357.829803 393.074249,358.897003 
	C393.074249,358.897003 393.015106,358.984344 393.055298,359.289917 
	C393.792664,359.979309 394.489838,360.363098 395.186981,360.746887 
	C395.186981,360.746887 395.190308,360.812500 395.180176,361.166565 
	C396.401093,362.391357 397.632111,363.262146 398.863159,364.132904 
	C398.863159,364.132904 399.086945,364.436829 399.125183,364.944427 
	C399.847290,365.858917 400.531189,366.265808 401.215088,366.672729 
	C401.215088,366.672729 401.249054,366.728455 401.264435,367.034210 
	C401.716888,367.651978 402.153961,367.963989 402.591064,368.275970 
	C402.591064,368.275970 402.766724,368.664398 402.836060,369.204224 
	C403.824005,370.901184 404.742615,372.058380 405.661255,373.215546 
	C405.742310,373.609772 405.823364,374.003998 405.695374,374.881775 
	C406.313995,376.585968 407.141632,377.806610 407.969238,379.027283 
	C408.313629,380.437897 408.658020,381.848541 408.716675,383.928802 
	C408.612823,393.066406 408.794708,401.534393 408.976624,410.002350 
	C408.910645,411.329742 408.844666,412.657104 408.397980,414.481323 
	C407.739380,415.706268 407.461548,416.434448 407.183716,417.162598 
	C407.048676,417.515778 406.913635,417.868958 406.383118,418.615967 
	C405.833405,419.782379 405.679260,420.554962 405.525085,421.327515 
	C405.387238,421.682770 405.249390,422.038025 404.683594,422.708252 
	C403.918274,423.750793 403.580933,424.478363 403.243591,425.205933 
	C402.921570,425.957245 402.599579,426.708557 401.719208,427.724487 
	C395.545135,431.650696 389.914337,435.289612 384.334900,439.005707 
	C383.716949,439.417267 383.392151,440.268860 382.888855,441.110138 
	C382.846313,441.303894 383.069794,441.631683 383.069794,441.631653 
	C381.544281,441.681061 380.018768,441.730469 377.668945,441.666901 
	C365.527954,441.395386 354.211334,441.236847 342.864014,440.780579 
	C342.349731,439.944183 341.866089,439.405518 341.382446,438.866852 
	C341.306458,417.246338 341.233154,395.625824 341.151276,374.005310 
	C341.140564,371.178833 341.085999,368.352539 340.892090,365.131805 
M376.679382,370.486633 
	C373.185760,370.375336 369.674377,370.445679 366.203064,370.106110 
	C361.989807,369.694000 360.677582,371.423370 360.731049,375.535004 
	C360.919342,390.023590 361.082275,404.522827 360.693726,419.002991 
	C360.553284,424.236725 362.448090,425.372345 367.180054,425.653168 
	C380.702362,426.455719 391.316193,418.062195 390.162567,404.204010 
	C389.666626,398.246674 390.057129,392.220428 389.884521,386.230103 
	C389.679779,379.124573 384.533112,372.755707 376.679382,370.486633 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M264.738770,371.655518 
	C269.664032,371.810333 274.329620,368.976410 279.631134,371.630951 
	C282.939453,373.287476 284.604095,375.085907 285.006927,378.422150 
	C285.706818,384.218445 287.145782,385.683563 293.102020,385.935089 
	C296.733490,386.088409 300.377716,385.939514 304.476135,385.925354 
	C304.955963,386.385651 304.975891,386.846954 304.551971,387.654144 
	C302.496918,388.008606 300.779510,387.661957 299.298279,388.104980 
	C296.767792,388.861877 294.385376,390.113861 291.581543,391.077026 
	C287.413483,389.523895 283.658844,387.885651 279.782257,386.621094 
	C274.842712,385.009735 269.804565,383.700653 264.799011,382.237274 
	C264.789124,382.213287 264.742371,382.190826 264.814545,381.780060 
	C264.824371,378.172058 264.761993,374.974854 264.706909,371.746277 
	C264.714172,371.714935 264.738770,371.655518 264.738770,371.655518 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M142.000381,561.017822 
	C144.110199,558.730103 145.480820,556.506714 144.165436,553.008545 
	C143.102020,550.180420 144.931503,548.445312 147.995102,548.009277 
	C156.133865,546.850952 164.253052,545.555481 172.384491,544.344666 
	C176.131790,543.786682 177.651581,545.418823 176.668701,549.650757 
	C174.844940,551.014648 173.425049,552.018921 171.713684,553.153015 
	C170.622147,553.537048 169.822098,553.791199 168.799744,553.774658 
	C167.392715,554.017883 166.207993,554.531860 164.745728,555.183105 
	C163.986572,555.963257 163.504959,556.606262 162.724579,557.247864 
	C162.032944,557.292358 161.640060,557.338257 160.822403,557.275574 
	C158.482468,557.374634 156.526779,557.403259 154.660339,557.825989 
	C150.417084,558.787170 146.217651,559.941833 142.000381,561.017822 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M101.998787,575.015564 
	C107.500679,573.479309 113.002579,571.943115 118.562836,570.958008 
	C114.089844,573.698853 109.558487,575.888550 104.712326,578.093140 
	C103.985054,578.181641 103.572578,578.255127 102.785156,578.245972 
	C95.927727,580.616699 89.445236,583.070068 82.962738,585.523376 
	C83.121468,586.137817 83.280190,586.752197 83.438919,587.366638 
	C91.514023,588.352600 99.238426,586.078857 107.294777,583.921265 
	C108.413658,584.090881 109.212570,584.255798 110.003784,584.836060 
	C109.944893,585.802063 109.893692,586.352600 109.842484,586.903198 
	C104.192360,587.614197 98.542236,588.325195 92.086578,589.041016 
	C89.513252,589.673828 87.745453,590.301758 85.977654,590.929688 
	C85.237305,590.964233 84.496971,590.998779 83.092667,591.049561 
	C81.943115,591.347717 81.457520,591.629578 80.971924,591.911377 
	C79.916870,591.945557 78.861824,591.979736 76.986725,592.013855 
	C72.323746,592.366943 68.480827,592.719971 63.981586,593.133362 
	C65.092796,591.034241 65.610001,590.057251 66.127205,589.080200 
	C66.652359,588.885254 67.177513,588.690247 68.173882,588.218018 
	C69.167839,587.341003 69.690598,586.741211 70.213348,586.141357 
	C71.546204,586.113708 72.879059,586.086060 74.960541,586.015991 
	C77.479462,585.005554 79.249756,584.037476 81.020058,583.069458 
	C82.194412,582.907471 83.368759,582.745483 85.107208,582.272217 
	C86.152557,581.329346 86.633804,580.697754 87.115051,580.066162 
	C87.836044,580.057190 88.557037,580.048218 89.997253,579.990112 
	C92.190041,578.990051 93.663597,578.039124 95.137146,577.088196 
	C95.852852,577.068726 96.568558,577.049194 97.969131,576.995239 
	C99.768921,576.312317 100.883858,575.663940 101.998787,575.015564 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M313.000000,401.000000 
	C317.216095,398.945953 319.066376,400.987183 319.835205,404.934845 
	C320.837952,410.083130 323.081207,414.908905 318.823212,420.227844 
	C316.983917,422.525452 318.273804,427.254028 317.906891,430.854309 
	C317.810364,431.801453 316.836792,432.659180 316.265991,433.557983 
	C315.602722,432.912018 314.939453,432.266052 314.137482,430.893005 
	C313.998779,428.777710 313.998779,427.389435 314.287659,425.710632 
	C314.051025,419.280060 313.525513,413.140045 313.000000,406.571533 
	C313.000000,404.428711 313.000000,402.714355 313.000000,401.000000 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M110.858589,566.061340 
	C105.813675,566.744629 101.155205,567.254395 95.451607,567.878479 
	C101.729584,558.270691 117.150719,556.363098 123.392105,563.066345 
	C119.342987,564.006470 115.294014,564.947144 110.858589,566.061340 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M322.031616,521.117798 
	C322.468170,521.112793 322.904694,521.107849 324.085571,521.088989 
	C326.219788,521.038757 327.609680,521.002319 329.404297,520.977478 
	C333.205475,520.320801 336.601868,519.652527 339.998291,518.984253 
	C341.386200,518.974304 342.774109,518.964294 344.908752,518.953308 
	C346.444305,518.667786 347.233063,518.383240 348.436798,518.095337 
	C350.234497,518.054993 351.617249,518.017944 353.384399,518.000122 
	C354.856018,518.445129 355.943207,518.870850 356.970337,519.521484 
	C356.693115,519.976624 356.439697,520.155396 355.728607,520.170349 
	C352.550903,520.767395 349.794617,521.476929 346.605682,522.190430 
	C339.778900,522.791931 333.387238,523.417297 326.989929,523.978943 
	C320.303894,524.565857 313.612488,525.091614 307.031433,525.318115 
	C309.101776,524.022339 311.064178,523.052612 313.462769,522.066772 
	C316.609863,521.739624 319.320740,521.428711 322.031616,521.117798 
z"
          />
          <path
            fill="#A9B5D9"
            opacity="1.000000"
            stroke="none"
            d="
M180.017807,575.045288 
	C176.517075,575.480347 173.016327,575.915283 168.792389,576.658447 
	C163.976257,577.166931 159.883331,577.367126 155.947906,577.272339 
	C157.733322,576.287048 159.361206,575.596863 161.392273,574.911011 
	C163.123398,574.774292 164.451324,574.633301 166.124054,574.624756 
	C167.308487,574.454529 168.148117,574.151794 169.335266,573.842163 
	C170.791901,573.562622 171.901047,573.289856 173.422852,573.061523 
	C174.924042,572.943054 176.012589,572.780273 177.500427,572.661377 
	C185.557861,571.993713 193.215973,571.282104 201.262451,570.668640 
	C203.389587,570.465454 205.122742,570.125305 206.868484,569.871826 
	C209.571106,569.479431 212.282333,569.146179 214.980621,569.134033 
	C214.947876,569.941528 214.924423,570.402710 214.669739,570.908264 
	C214.438492,570.952576 213.986206,571.083801 213.986206,571.083801 
	C207.300690,571.767883 200.615173,572.452026 193.057312,573.124268 
	C188.129242,573.756775 184.073517,574.401062 180.017807,575.045288 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M141.683121,561.187988 
	C146.217651,559.941833 150.417084,558.787170 154.660339,557.825989 
	C156.526779,557.403259 158.482468,557.374634 160.697128,557.566467 
	C159.269577,559.170105 157.542511,560.374268 155.511749,561.620605 
	C154.799988,561.681396 154.391937,561.699951 153.769867,561.521423 
	C152.610275,561.730469 151.664688,562.136658 150.507446,562.677429 
	C150.110916,563.085327 149.926041,563.358582 149.422470,563.619629 
	C147.623047,564.036560 146.142303,564.465759 144.261169,564.849731 
	C141.651382,565.320496 139.428818,565.786255 137.235168,566.362244 
	C131.772446,567.796509 126.322876,569.280945 120.868019,570.745300 
	C122.624138,569.507446 124.230919,567.914368 126.165520,567.101013 
	C131.154984,565.002869 136.289368,563.249390 141.683121,561.187988 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M221.023651,540.015259 
	C222.079926,540.012756 223.136185,540.010254 224.921661,539.969971 
	C226.770401,539.296204 227.889954,538.660278 229.009506,538.024414 
	C230.401825,537.976807 231.794128,537.929199 233.631989,538.048706 
	C234.077530,538.215698 234.108917,538.663269 233.780945,538.888672 
	C229.671387,540.090149 225.873184,541.006531 222.111832,542.055176 
	C215.063629,544.020264 208.036758,546.061890 200.614288,548.094849 
	C198.200485,548.517212 196.173386,548.916382 193.830704,549.238220 
	C192.942184,549.702942 192.369263,550.244995 191.489197,550.917542 
	C190.115982,551.311035 189.049911,551.574036 187.790680,551.633789 
	C186.704254,551.681213 185.810974,551.931885 184.643494,552.085693 
	C184.221420,551.863525 184.073547,551.738159 184.012268,551.311890 
	C185.413330,550.036621 186.727814,549.062439 188.440613,548.067932 
	C191.916519,546.737549 194.994095,545.427490 198.071671,544.117371 
	C201.435486,543.754395 204.799301,543.391418 208.980682,543.030396 
	C212.207291,542.377197 214.616333,541.721985 217.388702,541.059692 
	C218.842575,540.706848 219.933121,540.361084 221.023651,540.015259 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M321.987854,520.753052 
	C319.320740,521.428711 316.609863,521.739624 313.296875,521.808594 
	C312.129852,521.379272 311.564911,521.192017 311.000000,521.004700 
	C307.540222,518.592041 304.080444,516.179321 300.005432,513.337646 
	C300.882294,512.731201 302.259216,511.182526 302.879272,511.442047 
	C309.354309,514.152100 317.497650,513.403076 321.987854,520.753052 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M342.894684,441.078308 
	C354.211334,441.236847 365.527954,441.395386 377.328339,441.816895 
	C378.086792,442.268127 378.178650,442.536896 377.816772,443.072540 
	C377.235474,443.398041 377.035858,443.631836 376.947052,443.960266 
	C376.947052,443.960266 376.989929,443.938293 376.503235,443.931335 
	C364.713409,443.871826 353.410309,443.819305 342.107178,443.766785 
	C341.732971,443.051239 341.358734,442.335724 341.308411,441.320251 
	C342.053101,441.039642 342.473877,441.058990 342.894684,441.078308 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M246.046326,435.876617 
	C246.025391,435.196198 246.004456,434.515778 246.296951,433.579254 
	C253.898529,440.782410 263.485992,442.660156 273.193604,443.756958 
	C275.767365,444.047760 278.573608,442.280609 281.561157,441.473389 
	C282.235077,441.497864 282.620941,441.507263 283.301300,441.707458 
	C284.772278,442.035706 285.948761,442.173248 287.127594,442.515533 
	C287.129913,442.720276 287.038330,443.119385 286.706848,443.107849 
	C285.242493,444.036438 284.109589,444.976593 282.976715,445.916779 
	C279.953003,445.951385 276.929321,445.985992 273.038208,446.009766 
	C269.780975,445.681427 267.391113,445.363953 264.763123,444.759460 
	C262.017029,443.979919 259.509033,443.487335 256.694397,442.837097 
	C254.269104,441.744812 252.150482,440.810150 249.894852,439.607910 
	C248.851044,438.542267 247.944244,437.744232 246.929779,436.721619 
	C246.604324,436.239166 246.345734,436.032379 246.046326,435.876617 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M291.940735,391.157898 
	C294.385376,390.113861 296.767792,388.861877 299.298279,388.104980 
	C300.779510,387.661957 302.496918,388.008606 304.554047,388.000000 
	C304.582458,390.295197 304.164917,392.590393 303.747375,394.885590 
	C303.192902,394.950470 302.638428,395.015381 302.083984,395.080292 
	C303.951813,396.852203 305.819672,398.624115 307.799194,401.018799 
	C307.269318,402.095612 306.627777,402.549622 305.830139,402.711243 
	C301.096283,398.665192 296.518524,394.911530 291.940735,391.157898 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M228.166626,598.992676 
	C234.349304,599.119873 238.562210,602.037170 241.303772,608.544067 
	C239.101639,608.732361 237.403854,608.877502 234.949081,608.977234 
	C233.035355,608.352844 231.878616,607.773926 230.478912,606.974426 
	C229.154190,604.955505 228.072433,603.157227 226.836334,601.057678 
	C226.473923,600.669189 226.265854,600.581909 226.327423,600.456787 
	C227.120255,599.943481 227.643448,599.468079 228.166626,598.992676 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M120.563980,570.811279 
	C126.322876,569.280945 131.772446,567.796509 137.235168,566.362244 
	C139.428818,565.786255 141.651382,565.320496 144.530914,565.006226 
	C142.460251,566.475464 139.719452,567.742981 136.590027,568.994751 
	C133.792847,569.974670 131.384293,570.970459 128.979858,571.973999 
	C128.983948,571.981812 128.965302,571.963684 128.544800,571.958618 
	C121.709457,573.859009 115.304558,575.799255 108.870995,577.639221 
	C107.647903,577.989075 106.311325,577.942139 105.027115,578.078247 
	C109.558487,575.888550 114.089844,573.698853 118.818932,571.214417 
	C119.431091,570.905579 119.845512,570.891418 120.563980,570.811279 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M401.808258,430.824432 
	C404.509918,431.200104 405.312042,432.512512 404.566620,435.263702 
	C403.955750,437.518250 404.022491,439.956421 403.949341,442.632660 
	C403.191406,443.389771 402.109436,443.652283 401.400787,444.314392 
	C400.693695,444.975037 400.360657,446.036011 399.863586,446.921478 
	C399.053497,446.464264 397.715118,446.146790 397.549194,445.519226 
	C397.252838,444.398560 397.347107,442.930359 397.866180,441.905273 
	C398.413940,440.823517 399.649139,440.089844 401.301758,438.524078 
	C399.496033,437.910339 398.179688,437.462921 396.439178,436.986145 
	C396.015015,436.956787 395.896606,436.898041 396.049347,436.608704 
	C398.070831,434.487762 399.939545,432.656097 401.808258,430.824432 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M342.161469,444.066528 
	C353.410309,443.819305 364.713409,443.871826 376.479126,443.927124 
	C376.960083,444.390045 376.978516,444.850128 376.701782,445.677307 
	C375.936035,446.358276 375.465454,446.672211 374.994843,446.986115 
	C373.282135,446.988464 371.569397,446.990845 369.007629,446.982849 
	C363.439209,446.656372 358.719818,446.340240 354.000458,446.024078 
	C352.285950,446.019226 350.571442,446.014374 348.012115,446.008179 
	C345.779816,446.003387 344.392365,445.999878 343.004883,445.996338 
	C342.741852,445.452972 342.478821,444.909637 342.161469,444.066528 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M296.941895,579.879578 
	C288.301941,581.288269 279.614899,582.302612 270.472290,583.263550 
	C270.194702,581.670349 270.372681,580.130615 270.548798,578.606812 
	C278.686432,578.606812 286.189301,578.572083 293.691101,578.645874 
	C294.761658,578.656372 295.827057,579.192444 296.941895,579.879578 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M220.713226,539.850952 
	C219.933121,540.361084 218.842575,540.706848 217.114792,540.760986 
	C213.986862,540.316162 211.496170,540.162964 209.005493,540.009766 
	C208.438751,538.829773 207.549316,537.696289 207.370880,536.460266 
	C206.970032,533.683350 208.162827,531.960938 211.217392,532.635803 
	C215.329437,533.544189 218.757645,535.492981 220.713226,539.850952 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M175.034454,602.943420 
	C175.034454,602.943420 175.024521,602.472168 174.973572,601.864014 
	C174.291992,598.841370 173.661346,596.426758 173.030701,594.012207 
	C176.157547,593.730774 179.284393,593.449219 182.672607,593.611816 
	C181.522079,594.392273 179.737778,594.316162 178.792831,595.169556 
	C177.621948,596.226868 176.105103,598.732788 176.557770,599.428040 
	C178.210114,601.966187 180.619766,604.011292 182.791962,606.439087 
	C182.839844,606.635925 182.968979,607.021729 182.979950,607.344971 
	C183.601074,608.164978 184.211227,608.661865 184.831207,609.167725 
	C184.841064,609.176758 184.833908,609.209351 184.851044,609.555176 
	C185.060577,610.791565 185.252975,611.682190 185.328720,612.684814 
	C185.212082,612.796875 184.979004,613.021301 184.729675,612.895630 
	C183.986389,612.514160 183.492432,612.258362 182.998474,612.002502 
	C181.577179,610.339661 180.155899,608.676819 178.364319,606.608215 
	C177.007507,605.116150 176.020981,604.029785 175.034454,602.943420 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M357.030396,519.296631 
	C355.943207,518.870850 354.856018,518.445129 353.383911,517.627930 
	C352.999725,516.490967 353.000488,515.745483 353.001221,515.000000 
	C355.502472,515.181885 358.003693,515.363708 361.186340,515.272827 
	C364.244354,514.333313 366.620941,513.666687 368.997559,513.000000 
	C372.958984,513.454590 376.920410,513.909119 380.568359,514.876099 
	C375.268433,515.942566 370.265686,516.386292 365.305389,517.117920 
	C363.813171,517.338013 362.439117,518.359070 360.618530,519.092590 
	C359.160919,519.213501 358.095642,519.255066 357.030396,519.296631 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M164.489059,610.627441 
	C162.524796,609.963623 159.826538,609.402161 159.533951,608.236816 
	C158.982193,606.039185 159.610779,603.435181 160.264252,601.147278 
	C160.380890,600.738892 163.362762,600.463867 164.373505,601.122314 
	C167.594666,603.220581 167.628769,607.010925 164.489059,610.627441 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M361.010925,519.013245 
	C362.439117,518.359070 363.813171,517.338013 365.305389,517.117920 
	C370.265686,516.386292 375.268433,515.942566 380.625336,515.226562 
	C382.064117,515.051208 383.132507,515.037903 384.997925,515.016602 
	C387.529816,515.281189 389.264648,515.553711 391.006561,516.177490 
	C391.023621,516.762146 391.033630,516.995544 390.733734,517.217468 
	C390.012390,517.243896 389.600983,517.281860 388.820618,517.204956 
	C387.218567,517.595764 385.985413,518.101379 384.408813,518.664001 
	C383.369324,518.734192 382.673309,518.747375 381.722412,518.531311 
	C379.985657,518.572388 378.503754,518.842773 376.553772,519.117798 
	C371.060791,519.085999 366.035858,519.049622 361.010925,519.013245 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M236.000000,534.000000 
	C234.312515,533.007996 232.683395,531.891846 230.919510,531.062195 
	C229.445175,530.368713 227.813110,530.010559 226.250885,529.503967 
	C226.304474,528.782837 226.358078,528.061768 226.411667,527.340698 
	C230.398926,527.340698 234.470276,526.889771 238.323532,527.608337 
	C239.519913,527.831421 240.124725,531.226868 240.583984,533.584106 
	C238.778397,533.998901 237.389206,533.999451 236.000000,534.000000 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M259.947205,583.074280 
	C255.398514,584.394226 250.633255,585.644226 245.787735,586.227173 
	C241.976776,586.685669 240.347443,588.098938 241.365707,592.330444 
	C240.233948,591.389160 237.984314,589.609009 238.290146,588.822205 
	C239.114822,586.700500 240.832260,583.632751 242.546158,583.387024 
	C248.179031,582.579468 253.967392,582.856445 259.947205,583.074280 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M250.031860,439.875488 
	C252.150482,440.810150 254.269104,441.744812 256.693970,443.170990 
	C257.001770,444.107483 257.003326,444.552460 257.004883,444.997437 
	C255.859741,451.810669 254.427719,452.375732 248.307297,447.820129 
	C247.999084,446.888367 247.999237,446.444183 248.342346,445.943848 
	C249.123123,444.924286 249.560959,443.960938 249.999298,442.608490 
	C250.010513,441.438110 250.021194,440.656799 250.031860,439.875488 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M65.714935,589.052246 
	C65.610001,590.057251 65.092796,591.034241 63.981586,593.133362 
	C68.480827,592.719971 72.323746,592.366943 76.591942,592.043152 
	C76.897705,592.717834 77.005226,593.597656 76.621025,593.969788 
	C74.479393,596.044373 60.621967,595.346191 57.216164,592.016602 
	C60.322464,590.867126 62.812561,589.945679 65.714935,589.052246 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M184.936188,593.360046 
	C187.312027,592.427307 189.629745,590.927551 192.075500,590.676758 
	C197.631241,590.107117 203.249954,590.151733 208.588867,590.441528 
	C204.928391,591.713318 201.522110,592.490295 197.784119,593.265991 
	C197.020676,593.348389 196.588959,593.432068 195.848206,593.382202 
	C194.634796,593.592468 193.730423,593.936340 192.446472,594.358643 
	C189.698273,594.491577 187.329666,594.546143 184.946014,594.290710 
	C184.932693,593.773804 184.934433,593.566895 184.936188,593.360046 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M390.999512,515.826294 
	C389.264648,515.553711 387.529816,515.281189 385.397491,514.987732 
	C386.238983,513.647400 387.533630,511.164581 388.706696,511.220764 
	C392.663391,511.410309 397.972321,508.602905 399.935516,515.354736 
	C399.537201,515.922363 399.082062,516.138794 397.965393,516.367188 
	C395.930847,516.429932 394.557770,516.480652 392.856018,516.322510 
	C392.018005,516.017883 391.508759,515.922058 390.999512,515.826294 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M287.038330,443.119385 
	C287.038330,443.119385 287.129913,442.720276 287.433105,442.452209 
	C288.151825,442.180542 288.567322,442.176971 289.217957,442.411133 
	C290.928802,442.397003 292.404602,442.145142 293.880371,441.893280 
	C293.321686,444.354126 293.424835,447.298676 292.057953,449.168762 
	C289.672150,452.432831 286.330872,451.607452 284.387451,447.367950 
	C285.516296,445.689758 286.277313,444.404572 287.038330,443.119385 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M257.012787,532.049683 
	C258.439117,531.751404 259.865479,531.453064 261.984436,531.107056 
	C263.117462,531.042114 263.557861,531.024902 264.370117,531.010010 
	C265.484222,530.970398 266.226532,530.928467 267.267120,530.904236 
	C268.045746,530.623962 268.526123,530.326111 269.346802,529.997375 
	C270.779907,529.680786 271.872650,529.395020 273.156555,529.314941 
	C273.958466,529.052979 274.569214,528.585327 275.476440,528.127808 
	C276.168365,528.139038 276.563812,528.140076 277.175720,528.368408 
	C277.989075,528.808105 278.586060,529.020630 279.093536,529.549438 
	C278.014130,530.627625 277.024200,531.389526 275.616333,532.140381 
	C273.547668,532.245117 271.896912,532.361023 269.937988,532.348389 
	C269.089813,532.504700 268.549805,532.789490 267.658325,533.088135 
	C266.609619,533.180298 265.912354,533.258545 264.812042,533.237671 
	C260.612152,534.141663 256.815308,535.144897 252.680328,536.168701 
	C251.897293,536.264954 251.452377,536.340698 250.998779,536.109680 
	C250.986847,535.598450 250.983612,535.393921 250.995804,534.902222 
	C251.030746,534.424438 251.050247,534.233765 251.301498,534.038940 
	C251.533234,534.034851 251.994308,533.987183 252.343872,533.964600 
	C254.133209,533.311279 255.572998,532.680481 257.012787,532.049683 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M65.218140,600.898560 
	C66.537636,597.569946 72.914192,595.353394 74.840416,597.512939 
	C75.490616,598.241943 74.112656,602.308777 73.545540,602.335022 
	C70.816475,602.460938 68.047737,601.727173 65.218140,600.898560 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M228.086517,598.606934 
	C227.643448,599.468079 227.120255,599.943481 226.126495,600.497070 
	C225.655914,600.575195 225.253326,600.651917 225.180023,600.393066 
	C225.005371,599.790100 224.904007,599.446106 224.867447,598.692261 
	C223.749695,596.070068 222.567139,593.857544 221.419724,591.635376 
	C221.454865,591.625732 221.437271,591.646423 221.050537,591.477417 
	C220.383804,591.280884 220.103806,591.253296 219.433655,591.154968 
	C217.728653,591.103699 216.413773,591.123291 214.697266,591.055664 
	C213.496979,590.815979 212.698334,590.663635 211.899704,590.511230 
	C214.121719,587.113708 220.869263,586.837769 224.054596,590.507507 
	C225.893448,592.625977 226.722733,595.620728 228.086517,598.606934 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M155.790390,577.567383 
	C159.883331,577.367126 163.976257,577.166931 168.511902,576.931763 
	C165.697586,578.241272 162.541718,580.013672 159.156067,580.813782 
	C155.514694,581.674377 151.660416,581.658142 147.892731,581.940369 
	C147.109833,581.999084 146.299271,581.688904 145.246216,581.029419 
	C144.990952,580.510132 145.008102,580.027039 145.382904,580.025391 
	C147.501984,579.302551 149.246246,578.581299 151.392395,577.841187 
	C153.126312,577.737305 154.458359,577.652283 155.790390,577.567383 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M301.474915,450.612061 
	C300.511383,448.542175 299.710571,446.673492 299.425079,444.729156 
	C299.362640,444.303955 301.437225,442.918274 302.011780,443.166901 
	C303.772491,443.928833 306.386017,445.045868 306.667847,446.431549 
	C307.323303,449.654388 305.156769,451.213989 301.474915,450.612061 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M305.986237,403.003662 
	C306.627777,402.549622 307.269318,402.095612 307.955444,401.321411 
	C309.388885,401.000824 310.777802,401.000427 312.583344,401.000000 
	C313.000000,402.714355 313.000000,404.428711 312.691833,406.735901 
	C311.558289,408.892761 310.732910,410.456787 309.618561,411.870911 
	C308.425598,410.799988 307.521637,409.878967 306.580933,408.702118 
	C306.531189,408.102020 306.518158,407.757751 306.661835,407.050995 
	C306.541077,405.460205 306.263641,404.231934 305.986237,403.003662 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M375.041412,447.359253 
	C375.465454,446.672211 375.936035,446.358276 376.702698,446.022186 
	C380.095459,446.359039 383.196869,446.683685 386.284424,447.109009 
	C386.837402,447.185181 387.333008,447.677734 387.930115,448.021179 
	C383.623383,452.144135 378.118744,451.925629 375.041412,447.359253 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M293.894684,441.901428 
	C292.404602,442.145142 290.928802,442.397003 289.386322,442.100220 
	C295.003113,436.892487 300.686584,432.233398 306.519043,427.908203 
	C306.101349,429.818268 305.534668,431.394409 304.967957,432.970551 
	C304.967957,432.970551 305.000000,433.000000 304.703003,433.110352 
	C303.593933,434.132629 302.781891,435.044586 301.969849,435.956543 
	C301.653961,436.057770 301.392090,436.237640 300.744629,436.755341 
	C299.194244,437.998657 298.083466,438.982727 296.972656,439.966827 
	C296.972656,439.966827 296.994965,439.994904 296.632141,439.982178 
	C295.486023,440.609985 294.702698,441.250488 293.919373,441.890991 
	C293.919373,441.890991 293.908997,441.909607 293.894684,441.901428 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M184.662888,593.343567 
	C184.934433,593.566895 184.932693,593.773804 184.873917,594.579102 
	C180.677368,597.638672 177.965790,600.393188 182.718567,604.821045 
	C182.959381,605.045410 182.745972,605.757324 182.744080,606.242188 
	C180.619766,604.011292 178.210114,601.966187 176.557770,599.428040 
	C176.105103,598.732788 177.621948,596.226868 178.792831,595.169556 
	C179.737778,594.316162 181.522079,594.392273 183.119293,593.748291 
	C183.666245,593.402710 184.027908,593.364868 184.662888,593.343567 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M426.234070,444.093140 
	C427.042297,442.644043 428.138580,441.321198 429.557312,439.912048 
	C431.410034,440.741516 433.673981,441.307770 434.312622,442.648315 
	C435.514954,445.172028 436.130646,448.289001 431.388184,448.638062 
	C430.325256,447.529449 429.662628,446.765350 429.000000,446.001221 
	C428.174042,445.407288 427.348053,444.813354 426.234070,444.093140 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M304.945862,563.277100 
	C306.073212,562.887268 307.129700,562.868286 308.581604,562.860474 
	C309.985870,565.369751 314.208435,566.989990 311.225922,570.459717 
	C307.706055,568.896667 304.233063,567.354431 300.781311,565.821655 
	C302.001282,565.173889 303.438141,564.410950 304.945862,563.277100 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M352.993591,514.616333 
	C353.000488,515.745483 352.999725,516.490967 352.999512,517.608643 
	C351.617249,518.017944 350.234497,518.054993 348.380066,517.741272 
	C346.938965,516.927002 345.969482,516.463501 345.000000,516.000000 
	C346.515533,514.358521 347.935791,512.594788 349.674591,511.239868 
	C349.877686,511.081604 351.839478,513.180298 352.993591,514.616333 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M188.042282,548.088196 
	C186.727814,549.062439 185.413330,550.036621 183.795654,551.447144 
	C183.346268,551.969421 183.200104,552.055359 182.723572,552.037842 
	C181.932297,551.937073 181.471405,551.939819 180.786102,551.699585 
	C177.709518,551.978821 174.857346,552.501038 172.005157,553.023193 
	C173.425049,552.018921 174.844940,551.014648 176.632416,550.002747 
	C179.755768,549.025330 182.511536,548.055481 185.940277,547.069946 
	C187.089615,547.398926 187.565948,547.743530 188.042282,548.088196 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M289.000000,524.000000 
	C289.872589,522.049072 290.708374,518.477722 291.624756,518.456848 
	C294.179474,518.398804 297.844360,518.656982 296.556396,523.578552 
	C293.741760,523.997681 291.370880,523.998840 289.000000,524.000000 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M354.018433,446.435364 
	C358.719818,446.340240 363.439209,446.656372 368.579559,446.983215 
	C365.846619,451.075409 361.468597,448.304321 357.699066,448.863434 
	C356.602020,449.026184 355.264008,447.563995 354.018433,446.435364 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M127.875954,583.882690 
	C125.486847,584.131958 123.097733,584.381287 119.907806,584.791260 
	C117.639328,584.843567 116.171654,584.735229 114.866928,584.337952 
	C117.016273,583.031555 119.002663,582.014160 121.438042,580.987488 
	C124.284386,581.044678 126.681755,581.111145 129.049408,581.477417 
	C128.638458,582.479065 128.257202,583.180908 127.875954,583.882690 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M188.440613,548.067932 
	C187.565948,547.743530 187.089615,547.398926 186.306549,547.028320 
	C186.873947,546.024841 187.625732,544.383484 188.645401,544.195618 
	C191.564606,543.657532 194.592499,543.709106 197.824677,543.823120 
	C194.994095,545.427490 191.916519,546.737549 188.440613,548.067932 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M251.994308,533.987183 
	C251.994308,533.987183 251.533234,534.034851 250.930161,533.983276 
	C248.884933,533.621948 247.442764,533.312195 246.000610,533.002441 
	C245.934937,531.474487 245.869263,529.946533 245.803574,528.418579 
	C246.978973,528.723511 248.138962,529.143921 249.333771,529.303223 
	C250.727097,529.489075 252.150848,529.446655 253.638824,530.043579 
	C253.142258,531.718323 252.568283,532.852722 251.994308,533.987183 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M265.001251,445.046448 
	C267.391113,445.363953 269.780975,445.681427 272.585419,446.036682 
	C272.016602,447.651215 271.033203,449.227966 270.049805,450.804718 
	C268.370911,449.459503 266.692017,448.114288 265.009277,446.150330 
	C265.005432,445.531616 265.001251,445.046448 265.001251,445.046448 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M211.556564,590.571533 
	C212.698334,590.663635 213.496979,590.815979 214.680923,591.291382 
	C215.066223,591.614441 214.989166,592.082458 214.694397,592.077271 
	C213.923767,592.371399 213.447891,592.670654 212.987762,592.981934 
	C213.003525,592.994019 212.977112,592.960388 212.614075,592.956787 
	C211.165604,593.285645 210.080200,593.618042 208.580780,593.968994 
	C206.787537,594.056030 205.408295,594.124390 203.634369,594.144714 
	C201.531738,593.820129 199.823792,593.543701 198.115829,593.267212 
	C201.522110,592.490295 204.928391,591.713318 208.736542,590.776001 
	C209.830109,590.621094 210.521759,590.626465 211.556564,590.571533 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M145.008102,580.027039 
	C145.008102,580.027039 144.990952,580.510132 144.992935,580.751770 
	C141.628845,581.344727 138.262756,581.695984 134.229523,581.934204 
	C133.372406,581.521912 133.182465,581.222717 133.076996,580.682617 
	C133.422211,580.221191 133.682953,580.000671 134.336868,579.847778 
	C136.893005,579.068481 139.055984,578.221619 141.233795,577.306763 
	C141.248657,577.238770 141.124115,577.176880 141.504501,577.243042 
	C142.915771,577.377014 143.946655,577.444702 144.966675,577.826538 
	C144.973251,578.769470 144.990677,579.398254 145.008102,580.027039 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M296.999756,524.978760 
	C298.378876,524.959961 299.757965,524.941162 301.885742,524.924255 
	C303.421478,525.254395 304.208618,525.582642 304.892181,526.244141 
	C303.911224,526.804260 303.033783,527.031067 301.752167,527.170959 
	C298.898926,527.701843 296.449890,528.319763 293.587158,528.988342 
	C291.788818,529.155579 290.404144,529.272095 289.019043,529.196838 
	C289.018585,529.005066 288.987213,528.622803 289.082642,528.293152 
	C291.785309,526.968628 294.392548,525.973694 296.999756,524.978760 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M313.998779,426.001190 
	C313.998779,427.389435 313.998779,428.777710 313.998779,430.582367 
	C312.579224,431.328766 311.159637,431.658722 309.371582,431.599792 
	C308.944824,429.112640 308.886597,427.014374 308.861145,424.590088 
	C309.226318,423.502106 309.558655,422.740051 310.168274,422.176941 
	C311.629974,423.584320 312.814362,424.792755 313.998779,426.001190 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M279.187988,565.884766 
	C281.547974,561.084534 286.256531,563.729187 290.554260,562.983032 
	C290.040649,564.006104 289.176422,565.698242 288.090912,565.854675 
	C285.338470,566.251282 282.486786,565.959290 279.187988,565.884766 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M404.997864,444.263123 
	C407.550293,444.709045 410.053345,445.522614 412.820251,446.588745 
	C412.225647,447.552185 411.468262,448.606079 410.485718,448.895477 
	C408.920441,449.356506 406.735138,449.987732 405.696594,449.268921 
	C404.804474,448.651489 405.150146,446.245728 404.997864,444.263123 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M272.965424,529.109314 
	C271.872650,529.395020 270.779907,529.680786 269.346985,529.737122 
	C269.004974,529.334412 269.003082,529.161072 269.001221,528.987793 
	C272.885498,523.336914 274.699982,522.840515 278.627563,526.669678 
	C277.905090,527.415955 277.432190,527.778564 276.959290,528.141113 
	C276.563812,528.140076 276.168365,528.139038 275.144226,528.107910 
	C273.998840,528.421631 273.482117,528.765503 272.965424,529.109314 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M339.919800,518.616699 
	C336.601868,519.652527 333.205475,520.320801 329.404053,520.650269 
	C328.998962,519.874268 328.998871,519.437134 328.998779,519.000000 
	C332.379761,515.382080 336.119751,516.948853 339.919800,518.616699 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M223.757858,568.639893 
	C227.195770,568.736206 230.633682,568.832520 234.535461,568.955994 
	C231.395706,572.504578 226.815414,571.314514 222.266617,570.929688 
	C221.980301,569.816650 221.961548,569.341125 222.244690,568.817383 
	C222.950348,568.726074 223.354095,568.682983 223.757858,568.639893 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M297.002197,524.731079 
	C294.392548,525.973694 291.785309,526.968628 288.747101,528.350342 
	C287.873840,528.659058 287.431580,528.580994 286.989288,528.502930 
	C287.430115,527.042175 287.870941,525.581482 288.655884,524.060364 
	C291.370880,523.998840 293.741760,523.997681 296.556946,523.992126 
	C297.001221,523.987793 297.004608,524.483276 297.002197,524.731079 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M286.598877,528.602905 
	C287.431580,528.580994 287.873840,528.659058 288.651672,528.679932 
	C288.987213,528.622803 289.018585,529.005066 288.904785,529.532654 
	C284.538727,530.757263 280.286499,531.454346 276.034271,532.151428 
	C277.024200,531.389526 278.014130,530.627625 279.395874,529.523438 
	C280.192902,529.151001 280.598053,529.120728 281.170197,529.292603 
	C281.888733,529.279358 282.440277,529.064026 282.991821,528.848633 
	C284.064026,528.800049 285.136261,528.751526 286.598877,528.602905 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M304.995728,525.910828 
	C304.208618,525.582642 303.421478,525.254395 302.295959,524.872070 
	C302.620239,524.048462 303.147614,522.857422 303.972321,522.592712 
	C306.020935,521.935120 308.205231,521.700195 310.667511,521.150146 
	C311.564911,521.192017 312.129852,521.379272 312.860657,521.824768 
	C311.064178,523.052612 309.101776,524.022339 306.713654,525.385559 
	C305.857178,525.822937 305.426453,525.866882 304.995728,525.910828 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M235.742737,534.183777 
	C237.389206,533.999451 238.778397,533.998901 240.584412,533.996704 
	C241.005341,534.178284 241.009460,534.361389 241.011536,535.141113 
	C240.963013,536.384338 240.916534,537.031067 240.518478,537.768066 
	C239.442596,537.898071 238.718277,537.937805 237.833862,537.757935 
	C237.078049,537.951416 236.482346,538.364502 235.943359,538.858765 
	C236.000046,538.939880 236.073532,539.123718 235.760223,538.994629 
	C235.000916,538.798035 234.554916,538.730652 234.108917,538.663269 
	C234.108917,538.663269 234.077530,538.215698 234.045959,537.991699 
	C234.504745,536.634277 234.995102,535.500916 235.742737,534.183777 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M180.304321,575.325317 
	C184.073517,574.401062 188.129242,573.756775 192.607025,573.175537 
	C189.407593,576.784485 184.992584,576.160034 180.304321,575.325317 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M234.006256,446.368652 
	C235.728928,445.677490 237.457870,445.362762 239.831070,445.211365 
	C240.650024,446.249817 240.824707,447.124908 240.999390,448.000000 
	C239.990234,448.342834 238.867218,448.506256 237.992706,449.061249 
	C235.054062,450.926208 234.395737,449.130188 234.006256,446.368652 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M387.661713,442.780121 
	C386.160583,442.606323 384.659424,442.432526 383.114044,441.945190 
	C383.069794,441.631683 382.846313,441.303894 383.266785,441.154297 
	C387.073792,439.959656 390.460358,438.914642 393.898926,438.148621 
	C393.963959,438.616394 393.976929,438.805145 393.989868,438.993866 
	C393.670044,439.084229 393.407074,439.259735 392.707092,439.749695 
	C390.696136,440.912689 389.178925,441.846405 387.661713,442.780121 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M101.997986,574.612915 
	C100.883858,575.663940 99.768921,576.312317 98.326233,576.969482 
	C97.850510,575.203857 97.702545,573.429443 97.543793,571.525696 
	C100.149925,570.587219 102.089432,570.637634 101.997986,574.612915 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M342.676453,446.049255 
	C344.392365,445.999878 345.779816,446.003387 347.583618,446.011627 
	C348.135193,449.629303 345.373260,449.486359 343.033386,449.608643 
	C342.866486,449.617371 342.579285,447.324707 342.676453,446.049255 
z"
          />
          <path
            fill="#F8F9F9"
            opacity="1.000000"
            stroke="none"
            d="
M308.828369,424.916077 
	C308.886597,427.014374 308.944824,429.112640 309.002869,431.604248 
	C307.903717,432.328491 306.804749,432.659454 305.336884,432.980469 
	C305.534668,431.394409 306.101349,429.818268 306.580353,427.666504 
	C306.515564,426.757996 306.538452,426.425110 306.866333,425.987122 
	C307.723663,425.560059 308.276031,425.238068 308.828369,424.916077 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M172.666092,594.008057 
	C173.661346,596.426758 174.291992,598.841370 174.955505,601.629089 
	C172.947052,599.814331 170.905716,597.626465 168.163162,594.687012 
	C170.488358,594.303162 171.394928,594.153503 172.666092,594.008057 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M241.192047,448.240173 
	C240.824707,447.124908 240.650024,446.249817 240.206512,445.162445 
	C240.746826,444.370117 241.555939,443.790070 242.938080,443.227905 
	C244.007812,444.164459 244.504517,445.083130 245.000946,446.334839 
	C245.000244,447.111908 244.999817,447.555939 244.999390,448.000000 
	C243.794495,448.160126 242.589584,448.320221 241.192047,448.240173 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M209.004761,540.383423 
	C211.496170,540.162964 213.986862,540.316162 216.751465,540.768066 
	C214.616333,541.721985 212.207291,542.377197 209.399719,543.016235 
	C209.002151,542.252380 209.003098,541.504761 209.004761,540.383423 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M114.703979,584.626953 
	C116.171654,584.735229 117.639328,584.843567 119.550819,584.961182 
	C117.645782,588.977417 113.807259,586.118591 110.308243,586.937134 
	C109.893692,586.352600 109.944893,585.802063 110.362717,584.813782 
	C111.404709,584.186768 112.080078,583.997437 113.008652,584.081421 
	C113.742561,584.445557 114.223267,584.536255 114.703979,584.626953 
z"
          />
          <path
            fill="#F8F9F9"
            opacity="1.000000"
            stroke="none"
            d="
M368.658325,512.851318 
	C366.620941,513.666687 364.244354,514.333313 361.434479,515.000000 
	C362.733246,511.981323 365.256958,511.484375 368.658325,512.851318 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M264.763123,444.759460 
	C265.001251,445.046448 265.005432,445.531616 265.003326,445.774414 
	C262.578888,445.784302 260.156525,445.551392 257.369537,445.157959 
	C257.003326,444.552460 257.001770,444.107483 257.000641,443.328613 
	C259.509033,443.487335 262.017029,443.979919 264.763123,444.759460 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M388.051819,442.852295 
	C389.178925,441.846405 390.696136,440.912689 392.590637,439.959839 
	C392.902252,441.398834 392.836578,442.856964 392.762299,444.506317 
	C391.097992,443.896973 389.769958,443.410736 388.051819,442.852295 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M132.992538,580.923523 
	C133.182465,581.222717 133.372406,581.521912 133.791931,581.969971 
	C132.185562,582.809692 130.349625,583.500488 128.194809,584.036987 
	C128.257202,583.180908 128.638458,582.479065 129.017929,581.319702 
	C129.016144,580.862183 128.849731,580.587891 129.242462,580.742798 
	C130.754303,580.906250 131.873413,580.914856 132.992538,580.923523 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M269.215576,566.187012 
	C271.459229,565.685364 273.965485,565.488525 276.777405,565.528015 
	C274.548126,566.006836 272.013153,566.249268 269.215576,566.187012 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M244.148529,598.970093 
	C245.411316,600.591797 246.350113,602.413635 247.311234,604.594238 
	C242.774429,605.082092 243.631546,601.924622 244.148529,598.970093 
z"
          />
          <path
            fill="#F8F9F9"
            opacity="1.000000"
            stroke="none"
            d="
M433.600250,424.995850 
	C437.788574,424.651855 442.428253,424.310333 447.532471,423.989227 
	C443.348511,424.339172 438.700073,424.668762 433.600250,424.995850 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M249.126923,568.884521 
	C250.885193,567.907532 253.068268,566.986450 255.637787,566.124023 
	C253.866745,567.101990 251.709244,568.021179 249.126923,568.884521 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M221.942810,568.865540 
	C221.961548,569.341125 221.980301,569.816650 221.999054,570.649414 
	C220.292557,571.023682 218.586060,571.040833 216.170929,571.025757 
	C215.275177,570.950317 215.088074,570.907104 214.900986,570.863953 
	C214.924423,570.402710 214.947876,569.941528 215.417786,569.130188 
	C217.890427,568.808533 219.916626,568.837036 221.942810,568.865540 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M175.021515,603.349487 
	C176.020981,604.029785 177.007507,605.116150 178.038269,606.516602 
	C177.470840,607.485535 176.859161,608.140503 175.385651,609.718140 
	C175.215027,607.020203 175.111801,605.387878 175.021515,603.349487 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M252.343872,533.964600 
	C252.568283,532.852722 253.142258,531.718323 253.857742,530.289490 
	C254.910492,530.482849 255.821732,530.970581 256.872864,531.754028 
	C255.572998,532.680481 254.133209,533.311279 252.343872,533.964600 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M286.706848,443.107849 
	C286.277313,444.404572 285.516296,445.689758 284.377625,446.985657 
	C283.732086,446.791901 283.464142,446.587402 283.086456,446.149841 
	C284.109589,444.976593 285.242493,444.036438 286.706848,443.107849 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M80.809326,582.796631 
	C79.249756,584.037476 77.479462,585.005554 75.360504,586.003052 
	C75.668655,582.943604 77.578468,581.849731 80.809326,582.796631 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M183.002167,612.368652 
	C183.492432,612.258362 183.986389,612.514160 184.771591,613.215332 
	C185.714752,614.755310 186.366669,615.849976 187.018600,616.944580 
	C182.964569,618.130615 183.838318,614.619568 183.002167,612.368652 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M307.200043,384.916290 
	C307.606812,383.578461 308.402435,382.278198 309.514709,380.870728 
	C310.291901,382.806763 311.912964,385.471039 307.200043,384.916290 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M86.192635,591.204224 
	C87.745453,590.301758 89.513252,589.673828 91.651550,589.077881 
	C90.150566,589.899414 88.279091,590.689026 86.192635,591.204224 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M240.870056,537.677856 
	C240.916534,537.031067 240.963013,536.384338 241.018188,535.415833 
	C242.411987,535.029480 243.797089,534.964905 245.624542,535.019287 
	C246.066864,535.138184 246.093262,535.498840 245.987274,535.791992 
	C244.210861,536.616028 242.540466,537.146912 240.870056,537.677856 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M94.743301,577.058838 
	C93.663597,578.039124 92.190041,578.990051 90.357193,579.969238 
	C91.448418,579.008179 92.898933,578.018799 94.743301,577.058838 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M228.777573,537.753235 
	C227.889954,538.660278 226.770401,539.296204 225.316727,539.940430 
	C224.942535,537.353027 226.031067,536.387634 228.777573,537.753235 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M187.316589,616.976807 
	C186.366669,615.849976 185.714752,614.755310 185.020920,613.341003 
	C184.979004,613.021301 185.212082,612.796875 185.609833,612.836060 
	C187.080414,614.174744 188.153229,615.474182 189.226044,616.773682 
	C189.226044,616.773682 189.006500,616.999329 188.897537,617.115967 
	C188.397247,617.158020 188.005920,617.083496 187.316589,616.976807 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M328.602112,519.000000 
	C328.998871,519.437134 328.998962,519.874268 328.999329,520.638672 
	C327.609680,521.002319 326.219788,521.038757 324.412354,521.067627 
	C325.398346,520.373352 326.801880,519.686646 328.602112,519.000000 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M344.999908,516.360779 
	C345.969482,516.463501 346.938965,516.927002 347.965149,517.744629 
	C347.233063,518.383240 346.444305,518.667786 345.323303,518.913818 
	C344.994019,518.157410 344.996918,517.439514 344.999908,516.360779 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M230.721878,607.195007 
	C231.878616,607.773926 233.035355,608.352844 234.525436,608.991516 
	C234.885178,609.493774 234.911575,609.936340 234.905701,610.711121 
	C233.771744,610.444702 232.670044,609.846008 231.141571,608.837158 
	C230.717148,608.016296 230.719513,607.605652 230.721878,607.195007 
z"
          />
          <path
            fill="#F8F9F9"
            opacity="1.000000"
            stroke="none"
            d="
M302.196899,436.223511 
	C302.781891,435.044586 303.593933,434.132629 304.688354,433.094360 
	C304.543182,434.446899 306.193115,437.427765 302.196899,436.223511 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M266.968872,530.886597 
	C266.226532,530.928467 265.484222,530.970398 264.371307,530.634155 
	C263.998840,529.504822 263.996979,528.753601 263.995117,528.002441 
	C264.857605,528.162781 265.720093,528.323120 266.788940,529.057617 
	C266.986481,530.050110 266.977661,530.468323 266.968872,530.886597 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M245.999191,533.299438 
	C247.442764,533.312195 248.884933,533.621948 250.698425,533.987366 
	C251.050247,534.233765 251.030746,534.424438 250.586823,534.894409 
	C248.806015,535.282043 247.449631,535.390442 246.093262,535.498840 
	C246.093262,535.498840 246.066864,535.138184 246.036530,534.957886 
	C246.003387,534.383911 246.000580,533.990234 245.999191,533.299438 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M297.341187,439.975586 
	C298.083466,438.982727 299.194244,437.998657 300.629425,436.966980 
	C299.872437,437.941040 298.791077,438.962708 297.341187,439.975586 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M245.167160,448.231689 
	C244.999817,447.555939 245.000244,447.111908 245.375702,446.334229 
	C246.500290,446.000427 247.249832,446.000214 247.999390,446.000000 
	C247.999237,446.444183 247.999084,446.888367 247.998550,447.665649 
	C247.110428,448.153625 246.222672,448.308502 245.167160,448.231689 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M281.003204,529.090515 
	C280.598053,529.120728 280.192902,529.151001 279.485413,529.207153 
	C278.586060,529.020630 277.989075,528.808105 277.175720,528.368347 
	C277.432190,527.778564 277.905090,527.415955 278.689026,527.023071 
	C279.551208,527.159485 280.102417,527.326355 280.824097,528.012329 
	C280.997467,528.717773 281.000336,528.904175 281.003204,529.090515 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M422.212036,446.926514 
	C422.676117,445.971344 423.494751,445.009186 424.656616,444.028564 
	C424.188812,444.979919 423.377716,445.949707 422.212036,446.926514 
z"
          />
          <path
            fill="#F8F9F9"
            opacity="1.000000"
            stroke="none"
            d="
M452.285156,424.299011 
	C454.184753,424.144653 456.360168,424.268982 458.791870,424.704041 
	C456.885773,424.869110 454.723389,424.723419 452.285156,424.299011 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M241.205994,593.107971 
	C241.962982,593.560852 242.625854,594.401672 243.277405,595.615356 
	C242.548004,595.157532 241.829926,594.326660 241.205994,593.107971 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M429.005798,446.353821 
	C429.662628,446.765350 430.325256,447.529449 430.993347,448.646179 
	C430.336365,448.234650 429.673981,447.470551 429.005798,446.353821 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M294.280396,441.922180 
	C294.702698,441.250488 295.486023,440.609985 296.618530,439.965942 
	C296.192291,440.626068 295.416840,441.289734 294.280396,441.922180 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M263.705994,528.147583 
	C263.996979,528.753601 263.998840,529.504822 263.999512,530.631836 
	C263.557861,531.024902 263.117462,531.042114 262.346680,531.054932 
	C262.483185,530.131226 262.950012,529.211975 263.705994,528.147583 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M69.869354,586.128723 
	C69.690598,586.741211 69.167839,587.341003 68.326782,587.975464 
	C68.514099,587.378784 69.019722,586.747375 69.869354,586.128723 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M86.777313,580.082825 
	C86.633804,580.697754 86.152557,581.329346 85.354431,582.015625 
	C85.504883,581.413391 85.972229,580.756470 86.777313,580.082825 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M214.669739,570.908264 
	C215.088074,570.907104 215.275177,570.950317 215.745239,571.049438 
	C215.696838,571.590332 215.365494,572.075134 215.034119,572.559937 
	C214.713959,572.257385 214.393829,571.954773 214.029953,571.367981 
	C213.986206,571.083801 214.438492,570.952576 214.669739,570.908264 
z"
          />
          <path
            fill="#F8F9F9"
            opacity="1.000000"
            stroke="none"
            d="
M337.997559,432.005951 
	C338.299225,432.613342 338.600891,433.220734 338.902557,433.828125 
	C338.616241,433.715149 338.329895,433.602142 338.019470,432.992493 
	C337.995392,432.495819 337.997559,432.005951 337.997559,432.005951 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M394.311096,438.954529 
	C393.976929,438.805145 393.963959,438.616394 394.064087,437.893311 
	C394.750336,437.205353 395.323486,437.051697 395.896606,436.898071 
	C395.896606,436.898041 396.015015,436.956787 396.069916,436.995422 
	C395.627319,437.661102 395.129791,438.288147 394.311096,438.954529 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M342.864014,440.780579 
	C342.473877,441.058990 342.053101,441.039642 341.315186,441.014709 
	C340.998108,441.009155 340.820343,441.006409 340.776978,440.761841 
	C340.654449,440.333313 340.575348,440.149384 340.496246,439.965485 
	C340.623932,439.766357 340.751617,439.567200 341.130890,439.117462 
	C341.866089,439.405518 342.349731,439.944183 342.864014,440.780579 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M267.267151,530.904236 
	C266.977661,530.468323 266.986481,530.050110 266.998260,529.317139 
	C267.445679,529.000671 267.890106,528.998901 268.667908,528.992432 
	C269.003082,529.161072 269.004974,529.334412 269.006653,529.767944 
	C268.526123,530.326111 268.045746,530.623962 267.267151,530.904236 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M281.170197,529.292603 
	C281.000336,528.904175 280.997467,528.717773 280.994690,528.251465 
	C281.543549,528.113586 282.092346,528.255554 282.816467,528.623108 
	C282.440277,529.064026 281.888733,529.279358 281.170197,529.292603 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M338.452393,444.622070 
	C338.339569,445.057678 338.064850,445.324585 337.772675,445.570831 
	C337.765198,445.577118 337.566711,445.356812 337.457336,445.242188 
	C337.734314,444.978333 338.011261,444.714478 338.452393,444.622070 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M81.132500,592.148926 
	C81.457520,591.629578 81.943115,591.347717 82.723732,591.084900 
	C82.443527,591.531494 81.868294,591.958923 81.132500,592.148926 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M340.298035,440.005920 
	C340.575348,440.149384 340.654449,440.333313 340.707214,440.816956 
	C340.487183,440.759857 340.293518,440.403076 340.298035,440.005920 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M377.162354,443.890015 
	C377.035858,443.631836 377.235474,443.398041 377.779816,443.226624 
	C377.861237,443.463318 377.649170,443.671814 377.162354,443.890015 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M337.836273,432.093781 
	C337.997559,432.005951 337.995392,432.495819 337.994110,432.740540 
	C337.825928,432.822144 337.643372,432.670715 337.505096,432.486145 
	C337.476746,432.448303 337.614044,432.286377 337.836273,432.093781 
z"
          />
          <path
            fill="#F8F9F9"
            opacity="1.000000"
            stroke="none"
            d="
M338.212891,366.890381 
	C338.100769,366.680298 338.288086,366.421387 338.775757,366.115417 
	C338.888580,366.326813 338.700653,366.584564 338.212891,366.890381 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M305.174133,385.872253 
	C305.140045,385.705292 305.334534,385.442352 305.764526,385.091400 
	C305.887787,385.328156 305.688690,385.585785 305.174133,385.872253 
z"
          />
          <path
            fill="#538ACD"
            opacity="1.000000"
            stroke="none"
            d="
M613.088623,139.991287 
	C613.085815,142.770477 611.428101,143.142410 609.100281,142.950882 
	C596.881653,141.945602 584.657654,141.005600 572.433289,140.071396 
	C566.817261,139.642212 561.140015,139.626572 555.601746,138.733643 
	C552.939331,138.304367 549.495728,136.934860 548.180115,134.864014 
	C538.654541,119.869743 529.582275,104.587440 519.891724,88.613632 
	C536.331177,89.564995 553.649231,86.644974 569.400696,97.186172 
	C569.775330,97.207390 569.806763,97.222771 569.868164,97.552780 
	C573.272034,100.274261 576.614502,102.665741 579.976318,105.027565 
	C579.995605,104.997917 579.942383,105.045158 579.982300,105.386429 
	C582.368347,107.734322 584.714539,109.740944 587.331543,111.915382 
	C588.660400,113.101006 589.718384,114.118813 590.846924,115.471512 
	C596.265442,121.218262 601.613403,126.630112 606.961365,132.041977 
	C607.569702,132.846741 608.178101,133.651505 608.968384,135.002197 
	C610.384644,136.771347 611.619019,137.994583 612.879517,139.421127 
	C612.905640,139.624435 613.088623,139.991287 613.088623,139.991287 
M594.953064,127.244041 
	C588.974731,121.479759 582.996460,115.715469 576.926392,109.228607 
	C571.438416,104.936508 565.953308,100.640800 560.453308,96.364159 
	C560.209106,96.174278 559.836121,96.110489 559.512573,96.067986 
	C551.642639,95.034187 543.791809,93.784744 535.892517,93.072556 
	C531.256104,92.654541 529.456848,95.581772 531.336548,99.990425 
	C532.287903,102.221619 533.662964,104.318642 535.111633,106.280472 
	C537.329773,109.284332 540.070923,111.932213 542.062195,115.066528 
	C545.567749,120.584328 548.674622,126.355400 552.361572,132.699310 
	C554.020813,133.138138 555.656677,133.742844 557.344238,133.980148 
	C560.546387,134.430450 563.778137,134.670303 567.767761,135.111069 
	C578.483276,136.409973 589.185303,137.844421 599.923462,138.917038 
	C602.777344,139.202103 604.024109,136.581665 602.508057,134.559402 
	C600.553345,131.952148 597.580811,130.107986 594.953064,127.244041 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M590.776428,115.136620 
	C589.718384,114.118813 588.660400,113.101006 587.281738,111.595612 
	C584.621582,109.087067 582.281982,107.066116 579.942383,105.045166 
	C579.942383,105.045158 579.995605,104.997917 579.961548,104.674362 
	C576.553894,101.974792 573.180359,99.598785 569.806763,97.222778 
	C569.806763,97.222771 569.775330,97.207390 569.756348,97.216171 
	C569.166321,96.072685 568.595276,94.920433 567.958740,93.453018 
	C570.085754,92.558960 572.254150,91.626648 574.475891,91.475174 
	C579.010132,91.166054 583.821289,90.326187 588.066711,91.434792 
	C594.694153,93.165413 601.211426,95.826416 607.297302,99.007721 
	C617.382568,104.279655 626.846436,110.760826 637.013855,115.850098 
	C649.938843,122.319672 659.447021,133.439941 671.905090,140.471909 
	C672.708801,140.925568 672.541504,143.099487 672.387817,144.843842 
	C665.773499,140.243591 659.890442,134.841873 653.357910,130.394089 
	C640.914124,121.921532 628.253479,113.740158 615.387451,105.924179 
	C606.015564,100.230835 595.912964,95.986259 584.867737,94.611671 
	C583.756226,94.473343 582.538391,95.188774 581.369934,95.507996 
	C581.966553,96.666847 582.263794,98.233490 583.204285,98.923973 
	C599.923645,111.198952 616.014526,124.164055 629.071472,140.460220 
	C632.688049,144.974014 636.653320,148.077682 642.937500,147.231415 
	C644.945557,146.961014 647.131348,148.046860 649.240845,148.487976 
	C659.669983,150.668869 670.100769,152.841629 680.754028,155.063782 
	C680.013977,153.446503 679.295166,151.875763 678.576416,150.305023 
	C681.112000,150.151688 683.880615,148.923752 685.028748,152.857254 
	C686.088135,156.486572 685.466858,158.447876 681.332214,157.981583 
	C677.581604,157.558578 674.071960,157.596313 670.095825,156.072388 
	C665.838806,154.440811 660.749878,152.650650 655.755005,151.934677 
	C648.504089,150.895248 641.103882,150.098831 634.180786,147.898163 
	C630.580688,146.753830 627.671448,143.013809 624.792175,140.107452 
	C617.418762,132.664734 610.287109,124.982773 602.937927,117.515404 
	C601.368896,115.921135 599.360596,114.696426 597.379639,113.624908 
	C596.997131,113.417976 595.799011,114.718842 594.602417,115.638062 
	C593.077271,115.680168 591.926819,115.408394 590.776428,115.136620 
z"
          />
          <path
            fill="#72A1D2"
            opacity="1.000000"
            stroke="none"
            d="
M457.644226,92.772141 
	C457.310516,91.089470 457.314514,89.632469 457.314697,87.817291 
	C461.774414,86.693283 466.212402,85.593338 470.708160,85.250092 
	C475.802124,84.861160 480.947906,85.150444 486.536224,85.077888 
	C487.444305,85.042854 487.887299,85.074776 488.661987,85.070343 
	C489.116547,85.146416 489.252991,85.368904 489.360260,85.355621 
	C499.987854,84.039314 503.274170,92.552109 507.054840,99.403816 
	C512.734558,109.697182 517.544006,120.481766 522.478760,131.170486 
	C524.928467,136.476624 523.786194,138.001343 517.856628,137.999146 
	C501.372406,137.993011 484.887726,137.951569 468.404144,137.816727 
	C462.238647,137.766296 461.299774,136.550171 460.998871,130.476898 
	C460.415802,118.707581 459.679871,106.945648 458.944885,95.184547 
	C458.898254,94.438499 458.315369,93.725960 457.644226,92.772141 
M501.093445,96.464035 
	C498.741852,92.715164 496.209198,89.612907 491.017242,89.664246 
	C483.243164,89.741104 475.460449,89.050392 467.688843,89.169647 
	C466.419189,89.189133 464.397217,91.524742 464.064056,93.077576 
	C461.480225,105.120827 463.449005,117.125450 465.146027,129.067703 
	C465.357758,130.557800 467.653778,132.842865 469.063446,132.903168 
	C483.472290,133.519638 497.897247,133.855103 512.319031,133.910263 
	C513.576294,133.915085 515.972168,131.286545 515.879822,130.024689 
	C515.661560,127.044502 514.559753,124.013046 513.282898,121.248184 
	C509.521729,113.103561 505.501709,105.078453 501.093445,96.464035 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M678.415527,150.274597 
	C679.295166,151.875763 680.013977,153.446503 680.754028,155.063782 
	C670.100769,152.841629 659.669983,150.668869 649.240845,148.487976 
	C647.131348,148.046860 644.945557,146.961014 642.937500,147.231415 
	C636.653320,148.077682 632.688049,144.974014 629.071472,140.460220 
	C616.014526,124.164055 599.923645,111.198952 583.204285,98.923973 
	C582.263794,98.233490 581.966553,96.666847 581.369934,95.507996 
	C582.538391,95.188774 583.756226,94.473343 584.867737,94.611671 
	C595.912964,95.986259 606.015564,100.230835 615.387451,105.924179 
	C628.253479,113.740158 640.914124,121.921532 653.357910,130.394089 
	C659.890442,134.841873 665.773499,140.243591 672.283569,145.261383 
	C672.617371,145.308228 672.685974,145.282990 672.759155,145.601349 
	C674.543274,147.310303 676.254150,148.700897 677.965027,150.091476 
	C677.965027,150.091476 678.254700,150.244171 678.415527,150.274597 
M624.977783,130.705139 
	C625.289062,131.147369 625.600342,131.589600 626.215271,132.630920 
	C631.044556,136.538284 635.330444,141.812454 640.841980,144.003525 
	C647.578064,146.681412 655.337341,146.804550 662.670105,147.928604 
	C663.317810,148.027893 664.718628,147.276672 664.690369,147.062439 
	C664.510498,145.694931 664.454651,143.890152 663.575745,143.104126 
	C658.063965,138.175232 652.740784,132.880295 646.613953,128.826950 
	C634.371216,120.727463 621.720337,113.232994 609.095764,105.727982 
	C603.420532,102.354187 597.413391,99.523552 590.304077,99.356422 
	C590.567322,100.175713 590.570068,100.578308 590.770752,100.751892 
	C599.185730,108.028862 607.617188,115.286797 616.049622,122.543617 
	C619.000427,125.083015 621.958923,127.613457 624.977783,130.705139 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M584.968018,161.931427 
	C578.639282,162.369049 572.316589,162.244247 566.003418,162.433884 
	C563.874573,162.497833 561.769653,163.359512 560.146545,163.740845 
	C564.983765,180.791595 569.582214,197.000946 574.141113,213.604294 
	C573.017639,213.181091 571.933838,212.363876 570.452576,211.246948 
	C570.452576,213.190155 570.452576,214.831940 570.452576,216.473724 
	C569.927979,216.571732 569.403381,216.669724 568.878784,216.767715 
	C568.382080,213.959915 566.614868,210.526566 567.630676,208.463470 
	C570.494324,202.647324 567.008728,198.331375 565.489563,193.507294 
	C565.205811,192.606506 564.332581,191.884293 564.079590,190.982056 
	C562.493896,185.325974 561.280945,179.549225 559.397095,173.998230 
	C557.981628,169.827042 555.746460,165.934036 553.526794,161.166824 
	C562.157593,160.141724 571.165649,159.025879 580.186951,158.030228 
	C582.669067,157.756302 585.001587,158.101120 584.968018,161.931427 
z"
          />
          <path
            fill="#4377BC"
            opacity="1.000000"
            stroke="none"
            d="
M529.147400,264.265076 
	C530.181763,264.207123 531.216125,264.149170 532.853333,264.328918 
	C534.693054,264.213409 535.929993,263.860199 537.166870,263.507019 
	C542.142090,263.395081 547.117310,263.283173 553.012329,263.221405 
	C556.994629,263.503418 560.057007,263.735291 563.104980,264.261627 
	C563.045471,265.367065 563.000427,266.178040 562.516968,266.982971 
	C558.396973,266.665344 554.715454,266.353729 550.627319,266.021881 
	C549.135986,266.015259 548.051208,266.028870 546.540344,266.029419 
	C539.442200,266.671997 532.777161,267.423981 526.093018,267.915619 
	C524.418091,268.038788 522.681641,267.325012 521.250366,266.717957 
	C524.067322,265.714996 526.607361,264.990051 529.147400,264.265076 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M648.829956,192.887909 
	C647.998779,192.765320 646.948425,192.403992 646.533081,191.678955 
	C641.877319,183.551544 637.319336,175.368118 632.699402,166.870178 
	C634.083130,166.141296 635.502502,165.742004 637.318298,165.231186 
	C637.318298,164.369675 637.318298,163.020828 637.318298,161.474915 
	C638.035767,161.575714 638.747070,161.490906 638.873901,161.724106 
	C639.573425,163.009766 640.418701,164.318375 640.684448,165.719528 
	C641.670410,170.918091 642.449280,176.155914 643.364624,181.736847 
	C645.756104,180.979477 646.997681,180.586288 647.617188,180.390091 
	C648.067627,184.616257 648.486694,188.548264 648.829956,192.887909 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M580.147827,238.907837 
	C582.502930,239.498093 584.469543,240.745651 586.362793,240.642960 
	C596.274536,240.105316 606.167725,239.225342 616.525024,238.548477 
	C616.866760,239.110138 616.866943,239.929642 616.617615,240.014252 
	C612.568604,241.388565 608.531982,243.574051 604.404907,243.774963 
	C596.879333,244.141373 589.292175,243.382156 581.739990,242.935104 
	C580.354309,242.853073 579.012817,242.024597 577.650940,241.540680 
	C578.342468,240.674500 579.033997,239.808319 580.147827,238.907837 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M563.119446,263.967163 
	C560.057007,263.735291 556.994629,263.503418 553.478149,263.165619 
	C553.472595,262.436951 553.885315,261.301422 554.375061,261.267120 
	C564.377197,260.566772 574.388245,259.992371 584.704529,259.970398 
	C585.020630,260.743286 585.029663,260.938538 584.716309,261.142395 
	C583.966614,261.185181 583.539307,261.219330 582.666504,261.144470 
	C577.815735,261.792511 573.410461,262.549591 568.577393,263.309753 
	C566.472839,263.530945 564.796143,263.749054 563.119446,263.967163 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M632.975830,237.824585 
	C640.880249,236.518265 648.980713,235.561493 657.536865,234.678589 
	C656.667297,235.833817 655.493835,237.494858 653.988831,237.888672 
	C649.335632,239.106277 644.585388,240.223404 639.812317,240.589432 
	C637.673096,240.753479 635.389771,239.037170 632.975830,237.824585 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M609.036560,256.072479 
	C614.155518,256.118530 619.274414,256.164612 624.766418,256.595459 
	C625.032593,257.529572 624.925720,258.078918 624.467407,258.690369 
	C623.402954,258.741211 622.690002,258.729950 621.683228,258.478455 
	C618.844299,258.697998 616.299255,259.157715 613.452881,259.647034 
	C612.748291,259.686127 612.344910,259.695587 611.738647,259.499329 
	C610.691711,259.529449 609.847656,259.765350 608.554688,260.029907 
	C604.414856,260.330811 600.723877,260.603027 597.028564,260.586578 
	C597.012756,260.105804 597.001282,259.913666 596.989807,259.721527 
	C598.088928,259.155548 599.187988,258.589569 601.036926,258.009094 
	C604.203369,257.353912 606.619934,256.713196 609.036560,256.072479 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M630.935303,165.738342 
	C623.484863,165.413345 615.968201,164.706390 608.126709,163.740753 
	C611.753113,160.097794 622.249817,159.949173 626.312805,163.054153 
	C627.641846,164.069809 629.339844,164.602707 630.935303,165.738342 
z"
          />
          <path
            fill="#F8F9F9"
            opacity="1.000000"
            stroke="none"
            d="
M471.055603,262.687378 
	C469.933136,263.047546 468.866028,263.051636 467.398804,263.050537 
	C466.154785,261.339996 465.310944,259.634705 464.234283,257.458923 
	C469.067810,257.458923 473.655121,257.458923 478.566132,257.458923 
	C475.877869,259.215851 473.494415,260.773560 471.055603,262.687378 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M574.123291,217.002563 
	C576.380249,223.257996 578.292786,229.728973 580.146118,236.598999 
	C578.489563,235.339554 575.920105,233.900650 575.486023,231.979263 
	C574.400574,227.175552 574.284058,222.152893 574.123291,217.002563 
z"
          />
          <path
            fill="#BCD0E6"
            opacity="1.000000"
            stroke="none"
            d="
M661.047852,168.085236 
	C662.091309,168.086487 663.134766,168.087753 664.864868,168.367905 
	C668.003723,168.814270 670.455872,168.981750 672.908020,169.149231 
	C675.529419,169.998932 678.150879,170.848663 680.902588,172.375809 
	C681.011292,173.260025 680.989746,173.466812 680.547363,173.516113 
	C677.748291,173.227615 675.369995,173.096558 672.743164,172.691345 
	C668.629211,171.488388 664.763794,170.559616 660.914185,169.371918 
	C660.969360,168.770401 661.008606,168.427826 661.047852,168.085236 
z"
          />
          <path
            fill="#BED4EB"
            opacity="1.000000"
            stroke="none"
            d="
M590.846924,115.471512 
	C591.926819,115.408394 593.077271,115.680168 594.578003,116.007538 
	C598.081116,119.612007 601.233887,123.160889 604.726624,127.181244 
	C605.680664,128.128799 606.294617,128.604858 606.908569,129.080933 
	C606.954163,129.821671 606.999695,130.562408 607.003296,131.672562 
	C601.613403,126.630112 596.265442,121.218262 590.846924,115.471512 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M645.654541,252.046295 
	C644.687195,252.721680 643.230225,253.349457 641.797424,254.028091 
	C640.515198,254.635406 639.259521,255.298782 638.023193,256.315308 
	C637.606750,256.774750 637.159241,256.856354 636.294678,256.502899 
	C633.919250,255.717651 631.960876,255.367493 630.002441,255.017334 
	C631.413147,254.012161 632.742981,252.316208 634.250732,252.139709 
	C637.847656,251.718597 641.528564,252.014465 645.654541,252.046295 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M660.804749,167.796753 
	C661.008606,168.427826 660.969360,168.770401 660.557617,169.400940 
	C658.532043,169.544205 656.878906,169.399490 655.225769,169.254791 
	C654.121765,169.192703 653.017822,169.130615 651.501465,169.100922 
	C651.179871,167.892593 651.270630,166.651871 651.402222,164.853485 
	C654.763855,165.827820 657.662720,166.668045 660.804749,167.796753 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M666.882629,199.301239 
	C668.066345,200.671600 669.080139,202.413773 670.128784,204.514832 
	C669.494019,206.089752 668.824402,207.305801 667.892517,208.998093 
	C664.890991,206.020218 665.880188,202.833908 666.882629,199.301239 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M613.415405,140.034851 
	C613.088623,139.991287 612.905640,139.624435 612.914429,139.074463 
	C612.303040,137.334839 611.682922,136.145157 611.062744,134.955475 
	C615.087646,135.000031 616.269470,138.104141 616.763672,141.091873 
	C616.945923,142.193878 614.830994,143.675842 613.372925,144.981384 
	C613.235352,143.344986 613.488770,141.711700 613.415405,140.034851 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M600.929810,162.837891 
	C596.834656,163.145752 592.669922,163.033768 588.149780,162.695496 
	C592.149719,162.452148 596.505005,162.435104 600.929810,162.837891 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M596.533813,259.788330 
	C597.001282,259.913666 597.012756,260.105804 596.668518,260.796509 
	C592.554810,261.241364 588.796753,261.187592 585.038696,261.133789 
	C585.029663,260.938538 585.020630,260.743286 585.008728,260.254578 
	C588.696533,259.925812 592.387146,259.890472 596.533813,259.788330 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M680.968262,173.673584 
	C680.989746,173.466812 681.011292,173.260025 681.046753,172.744141 
	C681.422424,172.414276 681.784180,172.393494 682.412842,172.415665 
	C684.785583,173.615479 686.891296,174.772339 688.713379,176.370728 
	C685.942566,175.766037 683.455383,174.719803 680.968262,173.673584 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M608.762939,255.775238 
	C606.619934,256.713196 604.203369,257.353912 601.393372,257.993073 
	C601.919800,252.455841 605.763794,255.633133 608.762939,255.775238 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M621.040894,238.892029 
	C623.570435,238.217117 626.347473,237.905533 629.560181,237.653687 
	C627.784485,242.130508 624.342896,239.599960 621.040894,238.892029 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M629.675232,255.073700 
	C631.960876,255.367493 633.919250,255.717651 635.958069,256.429321 
	C634.281128,257.103882 632.523743,257.416931 630.273926,257.593109 
	C629.206116,257.126312 628.630798,256.796356 628.055481,256.466400 
	C628.486328,256.020966 628.917175,255.575516 629.675232,255.073700 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M672.866150,168.754318 
	C670.455872,168.981750 668.003723,168.814270 665.255981,168.383316 
	C667.581665,168.199677 670.202942,168.279541 672.866150,168.754318 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M677.976746,149.712006 
	C676.254150,148.700897 674.543274,147.310303 672.792969,145.587326 
	C674.498535,146.614151 676.243469,147.973343 677.976746,149.712006 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M627.713867,256.404938 
	C628.630798,256.796356 629.206116,257.126312 629.906494,257.678650 
	C629.536682,258.129791 629.041931,258.358582 627.947327,258.780792 
	C626.504639,258.858887 625.661743,258.743561 624.818848,258.628235 
	C624.925720,258.078918 625.032593,257.529572 625.238098,256.726074 
	C626.015259,256.429047 626.693726,256.386261 627.713867,256.404938 
z"
          />
          <path
            fill="#BED4EB"
            opacity="1.000000"
            stroke="none"
            d="
M610.762146,134.927109 
	C611.682922,136.145157 612.303040,137.334839 612.888306,138.871155 
	C611.619019,137.994583 610.384644,136.771347 609.206787,135.208725 
	C609.662659,134.879150 610.062134,134.888947 610.762146,134.927109 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M536.782288,263.317993 
	C535.929993,263.860199 534.693054,264.213409 533.242310,264.330627 
	C533.019348,263.900330 533.010254,263.706055 532.998901,263.219666 
	C534.130310,262.994720 535.264038,263.061829 536.782288,263.317993 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M563.520142,212.013092 
	C562.945190,212.405716 562.374023,212.502106 561.802917,212.598480 
	C561.808228,212.193878 561.813599,211.789276 561.818970,211.384674 
	C562.387329,211.495392 562.955688,211.606125 563.520142,212.013092 
z"
          />
          <path
            fill="#DEE8F0"
            opacity="1.000000"
            stroke="none"
            d="
M606.896606,128.751282 
	C606.294617,128.604858 605.680664,128.128799 605.019897,127.340225 
	C605.610291,127.492371 606.247437,127.957008 606.896606,128.751282 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M400.813141,303.324219 
	C399.785675,303.273682 398.758179,303.223145 397.355713,302.921112 
	C396.983521,302.485565 396.986328,302.301544 397.271149,301.806274 
	C406.684235,301.021851 415.815277,300.548676 425.260162,300.354004 
	C433.045746,300.760376 440.517487,300.888275 448.458435,301.028381 
	C468.068176,301.034668 487.209320,301.119049 506.348816,300.978088 
	C513.897095,300.922485 521.441650,300.364594 529.412476,300.030762 
	C537.579773,299.354401 545.345215,298.871429 553.057556,297.946198 
	C558.418457,297.303070 563.706482,296.052246 569.274170,295.061279 
	C569.521362,295.049683 570.016174,295.025543 570.441528,295.028076 
	C572.563904,295.001373 574.260925,294.972137 576.314392,294.958984 
	C577.752441,295.245636 578.833984,295.516205 579.915527,295.786774 
	C574.784241,296.819824 569.694885,298.381744 564.514221,298.792908 
	C543.848816,300.433014 523.150696,301.657013 502.475159,303.176788 
	C498.191681,303.491669 493.952789,304.412628 489.323792,305.268982 
	C482.685394,305.771881 476.418427,306.201141 470.146759,306.288391 
	C467.040161,306.331635 463.926544,305.507965 460.813080,305.484772 
	C446.532715,305.378326 432.251282,305.416046 417.506165,305.457642 
	C414.689423,305.315521 412.336761,305.117737 409.422485,304.526855 
	C406.178314,303.863922 403.495728,303.594055 400.813141,303.324219 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M550.987915,350.899719 
	C529.586731,351.282593 508.199829,351.212769 486.814056,351.337067 
	C484.694702,351.349396 482.582214,352.547485 480.153809,353.309082 
	C477.317078,352.009216 478.223022,350.743164 480.237366,350.142059 
	C482.423065,349.489838 484.762695,349.058289 487.037720,349.047150 
	C504.651398,348.960968 522.266479,349.087585 539.879211,348.929871 
	C543.639526,348.896149 547.391907,347.970398 551.331909,347.430389 
	C551.299255,347.735413 551.150879,349.118469 550.987915,350.899719 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M379.257904,354.408722 
	C370.032715,354.290680 360.807343,354.084869 351.582458,354.105652 
	C348.643188,354.112244 345.705261,354.720703 342.336121,355.030670 
	C341.472961,354.332458 341.040436,353.657318 340.617249,352.711548 
	C340.626648,352.440887 340.962494,352.489807 341.381409,352.409424 
	C342.218567,352.028900 342.636871,351.728790 343.055176,351.428650 
	C344.106659,351.280457 345.158173,351.132294 346.988861,351.002686 
	C348.854340,350.930084 349.940643,350.838867 351.026947,350.747681 
	C358.319977,350.893768 365.613007,351.039856 373.477356,351.542084 
	C375.785095,352.735046 377.521484,353.571899 379.257904,354.408722 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M484.093689,371.380188 
	C489.941315,370.921509 495.882355,370.730011 501.813202,370.898102 
	C503.062439,370.933472 504.274353,372.288147 505.449280,373.482849 
	C504.346741,374.641724 503.382080,375.583405 502.227692,376.002563 
	C500.919189,376.477661 498.329803,376.941589 498.200531,376.627350 
	C495.757355,370.688843 490.618103,373.770416 486.686646,372.954498 
	C485.808502,372.772247 485.015625,372.179260 484.093689,371.380188 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M513.357910,306.956970 
	C513.864746,306.285034 514.815796,305.142853 515.844055,305.068512 
	C522.586365,304.580994 529.345276,304.324188 536.548218,303.997864 
	C537.134827,307.175812 535.074890,307.972900 532.518799,307.880920 
	C526.289124,307.656677 520.064514,307.289978 513.357910,306.956970 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M432.680786,361.005676 
	C431.603668,364.917175 430.147736,368.833557 428.384064,372.947327 
	C425.287048,364.649628 425.747345,363.327942 432.680786,361.005676 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M469.376465,384.925476 
	C467.348969,380.251190 465.677795,375.505829 463.966614,370.384949 
	C472.310974,376.467712 473.189453,378.713806 469.376465,384.925476 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M573.997681,348.989197 
	C573.280884,349.647858 572.618347,349.928955 571.691406,350.244690 
	C570.789062,349.292480 570.151123,348.305695 569.513123,347.318909 
	C571.600281,346.374481 573.687439,345.430084 575.774658,344.485657 
	C576.023315,344.890289 576.271973,345.294952 576.520691,345.699585 
	C575.697754,346.670288 574.874817,347.640961 573.997681,348.989197 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M425.790771,378.081024 
	C425.420044,380.376892 424.639069,382.642242 423.523560,385.017273 
	C421.463593,382.007599 420.406830,379.095276 425.790771,378.081024 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M392.991821,358.613678 
	C391.039886,357.829803 389.005524,356.762604 386.985535,355.330811 
	C388.478027,355.234589 389.956207,355.502899 391.547150,356.085083 
	C392.076416,357.042755 392.492920,357.686554 392.991821,358.613678 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M379.588470,354.449463 
	C377.521484,353.571899 375.785095,352.735046 373.932587,351.579132 
	C375.257874,351.170776 376.699219,351.081543 378.721252,351.245300 
	C379.896606,352.311615 380.491364,353.124878 381.086090,353.938141 
	C380.697083,354.122162 380.308044,354.306183 379.588470,354.449463 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M381.442383,354.127167 
	C380.491364,353.124878 379.896606,352.311615 379.153564,351.264160 
	C380.385712,351.741302 381.766235,352.452667 383.170227,353.439545 
	C382.728729,353.915436 382.263702,354.115814 381.442383,354.127167 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M416.671600,401.026642 
	C416.673950,402.351013 416.261658,403.661743 415.411743,404.729004 
	C414.294189,403.978912 413.614288,403.472260 412.934448,402.965576 
	C412.931396,402.533722 412.928345,402.101898 412.935425,401.346741 
	C414.049347,401.019958 415.153168,401.016479 416.671600,401.026642 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M351.009430,350.392273 
	C349.940643,350.838867 348.854340,350.930084 347.373169,350.897705 
	C347.608734,349.877045 348.239136,348.979950 348.869537,348.082886 
	C349.576996,348.734222 350.284454,349.385529 351.009430,350.392273 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M479.970093,368.960815 
	C480.783813,369.382416 481.254211,369.859009 481.902130,370.519806 
	C481.005371,370.850037 479.931061,370.996124 478.856781,371.142181 
	C479.113434,370.433380 479.370117,369.724609 479.970093,368.960815 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M489.052429,307.400391 
	C490.049438,307.035309 491.121552,307.007385 492.595428,306.986145 
	C492.185028,309.723572 490.830200,309.634399 489.052429,307.400391 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M479.086273,363.075134 
	C479.582977,364.042938 479.669128,365.032745 479.728729,366.393555 
	C478.880951,366.415497 478.059723,366.066406 477.238525,365.717316 
	C477.717590,364.843933 478.196655,363.970551 479.086273,363.075134 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M342.927673,351.126434 
	C342.636871,351.728790 342.218567,352.028900 341.537231,352.342346 
	C341.782837,351.845154 342.291504,351.334656 342.927673,351.126434 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M385.812988,354.010925 
	C385.867737,354.064911 385.515808,354.369629 385.413879,354.159271 
	C385.311951,353.948914 385.758240,353.956909 385.812988,354.010925 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M831.641968,425.714966 
	C827.358337,425.693146 823.529114,425.625641 819.700867,425.662476 
	C816.577393,425.692505 815.161621,424.293732 815.255066,421.117737 
	C815.367615,417.292358 815.203308,413.459442 815.279785,409.632233 
	C815.361023,405.562531 816.985596,402.964630 821.608398,403.320038 
	C826.221497,403.674744 831.088989,403.092346 835.404968,404.387238 
	C841.968567,406.356476 844.024292,411.030060 842.919250,418.110168 
	C842.246826,422.418518 838.280457,425.205475 831.641968,425.714966 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M815.271729,378.968964 
	C815.327698,369.975616 815.361267,369.927307 823.915833,370.038574 
	C826.904602,370.077423 829.898438,370.122253 832.878723,370.327362 
	C837.115356,370.618988 839.768250,373.785553 839.816162,378.402618 
	C839.863098,382.933411 837.179810,386.525635 833.156982,386.828857 
	C829.687195,387.090363 826.185852,386.948212 822.698364,386.956268 
	C815.392151,386.973145 815.293396,386.865356 815.271729,378.968964 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M798.889160,440.649780 
	C798.214355,439.805298 797.028259,438.485352 797.023193,437.160858 
	C796.922058,410.742767 796.963501,384.324188 796.956726,357.905701 
	C796.955933,354.771149 797.680908,351.915283 801.398315,351.991638 
	C813.149658,352.232880 824.895630,352.743652 836.642090,353.190033 
	C837.080872,353.206757 837.503845,353.638824 838.130493,354.182251 
	C829.132446,354.342316 819.937805,354.062378 810.743774,354.084259 
	C797.043518,354.116852 798.947083,352.307251 798.917847,365.956543 
	C798.864807,390.694977 798.918579,415.433655 798.889160,440.649780 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M832.867004,441.343384 
	C832.978638,441.873688 832.622375,442.514740 832.272034,442.511505 
	C821.545105,442.413574 810.818726,442.252472 799.895752,441.767120 
	C810.600037,441.340027 821.500916,441.246918 832.867004,441.343384 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M849.111938,359.237671 
	C849.781128,359.314911 850.433838,359.738525 851.143311,360.468994 
	C850.498596,360.378632 849.796997,359.981354 849.111938,359.237671 
z"
          />
          <path
            fill="#236AD7"
            opacity="1.000000"
            stroke="none"
            d="
M583.946777,228.966644 
	C583.638123,227.882126 583.329468,226.797607 583.218262,225.199097 
	C582.942139,224.120483 582.468628,223.555847 581.995117,222.991180 
	C580.951416,219.614365 579.836243,216.257462 578.886841,212.854340 
	C578.147522,210.203964 577.614441,207.496078 577.393188,204.417236 
	C578.537720,204.906281 579.144043,205.979828 580.053040,206.631058 
	C581.261414,207.496796 582.835205,208.767471 584.021484,208.558121 
	C586.473083,208.125397 588.772278,206.829025 591.140076,205.885559 
	C594.477966,201.830093 597.367371,196.739426 601.657043,193.507233 
	C607.028015,189.460297 609.675903,184.257874 612.005615,177.897034 
	C613.818909,178.365982 615.671997,178.946869 617.567017,179.308395 
	C619.706726,179.716599 623.519836,180.660919 623.770691,180.101379 
	C625.070435,177.202103 629.259155,175.103500 626.981567,170.989792 
	C628.333191,172.396378 629.684753,173.802963 631.025391,175.996582 
	C631.840332,179.363937 632.537781,181.994598 633.523193,184.512543 
	C634.915833,188.070892 636.417236,191.595657 638.064026,195.043137 
	C639.827148,198.734070 640.962402,204.070801 643.917236,205.504929 
	C647.587341,207.286224 649.172302,208.674576 649.036743,212.516968 
	C648.956909,214.780991 649.268677,217.058838 649.317810,217.881958 
	C646.596924,219.810226 644.645874,221.258072 642.625366,222.601547 
	C641.311890,223.474945 639.957214,224.721802 638.516785,224.885803 
	C634.433533,225.350693 630.279724,225.168198 626.184692,225.562592 
	C618.585571,226.294510 610.615906,226.327469 605.947144,234.087051 
	C602.945374,234.086823 599.943665,234.086594 596.477783,233.677826 
	C595.594604,231.374786 595.492798,229.319656 594.644226,227.642410 
	C594.082397,226.532135 592.449585,225.963791 591.296204,225.152832 
	C590.529358,226.252594 589.395142,227.251343 589.060181,228.469833 
	C587.709351,233.384125 585.782471,230.293350 583.946777,228.966644 
z"
          />
          <path
            fill="#2772DB"
            opacity="1.000000"
            stroke="none"
            d="
M626.570435,170.925598 
	C629.259155,175.103500 625.070435,177.202103 623.770691,180.101379 
	C623.519836,180.660919 619.706726,179.716599 617.567017,179.308395 
	C615.671997,178.946869 613.818909,178.365982 612.005615,177.897034 
	C609.675903,184.257874 607.028015,189.460297 601.657043,193.507233 
	C597.367371,196.739426 594.477966,201.830093 591.140076,205.885559 
	C588.772278,206.829025 586.473083,208.125397 584.021484,208.558121 
	C582.835205,208.767471 581.261414,207.496796 580.053040,206.631058 
	C579.144043,205.979828 578.537720,204.906281 577.405090,203.999176 
	C573.940186,193.926636 570.878479,183.872086 567.789429,173.825958 
	C565.823303,167.431961 566.958679,165.581909 573.324097,166.044189 
	C590.154114,167.266449 606.967590,168.717697 623.784607,170.114899 
	C624.591248,170.181931 625.368347,170.604446 626.570435,170.925598 
z"
          />
          <path
            fill="#2366CE"
            opacity="1.000000"
            stroke="none"
            d="
M606.254761,234.363800 
	C610.615906,226.327469 618.585571,226.294510 626.184692,225.562592 
	C630.279724,225.168198 634.433533,225.350693 638.516785,224.885803 
	C639.957214,224.721802 641.311890,223.474945 642.625366,222.601547 
	C644.645874,221.258072 646.596924,219.810226 649.317810,217.881958 
	C649.268677,217.058838 648.956909,214.780991 649.036743,212.516968 
	C649.172302,208.674576 647.587341,207.286224 643.917236,205.504929 
	C640.962402,204.070801 639.827148,198.734070 638.064026,195.043137 
	C636.417236,191.595657 634.915833,188.070892 633.523193,184.512543 
	C632.537781,181.994598 631.840332,179.363937 631.028931,176.360519 
	C632.028137,176.874649 633.167969,177.699326 633.952820,178.781464 
	C634.701843,179.814178 634.819702,181.362900 635.666931,182.256226 
	C639.838623,186.655334 641.708740,191.945862 642.596680,197.826141 
	C642.832397,199.387360 644.401184,200.766846 645.418762,202.186172 
	C647.214050,204.690353 649.448730,206.967758 650.788879,209.689972 
	C652.917908,214.014572 654.390198,218.657532 656.277771,223.107468 
	C657.828308,226.762817 656.065979,227.843643 652.798645,228.303848 
	C644.464905,229.477661 636.149109,230.779968 627.830688,232.060547 
	C621.549377,233.027542 615.279114,234.066650 608.992920,234.999374 
	C608.217529,235.114426 607.374146,234.771332 606.254761,234.363800 
M651.286316,222.891342 
	C651.475708,223.425461 651.665039,223.959579 651.854370,224.493713 
	C652.129028,224.368942 652.403625,224.244186 652.678223,224.119415 
	C652.357422,223.569580 652.036560,223.019730 651.286316,222.891342 
z"
          />
          <path
            fill="#2366CE"
            opacity="1.000000"
            stroke="none"
            d="
M583.704834,229.199249 
	C585.782471,230.293350 587.709351,233.384125 589.060181,228.469833 
	C589.395142,227.251343 590.529358,226.252594 591.296204,225.152832 
	C592.449585,225.963791 594.082397,226.532135 594.644226,227.642410 
	C595.492798,229.319656 595.594604,231.374786 596.027954,233.685272 
	C588.416321,236.769241 584.541138,235.330765 583.704834,229.199249 
z"
          />
          <path
            fill="#2366CE"
            opacity="1.000000"
            stroke="none"
            d="
M582.007690,223.291168 
	C582.468628,223.555847 582.942139,224.120483 583.183960,224.859360 
	C582.641541,224.552765 582.330872,224.071945 582.007690,223.291168 
z"
          />
          <path
            fill="#1243B9"
            opacity="1.000000"
            stroke="none"
            d="
M479.987793,519.985352 
	C482.688873,519.979126 485.389954,519.972839 488.630280,520.357483 
	C492.616791,521.499512 496.038147,522.414978 499.517365,522.963135 
	C504.735138,523.785217 510.018280,524.203613 515.223694,525.087708 
	C517.668396,525.502991 520.022400,527.018250 518.272827,529.900269 
	C514.426453,536.236450 508.420166,540.647339 501.959839,543.523743 
	C497.914734,545.324707 497.527161,547.766418 496.547974,550.986450 
	C493.738953,550.985840 491.373138,550.981934 488.951294,550.598999 
	C486.003174,547.271667 477.119720,547.727966 473.554871,550.989014 
	C471.347626,550.996704 469.575928,551.088623 467.815735,550.985229 
	C464.773987,550.806580 465.294647,552.516663 465.927551,554.342712 
	C463.612427,554.470520 461.297302,554.598389 458.508850,554.431152 
	C457.862640,553.944580 457.689728,553.753174 457.516785,553.561707 
	C457.302856,553.873474 457.088959,554.185303 456.875061,554.497192 
	C447.858093,554.534180 438.841156,554.571167 429.214844,554.133057 
	C424.738464,553.098450 420.871460,552.538940 417.077026,551.611572 
	C422.676025,547.687500 429.547455,548.007935 432.801483,552.110229 
	C432.612885,552.583130 432.625122,552.928650 432.637390,553.274170 
	C432.763824,552.848633 432.890228,552.423096 433.480225,551.997681 
	C437.101349,551.996948 440.278687,552.202576 443.407410,551.901855 
	C444.816956,551.766357 447.065765,550.870361 447.326782,549.891174 
	C448.574982,545.208496 451.797058,544.773315 455.767609,544.958984 
	C459.747314,545.145081 463.742920,545.028442 467.730804,544.980774 
	C468.871979,544.967102 470.911072,544.963623 471.013672,544.563965 
	C472.327606,539.447266 477.248840,536.994263 479.487915,533.102356 
	C481.393311,529.790405 482.764709,528.902832 485.931427,528.983948 
	C488.754578,529.056274 491.592194,529.134155 494.401123,528.919006 
	C495.559296,528.830261 496.659332,527.981934 497.785797,527.479126 
	C497.665436,526.988708 497.545074,526.498230 497.424683,526.007812 
	C493.537170,526.007812 489.625671,525.736389 485.768158,526.076782 
	C480.983765,526.498962 479.380768,524.420288 479.987793,519.985352 
z"
          />
          <path
            fill="#244DAE"
            opacity="1.000000"
            stroke="none"
            d="
M336.905609,557.493958 
	C330.911865,557.661865 324.918121,557.829773 318.171265,557.695923 
	C311.902954,557.792969 306.369080,558.031494 300.882355,558.673340 
	C298.841278,558.912109 296.910614,560.094849 294.929626,560.847168 
	C290.932831,560.928284 286.936066,561.009399 282.221588,560.797363 
	C279.307037,560.935425 277.110138,561.366760 274.913269,561.798096 
	C274.486511,561.839478 274.059723,561.880920 273.032715,561.640137 
	C269.943268,561.227112 267.454071,561.096252 264.978394,560.595032 
	C265.662140,559.153076 266.332306,558.081421 267.470825,557.011597 
	C274.717926,557.013611 281.593781,556.303467 288.248169,557.213257 
	C294.943054,558.128601 300.956177,553.190735 307.699829,555.831238 
	C308.741943,556.239319 310.556885,554.673584 312.481567,554.018066 
	C321.299652,554.002808 329.650635,553.990173 338.005188,554.230103 
	C338.010803,554.651062 338.012787,554.819397 337.777344,555.157593 
	C337.328522,556.049622 337.117065,556.771790 336.905609,557.493958 
z"
          />
          <path
            fill="#507ABE"
            opacity="1.000000"
            stroke="none"
            d="
M264.964844,560.965332 
	C267.454071,561.096252 269.943268,561.227112 272.710815,561.640869 
	C269.165588,562.603149 265.366821,563.527100 261.514008,563.916443 
	C249.484116,565.132202 237.433228,566.139526 225.393936,567.264648 
	C224.952805,567.305908 224.547943,567.735535 223.942017,568.312012 
	C223.354095,568.682983 222.950348,568.726074 222.244690,568.817383 
	C219.916626,568.837036 217.890427,568.808533 215.427078,568.783936 
	C212.282333,569.146179 209.571106,569.479431 206.868484,569.871826 
	C205.122742,570.125305 203.389587,570.465454 201.296921,570.359619 
	C200.986145,569.321045 201.029236,568.689697 201.470825,568.039795 
	C222.901154,565.669373 243.932999,563.317322 264.964844,560.965332 
z"
          />
          <path
            fill="#183DA3"
            opacity="1.000000"
            stroke="none"
            d="
M466.379944,554.510742 
	C465.294647,552.516663 464.773987,550.806580 467.815735,550.985229 
	C469.575928,551.088623 471.347626,550.996704 474.021423,550.994141 
	C479.621613,550.989746 484.314484,550.983887 489.007355,550.978027 
	C491.373138,550.981934 493.738953,550.985840 497.016083,550.993530 
	C501.840210,551.000488 505.774292,550.777161 509.653046,551.142212 
	C510.846680,551.254517 511.889069,552.973999 512.988342,554.212891 
	C512.986450,554.638245 512.997009,554.808350 513.007629,554.978516 
	C497.615875,554.878601 482.224121,554.778687 466.379944,554.510742 
z"
          />
          <path
            fill="#183DA3"
            opacity="1.000000"
            stroke="none"
            d="
M417.004456,551.979492 
	C420.871460,552.538940 424.738464,553.098450 428.777954,553.953247 
	C419.398712,554.488586 409.847809,554.769836 400.295013,554.952148 
	C395.175385,555.049866 390.052490,554.977478 384.168884,554.677856 
	C379.273071,554.668518 375.139496,554.963196 371.005890,555.257812 
	C370.553070,555.248718 370.100220,555.239685 369.322876,554.777710 
	C368.998901,553.552307 368.999481,552.779846 369.468750,552.004517 
	C385.626404,551.994324 401.315430,551.986877 417.004456,551.979492 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M571.987183,557.931396 
	C568.665466,557.912598 565.343811,557.893799 561.432373,557.466309 
	C557.202942,556.417480 553.563232,555.777405 549.950195,554.850281 
	C549.983398,554.372314 549.989868,554.181274 550.464844,553.990967 
	C556.950745,553.986633 562.968140,553.981750 569.450806,553.985291 
	C572.907349,553.996399 576.007141,553.505859 578.861084,554.133606 
	C582.028809,554.830383 584.972107,556.547607 587.594299,557.855713 
	C582.112732,557.904480 577.049927,557.917969 571.987183,557.931396 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M611.983032,561.053345 
	C608.647949,561.025818 605.312927,560.998230 601.412476,560.576965 
	C598.217163,559.474365 595.587158,558.765381 593.253662,557.753174 
	C603.583435,556.315186 613.603882,556.410583 623.555237,558.133789 
	C624.515991,558.300171 625.212402,559.993652 625.585205,560.997314 
	C623.647644,561.130066 622.156433,561.243652 620.665161,561.357178 
	C619.695190,561.313110 618.725159,561.268982 617.182556,560.926147 
	C615.067627,560.769470 613.525330,560.911377 611.983032,561.053345 
z"
          />
          <path
            fill="#5460A8"
            opacity="1.000000"
            stroke="none"
            d="
M549.923462,555.137329 
	C553.563232,555.777405 557.202942,556.417480 560.986084,557.411621 
	C553.889099,557.507996 546.644043,557.341614 539.409546,556.968994 
	C530.849548,556.528015 522.298706,555.909058 513.375732,555.172852 
	C512.997009,554.808350 512.986450,554.638245 513.425293,554.236145 
	C515.916504,553.988647 517.958374,553.973267 520.449585,553.982300 
	C523.596252,554.006958 526.293579,554.007263 529.444824,554.028076 
	C536.573669,554.411438 543.248535,554.774353 549.923462,555.137329 
z"
          />
          <path
            fill="#5460A8"
            opacity="1.000000"
            stroke="none"
            d="
M707.274963,571.244629 
	C710.352234,571.018005 713.737183,570.763489 717.044006,571.160217 
	C726.022217,572.237427 734.977661,573.523682 743.916687,574.892944 
	C745.636230,575.156372 747.240173,576.174927 749.066650,577.131958 
	C744.545471,576.930359 739.830811,576.606567 735.169861,575.918396 
	C725.946533,574.556702 716.752930,572.993652 707.274963,571.244629 
z"
          />
          <path
            fill="#244DAE"
            opacity="1.000000"
            stroke="none"
            d="
M369.000092,552.007324 
	C368.999481,552.779846 368.998901,553.552307 368.989319,554.712158 
	C367.048340,555.206604 365.116394,555.313721 362.316162,555.288574 
	C358.596313,555.678284 355.744720,556.200256 352.893097,556.722290 
	C351.530945,556.809143 350.168793,556.895996 348.197845,556.702271 
	C346.734680,556.274353 345.880310,556.127014 344.781158,555.716919 
	C343.351166,555.296143 342.165985,555.138306 340.985840,554.652588 
	C340.995972,553.887146 341.001038,553.449646 341.449738,553.010620 
	C345.595184,552.674072 349.296967,552.339111 353.185364,552.249451 
	C354.247986,552.329834 355.124023,552.164917 356.333282,552.000366 
	C357.110809,551.999756 357.555145,551.998657 358.386536,551.999146 
	C360.182800,551.665588 361.591980,551.330383 363.400635,550.996460 
	C364.864990,550.996094 365.929901,550.994385 367.253601,551.103271 
	C368.008301,551.478333 368.504211,551.742859 369.000092,552.007324 
z"
          />
          <path
            fill="#08229F"
            opacity="1.000000"
            stroke="none"
            d="
M652.003479,564.846252 
	C651.250061,564.792542 650.496643,564.738831 649.396790,564.176453 
	C649.031921,562.778564 649.013550,561.889282 649.464111,560.999268 
	C654.731995,561.000122 659.566833,560.649475 664.316895,561.132263 
	C667.279175,561.433289 670.113403,562.992920 672.603149,563.992981 
	C671.394226,564.051758 670.587158,564.105286 669.326355,564.148315 
	C663.249573,564.373901 657.626526,564.610107 652.003479,564.846252 
z"
          />
          <path
            fill="#5460A8"
            opacity="1.000000"
            stroke="none"
            d="
M592.957214,558.056458 
	C595.587158,558.765381 598.217163,559.474365 600.960571,560.542114 
	C591.581055,560.096558 582.088013,559.292053 572.291077,558.209473 
	C577.049927,557.917969 582.112732,557.904480 588.004578,557.914185 
	C590.208191,557.977051 591.582703,558.016785 592.957214,558.056458 
z"
          />
          <path
            fill="#5460A8"
            opacity="1.000000"
            stroke="none"
            d="
M620.842651,561.682495 
	C622.156433,561.243652 623.647644,561.130066 626.034424,561.019714 
	C629.925903,561.048828 632.921936,561.074707 636.202515,561.356689 
	C639.710327,562.587036 642.933472,563.561218 646.156616,564.535400 
	C637.777771,563.692871 629.398987,562.850342 620.842651,561.682495 
z"
          />
          <path
            fill="#5460A8"
            opacity="1.000000"
            stroke="none"
            d="
M652.258179,565.169556 
	C657.626526,564.610107 663.249573,564.373901 669.426208,564.456360 
	C671.048035,565.695312 672.116272,566.615601 673.184509,567.535950 
	C666.294006,566.854919 659.403442,566.173889 652.258179,565.169556 
z"
          />
          <path
            fill="#507ABE"
            opacity="1.000000"
            stroke="none"
            d="
M295.383698,560.937866 
	C296.910614,560.094849 298.841278,558.912109 300.882355,558.673340 
	C306.369080,558.031494 311.902954,557.792969 317.702332,557.680054 
	C310.603638,558.986755 303.220703,560.007629 295.383698,560.937866 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M673.604431,567.638062 
	C672.116272,566.615601 671.048035,565.695312 669.879944,564.466919 
	C670.587158,564.105286 671.394226,564.051758 672.934692,563.996582 
	C674.110046,563.993408 674.552124,563.991821 675.267334,564.272095 
	C678.040649,565.318481 680.540771,566.083008 682.747437,566.908936 
	C681.966675,567.271057 681.479309,567.571777 680.991943,567.872559 
	C678.669434,567.828430 676.346863,567.784302 673.604431,567.638062 
z"
          />
          <path
            fill="#5460A8"
            opacity="1.000000"
            stroke="none"
            d="
M681.265198,568.163086 
	C681.479309,567.571777 681.966675,567.271057 683.157410,566.953674 
	C685.236328,566.937073 686.612000,566.937134 688.242249,566.967163 
	C688.667358,566.984680 688.837891,566.972229 689.387878,566.985229 
	C690.781067,567.045776 691.794739,567.080811 692.894836,567.423706 
	C693.638306,568.162781 694.295410,568.593933 694.970276,569.019836 
	C694.988037,569.014648 694.976624,569.049805 695.015930,569.290100 
	C695.055237,569.530396 695.328796,569.933350 695.328796,569.933350 
	C695.328796,569.933350 695.334961,570.281372 695.302856,570.451416 
	C690.693359,569.898926 686.115906,569.176331 681.265198,568.163086 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M646.524170,564.627563 
	C642.933472,563.561218 639.710327,562.587036 636.470215,561.057312 
	C640.633850,560.667847 644.814514,560.833923 648.995117,561.000000 
	C649.013550,561.889282 649.031921,562.778564 649.065796,563.993042 
	C648.351379,564.452087 647.621521,564.585876 646.524170,564.627563 
z"
          />
          <path
            fill="#507ABE"
            opacity="1.000000"
            stroke="none"
            d="
M371.327454,555.502014 
	C375.139496,554.963196 379.273071,554.668518 383.700134,554.660400 
	C379.878723,555.213318 375.763885,555.479797 371.327454,555.502014 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M340.980774,554.980408 
	C342.165985,555.138306 343.351166,555.296143 344.762085,555.977539 
	C344.948883,556.843567 344.909973,557.185974 344.871063,557.528442 
	C342.506958,557.601990 340.142883,557.675537 337.342194,557.621521 
	C337.117065,556.771790 337.328522,556.049622 338.148010,555.160034 
	C339.497650,554.988525 340.239197,554.984436 340.980774,554.980408 
z"
          />
          <path
            fill="#5460A8"
            opacity="1.000000"
            stroke="none"
            d="
M763.915649,578.593384 
	C764.009949,579.146301 764.000732,579.340637 763.972046,579.826782 
	C761.447998,579.546509 758.943359,578.974426 756.229370,578.083374 
	C758.617371,577.921265 761.214783,578.078003 763.915649,578.593384 
z"
          />
          <path
            fill="#507ABE"
            opacity="1.000000"
            stroke="none"
            d="
M353.332886,556.827148 
	C355.744720,556.200256 358.596313,555.678284 361.905212,555.301392 
	C359.499207,555.941711 356.635925,556.436890 353.332886,556.827148 
z"
          />
          <path
            fill="#507ABE"
            opacity="1.000000"
            stroke="none"
            d="
M275.327484,561.893555 
	C277.110138,561.366760 279.307037,560.935425 281.760071,560.789185 
	C279.924713,561.379150 277.833221,561.684082 275.327484,561.893555 
z"
          />
          <path
            fill="#5460A8"
            opacity="1.000000"
            stroke="none"
            d="
M612.231445,561.290222 
	C613.525330,560.911377 615.067627,560.769470 616.818115,560.927246 
	C615.510803,561.327087 613.995300,561.427063 612.231445,561.290222 
z"
          />
          <path
            fill="#507ABE"
            opacity="1.000000"
            stroke="none"
            d="
M345.204529,557.649841 
	C344.909973,557.185974 344.948883,556.843567 345.006866,556.240417 
	C345.880310,556.127014 346.734680,556.274353 347.787476,556.682556 
	C347.169891,557.219360 346.353912,557.495300 345.204529,557.649841 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M457.185425,554.604919 
	C457.088959,554.185303 457.302856,553.873474 457.516785,553.561707 
	C457.689728,553.753174 457.862640,553.944580 458.081482,554.333618 
	C457.916901,554.591675 457.706390,554.652222 457.185425,554.604919 
z"
          />
          <path
            fill="#024AAC"
            opacity="1.000000"
            stroke="none"
            d="
M720.555908,421.974640 
	C720.492615,421.647095 720.458923,421.316559 720.641602,420.246155 
	C720.862671,400.679382 720.901001,381.849518 720.929138,363.019623 
	C720.939331,356.213135 723.832275,354.016693 730.648560,355.261078 
	C734.028503,355.878143 735.140991,357.335846 735.101135,360.892334 
	C734.903198,378.556000 735.003784,396.223389 735.053162,413.889313 
	C735.076111,422.082489 744.725281,430.752960 752.801392,429.866577 
	C753.734009,429.764221 754.610413,429.174133 755.519531,428.827637 
	C757.163391,428.201141 758.764648,427.320984 760.467651,427.017181 
	C766.457886,425.948578 770.961426,418.724121 769.046387,413.006531 
	C769.030884,394.335938 769.015381,375.665344 769.501465,356.514893 
	C772.140747,355.787933 774.296204,355.643036 776.412964,355.275238 
	C783.540283,354.036804 786.070068,356.054016 786.069092,363.086884 
	C786.067017,378.410919 786.497070,393.753174 785.851624,409.050018 
	C785.595032,415.132080 783.930908,421.609497 781.173950,427.024200 
	C777.088867,435.047302 769.786560,439.228180 760.478699,440.312439 
	C751.382874,441.372040 742.653748,441.527740 734.364380,437.029266 
	C728.028748,433.591003 724.318176,427.763458 720.555908,421.974640 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M691.011963,389.890320 
	C690.530518,389.879639 690.049011,389.868958 689.290222,389.908325 
	C689.012878,389.958405 689.131409,389.764435 689.056519,389.426971 
	C685.990173,387.800934 682.998169,386.513824 680.007568,385.223389 
	C677.000610,383.925903 673.510864,383.174164 671.175476,381.114349 
	C669.832886,379.930176 669.263245,375.705078 670.290955,374.478027 
	C672.073547,372.349548 675.283020,370.567352 678.045166,370.318878 
	C681.649658,369.994598 685.401184,371.305206 689.326294,371.992706 
	C689.873230,372.253174 690.057617,372.520081 690.048828,373.267334 
	C690.668762,376.316864 691.000061,379.147583 692.183350,381.560516 
	C692.846191,382.912323 694.920288,383.572113 696.358521,384.543701 
	C697.017395,384.567841 697.676331,384.591949 698.847290,384.946686 
	C701.302612,384.962006 703.245850,384.646698 705.189087,384.331421 
	C706.837524,384.213623 708.485901,384.095795 710.540100,383.896545 
	C712.226990,384.261139 713.508118,384.707184 714.605957,385.089417 
	C714.605957,392.206329 714.605957,399.363739 714.605957,406.521149 
	C714.220032,406.625244 713.834167,406.729340 713.448242,406.833435 
	C712.946350,405.821625 712.546936,404.741516 711.925964,403.809021 
	C707.809998,397.627594 703.088806,392.204468 695.062622,391.256653 
	C694.746155,391.544556 694.552917,391.512268 694.096558,390.987061 
	C692.893005,390.292908 691.952454,390.091614 691.011963,389.890320 
z"
          />
          <path
            fill="#3D73BB"
            opacity="1.000000"
            stroke="none"
            d="
M768.773560,413.247437 
	C770.961426,418.724121 766.457886,425.948578 760.467651,427.017181 
	C758.764648,427.320984 757.163391,428.201141 755.519531,428.827637 
	C754.610413,429.174133 753.734009,429.764221 752.801392,429.866577 
	C744.725281,430.752960 735.076111,422.082489 735.053162,413.889313 
	C735.003784,396.223389 734.903198,378.556000 735.101135,360.892334 
	C735.140991,357.335846 734.028503,355.878143 730.648560,355.261078 
	C723.832275,354.016693 720.939331,356.213135 720.929138,363.019623 
	C720.901001,381.849518 720.862671,400.679382 720.654358,419.997009 
	C719.609680,417.570679 718.025085,414.666260 717.985352,411.740906 
	C717.746155,394.108490 717.880798,376.471222 717.843567,358.835663 
	C717.833984,354.284271 720.065308,352.114960 724.534851,352.083221 
	C724.867615,352.080841 725.200378,352.084808 725.533142,352.081390 
	C736.736877,351.966980 736.876099,352.122467 736.868469,363.469666 
	C736.857849,379.275574 736.978149,395.082428 736.830811,410.886932 
	C736.763123,418.146210 740.941833,425.801178 750.236877,426.095856 
	C760.783630,426.430206 766.722351,422.731720 768.773560,413.247437 
z"
          />
          <path
            fill="#3D73BB"
            opacity="1.000000"
            stroke="none"
            d="
M720.366943,422.260681 
	C724.318176,427.763458 728.028748,433.591003 734.364380,437.029266 
	C742.653748,441.527740 751.382874,441.372040 760.478699,440.312439 
	C769.786560,439.228180 777.088867,435.047302 781.173950,427.024200 
	C783.930908,421.609497 785.595032,415.132080 785.851624,409.050018 
	C786.497070,393.753174 786.067017,378.410919 786.069092,363.086884 
	C786.070068,356.054016 783.540283,354.036804 776.412964,355.275238 
	C774.296204,355.643036 772.140747,355.787933 769.575745,356.056335 
	C770.543030,354.772827 771.856934,352.478882 773.347961,352.357330 
	C777.103516,352.051178 781.046448,352.299194 784.688538,353.218811 
	C786.106934,353.576935 787.847168,356.322266 787.866516,357.998444 
	C788.082336,376.648132 788.782532,395.346558 787.724915,413.939270 
	C786.901917,428.408051 777.591919,439.714294 761.557495,442.357605 
	C754.421326,443.533966 747.796936,442.996063 740.937317,441.934143 
	C731.187317,440.424835 720.734863,430.713989 720.366943,422.260681 
z"
          />
          <path
            fill="#1053AF"
            opacity="1.000000"
            stroke="none"
            d="
M426.029114,387.206665 
	C429.180481,379.390564 432.197327,371.941528 435.235687,364.501282 
	C436.424194,361.590942 437.456970,358.592468 438.920654,355.824463 
	C441.533356,350.883514 446.553162,352.543091 450.104431,353.263062 
	C452.778809,353.805237 455.792236,357.097839 456.958527,359.877777 
	C461.599976,370.941040 465.637909,382.260651 469.780609,393.528992 
	C473.390442,403.347748 476.777924,413.248260 480.391724,423.065460 
	C481.860199,427.054688 483.536835,430.980957 485.354645,434.824310 
	C487.492615,439.344513 486.655701,441.185150 481.609344,441.485199 
	C477.975494,441.701294 474.179657,441.992035 470.693390,441.202057 
	C466.071564,440.154816 466.994232,434.869049 464.928070,431.759552 
	C464.240814,430.725250 464.437103,429.143677 463.896637,427.964844 
	C462.081848,424.006653 459.829559,421.094879 454.523834,421.754547 
	C449.603241,422.366333 444.546753,421.882507 439.550079,421.885162 
	C432.234924,421.889038 430.567108,423.291473 428.517883,430.385681 
	C427.572266,433.659271 426.234283,436.834229 424.852264,439.955658 
	C424.550232,440.637756 423.321320,441.252960 422.486755,441.298126 
	C418.178864,441.531158 413.853516,441.761871 409.547852,441.609650 
	C406.446472,441.500031 405.586426,439.379730 406.676117,436.674286 
	C412.627258,421.899139 418.665588,407.159149 424.618591,392.384766 
	C425.233887,390.857635 425.483765,389.183289 426.029114,387.206665 
M441.305908,395.597107 
	C440.690613,397.740326 440.075348,399.883514 439.084930,403.333435 
	C443.683258,403.333435 447.822510,403.496765 451.924316,403.177643 
	C452.633698,403.122498 453.945251,400.796600 453.680420,399.905334 
	C452.295380,395.244446 450.555695,390.682983 448.788818,386.145599 
	C448.344177,385.003662 447.346161,384.077209 446.144104,382.419678 
	C444.291412,387.237366 442.836945,391.019653 441.305908,395.597107 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M409.263733,409.702545 
	C408.794708,401.534393 408.612823,393.066406 408.717285,384.297821 
	C409.186035,392.465698 409.368439,400.934235 409.263733,409.702545 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M396.049347,436.608734 
	C395.323486,437.051697 394.750336,437.205353 394.012024,437.614319 
	C390.460358,438.914642 387.073792,439.959656 383.309326,440.960510 
	C383.392151,440.268860 383.716949,439.417267 384.334900,439.005707 
	C389.914337,435.289612 395.545135,431.650696 401.493530,427.931915 
	C401.832855,428.612549 401.839508,429.350433 401.827209,430.456360 
	C399.939545,432.656097 398.070831,434.487762 396.049347,436.608734 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M415.077179,406.035461 
	C414.226166,409.420624 413.375153,412.805786 412.524170,416.190979 
	C411.938110,416.077972 411.352051,415.964935 410.766022,415.851929 
	C411.282990,412.738403 411.799927,409.624878 412.948792,406.250000 
	C414.079529,406.004242 414.578339,406.019836 415.077179,406.035461 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M398.819580,363.790863 
	C397.632111,363.262146 396.401093,362.391357 395.212280,361.159698 
	C396.428345,361.682129 397.602173,362.565491 398.819580,363.790863 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M408.017609,378.686432 
	C407.141632,377.806610 406.313995,376.585968 405.733795,375.181335 
	C406.676117,376.113434 407.371063,377.229523 408.017609,378.686432 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M405.763794,372.881744 
	C404.742615,372.058380 403.824005,370.901184 402.927307,369.396790 
	C403.921631,370.215698 404.894012,371.381836 405.763794,372.881744 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M415.093170,405.818909 
	C414.578339,406.019836 414.079529,406.004242 413.204346,405.970154 
	C412.858337,405.206146 412.888672,404.460571 412.926727,403.340302 
	C413.614288,403.472260 414.294189,403.978912 415.059998,404.827576 
	C415.145935,405.169617 415.109161,405.602356 415.093170,405.818909 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M401.172150,366.374847 
	C400.531189,366.265808 399.847290,365.858917 399.228241,365.102539 
	C399.905121,365.194336 400.517181,365.635651 401.172150,366.374847 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M395.130005,360.441956 
	C394.489838,360.363098 393.792664,359.979309 393.095093,359.255310 
	C393.754120,359.322418 394.413574,359.729736 395.130005,360.441956 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M407.514709,417.097839 
	C407.461548,416.434448 407.739380,415.706268 408.308167,414.869354 
	C408.347961,415.518097 408.096832,416.275604 407.514709,417.097839 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M405.801819,421.179749 
	C405.679260,420.554962 405.833405,419.782379 406.284363,418.881866 
	C406.413605,419.513275 406.246063,420.272614 405.801819,421.179749 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M403.564728,425.142639 
	C403.580933,424.478363 403.918274,423.750793 404.583923,422.976074 
	C404.570129,423.645721 404.227997,424.362518 403.564728,425.142639 
z"
          />
          <path
            fill="#5E8BC7"
            opacity="1.000000"
            stroke="none"
            d="
M402.634827,367.981049 
	C402.153961,367.963989 401.716888,367.651978 401.287598,367.057190 
	C401.756470,367.078278 402.217529,367.382202 402.634827,367.981049 
z"
          />
          <path
            fill="#024AAC"
            opacity="1.000000"
            stroke="none"
            d="
M507.958984,401.000000 
	C507.956329,391.668854 507.999481,382.837372 507.935028,374.006683 
	C507.892609,368.190979 506.720123,367.108673 500.849823,367.085114 
	C496.684235,367.068420 492.516541,367.155884 488.353485,367.050903 
	C482.930359,366.914124 481.266815,364.949158 481.989929,359.452728 
	C482.598053,354.829956 485.707062,354.690887 489.397278,355.468964 
	C491.163788,355.841370 493.028381,355.846649 494.848846,355.849915 
	C511.677979,355.879883 528.507202,355.867371 545.336365,355.883575 
	C547.627380,355.885773 550.304260,355.384918 550.086609,359.131805 
	C549.905640,362.248383 551.339233,366.308441 546.270874,366.773071 
	C542.304932,367.136658 538.292603,367.034485 534.300354,367.061493 
	C525.438660,367.121490 524.091797,368.421906 524.093872,377.008942 
	C524.098511,396.170868 524.104614,415.332855 524.074829,434.494720 
	C524.071838,436.415710 523.827515,438.336304 523.706665,440.090485 
	C519.804749,440.090485 516.488892,439.916687 513.199219,440.135834 
	C509.346619,440.392456 507.840118,438.971436 507.906158,434.990692 
	C508.091339,423.829895 507.963409,412.663940 507.958984,401.000000 
z"
          />
          <path
            fill="#0C50AE"
            opacity="1.000000"
            stroke="none"
            d="
M548.010376,441.161133 
	C545.234009,441.163422 542.933167,441.163422 539.682800,441.163422 
	C541.148376,436.630249 542.262268,432.716888 543.674438,428.914307 
	C546.593994,421.052795 549.603333,413.222809 552.706787,405.432037 
	C555.309204,398.898773 558.253296,392.499115 560.763000,385.932037 
	C563.620361,378.454834 566.537781,370.964661 568.737366,363.282074 
	C570.902405,355.720306 576.663940,351.849792 584.394104,353.978973 
	C585.515198,354.287750 586.743652,355.340454 587.294800,356.381287 
	C589.273621,360.118408 591.269104,363.891846 592.738098,367.844879 
	C596.393982,377.682861 599.574097,387.699066 603.295654,397.510803 
	C608.096436,410.167847 613.187866,422.715942 618.261475,435.267517 
	C619.989319,439.542053 619.176697,441.050781 614.617737,441.160797 
	C613.619812,441.184906 612.620789,441.165619 611.622314,441.169250 
	C600.655579,441.209137 600.216431,440.849457 597.878113,429.907135 
	C596.254517,422.309326 594.825745,421.138794 587.185486,421.156036 
	C582.359436,421.166901 577.533386,421.186432 572.707336,421.193848 
	C564.850830,421.205933 562.601135,423.345215 560.939575,431.086823 
	C560.271851,434.197998 559.116516,437.847809 556.900024,439.782654 
	C555.081055,441.370575 551.362549,440.782440 548.010376,441.161133 
M586.053894,403.116760 
	C586.353455,401.800873 587.147949,400.362457 586.858276,399.192535 
	C586.042053,395.896606 584.956543,392.636566 583.635437,389.506622 
	C582.663330,387.203522 581.153320,385.127411 579.194702,381.770416 
	C577.223145,386.739563 575.438416,390.333374 574.408203,394.131805 
	C573.639099,396.967438 573.844482,400.067352 573.615906,403.049591 
	C576.878479,403.286957 580.139709,403.546326 583.404785,403.742859 
	C584.048889,403.781616 584.707581,403.578400 586.053894,403.116760 
z"
          />
          <path
            fill="#1A52C5"
            opacity="1.000000"
            stroke="none"
            d="
M295.051453,584.889221 
	C298.034729,584.857056 301.018005,584.824890 304.683228,585.085083 
	C306.905762,585.574036 308.446350,585.770630 310.201324,586.254517 
	C312.951782,588.341858 317.717499,587.856445 317.009430,593.403198 
	C316.084106,596.263489 315.052368,598.663635 314.266968,601.141785 
	C313.044708,604.998291 310.744232,607.382751 306.521484,607.676575 
	C306.125488,607.704163 305.446686,608.732483 305.490753,609.245361 
	C305.984772,614.995483 302.551147,616.763000 297.675262,617.366516 
	C296.842590,617.469604 295.908661,618.382812 295.395416,619.166626 
	C292.681366,623.311462 290.086945,627.534607 286.855865,632.678040 
	C282.716370,632.746277 280.899628,635.712097 282.441254,641.113770 
	C282.571381,641.569763 281.507568,642.366455 280.749390,642.999390 
	C280.500336,642.993835 280.002228,642.982910 279.996185,642.613037 
	C278.450531,638.825806 277.792297,634.661987 272.852661,633.696167 
	C269.220734,628.454468 265.451782,623.686462 262.333679,618.525269 
	C258.345367,611.923645 254.575974,605.149353 251.287903,598.177917 
	C248.615143,592.511169 250.391083,590.424377 256.553284,589.946594 
	C267.375671,589.107605 278.175690,587.979675 289.154968,587.217163 
	C289.879059,587.636230 290.433258,587.810791 291.340057,587.984985 
	C292.464233,587.333496 293.235809,586.682373 294.238525,585.900146 
	C294.663635,585.475830 294.857544,585.182556 295.051453,584.889221 
z"
          />
          <path
            fill="#174ABD"
            opacity="1.000000"
            stroke="none"
            d="
M280.998444,643.004883 
	C281.507568,642.366455 282.571381,641.569763 282.441254,641.113770 
	C280.899628,635.712097 282.716370,632.746277 286.855865,632.678040 
	C290.086945,627.534607 292.681366,623.311462 295.395416,619.166626 
	C295.908661,618.382812 296.842590,617.469604 297.675262,617.366516 
	C302.551147,616.763000 305.984772,614.995483 305.490753,609.245361 
	C305.446686,608.732483 306.125488,607.704163 306.521484,607.676575 
	C310.744232,607.382751 313.044708,604.998291 314.266968,601.141785 
	C315.052368,598.663635 316.084106,596.263489 317.384094,593.408081 
	C318.512970,592.988098 319.263184,592.988464 320.284302,592.994263 
	C320.735596,592.990173 320.915955,592.980652 321.096344,592.971069 
	C321.096344,592.971069 321.185516,593.374878 321.128754,594.025391 
	C322.500000,601.806396 323.904999,608.941711 325.415558,616.054626 
	C325.502319,616.463196 326.449554,616.689026 327.003693,617.403687 
	C329.169586,626.176758 330.556671,634.743408 335.646759,642.173340 
	C337.992340,645.597168 335.618927,649.073303 331.465729,648.964172 
	C323.848755,648.763977 316.234650,648.403381 308.624695,648.006897 
	C304.667175,647.800842 300.719269,647.396240 296.770325,647.043945 
	C292.113800,646.628540 287.445496,646.280701 282.822510,645.621643 
	C282.104706,645.519348 281.601074,643.914795 280.998444,643.004883 
z"
          />
          <path
            fill="#244DAE"
            opacity="1.000000"
            stroke="none"
            d="
M280.749390,642.999390 
	C281.601074,643.914795 282.104706,645.519348 282.822510,645.621643 
	C287.445496,646.280701 292.113800,646.628540 296.770325,647.043945 
	C300.719269,647.396240 304.667175,647.800842 308.624695,648.006897 
	C316.234650,648.403381 323.848755,648.763977 331.465729,648.964172 
	C335.618927,649.073303 337.992340,645.597168 335.646759,642.173340 
	C330.556671,634.743408 329.169586,626.176758 327.318787,617.376465 
	C327.834534,616.910828 328.042511,616.876282 328.250458,616.841736 
	C332.675903,628.573242 337.101349,640.304810 342.011414,653.320923 
	C333.363525,652.817322 326.165253,652.389648 318.965851,651.981628 
	C313.509003,651.672302 308.037964,651.533447 302.596283,651.056091 
	C295.691711,650.450378 288.661804,650.185730 281.985718,648.550354 
	C278.932587,647.802429 276.596039,644.129272 274.228760,641.412537 
	C276.349396,641.689148 278.175812,642.336060 280.002228,642.982910 
	C280.002228,642.982910 280.500336,642.993835 280.749390,642.999390 
z"
          />
          <path
            fill="#305CB3"
            opacity="1.000000"
            stroke="none"
            d="
M288.985077,586.972717 
	C278.175690,587.979675 267.375671,589.107605 256.553284,589.946594 
	C250.391083,590.424377 248.615143,592.511169 251.287903,598.177917 
	C254.575974,605.149353 258.345367,611.923645 262.333679,618.525269 
	C265.451782,623.686462 269.220734,628.454468 272.847900,634.090088 
	C272.630829,636.220215 272.266327,637.657288 271.901794,639.094421 
	C262.309662,624.851074 253.382721,610.239929 246.984146,594.221313 
	C245.180359,589.705627 245.678680,588.558350 250.552277,588.140869 
	C257.644592,587.533325 264.766266,587.268188 272.700378,586.905701 
	C274.681000,586.384521 275.836639,585.814697 276.992249,585.244873 
	C278.397308,585.189819 279.802338,585.134766 281.715088,585.249634 
	C282.222748,585.419556 282.650604,585.480408 282.650604,585.480408 
	C282.650604,585.480408 282.955933,585.131531 282.955933,585.131531 
	C284.677399,585.058105 286.398834,584.984680 288.554993,585.265747 
	C288.988159,586.071045 288.986603,586.521912 288.985077,586.972717 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M328.206726,616.434326 
	C328.042511,616.876282 327.834534,616.910828 327.311432,616.972656 
	C326.449554,616.689026 325.502319,616.463196 325.415558,616.054626 
	C323.904999,608.941711 322.500000,601.806396 321.135864,594.232117 
	C321.849548,594.463318 322.879761,595.037720 323.089264,595.829285 
	C324.865265,602.539429 326.491669,609.289124 328.206726,616.434326 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M309.986969,585.967163 
	C308.446350,585.770630 306.905762,585.574036 305.127502,585.057434 
	C309.168030,583.984619 313.456024,582.730225 317.717651,582.814270 
	C318.853119,582.836670 319.907745,586.959351 320.717590,589.387939 
	C318.170227,584.848816 314.659241,583.726990 309.986969,585.967163 
z"
          />
          <path
            fill="#244DAE"
            opacity="1.000000"
            stroke="none"
            d="
M310.201324,586.254456 
	C314.659241,583.726990 318.170227,584.848816 320.706635,589.790527 
	C321.023468,590.446716 321.069397,590.888855 320.867462,591.762085 
	C320.417511,592.458435 320.215424,592.723633 320.013367,592.988892 
	C319.263184,592.988464 318.512970,592.988098 317.388123,592.982910 
	C317.717499,587.856445 312.951782,588.341858 310.201324,586.254456 
z"
          />
          <path
            fill="#174ABD"
            opacity="1.000000"
            stroke="none"
            d="
M271.973175,639.383911 
	C272.266327,637.657288 272.630829,636.220215 273.000122,634.389099 
	C277.792297,634.661987 278.450531,638.825806 279.996185,642.613037 
	C278.175812,642.336060 276.349396,641.689148 274.133606,641.079712 
	C273.177673,640.635864 272.611115,640.154663 271.973175,639.383911 
z"
          />
          <path
            fill="#244DAE"
            opacity="1.000000"
            stroke="none"
            d="
M289.154968,587.217163 
	C288.986603,586.521912 288.988159,586.071045 288.985535,585.280884 
	C290.714386,584.911316 292.447357,584.881042 294.615906,584.869995 
	C294.857544,585.182556 294.663635,585.475830 293.885803,585.896484 
	C292.530457,586.677734 291.758972,587.331543 290.987488,587.985352 
	C290.433258,587.810791 289.879059,587.636230 289.154968,587.217163 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M276.623718,585.187622 
	C275.836639,585.814697 274.681000,586.384521 273.134460,586.916565 
	C273.914124,586.296021 275.084656,585.713135 276.623718,585.187622 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M282.728790,585.110962 
	C282.955933,585.131531 282.650604,585.480408 282.650604,585.480408 
	C282.650604,585.480408 282.222748,585.419556 282.136292,585.286499 
	C282.049866,585.153381 282.501648,585.090393 282.728790,585.110962 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M320.284302,592.994263 
	C320.215424,592.723633 320.417511,592.458435 320.856201,592.095459 
	C321.092834,591.997620 321.111145,592.484619 321.103760,592.727844 
	C320.915955,592.980652 320.735596,592.990173 320.284302,592.994263 
z"
          />
          <path
            fill="#0927AB"
            opacity="1.000000"
            stroke="none"
            d="
M472.033661,681.948547 
	C474.082672,681.626770 476.131653,681.304932 478.647095,681.351562 
	C483.390625,686.662231 489.052429,685.024353 494.289215,684.663757 
	C499.583588,684.299133 502.966553,686.943359 503.002441,692.210388 
	C503.013672,693.856750 503.044067,695.502930 503.065674,697.149231 
	C502.903259,697.673889 502.740845,698.198547 501.966492,698.872925 
	C500.004974,699.833557 498.479980,700.452026 497.339020,701.491943 
	C494.068268,704.472839 492.054565,709.897339 485.791229,706.375793 
	C484.953552,705.904846 482.608154,708.033936 481.003387,709.027466 
	C478.400940,710.638672 475.989716,712.692139 473.199402,713.822144 
	C471.170380,714.643860 469.122650,714.142456 468.918732,710.843872 
	C468.535065,704.638977 467.751495,698.452393 466.918579,692.287292 
	C466.758606,691.103394 465.607880,690.053406 464.914215,688.941650 
	C464.921295,688.214355 464.928375,687.487122 465.294281,686.330444 
	C467.779968,684.583557 469.906799,683.266052 472.033661,681.948547 
z"
          />
          <path
            fill="#08229F"
            opacity="1.000000"
            stroke="none"
            d="
M464.626404,689.249023 
	C465.607880,690.053406 466.758606,691.103394 466.918579,692.287292 
	C467.751495,698.452393 468.535065,704.638977 468.918732,710.843872 
	C469.122650,714.142456 471.170380,714.643860 473.199402,713.822144 
	C475.989716,712.692139 478.400940,710.638672 481.003387,709.027466 
	C482.608154,708.033936 484.953552,705.904846 485.791229,706.375793 
	C492.054565,709.897339 494.068268,704.472839 497.339020,701.491943 
	C498.479980,700.452026 500.004974,699.833557 501.737305,699.035522 
	C500.560974,701.084595 499.044952,703.155945 497.425049,705.142578 
	C496.591400,706.164978 494.973969,706.903870 494.731873,707.997131 
	C492.684235,717.243469 484.915466,715.911438 478.456818,717.079346 
	C475.298676,717.650452 472.185150,718.475525 469.021240,719.005127 
	C466.044647,719.503296 463.985077,718.180664 463.980530,715.089600 
	C463.967957,706.578735 464.202087,698.067505 464.626404,689.249023 
z"
          />
          <path
            fill="#08229F"
            opacity="1.000000"
            stroke="none"
            d="
M503.413452,697.061401 
	C503.044067,695.502930 503.013672,693.856750 503.002441,692.210388 
	C502.966553,686.943359 499.583588,684.299133 494.289215,684.663757 
	C489.052429,685.024353 483.390625,686.662231 479.042603,681.363525 
	C489.191895,681.006958 499.412109,681.006958 511.175232,681.006958 
	C508.328552,687.137573 506.044891,692.055603 503.413452,697.061401 
z"
          />
          <path
            fill="#08229F"
            opacity="1.000000"
            stroke="none"
            d="
M471.797119,681.688110 
	C469.906799,683.266052 467.779968,684.583557 465.304535,685.966919 
	C464.898621,681.258118 468.331726,681.489380 471.797119,681.688110 
z"
          />
          <path
            fill="#38459A"
            opacity="1.000000"
            stroke="none"
            d="
M520.896912,578.538940 
	C527.818237,578.815857 534.761047,578.877930 541.641541,579.564453 
	C542.905762,579.690552 545.033203,582.498108 544.858521,583.815430 
	C543.950378,590.663208 542.695679,597.495117 541.056152,604.209106 
	C538.196350,615.920044 535.163269,627.597839 531.798889,639.172058 
	C530.409851,643.950562 527.977234,648.425842 525.572205,652.738403 
	C526.100220,648.627808 527.075317,644.817871 528.320190,640.757812 
	C529.251038,637.956238 529.953491,635.414551 530.566162,632.851501 
	C533.899597,618.907166 537.258484,604.968506 540.510498,591.005127 
	C542.212097,583.698914 541.417908,582.685608 534.323181,581.930969 
	C532.523376,581.739563 530.750549,581.294434 528.866333,580.755737 
	C528.574890,580.285461 528.321838,580.115295 527.641968,580.026978 
	C526.176941,580.338379 525.078125,580.658020 523.615662,580.973511 
	C522.527466,580.966980 521.802795,580.964478 521.067749,580.656006 
	C521.003845,579.746277 520.950378,579.142639 520.896912,578.538940 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M464.154114,649.034851 
	C464.355469,646.644958 464.556824,644.255005 465.074524,641.283569 
	C465.230957,639.785767 465.071045,638.869507 464.911133,637.953247 
	C464.971344,631.296448 465.031525,624.639587 465.521240,617.402832 
	C466.279144,616.286682 466.855652,615.769836 466.899017,615.211487 
	C467.637238,605.703125 468.279968,596.187195 469.020874,586.679077 
	C469.169403,584.773132 469.636566,582.892090 470.226135,581.005249 
	C470.674927,581.016296 470.854431,581.021545 471.023132,581.492554 
	C470.931763,597.354004 470.760834,612.735168 469.093384,628.078247 
	C468.522827,633.328247 469.002563,638.692322 468.749786,644.239868 
	C469.003387,647.650330 469.509644,650.825134 469.583679,654.008789 
	C467.702820,654.087402 466.254150,654.157166 464.805511,654.226929 
	C464.805511,654.226929 464.920319,654.102478 464.927002,653.750671 
	C464.673859,651.944214 464.413971,650.489502 464.154114,649.034851 
z"
          />
          <path
            fill="#182893"
            opacity="1.000000"
            stroke="none"
            d="
M471.033936,581.026855 
	C470.854431,581.021545 470.674927,581.016296 470.232666,580.667419 
	C470.011658,579.655273 470.053406,578.986633 470.095154,578.317993 
	C486.730011,578.312805 503.364899,578.307617 520.448364,578.420654 
	C520.950378,579.142639 521.003845,579.746277 520.590454,580.673401 
	C503.760345,581.006836 487.397125,581.016846 471.033936,581.026855 
z"
          />
          <path
            fill="#38459A"
            opacity="1.000000"
            stroke="none"
            d="
M464.900024,654.630066 
	C466.254150,654.157166 467.702820,654.087402 470.039520,654.015442 
	C474.279114,654.013855 477.630646,654.014465 481.451660,654.020264 
	C487.285980,654.022400 492.650879,654.019348 498.412598,654.018616 
	C499.866455,654.018311 500.923523,654.015747 502.435638,654.019531 
	C506.277313,654.393066 509.663940,654.760254 513.050537,655.127441 
	C512.297668,655.399841 511.546753,655.904175 510.791748,655.910278 
	C496.551514,656.025696 482.310852,656.105591 468.070251,656.102356 
	C467.044952,656.102112 466.019775,655.405823 464.900024,654.630066 
z"
          />
          <path
            fill="#182893"
            opacity="1.000000"
            stroke="none"
            d="
M513.496643,655.146118 
	C509.663940,654.760254 506.277313,654.393066 502.533966,653.623840 
	C503.508850,652.816223 504.828125,652.117310 506.174469,652.060547 
	C509.987732,651.899841 513.819641,651.852600 517.629700,652.046509 
	C520.823914,652.209106 522.215942,651.042114 523.195984,647.865479 
	C523.980652,645.322449 526.372070,643.275208 528.050415,641.007935 
	C527.075317,644.817871 526.100220,648.627808 525.232422,652.827209 
	C525.339661,653.216675 525.259949,653.232544 524.941406,653.281677 
	C524.475159,653.660461 524.327454,653.990173 524.179810,654.319824 
	C520.767456,654.601501 517.355103,654.883179 513.496643,655.146118 
z"
          />
          <path
            fill="#38459A"
            opacity="1.000000"
            stroke="none"
            d="
M469.702881,578.231445 
	C470.053406,578.986633 470.011658,579.655273 469.963379,580.661743 
	C469.636566,582.892090 469.169403,584.773132 469.020874,586.679077 
	C468.279968,596.187195 467.637238,605.703125 466.899017,615.211487 
	C466.855652,615.769836 466.279144,616.286682 465.592773,616.943604 
	C465.507538,611.139893 465.747040,605.213745 466.062714,599.291565 
	C466.361389,593.688843 466.604950,588.076721 467.172760,582.499207 
	C467.326324,580.990906 468.568268,579.593323 469.702881,578.231445 
z"
          />
          <path
            fill="#38459A"
            opacity="1.000000"
            stroke="none"
            d="
M463.845978,649.229980 
	C464.413971,650.489502 464.673859,651.944214 464.853699,653.792969 
	C464.361725,652.599792 463.949799,651.012390 463.845978,649.229980 
z"
          />
          <path
            fill="#38459A"
            opacity="1.000000"
            stroke="none"
            d="
M464.699829,638.157288 
	C465.071045,638.869507 465.230957,639.785767 465.112976,640.879517 
	C464.719604,640.158386 464.604065,639.259888 464.699829,638.157288 
z"
          />
          <path
            fill="#38459A"
            opacity="1.000000"
            stroke="none"
            d="
M524.430420,654.221924 
	C524.327454,653.990173 524.475159,653.660461 524.901123,653.276123 
	C525.013306,653.522278 524.847168,653.823181 524.430420,654.221924 
z"
          />
          <path
            fill="#141F8A"
            opacity="1.000000"
            stroke="none"
            d="
M710.170776,609.322388 
	C707.718811,612.474854 705.266846,615.627380 702.171387,618.959412 
	C701.021057,620.082153 700.514282,621.025391 700.007568,621.968628 
	C700.007568,621.968628 700.009705,622.009460 699.628967,622.012451 
	C698.529541,622.747009 697.810852,623.478516 697.092224,624.210022 
	C695.641479,625.765381 694.190735,627.320740 692.395325,628.603882 
	C694.079041,624.541931 695.705261,620.453918 698.238647,617.038757 
	C701.330017,612.871460 705.326721,609.385620 708.605042,605.340210 
	C709.411194,604.345398 709.619812,601.706787 708.902832,600.925903 
	C707.973938,599.914185 705.837402,600.026611 704.230591,599.616638 
	C703.173340,599.346863 702.150330,598.835327 701.082764,598.730835 
	C694.765991,598.112549 688.392944,596.916992 682.142822,597.365356 
	C679.810547,597.532654 677.258484,601.307739 675.676270,603.957031 
	C671.592163,610.795410 665.059814,616.168884 663.494568,624.583435 
	C663.192810,626.205933 661.022278,627.444336 659.816101,628.942261 
	C656.976257,632.468994 654.198120,636.045471 651.395081,639.601868 
	C651.824890,640.013672 652.254700,640.425415 652.684509,640.837219 
	C654.408997,640.513367 656.600708,640.749084 657.776062,639.767639 
	C662.562744,635.770569 668.126587,636.047913 673.772827,635.868652 
	C675.574463,635.811462 677.805176,634.777954 679.019958,633.444519 
	C682.664612,629.443970 687.420715,629.532837 692.109741,629.104065 
	C686.422302,632.466248 681.057739,636.693909 674.964233,638.967896 
	C667.630737,641.704590 659.691345,642.843994 651.983032,644.532471 
	C649.931030,644.981873 647.764893,644.909851 644.119324,645.198486 
	C647.170410,640.866211 649.643250,637.704834 651.742493,634.312256 
	C659.935791,621.071106 668.048279,607.779297 676.103821,594.453735 
	C678.152954,591.063965 681.003967,591.813782 684.002747,592.344177 
	C689.026794,593.232910 694.057312,594.110046 699.111816,594.795593 
	C705.623352,595.678711 712.161133,596.368347 720.522705,597.358704 
	C717.672485,600.902161 715.714600,603.336304 713.091614,605.934814 
	C711.674622,607.173645 710.922729,608.247986 710.170776,609.322388 
z"
          />
          <path
            fill="#182893"
            opacity="1.000000"
            stroke="none"
            d="
M710.490723,609.211853 
	C710.922729,608.247986 711.674622,607.173645 712.798462,606.100464 
	C712.383728,607.101562 711.597168,608.101440 710.490723,609.211853 
z"
          />
          <path
            fill="#182893"
            opacity="1.000000"
            stroke="none"
            d="
M697.403687,624.137573 
	C697.810852,623.478516 698.529541,622.747009 699.608521,622.011536 
	C699.217590,622.693420 698.466370,623.379272 697.403687,624.137573 
z"
          />
          <path
            fill="#182893"
            opacity="1.000000"
            stroke="none"
            d="
M700.298401,621.846497 
	C700.514282,621.025391 701.021057,620.082153 701.897888,619.147156 
	C701.708374,620.011719 701.148865,620.868042 700.298401,621.846497 
z"
          />
          <path
            fill="#0E35B2"
            opacity="1.000000"
            stroke="none"
            d="
M412.048370,680.969360 
	C412.471405,680.965759 412.894440,680.962097 413.730469,681.341736 
	C415.127228,682.148926 416.102631,682.911438 417.096191,682.935852 
	C420.673615,683.023865 423.606201,683.922974 425.125153,687.572754 
	C425.381470,688.188599 426.466492,688.411255 426.905426,689.008911 
	C428.014709,690.519226 429.602997,692.041687 429.866791,693.735718 
	C430.475342,697.642883 430.527252,701.654480 430.535736,705.624329 
	C430.537720,706.559692 429.683502,707.980103 428.859406,708.336609 
	C426.948425,709.163269 424.816254,709.480408 422.771820,709.996521 
	C420.318787,710.615662 417.863739,711.226990 415.409576,711.841675 
	C413.692444,712.252747 411.864410,711.352356 409.947052,711.079224 
	C404.799438,710.345947 401.761902,707.398376 399.886139,702.722229 
	C399.442688,701.616638 397.950867,700.931641 396.941315,700.053162 
	C396.941315,700.053162 396.997803,699.998535 396.951294,699.667908 
	C395.275848,695.361023 393.196899,691.496033 392.176697,687.369263 
	C391.500214,684.632751 393.417633,682.819641 396.682922,682.962646 
	C400.503021,683.129944 404.348145,683.172363 408.157104,682.906494 
	C409.492767,682.813232 410.753448,681.646057 412.048370,680.969360 
z"
          />
          <path
            fill="#102CA8"
            opacity="1.000000"
            stroke="none"
            d="
M396.923401,700.442444 
	C397.950867,700.931641 399.442688,701.616638 399.886139,702.722229 
	C401.761902,707.398376 404.799438,710.345947 409.947052,711.079224 
	C411.864410,711.352356 413.692444,712.252747 415.409576,711.841675 
	C417.863739,711.226990 420.318787,710.615662 422.771820,709.996521 
	C424.816254,709.480408 426.948425,709.163269 428.859406,708.336609 
	C429.683502,707.980103 430.537720,706.559692 430.535736,705.624329 
	C430.527252,701.654480 430.475342,697.642883 429.866791,693.735718 
	C429.602997,692.041687 428.014709,690.519226 426.905426,689.008911 
	C426.466492,688.411255 425.381470,688.188599 425.125153,687.572754 
	C423.606201,683.922974 420.673615,683.023865 417.096191,682.935852 
	C416.102631,682.911438 415.127228,682.148926 414.047729,681.347046 
	C419.028229,681.292908 424.103119,681.639709 429.181091,681.933350 
	C433.047211,682.156860 432.727936,685.269165 433.037109,687.716919 
	C433.550323,691.780090 433.750793,695.882874 434.050232,700.874756 
	C434.011780,705.738770 434.241180,709.719849 433.859497,713.641479 
	C433.742432,714.844177 431.946167,716.850342 430.927368,716.845154 
	C422.886871,716.803406 414.848450,716.363770 406.809387,716.046509 
	C404.154572,712.973511 401.287872,710.052185 398.934967,706.763184 
	C397.770325,705.135254 397.551971,702.830322 396.923401,700.442444 
z"
          />
          <path
            fill="#141F8A"
            opacity="1.000000"
            stroke="none"
            d="
M406.900330,716.440002 
	C414.848450,716.363770 422.886871,716.803406 430.927368,716.845154 
	C431.946167,716.850342 433.742432,714.844177 433.859497,713.641479 
	C434.241180,709.719849 434.011780,705.738770 434.040741,701.291260 
	C434.998199,702.561035 436.555145,704.257446 436.739441,706.091797 
	C437.147736,710.155029 436.866577,714.287476 436.866577,719.044861 
	C428.013245,719.044861 419.475220,719.139832 410.943787,718.945984 
	C409.610962,718.915710 408.308044,717.570618 406.900330,716.440002 
z"
          />
          <path
            fill="#102CA8"
            opacity="1.000000"
            stroke="none"
            d="
M411.574951,680.951599 
	C410.753448,681.646057 409.492767,682.813232 408.157104,682.906494 
	C404.348145,683.172363 400.503021,683.129944 396.682922,682.962646 
	C393.417633,682.819641 391.500214,684.632751 392.176697,687.369263 
	C393.196899,691.496033 395.275848,695.361023 396.925720,699.696533 
	C392.462524,698.024475 387.408539,688.166870 389.278717,684.087769 
	C389.961761,682.598022 392.462616,681.203491 394.224274,681.083069 
	C399.827209,680.699829 405.472565,680.936646 411.574951,680.951599 
z"
          />
          <path
            fill="#182893"
            opacity="1.000000"
            stroke="none"
            d="
M568.439758,614.554016 
	C568.480408,614.213074 568.520996,613.872131 568.943909,613.138062 
	C569.886597,610.499634 570.447021,608.254456 571.007446,606.009216 
	C571.101318,605.297363 571.195251,604.585571 571.667786,603.388367 
	C572.780762,600.290588 573.515137,597.678101 574.249512,595.065674 
	C574.249023,594.635437 574.248535,594.205200 574.636719,593.337769 
	C581.608948,581.708069 577.793579,583.749939 591.075684,584.644958 
	C598.158386,585.122253 605.310913,584.564758 612.431885,584.474365 
	C613.065674,584.531799 613.699463,584.589294 614.885864,584.996277 
	C622.906189,586.212219 630.368958,587.127075 637.847412,587.887817 
	C638.860229,587.990906 639.951660,587.320984 641.006714,587.008972 
	C641.722168,587.057129 642.437683,587.105286 643.652588,587.568909 
	C644.551636,588.253235 644.923279,588.695740 645.354919,588.765015 
	C652.334961,589.886292 652.202515,589.930237 649.015991,596.371277 
	C647.124817,600.193848 646.018616,604.404724 644.559448,608.441040 
	C644.559448,608.441040 644.432617,608.823914 643.952698,608.972656 
	C641.789062,611.856079 640.187927,614.646240 638.399658,617.310791 
	C636.395325,620.297363 634.221680,623.170288 632.111450,625.729919 
	C632.730835,623.576355 633.360535,621.785461 634.143494,619.653687 
	C634.877014,611.863464 642.547058,606.843079 641.364929,598.740845 
	C641.279297,598.154175 642.264465,597.420166 642.733459,596.742065 
	C644.586243,594.063049 644.815186,592.231506 640.680054,591.947754 
	C633.769836,591.473511 626.869141,590.835510 619.975342,590.155029 
	C609.828186,589.153442 599.705994,587.847473 589.542114,587.086426 
	C584.731873,586.726257 577.893066,592.980408 576.872375,597.519348 
	C575.172119,605.080505 573.353394,612.617798 571.416992,620.121887 
	C570.469543,623.793579 569.144897,627.367920 567.671997,631.033997 
	C566.904419,631.724731 566.455872,632.367737 565.967834,633.357605 
	C565.288879,635.137146 564.649353,636.569824 563.743408,638.109985 
	C562.954834,638.450134 562.432678,638.682678 561.902344,638.586426 
	C561.592102,637.506958 561.290222,636.756348 560.988281,636.005737 
	C561.074524,635.294495 561.160828,634.583252 561.667175,633.438110 
	C565.464905,627.287964 569.173218,621.685669 568.439758,614.554016 
z"
          />
          <path
            fill="#141F8A"
            opacity="1.000000"
            stroke="none"
            d="
M602.800415,651.588440 
	C597.179565,651.724915 591.558716,651.861450 585.185303,651.694946 
	C575.113220,651.391968 565.793762,651.391968 555.709839,651.391968 
	C557.578613,647.022522 559.290527,643.019958 561.000244,639.353516 
	C561.010437,640.138489 561.022705,640.587402 561.030823,641.449829 
	C561.018921,643.242310 561.011108,644.621155 560.770386,646.171387 
	C561.024170,647.226807 561.510864,648.110840 562.420288,648.996216 
	C567.899963,648.990356 572.956848,648.983154 578.385742,648.980103 
	C579.501770,648.982605 580.245789,648.980957 581.453369,648.987671 
	C589.664490,649.018860 597.412048,649.041687 604.985291,649.307251 
	C604.140747,650.229431 603.470581,650.908936 602.800415,651.588440 
z"
          />
          <path
            fill="#535B9F"
            opacity="1.000000"
            stroke="none"
            d="
M603.230774,651.729797 
	C603.470581,650.908936 604.140747,650.229431 605.192444,649.304443 
	C605.573975,649.058838 605.988892,649.015259 606.429199,649.042969 
	C617.000244,649.072266 621.183838,646.793518 622.596924,640.979553 
	C623.219604,640.233765 623.626770,639.650391 624.341370,638.902100 
	C627.124146,634.510681 629.599426,630.284241 632.085510,626.063110 
	C632.096313,626.068481 632.121704,626.092651 632.121704,626.092651 
	C634.221680,623.170288 636.395325,620.297363 638.399658,617.310791 
	C640.187927,614.646240 641.789062,611.856079 643.836182,609.137207 
	C640.986694,615.437195 637.765015,621.716736 634.568481,628.009094 
	C633.912842,629.299622 633.351868,630.638184 632.763062,632.314880 
	C632.778748,632.675171 632.808960,632.779297 632.755005,632.789795 
	C629.649780,636.579956 625.989075,640.031494 623.725830,644.235352 
	C621.639282,648.110901 618.718811,649.769348 614.913086,650.467590 
	C611.201477,651.148438 607.414856,651.419983 603.230774,651.729797 
z"
          />
          <path
            fill="#535B9F"
            opacity="1.000000"
            stroke="none"
            d="
M612.207397,584.170776 
	C605.310913,584.564758 598.158386,585.122253 591.075684,584.644958 
	C577.793579,583.749939 581.608948,581.708069 574.724792,593.026611 
	C574.881165,590.271729 574.935425,587.237183 575.952942,584.570129 
	C576.521790,583.079285 578.753052,581.130493 580.126404,581.212341 
	C590.760193,581.846313 601.367432,582.925720 612.207397,584.170776 
z"
          />
          <path
            fill="#535B9F"
            opacity="1.000000"
            stroke="none"
            d="
M561.002441,639.017395 
	C559.290527,643.019958 557.578613,647.022522 555.709839,651.391968 
	C565.793762,651.391968 575.113220,651.391968 584.717712,651.685242 
	C574.620483,654.567139 564.165588,655.718994 552.416504,654.300293 
	C555.350769,647.612793 557.839478,641.940796 560.658264,636.137207 
	C561.290222,636.756348 561.592102,637.506958 561.924683,638.590210 
	C561.955383,638.922791 561.925903,638.918945 561.693604,638.929688 
	C561.461243,638.940369 561.002441,639.017395 561.002441,639.017395 
z"
          />
          <path
            fill="#535B9F"
            opacity="1.000000"
            stroke="none"
            d="
M644.835999,608.257324 
	C646.018616,604.404724 647.124817,600.193848 649.015991,596.371277 
	C652.202515,589.930237 652.334961,589.886292 645.354919,588.765015 
	C644.923279,588.695740 644.551636,588.253235 644.003052,587.645386 
	C647.357361,587.306458 650.860718,587.306458 656.041077,587.306458 
	C651.839417,595.290771 648.476013,601.682190 644.835999,608.257324 
z"
          />
          <path
            fill="#535B9F"
            opacity="1.000000"
            stroke="none"
            d="
M640.798340,586.680054 
	C639.951660,587.320984 638.860229,587.990906 637.847412,587.887817 
	C630.368958,587.127075 622.906189,586.212219 615.203552,585.017212 
	C623.509033,585.242859 632.049500,585.796997 640.798340,586.680054 
z"
          />
          <path
            fill="#535B9F"
            opacity="1.000000"
            stroke="none"
            d="
M568.160645,614.751099 
	C569.173218,621.685669 565.464905,627.287964 561.767151,633.094238 
	C563.591919,627.105652 565.736694,621.026978 568.160645,614.751099 
z"
          />
          <path
            fill="#535B9F"
            opacity="1.000000"
            stroke="none"
            d="
M573.918396,595.219971 
	C573.515137,597.678101 572.780762,600.290588 571.756592,603.040283 
	C572.173584,600.576538 572.880432,597.975403 573.918396,595.219971 
z"
          />
          <path
            fill="#535B9F"
            opacity="1.000000"
            stroke="none"
            d="
M570.717773,606.186768 
	C570.447021,608.254456 569.886597,610.499634 568.983643,612.883545 
	C569.236755,610.802917 569.832397,608.583618 570.717773,606.186768 
z"
          />
          <path
            fill="#244DAE"
            opacity="1.000000"
            stroke="none"
            d="
M740.105652,245.232330 
	C740.645447,245.008881 741.185303,244.785446 741.875122,244.309662 
	C742.752441,244.081207 743.479736,244.105072 744.668091,244.567719 
	C752.542664,247.878662 758.684814,243.734879 765.150818,241.378510 
	C767.597900,241.405991 770.044922,241.433472 773.151367,241.235886 
	C776.573547,240.120697 779.336365,239.230591 782.099243,238.340485 
	C783.786865,238.233994 785.474487,238.127487 787.850952,238.300415 
	C790.545166,238.875031 792.550476,239.170181 794.555847,239.465347 
	C794.629089,239.928024 794.702332,240.390701 794.775635,240.853378 
	C789.559998,242.588882 784.280762,244.160248 779.161438,246.144196 
	C777.233093,246.891525 775.675171,248.594574 773.948364,249.861938 
	C772.548828,250.251160 771.149353,250.640381 769.193176,250.799561 
	C764.709839,251.463486 760.712097,252.135925 756.891296,253.360306 
	C755.360901,253.850739 754.219910,255.556229 752.901672,256.708649 
	C752.178284,256.808380 751.454834,256.908112 750.160706,256.766663 
	C747.366455,257.602112 745.142883,258.678741 742.919373,259.755371 
	C742.496155,259.840485 742.072998,259.925598 741.089600,259.748779 
	C736.678833,260.975861 732.828186,262.464874 728.977600,263.953918 
	C720.187622,266.243469 711.404968,268.561798 702.602478,270.802185 
	C700.411133,271.359955 698.155640,271.665771 695.112671,272.068359 
	C690.529114,273.450043 686.762390,274.850739 682.995728,276.251404 
	C682.009705,276.277069 681.023621,276.302734 679.296814,276.220825 
	C678.035400,276.042267 677.514709,275.971313 677.053833,275.531433 
	C677.780701,274.489838 678.338562,273.429718 679.132263,273.205872 
	C685.399414,271.438110 691.743103,269.939697 698.002014,268.144745 
	C705.927307,265.871857 713.753967,263.249298 721.700256,261.057129 
	C728.129028,259.283630 734.754700,258.202240 741.140808,256.302155 
	C747.502441,254.409348 753.674561,251.879257 760.749573,249.333649 
	C752.537415,246.622864 745.495911,249.273727 738.358276,249.983688 
	C732.594543,250.556961 726.785767,250.677444 720.997009,250.998779 
	C721.869629,250.504593 722.742188,250.010422 724.237000,249.269806 
	C728.226196,248.642212 731.593262,248.261063 735.360107,247.921432 
	C737.208496,247.052750 738.657104,246.142532 740.105652,245.232330 
z"
          />
          <path
            fill="#5267AC"
            opacity="1.000000"
            stroke="none"
            d="
M774.333435,249.916626 
	C775.675171,248.594574 777.233093,246.891525 779.161438,246.144196 
	C784.280762,244.160248 789.559998,242.588882 794.775635,240.853378 
	C794.702332,240.390701 794.629089,239.928024 794.555847,239.465347 
	C792.550476,239.170181 790.545166,238.875031 788.275024,238.306702 
	C790.823792,237.242477 793.604858,236.298615 796.457520,235.691269 
	C802.928589,234.313538 809.425598,233.050568 815.934021,231.861160 
	C817.391602,231.594788 818.939331,231.822021 820.445557,231.822021 
	C820.536133,232.364685 820.626709,232.907333 820.717285,233.449997 
	C805.384338,238.957092 790.051453,244.464203 774.333435,249.916626 
z"
          />
          <path
            fill="#507ABE"
            opacity="1.000000"
            stroke="none"
            d="
M764.725708,241.265839 
	C758.684814,243.734879 752.542664,247.878662 745.025757,244.636276 
	C745.354126,244.108475 745.785950,243.950882 746.555298,243.706955 
	C749.950317,243.233871 753.012512,242.880936 756.063843,242.450409 
	C758.815674,242.062149 761.555481,241.588898 764.725708,241.265839 
z"
          />
          <path
            fill="#5267AC"
            opacity="1.000000"
            stroke="none"
            d="
M753.285400,256.784851 
	C754.219910,255.556229 755.360901,253.850739 756.891296,253.360306 
	C760.712097,252.135925 764.709839,251.463486 768.831299,250.845703 
	C763.907104,253.034927 758.788147,254.947983 753.285400,256.784851 
z"
          />
          <path
            fill="#5267AC"
            opacity="1.000000"
            stroke="none"
            d="
M729.375488,263.992737 
	C732.828186,262.464874 736.678833,260.975861 740.765747,259.764160 
	C737.259216,261.371521 733.516235,262.701538 729.375488,263.992737 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M683.261597,276.443909 
	C686.762390,274.850739 690.529114,273.450043 694.693726,272.073975 
	C691.236938,273.611176 687.382202,275.123779 683.261597,276.443909 
z"
          />
          <path
            fill="#5267AC"
            opacity="1.000000"
            stroke="none"
            d="
M781.688721,238.234406 
	C779.336365,239.230591 776.573547,240.120697 773.390137,240.990189 
	C775.739136,240.022491 778.508667,239.075409 781.688721,238.234406 
z"
          />
          <path
            fill="#5267AC"
            opacity="1.000000"
            stroke="none"
            d="
M743.290222,259.842133 
	C745.142883,258.678741 747.366455,257.602112 749.794922,256.775208 
	C747.886902,257.992889 745.773987,258.960907 743.290222,259.842133 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M676.994080,275.900391 
	C677.514709,275.971313 678.035400,276.042267 678.928711,276.253510 
	C677.023438,277.407379 674.745483,278.420959 672.235474,279.248962 
	C672.891602,278.213043 673.779846,277.362671 675.168579,276.248108 
	C676.110718,275.956085 676.552429,275.928253 676.994080,275.900391 
z"
          />
          <path
            fill="#08229F"
            opacity="1.000000"
            stroke="none"
            d="
M692.808411,567.115845 
	C691.794739,567.080811 690.781067,567.045776 689.296875,566.825745 
	C688.546753,566.739563 688.267151,566.838379 687.987610,566.937195 
	C686.612000,566.937134 685.236328,566.937073 683.450806,566.892212 
	C680.540771,566.083008 678.040649,565.318481 675.360657,563.885864 
	C676.997742,562.811890 678.804626,562.115356 680.633545,562.051941 
	C687.320496,561.820374 689.396606,559.501404 688.630127,553.324646 
	C686.900818,552.085266 685.452881,551.047546 684.420898,550.007019 
	C686.890198,549.687073 688.943481,549.369812 691.369568,549.052368 
	C692.486023,549.086365 693.229675,549.120605 694.050171,549.499512 
	C696.688477,553.559509 700.472778,553.182312 704.238953,552.865784 
	C704.238953,552.865784 704.589661,552.829468 704.764221,552.843506 
	C709.864990,554.608948 714.790283,556.362915 719.718201,558.109558 
	C720.500000,558.386719 721.429016,558.448853 722.060486,558.918762 
	C728.395020,563.633301 736.144287,563.372437 743.316406,565.157715 
	C744.300598,565.402710 745.000122,566.791260 745.831482,567.650391 
	C744.552002,568.757141 743.341431,570.702087 741.980286,570.813904 
	C737.865479,571.151917 733.678101,570.962341 729.544556,570.645874 
	C719.388000,569.868164 709.247192,568.885986 699.094604,568.052734 
	C698.324463,567.989502 697.511169,568.453186 696.103638,568.836304 
	C695.318481,569.017090 695.147583,569.033447 694.976624,569.049805 
	C694.976624,569.049805 694.988037,569.014648 694.942749,568.683411 
	C694.201050,567.940063 693.504761,567.527954 692.808411,567.115845 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M695.015930,569.290100 
	C695.147583,569.033447 695.318481,569.017090 695.747131,568.996033 
	C695.934021,569.253296 695.809753,569.486755 695.480469,569.812561 
	C695.328796,569.933350 695.055237,569.530396 695.015930,569.290100 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M431.862396,616.633057 
	C431.575775,619.616943 431.082672,622.196167 431.056183,624.780090 
	C430.959320,634.232849 431.017822,643.687134 431.017822,654.617859 
	C421.671722,654.759705 411.686646,654.911194 401.229309,655.208984 
	C397.478119,655.313904 394.199188,655.272583 390.202271,654.896851 
	C385.751007,654.384277 382.019318,654.113953 378.284027,654.048889 
	C372.128082,653.941589 371.485199,653.393921 371.142853,646.937378 
	C371.951935,646.549438 372.479980,646.272278 373.322083,646.157959 
	C377.491821,653.718323 384.437042,651.937195 390.703064,651.985352 
	C400.845306,652.063416 410.988617,652.004578 421.131500,651.996155 
	C427.567993,651.990845 427.879059,651.610046 428.021332,645.295288 
	C428.293945,633.197327 428.656677,621.101440 429.332367,609.002930 
	C430.150391,608.994446 430.618011,608.987610 431.085663,608.980835 
	C431.347107,611.397400 431.608551,613.814026 431.862396,616.633057 
z"
          />
          <path
            fill="#5267AC"
            opacity="1.000000"
            stroke="none"
            d="
M361.070465,620.980591 
	C358.717041,612.121155 356.354553,603.264160 354.015289,594.401001 
	C353.221771,591.394287 352.592804,588.340332 351.699463,585.364746 
	C350.478424,581.297607 351.660980,579.328918 355.880310,579.061340 
	C361.968414,578.675232 368.063751,578.403442 374.495422,578.506470 
	C372.848969,579.910828 370.863098,580.891541 368.570618,581.880310 
	C367.852570,581.877441 367.441132,581.866577 366.757690,581.626160 
	C364.193634,581.581116 361.892059,581.994385 359.611694,581.899170 
	C355.729523,581.737061 354.314209,583.695740 355.051880,587.127686 
	C357.196869,597.106995 359.551361,607.041321 361.847687,617.352844 
	C361.604645,618.801819 361.337555,619.891174 361.070465,620.980591 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M424.561340,578.092407 
	C426.004272,578.869080 426.976227,579.618958 427.595551,580.689453 
	C426.489197,581.015198 425.735443,581.020203 424.546021,581.017334 
	C422.072723,581.009705 420.035156,581.010071 417.553833,581.002441 
	C414.740967,580.998779 412.371857,581.003052 409.533875,581.002258 
	C400.042938,581.008118 391.020874,581.018982 381.582397,581.021729 
	C379.777435,581.011719 378.388947,581.009888 377.000793,580.704407 
	C377.030701,579.801636 377.060303,579.202515 377.089905,578.603333 
	C392.756683,578.424072 408.423523,578.244873 424.561340,578.092407 
z"
          />
          <path
            fill="#5267AC"
            opacity="1.000000"
            stroke="none"
            d="
M370.861816,647.048218 
	C371.485199,653.393921 372.128082,653.941589 378.284027,654.048889 
	C382.019318,654.113953 385.751007,654.384277 389.748596,654.896606 
	C384.461548,655.421448 378.896851,655.880310 373.368896,655.603943 
	C372.071045,655.539062 370.523773,653.166565 369.753510,651.541809 
	C368.570984,649.047485 367.962097,646.281189 367.497742,643.342163 
	C368.877747,644.385437 369.869781,645.716797 370.861816,647.048218 
z"
          />
          <path
            fill="#5267AC"
            opacity="1.000000"
            stroke="none"
            d="
M366.992065,641.008423 
	C365.080322,635.860718 363.168518,630.713074 361.649048,625.221802 
	C362.677216,624.935425 363.313049,624.992493 363.967621,625.442627 
	C365.303009,629.247864 366.619690,632.660156 367.937256,636.463013 
	C367.972809,637.893433 368.007416,638.933228 367.921936,640.198486 
	C367.531921,640.618774 367.261993,640.813599 366.992065,641.008423 
z"
          />
          <path
            fill="#183DA3"
            opacity="1.000000"
            stroke="none"
            d="
M367.026855,641.319946 
	C367.261993,640.813599 367.531921,640.618774 368.276794,640.247681 
	C371.337891,641.207153 373.554901,642.608215 373.007996,645.995117 
	C372.479980,646.272278 371.951935,646.549438 371.142853,646.937378 
	C369.869781,645.716797 368.877747,644.385437 367.585693,642.957703 
	C367.211029,642.451477 367.136353,642.041504 367.026855,641.319946 
z"
          />
          <path
            fill="#183DA3"
            opacity="1.000000"
            stroke="none"
            d="
M363.948914,625.049561 
	C363.313049,624.992493 362.677216,624.935425 361.726196,624.848145 
	C361.299103,623.797241 361.187195,622.776428 361.072876,621.368164 
	C361.337555,619.891174 361.604645,618.801819 362.140198,617.347168 
	C362.602478,616.977051 362.796326,616.972046 363.276398,617.250244 
	C363.718658,620.012024 363.874695,622.490601 364.010376,624.989380 
	C363.990021,625.009583 363.948914,625.049622 363.948914,625.049561 
z"
          />
          <path
            fill="#244DAE"
            opacity="1.000000"
            stroke="none"
            d="
M376.782074,578.430786 
	C377.060303,579.202515 377.030701,579.801636 376.716675,580.986755 
	C373.913879,581.672546 371.395569,581.772400 368.877228,581.872253 
	C370.863098,580.891541 372.848969,579.910828 374.956299,578.604004 
	C375.543243,578.271240 376.008759,578.264709 376.782074,578.430786 
z"
          />
          <path
            fill="#174ABD"
            opacity="1.000000"
            stroke="none"
            d="
M369.468750,552.004517 
	C368.504211,551.742859 368.008301,551.478333 367.311310,550.762390 
	C369.430603,547.945068 372.915466,545.952454 373.819305,543.132629 
	C375.493622,537.909119 378.380768,536.637634 383.357819,536.827271 
	C385.343048,536.902893 388.175110,535.562134 389.241364,533.952026 
	C390.750458,531.673401 390.981049,528.548035 391.938354,525.143311 
	C386.716797,524.742554 381.847412,524.368835 377.057831,523.622070 
	C384.093628,522.142395 391.049622,521.035767 398.005615,519.929077 
	C425.021515,519.944153 452.037415,519.959229 479.520569,519.979797 
	C479.380768,524.420288 480.983765,526.498962 485.768158,526.076782 
	C489.625671,525.736389 493.537170,526.007812 497.424683,526.007812 
	C497.545074,526.498230 497.665436,526.988708 497.785797,527.479126 
	C496.659332,527.981934 495.559296,528.830261 494.401123,528.919006 
	C491.592194,529.134155 488.754578,529.056274 485.931427,528.983948 
	C482.764709,528.902832 481.393311,529.790405 479.487915,533.102356 
	C477.248840,536.994263 472.327606,539.447266 471.013672,544.563965 
	C470.911072,544.963623 468.871979,544.967102 467.730804,544.980774 
	C463.742920,545.028442 459.747314,545.145081 455.767609,544.958984 
	C451.797058,544.773315 448.574982,545.208496 447.326782,549.891174 
	C447.065765,550.870361 444.816956,551.766357 443.407410,551.901855 
	C440.278687,552.202576 437.101349,551.996948 433.471924,551.998779 
	C433.000061,551.999756 433.002350,551.982910 433.002350,551.982910 
	C429.547455,548.007935 422.676025,547.687500 417.077026,551.611572 
	C401.315430,551.986877 385.626404,551.994324 369.468750,552.004517 
z"
          />
          <path
            fill="#123CB5"
            opacity="1.000000"
            stroke="none"
            d="
M520.000305,553.957886 
	C517.958374,553.973267 515.916504,553.988647 513.437622,553.980835 
	C511.889069,552.973999 510.846680,551.254517 509.653046,551.142212 
	C505.774292,550.777161 501.840210,551.000488 497.459351,550.990234 
	C497.527161,547.766418 497.914734,545.324707 501.959839,543.523743 
	C508.420166,540.647339 514.426453,536.236450 518.272827,529.900269 
	C520.022400,527.018250 517.668396,525.502991 515.223694,525.087708 
	C510.018280,524.203613 504.735138,523.785217 499.517365,522.963135 
	C496.038147,522.414978 492.616791,521.499512 489.080109,520.361084 
	C495.200684,520.099670 501.430389,519.959290 507.615723,520.417725 
	C515.680115,521.015503 523.704651,522.134766 531.755493,522.937622 
	C536.197693,523.380615 540.658630,523.635193 545.632690,524.362183 
	C548.433105,525.176758 550.711975,525.603943 553.006958,526.415771 
	C554.060913,527.514404 555.098816,528.228455 556.136719,528.942505 
	C556.758972,527.968262 557.381165,526.993958 558.348511,526.048462 
	C559.795715,526.390808 560.897827,526.704346 562.000000,527.456726 
	C563.084961,531.890381 564.332581,535.852173 565.153809,539.900513 
	C565.394043,541.084717 564.726624,543.426086 563.954956,543.684692 
	C558.944031,545.363892 553.811523,546.702759 548.675537,547.979187 
	C544.144653,549.105225 539.557434,550.005127 534.568237,551.004395 
	C532.432434,551.003418 530.723511,551.002930 528.566040,551.001343 
	C526.341309,551.002869 524.457581,550.645203 522.826111,551.132446 
	C521.703064,551.467896 520.929871,552.974609 520.000305,553.957886 
z"
          />
          <path
            fill="#305CB3"
            opacity="1.000000"
            stroke="none"
            d="
M398.004883,519.615723 
	C391.049622,521.035767 384.093628,522.142395 376.592957,523.619141 
	C363.691528,524.665710 351.334778,525.342163 338.584839,526.019287 
	C336.128662,526.344299 334.065674,526.668457 331.627075,526.993652 
	C330.162384,527.661560 329.073242,528.328369 327.521057,528.992798 
	C322.130554,529.361328 317.180481,529.547913 312.281219,530.148804 
	C305.957001,530.924622 299.659241,531.951233 293.382385,533.053772 
	C291.846130,533.323547 290.435608,534.308899 288.644836,534.963989 
	C287.892578,534.961365 287.462708,534.960022 286.767456,534.694336 
	C283.994781,534.949219 281.487518,535.468262 278.564117,535.987244 
	C275.476868,536.653320 272.796478,537.925903 270.136200,537.885193 
	C255.084991,537.655334 240.979599,542.883667 226.369476,545.133850 
	C225.895203,545.206909 225.356003,545.384277 225.011597,545.695251 
	C220.308762,549.941223 214.542755,548.947998 208.990326,548.679077 
	C209.006760,547.940613 209.019867,547.519104 209.444672,547.068848 
	C215.819931,545.884399 221.860626,545.017334 227.714691,543.452087 
	C230.675110,542.660645 233.298019,540.606812 236.073547,539.123718 
	C236.073532,539.123718 236.000046,538.939880 236.277893,538.995728 
	C237.035858,539.075012 237.515961,539.098328 238.275879,539.365540 
	C242.793869,538.886597 247.631454,540.501465 251.007446,536.416443 
	C251.452377,536.340698 251.897293,536.264954 252.971008,536.421326 
	C257.729462,536.451050 262.444763,538.299683 265.215057,533.336792 
	C265.912354,533.258545 266.609619,533.180298 267.811707,533.299194 
	C268.959686,533.156555 269.602936,532.816711 270.246155,532.476868 
	C271.896912,532.361023 273.547668,532.245117 275.616333,532.140381 
	C280.286499,531.454346 284.538727,530.757263 288.905212,529.724426 
	C290.404144,529.272095 291.788818,529.155579 293.812805,529.263794 
	C297.020203,528.744934 299.588287,528.001404 302.156372,527.257935 
	C303.033783,527.031067 303.911224,526.804260 304.892181,526.244141 
	C305.426453,525.866882 305.857178,525.822937 306.605652,525.711548 
	C313.612488,525.091614 320.303894,524.565857 326.989929,523.978943 
	C333.387238,523.417297 339.778900,522.791931 346.867950,522.435791 
	C350.425232,521.879028 353.287598,521.080933 356.149994,520.282776 
	C356.439697,520.155396 356.693115,519.976624 356.970337,519.521545 
	C358.095642,519.255066 359.160919,519.213501 360.618530,519.092590 
	C366.035858,519.049622 371.060791,519.085999 376.802490,519.363159 
	C379.005280,519.322754 380.491272,519.041687 381.977264,518.760620 
	C382.673309,518.747375 383.369324,518.734192 384.782715,518.778442 
	C386.729889,518.330505 387.959717,517.825134 389.189545,517.319824 
	C389.600983,517.281860 390.012390,517.243896 390.892090,517.418213 
	C391.968506,517.264160 392.576599,516.897766 393.184692,516.531372 
	C394.557770,516.480652 395.930847,516.429932 397.663513,516.596558 
	C398.016815,517.643433 398.010498,518.472839 398.004883,519.615723 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M552.990845,526.031128 
	C550.711975,525.603943 548.433105,525.176758 546.064941,524.369873 
	C547.157593,523.849426 548.339661,523.708618 550.213135,523.293213 
	C553.009277,522.850403 555.113892,522.682129 557.218567,522.513916 
	C559.187378,522.657593 561.156250,522.801208 563.539551,523.045166 
	C564.616150,524.205627 565.278320,525.265747 565.587891,526.659546 
	C564.156860,527.001465 563.078430,527.009644 562.000000,527.017883 
	C560.897827,526.704346 559.795715,526.390808 557.930908,526.041748 
	C555.775757,526.014526 554.383301,526.022827 552.990845,526.031128 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M557.044189,522.188232 
	C555.113892,522.682129 553.009277,522.850403 550.454346,523.030945 
	C548.353210,522.343201 546.702332,521.643188 544.930908,520.619873 
	C548.830139,520.818665 552.849976,521.340637 557.044189,522.188232 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M518.160217,519.124146 
	C518.890076,518.763489 519.778259,518.665161 520.830444,518.823853 
	C520.102417,519.182678 519.210510,519.284546 518.160217,519.124146 
z"
          />
          <path
            fill="#4453A0"
            opacity="1.000000"
            stroke="none"
            d="
M356.083008,716.713806 
	C353.930389,716.285217 351.506897,716.347900 349.674835,715.337891 
	C343.676178,712.030701 337.892578,708.333496 331.691742,704.702820 
	C323.220520,697.628296 314.986481,690.745911 306.996307,683.591248 
	C303.916595,680.833618 301.391083,677.457031 298.613068,674.362488 
	C298.898956,673.963135 299.184845,673.563843 299.470734,673.164551 
	C304.663055,673.488831 309.853485,673.848267 315.048096,674.130249 
	C326.631042,674.758972 338.211304,675.499084 349.803040,675.867065 
	C353.494476,675.984192 356.043823,676.908936 357.913330,680.277954 
	C360.388275,684.737915 363.314178,688.947632 365.695862,693.611328 
	C363.553009,692.302185 361.760529,690.646606 359.762085,688.974915 
	C359.556091,688.958740 359.142914,688.953186 359.116272,688.587036 
	C358.393494,686.804077 357.697357,685.387390 356.898071,683.758911 
	C356.606171,683.282288 356.358368,683.096008 355.992249,682.617676 
	C352.692871,676.867676 347.390106,679.726013 342.524170,678.995117 
	C333.155884,678.326660 324.251099,677.598999 315.334564,677.069397 
	C313.619690,676.967529 311.858643,677.643433 310.212982,677.943359 
	C314.416840,689.907715 325.012604,694.070251 333.279541,700.630493 
	C337.221008,703.758240 341.667603,706.249512 346.050781,709.330261 
	C349.505157,711.992249 352.794067,714.353027 356.083008,716.713806 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M356.486237,716.801636 
	C352.794067,714.353027 349.505157,711.992249 346.452698,709.326904 
	C348.269806,709.462708 350.117889,709.520142 351.386749,710.406677 
	C358.505890,715.380554 365.453888,713.237488 372.737946,710.684570 
	C367.811737,703.444397 361.124420,697.840759 359.968048,688.991089 
	C361.760529,690.646606 363.553009,692.302185 365.707764,693.968994 
	C366.070007,693.980347 366.250549,694.256897 366.181946,694.820557 
	C369.242096,699.904297 372.377136,704.419861 375.497406,708.945679 
	C377.964050,712.523438 380.412598,716.113708 382.869171,719.698425 
	C382.177185,719.727295 381.485229,719.756226 380.322083,719.372131 
	C373.722107,716.899780 367.750854,713.392822 361.015320,716.909546 
	C359.640045,716.902832 358.264771,716.896118 356.486237,716.801636 
z"
          />
          <path
            fill="#4453A0"
            opacity="1.000000"
            stroke="none"
            d="
M383.235840,719.831055 
	C380.412598,716.113708 377.964050,712.523438 375.497406,708.945679 
	C372.377136,704.419861 369.242096,699.904297 366.206238,694.984009 
	C370.697205,699.699585 375.003815,704.898315 379.531464,709.896912 
	C381.853699,712.460693 384.570679,714.666809 387.424377,717.138489 
	C386.360504,718.149475 384.981537,719.056519 383.235840,719.831055 
z"
          />
          <path
            fill="#182893"
            opacity="1.000000"
            stroke="none"
            d="
M704.132385,552.567627 
	C700.472778,553.182312 696.688477,553.559509 694.416626,549.452271 
	C696.475464,549.070862 698.244629,549.081421 700.013855,549.092041 
	C700.418457,549.121704 700.822998,549.151306 701.520203,549.263306 
	C702.550476,550.320251 703.288147,551.294922 704.132385,552.567627 
z"
          />
          <path
            fill="#044BAC"
            opacity="1.000000"
            stroke="none"
            d="
M671.450867,402.809082 
	C668.439941,401.034424 665.800842,399.213501 663.012024,397.661804 
	C658.683716,395.253540 654.369507,392.949921 652.330811,387.978363 
	C651.867615,386.848724 650.722778,385.998627 649.563232,384.949432 
	C649.179260,384.156342 649.123718,383.433716 649.364258,382.064423 
	C649.494263,378.286316 649.328186,375.154846 649.162109,372.023407 
	C649.158386,371.301422 649.154785,370.579437 649.217529,369.502869 
	C651.959961,366.442871 654.430176,363.488098 657.349670,361.077484 
	C667.270813,352.885834 678.246460,350.800171 690.809631,354.520264 
	C698.172485,356.700562 703.298035,360.889679 707.406555,366.921051 
	C707.870117,367.601593 708.309570,368.327606 708.598816,369.093475 
	C712.476013,379.360016 710.763855,381.933167 700.030518,382.062897 
	C698.872864,382.076874 697.716614,382.198914 696.043640,382.341370 
	C695.527588,382.411896 695.476685,382.250641 695.616699,381.811279 
	C696.879395,376.004211 694.231995,372.613800 690.430969,369.087921 
	C685.939270,364.921448 680.894531,366.392517 675.951965,366.087219 
	C672.375916,365.866333 669.961060,367.409821 668.757324,371.147705 
	C668.285400,371.858582 668.014771,372.363708 667.412842,372.975403 
	C664.017761,377.801178 664.522339,380.431763 668.993164,383.006287 
	C669.000000,383.000000 668.987671,383.013947 669.015442,383.355286 
	C671.016357,385.521423 672.226013,388.938965 676.305359,387.044556 
	C677.036011,387.123413 677.451843,387.184448 677.981873,387.560455 
	C680.726135,388.616455 683.356140,389.357452 686.386963,390.103058 
	C687.568970,389.993256 688.350159,389.878845 689.131409,389.764435 
	C689.131409,389.764435 689.012878,389.958405 688.960571,390.059509 
	C690.375610,391.610504 691.669556,393.313171 693.343994,394.460938 
	C697.629517,397.398529 702.420532,399.684723 706.386108,402.981201 
	C711.318604,407.081482 712.130493,417.514984 710.515869,423.618835 
	C708.922363,429.642853 703.995972,432.597900 700.936646,437.538452 
	C700.275085,438.018188 699.646423,438.157745 698.304321,438.235962 
	C694.723877,439.256500 691.856812,440.338379 688.989746,441.420288 
	C688.630554,441.430695 688.271423,441.441132 687.165588,441.288605 
	C681.965759,441.322906 677.512634,441.520111 673.059448,441.717346 
	C672.334534,441.706879 671.609619,441.696381 670.382141,441.307465 
	C663.403320,437.867096 656.667236,435.216675 652.334839,428.970551 
	C650.413635,426.200653 648.291321,423.570282 646.260254,420.876556 
	C646.194763,419.220764 646.129211,417.565002 646.104858,415.498047 
	C649.181335,414.375946 652.185120,413.311371 655.259216,413.038940 
	C659.628784,412.651703 663.097412,413.116608 663.521301,419.317108 
	C663.704102,421.991516 667.399902,425.394897 670.297791,426.714050 
	C676.452454,429.515747 683.281738,428.888153 689.502197,426.662537 
	C692.052185,425.750214 694.831055,422.792847 695.606079,420.198242 
	C696.956604,415.677429 695.554382,411.310059 690.279297,409.065674 
	C689.918762,409.090149 689.957214,409.074890 689.861389,408.853638 
	C689.571289,408.353302 689.311157,408.165192 688.836792,407.763123 
	C686.793091,406.967255 684.897766,406.476318 683.002441,405.985382 
	C682.293640,405.923584 681.584839,405.861816 680.394897,405.434143 
	C677.610962,404.378418 675.308228,403.688568 673.005432,402.998718 
	C672.588806,402.960327 672.172241,402.921906 671.450867,402.809082 
z"
          />
          <path
            fill="#4377BC"
            opacity="1.000000"
            stroke="none"
            d="
M646.147217,421.258606 
	C648.291321,423.570282 650.413635,426.200653 652.334839,428.970551 
	C656.667236,435.216675 663.403320,437.867096 670.031738,441.210449 
	C661.085022,441.851593 647.441406,431.239685 646.147217,421.258606 
z"
          />
          <path
            fill="#4377BC"
            opacity="1.000000"
            stroke="none"
            d="
M673.248657,442.069275 
	C677.512634,441.520111 681.965759,441.322906 686.896240,441.276550 
	C682.986938,445.384949 678.172974,443.350159 673.248657,442.069275 
z"
          />
          <path
            fill="#4377BC"
            opacity="1.000000"
            stroke="none"
            d="
M689.292847,441.608612 
	C691.856812,440.338379 694.723877,439.256500 697.985962,438.288208 
	C696.457397,442.133514 692.962280,441.798645 689.292847,441.608612 
z"
          />
          <path
            fill="#141F8A"
            opacity="1.000000"
            stroke="none"
            d="
M582.752869,702.641968 
	C586.248657,700.632935 589.860229,698.872253 593.109802,696.594971 
	C595.925720,694.621704 598.331360,692.062866 601.022278,689.447021 
	C605.547791,685.732849 610.240051,682.628479 614.326111,678.865845 
	C620.148987,673.503784 625.663391,668.449158 634.638367,669.649658 
	C638.083435,670.110413 641.815918,668.422363 645.417419,667.713440 
	C645.704407,668.250793 645.991333,668.788147 646.278259,669.325439 
	C644.423523,670.985229 642.767090,672.967163 640.681030,674.251038 
	C629.420654,681.180969 618.050354,687.932129 606.730713,694.765991 
	C605.901123,695.266724 605.171936,695.933960 604.331543,696.862366 
	C604.266968,697.200928 604.246582,697.253235 603.838135,697.194946 
	C597.889221,700.329407 592.348877,703.522156 586.808533,706.714905 
	C586.421448,706.812927 586.034302,706.910950 585.354980,707.072021 
	C583.932556,707.175720 582.747864,707.443970 581.688110,707.190308 
	C580.705566,706.955017 579.856506,706.162109 578.916809,705.246826 
	C578.884888,704.878967 579.277588,704.721375 579.817383,704.748657 
	C581.125549,704.099976 581.893799,703.424011 582.662109,702.748108 
	C582.662109,702.748108 582.711426,702.665955 582.752869,702.641968 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M594.545288,526.739380 
	C595.591125,530.555725 597.955200,530.375000 600.876404,529.082825 
	C601.515198,528.809570 601.940063,528.846313 602.761108,529.355347 
	C607.597107,530.725708 612.035278,531.631958 616.483521,532.486389 
	C616.631653,532.514832 616.880493,532.019226 617.082397,531.767944 
	C618.433594,531.906555 619.784729,532.045166 621.657593,532.631287 
	C624.804810,534.371765 627.430298,535.664795 629.792114,536.976929 
	C629.352783,537.004700 629.177124,537.013367 628.561523,537.015930 
	C618.410706,535.838440 608.695007,534.705627 598.991699,533.474670 
	C595.571777,533.040894 592.173462,532.415527 588.781128,531.790894 
	C583.961121,530.903442 579.135925,530.024536 574.355835,528.951050 
	C572.828491,528.608154 571.437439,527.658875 569.984009,526.987061 
	C573.125732,526.855530 576.267395,526.723999 579.702515,526.313904 
	C581.421631,526.035706 582.847412,526.036072 584.689636,525.927795 
	C585.513672,525.909668 585.921204,526.000183 586.675720,526.447144 
	C587.726990,526.690063 588.431274,526.576416 589.135559,526.462769 
	C590.831909,526.498047 592.528320,526.533325 594.545288,526.739380 
z"
          />
          <path
            fill="#182893"
            opacity="1.000000"
            stroke="none"
            d="
M690.996765,549.052612 
	C688.943481,549.369812 686.890198,549.687073 684.032043,549.955933 
	C676.324463,549.458740 670.840088,544.091614 663.427429,544.676331 
	C659.823792,544.960571 656.013062,542.929504 652.346069,541.786255 
	C651.757202,541.602661 651.431091,540.576111 651.352173,539.933594 
	C652.815491,539.607666 653.910156,539.287354 655.004822,538.967041 
	C656.693604,539.061340 658.382446,539.155701 660.603149,539.659790 
	C663.745972,540.755249 666.356873,541.441040 668.967773,542.126831 
	C671.355591,542.428955 673.743469,542.731079 676.537354,543.507141 
	C678.288513,544.343872 679.633484,544.706665 680.978516,545.069336 
	C682.013062,545.106934 683.047546,545.144531 684.557983,545.578857 
	C687.021484,547.001282 689.009094,548.026917 690.996765,549.052612 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M630.055847,536.957825 
	C627.430298,535.664795 624.804810,534.371765 622.044067,532.760132 
	C625.582031,532.905151 629.266968,533.292114 632.924072,533.858948 
	C635.461853,534.252380 637.961243,534.893250 640.460815,536.020752 
	C637.959351,536.744446 635.474792,536.870972 632.623108,536.987427 
	C631.522583,536.970886 630.789246,536.964355 630.055847,536.957825 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M654.789185,538.661865 
	C653.910156,539.287354 652.815491,539.607666 650.961182,539.886230 
	C647.820557,539.547485 645.439697,539.250549 643.048950,538.605652 
	C643.005798,537.564941 642.972351,536.872192 642.938965,536.179504 
	C646.817200,536.905212 650.695374,537.630920 654.789185,538.661865 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M617.003540,531.417480 
	C616.880493,532.019226 616.631653,532.514832 616.483521,532.486389 
	C612.035278,531.631958 607.597107,530.725708 603.067993,529.447021 
	C604.696106,529.056519 606.413452,529.046814 608.558411,528.989746 
	C611.632202,529.650574 614.278442,530.358765 617.003540,531.417480 
z"
          />
          <path
            fill="#5F629C"
            opacity="1.000000"
            stroke="none"
            d="
M691.369568,549.052368 
	C689.009094,548.026917 687.021484,547.001282 684.940369,545.646729 
	C689.743103,546.382568 694.639343,547.447327 699.774658,548.802002 
	C698.244629,549.081421 696.475464,549.070862 694.339783,549.107544 
	C693.229675,549.120605 692.486023,549.086365 691.369568,549.052368 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M642.621216,536.092529 
	C642.972351,536.872192 643.005798,537.564941 642.655945,538.668152 
	C638.790466,540.116028 635.327881,541.065613 632.990234,536.997559 
	C635.474792,536.870972 637.959351,536.744446 640.716553,536.327637 
	C641.427246,536.026733 641.865417,536.016174 642.621216,536.092529 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M668.797119,541.811096 
	C666.356873,541.441040 663.745972,540.755249 661.011597,539.764282 
	C663.467590,540.137878 666.046997,540.816650 668.797119,541.811096 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M680.817749,544.771240 
	C679.633484,544.706665 678.288513,544.343872 676.925354,543.640808 
	C678.157104,543.691345 679.407043,544.082214 680.817749,544.771240 
z"
          />
          <path
            fill="#3965B7"
            opacity="1.000000"
            stroke="none"
            d="
M680.547363,173.516113 
	C683.455383,174.719803 685.942566,175.766037 688.837280,176.690903 
	C690.869507,176.705841 692.494080,176.842133 694.580811,177.360321 
	C696.360107,178.154312 697.677368,178.566391 698.994629,178.978470 
	C699.402832,179.053329 699.810974,179.128204 700.638550,179.586899 
	C703.019226,181.005325 704.980591,182.039948 706.941895,183.074554 
	C707.939697,184.125702 708.937561,185.176849 710.020020,186.872437 
	C711.050476,188.363312 711.996338,189.209747 712.942200,190.056183 
	C712.942200,190.056183 713.101135,190.399200 713.127686,190.926544 
	C713.801453,191.944839 714.448608,192.435806 715.095825,192.926788 
	C718.736145,197.711975 722.376465,202.497177 725.652588,207.616852 
	C722.530090,206.287186 719.771912,204.623062 716.746948,202.733826 
	C715.848328,201.008163 715.419617,199.376617 714.550720,198.028854 
	C709.820312,190.690872 702.861328,185.861938 695.360718,181.847000 
	C693.686157,180.950653 691.167969,181.785049 689.125977,181.380112 
	C686.739624,180.906876 683.985413,180.517914 682.218323,179.077332 
	C678.995850,176.450333 674.610962,175.948151 672.138367,178.057938 
	C669.250610,177.580307 667.055908,177.217300 664.861206,176.854309 
	C665.108398,178.550156 664.885132,180.517365 665.680969,181.896683 
	C670.036804,189.446487 674.911926,196.705627 679.071838,204.356491 
	C682.040466,209.816406 684.071411,215.779541 686.732178,221.418350 
	C687.362549,222.754456 688.778625,224.579315 689.938782,224.659866 
	C695.504883,225.046371 701.172241,226.045914 706.478577,222.861969 
	C708.329834,221.751129 710.772644,221.626236 712.951660,221.445328 
	C712.973633,222.890518 712.989258,223.941437 713.004883,224.992371 
	C711.698975,225.649612 710.393005,226.306854 708.946289,227.246155 
	C706.165588,227.683334 703.525574,227.838486 700.250366,227.753326 
	C698.742432,227.736740 697.869812,227.960464 696.997192,228.184204 
	C695.367493,228.230118 693.737732,228.276031 691.308716,228.209900 
	C688.682800,227.712952 686.856201,227.328064 685.029602,226.943192 
	C684.933716,226.586914 684.837830,226.230637 684.772522,225.231796 
	C683.615051,222.680176 682.426880,220.771118 681.238708,218.862061 
	C681.156250,218.475494 681.073853,218.088928 680.980347,217.031128 
	C679.721802,214.194046 678.474365,212.028214 677.226929,209.862381 
	C677.226929,209.862381 677.078735,209.486832 677.000244,208.984436 
	C676.374634,207.932663 675.827393,207.383270 675.280212,206.833878 
	C675.120422,206.201950 674.960632,205.570038 674.817261,204.245392 
	C671.771301,197.541687 668.936401,191.398270 665.548523,185.576630 
	C663.898743,182.741760 661.216553,180.507690 659.001709,178.001678 
	C659.001709,178.001678 658.874634,177.599625 658.865723,176.997757 
	C658.647156,175.076736 658.437439,173.757584 658.103943,171.659668 
	C661.325989,172.137283 663.683594,172.603912 666.063660,172.806030 
	C668.360962,173.001129 670.681458,172.923401 672.991760,172.965515 
	C675.369995,173.096558 677.748291,173.227615 680.547363,173.516113 
z"
          />
          <path
            fill="#244DAE"
            opacity="1.000000"
            stroke="none"
            d="
M713.327820,224.974640 
	C712.989258,223.941437 712.973633,222.890518 713.407349,221.425888 
	C718.850220,221.606964 723.140076,221.108429 723.385071,216.079636 
	C720.942444,211.049438 718.978088,207.004181 717.013733,202.958923 
	C719.771912,204.623062 722.530090,206.287186 725.639648,207.968689 
	C725.991089,207.986069 726.200378,208.287659 726.133179,208.864960 
	C727.077637,210.617249 728.089294,211.792252 729.100891,212.967255 
	C729.100891,212.967270 729.270813,213.249710 729.192017,213.787140 
	C730.053589,215.216843 730.993896,216.109131 731.934204,217.001404 
	C732.073853,217.318054 732.213501,217.634735 732.245117,218.653610 
	C732.081543,220.816010 732.026062,222.276215 731.970520,223.736389 
	C730.588623,223.866531 729.206787,223.996674 727.126587,223.764236 
	C722.541626,223.430161 718.384155,221.304886 714.939514,224.846832 
	C714.509949,224.883530 714.080322,224.920227 713.327820,224.974640 
z"
          />
          <path
            fill="#3965B7"
            opacity="1.000000"
            stroke="none"
            d="
M715.371704,224.897675 
	C718.384155,221.304886 722.541626,223.430161 726.711365,223.700348 
	C725.263794,224.326843 723.533081,224.654663 721.401672,225.000015 
	C719.268616,224.994522 717.536316,224.971512 715.371704,224.897675 
z"
          />
          <path
            fill="#3965B7"
            opacity="1.000000"
            stroke="none"
            d="
M732.325562,223.845367 
	C732.026062,222.276215 732.081543,220.816010 732.241699,218.915024 
	C733.709900,220.224518 737.592651,221.821075 732.325562,223.845367 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M706.845947,182.742554 
	C704.980591,182.039948 703.019226,181.005325 700.937927,179.672638 
	C702.795288,180.386551 704.772583,181.398544 706.845947,182.742554 
z"
          />
          <path
            fill="#3965B7"
            opacity="1.000000"
            stroke="none"
            d="
M729.126221,212.608261 
	C728.089294,211.792252 727.077637,210.617249 726.138062,209.048447 
	C727.190552,209.852859 728.171021,211.051056 729.126221,212.608261 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M698.849426,178.662384 
	C697.677368,178.566391 696.360107,178.154312 694.985352,177.427917 
	C696.186584,177.524490 697.445435,177.935410 698.849426,178.662384 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M712.937622,189.716400 
	C711.996338,189.209747 711.050476,188.363312 710.138794,187.162750 
	C711.093018,187.664612 712.013000,188.520615 712.937622,189.716400 
z"
          />
          <path
            fill="#3965B7"
            opacity="1.000000"
            stroke="none"
            d="
M731.971680,216.659729 
	C730.993896,216.109131 730.053589,215.216843 729.170898,213.950592 
	C730.155396,214.490417 731.082336,215.404236 731.971680,216.659729 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M715.040283,192.619919 
	C714.448608,192.435806 713.801453,191.944839 713.188477,191.105560 
	C713.809998,191.275848 714.397400,191.794464 715.040283,192.619919 
z"
          />
          <path
            fill="#205CC7"
            opacity="1.000000"
            stroke="none"
            d="
M563.104980,264.261627 
	C564.796143,263.749054 566.472839,263.530945 568.848999,263.546509 
	C571.015259,263.868866 572.481995,263.957520 574.259033,264.329773 
	C582.203003,264.713074 589.863770,265.230713 597.459351,264.739563 
	C601.371887,264.486511 605.168213,262.437714 609.383484,261.132263 
	C610.494202,261.042084 611.238586,261.020081 612.278992,261.289520 
	C619.041443,261.377655 625.507751,261.174347 631.974121,260.971039 
	C640.393433,261.202667 648.812683,261.434265 657.695740,262.207520 
	C663.229431,266.119476 667.823547,262.250214 671.730835,260.643188 
	C676.330139,258.751526 679.620972,260.072937 683.573547,261.282837 
	C681.393311,262.595612 679.031250,263.133423 676.857300,264.096008 
	C675.167786,264.844025 672.231201,266.566528 672.389282,267.093201 
	C673.600403,271.127197 670.771179,273.311584 669.002441,276.001221 
	C659.769897,277.796295 650.541077,279.610962 641.301575,281.369324 
	C639.530029,281.706451 637.690979,281.701447 635.928894,282.072174 
	C634.020203,282.473724 631.835938,282.996582 634.740234,285.676331 
	C633.533325,286.257751 632.139709,286.571564 630.154480,286.628235 
	C628.715576,286.252991 627.868286,286.134949 626.748352,285.733765 
	C621.697998,284.271698 617.575378,285.443085 614.090393,288.902405 
	C612.704346,288.950043 611.318359,288.997681 609.520752,289.054413 
	C605.468872,289.746002 601.828552,290.428497 598.097473,291.460083 
	C596.622620,291.864441 595.238586,291.919800 593.255493,291.721222 
	C592.094238,290.651764 591.532043,289.836273 591.334961,289.006470 
	C597.462097,285.346954 603.236267,281.720917 608.981384,278.049469 
	C612.547241,275.770660 617.432129,275.912109 618.595825,278.782288 
	C619.442566,280.870758 620.244263,283.024414 622.482910,281.738007 
	C624.367126,280.655212 626.807800,277.961212 626.585876,276.303955 
	C626.043457,272.253265 627.699341,271.291595 631.013062,270.867126 
	C631.707214,270.778198 632.715576,269.734436 632.783630,269.051422 
	C632.863708,268.247314 632.244202,266.772736 631.639832,266.600494 
	C628.189331,265.617310 624.529785,263.919128 621.167053,264.326691 
	C606.482056,266.106506 591.852173,268.268066 576.554932,267.997131 
	C571.723328,267.660797 567.339355,267.324890 562.955322,266.989014 
	C563.000427,266.178040 563.045471,265.367065 563.104980,264.261627 
z"
          />
          <path
            fill="#1A55C3"
            opacity="1.000000"
            stroke="none"
            d="
M669.373657,276.002808 
	C670.771179,273.311584 673.600403,271.127197 672.389282,267.093201 
	C672.231201,266.566528 675.167786,264.844025 676.857300,264.096008 
	C679.031250,263.133423 681.393311,262.595612 683.573547,261.282837 
	C679.620972,260.072937 676.330139,258.751526 671.730835,260.643188 
	C667.823547,262.250214 663.229431,266.119476 658.077271,262.378235 
	C659.434021,261.685303 661.305664,261.832001 662.242981,260.966644 
	C669.335510,254.418808 678.377319,257.881775 686.387512,256.171112 
	C696.390686,254.034836 706.641724,253.060364 716.784729,251.576828 
	C717.919922,251.410767 719.047974,251.196182 720.588196,251.001434 
	C726.785767,250.677444 732.594543,250.556961 738.358276,249.983688 
	C745.495911,249.273727 752.537415,246.622864 760.749573,249.333649 
	C753.674561,251.879257 747.502441,254.409348 741.140808,256.302155 
	C734.754700,258.202240 728.129028,259.283630 721.700256,261.057129 
	C713.753967,263.249298 705.927307,265.871857 698.002014,268.144745 
	C691.743103,269.939697 685.399414,271.438110 679.132263,273.205872 
	C678.338562,273.429718 677.780701,274.489838 677.053833,275.531433 
	C676.552429,275.928253 676.110718,275.956085 675.337097,275.983551 
	C673.251709,275.990265 671.498352,275.997345 669.373657,276.002808 
z"
          />
          <path
            fill="#4377BC"
            opacity="1.000000"
            stroke="none"
            d="
M631.917725,260.621399 
	C625.507751,261.174347 619.041443,261.377655 612.277222,260.966003 
	C611.966736,260.135712 611.954163,259.920380 611.941589,259.705078 
	C612.344910,259.695587 612.748291,259.686127 613.895386,259.774963 
	C617.085144,259.488434 619.531128,259.103546 621.977051,258.718658 
	C622.690002,258.729950 623.402954,258.741211 624.467407,258.690369 
	C625.661743,258.743561 626.504639,258.858887 627.779785,258.993591 
	C629.428467,259.432587 630.644958,259.852142 631.917725,260.621399 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M644.462769,256.756531 
	C646.990601,256.793365 649.082825,256.896210 651.618835,257.076080 
	C647.673279,260.680450 642.899353,258.877319 638.028931,257.821350 
	C639.912048,257.267303 641.969604,257.044922 644.462769,256.756531 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M734.960266,247.879913 
	C731.593262,248.261063 728.226196,248.642212 724.433716,249.029770 
	C723.737610,248.810532 723.467041,248.584915 723.088867,248.115601 
	C722.978394,247.699402 722.975586,247.526886 722.983948,247.094940 
	C723.418274,246.834274 723.841370,246.833023 724.576172,246.768112 
	C727.962402,246.431641 731.037048,246.158829 734.549072,246.229523 
	C734.977722,247.008636 734.968994,247.444275 734.960266,247.879913 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M681.232361,254.222107 
	C684.372437,253.293152 687.753906,252.643768 691.564209,252.050766 
	C688.486694,252.905319 684.980164,253.703476 681.232361,254.222107 
z"
          />
          <path
            fill="#123EB8"
            opacity="1.000000"
            stroke="none"
            d="
M282.957886,690.181396 
	C282.799347,690.502991 282.383392,690.399414 282.050415,690.013550 
	C281.110687,689.493652 280.503967,689.359619 279.897247,689.225586 
	C279.897247,689.225586 279.813324,689.252197 279.862762,688.855957 
	C279.378937,687.449158 278.845642,686.438538 278.312378,685.427979 
	C277.818481,686.103149 277.324585,686.778320 276.830688,687.453430 
	C275.705872,687.169617 274.581085,686.885864 273.485474,686.121460 
	C272.824249,684.433899 272.429474,682.780945 271.393982,682.094421 
	C265.849121,678.418701 260.142059,674.987854 254.514572,671.435120 
	C254.412872,671.371033 254.602417,670.845581 254.720261,670.164246 
	C257.268433,670.432190 259.836334,670.723083 262.408600,670.968567 
	C268.316010,671.532410 274.018829,671.619446 278.371277,677.281616 
	C280.764252,680.394653 285.484375,681.718933 289.163574,683.863281 
	C289.171844,683.876953 289.185730,683.830627 289.185730,683.830627 
	C288.944366,686.021423 288.703003,688.212158 288.453094,690.480591 
	C286.292084,690.229187 284.704254,690.044495 282.957886,690.181396 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M290.499023,681.134399 
	C290.927795,681.806335 291.037140,682.429199 291.160004,683.366455 
	C290.733337,683.739197 290.293091,683.797607 289.519287,683.843323 
	C289.185730,683.830627 289.171844,683.876953 289.145630,683.523254 
	C289.042694,682.491272 288.965942,681.812988 288.889191,681.134705 
	C289.319305,681.118286 289.749420,681.101868 290.499023,681.134399 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M696.283630,384.288452 
	C694.920288,383.572113 692.846191,382.912323 692.183350,381.560516 
	C691.000061,379.147583 690.668762,376.316864 690.389893,373.366638 
	C692.357971,376.131836 693.917297,379.191223 695.476685,382.250641 
	C695.476685,382.250641 695.527588,382.411896 695.575317,382.483093 
	C695.818237,383.047272 696.013489,383.540253 696.283630,384.288452 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M704.797241,384.186951 
	C703.245850,384.646698 701.302612,384.962006 699.177246,384.930420 
	C700.798523,384.403198 702.601929,384.222809 704.797241,384.186951 
z"
          />
          <path
            fill="#305CB3"
            opacity="1.000000"
            stroke="none"
            d="
M590.969849,289.020752 
	C591.532043,289.836273 592.094238,290.651764 592.840149,291.720276 
	C590.452759,292.874390 587.881714,293.775482 585.099670,295.004974 
	C583.471924,295.522675 582.055054,295.711975 580.276855,295.844055 
	C578.833984,295.516205 577.752441,295.245636 576.324951,294.628662 
	C575.986938,293.840271 575.994873,293.398315 576.367188,292.926453 
	C578.151855,292.592468 579.572266,292.288361 581.408691,291.988586 
	C584.226807,291.002594 586.628845,290.012238 589.354248,289.022461 
	C590.108337,289.022308 590.539124,289.021515 590.969849,289.020752 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M659.005981,178.357697 
	C661.216553,180.507690 663.898743,182.741760 665.548523,185.576630 
	C668.936401,191.398270 671.771301,197.541687 674.753296,203.925690 
	C673.577942,203.128708 672.214172,202.114136 671.433167,200.762756 
	C667.212585,193.459106 663.136292,186.072037 659.005981,178.357697 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M672.743164,172.691345 
	C670.681458,172.923401 668.360962,173.001129 666.063660,172.806030 
	C663.683594,172.603912 661.325989,172.137283 658.103943,171.659668 
	C658.437439,173.757584 658.647156,175.076736 658.817871,176.792419 
	C657.551575,174.791519 656.324158,172.394073 655.161255,169.625702 
	C656.878906,169.399490 658.532043,169.544205 660.541748,169.659882 
	C664.763794,170.559616 668.629211,171.488388 672.743164,172.691345 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M685.036499,227.318329 
	C686.856201,227.328064 688.682800,227.712952 690.903625,228.235931 
	C688.922119,230.820648 686.814026,230.807999 685.036499,227.318329 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M677.164856,210.235535 
	C678.474365,212.028214 679.721802,214.194046 680.924072,216.739975 
	C679.620178,214.949615 678.361450,212.779160 677.164856,210.235535 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M681.180054,219.221497 
	C682.426880,220.771118 683.615051,222.680176 684.715210,224.961060 
	C683.458557,223.415573 682.289978,221.498245 681.180054,219.221497 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M697.206116,228.389389 
	C697.869812,227.960464 698.742432,227.736740 699.804565,227.762054 
	C699.134399,228.205612 698.274719,228.400101 697.206116,228.389389 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M675.220825,207.163727 
	C675.827393,207.383270 676.374634,207.932663 676.915283,208.801331 
	C676.326294,208.578262 675.743835,208.035919 675.220825,207.163727 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M470.349762,735.878174 
	C469.044342,734.509766 468.093536,733.140381 467.064575,731.442566 
	C469.759338,731.114075 472.532288,731.114075 476.641968,731.114075 
	C474.021820,733.216003 472.363098,734.546631 470.349762,735.878174 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M462.659210,731.110962 
	C461.555115,731.978821 460.054108,732.768860 458.199707,733.607422 
	C459.318298,732.781738 460.790314,731.907471 462.659210,731.110962 
z"
          />
          <path
            fill="#343C8E"
            opacity="1.000000"
            stroke="none"
            d="
M520.198853,720.120361 
	C518.614685,720.465759 517.264465,720.464050 515.507324,720.480469 
	C513.778259,720.016479 512.456055,719.534424 510.086945,718.670654 
	C513.790527,714.992859 516.875061,711.929749 520.396729,708.862061 
	C520.833862,708.857483 521.066040,709.067566 521.011230,709.393250 
	C520.462830,711.680359 519.969238,713.641785 519.284668,716.362183 
	C523.770447,715.875732 527.647034,715.340454 531.541870,715.054321 
	C543.627075,714.166199 554.403992,710.510681 562.649048,701.049622 
	C563.529541,700.039246 565.223389,699.782715 566.420410,698.994934 
	C570.526672,696.292419 576.174561,695.208557 577.411621,689.002625 
	C578.872437,688.592651 580.026184,688.342346 581.063965,687.848328 
	C588.546753,684.286621 590.866516,679.971619 588.011414,675.047241 
	C591.130554,674.390930 594.249634,673.734619 597.368713,673.078369 
	C597.761902,673.607178 598.155090,674.135925 598.548279,674.664734 
	C594.428711,678.742798 590.473816,683.005615 586.151367,686.855957 
	C579.531250,692.752991 572.657898,698.365723 565.815796,704.428162 
	C565.739502,704.757202 565.387512,704.892395 564.869629,704.973389 
	C561.787842,706.926941 559.223938,708.799377 556.659973,710.671875 
	C556.659973,710.671875 556.313599,710.791870 556.160461,710.892334 
	C545.123840,717.868713 532.350403,717.086975 520.198853,720.120361 
z"
          />
          <path
            fill="#141F8A"
            opacity="1.000000"
            stroke="none"
            d="
M587.554688,674.997070 
	C590.866516,679.971619 588.546753,684.286621 581.063965,687.848328 
	C580.026184,688.342346 578.872437,688.592651 577.397583,688.989136 
	C577.022888,689.022461 576.997559,688.997559 577.001343,688.600342 
	C576.607483,684.766907 579.372803,684.370544 581.500793,684.190918 
	C585.022156,683.893616 586.260925,681.949219 586.999634,678.402405 
	C584.582153,678.402405 581.635071,677.544739 580.678894,678.560791 
	C577.620361,681.810669 574.014465,680.808167 570.509705,680.985352 
	C563.574707,681.336060 556.639832,681.853027 549.701660,681.912170 
	C545.858337,681.944885 544.874817,683.619202 544.902039,687.094971 
	C544.917175,689.035217 544.633606,691.952332 543.400391,692.747131 
	C539.522766,695.246338 535.131775,696.949158 530.985535,698.611694 
	C532.608215,695.775879 534.213501,693.306152 535.756470,690.798157 
	C540.913757,682.415222 547.654602,677.754456 558.242371,678.748535 
	C564.009827,679.289978 570.003479,677.494324 575.880371,676.649719 
	C576.325195,676.585815 576.651489,675.696960 577.032898,675.191650 
	C580.387939,675.110107 583.742981,675.028564 587.554688,674.997070 
z"
          />
          <path
            fill="#343C8E"
            opacity="1.000000"
            stroke="none"
            d="
M576.594910,675.086365 
	C576.651489,675.696960 576.325195,676.585815 575.880371,676.649719 
	C570.003479,677.494324 564.009827,679.289978 558.242371,678.748535 
	C547.654602,677.754456 540.913757,682.415222 535.756470,690.798157 
	C534.213501,693.306152 532.608215,695.775879 530.698853,698.768677 
	C529.555115,700.513550 528.744141,701.752869 527.642700,703.142334 
	C526.209167,704.842041 525.066040,706.391602 523.715698,708.062378 
	C523.254822,708.392456 523.072327,708.651550 522.644165,708.967712 
	C521.906921,709.005676 521.486511,709.036621 521.066040,709.067566 
	C521.066040,709.067566 520.833862,708.857483 520.712646,708.754150 
	C527.296387,698.718384 533.965637,688.761475 540.753784,678.886353 
	C541.555237,677.720398 543.009155,677.002991 544.380615,675.827759 
	C544.600586,675.577820 544.662903,675.464355 544.707520,675.421631 
	C546.870605,675.396301 548.989075,675.413696 551.555786,675.419556 
	C556.146606,675.424927 560.289124,675.441833 564.722412,675.165955 
	C566.371765,674.850647 567.730286,674.828186 569.489990,674.727051 
	C571.979736,674.759277 574.068359,674.870178 576.594910,675.086365 
z"
          />
          <path
            fill="#305CB3"
            opacity="1.000000"
            stroke="none"
            d="
M230.478912,606.974426 
	C230.719513,607.605652 230.717148,608.016296 230.843384,608.711426 
	C234.584778,615.215454 238.085434,621.503906 241.852798,627.628357 
	C244.078064,631.245911 246.725388,634.603760 249.242218,638.419983 
	C247.944305,640.340210 246.587082,641.918823 244.661346,643.787109 
	C242.970917,643.863159 241.857285,643.575745 240.725662,643.449219 
	C236.147934,642.937256 231.564651,642.474915 226.983429,641.994263 
	C226.257629,641.962341 225.531815,641.930481 224.265930,641.575439 
	C221.479462,640.517883 219.233078,639.783508 216.986710,639.049072 
	C216.580429,638.986877 216.174164,638.924683 215.382889,638.467407 
	C208.144775,631.801514 201.298477,625.523132 194.416077,619.284607 
	C194.107727,619.005066 193.471481,619.087219 192.987946,619.000977 
	C192.987946,619.000977 192.811859,618.662720 192.852081,618.103027 
	C192.915298,617.026917 192.938309,616.510559 193.309723,616.029785 
	C194.953125,617.058411 196.355347,617.939758 197.524490,619.063660 
	C202.457031,623.805298 206.543640,629.506653 214.294922,630.442749 
	C216.765472,630.741150 218.837814,634.374634 221.304138,634.661499 
	C225.470383,635.146118 228.639587,636.558594 232.028320,638.981689 
	C233.847305,640.282349 238.245209,640.028625 240.129486,638.641968 
	C242.256989,637.076294 240.519974,634.385071 238.871140,631.890625 
	C234.459778,625.216919 231.228653,617.768250 226.884735,611.043335 
	C222.910110,604.890137 222.195892,595.439392 212.110718,594.718384 
	C212.473770,593.960938 212.725433,593.460632 212.977112,592.960388 
	C212.977112,592.960388 213.003525,592.994019 213.281540,592.974976 
	C214.036087,592.664734 214.512619,592.373596 214.989166,592.082458 
	C214.989166,592.082458 215.066223,591.614441 215.082550,591.378662 
	C216.413773,591.123291 217.728653,591.103699 219.467529,591.511841 
	C221.528580,594.327087 223.165604,596.714539 224.802643,599.102051 
	C224.904007,599.446106 225.005371,599.790100 225.206482,600.667725 
	C225.867722,601.253784 226.429199,601.306335 226.990677,601.358887 
	C228.072433,603.157227 229.154190,604.955505 230.478912,606.974426 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M193.006195,619.348877 
	C193.471481,619.087219 194.107727,619.005066 194.416077,619.284607 
	C201.298477,625.523132 208.144775,631.801514 215.083740,638.393066 
	C211.046387,636.245300 206.496841,634.269897 202.916824,631.173523 
	C199.137589,627.904907 196.282974,623.567322 193.006195,619.348877 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M227.236786,642.261963 
	C231.564651,642.474915 236.147934,642.937256 240.725662,643.449219 
	C241.857285,643.575745 242.970917,643.863159 244.500168,644.099854 
	C244.983017,644.449707 245.058517,644.776489 245.169281,645.349731 
	C239.299744,644.573975 233.394943,643.551758 227.236786,642.261963 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M192.961304,615.994141 
	C192.938309,616.510559 192.915298,617.026917 192.792801,617.921753 
	C191.739319,617.853760 190.785324,617.407288 189.528687,616.867249 
	C188.153229,615.474182 187.080414,614.174744 185.726471,612.723999 
	C185.252975,611.682190 185.060577,610.791565 185.198212,609.529419 
	C187.977753,611.151917 190.427246,613.145996 192.898209,615.352966 
	C192.919693,615.565857 192.961319,615.994141 192.961304,615.994141 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M217.197327,639.321533 
	C219.233078,639.783508 221.479462,640.517883 223.905273,641.531433 
	C221.859131,641.071716 219.633545,640.332886 217.197327,639.321533 
z"
          />
          <path
            fill="#A8B4D5"
            opacity="1.000000"
            stroke="none"
            d="
M254.257339,647.368896 
	C254.711243,646.396973 255.396500,645.664490 256.380737,644.892517 
	C255.949341,645.771484 255.219025,646.689941 254.257339,647.368896 
z"
          />
          <path
            fill="#4453A0"
            opacity="1.000000"
            stroke="none"
            d="
M361.265076,717.212585 
	C367.750854,713.392822 373.722107,716.899780 379.984924,719.282043 
	C373.917542,718.908508 367.716187,718.212036 361.265076,717.212585 
z"
          />
          <path
            fill="#183DA3"
            opacity="1.000000"
            stroke="none"
            d="
M424.981720,581.025269 
	C425.735443,581.020203 426.489197,581.015198 427.619629,581.017273 
	C428.872437,588.870667 429.748566,596.716858 430.965363,604.824646 
	C431.287079,606.128967 431.268127,607.171814 431.167419,608.597717 
	C430.618011,608.987610 430.150391,608.994446 429.047729,608.706665 
	C427.269043,599.283142 426.125366,590.154175 424.981720,581.025269 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M691.133545,390.213379 
	C691.952454,390.091614 692.893005,390.292908 693.852478,390.823425 
	C692.999329,390.947235 692.127258,390.741821 691.133545,390.213379 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M673.146118,403.329285 
	C675.308228,403.688568 677.610962,404.378418 680.045410,405.366394 
	C677.880310,404.996246 675.583496,404.328033 673.146118,403.329285 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M683.182251,406.276367 
	C684.897766,406.476318 686.793091,406.967255 688.838806,408.015991 
	C688.989990,408.742828 688.990723,408.911896 688.991455,409.080933 
	C687.114990,408.243073 685.238525,407.405212 683.182251,406.276367 
z"
          />
          <path
            fill="#4377BC"
            opacity="1.000000"
            stroke="none"
            d="
M689.227112,409.122437 
	C688.990723,408.911896 688.989990,408.742828 688.987183,408.320923 
	C689.311157,408.165192 689.571289,408.353302 689.850708,408.862274 
	C689.935913,409.092163 689.462769,409.163940 689.227112,409.122437 
z"
          />
          <path
            fill="#535B9F"
            opacity="1.000000"
            stroke="none"
            d="
M587.174194,706.772339 
	C592.348877,703.522156 597.889221,700.329407 603.815247,697.211182 
	C601.324463,699.248108 598.545410,701.378174 595.541809,703.121338 
	C593.009827,704.590698 590.217163,705.610779 587.174194,706.772339 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M288.681061,681.047852 
	C288.965942,681.812988 289.042694,682.491272 289.137360,683.509644 
	C285.484375,681.718933 280.764252,680.394653 278.371277,677.281616 
	C274.018829,671.619446 268.316010,671.532410 262.408600,670.968567 
	C259.836334,670.723083 257.268433,670.432190 254.720261,670.164246 
	C254.602417,670.845581 254.412872,671.371033 254.514572,671.435120 
	C260.142059,674.987854 265.849121,678.418701 271.393982,682.094421 
	C272.429474,682.780945 272.824249,684.433899 273.247559,685.868713 
	C270.568115,685.052368 268.155823,684.008240 265.374695,682.978760 
	C265.005859,682.993347 265.000275,683.000000 264.972168,682.665771 
	C264.233704,681.997620 263.523315,681.663696 262.812927,681.329773 
	C262.812927,681.329773 262.426880,681.138245 262.224670,681.064209 
	C258.036407,677.715576 254.069870,674.416809 250.056061,671.176575 
	C248.066711,669.570496 245.990417,668.072144 243.953415,666.525146 
	C244.126144,666.079163 244.298874,665.633240 244.471619,665.187256 
	C251.396851,666.360840 258.322083,667.534485 265.655762,668.595276 
	C268.082855,668.545532 270.101532,668.608582 272.548798,668.618164 
	C277.778778,672.665527 282.580109,676.766479 287.721832,680.820923 
	C288.062195,680.774536 288.472931,680.960938 288.681061,681.047852 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M276.902985,687.761230 
	C277.324585,686.778320 277.818481,686.103149 278.312378,685.427979 
	C278.845642,686.438538 279.378937,687.449158 279.822266,688.838745 
	C278.813293,688.834839 277.894257,688.451904 276.902985,687.761230 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M280.043030,689.506226 
	C280.503967,689.359619 281.110687,689.493652 281.851562,689.933594 
	C281.386749,690.088562 280.787781,689.937683 280.043030,689.506226 
z"
          />
          <path
            fill="#535B9F"
            opacity="1.000000"
            stroke="none"
            d="
M582.347534,702.761108 
	C581.893799,703.424011 581.125549,704.099976 580.017456,704.679749 
	C580.462769,703.980408 581.247864,703.377258 582.347534,702.761108 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M569.149475,705.274414 
	C569.463440,705.007629 569.917114,705.008301 570.711060,705.022034 
	C570.906372,705.366882 570.761597,705.698669 570.391235,706.207153 
	C569.873535,706.103271 569.581360,705.822632 569.149475,705.274414 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M648.856079,372.287292 
	C649.328186,375.154846 649.494263,378.286316 649.382385,381.701477 
	C648.919617,378.840546 648.734863,375.695862 648.856079,372.287292 
z"
          />
          <path
            fill="#343C8E"
            opacity="1.000000"
            stroke="none"
            d="
M440.387604,735.927551 
	C437.483856,736.246887 434.732697,736.306396 431.552094,736.270142 
	C430.238037,735.535706 429.353424,734.897095 428.468811,734.258423 
	C428.463196,733.838867 428.457611,733.419312 428.452026,732.999756 
	C431.989746,732.999756 435.527496,732.999756 439.516266,732.934814 
	C440.158234,733.802429 440.349213,734.735107 440.387604,735.927551 
z"
          />
          <path
            fill="#5F629C"
            opacity="1.000000"
            stroke="none"
            d="
M392.484924,732.988037 
	C392.089691,731.984497 392.129700,731.054810 392.272034,729.799438 
	C393.340820,729.390076 394.307281,729.306274 395.630188,729.127869 
	C398.054077,729.259827 400.121521,729.486328 402.569672,729.736694 
	C403.509735,730.494507 404.069061,731.228516 404.954254,732.390259 
	C403.550049,732.697937 402.522003,732.923218 401.143738,733.290039 
	C398.169067,733.308350 395.544586,733.185059 392.484924,732.988037 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M557.038940,710.773804 
	C559.223938,708.799377 561.787842,706.926941 564.707275,705.069214 
	C562.514465,707.014587 559.966248,708.945129 557.038940,710.773804 
z"
          />
          <path
            fill="#979CC2"
            opacity="1.000000"
            stroke="none"
            d="
M262.890198,681.627991 
	C263.523315,681.663696 264.233704,681.997620 264.975342,682.662903 
	C264.326874,682.638245 263.647186,682.282227 262.890198,681.627991 
z"
          />
          <path
            fill="#0E35B2"
            opacity="1.000000"
            stroke="none"
            d="
M569.589905,526.981812 
	C571.437439,527.658875 572.828491,528.608154 574.355835,528.951050 
	C579.135925,530.024536 583.961121,530.903442 588.781128,531.790894 
	C592.173462,532.415527 595.571777,533.040894 598.991699,533.474670 
	C608.695007,534.705627 618.410706,535.838440 628.606384,537.374268 
	C629.307129,538.182739 629.502441,538.638367 629.742859,539.068787 
	C630.415527,540.273438 631.106934,541.467590 631.790894,542.665894 
	C630.486084,543.127563 629.209778,543.862366 627.871277,544.002075 
	C622.982544,544.512512 618.034485,544.582886 613.194031,545.353943 
	C611.679138,545.595215 610.025757,547.289307 609.187256,548.749939 
	C608.459412,550.017517 608.793457,551.894775 608.647705,553.628601 
	C605.359009,554.008362 602.304932,555.056213 599.535828,554.548889 
	C591.652527,553.104431 583.864136,551.111023 576.081238,549.162659 
	C572.032715,548.149170 569.536987,549.696716 568.985596,553.976868 
	C562.968140,553.981750 556.950745,553.986633 550.169434,553.703369 
	C547.482971,553.276733 545.531250,553.300903 543.644653,552.962280 
	C540.738037,552.440430 537.876038,551.669922 534.995117,551.004883 
	C539.557434,550.005127 544.144653,549.105225 548.675537,547.979187 
	C553.811523,546.702759 558.944031,545.363892 563.954956,543.684692 
	C564.726624,543.426086 565.394043,541.084717 565.153809,539.900513 
	C564.332581,535.852173 563.084961,531.890381 562.000000,527.456726 
	C563.078430,527.009644 564.156860,527.001465 565.641174,526.980530 
	C567.096619,526.970703 568.146240,526.973633 569.589905,526.981812 
z"
          />
          <path
            fill="#3965B7"
            opacity="1.000000"
            stroke="none"
            d="
M614.087891,289.151886 
	C617.575378,285.443085 621.697998,284.271698 626.573120,285.980835 
	C626.093079,287.271515 625.515564,288.031952 624.937988,288.792358 
	C623.861572,288.929413 622.785156,289.066498 621.332520,289.359283 
	C618.898621,289.876129 616.840881,290.237274 614.446533,290.489105 
	C614.113647,290.053345 614.105469,289.727203 614.087891,289.151886 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M625.299683,288.843719 
	C625.515564,288.031952 626.093079,287.271515 626.845825,286.263977 
	C627.868286,286.134949 628.715576,286.252991 629.771362,286.615540 
	C628.540466,287.538361 627.100952,288.216736 625.299683,288.843719 
z"
          />
          <path
            fill="#3C53A5"
            opacity="1.000000"
            stroke="none"
            d="
M588.790344,526.279663 
	C588.431274,526.576416 587.726990,526.690063 586.973755,526.544800 
	C587.431641,526.222778 587.938354,526.159668 588.790344,526.279663 
z"
          />
          <path
            fill="#507ABE"
            opacity="1.000000"
            stroke="none"
            d="
M735.360107,247.921432 
	C734.968994,247.444275 734.977722,247.008636 734.976929,246.244019 
	C735.556030,245.697876 736.144653,245.480743 736.878662,244.972504 
	C737.447388,244.696747 737.870667,244.712112 738.603516,244.643951 
	C739.108459,244.677734 739.303772,244.795029 739.802368,245.072327 
	C738.657104,246.142532 737.208496,247.052750 735.360107,247.921432 
z"
          />
          <path
            fill="#064CAC"
            opacity="1.000000"
            stroke="none"
            d="
M228.243317,440.987946 
	C223.618912,441.897858 221.360382,439.122711 219.220413,436.384918 
	C211.885605,427.000793 204.796021,417.417694 197.209793,408.243866 
	C195.754028,406.483429 192.471054,406.234039 190.031036,405.287537 
	C189.379562,407.352081 188.222366,409.403046 188.166321,411.483643 
	C187.951065,419.475616 187.872910,427.485321 188.179092,435.471741 
	C188.351532,439.969543 186.677750,441.468994 182.369400,441.042511 
	C179.397720,440.748291 176.343292,441.199280 173.395340,440.801880 
	C172.261826,440.649078 170.426163,439.067932 170.426224,438.137695 
	C170.427185,423.995575 170.741104,409.853912 170.854950,395.711151 
	C170.950180,383.881622 171.016022,372.048431 170.810486,360.221802 
	C170.737808,356.039764 172.272491,354.691223 176.345810,354.729736 
	C190.198990,354.860840 187.904495,353.586823 188.096054,366.389465 
	C188.183289,372.219788 187.961945,378.057129 188.154404,383.882202 
	C188.218842,385.832733 189.132385,387.755219 189.657806,389.690521 
	C190.290802,389.914581 190.923798,390.138611 191.556793,390.362671 
	C195.326645,386.180634 199.252136,382.126953 202.830963,377.787384 
	C208.619461,370.768494 213.872711,363.276733 220.040710,356.623108 
	C221.701630,354.831390 225.844559,354.954956 228.856964,354.918030 
	C231.483582,354.885864 234.122620,355.865967 236.756165,356.398834 
	C235.566727,358.620728 234.661057,361.062500 233.139175,363.026794 
	C226.164124,372.029602 219.218124,381.070282 211.840546,389.738922 
	C208.477097,393.691010 207.170578,396.506348 211.093735,401.117493 
	C220.521606,412.198944 229.409851,423.741241 238.422623,435.171082 
	C239.237000,436.203918 240.010544,437.994873 239.644470,439.020050 
	C239.306335,439.966980 237.457901,440.758881 236.190964,440.907959 
	C233.725937,441.198059 231.202316,440.990356 228.243317,440.987946 
z"
          />
          <path
            fill="#074DAD"
            opacity="1.000000"
            stroke="none"
            d="
M151.177078,396.926880 
	C152.826248,401.256622 154.271194,405.250885 155.748367,409.233215 
	C158.633972,417.012665 161.330887,424.870697 164.494644,432.535767 
	C167.382751,439.533051 166.621140,440.995422 159.078903,440.992920 
	C150.675110,440.990112 149.839340,440.307587 147.867676,431.837341 
	C145.540588,421.840179 143.496979,420.212158 133.266479,420.224701 
	C128.269150,420.230835 123.267494,420.124481 118.275795,420.298431 
	C113.959061,420.448792 110.727585,421.776764 109.983826,426.999969 
	C109.463402,430.654694 107.866852,434.242035 106.239037,437.612213 
	C105.557907,439.022430 103.634857,440.539581 102.131058,440.707733 
	C98.242706,441.142426 94.273460,440.853485 88.855652,440.853485 
	C90.614944,435.520538 91.915993,431.085724 93.546600,426.775543 
	C95.093880,422.685669 97.171333,418.795044 98.692604,414.696869 
	C103.501892,401.741028 108.125725,388.716461 112.892143,375.744507 
	C114.975983,370.073242 116.987793,364.350616 119.549904,358.894379 
	C120.377304,357.132324 122.678841,355.497559 124.623306,354.966736 
	C127.104523,354.289368 129.924286,354.613098 132.563812,354.865051 
	C133.715424,354.974976 135.433945,355.723480 135.782867,356.616516 
	C138.393158,363.297455 140.636551,370.120575 143.169373,376.833313 
	C145.668900,383.457733 148.373032,390.004913 151.177078,396.926880 
M131.092514,382.764862 
	C127.430611,379.605743 125.522095,382.635132 124.255226,385.186493 
	C122.520317,388.680481 120.941925,392.457764 120.401550,396.270264 
	C120.012039,399.018341 120.919518,404.232178 122.126083,404.508057 
	C126.173843,405.433563 130.655670,404.900177 134.866913,404.249451 
	C135.590164,404.137665 136.513870,400.554169 136.118683,398.860565 
	C134.898117,393.629913 133.084564,388.537659 131.092514,382.764862 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M166.999390,412.001221 
	C167.270294,411.072876 167.541183,410.144531 168.165192,409.067200 
	C168.518280,417.298340 168.518280,425.678467 168.518280,435.505005 
	C166.718857,431.414520 165.450546,428.531403 164.635422,425.279633 
	C165.729416,423.833130 166.836151,422.791168 166.922638,421.670502 
	C167.170044,418.464539 167.003281,415.226593 166.999390,412.001221 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M157.984421,398.029541 
	C159.322174,402.412964 160.659912,406.796326 161.865005,411.828369 
	C161.425156,412.673309 161.117966,412.869659 160.810791,413.066010 
	C158.578842,407.942749 156.346893,402.819519 154.479233,397.373199 
	C155.890488,397.376617 156.937454,397.703094 157.984421,398.029541 
z"
          />
          <path
            fill="#F8F9F9"
            opacity="1.000000"
            stroke="none"
            d="
M160.821808,413.389404 
	C161.117966,412.869659 161.425156,412.673309 161.871506,412.237885 
	C163.396271,411.999176 164.781876,411.999603 166.583435,412.000610 
	C167.003281,415.226593 167.170044,418.464539 166.922638,421.670502 
	C166.836151,422.791168 165.729416,423.833130 164.704208,424.920105 
	C164.195038,424.203674 164.070251,423.478149 164.234222,422.159790 
	C165.596558,418.187683 163.834579,416.313446 161.032745,414.991119 
	C160.966110,414.565002 160.899460,414.138916 160.821808,413.389404 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M213.092392,432.483398 
	C212.959106,432.867096 212.769531,432.914093 212.289093,432.996796 
	C210.097778,430.888672 208.197311,428.744843 206.009216,425.986389 
	C205.248459,424.141174 204.775345,422.910553 204.302216,421.679962 
	C207.213501,425.168854 210.124802,428.657776 213.092392,432.483398 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M232.828934,368.800293 
	C235.695129,371.327881 234.815750,373.307556 229.343857,375.571320 
	C228.975937,374.456848 228.962662,373.691193 228.949371,372.925568 
	C229.443436,372.361603 229.937515,371.797668 231.022308,371.075012 
	C231.992157,370.213257 232.371277,369.510193 232.750397,368.807129 
	C232.750397,368.807129 232.808258,368.774231 232.828934,368.800293 
z"
          />
          <path
            fill="#F8F9F9"
            opacity="1.000000"
            stroke="none"
            d="
M157.990082,397.614441 
	C156.937454,397.703094 155.890488,397.376617 154.519775,397.004425 
	C154.078156,396.542297 153.960297,396.125916 154.112732,395.226990 
	C154.586670,394.163788 154.790283,393.583099 154.993927,393.002441 
	C156.104324,392.037659 157.214737,391.072845 158.661957,390.054016 
	C158.664429,392.399780 158.330078,394.799591 157.990082,397.614441 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M212.068161,397.293762 
	C214.349533,399.489136 216.604095,402.040253 218.789948,404.970703 
	C216.494598,402.783203 214.267975,400.216370 212.068161,397.293762 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M218.438019,386.794525 
	C216.760925,389.450836 214.755707,392.203522 212.399750,394.998535 
	C214.069305,392.324249 216.089600,389.607574 218.438019,386.794525 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M228.641266,373.064728 
	C228.962662,373.691193 228.975937,374.456848 228.985718,375.604706 
	C227.181442,377.635040 225.380661,379.283112 223.225922,380.924561 
	C224.692368,378.346588 226.512772,375.775238 228.641266,373.064728 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M204.216339,421.413849 
	C204.775345,422.910553 205.248459,424.141174 205.867065,425.683289 
	C200.742844,425.594849 200.112946,424.646973 202.690826,420.411743 
	C203.311798,420.479797 203.721130,420.813751 204.216339,421.413849 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M160.969376,415.358246 
	C163.834579,416.313446 165.596558,418.187683 164.248291,421.789429 
	C162.951080,419.916412 161.928543,417.820892 160.969376,415.358246 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M218.965698,406.376190 
	C220.929794,408.152771 222.914703,410.281921 224.879288,412.772156 
	C222.901489,410.998413 220.944000,408.863586 218.965698,406.376190 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M230.885620,421.465912 
	C232.172638,422.248322 233.522110,423.377686 234.838898,424.875916 
	C233.520172,424.100800 232.234131,422.956848 230.885620,421.465912 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M194.194733,408.420807 
	C193.167618,409.160522 192.015289,409.555328 190.511963,410.013702 
	C189.863647,406.285004 191.544434,406.356018 194.194733,408.420807 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M224.894897,413.464233 
	C225.904419,414.201843 226.946762,415.264282 227.953156,416.698547 
	C226.920731,415.976562 225.924240,414.882782 224.894897,413.464233 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M145.148376,432.564453 
	C144.485352,432.072540 143.810104,431.240265 143.236145,430.018494 
	C143.937012,430.494019 144.536575,431.359039 145.148376,432.564453 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M221.665314,382.065674 
	C221.493622,382.923096 221.019806,383.895813 220.182755,384.875977 
	C220.334076,383.982635 220.848633,383.081848 221.665314,382.065674 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M227.924255,417.400879 
	C228.598618,417.851471 229.285812,418.625305 229.935043,419.767273 
	C229.243759,419.331696 228.590424,418.527924 227.924255,417.400879 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M151.305603,391.255035 
	C151.891663,391.467651 152.400528,391.994690 152.892776,392.829865 
	C152.326904,392.615143 151.777649,392.092285 151.305603,391.255035 
z"
          />
          <path
            fill="#DEEAF4"
            opacity="1.000000"
            stroke="none"
            d="
M154.637146,393.002075 
	C154.790283,393.583099 154.586670,394.163788 154.105591,394.901184 
	C153.515564,394.606171 153.202988,394.154480 152.874084,393.418365 
	C153.331955,393.089874 153.806183,393.045776 154.637146,393.002075 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M232.449341,368.879028 
	C232.371277,369.510193 231.992157,370.213257 231.272263,370.939819 
	C231.337097,370.292542 231.742691,369.621735 232.449341,368.879028 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M174.759842,442.976501 
	C174.724350,443.133087 174.439148,443.225494 174.075684,443.142761 
	C174.168320,442.949127 174.339218,442.930725 174.759842,442.976501 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M216.955780,436.748840 
	C216.742538,436.814209 216.464935,436.617645 216.099670,436.175415 
	C216.305145,436.115448 216.598267,436.301178 216.955780,436.748840 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M214.058899,433.698212 
	C213.842575,433.765106 213.554703,433.576782 213.197693,433.131927 
	C213.414841,433.064575 213.701096,433.253784 214.058899,433.698212 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M279.015381,425.019531 
	C279.441650,425.014343 279.867889,425.009155 280.986023,425.024902 
	C288.452271,422.302856 290.304688,416.146027 284.930267,411.675293 
	C281.693726,408.982941 276.853668,408.218231 272.734222,406.587280 
	C272.044250,406.483307 271.354248,406.379333 270.153137,405.939453 
	C267.350952,404.861694 265.059937,404.119934 262.768921,403.378174 
	C262.377106,403.292267 261.985321,403.206329 261.162750,402.769897 
	C259.548737,401.888245 258.365448,401.357117 256.913544,400.764465 
	C256.464630,400.738586 256.284393,400.774231 256.104156,400.809845 
	C256.104156,400.809845 256.293579,400.713562 256.135071,400.324738 
	C253.499649,397.770782 251.022751,395.605621 248.545853,393.440491 
	C248.545853,393.440491 248.817642,393.174774 249.305237,393.083252 
	C257.811859,398.843689 266.866333,402.336426 276.352753,405.001068 
	C279.778351,405.963287 283.321320,407.280029 286.183350,409.316681 
	C292.338531,413.696625 291.804230,423.074707 285.192474,426.165253 
	C276.289734,430.326691 259.785461,429.714600 256.335083,416.633545 
	C255.502594,413.477386 253.344208,412.801910 250.298157,412.852020 
	C243.036026,412.971558 243.045609,412.841217 241.782516,419.888092 
	C241.707108,420.308868 241.209290,420.653931 240.663391,420.953644 
	C240.212234,420.599701 240.005722,420.326355 239.874908,419.619568 
	C239.852890,417.432556 239.755173,415.678986 239.784622,413.617859 
	C240.353546,412.304047 240.795273,411.297852 241.685211,410.233826 
	C245.256287,410.110779 248.401459,410.255188 251.496262,409.926025 
	C255.173340,409.534882 256.711029,411.255951 257.871765,414.510834 
	C258.798706,417.110199 260.705536,419.360077 262.177399,421.765106 
	C262.177399,421.765106 262.153931,421.849121 262.257141,422.170105 
	C264.579651,423.373962 266.798889,424.256836 269.393982,425.193451 
	C271.781250,425.339996 273.792725,425.432739 276.134827,425.663116 
	C277.315430,425.540344 278.165405,425.279938 279.015381,425.019531 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M281.273132,441.458405 
	C278.573608,442.280609 275.767365,444.047760 273.193604,443.756958 
	C263.485992,442.660156 253.898529,440.782410 246.287125,433.239014 
	C244.345383,431.264130 242.726898,429.373383 240.878799,427.216553 
	C240.629120,426.537964 240.609055,426.125458 240.668701,425.120331 
	C240.889496,424.320312 241.030579,424.112915 241.504242,423.970764 
	C242.451447,424.575226 243.244431,425.004547 243.654083,425.669891 
	C250.010223,435.993439 259.376007,440.828339 271.342133,441.048950 
	C274.653870,441.109985 277.962860,441.317932 281.273132,441.458405 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M272.868042,406.879150 
	C276.853668,408.218231 281.693726,408.982941 284.930267,411.675293 
	C290.304688,416.146027 288.452271,422.302856 281.303040,424.982880 
	C281.706604,423.701660 282.431885,422.445282 283.273102,421.272003 
	C286.251068,417.118286 284.872742,412.936462 280.136841,411.040771 
	C277.641907,410.042145 275.372864,408.479156 272.868042,406.879150 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M262.153961,421.357086 
	C260.705536,419.360077 258.798706,417.110199 257.871765,414.510834 
	C256.711029,411.255951 255.173340,409.534882 251.496262,409.926025 
	C248.401459,410.255188 245.256287,410.110779 241.606567,409.917084 
	C241.057068,409.442963 241.034424,409.227753 241.011795,409.012512 
	C246.016556,408.767609 251.410034,407.245880 255.922073,408.619476 
	C260.919189,410.140778 262.034607,415.791260 262.153961,421.357086 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M248.251556,393.281982 
	C251.022751,395.605621 253.499649,397.770782 256.106995,400.222290 
	C255.757065,400.637299 255.276672,400.765930 254.242447,400.663452 
	C253.146225,400.249573 252.603851,400.066803 252.061478,399.884033 
	C248.221619,396.618378 242.779922,394.794250 241.914124,388.083069 
	C241.229736,386.602783 240.666626,385.782410 240.103516,384.962067 
	C240.172592,384.130402 240.241653,383.298737 240.535889,382.249634 
	C243.159790,385.729309 245.558517,389.426361 248.251556,393.281982 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M240.659760,409.070129 
	C241.034424,409.227753 241.057068,409.442963 241.158356,409.974915 
	C240.795273,411.297852 240.353546,412.304047 239.460297,413.733032 
	C239.272263,416.121521 239.535736,418.087280 239.799210,420.053009 
	C240.005722,420.326355 240.212234,420.599701 240.663559,421.314789 
	C240.996140,422.472839 241.083908,423.189178 241.171661,423.905487 
	C241.030579,424.112915 240.889496,424.320312 240.534180,424.838837 
	C236.622925,423.948914 234.542999,415.969177 237.114746,412.639557 
	C238.078766,411.391479 239.237671,410.293915 240.659760,409.070129 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M275.804199,425.525482 
	C273.792725,425.432739 271.781250,425.339996 269.241486,424.934998 
	C268.111176,424.108032 267.509186,423.593231 266.907196,423.078430 
	C269.765747,423.216919 272.624298,423.355408 275.736816,424.039124 
	C275.917755,424.895935 275.855560,425.209625 275.804199,425.525482 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M262.807495,403.636780 
	C265.059937,404.119934 267.350952,404.861694 269.803345,405.927155 
	C268.633423,406.446289 267.302124,406.641754 265.507507,406.539856 
	C264.378876,405.802551 263.713623,405.362610 263.048370,404.922668 
	C262.980927,404.580261 262.913483,404.237823 262.807495,403.636780 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M241.823318,366.099701 
	C242.397629,366.014587 242.775253,365.962952 243.435608,365.879578 
	C242.990143,367.787506 242.261948,369.727173 241.225067,371.834137 
	C240.639587,371.226044 240.362778,370.450592 240.348633,369.144623 
	C240.816742,367.785553 241.022202,366.957031 241.227661,366.128510 
	C241.227661,366.128510 241.626648,366.133179 241.823318,366.099701 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M266.560913,423.028870 
	C267.509186,423.593231 268.111176,424.108032 268.865662,424.881226 
	C266.798889,424.256836 264.579651,423.373962 262.296997,422.152405 
	C263.560638,422.202271 264.887634,422.590790 266.560913,423.028870 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M257.182190,400.825989 
	C258.365448,401.357117 259.548737,401.888245 260.864136,402.731934 
	C259.723053,402.909515 258.449829,402.774567 257.121765,402.024445 
	C257.105377,401.214874 257.143768,401.020416 257.182190,400.825989 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M228.731018,382.699005 
	C229.597687,382.979706 230.171783,383.413025 230.745880,383.846375 
	C229.956741,384.177399 229.167587,384.508423 228.378448,384.839417 
	C228.398438,384.176819 228.418427,383.514221 228.731018,382.699005 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M240.874222,366.110413 
	C241.022202,366.957031 240.816742,367.785553 240.358276,368.813904 
	C239.537628,368.589600 238.969971,368.165405 238.402313,367.741241 
	C239.108459,367.191589 239.814621,366.641968 240.874222,366.110413 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M239.828171,385.169647 
	C240.666626,385.782410 241.229736,386.602783 241.884308,387.714966 
	C241.168137,387.130249 240.360489,386.253754 239.828171,385.169647 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M276.134827,425.663116 
	C275.855560,425.209625 275.917755,424.895935 276.010681,424.345215 
	C276.885925,424.240692 277.741272,424.375397 278.806030,424.764801 
	C278.165405,425.279938 277.315430,425.540344 276.134827,425.663116 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M263.077576,405.268433 
	C263.713623,405.362610 264.378876,405.802551 265.148865,406.546783 
	C264.537994,406.438782 263.822388,406.026489 263.077576,405.268433 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M252.069778,400.191772 
	C252.603851,400.066803 253.146225,400.249573 253.865356,400.662292 
	C253.387436,400.761322 252.732758,400.630432 252.069778,400.191772 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M256.913544,400.764465 
	C257.143768,401.020416 257.105377,401.214874 257.033081,401.704651 
	C256.740265,401.774780 256.481262,401.549561 256.163208,401.067078 
	C256.284393,400.774231 256.464630,400.738586 256.913544,400.764465 
z"
          />
          <path
            fill="#318BED"
            opacity="1.000000"
            stroke="none"
            d="
M387.007812,163.969254 
	C391.019165,163.959854 395.030518,163.950470 399.699829,164.291168 
	C404.963623,165.095795 409.607697,165.319778 414.163910,166.074203 
	C418.164734,166.736710 422.067108,167.993652 425.743988,169.210831 
	C423.973694,170.958847 422.722931,172.969879 420.915710,173.901978 
	C417.754333,175.532516 415.909302,179.165268 417.148132,182.894455 
	C418.394623,186.646790 420.741791,184.959106 423.070679,184.194565 
	C424.268585,183.801300 425.676971,184.049118 426.994873,184.406250 
	C426.999664,185.869354 426.999817,186.933456 426.576202,187.998291 
	C422.299652,188.128204 417.571747,185.458862 415.513062,191.471939 
	C415.344177,191.965164 413.366882,191.944031 412.224457,191.977692 
	C409.728455,192.051224 407.182129,191.721558 404.749359,192.121872 
	C403.401672,192.343628 401.322021,193.555145 401.154846,194.578964 
	C400.397614,199.216278 397.893036,201.543961 392.987640,201.522949 
	C393.622681,207.396118 390.330170,209.283646 385.284973,209.239243 
	C385.236481,214.596695 383.098602,217.538605 377.688599,217.013382 
	C376.698700,216.917267 375.685547,217.070374 374.693298,216.987213 
	C371.282593,216.701370 368.694702,217.287048 368.826874,221.613235 
	C368.849030,222.338440 367.848450,223.094879 367.180908,224.032944 
	C365.795319,222.936813 364.710114,222.078323 363.657043,221.245255 
	C359.160889,225.709991 359.013031,227.662323 362.113434,232.167923 
	C363.068268,233.555481 363.389740,235.378860 363.601471,237.002991 
	C362.135681,237.000397 361.069611,236.997543 359.951477,236.660995 
	C358.266266,234.885071 356.633118,233.442856 355.000000,231.571625 
	C355.666168,228.096420 356.332306,225.050217 357.265015,221.781158 
	C358.602509,219.521149 359.996490,217.580978 360.645905,215.417313 
	C361.201202,213.567245 360.960693,211.460907 360.963654,209.467743 
	C360.966187,207.751465 360.899506,206.025787 360.713776,204.320572 
	C360.406372,201.497986 362.961243,198.070694 359.008820,195.996307 
	C359.436462,194.761124 359.864136,193.525955 360.962585,192.083282 
	C363.936005,189.702774 365.943817,187.513321 362.017212,184.992905 
	C362.317932,183.891388 362.618652,182.789886 363.332031,181.275177 
	C364.454315,177.856888 365.163940,174.851807 365.873566,171.846741 
	C365.901825,171.138733 365.930084,170.430740 366.345642,169.324982 
	C371.100922,164.785553 376.568909,166.353470 381.688080,165.916321 
	C383.503540,165.761276 385.236938,164.646103 387.007812,163.969254 
z"
          />
          <path
            fill="#2B7CE1"
            opacity="1.000000"
            stroke="none"
            d="
M360.003571,236.994690 
	C361.069611,236.997543 362.135681,237.000397 363.967407,237.162598 
	C366.022461,237.540619 367.327179,238.014389 368.598663,237.937531 
	C378.315002,237.349960 388.039032,236.813812 397.732574,235.947144 
	C406.701782,235.145233 415.636230,233.953857 424.999084,232.886902 
	C424.999084,224.578339 424.894714,216.970352 425.089447,209.370026 
	C425.127167,207.897202 426.332214,206.454285 427.170471,205.241974 
	C427.900116,205.658676 428.458099,205.830963 429.016052,206.003250 
	C429.015137,206.746780 429.014191,207.490295 428.601135,208.705215 
	C428.153992,214.127090 428.118927,219.077560 428.083893,224.028046 
	C428.050293,227.701447 428.016724,231.374847 427.641479,235.563934 
	C427.230499,237.068817 427.161163,238.058014 427.091797,239.047195 
	C426.622803,239.675888 426.202423,240.804428 425.677277,240.855469 
	C420.740540,241.335281 415.787384,241.878510 410.836029,241.907684 
	C406.218933,241.934906 401.602631,241.226105 396.978333,241.074188 
	C384.377441,240.660248 371.770996,240.416168 359.169800,240.009247 
	C353.249390,239.818039 351.978912,238.380585 351.934174,232.405212 
	C351.922974,230.908585 351.943268,229.411713 352.225403,227.261459 
	C352.647308,225.062393 352.792816,223.516846 352.938354,221.971283 
	C352.949005,221.536072 352.959686,221.100845 353.361145,220.339264 
	C354.501312,220.010834 355.250641,220.008774 356.021210,220.303299 
	C356.361115,221.067932 356.679779,221.535980 356.998474,222.004028 
	C356.332306,225.050217 355.666168,228.096420 354.727936,231.816605 
	C353.091980,237.949570 357.094055,236.799271 360.003571,236.994690 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M429.035522,205.563995 
	C428.458099,205.830963 427.900116,205.658676 427.172668,204.774658 
	C427.002136,198.707794 427.001068,193.352676 427.000000,187.997559 
	C426.999817,186.933456 426.999664,185.869354 426.999420,183.940140 
	C426.670563,178.379364 426.341766,173.683701 426.012970,168.988037 
	C422.067108,167.993652 418.164734,166.736710 414.163910,166.074203 
	C409.607697,165.319778 404.963623,165.095795 400.160583,164.287674 
	C401.837463,163.836685 403.712067,163.648697 405.585571,163.658646 
	C411.563629,163.690384 417.550903,164.035294 423.516052,163.796204 
	C428.240906,163.606827 430.126984,164.841751 430.036682,169.808273 
	C429.822540,181.582474 429.395782,193.352829 429.035522,205.563995 
z"
          />
          <path
            fill="#3587E1"
            opacity="1.000000"
            stroke="none"
            d="
M357.265015,221.781158 
	C356.679779,221.535980 356.361115,221.067932 356.178101,219.940521 
	C357.175781,212.801590 358.037781,206.322021 358.899780,199.842468 
	C358.919922,198.816544 358.940033,197.790619 358.984497,196.380493 
	C362.961243,198.070694 360.406372,201.497986 360.713776,204.320572 
	C360.899506,206.025787 360.966187,207.751465 360.963654,209.467743 
	C360.960693,211.460907 361.201202,213.567245 360.645905,215.417313 
	C359.996490,217.580978 358.602509,219.521149 357.265015,221.781158 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M358.543701,199.959412 
	C358.037781,206.322021 357.175781,212.801590 356.156860,219.643921 
	C355.250641,220.008774 354.501312,220.010834 353.377350,220.013153 
	C354.731049,213.367737 356.459351,206.722046 358.543701,199.959412 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M386.539001,163.960373 
	C385.236938,164.646103 383.503540,165.761276 381.688080,165.916321 
	C376.568909,166.353470 371.100922,164.785553 366.361237,168.971039 
	C366.769653,165.369278 369.130493,163.914597 372.734924,163.966309 
	C377.179047,164.030090 381.625031,163.962387 386.539001,163.960373 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M428.358582,223.717239 
	C428.118927,219.077560 428.153992,214.127090 428.579071,209.076385 
	C428.857178,213.786255 428.745239,218.596344 428.358582,223.717239 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M365.512421,171.966064 
	C365.163940,174.851807 364.454315,177.856888 363.376892,180.932480 
	C363.723175,178.030457 364.437225,175.057922 365.512421,171.966064 
z"
          />
          <path
            fill="#3587E1"
            opacity="1.000000"
            stroke="none"
            d="
M361.983765,185.385712 
	C365.943817,187.513321 363.936005,189.702774 361.261169,191.916321 
	C361.242767,189.897400 361.596558,187.837952 361.983765,185.385712 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M352.673523,222.234589 
	C352.792816,223.516846 352.647308,225.062393 352.238373,226.808197 
	C352.119537,225.504913 352.264099,224.001389 352.673523,222.234589 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M427.340637,238.821106 
	C427.161163,238.058014 427.230499,237.068817 427.620605,236.021454 
	C427.824005,236.840515 427.706726,237.717773 427.340637,238.821106 
z"
          />
          <path
            fill="#437DCA"
            opacity="1.000000"
            stroke="none"
            d="
M464.941956,172.024323 
	C464.837463,160.942093 464.839081,160.833298 475.343781,160.990219 
	C488.469971,161.186310 501.593384,161.572586 514.717407,161.901001 
	C519.312561,162.015991 523.943237,161.917496 528.479736,162.511765 
	C530.063965,162.719284 532.266968,164.451782 532.727234,165.939560 
	C535.072693,173.521774 536.985352,181.240295 538.958008,188.934555 
	C540.216797,193.844666 541.432617,198.771454 542.463257,203.733231 
	C544.579712,213.922882 546.630737,224.127441 548.566772,234.352737 
	C549.677063,240.216965 548.899902,240.933304 542.980103,241.163254 
	C520.082214,242.052734 497.182098,242.908829 474.294098,244.012009 
	C469.251343,244.255081 467.833801,242.570312 467.682312,237.542099 
	C467.029175,215.860352 465.899536,194.192932 464.941956,172.024323 
M469.995087,184.685226 
	C470.325592,190.966385 470.779114,197.244171 470.951111,203.529678 
	C471.114868,209.514679 470.896057,215.509399 471.008209,221.496796 
	C471.124542,227.706741 475.633453,235.692169 479.665863,236.699936 
	C484.409210,237.885391 489.216309,238.826553 494.019653,239.749146 
	C494.699341,239.879700 495.514191,239.306122 496.266510,239.058624 
	C498.331421,238.379288 500.386627,237.149765 502.462982,237.112869 
	C513.440308,236.917786 524.422913,237.011658 535.403687,237.032272 
	C537.970581,237.037079 540.410645,236.692673 539.857239,233.326950 
	C538.724731,226.439270 542.180664,219.199997 538.132812,212.498581 
	C537.572327,211.570663 538.340515,209.906204 538.289307,208.590698 
	C538.187744,205.983612 538.239380,203.319519 537.704346,200.789703 
	C535.929504,192.398285 533.746094,184.090927 532.080139,175.679871 
	C531.275452,171.617386 527.987671,166.145691 525.280701,166.014359 
	C517.171570,165.620911 509.058411,165.106934 500.944824,165.069641 
	C494.664978,165.040756 488.384277,165.709885 482.099792,165.966507 
	C478.943787,166.095383 475.777588,165.950867 472.617676,166.014618 
	C469.904846,166.069351 469.004517,167.131363 469.425140,170.147858 
	C470.049500,174.625168 469.851746,179.217133 469.995087,184.685226 
z"
          />
          <path
            fill="#5DA4DD"
            opacity="1.000000"
            stroke="none"
            d="
M248.908081,197.983276 
	C253.660217,189.169647 258.212524,180.237717 263.309448,171.628189 
	C264.535614,169.556992 267.469330,167.443237 269.785492,167.237518 
	C288.237396,165.598618 306.716339,164.161148 325.214539,163.183929 
	C336.838898,162.569855 336.272461,164.257507 332.798279,174.949249 
	C332.607513,175.536301 332.004547,175.989380 331.080750,176.758362 
	C330.372009,177.003769 330.175964,176.995621 329.983643,176.549911 
	C330.122375,173.189163 330.257385,170.266006 330.406250,167.043381 
	C329.070068,166.680496 327.811615,166.007431 326.584778,166.060776 
	C319.989960,166.347427 313.388733,166.657333 306.816406,167.253387 
	C294.203888,168.397232 281.609344,169.738800 268.662170,171.028717 
	C267.210541,172.374954 266.104126,173.690826 264.870026,175.295502 
	C264.480988,176.372757 264.219604,177.161240 263.618683,178.019302 
	C262.514954,179.724930 261.750824,181.360977 260.977783,183.236786 
	C260.968872,183.476532 260.965149,183.956314 260.611145,184.007935 
	C259.502869,184.853943 258.637085,185.569901 258.013672,186.456299 
	C255.324738,190.279587 252.706833,194.152817 249.774384,197.999924 
	C249.293152,197.989075 249.100616,197.986176 248.908081,197.983276 
z"
          />
          <path
            fill="#70A6D8"
            opacity="1.000000"
            stroke="none"
            d="
M317.897583,226.933884 
	C317.467773,229.861191 317.323181,232.868607 316.529816,235.693817 
	C315.697876,238.656326 313.696075,240.244873 310.184296,239.909363 
	C298.835114,238.825089 287.447968,238.133209 276.103058,237.010696 
	C264.278656,235.840744 252.461807,234.526154 240.690140,232.919647 
	C234.604752,232.089157 233.970139,230.431595 236.507309,224.968521 
	C237.731873,222.331772 238.962631,219.697922 240.587479,217.318390 
	C241.011108,218.057175 241.037842,218.540329 241.037750,219.389984 
	C240.983521,220.491730 240.956146,221.227005 240.653748,222.245636 
	C238.995377,227.238525 239.972748,230.285797 245.621216,230.433762 
	C249.084335,230.524475 252.596802,230.638214 255.986496,231.276230 
	C267.205261,233.387863 278.364685,235.860382 290.220581,235.393921 
	C293.667236,235.777161 296.793427,235.904694 300.231903,236.313354 
	C303.318481,236.741028 306.096344,236.839310 308.866211,237.045197 
	C313.276794,237.373001 315.374237,235.434784 315.020996,230.591629 
	C315.053070,229.212723 315.073944,228.212265 315.444885,227.165222 
	C316.495819,227.057037 317.196686,226.995468 317.897583,226.933884 
z"
          />
          <path
            fill="#70A6D8"
            opacity="1.000000"
            stroke="none"
            d="
M328.250183,186.934219 
	C328.011810,188.962585 328.027191,191.060577 327.492828,193.007706 
	C324.604523,203.532623 321.580078,214.020187 318.034241,224.521469 
	C317.510742,222.948746 317.332031,221.328857 317.646332,219.811066 
	C318.330170,216.508560 319.265442,213.258102 320.357910,209.704193 
	C321.079376,206.277008 321.540894,203.132019 322.012390,199.562195 
	C322.024261,197.443649 322.026184,195.749924 322.379120,194.010880 
	C324.570129,191.621780 326.410126,189.278000 328.250183,186.934219 
z"
          />
          <path
            fill="#70A6D8"
            opacity="1.000000"
            stroke="none"
            d="
M248.602600,198.149261 
	C249.100616,197.986176 249.293152,197.989075 249.742462,198.370850 
	C249.323883,200.830139 248.648499,202.910568 247.843399,205.282059 
	C247.456757,206.371521 247.199814,207.169907 246.670532,208.183960 
	C244.588669,210.928421 242.779129,213.457214 240.969589,215.986008 
	C243.412094,210.095764 245.854599,204.205505 248.602600,198.149261 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M328.234314,186.661743 
	C326.410126,189.278000 324.570129,191.621780 322.369049,193.583374 
	C322.366241,188.322815 322.920532,183.540756 328.014496,181.415710 
	C328.107483,182.996353 328.194672,184.147247 328.281860,185.298157 
	C328.260742,185.661865 328.239624,186.025558 328.234314,186.661743 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M320.097931,209.986374 
	C319.265442,213.258102 318.330170,216.508560 317.646332,219.811066 
	C317.332031,221.328857 317.510742,222.948746 317.753601,224.776154 
	C318.022186,225.454437 318.003052,225.878922 317.940735,226.618637 
	C317.196686,226.995468 316.495819,227.057037 315.408508,226.698441 
	C315.001862,223.850693 314.981598,221.423111 314.966980,218.660950 
	C314.974915,217.880203 314.977173,217.434036 315.223938,216.820831 
	C315.624664,216.098785 315.780853,215.543762 315.954987,214.632965 
	C316.645355,212.845490 317.317810,211.413788 318.341003,209.987106 
	C319.160461,209.990204 319.629211,209.988297 320.097931,209.986374 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M240.943192,216.202194 
	C242.779129,213.457214 244.588669,210.928421 246.930054,208.402847 
	C247.186035,213.063858 246.036728,217.195374 241.064590,219.023499 
	C241.037842,218.540329 241.011108,218.057175 240.931564,217.213150 
	C240.878769,216.852295 240.916794,216.418381 240.943192,216.202194 
z"
          />
          <path
            fill="#70A6D8"
            opacity="1.000000"
            stroke="none"
            d="
M328.660950,185.220184 
	C328.194672,184.147247 328.107483,182.996353 328.148560,181.116577 
	C328.844574,179.254318 329.412262,178.120911 329.979950,176.987488 
	C330.175964,176.995621 330.372009,177.003769 330.863159,177.032562 
	C330.452209,179.749557 329.746124,182.445877 328.660950,185.220184 
z"
          />
          <path
            fill="#3492EE"
            opacity="1.000000"
            stroke="none"
            d="
M426.987549,89.016647 
	C433.291321,89.056854 434.145691,89.718681 434.025421,95.712502 
	C433.812897,106.304588 433.468170,116.895920 433.012238,127.480392 
	C432.799042,132.429977 430.503143,134.005432 424.088745,134.025436 
	C411.378723,134.065048 398.668579,134.064133 385.349915,133.780823 
	C383.812439,133.003326 382.883514,132.524979 381.954590,132.046646 
	C381.954590,132.046646 381.997559,131.999390 382.031067,131.658813 
	C384.379974,128.694366 386.594727,125.970284 389.058929,123.494545 
	C390.086182,122.462517 391.644623,121.959213 392.961517,121.215500 
	C393.641113,122.740036 394.885651,124.265427 394.883362,125.788933 
	C394.878479,129.016251 395.985748,130.852982 399.171814,129.714142 
	C400.178619,129.354248 401.088562,126.731842 400.880829,125.315285 
	C400.042999,119.602066 402.925629,116.890991 407.949890,115.946648 
	C410.731384,115.423866 413.635803,115.555290 416.432495,115.394821 
	C416.948792,113.750374 416.951233,111.591980 418.003296,110.758499 
	C422.949982,106.839645 420.892365,102.087662 419.169525,98.096046 
	C417.310150,93.788055 418.772552,93.106407 422.447845,92.968254 
	C424.932739,92.874840 427.166229,92.273018 426.987549,89.016647 
z"
          />
          <path
            fill="#3C97F0"
            opacity="1.000000"
            stroke="none"
            d="
M426.521057,88.998215 
	C427.166229,92.273018 424.932739,92.874840 422.447845,92.968254 
	C418.772552,93.106407 417.310150,93.788055 419.169525,98.096046 
	C420.892365,102.087662 422.949982,106.839645 418.003296,110.758499 
	C416.951233,111.591980 416.948792,113.750374 416.432495,115.394821 
	C413.635803,115.555290 410.731384,115.423866 407.949890,115.946648 
	C402.925629,116.890991 400.042999,119.602066 400.880829,125.315285 
	C401.088562,126.731842 400.178619,129.354248 399.171814,129.714142 
	C395.985748,130.852982 394.878479,129.016251 394.883362,125.788933 
	C394.885651,124.265427 393.641113,122.740036 392.961517,121.215500 
	C391.644623,121.959213 390.086182,122.462517 389.058929,123.494545 
	C386.594727,125.970284 384.379974,128.694366 382.007019,131.680984 
	C378.474152,129.104706 381.936798,126.320427 382.781647,124.159805 
	C385.538483,117.109680 389.036377,110.315918 392.672333,103.654495 
	C395.184814,99.051331 398.302551,94.762138 401.374420,90.494324 
	C401.996613,89.629890 403.618561,89.103508 404.792603,89.080307 
	C411.877808,88.940369 418.966858,88.992142 426.521057,88.998215 
z"
          />
          <path
            fill="#3C97F0"
            opacity="1.000000"
            stroke="none"
            d="
M381.983643,132.368439 
	C382.883514,132.524979 383.812439,133.003326 384.882874,133.783783 
	C384.020447,133.620682 383.016571,133.155457 381.983643,132.368439 
z"
          />
          <path
            fill="#044BAC"
            opacity="1.000000"
            stroke="none"
            d="
M71.091820,418.082581 
	C71.093964,424.564758 71.033653,430.558929 71.126007,436.550751 
	C71.164543,439.050812 70.276886,440.002930 67.668877,440.174591 
	C56.936447,440.880981 56.942253,440.968475 56.942867,430.313019 
	C56.943039,427.316406 56.580048,424.266602 57.009827,421.332977 
	C58.381229,411.971893 53.867908,404.497314 49.751167,396.802216 
	C44.649414,387.265900 39.772770,377.609344 34.708420,368.052673 
	C33.351681,365.492432 31.501909,363.189423 30.198093,360.606934 
	C29.518358,359.260559 29.198074,357.513000 29.415462,356.050751 
	C29.493128,355.528320 31.619968,355.053680 32.832172,354.996643 
	C44.632225,354.440948 45.623512,355.162140 51.289921,367.485809 
	C53.803036,372.951447 56.156261,378.517303 59.104412,383.742981 
	C60.280888,385.828308 62.867546,387.118042 64.812263,388.769928 
	C66.798187,386.417725 69.276192,384.324524 70.676399,381.664093 
	C74.640099,374.132935 78.060608,366.317749 81.915131,358.726196 
	C83.226555,356.143341 85.353661,354.351288 88.751915,354.902527 
	C90.536003,355.191895 92.406120,354.949097 94.237389,354.949799 
	C100.909187,354.952393 101.135284,355.262695 98.061668,360.984741 
	C92.773033,370.830383 87.436752,380.650513 82.092911,390.466339 
	C78.601036,396.880432 74.805809,403.147339 71.684128,409.734924 
	C70.634163,411.950653 71.241249,414.951569 71.091820,418.082581 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M65.360878,384.288086 
	C65.135269,385.045715 64.779381,385.700195 64.423492,386.354645 
	C63.602936,385.502411 62.782375,384.650177 61.879936,383.492981 
	C61.798054,383.188019 62.030907,382.967896 62.421791,382.965637 
	C63.534283,383.298706 64.255890,383.634033 64.977493,383.969391 
	C64.977493,383.969391 65.230598,384.184875 65.360878,384.288086 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M88.396591,384.809357 
	C88.299980,385.690643 87.890823,386.704346 87.129761,387.802368 
	C87.213249,386.905060 87.648643,385.923401 88.396591,384.809357 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M299.006348,292.929443 
	C297.589478,292.946655 296.172638,292.963867 294.042603,292.998566 
	C292.914215,292.918365 292.499023,292.820679 291.981201,292.385040 
	C288.155762,291.157867 284.432861,290.268677 280.709991,289.379486 
	C279.404999,289.306000 278.100037,289.232483 276.180908,288.845093 
	C265.618744,286.387634 255.674088,284.227631 245.712326,282.149628 
	C245.223038,282.047577 244.584305,282.662048 244.015167,282.942871 
	C242.668320,282.883453 241.321472,282.824036 239.450165,282.393677 
	C236.552856,281.014313 234.254379,279.742950 231.793243,279.046570 
	C224.231339,276.906860 216.607132,274.987335 209.252884,272.716431 
	C219.185715,274.415436 229.206207,274.754578 238.513260,278.619629 
	C240.587494,279.481018 242.971466,279.655701 245.236023,279.989227 
	C254.484406,281.351501 263.741791,282.652832 273.396240,283.991516 
	C277.971161,285.330048 282.060120,287.239746 286.336700,287.864105 
	C297.840485,289.543579 309.424377,290.674194 321.068817,292.404541 
	C322.152740,293.192139 323.132690,293.918213 324.137634,293.954620 
	C335.498932,294.366119 346.868744,294.570801 358.224335,295.090790 
	C360.515167,295.195679 362.756226,296.387634 364.869415,297.359741 
	C363.516144,298.412476 362.313721,299.183868 360.807373,299.932251 
	C359.999695,299.626099 359.495880,299.342926 358.903564,298.662170 
	C352.991333,298.134918 347.167694,298.000000 341.343323,297.913879 
	C341.228333,297.912170 341.104919,298.482666 340.985443,298.786194 
	C338.670288,298.775177 336.355164,298.764160 333.470978,298.367310 
	C329.943268,297.590942 326.984619,297.200409 324.025940,296.809845 
	C323.603485,296.808960 323.181061,296.808075 322.304504,296.428772 
	C319.801178,295.829651 317.751892,295.608948 315.702637,295.388245 
	C314.404022,295.342010 313.105408,295.295776 311.210632,294.932922 
	C306.745117,294.054016 302.875732,293.491730 299.006348,292.929443 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M203.808868,273.216614 
	C202.491058,273.133484 201.173264,273.050354 199.378433,272.591003 
	C192.275909,270.426270 185.650391,268.637726 179.024887,266.849182 
	C178.598618,266.852539 178.172333,266.855865 177.330109,266.491394 
	C175.339844,265.291260 173.777176,264.435974 172.188095,263.632904 
	C170.430435,262.744720 168.648605,261.904327 167.190948,261.009674 
	C168.003708,260.649048 168.502823,260.321869 169.419891,259.996033 
	C171.586151,260.247925 173.374069,260.343048 175.075775,260.776550 
	C181.720383,262.469238 188.337708,264.268982 195.056946,266.395874 
	C196.302795,267.180481 197.530273,267.462982 198.596832,268.041962 
	C201.445084,269.588104 204.224228,271.261536 206.627808,272.916290 
	C205.419464,273.037659 204.614166,273.127136 203.808868,273.216614 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M203.934601,273.550903 
	C204.614166,273.127136 205.419464,273.037659 206.955811,272.951782 
	C208.126968,272.964264 208.567062,272.973236 209.007156,272.982208 
	C216.607132,274.987335 224.231339,276.906860 231.793243,279.046570 
	C234.254379,279.742950 236.552856,281.014313 239.050110,282.336182 
	C227.469788,279.728180 215.765060,276.806702 203.934601,273.550903 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M166.877304,261.043121 
	C168.648605,261.904327 170.430435,262.744720 172.188095,263.632904 
	C173.777176,264.435974 175.339844,265.291260 177.012451,266.453430 
	C173.299942,265.872955 169.415909,265.191406 165.694901,264.000549 
	C159.549698,262.033813 153.509720,259.738281 147.435730,257.035736 
	C149.479630,256.928070 151.521622,257.330627 153.545105,257.811096 
	C157.994125,258.867554 162.433807,259.963379 166.877304,261.043121 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M244.261230,283.232422 
	C244.584305,282.662048 245.223038,282.047577 245.712326,282.149628 
	C255.674088,284.227631 265.618744,286.387634 275.788391,288.827087 
	C270.120911,288.285828 264.207611,287.587860 258.350555,286.567291 
	C253.700439,285.756989 249.119141,284.551819 244.261230,283.232422 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M167.190948,261.009674 
	C162.433807,259.963379 157.994125,258.867554 153.545105,257.811096 
	C151.521622,257.330627 149.479630,256.928070 147.220062,256.757721 
	C146.573578,257.001678 146.153076,256.978088 145.422699,256.898621 
	C145.057205,256.147308 145.001633,255.451843 145.375305,254.384918 
	C147.935577,254.141586 150.218857,253.822998 152.167221,254.487015 
	C156.811172,256.069763 160.868896,259.596191 166.371475,258.172394 
	C167.045959,257.997864 168.115494,259.350037 169.001938,259.994690 
	C168.502823,260.321869 168.003708,260.649048 167.190948,261.009674 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M179.230774,267.153259 
	C185.650391,268.637726 192.275909,270.426270 198.983185,272.560944 
	C192.522217,271.090485 185.979446,269.273926 179.230774,267.153259 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M280.863281,289.696228 
	C284.432861,290.268677 288.155762,291.157867 291.697266,292.589966 
	C291.326294,293.267944 291.136719,293.403107 290.947144,293.538300 
	C290.525116,293.431061 290.103088,293.323822 289.353271,293.212524 
	C286.355865,292.143250 283.686188,291.078125 280.863281,289.696228 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M274.364746,294.041168 
	C273.502106,293.408264 273.004181,292.816528 272.238647,292.096130 
	C274.507751,292.448486 277.044495,292.929535 279.794861,293.683228 
	C278.248810,293.998047 276.489136,294.040192 274.364746,294.041168 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M91.061554,239.428650 
	C91.675781,239.500305 92.348930,239.897644 93.152390,240.586761 
	C92.561958,240.503815 91.841217,240.129074 91.061554,239.428650 
z"
          />
          <path
            fill="#72B0DE"
            opacity="1.000000"
            stroke="none"
            d="
M264.782959,144.350052 
	C250.712006,147.666519 236.757507,150.696609 222.776733,153.600204 
	C222.056381,153.749802 221.111145,152.816452 219.712143,152.102173 
	C221.667938,149.811066 223.344452,147.847122 225.136887,145.573471 
	C229.529129,141.972641 233.695953,138.526123 238.103363,135.421066 
	C249.049072,127.709717 259.944031,119.906601 271.174255,112.626038 
	C278.256866,108.034378 285.685944,103.826134 293.375488,100.371704 
	C299.036041,97.828758 305.348907,96.703148 311.420593,95.137863 
	C312.711395,94.805092 314.216553,95.303772 316.125641,96.267654 
	C297.508423,110.742020 279.343384,125.431992 264.782959,144.350052 
M263.718842,139.947540 
	C264.838776,138.340134 265.958740,136.732712 267.752625,134.987991 
	C271.524506,131.002106 275.296387,127.016228 279.613922,122.755569 
	C280.415222,121.851540 281.216522,120.947517 282.607941,119.747726 
	C283.448822,118.524017 284.289734,117.300316 285.893219,116.073578 
	C289.616730,112.816513 293.340271,109.559456 297.063782,106.302391 
	C296.774536,105.772041 296.485291,105.241699 296.196045,104.711349 
	C294.009277,105.435440 291.635681,105.828674 289.667603,106.940010 
	C280.389801,112.178932 271.223145,117.614624 261.303467,123.040977 
	C254.540497,127.697937 247.777527,132.354904 240.982239,137.480286 
	C240.982239,137.480286 240.974106,137.949722 240.358490,138.235672 
	C237.094498,140.969666 233.830490,143.703659 229.556824,147.283371 
	C231.959518,147.616058 232.599167,147.858917 233.181793,147.761307 
	C238.689590,146.838638 244.300598,146.262817 249.653931,144.769806 
	C254.181503,143.507095 260.054901,145.697845 263.718842,139.947540 
z"
          />
          <path
            fill="#5DA4DD"
            opacity="1.000000"
            stroke="none"
            d="
M305.121460,117.538300 
	C307.831940,114.749931 310.441498,112.278442 313.105896,109.472176 
	C313.584015,108.797623 314.007385,108.457848 315.047791,108.032501 
	C317.420074,106.941315 319.175354,105.935684 320.925842,105.274582 
	C319.616486,107.417198 318.311890,109.215294 316.791016,111.122574 
	C316.307220,111.432251 316.120422,111.690971 315.693207,112.143600 
	C314.245697,113.847290 313.119202,115.415298 311.878784,117.200706 
	C311.563507,117.690125 311.303009,117.881828 310.618439,117.996857 
	C308.864929,118.800240 307.203857,119.336662 306.133789,120.444115 
	C300.758942,126.006859 295.538574,131.718857 290.229797,137.415039 
	C290.864746,138.680023 291.431335,139.808823 291.997925,140.937607 
	C289.891571,141.367142 287.785248,141.796692 285.678925,142.226227 
	C285.335602,141.665421 284.992310,141.104614 284.649017,140.543808 
	C291.439514,132.980927 298.230011,125.418053 305.121460,117.538300 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M320.930634,104.930061 
	C319.175354,105.935684 317.420074,106.941315 315.317383,107.949547 
	C318.288574,104.553871 321.607178,101.155594 325.018250,97.438004 
	C326.096588,97.042778 327.082489,96.966873 328.431763,96.826012 
	C328.795135,96.761055 328.902740,96.891396 328.847778,97.211395 
	C328.557068,98.034035 328.321320,98.536674 327.851501,99.124847 
	C327.313507,99.379936 327.100555,99.627693 326.642944,99.988739 
	C325.458893,100.964478 324.610535,101.905128 323.565430,102.860764 
	C323.368713,102.875740 322.988739,102.981949 322.668701,103.013336 
	C321.877747,103.678024 321.406860,104.311333 320.939636,104.954819 
	C320.943298,104.964981 320.930634,104.930069 320.930634,104.930061 
z"
          />
          <path
            fill="#3696F3"
            opacity="1.000000"
            stroke="none"
            d="
M169.419891,259.996033 
	C168.115494,259.350037 167.045959,257.997864 166.371475,258.172394 
	C160.868896,259.596191 156.811172,256.069763 152.167221,254.487015 
	C150.218857,253.822998 147.935577,254.141586 145.356033,254.035248 
	C143.508392,253.746307 142.109268,253.435562 140.295410,252.716827 
	C137.642349,251.203506 135.391022,250.123566 133.170441,248.983704 
	C131.754623,248.256958 130.384323,247.441467 128.993622,246.665771 
	C129.207413,246.123367 129.421219,245.580963 129.635010,245.038559 
	C131.419556,245.038559 133.204132,245.046509 134.988617,245.036453 
	C136.993347,245.025177 138.997986,244.997620 141.429901,244.977295 
	C144.224747,244.698288 146.592377,244.419144 148.959991,244.139984 
	C149.412140,244.187057 149.864288,244.234146 150.659317,244.746857 
	C151.964859,246.141388 152.827637,247.730270 153.906158,247.893478 
	C161.625031,249.061523 169.387802,249.939392 177.540436,250.907883 
	C179.290436,250.911728 180.636627,250.917160 182.161194,251.183640 
	C183.226807,251.291168 184.114014,251.137665 185.415710,250.980865 
	C188.212753,251.308334 190.595322,251.639084 193.064453,252.351410 
	C197.695160,254.131866 202.199692,255.702866 206.820877,256.767303 
	C207.630386,256.953766 208.929886,255.012909 210.404266,254.036285 
	C211.876022,254.059845 212.946548,254.101639 214.093567,254.505066 
	C217.779205,255.583420 221.388382,256.300110 224.998627,257.461243 
	C224.999680,260.147369 224.999680,262.389099 224.999680,264.390656 
	C220.235504,265.386078 216.386246,266.751831 212.498795,266.870880 
	C206.668335,267.049438 200.811050,266.353210 194.965179,266.028931 
	C188.337708,264.268982 181.720383,262.469238 175.075775,260.776550 
	C173.374069,260.343048 171.586151,260.247925 169.419891,259.996033 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M141.002655,244.977142 
	C138.997986,244.997620 136.993347,245.025177 134.988617,245.036453 
	C133.204132,245.046509 131.419556,245.038559 129.635010,245.038559 
	C129.421219,245.580963 129.207413,246.123367 128.993622,246.665771 
	C130.384323,247.441467 131.754623,248.256958 133.170441,248.983704 
	C135.391022,250.123566 137.642349,251.203506 139.935699,252.667984 
	C135.345444,251.866211 130.632889,250.919876 126.071770,249.490341 
	C120.297585,247.680649 114.625549,245.533936 108.956985,243.405975 
	C107.512833,242.863846 106.248245,241.843353 105.081337,240.316193 
	C112.148399,237.676514 118.742386,241.353256 125.741562,241.266434 
	C126.708679,241.042923 127.445885,241.075531 128.671143,241.515762 
	C133.107010,242.941315 137.054825,243.959229 141.002655,244.977142 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M303.456909,261.154053 
	C305.259094,261.791016 306.600342,262.780457 308.020447,262.910889 
	C312.142212,263.289490 316.295654,263.323486 320.722137,264.041718 
	C321.007629,264.590759 320.984680,265.074921 320.537048,265.055481 
	C314.804810,265.310059 309.507935,265.943695 304.238861,265.763763 
	C298.145050,265.555695 292.075714,264.631439 285.662750,264.013672 
	C284.885681,264.013092 284.441650,264.016937 283.814209,263.771362 
	C282.752655,263.683594 281.874481,263.845215 280.623779,264.002106 
	C279.506134,263.996613 278.760986,263.995880 278.010071,263.625854 
	C277.990387,262.519287 277.976532,261.782013 278.424561,261.016846 
	C281.968414,261.061584 285.050293,261.182953 288.132507,261.192780 
	C291.090820,261.202240 294.049469,261.096069 297.007965,261.040710 
	C299.015961,261.065186 301.023956,261.089630 303.456909,261.154053 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M248.993530,257.074921 
	C251.325073,257.103912 253.656631,257.132904 256.569672,257.566345 
	C259.000122,258.317902 260.842102,258.709381 262.699493,259.003387 
	C267.129639,259.704651 271.567078,260.359863 275.708893,261.318604 
	C271.945923,261.739136 268.475647,261.874695 264.559845,262.005615 
	C257.607025,261.661713 251.066055,261.599976 244.612396,260.817902 
	C242.619141,260.576355 240.863693,258.372498 239.400482,257.088989 
	C242.865555,257.098907 245.929550,257.086914 248.993530,257.074921 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M192.977905,251.969818 
	C190.595322,251.639084 188.212753,251.308334 185.230957,250.737427 
	C183.748779,250.639053 182.865799,250.780823 181.982819,250.922592 
	C180.636627,250.917160 179.290436,250.911728 177.415070,250.533356 
	C172.748596,249.435074 168.569061,248.884232 164.494598,247.898941 
	C162.850739,247.501434 161.436752,246.153275 159.918167,245.237732 
	C172.441711,246.994034 184.965271,248.750336 197.473297,251.069580 
	C195.964478,251.744934 194.471191,251.857361 192.977905,251.969818 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M276.001556,261.033661 
	C271.567078,260.359863 267.129639,259.704651 262.699493,259.003387 
	C260.842102,258.709381 259.000122,258.317902 257.001892,257.613037 
	C267.463959,257.980408 278.077576,258.674408 288.685028,259.452698 
	C291.285278,259.643494 293.865295,260.109955 296.731293,260.745178 
	C294.049469,261.096069 291.090820,261.202240 288.132507,261.192780 
	C285.050293,261.182953 281.968414,261.061584 278.098389,260.997894 
	C276.874146,261.015778 276.437836,261.024719 276.001556,261.033661 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M159.553925,245.175415 
	C161.436752,246.153275 162.850739,247.501434 164.494598,247.898941 
	C168.569061,248.884232 172.748596,249.435074 177.011261,250.534943 
	C169.387802,249.939392 161.625031,249.061523 153.906158,247.893478 
	C152.827637,247.730270 151.964859,246.141388 150.975891,244.875732 
	C151.378769,244.426117 151.807968,244.313217 152.667862,244.545059 
	C154.390121,244.942825 155.681686,244.995850 156.973267,245.048859 
	C157.712067,245.070282 158.450867,245.091705 159.553925,245.175415 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M224.997559,257.016815 
	C221.388382,256.300110 217.779205,255.583420 214.484283,254.466293 
	C215.579956,253.979156 216.361359,253.892456 217.142761,253.805740 
	C217.556641,253.819733 217.970520,253.833725 218.879181,254.135162 
	C220.576035,254.312256 221.778107,254.201889 222.980179,254.091522 
	C224.981522,254.142029 226.982880,254.192535 229.549988,254.617340 
	C232.075745,255.662643 234.035721,256.333618 235.727142,257.286041 
	C231.971588,257.383942 228.484573,257.200378 224.997559,257.016815 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M193.064453,252.351395 
	C194.471191,251.857361 195.964478,251.744934 197.712952,251.370453 
	C199.004791,251.132828 200.041443,251.157227 201.592621,251.558960 
	C204.739151,252.642380 207.371109,253.348465 210.003082,254.054565 
	C208.929886,255.012909 207.630386,256.953766 206.820877,256.767303 
	C202.199692,255.702866 197.695160,254.131866 193.064453,252.351395 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M141.429901,244.977295 
	C137.054825,243.959229 133.107010,242.941315 129.036209,241.566467 
	C135.431229,241.981323 141.949203,242.753113 148.713593,243.832428 
	C146.592377,244.419144 144.224747,244.698288 141.429901,244.977295 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M235.995682,257.004608 
	C234.035721,256.333618 232.075745,255.662643 229.972900,254.675995 
	C236.048096,255.062531 242.266159,255.764709 248.738892,256.770905 
	C245.929550,257.086914 242.865555,257.098907 239.025574,257.061523 
	C237.498291,257.009644 236.746979,257.007141 235.995682,257.004608 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M210.404282,254.036301 
	C207.371109,253.348465 204.739151,252.642380 201.977753,251.609406 
	C206.854675,251.885193 211.861038,252.487869 217.005066,253.448135 
	C216.361359,253.892456 215.579956,253.979156 214.407822,254.104645 
	C212.946548,254.101639 211.876022,254.059845 210.404282,254.036301 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M156.747696,244.762024 
	C155.681686,244.995850 154.390121,244.942825 153.002716,244.585403 
	C154.111954,244.345718 155.317047,244.410431 156.747696,244.762024 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M222.785797,253.825806 
	C221.778107,254.201889 220.576035,254.312256 219.189774,254.138870 
	C220.200851,253.756790 221.396133,253.658447 222.785797,253.825806 
z"
          />
          <path
            fill="#4377BC"
            opacity="1.000000"
            stroke="none"
            d="
M330.037811,265.054688 
	C337.476074,264.921082 344.914368,264.787445 352.660248,264.384949 
	C357.948700,264.145386 362.929504,264.174744 368.493958,264.596130 
	C376.902924,268.720184 385.147614,266.545074 393.226685,266.702759 
	C396.185242,266.736511 399.143768,266.770264 402.816284,267.115784 
	C404.333435,267.585999 405.136383,267.881317 405.939880,267.882721 
	C427.069794,267.920197 448.199799,267.944214 469.329620,267.895935 
	C470.550842,267.893158 471.770599,267.260406 472.991058,266.920837 
	C475.365692,266.904327 477.740356,266.887787 480.792053,267.151550 
	C483.312439,267.238892 485.155823,267.045929 486.999176,266.852966 
	C492.614075,266.799255 498.229004,266.745514 504.766479,266.786377 
	C509.807343,266.937744 513.925598,266.994507 517.944092,267.422241 
	C511.230072,268.863617 504.615936,269.933990 497.574951,270.994751 
	C491.763824,270.984772 486.379456,270.984467 480.526764,270.981079 
	C459.038940,270.979858 438.019470,270.981689 416.577881,270.968140 
	C413.037476,270.633575 409.923309,270.092773 406.800140,270.034760 
	C395.228210,269.819855 383.316498,271.413513 372.183624,269.139526 
	C362.870148,267.237152 353.782715,267.700500 344.619263,266.863678 
	C339.744354,266.418488 334.897400,265.667572 330.037811,265.054688 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M472.675720,266.657837 
	C471.770599,267.260406 470.550842,267.893158 469.329620,267.895935 
	C448.199799,267.944214 427.069794,267.920197 405.939880,267.882721 
	C405.136383,267.881317 404.333435,267.585999 403.263489,267.095123 
	C404.087616,266.499207 405.178406,266.006622 406.269348,266.006226 
	C427.650299,265.998230 449.031281,266.044830 470.412201,266.098022 
	C471.061829,266.099640 471.710968,266.291626 472.675720,266.657837 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M393.054016,266.350098 
	C385.147614,266.545074 376.902924,268.720184 368.945007,264.650970 
	C376.835358,264.874969 384.858337,265.436218 393.054016,266.350098 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M518.043884,267.051239 
	C513.925598,266.994507 509.807343,266.937744 505.215210,266.749390 
	C505.505127,266.100769 506.243408,265.180084 507.036682,265.129944 
	C514.090088,264.684174 521.153198,264.392303 528.680420,264.160095 
	C526.607361,264.990051 524.067322,265.714996 520.884155,266.710449 
	C519.508667,267.004395 518.776245,267.027802 518.043884,267.051239 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M486.737061,266.608826 
	C485.155823,267.045929 483.312439,267.238892 481.236603,267.139709 
	C482.827728,266.686615 484.651367,266.525635 486.737061,266.608826 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M492.814301,65.441330 
	C494.067780,65.208107 495.122406,65.202126 496.570068,65.155579 
	C498.070068,65.371742 499.177032,65.628479 500.636414,65.865463 
	C508.647552,68.161308 516.313721,70.452667 523.955811,72.821945 
	C524.959351,73.133095 525.839233,73.843178 526.611511,74.890251 
	C513.292358,77.607666 500.869568,76.085648 489.850403,67.180031 
	C490.645660,66.745323 491.630554,66.206947 492.814301,65.441330 
z"
          />
          <path
            fill="#2772DB"
            opacity="1.000000"
            stroke="none"
            d="
M417.000000,270.983490 
	C438.019470,270.981689 459.038940,270.979858 480.528687,271.421173 
	C481.064819,276.338989 481.873016,281.211823 474.929169,281.355499 
	C474.272430,281.369049 473.114563,283.320496 473.120178,284.367188 
	C473.138428,287.744476 471.808807,289.055573 468.388916,289.143372 
	C467.017029,289.178650 465.685638,290.790680 464.335846,291.685028 
	C465.294067,293.079742 466.150452,294.561798 467.245728,295.838928 
	C467.991638,296.708679 469.071777,297.291748 469.533722,298.002991 
	C462.046173,298.005646 455.027374,298.008301 447.539032,298.006958 
	C441.129578,298.002594 435.184540,297.869171 429.253326,298.096466 
	C427.794525,298.152374 426.380768,299.384247 424.946320,300.075500 
	C415.815277,300.548676 406.684235,301.021851 396.909973,301.789398 
	C395.173950,302.369110 394.081146,302.654449 392.988342,302.939758 
	C392.263153,302.869629 391.537994,302.799530 390.410889,302.267548 
	C384.551270,300.981781 379.111084,299.759583 373.627747,299.518585 
	C371.241486,299.413727 369.380493,300.078979 369.327484,297.018799 
	C377.038361,296.335663 384.115570,295.752502 391.660553,297.576813 
	C397.068604,298.884430 403.424316,296.283630 409.350586,295.382996 
	C409.521149,295.357056 409.616730,294.838043 409.819519,294.388977 
	C408.166718,290.853485 401.791016,295.663452 401.144775,289.256470 
	C402.967712,289.159424 404.590790,289.275909 406.106354,288.926239 
	C407.257233,288.660706 408.428223,287.975098 409.314148,287.171875 
	C411.281952,285.387726 413.160431,283.485657 414.906342,281.484344 
	C415.791748,280.469452 416.725647,279.193665 416.904510,277.931885 
	C417.226990,275.656952 416.998444,273.303925 417.000000,270.983490 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M368.944672,297.020599 
	C369.380493,300.078979 371.241486,299.413727 373.627747,299.518585 
	C379.111084,299.759583 384.551270,300.981781 390.062256,302.162659 
	C380.609711,302.182129 371.103882,301.844635 361.327332,300.994080 
	C361.074860,300.305725 361.093048,300.130493 361.111267,299.955231 
	C362.313721,299.183868 363.516144,298.412476 365.262024,297.342834 
	C366.851837,297.036560 367.898254,297.028564 368.944672,297.020599 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M341.396118,298.898224 
	C341.104919,298.482666 341.228333,297.912170 341.343323,297.913879 
	C347.167694,298.000000 352.991333,298.134918 358.500732,298.657227 
	C356.458069,299.361694 354.729706,299.673492 353.001312,299.985291 
	C349.269806,299.660278 345.538330,299.335266 341.396118,298.898224 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M384.983276,306.532471 
	C381.041290,306.942444 376.971191,307.106171 372.916534,306.924561 
	C371.457855,306.859253 370.036316,305.963196 368.285858,305.205414 
	C373.602997,305.359650 379.232117,305.754761 384.983276,306.532471 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M299.251099,293.214722 
	C302.875732,293.491730 306.745117,294.054016 310.819733,294.939209 
	C307.181915,294.674744 303.338867,294.087341 299.251099,293.214722 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M324.244568,297.132446 
	C326.984619,297.200409 329.943268,297.590942 333.044800,298.297424 
	C330.279541,298.227203 327.371368,297.841125 324.244568,297.132446 
z"
          />
          <path
            fill="#90AFDB"
            opacity="1.000000"
            stroke="none"
            d="
M400.963837,303.673645 
	C403.495728,303.594055 406.178314,303.863922 408.979858,304.501221 
	C406.437408,304.586761 403.775970,304.304901 400.963837,303.673645 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M353.267914,300.284912 
	C354.729706,299.673492 356.458069,299.361694 358.589264,299.054810 
	C359.495880,299.342926 359.999695,299.626099 360.807373,299.932251 
	C361.093048,300.130493 361.074860,300.305725 361.023682,300.743225 
	C358.505341,300.865173 356.019928,300.724823 353.267914,300.284912 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M315.889709,295.707214 
	C317.751892,295.608948 319.801178,295.829651 321.989136,296.395325 
	C320.110840,296.502228 318.093811,296.264191 315.889709,295.707214 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M290.978516,293.862488 
	C291.136719,293.403107 291.326294,293.267944 291.799835,292.927917 
	C292.499023,292.820679 292.914215,292.918365 293.633545,293.116180 
	C293.969055,293.898102 294.000458,294.579865 294.055603,295.602478 
	C293.056183,295.357788 292.033020,294.772247 290.978516,293.862488 
z"
          />
          <path
            fill="#90AFDB"
            opacity="1.000000"
            stroke="none"
            d="
M393.174377,303.216583 
	C394.081146,302.654449 395.173950,302.369110 396.627930,302.100647 
	C396.986328,302.301544 396.983521,302.485565 396.969788,302.946655 
	C395.759369,303.313629 394.559906,303.403503 393.174377,303.216583 
z"
          />
          <path
            fill="#044BAC"
            opacity="1.000000"
            stroke="none"
            d="
M241.504242,423.970764 
	C241.083908,423.189178 240.996140,422.472839 240.908203,421.395386 
	C241.209290,420.653931 241.707108,420.308868 241.782516,419.888092 
	C243.045609,412.841217 243.036026,412.971558 250.298157,412.852020 
	C253.344208,412.801910 255.502594,413.477386 256.335083,416.633545 
	C259.785461,429.714600 276.289734,430.326691 285.192474,426.165253 
	C291.804230,423.074707 292.338531,413.696625 286.183350,409.316681 
	C283.321320,407.280029 279.778351,405.963287 276.352753,405.001068 
	C266.866333,402.336426 257.811859,398.843689 249.440765,392.950012 
	C247.857407,390.576172 246.343857,388.347717 245.443436,385.894226 
	C241.407394,374.896637 245.615234,361.620087 256.380768,356.746948 
	C264.544250,353.051666 273.571625,350.818909 283.176086,354.176483 
	C288.794800,356.140717 293.533661,358.483521 297.713196,362.751068 
	C302.420776,367.557800 304.472076,373.110962 305.065643,379.837677 
	C305.390137,380.263672 305.373322,380.443481 304.880859,381.013672 
	C301.108307,381.625183 297.809540,381.822510 294.515045,382.074829 
	C291.755829,382.286133 290.497894,381.240021 289.731323,378.356689 
	C288.926910,375.330994 287.411469,372.208527 285.368591,369.856232 
	C280.831268,364.631592 274.189240,365.154449 268.607361,366.510223 
	C265.025970,367.380096 261.178925,371.133759 259.444672,374.593964 
	C257.414795,378.643951 260.033447,382.893494 264.128021,384.939758 
	C268.897705,387.323303 274.030273,388.973755 278.974579,391.016846 
	C283.482788,392.879761 288.504944,394.102081 292.296265,396.945801 
	C297.168182,400.600006 301.037323,405.591156 305.343384,410.283752 
	C305.358307,410.930908 305.368317,411.300720 305.233246,412.141388 
	C305.205414,415.861786 305.322632,419.111298 305.415039,422.719604 
	C305.351471,424.035950 305.312744,424.993561 304.954590,426.095856 
	C300.099396,431.283264 295.790131,436.587646 289.082428,439.002869 
	C287.022552,439.744568 285.030121,440.673492 283.006836,441.516663 
	C282.620941,441.507263 282.235077,441.497864 281.561157,441.473450 
	C277.962860,441.317932 274.653870,441.109985 271.342133,441.048950 
	C259.376007,440.828339 250.010223,435.993439 243.654083,425.669891 
	C243.244431,425.004547 242.451447,424.575226 241.504242,423.970764 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M268.349792,350.719177 
	C270.605835,350.349518 273.211914,350.150360 275.991821,350.271088 
	C273.677063,350.690552 271.188477,350.790100 268.349792,350.719177 
z"
          />
          <path
            fill="#5DA4DD"
            opacity="1.000000"
            stroke="none"
            d="
M164.558487,224.631729 
	C163.842514,224.178894 163.580612,223.799591 163.195404,223.222778 
	C163.081131,222.582092 163.090195,222.138931 163.352905,221.150757 
	C164.405060,218.736969 165.203568,216.868210 166.002075,214.999466 
	C166.284470,214.246338 166.566864,213.493210 166.979584,212.469055 
	C172.106934,205.934448 177.789612,200.091568 181.948273,193.313614 
	C189.068863,181.708130 199.924026,177.307007 212.388199,175.001678 
	C220.596848,173.483459 228.750931,171.670197 237.088654,169.664978 
	C237.873047,169.317139 238.498199,169.293381 239.432968,169.309631 
	C241.153900,171.858841 240.923203,173.843964 239.224564,176.512558 
	C234.778214,183.497971 230.988403,190.901260 226.914597,198.511139 
	C226.892303,198.884262 226.929962,198.902451 226.612274,198.962875 
	C225.882599,199.686462 225.470612,200.349640 225.058594,201.012817 
	C223.837204,204.124634 222.615829,207.236481 220.855682,210.677643 
	C219.910370,211.678513 219.503815,212.350052 219.097244,213.021576 
	C217.383575,216.764359 215.538467,220.453796 213.992065,224.264420 
	C212.335083,228.347473 209.465469,229.415054 205.294144,228.883987 
	C198.912277,228.071457 192.477173,227.686478 186.073990,227.029343 
	C179.048004,226.308304 172.032578,225.484360 164.558487,224.631729 
M184.810776,222.783508 
	C191.130432,223.528091 197.465912,224.161102 203.764404,225.054901 
	C208.258331,225.692627 210.449097,223.419159 211.971985,219.623093 
	C214.413223,213.537842 216.576706,207.271469 219.717484,201.554016 
	C223.736771,194.237305 228.679230,187.431976 233.034454,180.290756 
	C233.651764,179.278580 233.753769,177.118698 233.095978,176.384735 
	C232.363358,175.567276 230.457581,175.463364 229.119141,175.600815 
	C226.721069,175.847092 224.372284,176.543655 221.990494,176.985062 
	C217.832169,177.755707 213.622757,178.302017 209.517776,179.288025 
	C207.379898,179.801544 205.403900,181.020477 203.381912,181.978043 
	C199.593491,183.772171 194.767166,184.692810 192.250427,187.601578 
	C185.287292,195.649338 179.125504,204.417084 172.981796,213.136139 
	C169.843964,217.589325 171.853973,220.666351 177.447006,221.052933 
	C179.709564,221.209305 181.940186,221.828110 184.810776,222.783508 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M219.415527,212.966095 
	C219.503815,212.350052 219.910370,211.678513 220.631653,210.934296 
	C220.542191,211.544632 220.138000,212.227600 219.415527,212.966095 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M225.376617,200.960388 
	C225.470612,200.349640 225.882599,199.686462 226.608612,198.967590 
	C226.513275,199.577225 226.103943,200.242584 225.376617,200.960388 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M457.398743,63.990868 
	C458.335541,63.392681 459.621643,62.759323 460.985474,62.413742 
	C459.958160,63.119576 458.853088,63.537640 457.398743,63.990868 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M65.025154,383.622131 
	C64.255890,383.634033 63.534283,383.298706 62.537392,382.854706 
	C61.855949,381.402679 61.449795,380.059326 61.016464,378.361267 
	C63.086464,377.776245 65.183640,377.545868 67.639786,377.157776 
	C67.023445,379.091614 66.048126,381.183228 65.025154,383.622131 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M305.338409,410.006409 
	C301.037323,405.591156 297.168182,400.600006 292.296265,396.945801 
	C288.504944,394.102081 283.482788,392.879761 278.974579,391.016846 
	C274.030273,388.973755 268.897705,387.323303 264.128021,384.939758 
	C260.033447,382.893494 257.414795,378.643951 259.444672,374.593964 
	C261.178925,371.133759 265.025970,367.380096 268.607361,366.510223 
	C274.189240,365.154449 280.831268,364.631592 285.368591,369.856232 
	C287.411469,372.208527 288.926910,375.330994 289.731323,378.356689 
	C290.497894,381.240021 291.755829,382.286133 294.515045,382.074829 
	C297.809540,381.822510 301.108307,381.625183 304.817993,381.277130 
	C303.193359,382.062073 301.168427,383.718567 299.116241,383.752991 
	C291.462738,383.881500 288.870300,385.879364 286.012390,375.021332 
	C283.990082,367.338135 273.071075,365.529388 264.854919,371.325195 
	C264.738770,371.655518 264.714172,371.714935 264.363739,371.819946 
	C259.479126,375.932526 259.628296,378.032898 264.742371,382.190826 
	C264.742371,382.190826 264.789124,382.213287 264.882996,382.567261 
	C267.750763,384.255493 270.446442,385.798126 273.316528,386.876526 
	C277.918610,388.605682 282.665619,389.944946 287.298462,391.597290 
	C295.584167,394.552490 301.570801,400.358124 306.505157,407.413513 
	C306.518158,407.757751 306.531189,408.102020 306.399170,408.806946 
	C305.948914,409.447266 305.643677,409.726837 305.338409,410.006409 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M248.342346,445.943848 
	C247.249832,446.000214 246.500290,446.000427 245.375977,446.001221 
	C244.504517,445.083130 244.007812,444.164459 243.213394,443.096741 
	C243.099121,442.402649 243.282562,441.857635 244.049561,441.153198 
	C245.088730,440.995880 245.544373,440.997955 246.159546,441.304626 
	C247.545654,442.071991 248.772217,442.534760 249.998779,442.997559 
	C249.560959,443.960938 249.123123,444.924286 248.342346,445.943848 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M246.000000,441.000000 
	C245.544373,440.997955 245.088730,440.995880 244.290497,440.986206 
	C244.466553,439.429565 244.985199,437.880493 245.775085,436.104004 
	C246.345734,436.032379 246.604324,436.239166 246.913696,437.077667 
	C246.670181,438.772247 246.335098,439.886108 246.000000,441.000000 
z"
          />
          <path
            fill="#538ACD"
            opacity="1.000000"
            stroke="none"
            d="
M437.060516,73.086678 
	C430.830872,72.840546 424.601257,72.594414 418.371613,72.348282 
	C418.232330,71.789619 418.093048,71.230957 417.953735,70.672287 
	C422.282318,68.412415 426.610901,66.152550 431.026978,63.562748 
	C432.780243,63.164101 434.445953,63.095383 436.578491,63.416260 
	C439.435120,66.635300 439.307983,69.707001 437.705872,72.947968 
	C437.491119,72.995934 437.060516,73.086678 437.060516,73.086678 
z"
          />
          <path
            fill="#90AFDB"
            opacity="1.000000"
            stroke="none"
            d="
M437.920593,72.899994 
	C439.307983,69.707001 439.435120,66.635300 436.996399,63.444565 
	C441.489624,63.364071 443.031464,67.345436 439.988281,72.819366 
	C438.858063,72.963615 438.389313,72.931808 437.920593,72.899994 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M437.705872,72.947968 
	C438.389313,72.931808 438.858063,72.963615 439.678894,73.017990 
	C439.157990,73.875946 438.284973,74.711334 437.215759,75.323029 
	C437.029633,74.651260 437.039642,74.203186 437.055084,73.420898 
	C437.060516,73.086678 437.491119,72.995934 437.705872,72.947968 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M292.367493,140.959671 
	C291.431335,139.808823 290.864746,138.680023 290.229797,137.415039 
	C295.538574,131.718857 300.758942,126.006859 306.133789,120.444115 
	C307.203857,119.336662 308.864929,118.800240 310.477325,118.298508 
	C305.935364,123.779900 301.088379,128.893723 296.509125,134.237030 
	C295.953552,134.885300 296.841156,136.770370 296.876709,138.322113 
	C296.111176,139.302765 295.528778,140.043503 294.946411,140.784256 
	C294.209961,140.850082 293.473541,140.915909 292.367493,140.959671 
z"
          />
          <path
            fill="#5DA4DD"
            opacity="1.000000"
            stroke="none"
            d="
M295.392487,140.866882 
	C295.528778,140.043503 296.111176,139.302765 297.270996,138.313995 
	C300.220551,138.368134 302.592621,138.670303 305.334381,138.981354 
	C306.807404,139.330780 307.910767,139.671356 309.014130,140.011932 
	C309.012451,140.196075 309.010803,140.380219 309.007385,140.841278 
	C307.310272,141.110062 305.614899,141.101929 303.500305,141.140839 
	C300.666901,141.108414 298.252747,141.028961 295.392487,140.866882 
z"
          />
          <path
            fill="#5DA4DD"
            opacity="1.000000"
            stroke="none"
            d="
M324.372192,140.773804 
	C320.604187,140.507645 317.239441,140.262375 313.527649,139.589050 
	C314.971802,138.774170 316.751099,138.314606 318.557190,138.018173 
	C321.030304,137.612305 323.523804,137.330353 326.337585,136.995865 
	C327.105438,136.992554 327.544281,136.988647 328.437256,136.991638 
	C337.818634,137.103775 346.617371,136.953430 350.196838,126.350586 
	C350.244965,126.207993 350.449768,126.124207 350.520630,125.982544 
	C352.684296,121.656700 354.840332,117.327034 356.998779,112.998978 
	C357.000000,113.000000 356.997559,112.997475 357.303040,112.851982 
	C358.835541,111.184608 359.942047,109.542320 361.315887,108.167168 
	C363.792389,105.688347 366.428925,103.369453 368.998627,100.983749 
	C365.771057,106.047577 362.425598,111.041176 359.354645,116.198265 
	C357.044434,120.077820 355.086426,124.167114 352.938660,128.536865 
	C352.747406,128.955063 352.484406,128.956345 352.445862,129.049988 
	C348.314514,139.089233 339.296997,138.567932 330.763092,139.145798 
	C330.192322,139.184448 329.655548,139.724640 328.929199,140.370499 
	C327.428772,140.737869 326.102142,140.766281 324.372192,140.773804 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M369.051392,100.795044 
	C366.428925,103.369453 363.792389,105.688347 361.315887,108.167168 
	C359.942047,109.542320 358.835541,111.184608 357.302490,112.463821 
	C358.075897,109.903564 358.990021,107.488914 360.278625,105.294159 
	C361.731262,102.820122 363.521118,100.544098 365.387421,97.861160 
	C355.420441,93.826912 346.198334,95.504417 337.035156,96.604958 
	C337.579132,95.601013 338.011841,94.488487 338.568695,94.422455 
	C348.804565,93.208664 359.045624,92.014503 369.308929,91.070457 
	C372.321960,90.793304 372.358490,92.788696 371.323792,95.028168 
	C370.537231,96.730598 369.862640,98.484779 369.138245,100.215935 
	C369.138245,100.215935 369.104156,100.606331 369.051392,100.795044 
z"
          />
          <path
            fill="#5DA4DD"
            opacity="1.000000"
            stroke="none"
            d="
M369.458557,100.105576 
	C369.862640,98.484779 370.537231,96.730598 371.323792,95.028168 
	C372.358490,92.788696 372.321960,90.793304 369.308929,91.070457 
	C359.045624,92.014503 348.804565,93.208664 338.568695,94.422455 
	C338.011841,94.488487 337.579132,95.601013 336.708954,96.611771 
	C335.894257,97.009720 335.459290,97.022316 334.627136,97.024681 
	C333.433319,96.971977 332.636658,96.929497 331.839966,96.887024 
	C334.511536,95.249847 336.996307,92.838623 339.894196,92.139435 
	C345.621216,90.757652 351.576813,90.323120 357.725952,89.199600 
	C359.047791,88.884613 360.081573,88.866951 361.961304,88.891182 
	C366.223297,88.866600 369.772339,89.365318 373.023773,88.599808 
	C377.774597,87.481285 378.139404,89.325882 377.025604,93.013474 
	C377.025604,93.013474 377.010132,93.001450 377.012146,93.013626 
	C374.602417,95.348938 372.190643,97.672073 369.458557,100.105576 
z"
          />
          <path
            fill="#2B7CE1"
            opacity="1.000000"
            stroke="none"
            d="
M369.327484,297.018799 
	C367.898254,297.028564 366.851837,297.036560 365.412842,297.061462 
	C362.756226,296.387634 360.515167,295.195679 358.224335,295.090790 
	C346.868744,294.570801 335.498932,294.366119 324.137634,293.954620 
	C323.132690,293.918213 322.152740,293.192139 321.307434,292.133484 
	C323.524139,291.487305 326.284698,292.358734 327.546265,291.351868 
	C333.178467,286.856934 339.222809,289.504669 345.115631,289.608246 
	C349.149048,289.679169 353.196167,290.112305 357.210205,289.892120 
	C364.221222,289.507538 360.664856,283.851379 361.600220,280.441101 
	C361.671204,280.182343 361.430206,279.838013 361.393616,279.721863 
	C356.314972,276.976593 350.585724,275.126831 346.661255,271.384460 
	C343.192657,268.076874 339.877838,268.003693 335.975769,268.000458 
	C332.143402,267.997284 328.287933,268.234253 324.491760,267.863190 
	C323.243805,267.741211 322.149323,266.049072 320.984680,265.074921 
	C320.984680,265.074921 321.007629,264.590759 321.005341,264.349487 
	C323.763641,264.316376 326.524231,264.524506 329.661316,264.893677 
	C334.897400,265.667572 339.744354,266.418488 344.619263,266.863678 
	C353.782715,267.700500 362.870148,267.237152 372.183624,269.139526 
	C383.316498,271.413513 395.228210,269.819855 406.800140,270.034760 
	C409.923309,270.092773 413.037476,270.633575 416.577911,270.968140 
	C416.998444,273.303925 417.226990,275.656952 416.904510,277.931885 
	C416.725647,279.193665 415.791748,280.469452 414.906342,281.484344 
	C413.160431,283.485657 411.281952,285.387726 409.314148,287.171875 
	C408.428223,287.975098 407.257233,288.660706 406.106354,288.926239 
	C404.590790,289.275909 402.967712,289.159424 401.144775,289.256470 
	C401.791016,295.663452 408.166718,290.853485 409.819519,294.388977 
	C409.616730,294.838043 409.521149,295.357056 409.350586,295.382996 
	C403.424316,296.283630 397.068604,298.884430 391.660553,297.576813 
	C384.115570,295.752502 377.038361,296.335663 369.327484,297.018799 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M377.384155,92.980400 
	C378.139404,89.325882 377.774597,87.481285 373.023773,88.599808 
	C369.772339,89.365318 366.223297,88.866600 362.344910,88.828354 
	C367.689026,87.916306 373.641602,86.008751 379.242188,86.752373 
	C382.169464,87.141045 380.219513,91.191269 377.384155,92.980400 
z"
          />
          <path
            fill="#C4D9EC"
            opacity="1.000000"
            stroke="none"
            d="
M558.334106,82.820618 
	C557.966492,81.796593 557.975159,80.827126 557.978088,79.493942 
	C558.945312,79.709892 559.918335,80.289574 560.891296,80.869263 
	C560.164307,81.537903 559.437317,82.206543 558.334106,82.820618 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M331.628754,96.904846 
	C332.636658,96.929497 333.433319,96.971977 334.612427,97.403046 
	C334.141754,98.841568 333.430145,100.064178 332.404877,100.903763 
	C330.541992,102.429306 327.657440,103.303177 326.691376,105.206390 
	C324.546387,109.432320 321.116669,110.563248 317.007324,111.013390 
	C318.311890,109.215294 319.616486,107.417198 320.925842,105.274590 
	C320.930634,104.930069 320.943298,104.964981 321.269409,104.936737 
	C322.059937,104.266312 322.524323,103.624130 322.988739,102.981949 
	C322.988739,102.981949 323.368713,102.875740 323.931763,102.887161 
	C325.322754,101.916946 326.150665,100.935310 326.978577,99.953659 
	C327.100555,99.627693 327.313507,99.379936 328.195892,99.116119 
	C329.516724,98.348984 330.259064,97.676132 331.001373,97.003273 
	C331.001373,97.003265 331.417542,96.922684 331.628754,96.904846 
z"
          />
          <path
            fill="#5DA4DD"
            opacity="1.000000"
            stroke="none"
            d="
M330.670288,96.983551 
	C330.259064,97.676132 329.516724,98.348984 328.429993,99.030579 
	C328.321320,98.536674 328.557068,98.034035 328.903564,97.274887 
	C329.455933,97.000183 329.897583,96.982010 330.670288,96.983551 
z"
          />
          <path
            fill="#F8F9F9"
            opacity="1.000000"
            stroke="none"
            d="
M338.528870,361.547729 
	C338.476562,361.578522 338.581146,361.516907 338.528870,361.547729 
z"
          />
          <path
            fill="#AFD1EB"
            opacity="1.000000"
            stroke="none"
            d="
M165.664444,215.047760 
	C165.203568,216.868210 164.405060,218.736969 163.350952,220.818420 
	C163.839172,219.052750 164.582993,217.074402 165.664444,215.047760 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M326.008545,136.995285 
	C323.523804,137.330353 321.030304,137.612305 318.557190,138.018173 
	C316.751099,138.314606 314.971802,138.774170 313.075958,139.543640 
	C311.916138,139.965820 310.860931,140.005386 309.409912,140.028442 
	C307.910767,139.671356 306.807404,139.330780 305.433899,138.599350 
	C312.014526,136.129120 318.911682,134.846436 326.008545,136.995285 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M377.091919,370.567749 
	C384.533112,372.755707 389.679779,379.124573 389.884521,386.230103 
	C390.057129,392.220428 389.666626,398.246674 390.162567,404.204010 
	C391.316193,418.062195 380.702362,426.455719 367.180054,425.653168 
	C362.448090,425.372345 360.553284,424.236725 360.693726,419.002991 
	C361.082275,404.522827 360.919342,390.023590 360.731049,375.535004 
	C360.677582,371.423370 361.989807,369.694000 366.203064,370.106110 
	C369.674377,370.445679 373.185760,370.375336 377.091919,370.567749 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M306.661835,407.050995 
	C301.570801,400.358124 295.584167,394.552490 287.298462,391.597290 
	C282.665619,389.944946 277.918610,388.605682 273.316528,386.876526 
	C270.446442,385.798126 267.750763,384.255493 264.892883,382.591248 
	C269.804565,383.700653 274.842712,385.009735 279.782257,386.621094 
	C283.658844,387.885651 287.413483,389.523895 291.581543,391.077026 
	C296.518524,394.911530 301.096283,398.665192 305.830139,402.711243 
	C306.263641,404.231934 306.541077,405.460205 306.661835,407.050995 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M264.814545,381.780060 
	C259.628296,378.032898 259.479126,375.932526 264.356445,371.851318 
	C264.761993,374.974854 264.824371,378.172058 264.814545,381.780060 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M155.815460,561.578491 
	C157.542511,560.374268 159.269577,559.170105 161.121918,557.675049 
	C161.640060,557.338257 162.032944,557.292358 162.963882,557.453613 
	C165.425278,556.856567 167.348587,556.052429 169.668091,555.209351 
	C171.111740,555.241211 172.159210,555.312073 173.076553,555.685913 
	C168.178314,560.739807 161.635406,560.051270 155.815460,561.578491 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M173.206665,555.382935 
	C172.159210,555.312073 171.111740,555.241211 169.592346,555.140991 
	C169.120392,555.111633 168.972153,554.971436 168.989090,554.740173 
	C169.006027,554.508911 169.022049,554.045349 169.022049,554.045349 
	C169.822098,553.791199 170.622147,553.537048 171.713684,553.153015 
	C174.857346,552.501038 177.709518,551.978821 180.623016,551.983276 
	C179.457520,553.360840 178.230682,554.211731 176.621521,555.074463 
	C175.228348,555.185181 174.217499,555.284058 173.206665,555.382935 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M169.196136,555.179932 
	C167.348587,556.052429 165.425278,556.856567 163.262634,557.454956 
	C163.504959,556.606262 163.986572,555.963257 165.140625,555.181824 
	C166.866074,555.019409 167.919113,554.995422 168.972153,554.971436 
	C168.972153,554.971436 169.120392,555.111633 169.196136,555.179932 
z"
          />
          <path
            fill="#DEEDF5"
            opacity="1.000000"
            stroke="none"
            d="
M168.989090,554.740173 
	C167.919113,554.995422 166.866074,555.019409 165.418152,555.044678 
	C166.207993,554.531860 167.392715,554.017883 168.799744,553.774658 
	C169.022049,554.045349 169.006027,554.508911 168.989090,554.740173 
z"
          />
          <path
            fill="#305CB3"
            opacity="1.000000"
            stroke="none"
            d="
M112.755447,583.808105 
	C112.080078,583.997437 111.404709,584.186768 110.370407,584.398438 
	C109.212570,584.255798 108.413658,584.090881 107.020744,583.652588 
	C103.454964,583.379150 100.483177,583.379150 97.435242,583.379150 
	C99.475037,581.579651 101.317574,579.954102 103.160095,578.328613 
	C103.572578,578.255127 103.985054,578.181641 104.712326,578.093140 
	C106.311325,577.942139 107.647903,577.989075 108.870995,577.639221 
	C115.304558,575.799255 121.709457,573.859009 128.485855,572.327759 
	C126.275948,573.814087 123.704514,574.926147 121.133072,576.038208 
	C121.082993,576.515747 121.032906,576.993225 120.982826,577.470703 
	C123.647118,577.978821 126.311417,578.486938 128.968033,579.261963 
	C128.923492,579.881775 128.886612,580.234863 128.849731,580.587891 
	C128.849731,580.587891 129.016144,580.862183 129.047638,581.019897 
	C126.681755,581.111145 124.284386,581.044678 121.211601,580.716675 
	C117.942604,581.572754 115.349030,582.690430 112.755447,583.808105 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M102.785164,578.245972 
	C101.317574,579.954102 99.475037,581.579651 97.435242,583.379150 
	C100.483177,583.379150 103.454964,583.379150 106.700775,583.647827 
	C99.238426,586.078857 91.514023,588.352600 83.438919,587.366638 
	C83.280190,586.752197 83.121468,586.137817 82.962738,585.523376 
	C89.445236,583.070068 95.927727,580.616699 102.785164,578.245972 
z"
          />
          <path
            fill="#F7F7F7"
            opacity="1.000000"
            stroke="none"
            d="
M314.287659,425.710632 
	C312.814362,424.792755 311.629974,423.584320 310.178833,421.725220 
	C309.910583,418.056671 309.909088,415.038727 309.907562,412.020813 
	C310.732910,410.456787 311.558289,408.892761 312.691833,407.164368 
	C313.525513,413.140045 314.051025,419.280060 314.287659,425.710632 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M355.728607,520.170349 
	C353.287598,521.080933 350.425232,521.879028 347.300568,522.431763 
	C349.794617,521.476929 352.550903,520.767395 355.728607,520.170349 
z"
          />
          <path
            fill="#305CB3"
            opacity="1.000000"
            stroke="none"
            d="
M201.072342,568.058289 
	C201.029236,568.689697 200.986145,569.321045 200.908569,570.261475 
	C193.215973,571.282104 185.557861,571.993713 177.391357,572.360352 
	C175.592041,572.349365 174.301117,572.683289 173.010178,573.017151 
	C171.901047,573.289856 170.791901,573.562622 169.134491,573.626465 
	C167.650543,573.775757 166.714905,574.133972 165.779266,574.492249 
	C164.451324,574.633301 163.123398,574.774292 161.127701,574.648560 
	C157.644653,574.555115 154.829361,574.728333 152.096191,574.546021 
	C155.385757,573.129517 158.532303,571.433899 161.811386,571.119141 
	C174.875946,569.865112 187.981781,569.041321 201.072342,568.058289 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M152.014069,574.901611 
	C154.829361,574.728333 157.644653,574.555115 160.724518,574.644287 
	C159.361206,575.596863 157.733322,576.287048 155.947906,577.272278 
	C154.458359,577.652283 153.126312,577.737305 151.173416,577.615845 
	C149.718475,577.299622 148.884399,577.189880 148.042206,576.743835 
	C148.051453,575.960876 148.068848,575.514160 148.478790,575.056519 
	C149.918930,574.997498 150.966492,574.949524 152.014069,574.901611 
z"
          />
          <path
            fill="#507ABE"
            opacity="1.000000"
            stroke="none"
            d="
M173.422852,573.061523 
	C174.301117,572.683289 175.592041,572.349365 176.992050,572.316528 
	C176.012589,572.780273 174.924042,572.943054 173.422852,573.061523 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M166.124054,574.624756 
	C166.714905,574.133972 167.650543,573.775757 168.786972,573.633301 
	C168.148117,574.151794 167.308487,574.454529 166.124054,574.624756 
z"
          />
          <path
            fill="#2162CC"
            opacity="1.000000"
            stroke="none"
            d="
M152.096191,574.546021 
	C150.966492,574.949524 149.918930,574.997498 148.043106,575.026367 
	C145.205673,575.789612 141.963699,573.111145 141.124115,577.176880 
	C141.124115,577.176880 141.248657,577.238770 140.794754,577.169006 
	C138.252594,577.096252 136.164337,577.093323 133.728394,577.086975 
	C131.912384,577.720764 130.444046,578.357910 128.975708,578.995117 
	C126.311417,578.486938 123.647118,577.978821 120.982826,577.470703 
	C121.032906,576.993225 121.082993,576.515747 121.133072,576.038208 
	C123.704514,574.926147 126.275948,573.814087 128.906342,572.332825 
	C128.965302,571.963684 128.983948,571.981812 129.391541,571.984375 
	C132.192307,570.994873 134.585480,570.002686 136.978653,569.010498 
	C139.719452,567.742981 142.460251,566.475464 144.931305,565.051392 
	C146.142303,564.465759 147.623047,564.036560 149.688705,563.957275 
	C152.178772,564.370605 154.182602,564.850647 155.972351,564.426819 
	C164.679153,562.364868 173.348694,560.133789 181.974930,557.757446 
	C183.151398,557.433289 184.010452,555.956970 185.009308,555.009644 
	C185.002136,555.005127 185.013168,555.016235 185.410309,555.012451 
	C188.200089,554.341614 190.592728,553.674561 193.364212,552.999146 
	C196.164017,551.726807 198.584976,550.462891 201.375839,549.143188 
	C202.492981,549.052429 203.240250,549.017395 204.205383,549.246033 
	C205.944504,549.338440 207.465744,549.167175 208.987000,548.995972 
	C214.542755,548.947998 220.308762,549.941223 225.011597,545.695251 
	C225.356003,545.384277 225.895203,545.206909 226.369476,545.133850 
	C240.979599,542.883667 255.084991,537.655334 270.136200,537.885193 
	C272.796478,537.925903 275.476868,536.653320 278.821442,536.262268 
	C282.007568,536.011108 284.520203,535.484863 287.032837,534.958618 
	C287.462708,534.960022 287.892578,534.961365 288.886597,535.237854 
	C292.063843,536.039734 293.360687,536.822449 292.247986,540.143921 
	C290.937622,544.055298 288.801392,545.631470 284.929352,544.984375 
	C275.721191,543.445557 272.959381,552.272034 267.059662,556.042969 
	C266.899872,556.145081 267.015350,556.677795 267.002441,557.009766 
	C266.332306,558.081421 265.662140,559.153076 264.978394,560.594971 
	C243.932999,563.317322 222.901154,565.669373 201.470825,568.039795 
	C187.981781,569.041321 174.875946,569.865112 161.811386,571.119141 
	C158.532303,571.433899 155.385757,573.129517 152.096191,574.546021 
z"
          />
          <path
            fill="#305CB3"
            opacity="1.000000"
            stroke="none"
            d="
M185.016479,555.014160 
	C184.010452,555.956970 183.151398,557.433289 181.974930,557.757446 
	C173.348694,560.133789 164.679153,562.364868 155.972351,564.426819 
	C154.182602,564.850647 152.178772,564.370605 150.007385,563.969482 
	C149.926041,563.358582 150.110916,563.085327 150.873398,562.818848 
	C152.295288,562.456543 153.139587,562.087524 153.983887,561.718506 
	C154.391937,561.699951 154.799988,561.681396 155.511749,561.620667 
	C161.635406,560.051270 168.178314,560.739807 173.076553,555.685913 
	C174.217499,555.284058 175.228348,555.185181 176.846527,555.336792 
	C179.248611,555.339050 181.043381,555.090820 183.199844,554.885376 
	C184.046524,554.956848 184.531509,554.985474 185.016479,555.014160 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M153.769867,561.521484 
	C153.139587,562.087524 152.295288,562.456543 151.085052,562.684204 
	C151.664688,562.136658 152.610275,561.730469 153.769867,561.521484 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M203.987488,548.982422 
	C203.240250,549.017395 202.492981,549.052429 201.360153,548.860535 
	C200.983398,548.446289 200.992203,548.258911 201.000992,548.071594 
	C208.036758,546.061890 215.063629,544.020264 222.111832,542.055176 
	C225.873184,541.006531 229.671387,540.090149 233.780945,538.888672 
	C234.554916,538.730652 235.000916,538.798035 235.760223,538.994629 
	C233.298019,540.606812 230.675110,542.660645 227.714691,543.452087 
	C221.860626,545.017334 215.819931,545.884399 209.086487,547.040955 
	C206.873535,547.688660 205.430511,548.335510 203.987488,548.982422 
z"
          />
          <path
            fill="#3D73BB"
            opacity="1.000000"
            stroke="none"
            d="
M200.614288,548.094849 
	C200.992203,548.258911 200.983398,548.446289 200.990265,548.916260 
	C198.584976,550.462891 196.164017,551.726807 192.945801,552.993774 
	C190.747726,552.981750 189.346939,552.966675 187.966400,552.673279 
	C187.985733,552.209045 187.984787,552.023010 187.983841,551.836975 
	C189.049911,551.574036 190.115982,551.311035 191.810120,550.975830 
	C193.007553,550.374268 193.576904,549.844910 194.146271,549.315552 
	C196.173386,548.916382 198.200485,548.517212 200.614288,548.094849 
z"
          />
          <path
            fill="#3D73BB"
            opacity="1.000000"
            stroke="none"
            d="
M185.009308,555.009644 
	C184.531509,554.985474 184.046524,554.956848 183.242752,554.550903 
	C182.967300,553.496216 183.010620,552.818726 183.053955,552.141296 
	C183.200104,552.055359 183.346268,551.969421 183.709076,551.748169 
	C184.073547,551.738159 184.221420,551.863525 184.658722,552.320312 
	C184.948120,552.651794 184.998840,553.121887 185.000610,553.437134 
	C185.005966,554.173645 185.009552,554.594910 185.013153,555.016235 
	C185.013168,555.016235 185.002136,555.005127 185.009308,555.009644 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M184.998840,553.121887 
	C184.998840,553.121887 184.948120,552.651794 184.932907,552.417175 
	C185.810974,551.931885 186.704254,551.681213 187.790680,551.633789 
	C187.984787,552.023010 187.985733,552.209045 187.597961,552.680298 
	C186.472458,553.017639 185.735641,553.069763 184.998840,553.121887 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M193.830688,549.238159 
	C193.576904,549.844910 193.007553,550.374268 192.117279,550.845337 
	C192.369263,550.244995 192.942184,549.702942 193.830688,549.238159 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M283.301300,441.707428 
	C285.030121,440.673492 287.022552,439.744568 289.082428,439.002869 
	C295.790131,436.587646 300.099396,431.283264 305.172058,426.105347 
	C305.708954,425.970184 306.126160,426.094147 306.126160,426.094147 
	C306.126160,426.094147 306.561371,426.092224 306.561340,426.092224 
	C306.538452,426.425110 306.515564,426.757996 306.431366,427.332581 
	C300.686584,432.233398 295.003113,436.892487 289.151245,441.862457 
	C288.567322,442.176971 288.151825,442.180542 287.430786,442.247467 
	C285.948761,442.173248 284.772278,442.035706 283.301300,441.707428 
z"
          />
          <path
            fill="#F3F4F4"
            opacity="1.000000"
            stroke="none"
            d="
M246.159546,441.304626 
	C246.335098,439.886108 246.670181,438.772247 247.021362,437.302307 
	C247.944244,437.744232 248.851044,438.542267 249.894867,439.607910 
	C250.021194,440.656799 250.010513,441.438110 249.999298,442.608490 
	C248.772217,442.534760 247.545654,442.071991 246.159546,441.304626 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M226.836334,601.057678 
	C226.429199,601.306335 225.867722,601.253784 225.279785,600.926575 
	C225.253326,600.651917 225.655914,600.575195 225.856842,600.534912 
	C226.265854,600.581909 226.473923,600.669189 226.836334,601.057678 
z"
          />
          <path
            fill="#305CB3"
            opacity="1.000000"
            stroke="none"
            d="
M136.590027,568.994751 
	C134.585480,570.002686 132.192307,570.994873 129.387451,571.976562 
	C131.384293,570.970459 133.792847,569.974670 136.590027,568.994751 
z"
          />
          <path
            fill="#3965B7"
            opacity="1.000000"
            stroke="none"
            d="
M192.876740,615.140076 
	C190.427246,613.145996 187.977753,611.151917 185.181091,609.183594 
	C184.833908,609.209351 184.841064,609.176758 184.848221,608.830811 
	C184.226578,607.997131 183.597778,607.509460 182.968979,607.021729 
	C182.968979,607.021729 182.839844,606.635925 182.791962,606.439087 
	C182.745972,605.757324 182.959381,605.045410 182.718567,604.821045 
	C177.965790,600.393188 180.677368,597.638672 184.888977,594.889160 
	C187.329666,594.546143 189.698273,594.491577 192.480499,594.552734 
	C192.894135,594.668396 193.004440,595.037964 192.946625,595.384033 
	C192.170761,596.349426 191.544220,597.157166 190.719788,597.557617 
	C185.280899,600.199768 184.864929,602.366272 188.438293,607.203552 
	C190.224945,609.622192 191.416199,612.480591 192.876740,615.140076 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M182.979950,607.344971 
	C183.597778,607.509460 184.226578,607.997131 184.838379,608.821777 
	C184.211227,608.661865 183.601074,608.164978 182.979950,607.344971 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M381.722412,518.531311 
	C380.491272,519.041687 379.005280,519.322754 377.270569,519.358521 
	C378.503754,518.842773 379.985657,518.572388 381.722412,518.531311 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M388.820618,517.204956 
	C387.959717,517.825134 386.729889,518.330505 385.126160,518.721436 
	C385.985413,518.101379 387.218567,517.595764 388.820618,517.204956 
z"
          />
          <path
            fill="#788CBE"
            opacity="1.000000"
            stroke="none"
            d="
M392.855988,516.322510 
	C392.576599,516.897766 391.968506,517.264160 391.202026,517.429810 
	C391.033630,516.995544 391.023621,516.762146 391.006561,516.177490 
	C391.508759,515.922058 392.018005,516.017883 392.855988,516.322510 
z"
          />
          <path
            fill="#3965B7"
            opacity="1.000000"
            stroke="none"
            d="
M197.784119,593.265991 
	C199.823792,593.543701 201.531738,593.820129 203.526947,594.496460 
	C201.198395,594.902039 198.582596,594.907715 195.989532,594.677734 
	C196.067001,594.134216 196.115341,593.825500 196.157257,593.515808 
	C196.588959,593.432068 197.020676,593.348389 197.784119,593.265991 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M195.848206,593.382202 
	C196.115341,593.825500 196.067001,594.134216 195.619720,594.692993 
	C194.486267,594.975342 193.745346,595.006653 193.004440,595.037964 
	C193.004440,595.037964 192.894135,594.668396 192.860107,594.474304 
	C193.730423,593.936340 194.634796,593.592468 195.848206,593.382202 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M250.998779,536.109680 
	C247.631454,540.501465 242.793869,538.886597 238.279633,539.079285 
	C238.000381,538.358643 237.997177,538.168091 237.993973,537.977539 
	C238.718277,537.937805 239.442596,537.898071 240.518494,537.768066 
	C242.540466,537.146912 244.210861,536.616028 245.987274,535.791992 
	C247.449631,535.390442 248.806015,535.282043 250.571381,535.181519 
	C250.983612,535.393921 250.986847,535.598450 250.998779,536.109680 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M264.812042,533.237671 
	C262.444763,538.299683 257.729462,536.451050 253.309143,536.400757 
	C256.815308,535.144897 260.612152,534.141663 264.812042,533.237671 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M269.937988,532.348389 
	C269.602936,532.816711 268.959686,533.156555 268.163116,533.285278 
	C268.549805,532.789490 269.089813,532.504700 269.937988,532.348389 
z"
          />
          <path
            fill="#E9EFF5"
            opacity="1.000000"
            stroke="none"
            d="
M273.156555,529.314941 
	C273.482117,528.765503 273.998840,528.421631 274.847778,528.097778 
	C274.569214,528.585327 273.958466,529.052979 273.156555,529.314941 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M224.867432,598.692261 
	C223.165604,596.714539 221.528580,594.327087 219.857666,591.582642 
	C220.103806,591.253296 220.383804,591.280884 221.030014,591.471069 
	C221.396210,591.633728 221.384567,591.645081 221.384583,591.645081 
	C222.567139,593.857544 223.749695,596.070068 224.867432,598.692261 
z"
          />
          <path
            fill="#305CB3"
            opacity="1.000000"
            stroke="none"
            d="
M221.419708,591.635376 
	C221.384567,591.645081 221.396210,591.633728 221.416748,591.640076 
	C221.437271,591.646423 221.454865,591.625732 221.419708,591.635376 
z"
          />
          <path
            fill="#A9B5D9"
            opacity="1.000000"
            stroke="none"
            d="
M148.050323,577.080139 
	C148.884399,577.189880 149.718475,577.299622 150.771530,577.634766 
	C149.246246,578.581299 147.501984,579.302551 145.382904,580.025391 
	C144.990677,579.398254 144.973251,578.769470 145.338562,577.712524 
	C146.497635,577.216248 147.273972,577.148193 148.050323,577.080139 
z"
          />
          <path
            fill="#B3C9E8"
            opacity="1.000000"
            stroke="none"
            d="
M306.866333,425.987122 
	C306.561371,426.092224 306.126160,426.094147 306.126160,426.094147 
	C306.126160,426.094147 305.708954,425.970184 305.491486,425.960693 
	C305.312744,424.993561 305.351471,424.035950 305.738342,422.515137 
	C305.850372,418.524780 305.614349,415.097656 305.378296,411.670532 
	C305.368317,411.300720 305.358307,410.930908 305.343384,410.283752 
	C305.643677,409.726837 305.948914,409.447266 306.435913,409.062805 
	C307.521637,409.878967 308.425598,410.799988 309.618561,411.870911 
	C309.909088,415.038727 309.910583,418.056671 309.901550,421.526306 
	C309.558655,422.740051 309.226318,423.502106 308.861176,424.590088 
	C308.276031,425.238068 307.723663,425.560059 306.866333,425.987122 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M182.723557,552.037842 
	C183.010620,552.818726 182.967300,553.496216 182.881058,554.508118 
	C181.043381,555.090820 179.248611,555.339050 177.228851,555.324951 
	C178.230682,554.211731 179.457520,553.360840 180.847443,552.226318 
	C181.471405,551.939819 181.932297,551.937073 182.723557,552.037842 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M113.008652,584.081421 
	C115.349030,582.690430 117.942604,581.572754 120.762619,580.725952 
	C119.002663,582.014160 117.016273,583.031555 114.866928,584.337952 
	C114.223267,584.536255 113.742561,584.445557 113.008652,584.081421 
z"
          />
          <path
            fill="#1D57CA"
            opacity="1.000000"
            stroke="none"
            d="
M192.946625,595.384033 
	C193.745346,595.006653 194.486267,594.975342 195.596985,594.928711 
	C198.582596,594.907715 201.198395,594.902039 203.921631,594.544556 
	C205.408295,594.124390 206.787537,594.056030 208.781738,594.239746 
	C210.264236,594.653137 211.131775,594.814392 211.999313,594.975586 
	C222.195892,595.439392 222.910110,604.890137 226.884735,611.043335 
	C231.228653,617.768250 234.459778,625.216919 238.871140,631.890625 
	C240.519974,634.385071 242.256989,637.076294 240.129486,638.641968 
	C238.245209,640.028625 233.847305,640.282349 232.028320,638.981689 
	C228.639587,636.558594 225.470383,635.146118 221.304138,634.661499 
	C218.837814,634.374634 216.765472,630.741150 214.294922,630.442749 
	C206.543640,629.506653 202.457031,623.805298 197.524490,619.063660 
	C196.355347,617.939758 194.953125,617.058411 193.309723,616.029785 
	C192.961319,615.994141 192.919693,615.565857 192.898224,615.352966 
	C191.416199,612.480591 190.224945,609.622192 188.438293,607.203552 
	C184.864929,602.366272 185.280899,600.199768 190.719788,597.557617 
	C191.544220,597.157166 192.170761,596.349426 192.946625,595.384033 
z"
          />
          <path
            fill="#3965B7"
            opacity="1.000000"
            stroke="none"
            d="
M212.110718,594.718384 
	C211.131775,594.814392 210.264236,594.653137 209.195740,594.221191 
	C210.080200,593.618042 211.165604,593.285645 212.614059,592.956787 
	C212.725433,593.460632 212.473770,593.960938 212.110718,594.718384 
z"
          />
          <path
            fill="#3965B7"
            opacity="1.000000"
            stroke="none"
            d="
M214.694397,592.077271 
	C214.512619,592.373596 214.036087,592.664734 213.265778,592.962891 
	C213.447891,592.670654 213.923767,592.371399 214.694397,592.077271 
z"
          />
          <path
            fill="#6A85BD"
            opacity="1.000000"
            stroke="none"
            d="
M134.076080,577.090393 
	C136.164337,577.093323 138.252594,577.096252 140.779907,577.237000 
	C139.055984,578.221619 136.893005,579.068481 134.346283,579.513428 
	C134.000381,578.437744 134.038223,577.764099 134.076080,577.090393 
z"
          />
          <path
            fill="#3D73BB"
            opacity="1.000000"
            stroke="none"
            d="
M133.728394,577.086975 
	C134.038223,577.764099 134.000381,578.437744 133.953110,579.445801 
	C133.682953,580.000671 133.422211,580.221191 133.076996,580.682617 
	C131.873413,580.914856 130.754303,580.906250 129.242462,580.742798 
	C128.886612,580.234863 128.923492,579.881775 128.968033,579.261963 
	C130.444046,578.357910 131.912384,577.720764 133.728394,577.086975 
z"
          />
          <path
            fill="#3D73BB"
            opacity="1.000000"
            stroke="none"
            d="
M148.042206,576.743835 
	C147.273972,577.148193 146.497635,577.216248 145.349411,577.398376 
	C143.946655,577.444702 142.915771,577.377014 141.504501,577.243042 
	C141.963699,573.111145 145.205673,575.789612 147.650543,575.037354 
	C148.068848,575.514160 148.051453,575.960876 148.042206,576.743835 
z"
          />
          <path
            fill="#6D96CC"
            opacity="1.000000"
            stroke="none"
            d="
M301.752167,527.170959 
	C299.588287,528.001404 297.020203,528.744934 294.226501,529.213013 
	C296.449890,528.319763 298.898926,527.701843 301.752167,527.170959 
z"
          />
          <path
            fill="#B9C7E4"
            opacity="1.000000"
            stroke="none"
            d="
M237.833862,537.757874 
	C237.997177,538.168091 238.000381,538.358643 237.999817,538.835449 
	C237.515961,539.098328 237.035858,539.075012 236.221207,538.914612 
	C236.482346,538.364502 237.078049,537.951416 237.833862,537.757874 
z"
          />
          <path
            fill="#2D80E5"
            opacity="1.000000"
            stroke="none"
            d="
M551.948425,132.020416 
	C548.674622,126.355400 545.567749,120.584328 542.062195,115.066528 
	C540.070923,111.932213 537.329773,109.284332 535.111633,106.280472 
	C533.662964,104.318642 532.287903,102.221619 531.336548,99.990425 
	C529.456848,95.581772 531.256104,92.654541 535.892517,93.072556 
	C543.791809,93.784744 551.642639,95.034187 559.512573,96.067986 
	C559.836121,96.110489 560.209106,96.174278 560.453308,96.364159 
	C565.953308,100.640800 571.438416,104.936508 576.972351,109.959145 
	C579.137817,113.696419 581.257324,116.703148 584.293152,121.009834 
	C577.900024,121.009834 573.265259,120.836113 568.648621,121.052139 
	C562.337402,121.347458 560.962524,123.429413 563.002075,129.333572 
	C563.001953,130.110062 563.002197,130.553772 562.594727,131.051407 
	C558.774170,131.410370 555.361328,131.715393 551.948425,132.020416 
z"
          />
          <path
            fill="#2A79E1"
            opacity="1.000000"
            stroke="none"
            d="
M563.002441,130.997482 
	C563.002197,130.553772 563.001953,130.110062 563.340576,129.156662 
	C567.460022,128.387375 571.318665,127.553673 574.995605,128.054520 
	C577.806580,128.437424 579.545837,128.605179 581.116577,125.872765 
	C584.059143,120.754036 584.961670,120.821632 588.423096,125.723335 
	C590.084961,128.076645 591.668152,130.704681 595.046570,127.935181 
	C597.580811,130.107986 600.553345,131.952148 602.508057,134.559402 
	C604.024109,136.581665 602.777344,139.202103 599.923462,138.917038 
	C589.185303,137.844421 578.483276,136.409973 567.347656,134.711884 
	C565.619141,133.207626 564.310791,132.102554 563.002441,130.997482 
z"
          />
          <path
            fill="#2B7CE1"
            opacity="1.000000"
            stroke="none"
            d="
M594.999817,127.589615 
	C591.668152,130.704681 590.084961,128.076645 588.423096,125.723335 
	C584.961670,120.821632 584.059143,120.754036 581.116577,125.872765 
	C579.545837,128.605179 577.806580,128.437424 574.995605,128.054520 
	C571.318665,127.553673 567.460022,128.387375 563.340942,128.823883 
	C560.962524,123.429413 562.337402,121.347458 568.648621,121.052139 
	C573.265259,120.836113 577.900024,121.009834 584.293152,121.009834 
	C581.257324,116.703148 579.137817,113.696419 577.018250,110.320435 
	C582.996460,115.715469 588.974731,121.479759 594.999817,127.589615 
z"
          />
          <path
            fill="#2B7CE1"
            opacity="1.000000"
            stroke="none"
            d="
M562.594727,131.051407 
	C564.310791,132.102554 565.619141,133.207626 566.962524,134.655121 
	C563.778137,134.670303 560.546387,134.430450 557.344238,133.980148 
	C555.656677,133.742844 554.020813,133.138138 552.155029,132.359863 
	C555.361328,131.715393 558.774170,131.410370 562.594727,131.051407 
z"
          />
          <path
            fill="#BED4EB"
            opacity="1.000000"
            stroke="none"
            d="
M569.868164,97.552780 
	C573.180359,99.598785 576.553894,101.974792 579.942261,104.704010 
	C576.614502,102.665741 573.272034,100.274261 569.868164,97.552780 
z"
          />
          <path
            fill="#BED4EB"
            opacity="1.000000"
            stroke="none"
            d="
M579.982300,105.386429 
	C582.281982,107.066116 584.621582,109.087067 587.010986,111.427795 
	C584.714539,109.740944 582.368347,107.734322 579.982300,105.386429 
z"
          />
          <path
            fill="#3289E9"
            opacity="1.000000"
            stroke="none"
            d="
M501.338226,96.735291 
	C505.501709,105.078453 509.521729,113.103561 513.282898,121.248184 
	C514.559753,124.013046 515.661560,127.044502 515.879822,130.024689 
	C515.972168,131.286545 513.576294,133.915085 512.319031,133.910263 
	C497.897247,133.855103 483.472290,133.519638 469.063446,132.903168 
	C467.653778,132.842865 465.357758,130.557800 465.146027,129.067703 
	C463.449005,117.125450 461.480225,105.120827 464.064056,93.077576 
	C464.397217,91.524742 466.419189,89.189133 467.688843,89.169647 
	C475.460449,89.050392 483.243164,89.741104 491.017242,89.664246 
	C496.209198,89.612907 498.741852,92.715164 501.338226,96.735291 
z"
          />
          <path
            fill="#3072C9"
            opacity="1.000000"
            stroke="none"
            d="
M624.913879,130.148010 
	C621.958923,127.613457 619.000427,125.083015 616.049622,122.543617 
	C607.617188,115.286797 599.185730,108.028862 590.770752,100.751892 
	C590.570068,100.578308 590.567322,100.175713 590.304077,99.356422 
	C597.413391,99.523552 603.420532,102.354187 609.095764,105.727982 
	C621.720337,113.232994 634.371216,120.727463 646.613953,128.826950 
	C652.740784,132.880295 658.063965,138.175232 663.575745,143.104126 
	C664.454651,143.890152 664.510498,145.694931 664.690369,147.062439 
	C664.718628,147.276672 663.317810,148.027893 662.670105,147.928604 
	C655.337341,146.804550 647.578064,146.681412 640.841980,144.003525 
	C635.330444,141.812454 631.044556,136.538284 626.445068,132.324738 
	C628.802490,133.383179 630.961548,134.701782 633.046143,136.129166 
	C634.362427,137.030457 635.437988,138.662125 636.845520,138.982773 
	C641.798279,140.111130 646.834900,140.912079 651.873352,141.584900 
	C652.739441,141.700546 654.027527,140.770218 654.615906,139.957626 
	C654.909485,139.552155 654.317444,137.963913 653.692444,137.517120 
	C645.926758,131.965546 638.081299,126.525612 629.288940,120.380844 
	C627.724487,123.873489 626.319153,127.010757 624.913879,130.148010 
z"
          />
          <path
            fill="#2772DB"
            opacity="1.000000"
            stroke="none"
            d="
M624.945801,130.426575 
	C626.319153,127.010757 627.724487,123.873489 629.288940,120.380844 
	C638.081299,126.525612 645.926758,131.965546 653.692444,137.517120 
	C654.317444,137.963913 654.909485,139.552155 654.615906,139.957626 
	C654.027527,140.770218 652.739441,141.700546 651.873352,141.584900 
	C646.834900,140.912079 641.798279,140.111130 636.845520,138.982773 
	C635.437988,138.662125 634.362427,137.030457 633.046143,136.129166 
	C630.961548,134.701782 628.802490,133.383179 626.293274,132.025192 
	C625.600342,131.589600 625.289062,131.147369 624.945801,130.426575 
z"
          />
          <path
            fill="#2366CE"
            opacity="1.000000"
            stroke="none"
            d="
M448.008545,298.010925 
	C455.027374,298.008301 462.046173,298.005646 469.948303,298.058380 
	C472.782410,298.409149 474.751862,299.043884 476.680573,298.937683 
	C483.045776,298.587341 489.388458,297.764008 495.754974,297.522400 
	C500.368073,297.347351 505.017456,298.055481 509.624176,297.829773 
	C510.975708,297.763580 512.723328,296.066437 513.334229,294.692169 
	C513.640259,294.003876 511.909149,292.253204 510.882385,291.193146 
	C510.341553,290.634735 509.334167,290.528198 508.537445,290.217621 
	C508.578033,289.811707 508.618591,289.405792 508.659180,288.999878 
	C513.534973,288.999878 518.411682,288.941071 523.286194,289.020416 
	C526.564758,289.073761 528.398987,287.979065 529.194336,284.428955 
	C529.580750,282.704102 532.130859,281.557220 533.369141,279.900360 
	C534.352905,278.584045 535.563110,275.628876 535.426392,275.549561 
	C532.543335,273.876862 529.488586,271.326080 526.456299,271.311737 
	C518.433655,271.273804 510.411499,272.471893 502.374207,272.893555 
	C500.953644,272.968048 499.461090,271.669067 498.001770,271.004364 
	C504.615936,269.933990 511.230072,268.863617 517.944092,267.422241 
	C518.776245,267.027802 519.508667,267.004395 520.607239,266.988434 
	C522.681641,267.325012 524.418091,268.038788 526.093018,267.915619 
	C532.777161,267.423981 539.442200,266.671997 546.617065,266.367340 
	C548.796997,268.648926 550.147034,268.686127 551.033875,266.042114 
	C554.715454,266.353729 558.396973,266.665344 562.516968,266.982971 
	C567.339355,267.324890 571.723328,267.660797 576.545166,268.397461 
	C576.743591,272.626007 573.747498,271.497040 571.494385,272.122803 
	C569.565613,272.658478 566.519470,274.108765 566.404358,275.376068 
	C565.923645,280.662628 565.954895,286.207428 571.761597,289.269897 
	C566.898987,289.193970 562.411804,289.109589 560.549438,294.985168 
	C557.066040,294.994843 554.036682,294.997528 550.765625,294.733337 
	C549.010986,294.964935 547.498108,295.463379 545.539490,295.971283 
	C543.631409,295.991089 542.072754,295.675293 540.724854,296.073364 
	C536.767639,297.241974 532.894104,298.694244 528.987854,300.035431 
	C521.441650,300.364594 513.897095,300.922485 506.348816,300.978088 
	C487.209320,301.119049 468.068176,301.034668 448.462128,300.652222 
	C448.000580,299.512909 448.004578,298.761932 448.008545,298.010925 
z"
          />
          <path
            fill="#205CC7"
            opacity="1.000000"
            stroke="none"
            d="
M550.627319,266.021881 
	C550.147034,268.686127 548.796997,268.648926 547.043091,266.380402 
	C548.051208,266.028870 549.135986,266.015259 550.627319,266.021881 
z"
          />
          <path
            fill="#4377BC"
            opacity="1.000000"
            stroke="none"
            d="
M609.017090,261.200378 
	C605.168213,262.437714 601.371887,264.486511 597.459351,264.739563 
	C589.863770,265.230713 582.203003,264.713074 574.546387,264.047729 
	C577.386292,262.739197 580.249146,261.996368 583.111938,261.253510 
	C583.539307,261.219330 583.966614,261.185181 584.716309,261.142395 
	C588.796753,261.187592 592.554810,261.241364 596.672913,261.085205 
	C600.723877,260.603027 604.414856,260.330811 608.545532,260.329834 
	C608.995911,260.800812 609.006531,261.000580 609.017090,261.200378 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M582.666504,261.144470 
	C580.249146,261.996368 577.386292,262.739197 574.236145,263.764099 
	C572.481995,263.957520 571.015259,263.868866 569.276855,263.543457 
	C573.410461,262.549591 577.815735,261.792511 582.666504,261.144470 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M621.683228,258.478455 
	C619.531128,259.103546 617.085144,259.488434 614.196655,259.745361 
	C616.299255,259.157715 618.844299,258.697998 621.683228,258.478455 
z"
          />
          <path
            fill="#79A9D7"
            opacity="1.000000"
            stroke="none"
            d="
M609.383484,261.132233 
	C609.006531,261.000580 608.995911,260.800812 608.994507,260.301147 
	C609.847656,259.765350 610.691711,259.529449 611.738647,259.499329 
	C611.954163,259.920380 611.966736,260.135712 611.981140,260.674561 
	C611.238586,261.020081 610.494202,261.042084 609.383484,261.132233 
z"
          />
          <path
            fill="#3965B7"
            opacity="1.000000"
            stroke="none"
            d="
M551.007324,295.000183 
	C554.036682,294.997528 557.066040,294.994843 560.955811,294.991272 
	C564.205383,294.670074 566.594604,294.349792 568.992676,294.290405 
	C569.010010,294.725220 569.018494,294.899048 569.027039,295.072876 
	C563.706482,296.052246 558.418457,297.303070 553.057556,297.946198 
	C545.345215,298.871429 537.579773,299.354401 529.412476,300.030762 
	C532.894104,298.694244 536.767639,297.241974 540.724854,296.073364 
	C542.072754,295.675293 543.631409,295.991089 545.927917,295.983917 
	C548.177124,295.658142 549.592224,295.329163 551.007324,295.000183 
z"
          />
          <path
            fill="#236AD7"
            opacity="1.000000"
            stroke="none"
            d="
M447.539062,298.006958 
	C448.004578,298.761932 448.000580,299.512909 447.992920,300.640045 
	C440.517487,300.888275 433.045746,300.760376 425.260162,300.353973 
	C426.380768,299.384247 427.794525,298.152374 429.253326,298.096466 
	C435.184540,297.869171 441.129578,298.002594 447.539062,298.006958 
z"
          />
          <path
            fill="#205CC7"
            opacity="1.000000"
            stroke="none"
            d="
M568.983826,294.029480 
	C566.594604,294.349792 564.205383,294.670074 561.409790,294.984253 
	C562.411804,289.109589 566.898987,289.193970 572.093689,289.269897 
	C573.109436,289.000824 573.552307,289.000397 574.026001,289.323853 
	C574.705566,290.750580 575.354248,291.853455 576.002869,292.956360 
	C575.994873,293.398315 575.986938,293.840271 575.968384,294.612549 
	C574.260925,294.972137 572.563904,295.001373 570.328918,294.806030 
	C569.586853,294.302429 569.317810,294.118439 568.983826,294.029480 
z"
          />
          <path
            fill="#305CB3"
            opacity="1.000000"
            stroke="none"
            d="
M568.992676,294.290405 
	C569.317810,294.118439 569.586853,294.302429 569.903564,294.803528 
	C570.016174,295.025543 569.521362,295.049683 569.274170,295.061279 
	C569.018494,294.899048 569.010010,294.725220 568.992676,294.290405 
z"
          />
          <path
            fill="#236AD7"
            opacity="1.000000"
            stroke="none"
            d="
M651.500977,222.680603 
	C652.036560,223.019730 652.357422,223.569580 652.678223,224.119415 
	C652.403625,224.244186 652.129028,224.368942 651.854370,224.493698 
	C651.665039,223.959579 651.475708,223.425461 651.500977,222.680603 
z"
          />
          <path
            fill="#123CB5"
            opacity="1.000000"
            stroke="none"
            d="
M488.951294,550.598999 
	C484.314484,550.983887 479.621613,550.989746 474.462189,550.990479 
	C477.119720,547.727966 486.003174,547.271667 488.951294,550.598999 
z"
          />
          <path
            fill="#183DA3"
            opacity="1.000000"
            stroke="none"
            d="
M433.008362,551.998657 
	C432.890228,552.423096 432.763824,552.848633 432.637390,553.274170 
	C432.625122,552.928650 432.612885,552.583130 432.801483,552.110229 
	C433.002350,551.982910 433.000061,551.999756 433.008362,551.998657 
z"
          />
          <path
            fill="#1D57CA"
            opacity="1.000000"
            stroke="none"
            d="
M267.470825,557.011597 
	C267.015350,556.677795 266.899872,556.145081 267.059662,556.042969 
	C272.959381,552.272034 275.721191,543.445557 284.929352,544.984375 
	C288.801392,545.631470 290.937622,544.055298 292.247986,540.143921 
	C293.360687,536.822449 292.063843,536.039734 289.208984,535.239136 
	C290.435608,534.308899 291.846130,533.323547 293.382385,533.053772 
	C299.659241,531.951233 305.957001,530.924622 312.281219,530.148804 
	C317.180481,529.547913 322.130554,529.361328 327.898468,528.994263 
	C329.826813,528.329590 330.914764,527.661133 332.002686,526.992676 
	C334.065674,526.668457 336.128662,526.344299 338.583801,526.411804 
	C338.897247,528.863892 337.982330,531.470093 338.901855,532.878784 
	C341.329010,536.596924 349.190399,537.389038 353.596497,535.342041 
	C355.221680,534.586975 357.384155,534.418274 359.174103,534.725159 
	C361.692383,535.156982 364.279724,535.958862 366.468872,537.234863 
	C367.694824,537.949524 368.614716,539.872864 368.845795,541.378479 
	C369.009399,542.444336 367.915955,544.649536 367.163422,544.753601 
	C362.765625,545.361755 358.229370,546.129761 353.882599,545.621887 
	C349.455872,545.104553 345.451294,544.207642 341.876678,547.862244 
	C341.084961,548.671631 339.396362,549.032593 338.178802,548.927429 
	C329.724915,548.197876 321.263245,547.467834 312.851959,546.370850 
	C308.180969,545.761658 302.682434,547.593567 299.431335,552.000427 
	C302.575714,552.000427 305.385040,551.788025 308.137543,552.091370 
	C309.484314,552.239746 310.725464,553.347229 312.014435,554.020569 
	C310.556885,554.673584 308.741943,556.239319 307.699829,555.831238 
	C300.956177,553.190735 294.943054,558.128601 288.248169,557.213257 
	C281.593781,556.303467 274.717926,557.013611 267.470825,557.011597 
z"
          />
          <path
            fill="#1A55C3"
            opacity="1.000000"
            stroke="none"
            d="
M312.481567,554.018066 
	C310.725464,553.347229 309.484314,552.239746 308.137543,552.091370 
	C305.385040,551.788025 302.575714,552.000427 299.431335,552.000427 
	C302.682434,547.593567 308.180969,545.761658 312.851959,546.370850 
	C321.263245,547.467834 329.724915,548.197876 338.178802,548.927429 
	C339.396362,549.032593 341.084961,548.671631 341.876678,547.862244 
	C345.451294,544.207642 349.455872,545.104553 353.882599,545.621887 
	C358.229370,546.129761 362.765625,545.361755 367.163422,544.753601 
	C367.915955,544.649536 369.009399,542.444336 368.845795,541.378479 
	C368.614716,539.872864 367.694824,537.949524 366.468872,537.234863 
	C364.279724,535.958862 361.692383,535.156982 359.174103,534.725159 
	C357.384155,534.418274 355.221680,534.586975 353.596497,535.342041 
	C349.190399,537.389038 341.329010,536.596924 338.901855,532.878784 
	C337.982330,531.470093 338.897247,528.863892 338.976990,526.411011 
	C351.334778,525.342163 363.691528,524.665710 376.513153,523.992188 
	C381.847412,524.368835 386.716797,524.742554 391.938354,525.143311 
	C390.981049,528.548035 390.750458,531.673401 389.241364,533.952026 
	C388.175110,535.562134 385.343048,536.902893 383.357819,536.827271 
	C378.380768,536.637634 375.493622,537.909119 373.819305,543.132629 
	C372.915466,545.952454 369.430603,547.945068 367.052490,550.651855 
	C365.929901,550.994385 364.864990,550.996094 363.159058,550.729675 
	C361.011841,550.973572 359.505646,551.485535 357.999451,551.997559 
	C357.555145,551.998657 357.110809,551.999756 356.147278,551.753906 
	C354.751617,551.672791 353.875214,551.838501 352.998779,552.004150 
	C349.296967,552.339111 345.595184,552.674072 341.249146,552.758057 
	C339.737152,552.997192 338.869385,553.487305 338.001617,553.977539 
	C329.650635,553.990173 321.299652,554.002808 312.481567,554.018066 
z"
          />
          <path
            fill="#174ABD"
            opacity="1.000000"
            stroke="none"
            d="
M338.005188,554.230103 
	C338.869385,553.487305 339.737152,552.997192 340.805542,552.759583 
	C341.001038,553.449646 340.995972,553.887146 340.985840,554.652588 
	C340.239197,554.984436 339.497650,554.988525 338.385406,554.990173 
	C338.012787,554.819397 338.010803,554.651062 338.005188,554.230103 
z"
          />
          <path
            fill="#0C2CA8"
            opacity="1.000000"
            stroke="none"
            d="
M569.450806,553.985291 
	C569.536987,549.696716 572.032715,548.149170 576.081238,549.162659 
	C583.864136,551.111023 591.652527,553.104431 599.535828,554.548889 
	C602.304932,555.056213 605.359009,554.008362 608.647705,553.628601 
	C608.793457,551.894775 608.459412,550.017517 609.187256,548.749939 
	C610.025757,547.289307 611.679138,545.595215 613.194031,545.353943 
	C618.034485,544.582886 622.982544,544.512512 627.871277,544.002075 
	C629.209778,543.862366 630.486084,543.127563 631.790894,542.665894 
	C631.106934,541.467590 630.415527,540.273438 629.742859,539.068787 
	C629.502441,538.638367 629.307129,538.182739 629.046387,537.380310 
	C629.177124,537.013367 629.352783,537.004700 629.792114,536.976929 
	C630.789246,536.964355 631.522583,536.970886 632.623108,536.987427 
	C635.327881,541.065613 638.790466,540.116028 642.665771,539.016174 
	C645.439697,539.250549 647.820557,539.547485 650.592529,539.891846 
	C651.431091,540.576111 651.757202,541.602661 652.346069,541.786255 
	C656.013062,542.929504 659.823792,544.960571 663.427429,544.676331 
	C670.840088,544.091614 676.324463,549.458740 683.616028,549.958740 
	C685.452881,551.047546 686.900818,552.085266 688.630127,553.324646 
	C689.396606,559.501404 687.320496,561.820374 680.633545,562.051941 
	C678.804626,562.115356 676.997742,562.811890 675.087402,563.604004 
	C674.552124,563.991821 674.110046,563.993408 673.336548,563.991333 
	C670.113403,562.992920 667.279175,561.433289 664.316895,561.132263 
	C659.566833,560.649475 654.731995,561.000122 649.464050,560.999268 
	C644.814514,560.833923 640.633850,560.667847 636.185547,560.801147 
	C632.921936,561.074707 629.925903,561.048828 626.480713,561.000610 
	C625.212402,559.993652 624.515991,558.300171 623.555237,558.133789 
	C613.603882,556.410583 603.583435,556.315186 593.253662,557.753174 
	C591.582703,558.016785 590.208191,557.977051 588.423401,557.878906 
	C584.972107,556.547607 582.028809,554.830383 578.861084,554.133606 
	C576.007141,553.505859 572.907349,553.996399 569.450806,553.985291 
z"
          />
          <path
            fill="#183DA3"
            opacity="1.000000"
            stroke="none"
            d="
M534.568237,551.004395 
	C537.876038,551.669922 540.738037,552.440430 543.644653,552.962280 
	C545.531250,553.300903 547.482971,553.276733 549.700928,553.702698 
	C549.989868,554.181274 549.983398,554.372314 549.950195,554.850342 
	C543.248535,554.774353 536.573669,554.411438 529.446289,553.652222 
	C529.000793,552.504700 529.007690,551.753601 529.014648,551.002441 
	C530.723511,551.002930 532.432434,551.003418 534.568237,551.004395 
z"
          />
          <path
            fill="#0E35B2"
            opacity="1.000000"
            stroke="none"
            d="
M528.566040,551.001343 
	C529.007690,551.753601 529.000793,552.504700 528.992371,553.631714 
	C526.293579,554.007263 523.596252,554.006958 520.449585,553.982300 
	C520.929871,552.974609 521.703064,551.467896 522.826111,551.132446 
	C524.457581,550.645203 526.341309,551.002869 528.566040,551.001343 
z"
          />
          <path
            fill="#174ABD"
            opacity="1.000000"
            stroke="none"
            d="
M358.386505,551.999146 
	C359.505646,551.485535 361.011841,550.973572 362.759613,550.728394 
	C361.591980,551.330383 360.182800,551.665588 358.386505,551.999146 
z"
          />
          <path
            fill="#174ABD"
            opacity="1.000000"
            stroke="none"
            d="
M353.185364,552.249451 
	C353.875214,551.838501 354.751617,551.672791 355.814056,551.753540 
	C355.124023,552.164917 354.247986,552.329834 353.185364,552.249451 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M692.894836,567.423706 
	C693.504761,567.527954 694.201050,567.940063 694.924927,568.688599 
	C694.295410,568.593933 693.638306,568.162781 692.894836,567.423706 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M688.242249,566.967163 
	C688.267151,566.838379 688.546753,566.739563 688.917358,566.800293 
	C688.837891,566.972229 688.667358,566.984680 688.242249,566.967163 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M677.867615,387.245483 
	C677.451843,387.184448 677.036011,387.123413 676.124512,386.761414 
	C673.415039,385.311615 671.201355,384.162781 668.987671,383.013947 
	C668.987671,383.013947 669.000000,383.000000 668.963623,382.595734 
	C668.532837,379.083923 668.138489,375.976379 667.744141,372.868835 
	C668.014771,372.363708 668.285400,371.858582 669.132690,371.137817 
	C671.012390,370.250488 672.317810,369.583221 673.618225,368.906281 
	C678.492859,366.368622 685.681519,367.763947 689.089478,371.912354 
	C685.401184,371.305206 681.649658,369.994598 678.045166,370.318878 
	C675.283020,370.567352 672.073547,372.349548 670.290955,374.478027 
	C669.263245,375.705078 669.832886,379.930176 671.175476,381.114349 
	C673.510864,383.174164 677.000610,383.925903 680.007568,385.223389 
	C682.998169,386.513824 685.990173,387.800934 689.056519,389.426971 
	C688.350159,389.878845 687.568970,389.993256 686.194458,389.803528 
	C683.023315,388.748047 680.445435,387.996735 677.867615,387.245483 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M689.326294,371.992706 
	C685.681519,367.763947 678.492859,366.368622 673.618225,368.906281 
	C672.317810,369.583221 671.012390,370.250488 669.333984,370.932098 
	C669.961060,367.409821 672.375916,365.866333 675.951965,366.087219 
	C680.894531,366.392517 685.939270,364.921448 690.430969,369.087921 
	C694.231995,372.613800 696.879395,376.004211 695.616699,381.811279 
	C693.917297,379.191223 692.357971,376.131836 690.457520,372.973114 
	C690.057617,372.520081 689.873230,372.253174 689.326294,371.992706 
z"
          />
          <path
            fill="#C9DAF2"
            opacity="1.000000"
            stroke="none"
            d="
M441.344177,395.199524 
	C442.836945,391.019653 444.291412,387.237366 446.144104,382.419678 
	C447.346161,384.077209 448.344177,385.003662 448.788818,386.145599 
	C450.555695,390.682983 452.295380,395.244446 453.680420,399.905334 
	C453.945251,400.796600 452.633698,403.122498 451.924316,403.177643 
	C447.822510,403.496765 443.683258,403.333435 439.084930,403.333435 
	C440.075348,399.883514 440.690613,397.740326 441.344177,395.199524 
z"
          />
          <path
            fill="#C2D8ED"
            opacity="1.000000"
            stroke="none"
            d="
M585.706726,403.301758 
	C584.707581,403.578400 584.048889,403.781616 583.404785,403.742859 
	C580.139709,403.546326 576.878479,403.286957 573.615906,403.049591 
	C573.844482,400.067352 573.639099,396.967438 574.408203,394.131805 
	C575.438416,390.333374 577.223145,386.739563 579.194702,381.770416 
	C581.153320,385.127411 582.663330,387.203522 583.635437,389.506622 
	C584.956543,392.636566 586.042053,395.896606 586.858276,399.192535 
	C587.147949,400.362457 586.353455,401.800873 585.706726,403.301758 
z"
          />
          <path
            fill="#174ABD"
            opacity="1.000000"
            stroke="none"
            d="
M291.340088,587.984985 
	C291.758972,587.331543 292.530457,586.677734 293.654663,586.027588 
	C293.235809,586.682373 292.464233,587.333496 291.340088,587.984985 
z"
          />
          <path
            fill="#0E35B2"
            opacity="1.000000"
            stroke="none"
            d="
M471.023132,581.492554 
	C487.397125,581.016846 503.760345,581.006836 520.600830,580.979370 
	C521.802795,580.964478 522.527466,580.966980 523.700684,581.349792 
	C527.081604,582.487488 530.070984,583.079102 532.924683,584.064209 
	C534.645325,584.658142 537.613403,586.043640 537.524353,586.765686 
	C536.907837,591.767883 539.432068,597.711914 533.464661,601.343750 
	C529.399048,603.818176 528.483582,607.001465 532.507935,609.959290 
	C531.370361,612.248901 530.356995,614.116577 529.498474,616.052979 
	C527.805115,619.872253 528.907471,625.738220 522.080933,625.794861 
	C521.446960,625.800110 520.838562,627.607666 520.199524,628.565796 
	C519.012512,630.345398 517.662354,632.034790 516.647644,633.906738 
	C514.543945,637.787842 509.648315,639.554138 507.916504,642.552673 
	C504.734283,648.062256 500.867950,647.171082 496.558289,646.646484 
	C490.312469,645.886230 484.301361,644.709106 479.026428,650.059326 
	C477.047363,652.066650 473.816406,651.914856 472.158600,648.307739 
	C471.430847,646.724243 470.073792,645.429871 469.002441,644.004272 
	C469.002563,638.692322 468.522827,633.328247 469.093384,628.078247 
	C470.760834,612.735168 470.931763,597.354004 471.023132,581.492554 
z"
          />
          <path
            fill="#102CA8"
            opacity="1.000000"
            stroke="none"
            d="
M468.749786,644.239868 
	C470.073792,645.429871 471.430847,646.724243 472.158600,648.307739 
	C473.816406,651.914856 477.047363,652.066650 479.026428,650.059326 
	C484.301361,644.709106 490.312469,645.886230 496.558289,646.646484 
	C500.867950,647.171082 504.734283,648.062256 507.916504,642.552673 
	C509.648315,639.554138 514.543945,637.787842 516.647644,633.906738 
	C517.662354,632.034790 519.012512,630.345398 520.199524,628.565796 
	C520.838562,627.607666 521.446960,625.800110 522.080933,625.794861 
	C528.907471,625.738220 527.805115,619.872253 529.498474,616.052979 
	C530.356995,614.116577 531.370361,612.248901 532.507935,609.959290 
	C528.483582,607.001465 529.399048,603.818176 533.464661,601.343750 
	C539.432068,597.711914 536.907837,591.767883 537.524353,586.765686 
	C537.613403,586.043640 534.645325,584.658142 532.924683,584.064209 
	C530.070984,583.079102 527.081604,582.487488 524.064331,581.353882 
	C525.078125,580.658020 526.176941,580.338379 527.748047,580.231445 
	C528.411926,580.698853 528.660339,580.872803 528.965332,580.965881 
	C530.750549,581.294434 532.523376,581.739563 534.323181,581.930969 
	C541.417908,582.685608 542.212097,583.698914 540.510498,591.005127 
	C537.258484,604.968506 533.899597,618.907166 530.566162,632.851501 
	C529.953491,635.414551 529.251038,637.956238 528.320190,640.757812 
	C526.372070,643.275208 523.980652,645.322449 523.195984,647.865479 
	C522.215942,651.042114 520.823914,652.209106 517.629700,652.046509 
	C513.819641,651.852600 509.987732,651.899841 506.174469,652.060547 
	C504.828125,652.117310 503.508850,652.816223 502.078888,653.617554 
	C500.923523,654.015747 499.866455,654.018311 498.315857,653.622437 
	C492.392883,653.030762 486.963715,652.824585 481.532715,652.702576 
	C481.359100,652.698669 481.166138,653.558044 480.982178,654.015015 
	C477.630646,654.014465 474.279114,654.013855 470.471741,654.006592 
	C469.509644,650.825134 469.003387,647.650330 468.749786,644.239868 
z"
          />
          <path
            fill="#182893"
            opacity="1.000000"
            stroke="none"
            d="
M528.866333,580.755737 
	C528.660339,580.872803 528.411926,580.698853 528.114197,580.239624 
	C528.321838,580.115295 528.574890,580.285461 528.866333,580.755737 
z"
          />
          <path
            fill="#182893"
            opacity="1.000000"
            stroke="none"
            d="
M481.451660,654.020264 
	C481.166138,653.558044 481.359100,652.698669 481.532715,652.702576 
	C486.963715,652.824585 492.392883,653.030762 497.919006,653.620178 
	C492.650879,654.019348 487.285980,654.022400 481.451660,654.020264 
z"
          />
          <path
            fill="#071CA1"
            opacity="1.000000"
            stroke="none"
            d="
M692.055054,629.052002 
	C687.420715,629.532837 682.664612,629.443970 679.019958,633.444519 
	C677.805176,634.777954 675.574463,635.811462 673.772827,635.868652 
	C668.126587,636.047913 662.562744,635.770569 657.776062,639.767639 
	C656.600708,640.749084 654.408997,640.513367 652.684509,640.837219 
	C652.254700,640.425415 651.824890,640.013672 651.395081,639.601868 
	C654.198120,636.045471 656.976257,632.468994 659.816101,628.942261 
	C661.022278,627.444336 663.192810,626.205933 663.494568,624.583435 
	C665.059814,616.168884 671.592163,610.795410 675.676270,603.957031 
	C677.258484,601.307739 679.810547,597.532654 682.142822,597.365356 
	C688.392944,596.916992 694.765991,598.112549 701.082764,598.730835 
	C702.150330,598.835327 703.173340,599.346863 704.230591,599.616638 
	C705.837402,600.026611 707.973938,599.914185 708.902832,600.925903 
	C709.619812,601.706787 709.411194,604.345398 708.605042,605.340210 
	C705.326721,609.385620 701.330017,612.871460 698.238647,617.038757 
	C695.705261,620.453918 694.079041,624.541931 692.077148,628.720703 
	C692.103699,629.109741 692.000427,629.000000 692.055054,629.052002 
z"
          />
          <path
            fill="#0927AB"
            opacity="1.000000"
            stroke="none"
            d="
M580.989868,648.979248 
	C580.245789,648.980957 579.501770,648.982605 578.338257,648.613037 
	C577.140808,645.147644 575.406982,644.509705 572.429932,645.635803 
	C570.985779,646.182068 568.276978,646.436157 567.706543,645.664734 
	C566.050049,643.424255 565.185181,640.598572 564.009766,638.002441 
	C564.649353,636.569824 565.288879,635.137146 566.286926,633.310303 
	C567.093933,632.272827 567.542419,631.629578 567.990967,630.986328 
	C569.144897,627.367920 570.469543,623.793579 571.416992,620.121887 
	C573.353394,612.617798 575.172119,605.080505 576.872375,597.519348 
	C577.893066,592.980408 584.731873,586.726257 589.542114,587.086426 
	C599.705994,587.847473 609.828186,589.153442 619.975342,590.155029 
	C626.869141,590.835510 633.769836,591.473511 640.680054,591.947754 
	C644.815186,592.231506 644.586243,594.063049 642.733459,596.742065 
	C642.264465,597.420166 641.279297,598.154175 641.364929,598.740845 
	C642.547058,606.843079 634.877014,611.863464 633.776978,619.696655 
	C627.787476,621.463623 625.240356,619.568970 625.013428,613.853943 
	C624.900391,611.007446 624.739563,607.639160 620.746460,609.823242 
	C616.847046,611.956055 613.529724,615.153320 608.941650,618.659058 
	C615.453430,620.499207 619.436707,623.525696 620.684631,629.375000 
	C621.890991,635.028809 619.483398,638.004333 613.904724,636.702087 
	C611.385315,636.114014 609.864014,636.191895 608.582703,638.574402 
	C608.080139,639.508911 606.914734,640.699402 606.027527,640.719299 
	C597.762512,640.904968 591.734253,649.104248 582.870239,647.157288 
	C582.399597,647.053894 581.623718,648.340027 580.989868,648.979248 
z"
          />
          <path
            fill="#08229F"
            opacity="1.000000"
            stroke="none"
            d="
M581.453369,648.987671 
	C581.623718,648.340027 582.399597,647.053894 582.870239,647.157288 
	C591.734253,649.104248 597.762512,640.904968 606.027527,640.719299 
	C606.914734,640.699402 608.080139,639.508911 608.582703,638.574402 
	C609.864014,636.191895 611.385315,636.114014 613.904724,636.702087 
	C619.483398,638.004333 621.890991,635.028809 620.684631,629.375000 
	C619.436707,623.525696 615.453430,620.499207 608.941650,618.659058 
	C613.529724,615.153320 616.847046,611.956055 620.746460,609.823242 
	C624.739563,607.639160 624.900391,611.007446 625.013428,613.853943 
	C625.240356,619.568970 627.787476,621.463623 633.623657,620.037598 
	C633.360535,621.785461 632.730835,623.576355 632.111450,625.729919 
	C632.121704,626.092651 632.096313,626.068481 631.728088,626.096313 
	C625.867310,628.711792 624.026855,633.366577 624.033936,639.067078 
	C623.626770,639.650391 623.219604,640.233765 622.209229,640.969604 
	C618.109131,642.743408 614.584106,644.307617 611.127136,646.010010 
	C609.351624,646.884399 607.697754,648.005554 605.988892,649.015259 
	C605.988892,649.015259 605.573975,649.058838 605.366821,649.061646 
	C597.412048,649.041687 589.664490,649.018860 581.453369,648.987671 
z"
          />
          <path
            fill="#08229F"
            opacity="1.000000"
            stroke="none"
            d="
M563.743408,638.109985 
	C565.185181,640.598572 566.050049,643.424255 567.706543,645.664734 
	C568.276978,646.436157 570.985779,646.182068 572.429932,645.635803 
	C575.406982,644.509705 577.140808,645.147644 577.966309,648.608826 
	C572.956848,648.983154 567.899963,648.990356 562.418823,648.659790 
	C561.664124,647.548035 561.333679,646.773987 561.003296,646.000000 
	C561.011108,644.621155 561.018921,643.242310 561.269531,641.260254 
	C561.650269,640.077698 561.788086,639.498352 561.925903,638.918945 
	C561.925903,638.918945 561.955383,638.922791 561.932983,638.919006 
	C562.432678,638.682678 562.954834,638.450134 563.743408,638.109985 
z"
          />
          <path
            fill="#08229F"
            opacity="1.000000"
            stroke="none"
            d="
M567.671997,631.033997 
	C567.542419,631.629578 567.093933,632.272827 566.326355,632.963379 
	C566.455872,632.367737 566.904419,631.724731 567.671997,631.033997 
z"
          />
          <path
            fill="#182893"
            opacity="1.000000"
            stroke="none"
            d="
M560.770386,646.171387 
	C561.333679,646.773987 561.664124,647.548035 561.996094,648.658447 
	C561.510864,648.110840 561.024170,647.226807 560.770386,646.171387 
z"
          />
          <path
            fill="#182893"
            opacity="1.000000"
            stroke="none"
            d="
M561.693604,638.929688 
	C561.788086,639.498352 561.650269,640.077698 561.273682,640.846680 
	C561.022705,640.587402 561.010437,640.138489 561.000305,639.353516 
	C561.002441,639.017395 561.461243,638.940369 561.693604,638.929688 
z"
          />
          <path
            fill="#141F8A"
            opacity="1.000000"
            stroke="none"
            d="
M606.429199,649.042969 
	C607.697754,648.005554 609.351624,646.884399 611.127136,646.010010 
	C614.584106,644.307617 618.109131,642.743408 621.993652,641.131958 
	C621.183838,646.793518 617.000244,649.072266 606.429199,649.042969 
z"
          />
          <path
            fill="#141F8A"
            opacity="1.000000"
            stroke="none"
            d="
M624.341370,638.902100 
	C624.026855,633.366577 625.867310,628.711792 631.717285,626.091003 
	C629.599426,630.284241 627.124146,634.510681 624.341370,638.902100 
z"
          />
          <path
            fill="#123EB8"
            opacity="1.000000"
            stroke="none"
            d="
M424.546021,581.017334 
	C426.125366,590.154175 427.269043,599.283142 428.697327,608.708374 
	C428.656677,621.101440 428.293945,633.197327 428.021332,645.295288 
	C427.879059,651.610046 427.567993,651.990845 421.131500,651.996155 
	C410.988617,652.004578 400.845306,652.063416 390.703064,651.985352 
	C384.437042,651.937195 377.491821,653.718323 373.322052,646.157959 
	C373.554901,642.608215 371.337891,641.207153 368.396881,640.022217 
	C368.007416,638.933228 367.972809,637.893433 368.222900,636.203003 
	C368.003448,632.035706 367.499268,628.519043 367.369507,625.006714 
	C369.552368,627.045898 371.274353,629.166626 373.189301,631.095825 
	C376.143951,634.072571 379.230652,636.918152 382.261383,639.819397 
	C383.497650,636.817139 385.410706,633.897949 385.792389,630.790710 
	C386.231750,627.213745 386.546112,625.250732 390.946991,624.606262 
	C393.191711,624.277588 394.862579,620.303284 396.912872,618.088013 
	C397.232574,617.742554 398.397614,618.249878 398.821503,617.916931 
	C399.971283,617.013794 401.802551,615.885986 401.820923,614.820618 
	C401.908051,609.768127 404.391998,609.768311 408.423218,610.471924 
	C411.059296,610.932068 414.950989,610.776306 416.554626,609.171753 
	C419.661133,606.063416 423.943176,602.566833 420.195862,596.827698 
	C418.580261,594.353271 418.078369,591.321228 422.724854,590.768127 
	C423.533661,590.671814 424.825165,589.199219 424.758057,588.466309 
	C424.660797,587.403259 423.652222,586.315674 422.812805,585.451965 
	C421.293213,583.888367 419.611572,582.482300 417.997589,581.010437 
	C420.035156,581.010071 422.072723,581.009705 424.546021,581.017334 
z"
          />
          <path
            fill="#1446BD"
            opacity="1.000000"
            stroke="none"
            d="
M417.553833,581.002441 
	C419.611572,582.482300 421.293213,583.888367 422.812805,585.451965 
	C423.652222,586.315674 424.660797,587.403259 424.758057,588.466309 
	C424.825165,589.199219 423.533661,590.671814 422.724854,590.768127 
	C418.078369,591.321228 418.580261,594.353271 420.195862,596.827698 
	C423.943176,602.566833 419.661133,606.063416 416.554626,609.171753 
	C414.950989,610.776306 411.059296,610.932068 408.423218,610.471924 
	C404.391998,609.768311 401.908051,609.768127 401.820923,614.820618 
	C401.802551,615.885986 399.971283,617.013794 398.821503,617.916931 
	C398.397614,618.249878 397.232574,617.742554 396.912872,618.088013 
	C394.862579,620.303284 393.191711,624.277588 390.946991,624.606262 
	C386.546112,625.250732 386.231750,627.213745 385.792389,630.790710 
	C385.410706,633.897949 383.497650,636.817139 382.261383,639.819397 
	C379.230652,636.918152 376.143951,634.072571 373.189301,631.095825 
	C371.274353,629.166626 369.552368,627.045898 367.371948,625.005493 
	C367.000000,625.000000 366.995209,625.004883 367.123352,624.694580 
	C365.837006,621.253723 364.422516,618.123169 363.004395,614.581848 
	C364.044403,608.344299 365.201996,602.533752 366.031067,596.676697 
	C366.187805,595.569336 365.174774,593.328369 364.470428,593.227722 
	C360.554169,592.668091 360.225739,589.512512 360.321350,586.974121 
	C360.365692,585.796021 363.160614,584.725464 364.702759,583.598206 
	C365.485077,583.026428 366.254456,582.437073 367.029663,581.855652 
	C367.441132,581.866577 367.852570,581.877441 368.570618,581.880310 
	C371.395569,581.772400 373.913879,581.672546 376.716309,581.290344 
	C378.388947,581.009888 379.777435,581.011719 381.640808,581.409912 
	C383.268707,582.533936 384.403534,583.855042 385.577728,583.890991 
	C392.206573,584.094055 398.853821,584.172119 405.473511,583.852417 
	C407.025452,583.777466 408.495209,582.000977 410.002808,581.007324 
	C412.371857,581.003052 414.740967,580.998779 417.553833,581.002441 
z"
          />
          <path
            fill="#244DAE"
            opacity="1.000000"
            stroke="none"
            d="
M366.757690,581.626099 
	C366.254456,582.437073 365.485077,583.026428 364.702759,583.598206 
	C363.160614,584.725464 360.365692,585.796021 360.321350,586.974121 
	C360.225739,589.512512 360.554169,592.668091 364.470428,593.227722 
	C365.174774,593.328369 366.187805,595.569336 366.031067,596.676697 
	C365.201996,602.533752 364.044403,608.344299 363.000671,614.911255 
	C362.997101,616.090027 362.993652,616.528564 362.990173,616.967102 
	C362.796326,616.972046 362.602478,616.977051 362.116150,616.987671 
	C359.551361,607.041321 357.196869,597.106995 355.051880,587.127686 
	C354.314209,583.695740 355.729523,581.737061 359.611694,581.899170 
	C361.892059,581.994385 364.193634,581.581116 366.757690,581.626099 
z"
          />
          <path
            fill="#123EB8"
            opacity="1.000000"
            stroke="none"
            d="
M409.533875,581.002258 
	C408.495209,582.000977 407.025452,583.777466 405.473511,583.852417 
	C398.853821,584.172119 392.206573,584.094055 385.577728,583.890991 
	C384.403534,583.855042 383.268707,582.533936 382.057251,581.418091 
	C391.020874,581.018982 400.042938,581.008118 409.533875,581.002258 
z"
          />
          <path
            fill="#183DA3"
            opacity="1.000000"
            stroke="none"
            d="
M366.997559,625.001221 
	C367.499268,628.519043 368.003448,632.035706 368.221985,635.812378 
	C366.619690,632.660156 365.303009,629.247864 363.967621,625.442627 
	C363.948914,625.049622 363.990021,625.009583 364.380341,624.998108 
	C365.512177,624.992676 366.253693,624.998779 366.995209,625.004883 
	C366.995209,625.004883 367.000000,625.000000 366.997559,625.001221 
z"
          />
          <path
            fill="#123EB8"
            opacity="1.000000"
            stroke="none"
            d="
M367.123352,624.694580 
	C366.253693,624.998779 365.512177,624.992676 364.400696,624.977905 
	C363.874695,622.490601 363.718658,620.012024 363.276428,617.250244 
	C362.993652,616.528564 362.997101,616.090027 363.004333,615.322083 
	C364.422516,618.123169 365.837006,621.253723 367.123352,624.694580 
z"
          />
          <path
            fill="#0E35B2"
            opacity="1.000000"
            stroke="none"
            d="
M553.006958,526.415710 
	C554.383301,526.022827 555.775757,526.014526 557.585815,526.013000 
	C557.381165,526.993958 556.758972,527.968262 556.136719,528.942505 
	C555.098816,528.228455 554.060913,527.514404 553.006958,526.415710 
z"
          />
          <path
            fill="#1D57CA"
            opacity="1.000000"
            stroke="none"
            d="
M286.767456,534.694336 
	C284.520203,535.484863 282.007568,536.011108 279.237610,536.262329 
	C281.487518,535.468262 283.994781,534.949219 286.767456,534.694336 
z"
          />
          <path
            fill="#3D73BB"
            opacity="1.000000"
            stroke="none"
            d="
M204.205368,549.245972 
	C205.430511,548.335510 206.873535,547.688660 208.674774,547.069702 
	C209.019867,547.519104 209.006760,547.940613 208.990326,548.679077 
	C207.465744,549.167175 205.944504,549.338440 204.205368,549.245972 
z"
          />
          <path
            fill="#1A55C3"
            opacity="1.000000"
            stroke="none"
            d="
M331.627075,526.993713 
	C330.914764,527.661133 329.826813,528.329590 328.361511,528.996582 
	C329.073242,528.328369 330.162384,527.661560 331.627075,526.993713 
z"
          />
          <path
            fill="#123CB5"
            opacity="1.000000"
            stroke="none"
            d="
M359.762085,688.974915 
	C361.124420,697.840759 367.811737,703.444397 372.737946,710.684570 
	C365.453888,713.237488 358.505890,715.380554 351.386749,710.406677 
	C350.117889,709.520142 348.269806,709.462708 346.287292,709.025757 
	C341.667603,706.249512 337.221008,703.758240 333.279541,700.630493 
	C325.012604,694.070251 314.416840,689.907715 310.212982,677.943359 
	C311.858643,677.643433 313.619690,676.967529 315.334564,677.069397 
	C324.251099,677.598999 333.155884,678.326660 342.619019,679.380981 
	C347.466003,680.847168 351.758728,681.917725 356.051453,682.988281 
	C356.358368,683.096008 356.606171,683.282288 356.896118,684.161133 
	C357.712494,686.167786 358.427704,687.560486 359.142914,688.953186 
	C359.142914,688.953186 359.556091,688.958740 359.762085,688.974915 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M355.992249,682.617676 
	C351.758728,681.917725 347.466003,680.847168 343.078430,679.390747 
	C347.390106,679.726013 352.692871,676.867676 355.992249,682.617676 
z"
          />
          <path
            fill="#183199"
            opacity="1.000000"
            stroke="none"
            d="
M359.116272,688.587036 
	C358.427704,687.560486 357.712494,686.167786 356.999268,684.372864 
	C357.697357,685.387390 358.393494,686.804077 359.116272,688.587036 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M667.412842,372.975372 
	C668.138489,375.976379 668.532837,379.083923 668.956787,382.601990 
	C664.522339,380.431763 664.017761,377.801178 667.412842,372.975372 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M677.981873,387.560425 
	C680.445435,387.996735 683.023315,388.748047 685.793640,389.798889 
	C683.356140,389.357452 680.726135,388.616455 677.981873,387.560425 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M669.015442,383.355286 
	C671.201355,384.162781 673.415039,385.311615 675.809570,386.743591 
	C672.226013,388.938965 671.016357,385.521423 669.015442,383.355286 
z"
          />
          <path
            fill="#1F5FCE"
            opacity="1.000000"
            stroke="none"
            d="
M716.746948,202.733826 
	C718.978088,207.004181 720.942444,211.049438 723.385071,216.079636 
	C723.140076,221.108429 718.850220,221.606964 713.401001,221.031631 
	C710.772644,221.626236 708.329834,221.751129 706.478577,222.861969 
	C701.172241,226.045914 695.504883,225.046371 689.938782,224.659866 
	C688.778625,224.579315 687.362549,222.754456 686.732178,221.418350 
	C684.071411,215.779541 682.040466,209.816406 679.071838,204.356491 
	C674.911926,196.705627 670.036804,189.446487 665.680969,181.896683 
	C664.885132,180.517365 665.108398,178.550156 664.861206,176.854309 
	C667.055908,177.217300 669.250610,177.580307 672.138367,178.057938 
	C674.610962,175.948151 678.995850,176.450333 682.218323,179.077332 
	C683.985413,180.517914 686.739624,180.906876 689.125977,181.380112 
	C691.167969,181.785049 693.686157,180.950653 695.360718,181.847000 
	C702.861328,185.861938 709.820312,190.690872 714.550720,198.028854 
	C715.419617,199.376617 715.848328,201.008163 716.746948,202.733826 
z"
          />
          <path
            fill="#1F5FCE"
            opacity="1.000000"
            stroke="none"
            d="
M573.995117,289.000000 
	C573.552307,289.000397 573.109436,289.000824 572.334534,289.001221 
	C565.954895,286.207428 565.923645,280.662628 566.404358,275.376068 
	C566.519470,274.108765 569.565613,272.658478 571.494385,272.122803 
	C573.747498,271.497040 576.743591,272.626007 576.992676,268.397888 
	C591.852173,268.268066 606.482056,266.106506 621.167053,264.326691 
	C624.529785,263.919128 628.189331,265.617310 631.639832,266.600494 
	C632.244202,266.772736 632.863708,268.247314 632.783630,269.051422 
	C632.715576,269.734436 631.707214,270.778198 631.013062,270.867126 
	C627.699341,271.291595 626.043457,272.253265 626.585876,276.303955 
	C626.807800,277.961212 624.367126,280.655212 622.482910,281.738007 
	C620.244263,283.024414 619.442566,280.870758 618.595825,278.782288 
	C617.432129,275.912109 612.547241,275.770660 608.981384,278.049469 
	C603.236267,281.720917 597.462097,285.346954 591.334961,289.006470 
	C590.539124,289.021515 590.108337,289.022308 588.906860,289.014374 
	C585.759094,289.004608 583.381958,289.003540 580.566711,289.001770 
	C578.084045,289.000732 576.039612,289.000366 573.995117,289.000000 
z"
          />
          <path
            fill="#205CC7"
            opacity="1.000000"
            stroke="none"
            d="
M581.004883,289.002441 
	C583.381958,289.003540 585.759094,289.004608 588.583496,289.013794 
	C586.628845,290.012238 584.226807,291.002594 581.411133,291.616150 
	C580.999939,290.493744 581.002380,289.748077 581.004883,289.002441 
z"
          />
          <path
            fill="#2366CE"
            opacity="1.000000"
            stroke="none"
            d="
M580.566711,289.001770 
	C581.002380,289.748077 580.999939,290.493744 580.995056,291.611816 
	C579.572266,292.288361 578.151855,292.592468 576.367188,292.926453 
	C575.354248,291.853455 574.705566,290.750580 574.026001,289.323853 
	C576.039612,289.000366 578.084045,289.000732 580.566711,289.001770 
z"
          />
          <path
            fill="#141F8A"
            opacity="1.000000"
            stroke="none"
            d="
M521.011230,709.393250 
	C521.486511,709.036621 521.906921,709.005676 522.669800,709.369995 
	C525.043701,712.457031 532.008423,713.773682 536.451660,712.732544 
	C548.556580,709.896240 557.204773,700.841125 567.886719,695.446350 
	C571.171448,693.787476 573.974121,691.173950 576.997559,688.997559 
	C576.997559,688.997559 577.022888,689.022461 577.036865,689.035950 
	C576.174561,695.208557 570.526672,696.292419 566.420410,698.994934 
	C565.223389,699.782715 563.529541,700.039246 562.649048,701.049622 
	C554.403992,710.510681 543.627075,714.166199 531.541870,715.054321 
	C527.647034,715.340454 523.770447,715.875732 519.284668,716.362183 
	C519.969238,713.641785 520.462830,711.680359 521.011230,709.393250 
z"
          />
          <path
            fill="#071CA1"
            opacity="1.000000"
            stroke="none"
            d="
M577.001343,688.600342 
	C573.974121,691.173950 571.171448,693.787476 567.886719,695.446350 
	C557.204773,700.841125 548.556580,709.896240 536.451660,712.732544 
	C532.008423,713.773682 525.043701,712.457031 522.986572,709.363037 
	C523.072327,708.651550 523.254822,708.392456 524.063904,708.049194 
	C525.723999,706.274048 526.828552,704.633118 527.933105,702.992249 
	C528.744141,701.752869 529.555115,700.513550 530.652832,699.117188 
	C535.131775,696.949158 539.522766,695.246338 543.400391,692.747131 
	C544.633606,691.952332 544.917175,689.035217 544.902039,687.094971 
	C544.874817,683.619202 545.858337,681.944885 549.701660,681.912170 
	C556.639832,681.853027 563.574707,681.336060 570.509705,680.985352 
	C574.014465,680.808167 577.620361,681.810669 580.678894,678.560791 
	C581.635071,677.544739 584.582153,678.402405 586.999634,678.402405 
	C586.260925,681.949219 585.022156,683.893616 581.500793,684.190918 
	C579.372803,684.370544 576.607483,684.766907 577.001343,688.600342 
z"
          />
          <path
            fill="#141F8A"
            opacity="1.000000"
            stroke="none"
            d="
M527.642700,703.142334 
	C526.828552,704.633118 525.723999,706.274048 524.271118,707.928040 
	C525.066040,706.391602 526.209167,704.842041 527.642700,703.142334 
z"
          />
          <path
            fill="#5580BE"
            opacity="1.000000"
            stroke="none"
            d="
M131.296921,383.078247 
	C133.084564,388.537659 134.898117,393.629913 136.118683,398.860565 
	C136.513870,400.554169 135.590164,404.137665 134.866913,404.249451 
	C130.655670,404.900177 126.173843,405.433563 122.126083,404.508057 
	C120.919518,404.232178 120.012039,399.018341 120.401550,396.270264 
	C120.941925,392.457764 122.520317,388.680481 124.255226,385.186493 
	C125.522095,382.635132 127.430611,379.605743 131.296921,383.078247 
M121.667305,401.454407 
	C124.181114,401.962769 126.683578,402.821228 129.211197,402.903107 
	C133.775009,403.050995 134.835556,401.577820 133.468964,397.111664 
	C132.245529,393.113403 130.637619,389.232819 128.976624,384.691345 
	C123.462631,388.974060 124.452637,395.754639 121.667305,401.454407 
z"
          />
          <path
            fill="#A8C3E6"
            opacity="1.000000"
            stroke="none"
            d="
M239.874908,419.619568 
	C239.535736,418.087280 239.272263,416.121521 239.333115,414.040588 
	C239.755173,415.678986 239.852890,417.432556 239.874908,419.619568 
z"
          />
          <path
            fill="#2D85E8"
            opacity="1.000000"
            stroke="none"
            d="
M426.576202,187.998291 
	C427.001068,193.352676 427.002136,198.707794 427.000977,204.530243 
	C426.332214,206.454285 425.127167,207.897202 425.089447,209.370026 
	C424.894714,216.970352 424.999084,224.578339 424.999084,232.886902 
	C415.636230,233.953857 406.701782,235.145233 397.732574,235.947144 
	C388.039032,236.813812 378.315002,237.349960 368.598663,237.937531 
	C367.327179,238.014389 366.022461,237.540619 364.367188,237.162308 
	C363.389740,235.378860 363.068268,233.555481 362.113434,232.167923 
	C359.013031,227.662323 359.160889,225.709991 363.657043,221.245255 
	C364.710114,222.078323 365.795319,222.936813 367.180908,224.032944 
	C367.848450,223.094879 368.849030,222.338440 368.826874,221.613235 
	C368.694702,217.287048 371.282593,216.701370 374.693298,216.987213 
	C375.685547,217.070374 376.698700,216.917267 377.688599,217.013382 
	C383.098602,217.538605 385.236481,214.596695 385.284973,209.239243 
	C390.330170,209.283646 393.622681,207.396118 392.987640,201.522949 
	C397.893036,201.543961 400.397614,199.216278 401.154846,194.578964 
	C401.322021,193.555145 403.401672,192.343628 404.749359,192.121872 
	C407.182129,191.721558 409.728455,192.051224 412.224457,191.977692 
	C413.366882,191.944031 415.344177,191.965164 415.513062,191.471939 
	C417.571747,185.458862 422.299652,188.128204 426.576202,187.998291 
z"
          />
          <path
            fill="#2D85E8"
            opacity="1.000000"
            stroke="none"
            d="
M425.743988,169.210831 
	C426.341766,173.683701 426.670563,178.379364 426.994812,183.541138 
	C425.676971,184.049118 424.268585,183.801300 423.070679,184.194565 
	C420.741791,184.959106 418.394623,186.646790 417.148132,182.894455 
	C415.909302,179.165268 417.754333,175.532516 420.915710,173.901978 
	C422.722931,172.969879 423.973694,170.958847 425.743988,169.210831 
z"
          />
          <path
            fill="#2D85E8"
            opacity="1.000000"
            stroke="none"
            d="
M359.951477,236.660995 
	C357.094055,236.799271 353.091980,237.949570 354.727936,232.245621 
	C356.633118,233.442856 358.266266,234.885071 359.951477,236.660995 
z"
          />
          <path
            fill="#2A79E1"
            opacity="1.000000"
            stroke="none"
            d="
M469.999512,184.222748 
	C469.851746,179.217133 470.049500,174.625168 469.425140,170.147858 
	C469.004517,167.131363 469.904846,166.069351 472.617676,166.014618 
	C475.777588,165.950867 478.943787,166.095383 482.099792,165.966507 
	C488.384277,165.709885 494.664978,165.040756 500.944824,165.069641 
	C509.058411,165.106934 517.171570,165.620911 525.280701,166.014359 
	C527.987671,166.145691 531.275452,171.617386 532.080139,175.679871 
	C533.746094,184.090927 535.929504,192.398285 537.704346,200.789703 
	C538.239380,203.319519 538.187744,205.983612 538.289307,208.590698 
	C538.340515,209.906204 537.572327,211.570663 538.132812,212.498581 
	C542.180664,219.199997 538.724731,226.439270 539.857239,233.326950 
	C540.410645,236.692673 537.970581,237.037079 535.403687,237.032272 
	C524.422913,237.011658 513.440308,236.917786 502.462982,237.112869 
	C500.386627,237.149765 498.331421,238.379288 496.266510,239.058624 
	C495.514191,239.306122 494.699341,239.879700 494.019653,239.749146 
	C489.216309,238.826553 484.409210,237.885391 479.665863,236.699936 
	C475.633453,235.692169 471.124542,227.706741 471.008209,221.496796 
	C470.896057,215.509399 471.114868,209.514679 470.951111,203.529678 
	C470.779114,197.244171 470.325592,190.966385 469.999512,184.222748 
z"
          />
          <path
            fill="#3696F3"
            opacity="1.000000"
            stroke="none"
            d="
M329.983643,176.549911 
	C329.412262,178.120911 328.844574,179.254318 328.142822,180.686844 
	C322.920532,183.540756 322.366241,188.322815 322.018066,193.628693 
	C322.026184,195.749924 322.024261,197.443649 321.641235,199.616058 
	C320.170135,203.390533 319.080200,206.686310 317.990234,209.982086 
	C317.317810,211.413788 316.645355,212.845490 315.673523,214.670456 
	C315.242584,215.705109 315.110992,216.346481 314.979431,216.987869 
	C314.977173,217.434036 314.974915,217.880203 314.589050,218.754501 
	C312.556641,223.973358 313.029175,229.714005 307.638550,233.000778 
	C306.499268,233.011581 305.739807,233.020798 304.609619,233.022385 
	C303.159607,233.661835 302.080261,234.308914 300.771729,235.080215 
	C300.334869,235.480377 300.127258,235.756287 299.919617,236.032211 
	C296.793427,235.904694 293.667236,235.777161 290.172913,234.981201 
	C288.998535,233.882751 288.158691,233.027206 287.391144,233.087799 
	C278.636749,233.778671 270.557190,231.224747 262.455109,228.408752 
	C260.265930,227.647873 257.486481,228.598221 255.286713,227.852478 
	C250.397095,226.194794 245.702728,223.961136 240.928772,221.962265 
	C240.956146,221.227005 240.983521,220.491730 241.037750,219.389984 
	C246.036728,217.195374 247.186035,213.063858 247.202393,208.187195 
	C247.199814,207.169907 247.456757,206.371521 248.186981,205.235077 
	C253.535324,198.371277 260.170959,192.880035 260.965149,183.956314 
	C260.965149,183.956314 260.968872,183.476532 261.323486,183.181671 
	C262.438141,181.241104 263.198181,179.595413 263.958252,177.949722 
	C264.219604,177.161240 264.480988,176.372757 265.213074,175.258575 
	C266.791656,173.621384 267.899506,172.309875 269.007324,170.998352 
	C281.609344,169.738800 294.203888,168.397232 306.816406,167.253387 
	C313.388733,166.657333 319.989960,166.347427 326.584778,166.060776 
	C327.811615,166.007431 329.070068,166.680496 330.406250,167.043381 
	C330.257385,170.266006 330.122375,173.189163 329.983643,176.549911 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M260.611145,184.007935 
	C260.170959,192.880035 253.535324,198.371277 248.316681,204.944031 
	C248.648499,202.910568 249.323883,200.830139 250.031158,198.378784 
	C252.706833,194.152817 255.324738,190.279587 258.013672,186.456299 
	C258.637085,185.569901 259.502869,184.853943 260.611145,184.007935 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M268.662170,171.028717 
	C267.899506,172.309875 266.791656,173.621384 265.340759,174.969803 
	C266.104126,173.690826 267.210541,172.374954 268.662170,171.028717 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M263.618652,178.019302 
	C263.198181,179.595413 262.438141,181.241104 261.332397,182.941925 
	C261.750824,181.360977 262.514954,179.724930 263.618652,178.019302 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M240.653748,222.245636 
	C245.702728,223.961136 250.397095,226.194794 255.286713,227.852478 
	C257.486481,228.598221 260.265930,227.647873 262.455109,228.408752 
	C270.557190,231.224747 278.636749,233.778671 287.391144,233.087799 
	C288.158691,233.027206 288.998535,233.882751 289.852448,234.725494 
	C278.364685,235.860382 267.205261,233.387863 255.986496,231.276230 
	C252.596802,230.638214 249.084335,230.524475 245.621216,230.433762 
	C239.972748,230.285797 238.995377,227.238525 240.653748,222.245636 
z"
          />
          <path
            fill="#3F87D4"
            opacity="1.000000"
            stroke="none"
            d="
M304.980316,233.030014 
	C305.739807,233.020798 306.499268,233.011581 307.883606,233.269928 
	C310.675537,232.681702 312.842651,231.825897 315.009766,230.970093 
	C315.374237,235.434784 313.276794,237.373001 308.866211,237.045197 
	C306.096344,236.839310 303.318481,236.741028 300.231903,236.313354 
	C300.127258,235.756287 300.334869,235.480377 301.144165,235.091019 
	C302.823975,234.328384 303.902161,233.679199 304.980316,233.030014 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M315.020996,230.591629 
	C312.842651,231.825897 310.675537,232.681702 308.263367,233.268372 
	C313.029175,229.714005 312.556641,223.973358 314.583374,219.089081 
	C314.981598,221.423111 315.001862,223.850693 315.058472,226.745056 
	C315.073944,228.212265 315.053070,229.212723 315.020996,230.591629 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M318.341003,209.987091 
	C319.080200,206.686310 320.170135,203.390533 321.631256,200.040909 
	C321.540894,203.132019 321.079376,206.277008 320.357910,209.704193 
	C319.629211,209.988297 319.160461,209.990204 318.341003,209.987091 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M315.223938,216.820831 
	C315.110992,216.346481 315.242584,215.705109 315.655609,215.026230 
	C315.780853,215.543762 315.624664,216.098785 315.223938,216.820831 
z"
          />
          <path
            fill="#2D85E8"
            opacity="1.000000"
            stroke="none"
            d="
M320.537048,265.055481 
	C322.149323,266.049072 323.243805,267.741211 324.491760,267.863190 
	C328.287933,268.234253 332.143402,267.997284 335.975769,268.000458 
	C339.877838,268.003693 343.192657,268.076874 346.661255,271.384460 
	C350.585724,275.126831 356.314972,276.976593 361.393616,279.721863 
	C361.430206,279.838013 361.671204,280.182343 361.600220,280.441101 
	C360.664856,283.851379 364.221222,289.507538 357.210205,289.892120 
	C353.196167,290.112305 349.149048,289.679169 345.115631,289.608246 
	C339.222809,289.504669 333.178467,286.856934 327.546265,291.351868 
	C326.284698,292.358734 323.524139,291.487305 321.215088,291.751648 
	C309.424377,290.674194 297.840485,289.543579 286.336700,287.864105 
	C282.060120,287.239746 277.971161,285.330048 273.485840,283.614594 
	C275.853210,283.226257 278.531525,283.248840 281.209412,283.223663 
	C282.227051,283.214081 283.243683,283.094696 284.260773,283.025330 
	C284.091614,282.167725 284.221497,280.998749 283.705048,280.502838 
	C280.242401,277.177917 281.952209,275.480255 285.507446,274.315430 
	C289.976562,272.851227 290.706329,270.100372 287.605438,266.339386 
	C287.008514,265.615387 286.529297,264.794373 285.995758,264.018097 
	C292.075714,264.631439 298.145050,265.555695 304.238861,265.763763 
	C309.507935,265.943695 314.804810,265.310059 320.537048,265.055481 
z"
          />
          <path
            fill="#318BED"
            opacity="1.000000"
            stroke="none"
            d="
M285.662750,264.013672 
	C286.529297,264.794373 287.008514,265.615387 287.605438,266.339386 
	C290.706329,270.100372 289.976562,272.851227 285.507446,274.315430 
	C281.952209,275.480255 280.242401,277.177917 283.705048,280.502838 
	C284.221497,280.998749 284.091614,282.167725 284.260773,283.025330 
	C283.243683,283.094696 282.227051,283.214081 281.209412,283.223663 
	C278.531525,283.248840 275.853210,283.226257 273.085449,283.599670 
	C263.741791,282.652832 254.484406,281.351501 245.236023,279.989227 
	C242.971466,279.655701 240.587494,279.481018 238.513260,278.619629 
	C229.206207,274.754578 219.185715,274.415436 209.252884,272.716431 
	C208.567062,272.973236 208.126968,272.964264 207.358856,272.919861 
	C204.224228,271.261536 201.445084,269.588104 198.596832,268.041962 
	C197.530273,267.462982 196.302795,267.180481 195.056946,266.395874 
	C200.811050,266.353210 206.668335,267.049438 212.498795,266.870880 
	C216.386246,266.751831 220.235504,265.386078 224.999680,264.390656 
	C224.999680,262.389099 224.999680,260.147369 224.998627,257.461243 
	C228.484573,257.200378 231.971588,257.383942 235.727142,257.286041 
	C236.746979,257.007141 237.498291,257.009644 238.624481,257.039612 
	C240.863693,258.372498 242.619141,260.576355 244.612396,260.817902 
	C251.066055,261.599976 257.607025,261.661713 264.633820,262.370056 
	C269.440826,263.157806 273.728333,263.576447 278.015869,263.995117 
	C278.760986,263.995880 279.506134,263.996613 280.810669,264.246277 
	C282.245911,264.337036 283.121765,264.178894 283.997589,264.020752 
	C284.441650,264.016937 284.885681,264.013092 285.662750,264.013672 
z"
          />
          <path
            fill="#39A0F6"
            opacity="1.000000"
            stroke="none"
            d="
M240.974106,137.949722 
	C240.974106,137.949722 240.982239,137.480286 241.383804,137.243103 
	C246.881500,134.258118 252.117889,131.733185 257.006958,128.656387 
	C259.056091,127.366821 260.369141,124.907585 262.016846,122.980133 
	C271.223145,117.614624 280.389801,112.178932 289.667603,106.940010 
	C291.635681,105.828674 294.009277,105.435440 296.196045,104.711349 
	C296.485291,105.241699 296.774536,105.772041 297.063782,106.302391 
	C293.340271,109.559456 289.616730,112.816513 285.139587,116.084869 
	C283.596588,117.411926 282.807220,118.727699 282.017822,120.043488 
	C281.216522,120.947517 280.415222,121.851540 278.960236,122.885147 
	C271.947632,124.625999 268.880005,129.288651 267.078674,135.125305 
	C265.958740,136.732712 264.838776,138.340134 263.152222,139.736542 
	C256.862030,140.341293 251.154510,141.314636 245.407440,141.899323 
	C242.925232,142.151840 240.835938,141.077240 240.974106,137.949722 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M240.666290,138.092697 
	C240.835938,141.077240 242.925232,142.151840 245.407440,141.899323 
	C251.154510,141.314636 256.862030,140.341293 262.811920,139.784973 
	C260.054901,145.697845 254.181503,143.507095 249.653931,144.769806 
	C244.300598,146.262817 238.689590,146.838638 233.181793,147.761307 
	C232.599167,147.858917 231.959518,147.616058 229.556824,147.283371 
	C233.830490,143.703659 237.094498,140.969666 240.666290,138.092697 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M261.660156,123.010559 
	C260.369141,124.907585 259.056091,127.366821 257.006958,128.656387 
	C252.117889,131.733185 246.881500,134.258118 241.399963,137.008911 
	C247.777527,132.354904 254.540497,127.697937 261.660156,123.010559 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M267.415649,135.056641 
	C268.880005,129.288651 271.947632,124.625999 278.687378,123.022537 
	C275.296387,127.016228 271.524506,131.002106 267.415649,135.056641 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M282.312866,119.895599 
	C282.807220,118.727699 283.596588,117.411926 284.758301,116.086380 
	C284.289734,117.300316 283.448822,118.524017 282.312866,119.895599 
z"
          />
          <path
            fill="#389EF7"
            opacity="1.000000"
            stroke="none"
            d="
M326.337585,136.995865 
	C318.911682,134.846436 312.014526,136.129120 305.064240,138.590485 
	C302.592621,138.670303 300.220551,138.368134 297.454163,138.074097 
	C296.841156,136.770370 295.953552,134.885300 296.509125,134.237030 
	C301.088379,128.893723 305.935364,123.779900 310.842316,118.294876 
	C311.303009,117.881828 311.563507,117.690125 312.233337,117.167358 
	C313.805939,115.280380 314.910095,113.644150 316.014221,112.007927 
	C316.120422,111.690971 316.307220,111.432251 316.791016,111.122574 
	C321.116669,110.563248 324.546387,109.432320 326.691376,105.206390 
	C327.657440,103.303177 330.541992,102.429306 332.404877,100.903763 
	C333.430145,100.064178 334.141754,98.841568 335.009552,97.413284 
	C335.459290,97.022316 335.894257,97.009720 336.655457,96.990311 
	C346.198334,95.504417 355.420441,93.826912 365.387421,97.861160 
	C363.521118,100.544098 361.731262,102.820122 360.278625,105.294159 
	C358.990021,107.488914 358.075897,109.903564 356.997009,112.609314 
	C356.997559,112.997475 357.000000,113.000000 356.650391,113.053535 
	C354.767212,114.701653 353.462585,117.266922 351.653473,117.693687 
	C347.580597,118.654457 346.941956,121.029266 346.945801,124.528458 
	C346.950165,128.480484 346.044861,132.326614 341.625580,133.542725 
	C338.466827,134.411942 335.076996,134.406311 331.845978,135.064590 
	C330.484467,135.341980 329.266388,136.323395 327.983093,136.984772 
	C327.544281,136.988647 327.105438,136.992554 326.337585,136.995865 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M315.693207,112.143600 
	C314.910095,113.644150 313.805939,115.280380 312.347229,116.949959 
	C313.119202,115.415298 314.245697,113.847290 315.693207,112.143600 
z"
          />
          <path
            fill="#5DA4DD"
            opacity="1.000000"
            stroke="none"
            d="
M326.642944,99.988739 
	C326.150665,100.935310 325.322754,101.916946 324.128479,102.872185 
	C324.610535,101.905128 325.458893,100.964478 326.642944,99.988739 
z"
          />
          <path
            fill="#5DA4DD"
            opacity="1.000000"
            stroke="none"
            d="
M322.668701,103.013336 
	C322.524323,103.624130 322.059937,104.266312 321.265747,104.926567 
	C321.406860,104.311333 321.877747,103.678024 322.668701,103.013336 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M182.161194,251.183640 
	C182.865799,250.780823 183.748779,250.639053 184.816498,250.740723 
	C184.114014,251.137665 183.226807,251.291168 182.161194,251.183640 
z"
          />
          <path
            fill="#2D85E8"
            opacity="1.000000"
            stroke="none"
            d="
M278.010071,263.625854 
	C273.728333,263.576447 269.440826,263.157806 265.079315,262.374725 
	C268.475647,261.874695 271.945923,261.739136 275.708893,261.318604 
	C276.437836,261.024719 276.874146,261.015778 277.636566,261.025757 
	C277.976532,261.782013 277.990387,262.519287 278.010071,263.625854 
z"
          />
          <path
            fill="#2D85E8"
            opacity="1.000000"
            stroke="none"
            d="
M283.814209,263.771362 
	C283.121765,264.178894 282.245911,264.337036 281.183197,264.251007 
	C281.874481,263.845215 282.752655,263.683594 283.814209,263.771362 
z"
          />
          <path
            fill="#236AD7"
            opacity="1.000000"
            stroke="none"
            d="
M497.574951,270.994751 
	C499.461090,271.669067 500.953644,272.968048 502.374207,272.893555 
	C510.411499,272.471893 518.433655,271.273804 526.456299,271.311737 
	C529.488586,271.326080 532.543335,273.876862 535.426392,275.549561 
	C535.563110,275.628876 534.352905,278.584045 533.369141,279.900360 
	C532.130859,281.557220 529.580750,282.704102 529.194336,284.428955 
	C528.398987,287.979065 526.564758,289.073761 523.286194,289.020416 
	C518.411682,288.941071 513.534973,288.999878 508.659180,288.999878 
	C508.618591,289.405792 508.578033,289.811707 508.537445,290.217621 
	C509.334167,290.528198 510.341553,290.634735 510.882385,291.193146 
	C511.909149,292.253204 513.640259,294.003876 513.334229,294.692169 
	C512.723328,296.066437 510.975708,297.763580 509.624176,297.829773 
	C505.017456,298.055481 500.368073,297.347351 495.754974,297.522400 
	C489.388458,297.764008 483.045776,298.587341 476.680573,298.937683 
	C474.751862,299.043884 472.782410,298.409149 470.417023,298.058411 
	C469.071777,297.291748 467.991638,296.708679 467.245728,295.838928 
	C466.150452,294.561798 465.294067,293.079742 464.335846,291.685028 
	C465.685638,290.790680 467.017029,289.178650 468.388916,289.143372 
	C471.808807,289.055573 473.138428,287.744476 473.120178,284.367188 
	C473.114563,283.320496 474.272430,281.369049 474.929169,281.355499 
	C481.873016,281.211823 481.064819,276.338989 480.997009,271.424194 
	C486.379456,270.984467 491.763824,270.984772 497.574951,270.994751 
z"
          />
          <path
            fill="#4C7BBD"
            opacity="1.000000"
            stroke="none"
            d="
M305.233276,412.141388 
	C305.614349,415.097656 305.850372,418.524780 305.763123,422.156372 
	C305.322632,419.111298 305.205414,415.861786 305.233276,412.141388 
z"
          />
          <path
            fill="#39A0F6"
            opacity="1.000000"
            stroke="none"
            d="
M184.498047,222.509995 
	C181.940186,221.828110 179.709564,221.209305 177.447006,221.052933 
	C171.853973,220.666351 169.843964,217.589325 172.981796,213.136139 
	C179.125504,204.417084 185.287292,195.649338 192.250427,187.601578 
	C194.767166,184.692810 199.593491,183.772171 203.381912,181.978043 
	C205.403900,181.020477 207.379898,179.801544 209.517776,179.288025 
	C213.622757,178.302017 217.832169,177.755707 221.990494,176.985062 
	C224.372284,176.543655 226.721069,175.847092 229.119141,175.600815 
	C230.457581,175.463364 232.363358,175.567276 233.095978,176.384735 
	C233.753769,177.118698 233.651764,179.278580 233.034454,180.290756 
	C228.679230,187.431976 223.736771,194.237305 219.717484,201.554016 
	C216.576706,207.271469 214.413223,213.537842 211.971985,219.623093 
	C210.449097,223.419159 208.258331,225.692627 203.764404,225.054901 
	C197.465912,224.161102 191.130432,223.528091 184.498047,222.509995 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M328.437256,136.991653 
	C329.266388,136.323395 330.484467,135.341980 331.845978,135.064590 
	C335.076996,134.406311 338.466827,134.411942 341.625580,133.542725 
	C346.044861,132.326614 346.950165,128.480484 346.945801,124.528458 
	C346.941956,121.029266 347.580597,118.654457 351.653473,117.693687 
	C353.462585,117.266922 354.767212,114.701653 356.649170,113.052521 
	C354.840332,117.327034 352.684296,121.656700 350.520630,125.982544 
	C350.449768,126.124207 350.244965,126.207993 350.196838,126.350586 
	C346.617371,136.953430 337.818634,137.103775 328.437256,136.991653 
z"
          />
          <path
            fill="#305CB3"
            opacity="1.000000"
            stroke="none"
            d="
M185.000610,553.437134 
	C185.735641,553.069763 186.472458,553.017639 187.577698,552.958496 
	C189.346939,552.966675 190.747726,552.981750 192.566956,553.002197 
	C190.592728,553.674561 188.200089,554.341614 185.410309,555.012451 
	C185.009552,554.594910 185.005966,554.173645 185.000610,553.437134 
z"
          />
          <path
            fill="#205CC7"
            opacity="1.000000"
            stroke="none"
            d="
M550.765625,294.733337 
	C549.592224,295.329163 548.177124,295.658142 546.373596,295.974487 
	C547.498108,295.463379 549.010986,294.964935 550.765625,294.733337 
z"
          />
          <path
            fill="#D9E3F1"
            opacity="1.000000"
            stroke="none"
            d="
M121.663712,401.084412 
	C124.452637,395.754639 123.462631,388.974060 128.976624,384.691345 
	C130.637619,389.232819 132.245529,393.113403 133.468964,397.111664 
	C134.835556,401.577820 133.775009,403.050995 129.211197,402.903107 
	C126.683578,402.821228 124.181114,401.962769 121.663712,401.084412 
z"
          />
          <path
            fill="#4199E4"
            opacity="1.000000"
            stroke="none"
            d="
M304.609619,233.022385 
	C303.902161,233.679199 302.823975,234.328384 301.373383,234.966782 
	C302.080261,234.308914 303.159607,233.661835 304.609619,233.022385 
z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
